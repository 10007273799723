import React from "react";
import CustomGoogleMap from "./CustomGoogleMap";
import { googleApiKey } from "../../config/Api.config";
import { Grid } from "@material-ui/core";
import { MAPOPTIONSTYLES_INTERFACE } from "../../RoutePlanning/AllRoutes/utils/AllRouteConstant";

export interface CustomLocation {
  lat: number;
  lng: number;
}

export interface CustomMarker {
  name: string;
  location: CustomLocation;
  icon?: any;
  label?: any;
  routeId?: string;
  deliveryArea?: string;
  deliverySlot?: string;
  consignmentCode?: string;
  stopover?: boolean;
}

interface GoogleMapContainerProps {
  center: CustomLocation;
  showDirections?: boolean;
  zoom?: number;
  markers?: CustomMarker[];
  roundTrip?: boolean;
  optimizeRoute?: boolean;
  updateMap?: boolean;
  showInfoWindow?: boolean;
  getRouteDetailsForInfoWindow?: any;
  mapOptionStyles?: MAPOPTIONSTYLES_INTERFACE[];
  mapPolylineMarker?: CustomLocation[];
  polylineColor?: string;
}

const MapContainer = (props: GoogleMapContainerProps) => {
  const { center, zoom, markers, showDirections, roundTrip, optimizeRoute, updateMap, showInfoWindow, getRouteDetailsForInfoWindow, mapOptionStyles, mapPolylineMarker, polylineColor } = props;

  return (
    <CustomGoogleMap
      googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleApiKey + "&libraries=geometry,drawing,places"}
      markers={markers || []}
      showDirections={showDirections}
      loadingElement={<Grid style={{ height: `100%` }} />}
      containerElement={<Grid style={{ height: `100%` }} />}
      mapElement={<Grid style={{ height: `100%` }} />}
      roundTrip={roundTrip}
      optimizeRoute={optimizeRoute}
      updateMap={updateMap}
      defaultCenter={center || { lat: 0, lng: 0 }}
      defaultZoom={zoom || 8}
      showInfoWindow={showInfoWindow}
      getRouteDetailsForInfoWindow={getRouteDetailsForInfoWindow}
      mapOptionStyles={mapOptionStyles}
      mapPolylineMarker={mapPolylineMarker}
      polylineColor={polylineColor}
    />
  );
};

export default MapContainer;
