import React, { useState } from "react";
import { useSelector } from "react-redux";

//Material UI Components
import { Grid, Typography } from "@material-ui/core";

//Style
import { useStyles } from "./performanceDashboard.styles";

//Common Components
import BarCharts from "../../common/BarCharts";
import AreaChart from "../../common/AreaChart";
import DonutChart from "../../common/DonutChart";

//Constants
import { COLORS } from "../../config/theme/baseTheme";

import { AppState } from '../../config/redux/reducers';
import PieChart from "common/PieChart";
import SwitchButton from "../../common/SwitchButton/SwitchButton";

interface ConsignmentDashboardProps { }

const BARCOLORS_PERFORMANCE = [
    COLORS.PALE_PURPLE,
    COLORS.OCEAN_GREEN
]

const BARCOLORS_VOLUME = [
    COLORS.ALICE_BLUE,
    COLORS.INFO_BLUE,
    COLORS.VIBRANT_YELLOW,
    COLORS.VENETIAN_RED,
    COLORS.FOUNTAIN_BLUE,
    COLORS.BRICK_RED,
    COLORS.VERY_LIGHT_GREY,
]

const DONUT_COLORS = [
    COLORS.PRIMARY_MAIN,
    COLORS.CORAL_PINK,
    COLORS.PICTON_BLUE,
    COLORS.ALERT_ORANGE
]

const PIE_COLORS = [
    COLORS.PRIMARY_LIGHT_BLUE,
    COLORS.PRIMARY_LIGHT,
    COLORS.PRIMARY_MAIN,
]

const DONUT_LABELS = [
    'E-Food', 'Marketplace', 'Non-Food', 'Carrefour Now'
]

const PIE_LABELS = [
    "COD", "Card on Delivery", "Prepaid"]

const ConsignmentsDashboard = (props: ConsignmentDashboardProps) => {
    const classes = useStyles();

    const { consignments } = useSelector(
        (state: AppState) => state.performance
    );
    const [isChecked, setIsChecked] = useState(false);


    const handleSwitch = (e: any) => {
        if (isChecked) {
            setIsChecked(false);
        } else {
            setIsChecked(true);
        }
    };

    const renderGraphsSection = () => {
        return (
            <>
                {isChecked ?
                    <Grid item>
                        <Grid item className={classes.subDiv}>
                            <AreaChart
                                days={consignments.formattedSlots}
                                color={BARCOLORS_PERFORMANCE}
                                seriesObj={consignments.countsData && consignments.countsData.length ? consignments.countsData : []}
                                showLegend={true}
                                stacked={false}
                            />
                        </Grid>
                    </Grid> : <Grid>
                        <Grid item className={classes.subDiv}>
                            <AreaChart
                                days={consignments.formattedSlots}
                                color={BARCOLORS_PERFORMANCE}
                                seriesObj={consignments.percentageData && consignments.percentageData.length ? consignments.percentageData : []}
                                showLegend={true}
                                stacked={false}
                            />
                        </Grid>
                    </Grid>}
                <Grid container style={{ paddingTop: "24px" }}>
                    <Grid item>
                        <Typography className={classes.consignmentText}>Volume by Slots</Typography>
                    </Grid>
                    <Grid container className={classes.chartDiv}>
                        <Grid item className={classes.subDiv}>
                            <BarCharts
                                days={consignments.formattedSlots}
                                color={BARCOLORS_VOLUME}
                                seriesObj={consignments.volumeData && consignments.volumeData.length ? consignments.volumeData : []}
                                showLegend={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

    const renderPieChartsSection = () => {
        return (
            <>
                <Grid item xs={6}>
                    <Grid item className={classes.pieDivision}>
                        <PieChart pieColors={PIE_COLORS} pieLabel={PIE_LABELS} pieData={consignments.paymentWise || []} title={"Consignments by Payment Mode"} />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item className={classes.pieDivision}>
                        <DonutChart pieColors={DONUT_COLORS} pieLabel={DONUT_LABELS} pieData={consignments.deliveryTypeWise || []} title={"Consignments by Delivery Type"} donutLabel={"Consignments"} />
                    </Grid>
                </Grid>
            </>

        )
    }

    return (
        <Grid container className={classes.subContainer} spacing={2} justify="space-between">
            <Grid item xs={3} >
                <Grid container className={classes.consignments} justify="space-between">
                    <Grid item className={classes.tileContent}>
                        <Typography className={classes.totalText}>Total Consignments</Typography></Grid>
                    <Grid item className={classes.consignmentData}>{consignments.totalConsignments || 0}</Grid>
                </Grid>
                <Grid container className={classes.deliveryText}>

                    <Grid item className={classes.deliverySection}>
                        <Typography className={classes.consignmentText}>Consignments by Delivery</Typography>
                    </Grid>

                    <Grid container spacing={2} className={classes.subDivision}>
                        <Grid item xs={6}>
                            <Grid item className={classes.subDivisionHandover}>
                                <Grid container justify="space-between">
                                    <Grid item xs={6} className={classes.deliveryTiles}><Typography className={classes.deliveryTilesText}>Ontime Handover</Typography></Grid>
                                    <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                        <Typography className={classes.deliveryTileContent}>
                                            {consignments.handOverOnTimePercentage + "%"}
                                        </Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item className={classes.subDivisionOntime}>
                                <Grid container justify="space-between">
                                    <Grid item xs={6} className={classes.deliveryTiles}><Typography className={classes.deliveryTilesText}>Ontime Delivery</Typography></Grid>
                                    <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                        <Typography className={classes.deliveryTileContent}>{consignments.onTimePercentage + "%"}</Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.firstTileSection} spacing={2}>
                        <Grid item xs={6}>
                            <Grid container className={classes.tiles}>
                                <Grid item className={classes.tileContent}><Typography className={classes.statusText}>Total</Typography></Grid>
                                <Grid item className={classes.tileContent}><Typography className={classes.totalData}>{consignments.globalStateCount.Total || 0}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container className={classes.tiles}>
                                <Grid item className={classes.tileContent}><Typography className={classes.statusText}>Ontime</Typography></Grid>
                                <Grid item className={classes.tileContent}><Typography className={classes.onTimeData}>{consignments.globalStateCount.OnTime || 0}</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.secondTileSection} spacing={2}>
                        <Grid item xs={6}>
                            <Grid container className={classes.tiles}>
                                <Grid item className={classes.tileContent}><Typography className={classes.statusText}>Late</Typography></Grid>
                                <Grid item className={classes.tileContent}><Typography className={classes.lateData}>{consignments.globalStateCount.Late || 0}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container className={classes.tiles}>
                                <Grid item className={classes.tileContent}><Typography className={classes.statusText}>At Risk</Typography></Grid>
                                <Grid item className={classes.tileContent}><Typography className={classes.atRiskData}>{consignments.globalStateCount['At Risk'] || 0}</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.horizontalLine} />
                    <Grid container style={{ padding: "24px" }}>
                        <Typography>Top 5 Drivers</Typography>
                    </Grid>
                    <Grid item style={{ padding: "24px" }}>Coming Soon..!!</Grid>

                </Grid>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={2} style={{ flexWrap: "nowrap" }}>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.globalStatusTiles}>
                            <Grid item className={classes.statusTilesTitle}>Unplanned</Grid>
                            <Grid item className={classes.data}>{consignments.performanceParams.Unplanned || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.globalStatusTiles}>
                            <Grid item className={classes.statusTilesTitle}>Planned /Published</Grid>
                            <Grid item className={classes.data}>{consignments.performanceParams['Planned/Published'] || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.globalStatusTiles}>
                            <Grid item className={classes.statusTilesTitle}>Preparing/Prepared</Grid>
                            <Grid item className={classes.data}>{consignments.performanceParams['Preparing/Prepared'] || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.globalStatusTiles}>
                            <Grid item className={classes.statusTilesTitle}>Out for Delivery</Grid>
                            <Grid item className={classes.data}>{consignments.performanceParams['Out for Delivery'] || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.globalStatusTiles}>
                            <Grid item className={classes.statusTilesTitle}>Delivered</Grid>
                            <Grid item className={classes.data}>{(consignments.performanceParams.Delivered + (consignments.performanceParams['Partially Delivered'] || 0)) || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.globalStatusTiles}>
                            <Grid item className={classes.statusTilesTitle}>Un-delivered</Grid>
                            <Grid item className={classes.data}>{consignments.performanceParams['Un-Delivered'] || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.globalStatusTiles}>
                            <Grid item className={classes.statusTilesTitle}>Cancelled</Grid>
                            <Grid item className={classes.data}>{consignments.performanceParams['Cancelled'] || 0}</Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "16px" }}>
                    <Grid container direction="column" className={classes.graphDivision}>
                        <Grid item>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Typography className={classes.consignmentText}>Performance by Slots</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid item xs={7} className={classes.switchStyle}>
                                        Percentage
                                        <SwitchButton
                                            size="large"
                                            checked={isChecked}
                                            handleChange={handleSwitch}
                                            onClick={(e: any) => e.stopPropagation()}
                                        />
                                        Count
                                     </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {renderGraphsSection()}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {renderPieChartsSection()}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ConsignmentsDashboard;