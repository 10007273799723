import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";
import { useStyles } from "./AreaChart.styles";
import { COLORS } from "../../config/theme/baseTheme";

interface AreaChartProps {
  options?: ApexOptions;
  seriesObj: any;
  days: any;
  color: any;
  showLegend: boolean;
  stacked: boolean;
}

const AreaChart = (props: AreaChartProps) => {
  const { days, color, seriesObj, showLegend } = props;
  const classes = useStyles();
  const config: any = {
    series: seriesObj,
    options: {
      colors: color,
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        curve: 'smooth',
        width: 2,
      },
      legend: {
        show: showLegend,
        position: 'top',
        horizontalAlign: 'left',
        markers: {
          width: 10,
          height: 10,
          radius: 4,
          offsetX: 0,
          offsetY: 0
        },
      },
      plotOptions: {
        area: {
          fillTo: 'origin',
        }
      },
      yaxis: {
        crosshairs: {
          show: true,
          position: 'back',
          stroke: {
            color: '#b6b6b6',
            width: 1,
            dashArray: 0,
          },
        },
        tickAmount: 4,
        min: 0,
      },
      xaxis: {
        categories: days,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.8,
          opacityTo: 0.9,
          stops: [100]
        },
      },
      grid: {
        show: true,
        borderColor: COLORS.SILVER_CHALICE,
        strokeDashArray: 3,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          show: false,
          formatter: function (val: any) {
            return val
          }
        },
        marker: {
          show: true,
        },
      },
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: 14,
        },
      },
    },
  };

  return (
    <div className={classes.barChart}>
      <div className="row">
        <div className="mixed-chart">
          <Chart options={config.options} series={config.series} height="286px" type='area' />
        </div>
      </div>
    </div>
  );
};


export default AreaChart;
