import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from './../../config/theme/baseTheme';

const drawerWidthClose = 52;
const drawerWidthOpen = 260;


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      width: drawerWidthClose,
      top: 0,
      height: "100vh",
      '& .countrySelect': {
        minWidth: 180,
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 0,
          border: "none"
        }
      },
      '& .hubSelect': {
        minWidth: 100,
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 0,
          border: "none"
        }
      },
    },
    appBar: {
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.05) !important",
      '&.open': {
        marginLeft: drawerWidthOpen,
        width: `calc(100% - ${drawerWidthOpen}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })
      },
      '&.close': {
        width: `calc(100% - ${drawerWidthClose}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      '& .appToolbar': {
        display: "flex",
        minHeight: 70,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
        '& .menuBtnSection': {
          paddingRight: theme.spacing(5),
          '& .menuButton': {
            fontSize: 21,
            color: COLORS.GRAY_DARK
          }
        },
        '& .countrySection': {
          marginLeft: "auto"
        },
        '& .avatarSection': {
          '& .avatarDetails': {
            textAlign: 'right',
            borderLeft: `1px solid ${theme.palette.secondary.dark}`,
            padding: theme.spacing(0, 1.5),
            '& .avatarItem': {
              fontSize: 12,
              fontWeight: 500
            },
            '& .name': {
              fontWeight: 600,
              lineHeight: 1.5
            },
            '& .logoutBtn': {
              cursor: "pointer"
            }
          },
          '& .avatarLogo': {
            '& .MuiAvatar-root': {
              width: 35,
              height: 35,
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.main
            }
          }
        }
      },
    },
    drawer: {
      width: drawerWidthOpen,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.secondary.main,
        border: "none"
      },
      '&.open': {
        width: drawerWidthOpen,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        '& .MuiPaper-root': {
          width: drawerWidthOpen,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }
      },
      '&.close': {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: drawerWidthClose,
        '& .MuiPaper-root': {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
          width: drawerWidthClose
        }
      },
      '& .drawerContainer': {
        flexDirection: "column",
        flexGrow: 1,
        flexWrap: "nowrap",
        '& .toolbarSection': {
          position: "sticky",
          top: 0,
          zIndex: 10,
          '& .toolbar': {
            backgroundColor: COLORS.SECONDARY_MAIN,
            minHeight: 70,
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.05)",
            '& .logoSection': {
              paddingRight: theme.spacing(5),
              '&a': {
                textDecoration: "none"
              },
              '& .CaMSLogo': {
                height: 40,
                width: 224
              }
            }
          },
        },
        '& .listSection': {
          flexGrow: 1,
          zIndex: 1,
          '& .listContainer': {
            padding: 0,
            '& .itemIcon': {
              color: "#F4F4F4"
            },
            '& .listItem': {
              minHeight: 60,
              borderBottom: `1px solid ${COLORS.WHITE}`,
              '&.Mui-selected': {
                backgroundColor: COLORS.TABLE_ROW_SELECTED,
                borderLeft: `3px solid ${theme.palette.primary.main}`,
                '& .MuiListItemText-primary': {
                  fontWeight: 600,
                  color: theme.palette.primary.main
                },
                '& .MuiListItemIcon-root .listIcon': {
                  stroke: theme.palette.primary.main
                },
                '& .dropdownIcon path': {
                  stroke: theme.palette.primary.main
                }
              },
              '&.subItem': {
                backgroundColor: COLORS.TABLE_ROW_SELECTED,
                paddingLeft: 75,
                '&.Mui-selected': {
                  backgroundColor: COLORS.SUB_HEADER_SELECTED,
                  borderLeft: "none"
                },
                '& .MuiListItemText-primary': {
                  fontWeight: 500
                },
              },
              '& .dropdownIcon': {
                width: 16,
                height: 16,
                '& path': {
                  stroke: COLORS.COPY,
                  strokeWidth: 1
                }
              },
              '& .MuiListItemText-primary': {
                fontSize: 14,
                color: COLORS.COPY
              },
              '& .listIcon': {
                width: 26,
                height: 24,
                '&.MuiSvgIcon-root': {
                  fill: "none"
                }
              }
            }
          },
        }
      },
    },
    countryMenuPaper: {
      maxHeight: "200px !important",
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: '8px !important',
      boxShadow: "none !important"
    }
  }),
);