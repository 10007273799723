import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            height: "100%",
        },
        container: {
            flexDirection: "column",
            '& .planningItem': {
                width: '100%',
                '& .MuiTab-root': {
                    marginRight: 44
                },
                '&.heading': {
                    '& p': {
                        fontSize: 20,
                        lineHeight: "30px",
                        color: COLORS.PRIMARY_MAIN,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                    }
                },
                '&.content': {
                    backgroundColor: COLORS.WHITE,
                    borderRadius: theme.spacing(1),
                    height: "calc(50vh)",
                    '& .tabPanel': {
                        height: "calc(100% - 56px)",
                        padding: 0,
                        '&.routes': {
                            position: "relative"
                        }
                    }
                }
            }
        },
        tabPanelContainer: {
            flexDirection: "column",
            height: "100%",
            '& .panelItem': {
                width: "100%",
                '&.chipFilter': {
                    padding: "16px 16px 16px 0px",
                    '& .chipsContainer': {
                        display: "flex",
                        justifyContent: "space-between",
                        '& .chipFilterItem': {
                            height: 36,
                            minWidth: "16%",
                            borderRadius: "30px",
                            boxShadow: "0px 4px 48px rgb(0 0 0 / 8%)",
                            backgroundColor: "#FFFFFF",
                            cursor: "pointer",
                            '&:last-child': {
                                marginRight: 0
                            },
                            '&.firstItem': {
                                boxShadow: `0px 0px 10px 4px ${COLORS.BOX_SHADOW_PRIMARY}`,
                                border: `2px solid ${theme.palette.primary.main}`
                            },
                            '&.active': {
                                background: COLORS.PRIMARY_MAIN,
                                border: "none",
                                boxShadow: "none",
                                '& .chipItem p': {
                                    color: COLORS.WHITE
                                }
                            },
                            '& .chipContainer': {
                                alignItems: "center",
                                height: "100%",
                                '& .chipItem': {
                                    padding: theme.spacing(0, 1.5),
                                    '& p': {
                                        fontWeight: 500,
                                        color: COLORS.MINE_SHAFT,
                                    },
                                    '&.name': {
                                        '& p': {
                                            fontSize: 14
                                        }
                                    },
                                    '&.count': {
                                        marginLeft: "auto",
                                        '& p': {
                                            fontSize: 24,
                                            fontWeight: 600
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                '&.gridWrapper': {
                    height: "calc(100% - 98px)",
                    '& .idLink': {
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        fontSize: 12
                    },
                    '& .actionsLink': {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        fontSize: 14
                    }
                }
            }
        },
       
      
    }),
);
