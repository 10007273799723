import React from "react";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as LocationIcon } from "../../../assets/icons/location-icon.svg";
import { ReactComponent as PayWalletIcon } from "../../../assets/icons/pay-wallet-icon.svg";

import { getPaymentModeString } from "../utils/AllRouteUtils";

interface RenderOrderAccordianDetailsInterface {
  classes: any;
  orderObj: any;
}

const RenderOrderAccordianDetails = (props: RenderOrderAccordianDetailsInterface) => {
  const { classes, orderObj } = props;

  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item className={classes.displayFlex}>
            <SvgIcon viewBox="0 0 16 16" fontSize="large" component={LocationIcon} className={classes.locationIconStyle} />
            <Typography className={`${classes.fontSizeFourteen} ${classes.fontWeightFour} ${classes.lineHeightEighteen} ${classes.marginAutoFour}`}>
              {`${orderObj?.address?.fullAddress || ""}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item className={`${classes.displayFlex} ${classes.borderRight} ${classes.paddingTopFour}`}>
            <Typography className={`${classes.displayFlex} ${classes.fontSizeFourteen} ${classes.fontWeightSeven} ${classes.lineHeightEighteen} ${classes.marginAutoFour} ${classes.paddingRightFour}`}>
              {`Area : `}
              <Typography className={`${classes.fontSizeFourteen} ${classes.fontWeightFour} ${classes.lineHeightEighteen} ${classes.marginAutoFour}`}>{`${
                (orderObj.address && orderObj.address.area) || ""
              }`}</Typography>
            </Typography>
          </Grid>
          <Grid item className={`${classes.displayFlex} ${classes.paddingTopFour}`}>
            <SvgIcon viewBox="0 0 24 24" component={PayWalletIcon} className={classes.WalletIconStyle} />
            <Typography className={`${classes.fontSizeFourteen} ${classes.fontWeightFour} ${classes.lineHeightEighteen} ${classes.marginAutoFour}`}>
              {getPaymentModeString(orderObj.paymentMethod)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderOrderAccordianDetails);
