import React from "react";

// Material UI Components
import { withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 46,
    height: 24,
    padding: 0,
    display: "flex",
    margin: 8,
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: "none",
    backgroundColor: "white",
  },
  track: {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "#E6E6E6",
  },
  checked: {},
}))(Switch);

const SwitchButton = ({ checked, handleChange, ...props }) => {
  return <AntSwitch {...props} checked={checked} onChange={handleChange} size="medium" />;
};

export default React.memo(SwitchButton);
