import React from "react";
import {saveAs} from "file-saver";
import { Typography } from "@material-ui/core";
interface DownloadFileProps {
  children?: any;
  icon: any;
  item: any;
}
interface UploadFileProps {
  children?: any;
  value?: any;
  accept: any;
  disabled?: boolean;
  onChange: any;
  icon?: any;
  Text?: any;
}

export const FileStorageUpload = (props: UploadFileProps) => {
  const {
    value,
    accept,
    disabled,
    icon,
    Text,
    onChange,
  } = props;


  function handleUpload(event: any) {
    let selectedFile = event.target.files[0];
    onChange(selectedFile)
  }


  return (
    <label htmlFor="contained-button-file" className="m-0 w-100">
      <input
        value={value}
        accept={accept}
        disabled={disabled}
        style={{ display: "none" }}
        id="contained-button-file"
        data-testid="contained-button-file"
        multiple
        type="file"
        onChange={disabled ? () => {} : handleUpload}
      />
      {
        <div className="btnDiv">
          {icon && <img src={icon} alt="attach" />}
          {Text && <Typography className="btnText">{Text}</Typography>}
        </div>
      }
    </label>
  );
};


export const FileStorageSave = (props: DownloadFileProps) => {
  const { children, icon, item } = props;

  const downloadFile = () => {
    if (item) {
      saveAs(item.url, item.name);
    }
  };

  return (
    <>
      {
        <img
          src={icon}
          alt="download"
          className="icon"
          onClick={() => downloadFile()}
        />
      }
    </>
  );
};
