const DriversConstants = {
    DRIVERS_CONSTANTS: {
        CONSIGNMENTS_DATA: {
            TABLE_WRAPPER_DATA: {
                title: "Drivers",
                HeaderData: [
                    {
                        field: 'id',
                        headerName: 'Id',
                        hide: true
                    },
                    {
                        field: 'driverId',
                        headerName: 'Driver ID',
                        width: 150,
                    },
                    {
                        field: 'driverName',
                        headerName: 'Driver Name',
                        width: 180,
                    },
                    {
                        field: 'vehicle',
                        headerName: 'Vehicle',
                        width: 150,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "vehicle",
                            fieldName: "vehicle",
                            fieldFullName: "vehicle",
                            multiple: true,
                            key: `autoComplete-vehicle-${Math.floor(Math.random() * 1000)}`,
                            label: "Vehicle",
                            items: []
                        }
                    },
                    {
                        field: 'vendor',
                        headerName: 'Vendor',
                        width: 180,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "vendor",
                            fieldName: "vendor",
                            fieldFullName: "vendor",
                            multiple: true,
                            key: `autoComplete-vendor-${Math.floor(Math.random() * 1000)}`,
                            label: "Vendor",
                            items: []
                        }
                    },
                    {
                        field: 'hubCode',
                        headerName: 'POS No',
                        width: 180,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "hubCode",
                            fieldName: "hubCode",
                            fieldFullName: "hubCode",
                            multiple: true,
                            key: `autoComplete-hubCode-${Math.floor(Math.random() * 1000)}`,
                            label: "Hub Code",
                            items: []
                        }
                    },
                    {
                        field: 'hubNames',
                        headerName: 'POS Name',
                        width: 180,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "hubName",
                            fieldName: "hubName",
                            fieldFullName: "hubName",
                            multiple: true,
                            key: `autoComplete-hubName-${Math.floor(Math.random() * 1000)}`,
                            label: "Hub Name",
                            items: []
                        }
                    },
                    {
                        field: 'status',
                        headerName: 'Status',
                        width: 180,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "status",
                            fieldName: "status",
                            fieldFullName: "status",
                            multiple: true,
                            key: `autoComplete-status-${Math.floor(Math.random() * 1000)}`,
                            label: "Status",
                            items: []
                        }
                    },
                    {
                        field: 'modes',
                        headerName: 'Assignment Mode',
                        width: 180,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "modes",
                            fieldName: "modes",
                            fieldFullName: "modes",
                            multiple: true,
                            key: `autoComplete-modes-${Math.floor(Math.random() * 1000)}`,
                            label: "Assignment Mode",
                            items: []
                        }
                    },
                    {
                        field: 'actions',
                        headerName: 'Actions',
                        width: 100,
                        hide: false
                    },
                ],
                headerButtons: [
                    {
                        name: "colsettings",
                        displayName: "Column Settings",
                        disabled: false,
                        childElements: [
                            {
                                name: "excel",
                                displayName: "EXPORT DATA",
                                disabled: false
                            }
                        ]
                    }
                ],
                loadMore: {
                    name: 'loadMore',
                    disabled: false,
                    pageSize: 20
                },
                defaultFiltersObj: {
                    hubCode: {
                        key: "hubCode",
                        value: [],
                    },
                    hubName: {
                        key: "hubNames",
                        value: [],
                    },
                    vehicle: {
                        key: "vehicle",
                        value: []
                    },
                    vendor: {
                        key: "vendor",
                        value: []
                    },
                    status: {
                        key: "status",
                        value: []
                    },
                    modes: {
                        key: "modes",
                        value: []
                    },
                    pageNumber: 0,
                },
                SEARCH_TYPES: [
                    {
                        name: "Driver ID",
                        value: "driverId"
                    },
                    {
                        name: "Driver Name",
                        value: "driverName"
                    }
                ],
                sortModel: {
                    field: "id",
                    direction: "asc"
                }
            },
            FILTER_CHIPS: [

                {
                    code: 'ACTIVE',
                    value: 'Active'
                },
                {
                    code: 'AVAILABLE',
                    value: 'Available'
                },
                {
                    code: 'ASSIGNED',
                    value: 'Assigned'
                },
                {
                    code: 'OUT_FOR_DELIVERY',
                    value: 'Out For Delivery'
                },
                {
                    code: 'AWAY',
                    value: 'Away'
                },
                {
                    code: 'BACK_TO_STORE',
                    value: 'Back To Store'
                },
                {
                    code: 'OFFLINE',
                    value: 'Offline'
                },
                {
                    code: "ALL",
                    value: "All"
                }
            ],
            DRIVER_STATUSES: {
                "ACTIVE": {
                    color: "OCEAN_GREEN",
                    value: "ACTIVE",
                    hintText: ""
                },
                "AVAILABLE": {
                    color: "SEA_GREEN",
                    value: "AVAILABLE",
                    hintText: ""
                },
                "UNASSIGNED": {
                    color: "SEA_GREEN",
                    value: "UNASSIGNED",
                    hintText: ""
                },
                "ASSIGNED": {
                    color: "GOLDEN_BELL",
                    value: "ASSIGNED",
                    hintText: ""
                },
                "BACK_TO_STORE": {
                    color: "INFO_BLUE",
                    value: "BACK TO STORE",
                    hintText: ""
                },
                "AWAY": {
                    color: "GREY_SCALE",
                    value: "AWAY",
                    hintText: ""
                },
                "OUT_FOR_DELIVERY": {
                    color: "GREY_SCALE",
                    value: "OUT FOR DELIVERY",
                    hintText: ""
                },
                "OFFLINE": {
                    color: "GREY_SCALE",
                    value: "OFFLINE",
                    hintText: ""
                },
            },
            STATUSES: {
                "ACTIVE": {
                    value: "ACTIVE",
                },
                "AVAILABLE": {
                    value: "AVAILABLE",
                },
                "UNASSIGNED": {
                    value: "UNASSIGNED",
                },
                "ASSIGNED": {
                    value: "ASSIGNED",
                },
                "BACK TO STORE": {
                    value: "BACK_TO_STORE",
                },
                "AWAY": {
                    value: "AWAY",
                },
                "OUT FOR DELIVERY": {
                    value: "OUT_FOR_DELIVERY",
                },
                "OFFLINE": {
                    value: "OFFLINE",
                },
            },
            DEFAULT_FILTER_CHIP: 'ACTIVE',
            TAB_VALUES: {
                DRIVER_PROFILE: 'User Profile',
                ACTIVITY_LOG: 'Activity Log',
            },
            ALL_FILTER_CHIP: "ALL",
            DRIVER_ASSIGNED_SUCCESS_MSG: "Driver Assigned successfully"
        },
        RESPONSE_CONSTANTS: {
            ERROR_CODES: {
                SESSION_TIMEOUT: "UM100",
                UNAUTHORISED: "UM101",
                CONCURRENT_CONSIGNMENT: "CWO15"
            }
        }
    },
}

export default DriversConstants;