import React, { forwardRef, ReactElement, Ref, useCallback } from 'react';
import { useSelector } from "react-redux";
import CustomDialog from '../common/Dialog';
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import { useStyles } from './HubDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, SvgIcon, Typography } from '@material-ui/core';
import { AppState } from "../config/redux/reducers";
interface HubDetailsProps {
    open: boolean;
    hub: any;
    closePopup: () => void;
    handleDialogAction?: (type: string) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const HubDetails = (props: HubDetailsProps) => {

    const classes = useStyles();
    const { isCollectionPointManager, isCHDManager, isVTManager, isDispatcher, isOnlyStandardDispatcher, isLastMileViewer, isOnlyStdLastMileViewer } = useSelector(
        (state: AppState) => state.userLogin
    );
    const { open, hub, closePopup, handleDialogAction } = props;
    let tableRecord: any = {};
    let hubItems: any = [];


    if (hub) {
        tableRecord = hub.tableRecord;
        if (hub.countryDetails) {
            for (let country in hub.countryDetails) {
                let countryHubs = hub.countryDetails[country];
                countryHubs.filter((hub: any) => {
                    hubItems.push(hub);
                    return true;
                })
            }
        }
    }
    const dialogPaperProps = {
        classes: {
            root: classes.dialogPaperPropsRoot
        },
        square: true
    }

    const dialogActionHandler = useCallback(
        (type: string) => {
            handleDialogAction && handleDialogAction(type);
        },
        [handleDialogAction],
    )

    const handleDialogClose = () => {
        closePopup();
    }

    const getTitleContent = () => {
        return (
            <Grid className={classes.titleContainer} container>
                <Grid item>
                    <Grid className="headingContainer" container>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Typography className="titleHeading">
                                        <span className="label"> HUB ID : </span>
                                        <span className="content">{tableRecord && tableRecord.hubId} </span>
                                    </Typography>
                                </Grid>
                                {(!isCollectionPointManager && !isDispatcher && !isCHDManager && !isVTManager && !isOnlyStandardDispatcher && !isLastMileViewer && !isOnlyStdLastMileViewer) ? (
                                    <Grid className="editIcon" item>
                                        <SvgIcon className="icon" onClick={() => dialogActionHandler('update')} component={editIcon} />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }

    const getDetailsContent = () => {
        return (
            <Grid className={classes.contentContainer} container>
                <Grid item>
                    <Grid className="detailsContent" container>
                        <Grid container className="contentRow" item>
                            <Grid container>
                                <Grid xs={5} className="contentColumn" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Hub Name</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{(tableRecord && tableRecord.hubName) || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={4} className="contentColumn second" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Hub Id</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{tableRecord && tableRecord.hubId}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={3} className="contentColumn" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Service Type</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{tableRecord && tableRecord.serviceType.join(" , ")}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="contentRow" item>
                            <Grid container>
                                <Grid xs={5} className="contentColumn" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Latitude/Longitude</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{(tableRecord && tableRecord.latLong) || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={4} className="contentColumn" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Category</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{(tableRecord && tableRecord.category[0]) || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={3} className="contentColumn" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Status</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{(tableRecord && tableRecord.hubStatus) || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container className="contentRow twoItems" item>
                            <Grid container>
                                <Grid xs={5} className="contentColumn second" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Country</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{(tableRecord && tableRecord.country) || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={7} className="contentColumn" item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className="label">Hub Address</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className="content">{(tableRecord && tableRecord.area) || "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }

    const getDialogActions = () => {
        return (
            <></>
        );
    };

    return (
        <CustomDialog
            open={open}
            TransitionComponent={Transition}
            PaperProps={dialogPaperProps}
            title={getTitleContent()}
            content={getDetailsContent()}
            actions={getDialogActions()}
            handleClose={handleDialogClose}
        ></CustomDialog>
    )
}

export default HubDetails;