import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { DriversDashboardState } from "./driversSlice.types";
import AppConstants from "../../constants";
import moment from "moment";
import { transformSelectValues, saveFile } from "../../utils/helpers.utils";

export const fetchDynamicFiltersOrdersDropdownValues = createAsyncThunk(
  "Drivers/FetchDynamicFiltersOrdersDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDriversListByFilter = createAsyncThunk(
  "Drivers/FetchDriversListByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'driver/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDriversFilterChipsDisplayCount = createAsyncThunk(
  "Drivers/FetchDriversFilterChipsDisplayCount",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'driver/displayFilterCount',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const releaseDriver = createAsyncThunk(
  "Drivers/ReleaseDriver",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'v2/driver/releaseStuckDriver',
        method: EApiMiddlewareMethods.POST,
      } as any;
      request.headers = params
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeDialog = createAsyncThunk("Drivers/CloseDialog", () => {
  return false;
});

export const driverAssignmentSuccess = createAsyncThunk("Drivers/DriverAssignmentSuccess", () => {
  return false;
});

export const userLogout = createAsyncThunk("UserLogin/Logout", () => {
  return false;
});

export const fetchDriverActivityLogs = createAsyncThunk(
  "Drivers/FetchDriverActivityLogs",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `driver/status/getDriverStatuses`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const markDriverAway = createAsyncThunk(
  "DriverDetails/MarkDriverAway",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'driver/manageStatus/web',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAssignmentMode = createAsyncThunk(
  "DriverDetails/UpdateAssignmentMode",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'driver/updateAssignmentMode',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "Drivers/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'driver/export',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error:any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: DriversDashboardState = {
  loading: false,
  tableLoading: false,
  exportError: "",
  driversFilterChipsDisplayCount: {
    driverStatusCount: {
      ACTIVE: 0,
      AVAILABLE: 0,
      ASSIGNED: 0,
      OUT_FOR_DELIVERY: 0,
      AWAY: 0,
      BACK_TO_STORE: 0,
      OFFLINE: 0
    }
  },
  driversFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    statusList: [],
    vehicleList: [],
    vendorList: [],
    modes: []
  },
  driversData: {
    elements: [],
    pageNumber: 0
  },
  error: "",
  errorCode: "",
  driverLogs: [],
  excelLoading: false,
  userUpdateStatus: false,
  userUpdateMsg: "",
  driversAfterMarkingAway: {},
  assignmentModeStatus: false,
  assignmentModeMsg: "",
  releaseDriverStatus: false,
  releaseDriverMsg:""
};

const DriversSlice = createSlice({
  name: "Drivers",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchDriversFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDriversFilterChipsDisplayCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driversFilterChipsDisplayCount = payload && payload.results;
      })
      .addCase(fetchDriversFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDriversListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDriversListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.userUpdateStatus = false;
        state.assignmentModeStatus = false;
        state.releaseDriverStatus = false;
        state.driversData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.results.elements]
              : [...state.driversData.elements, ...payload.results.elements],
          pageNumber: payload.results.pageNumber,
          totalElements: payload.results.totalElements,
          totalPages: payload.results.totalPages
        };
        if (payload && payload.results && payload.results.filters) {
          state.driversFiltersDropdowns = {
            hubCodeList: transformSelectValues(payload.results.filters.hubCodes || []),
            hubNameList: transformSelectValues(payload.results.filters.hubNames || []),
            statusList: transformSelectValues(payload.results.filters.status || []),
            vehicleList: transformSelectValues(payload.results.filters.vehicleType || []),
            vendorList: transformSelectValues(payload.results.filters.vendor || []),
            modes: transformSelectValues(payload.results.filters.modes || []),
          };
        }
      })
      .addCase(fetchDriversListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDriverActivityLogs.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDriverActivityLogs.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driverLogs = payload.results.statuses || [];
      })
      .addCase(fetchDriverActivityLogs.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.driverLogs = [];
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(markDriverAway.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(markDriverAway.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          if (payload.status === "SUCCESS") {
            state.userUpdateStatus = true;
            state.userUpdateMsg = payload.message;
          }
          state.driversAfterMarkingAway = payload && payload.results;
        }
      })
      .addCase(markDriverAway.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.userUpdateStatus = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateAssignmentMode.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateAssignmentMode.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          if (payload.status === "SUCCESS") {
            state.assignmentModeStatus = true;
            state.assignmentModeMsg = payload.message;
          }
          state.driversAfterMarkingAway = payload && payload.results;
        }
      })
      .addCase(updateAssignmentMode.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.assignmentModeStatus = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(releaseDriver.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(releaseDriver.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
          if (payload?.status === "SUCCESS") {
             state.releaseDriverStatus = true;
             state.releaseDriverMsg = payload?.message;
          }
          else{
            state.errorCode = AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
            state.error = payload
            ? payload?.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          }
      })
      .addCase(releaseDriver.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.releaseDriverStatus = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })

      
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Drivers_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(closeDialog.fulfilled, (state, action) => {
        state.driversAfterMarkingAway = {};
      })
  },
});

export default DriversSlice.reducer;