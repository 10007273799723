import moment from "moment";
import { RowData } from "../../common/GridWrapper/DataTable";
import AppConstants from "../../constants";
import {
  enumerateDaysBetweenDates,
  fixDecimals,
  getUniqueValues,
  getValueFromArray,
  localTimeString,
  transformIntoObj,
  transformIntoObjArrays,
  isUndefined,
  transformForScalable,
  displayUnitGrams,
  isNonEmptyArray,
  isNonEmptyObject,
  isNull,
  isObject,
} from "../../utils/helpers.utils";
import { ADDRESS_STATUS } from "../../constants/CommonConstants";
import { getSlotName } from "../../RoutePlanning/AllRoutes/utils/AllRouteUtils";
export interface MetaData {
  paymentType: string;
  totalAmount: string;
  transactionDate: Date;
  amountToCollect: string;
  collectedAmount: string;
  currency: string;
  category: string;
  deliveryType: string;
  modeOfCollection: string;
  invoiceNumber: string;
  internalExternal: string;
  origin: string;
  status: string;
  deliveryStatus: string;
  routeId: string;
  routeName: string;
  routeStatus: string;
  numberOfItems: number;
  lastStatusUpdatedTime: string;
  deliveryZone: string;
}

export interface Name {
  ar: string;
  en: string;
}

export interface Product {
  sku: string;
  entryNumber: string;
  hasRejectedProducts: boolean;
  name: Name;
  quantity: string;
  acceptedQty: string;
  unitPrice: string;
  imgUrl: string;
  vatPercentage: string;
  uom: string;
  orderedBarcode: string;
  associatedBarcodes: string[];
  substitution: boolean;
}

export interface Address {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  makani: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
  status: string;
}

export interface ContactInfo {
  name: string;
  phone: string;
  altphone: string;
  email: string;
  address: Address;
}

export interface PosInformation {
  store: string;
  storeName: string;
  contactInfo: ContactInfo;
}

export interface DeliverySlot {
  startTime: Date;
  endTime: Date;
  rescheduled ?: boolean;
}

export interface CustomerContactInfo {
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export const ROUTE_TYPE = {
  NORMAL_ROUTE: "NORMAL_ROUTE",
  PARENT_ROUTE: "PARENT_ROUTE",
  SUB_ROUTE: "SUB_ROUTE",
  ROUTE_AREA: "ROUTE_AREA",
};

export interface DeliveryInformation {
  deliveryInstructions: string;
  deliveryCost: string;
  carrier: string;
  carrierTrackingId: string;
  shipmentSize: string;
  totalCratesCount: number;
  deliverySlot: DeliverySlot;
  customerContactInfo: CustomerContactInfo;
  driver: Driver;
  helper: Driver;
  vehicle: Vehicle;
  transitTime: string;
  actualTransitTime: string;
  dynamicTransitTime: string;
  estimatedTransitTime: string;
  reason: Reason;
  deliveryExecutionStatus ?: string;
}

export interface Reason {
  deliveryStatus: string;
  reasonCode: string;
  reasonDescription: string;
}
export interface PickUpContactInfo {
  companyName: string;
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface PickupSlot {
  startTime: Date;
  endTime: Date;
}

export interface PickUpInformation {
  pickUpContactInfo: PickUpContactInfo;
  pickupSlot: PickupSlot;
}

export interface PaymentDetail {
  payBillNo: string;
  paymentPhoneNumber: string;
  uniqueReferenceNumber: string;
  transactionInitiatedTries: any;
  transactionStatus: any;
  timestamp: any;
  collectedAmount: string;
}

export interface Reason {
  deliveryStatus: string;
  reasonCode: string;
  reasonDescription: string;
}

export interface Order {
  id: string;
  orderCode: string;
  consignmentCode: string;
  hasRejectedProducts: boolean;
  retryCount: number;
  metadata: MetaData;
  products: Product[];
  reason: Reason;
  posInformation: PosInformation;
  deliveryInformation: DeliveryInformation;
  pickUpInformation: PickUpInformation;
  paymentDetail: PaymentDetail;
  statuses: Statuses[];
}

export interface CountryDetails {
  countryCode: string;
  countryName: string;
}

export interface AssignedStatus {
  vehicleAssigned: boolean;
  helperAssigned: boolean;
  driverAssigned: boolean;
  consignmentsAvailable: boolean;
}

export interface Vehicle {
  brand: string;
  country: string;
  crateCapacity: number;
  fleetId: string;
  id: string;
  orderCapacity: number;
  type: string;
  name: string;
}

export interface Driver {
  countryIsoCode: string;
  countryName: string;
  licenseNumber: number;
  id: string;
  name: string;
  type: string;
  vendor: string;
}

export interface ActualTransitDistance {
  totalDistanceMeters: number;
  formattedDistance: string;
}

export interface Route {
  id: string;
  routeId: string;
  creationTime: string;
  plannedDate: string;
  createdBy: string;
  carrier: string;
  deliveryProposition: string;
  deliveryArea: string[];
  deliverySlot: string[];
  countryDetails: CountryDetails;
  totalConsignment: number;
  totalCrates: number;
  transitTime: number;
  displayRouteStatus: string;
  hubCode: string;
  hubName: string;
  status: string;
  assignedStatus: AssignedStatus;
  assignedVehicle: Vehicle;
  assignee: Driver;
  helper: Driver;
  actualTransitTime: string;
  parentRouteId: string;
  routeType?: string;
  actualTransitDistance ?: ActualTransitDistance;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface PlanningOrdersDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: Order[];
}

export interface PlanningRoutesDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: Route[];
}
export interface Statuses {
  statusCode: string;
  statusName: string;
  statusDate: string;
  reason: Reason;
}

export interface SubsProducts {
  sku: string;
  name: Name;
  quantity: string;
  shippedQty: string;
  rejectedQty: string;
  unitPrice: string;
  vatPercentage: string;
  uom: string;
  reason: Reason;
}
export interface ProductsDataTransform {
  sku: string;
  name: Name;
  entryNumber: string;
  quantity: string;
  shippedQty: any;
  rejectedQty: string;
  unitPrice: string;
  vatPercentage: string;
  uom: string;
  reason: Reason;
  substitution: boolean;
  substitutedProducts: SubsProducts[];
}

export interface StandardProducts {
  products: ProductsDataTransform[];
}

interface NameObj {
  ar: string;
  en: string;
}

interface Metadata {
  bundleType: string;
  bundleName?: string;
  campaignId?: string;
  reason?: {
    reasonCode: string;
    reasonDescription: string;
  };
}

interface Item {
  sku: string;
  name: NameObj;
  quantity: string;
  shippedQty: string;
  acceptedQty?: string;
  rejectedQty: string;
  unitPrice: string;
  imgUrl: string;
  uom: string;
  associatedCrates?: any;
}

interface BundleProduct {
  sku: string;
  campaignId: string;
  name: Name;
  quantity: string;
  shippedQty: string;
  rejectedQty: string;
  unitPrice: string;
  imgUrl: string;
  uom: string;
  orderedBarcodes?: any;
  associatedBarcodes?: string[];
  lmMetadata: Metadata;
  items?: Item[];
}

interface TransformedBundleProduct {
  campaignId: string;
  bundleName: string;
  bundleShippedQty: string;
  bundlePrice: string;
  //bundleCurrency: string;
  bundleRejectedQty: string;
  bundleProductQuantity: string;
  bundleConstituents: {
    bundleProductName: string;
    bundleProductQuantity: string;
    bundleProductRejectedQty: string;
    bundleProductShippedQty: string;
  }[];
  reason?: {
    reasonCode: string;
    reasonDescription: string;
  };
}

interface TransformedBundleProductForRejection extends TransformedBundleProduct {
  id: string;
  rejectedQty: string;
  rejectedQtyList: { name: string; value: string }[];
  selectedRejectedQty: { name: string; value: string }[];
  itemDeliveryReason: { name: string; value: string }[];
  showReason: boolean;
  error: string;
}

export const lastMilePlanningOrdersDataTransform = (response: PlanningOrdersDataResponse): any[] => {
  const transformedArray: any[] = [];
  response.elements.forEach((order: Order) => {
    const {
      id,
      metadata,
      posInformation: { store, storeName },
      deliveryInformation,
      paymentDetail,
      orderCode,
      products,
      reason,
      consignmentCode,
      statuses,
    } = order;
    // TODO: map/add actual objects too for drilling down content or store in state
    const deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND);
    const startTime = new Date(deliveryInformation.deliverySlot.startTime);
    const endTime = new Date(deliveryInformation.deliverySlot.endTime);
    const customerContactInfo = deliveryInformation.customerContactInfo || { address: {} };
    const transactionDate = moment(metadata.transactionDate, AppConstants.DATE_FORMAT_BACKEND);
    const statusDate = metadata.lastStatusUpdatedTime
      ? moment(metadata.lastStatusUpdatedTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.TIMESTAMP)
      : statuses && statuses.length
      ? moment(statuses[0].statusDate, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.TIMESTAMP)
      : "";
    const mpesaTimestamp = paymentDetail ? moment(paymentDetail.timestamp, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.TIMESTAMP) : "";
    transformedArray.push({
      id: id,
      hubCode: store,
      hubName: storeName,
      deliveryArea: customerContactInfo.address ? customerContactInfo.address.area : "",
      consignmentId: consignmentCode,
      type: metadata.category,
      deliveryDate: deliveryDate.format(AppConstants.DATE_FORMAT),
      startEndTime: `${localTimeString(startTime)} - ${localTimeString(endTime)}`,
      deliveryStatus: metadata.deliveryStatus,
      statusDate: statusDate,
      products: products && products.length ? products.length : 0,
      numberOfItems: metadata.numberOfItems && metadata.numberOfItems > 0 ? metadata.numberOfItems : products && products.length ? products.length : 0,
      crates: deliveryInformation.totalCratesCount,
      orderId: orderCode,
      transactionDate: transactionDate.format(AppConstants.DATE_FORMAT),
      customerName: customerContactInfo.name,
      customerEmail: customerContactInfo.email,
      customerMobileNumber: customerContactInfo.phone,
      deliveryStreetAddress: getAddressInfo(customerContactInfo.address),
      landmark: customerContactInfo.address.landmark,
      paymentType: metadata.paymentType,
      totalAmount: fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : ""),
      codAmount: fixDecimals(2, metadata.amountToCollect ? metadata.amountToCollect.toString() : ""),
      collectedAmount: metadata.collectedAmount ? parseFloat(metadata.collectedAmount.toString()).toFixed(2) : "0.00",
      carrier: deliveryInformation.carrier,
      deliveryProposition: metadata.deliveryType,
      routeId: metadata.routeName,
      routeStatus: metadata.routeStatus,
      driver: deliveryInformation.driver ? deliveryInformation.driver.name : "",
      deliveryZone: metadata && metadata?.deliveryZone ? metadata?.deliveryZone : "",
      vendor: deliveryInformation.driver ? deliveryInformation.driver?.vendor : "",
      helper: deliveryInformation.helper ? deliveryInformation.helper.name : "",
      vehicle: deliveryInformation.vehicle ? deliveryInformation.vehicle.name : "",
      plannedDuration: deliveryInformation.dynamicTransitTime ? deliveryInformation.dynamicTransitTime : deliveryInformation.estimatedTransitTime,
      actualDuration: deliveryInformation.actualTransitTime ? deliveryInformation.actualTransitTime : "",
      deliveryFailedReason: deliveryInformation?.reason ? deliveryInformation?.reason?.reasonDescription : "",
      paybill: paymentDetail ? paymentDetail.payBillNo : "",
      mpesaphoneNumber: paymentDetail ? paymentDetail.paymentPhoneNumber : "",
      refNo: paymentDetail ? paymentDetail.uniqueReferenceNumber : "",
      address: customerContactInfo && customerContactInfo.address ? getAddressInfo(customerContactInfo.address) : "-",
      requestedAmt: paymentDetail ? paymentDetail.collectedAmount : "",
      mpesaAttemptNo: paymentDetail ? paymentDetail.transactionInitiatedTries : "",
      mpesaStatus: paymentDetail ? paymentDetail.transactionStatus : "",
      timestamp: paymentDetail ? mpesaTimestamp : "",
      addressStatus: customerContactInfo && customerContactInfo?.address?.status ? customerContactInfo.address.status : "",
      rescheduled : deliveryInformation?.deliverySlot?.rescheduled || false,
      deliveryExecutionStatus : deliveryInformation?.deliveryExecutionStatus
    });
  });
  return [...transformedArray];
};

export const lastMilePlanningRoutesDataTransform = (response: PlanningRoutesDataResponse): any[] => {
  const transformedArray: any[] = [];
  response.elements.forEach((order: Route) => {
    const {
      id,
      routeId,
      hubCode,
      hubName,
      carrier,
      deliveryProposition,
      transitTime,
      deliveryArea,
      deliverySlot,
      plannedDate,
      status,
      totalConsignment,
      assignedVehicle,
      assignee,
      helper,
      createdBy,
      totalCrates,
      actualTransitTime,
      parentRouteId,
      routeType = "",
      actualTransitDistance,
    } = order;
    // TODO: map/add actual objects too for drilling down content or store in state
    const deliveryDate = moment(plannedDate, AppConstants.DATE_FORMAT_BACKEND);
    transformedArray.push({
      id: id,
      hubCode: hubCode,
      hubName: hubName,
      deliveryArea: deliveryArea && deliveryArea.length ? deliveryArea : [],
      deliveryDate: deliveryDate.format(AppConstants.DATE_FORMAT),
      deliverySlot: deliverySlot && deliverySlot.length ? deliverySlot : [],
      consignments: totalConsignment,
      crates: totalCrates,
      carrier: carrier,
      deliveryProposition: deliveryProposition,
      routeId: routeId,
      routeStatus: status,
      driver: assignee ? assignee.name : "",
      vehicle: assignedVehicle ? assignedVehicle.name : "",
      helper: helper ? helper.name : "",
      createdBy: createdBy,
      plannedDuration: transitTime,
      actualDuration: actualTransitTime,
      actions: order,
      parentRouteId: parentRouteId,
      routeType: routeType,
      actualDistance: actualTransitDistance?.formattedDistance || "",
    });
  });
  return [...transformedArray];
};

export const getConsignmentStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
};

export const getRouteStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
};

export const rejectionDataTransform = (response: any) => {
  const transformedArray: any[] = [];
  response &&
    response.forEach((product: ProductsDataTransform) => {
      const { sku, entryNumber, rejectedQty, name, unitPrice } = product;
      let showReason = false;
      let qty = product.quantity;
      let isSubstitution = product.hasOwnProperty("substitutedProducts");

      let isScalable = isSubstitution
        ? product?.substitutedProducts?.length && product.substitutedProducts[0].uom
          ? product.substitutedProducts[0].uom?.toLowerCase() === "grams"
          : product.uom?.toLowerCase() === "grams"
        : product.uom?.toLowerCase() === "grams";

      let totalQty = `${qty} ${product.uom && product.uom === "Grams" ? displayUnitGrams(product.uom) : ""}`;

      const rejectedItems = isSubstitution
        ? (product && product.substitutedProducts && product.substitutedProducts.length && +product.substitutedProducts[0].rejectedQty) || 0
        : +product.rejectedQty || 0;

      const initialQty = isScalable ? (rejectedItems > 0 ? 1 : 0) : rejectedItems;

      let shipedQty = product.substitutedProducts && product.substitutedProducts.length ? product.substitutedProducts[0].shippedQty : product.shippedQty;

      let shipedQtyDisplay =
        (product.substitutedProducts && product.substitutedProducts.length && Number(product.substitutedProducts[0].shippedQty) === 0) || Number(product.shippedQty) === 0
          ? 0
          : isScalable
          ? 1
          : product.substitutedProducts && product.substitutedProducts.length
          ? product.substitutedProducts[0].shippedQty
          : product.shippedQty
          ? product.shippedQty
          : 0;

      let newObj: any = [];
      let selectRejected = [];
      if (isScalable) {
        for (let i = 0; i <= shipedQtyDisplay; i++) {
          newObj = transformForScalable(shipedQty, shipedQtyDisplay);
        }
      } else {
        for (let i = 0; i <= shipedQty; i++) {
          newObj = transformIntoObjArrays(i, shipedQty);
        }
      }
      if (initialQty === 0) {
        selectRejected = transformIntoObj(initialQty);
      } else {
        selectRejected = transformIntoObj(initialQty);
      }
      let deliveryReason = [];
      if (product.reason) {
        deliveryReason.push({
          name: product.reason.reasonDescription,
          value: product.reason.reasonCode,
        });
      } else {
        if (product.substitutedProducts) {
          product.substitutedProducts.forEach((subProd: any) => {
            if (subProd.reason) {
              deliveryReason.push({
                name: subProd.reason.reasonDescription,
                value: subProd.reason.reasonCode,
              });
            }
          });
        }
      }
      const uom = isSubstitution
        ? product?.substitutedProducts?.length && product.substitutedProducts[0]?.uom
          ? product.substitutedProducts[0].uom?.toLowerCase() === "grams"
            ? displayUnitGrams(product.substitutedProducts[0].uom)
            : ""
          : product.uom?.toLowerCase() === "grams"
          ? displayUnitGrams(product.uom)
          : ""
        : product.uom?.toLowerCase() === "grams"
        ? displayUnitGrams(product.uom)
        : "";
      //Logic to showReason only when the rejectedQty is not zero
      if (rejectedQty !== "0" && !isUndefined(rejectedQty)) {
        showReason = true;
      }
      transformedArray.push({
        id: sku,
        name: name,
        entryNumber: entryNumber,
        shippedQty: shipedQty || 0,
        rejectedQty: rejectedQty || 0,
        unitPrice: unitPrice,
        totalQty: totalQty,
        rejectedQtyList: newObj,
        selectedRejectedQty: selectRejected,
        itemDeliveryReason: deliveryReason,
        substitutedProducts: product.substitutedProducts,
        showReason: showReason,
        error: "",
        uom: uom,
      });
    });
  return [...transformedArray];
};

export const bundleRejectionDataTransform = (response: TransformedBundleProduct[]) => {
  const transformedArray: TransformedBundleProductForRejection[] = [];
  response &&
    response.forEach((product: any) => {
      const { campaignId, bundleRejectedQty, bundleShippedQty } = product;

      let showReason = false;
      const rejectedItems = bundleRejectedQty || 0;
      const initialQty = rejectedItems;

      const shipedQty = bundleShippedQty;
      let newObj: any = [];
      let selectRejected = [];
      for (let i = 0; i <= shipedQty; i++) {
        newObj = transformIntoObjArrays(i, shipedQty);
      }
      selectRejected = transformIntoObj(initialQty);
      const deliveryReason = [];
      if (product.reason) {
        deliveryReason.push({
          name: product.reason.reasonDescription,
          value: product.reason.reasonCode,
        });
      }
      //Logic to showReason only when the rejectedQty is not zero
      if (bundleRejectedQty !== "0" && !isUndefined(bundleRejectedQty) && !bundleRejectedQty.includes("-")) {
        showReason = true;
      }
      transformedArray.push({
        ...product,
        id: campaignId,
        rejectedQty: rejectedItems,
        rejectedQtyList: newObj,
        selectedRejectedQty: selectRejected,
        itemDeliveryReason: deliveryReason,
        showReason: showReason,
        error: "",
      });
    });
  return [...transformedArray];
};

export const sortedCountryData = (columnName: string, isDescending: boolean) => (a: any, b: any) => {
  const aVal = isDescending ? a : b;
  const bVal = isDescending ? b : a;
  if (aVal[columnName].match(/^[0-9]+$/) && bVal[columnName].match(/^[0-9]+$/)) {
    return parseFloat(aVal[columnName]) - parseFloat(bVal[columnName]);
  } else {
    if (aVal[columnName] < bVal[columnName]) {
      return -1;
    } else if (aVal[columnName] > bVal[columnName]) {
      return 1;
    } else {
      return 0;
    }
  }
};

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
  let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
  const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
  const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
  if (selectAllIdx > -1) {
    currentValue = [];
  }
  if (selectNoneIdx > -1) {
    currentValue = [];
  }
  return currentValue;
};

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, isExpress?: any, isRoute?: boolean) => {
  payload.attributes = [];
  if (payload.hub) {
    if (payload.hub.value) {
      let hubValue = payload.hub.value.map((item: any) => item.value);
      let hubNameValue = [];
      if (payload.hubName && payload.hubName.value.length) {
        hubNameValue = payload.hubName.value.map((item: any) => item.value);
      }
      hubValue = hubValue.concat(hubNameValue);
      const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        hubValue = [];
      }
      if (selectNoneIdx > -1) {
        hubValue = [];
      }
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: getUniqueValues(hubValue),
      });
      delete payload.hub;
      delete payload.hubName;
    } else {
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: [],
      });
      delete payload.hub;
      delete payload.hubName;
    }
  }
  if (payload.deliveryArea) {
    let attrValue = [];
    if (payload.deliveryArea.value) {
      attrValue = getFilterAttributeValue(payload.deliveryArea.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryArea.fieldName,
      value: attrValue,
    });
    delete payload.deliveryArea;
  }
  if (payload.addressStatus) {
    let attrValue = [];
    if (payload.addressStatus.value) {
      attrValue = getFilterAttributeValue(payload.addressStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.addressStatus.fieldName,
      value: attrValue,
    });
    delete payload.addressStatus;
  }
  if (payload.deliveryZone) {
    let attrValue = [];
    if (payload.deliveryZone.value) {
      attrValue = getFilterAttributeValue(payload.deliveryZone.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryZone.fieldName,
      value: attrValue,
    });
    delete payload.deliveryZone;
  }
  if (payload.vendor) {
    let attrValue = [];
    if (payload.vendor.value) {
      attrValue = getFilterAttributeValue(payload.vendor.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.vendor.fieldName,
      value: attrValue,
    });
    delete payload.vendor;
  }

  if (payload.orderType) {
    let attrValue = [];
    if (payload.orderType.value) {
      attrValue = getFilterAttributeValue(payload.orderType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.orderType.fieldName,
      value: attrValue,
    });
    delete payload.orderType;
  }
  if (payload.deliveryStatus) {
    let attrValue = [];
    if (payload.deliveryStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryStatus.fieldName,
      value: attrValue,
    });
    delete payload.deliveryStatus;
  }
  if (payload.paymentType) {
    let attrValue = [];
    if (payload.paymentType.value) {
      attrValue = getFilterAttributeValue(payload.paymentType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.paymentType.fieldName,
      value: attrValue,
    });
    delete payload.paymentType;
  }
  if (payload.carrier) {
    let attrValue = [];
    if (payload.carrier.value) {
      attrValue = getFilterAttributeValue(payload.carrier.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.carrier.fieldName,
      value: attrValue,
    });
    delete payload.carrier;
  }
  if (isExpress) {
    if (payload.deliveryProposition) {
      payload.attributes.push({
        key: payload.deliveryProposition.fieldName,
        value: ["EXP"],
      });
      delete payload.deliveryProposition;
    }
  } else {
    if (payload.deliveryProposition) {
      /* let attrValue = [];
      if (payload.deliveryProposition.value) {
        attrValue = getFilterAttributeValue(payload.deliveryProposition.value);
      } else {
        attrValue = [];
      } */
      payload.attributes.push({
        key: payload.deliveryProposition.fieldName,
        value: ["STD"],
      });
      delete payload.deliveryProposition;
    }
  }
  if (payload.routeId) {
    let attrValue = [];
    if (payload.routeId.value) {
      attrValue = getFilterAttributeValue(payload.routeId.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeId.fieldName,
      value: attrValue,
    });
    delete payload.routeId;
  }
  if (payload.routeStatus) {
    let attrValue = [];
    if (payload.routeStatus.value) {
      attrValue = getFilterAttributeValue(payload.routeStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeStatus.fieldName,
      value: attrValue,
    });
    delete payload.routeStatus;
  }
  if (payload.driver) {
    let attrValue = [];
    if (payload.driver.value) {
      attrValue = getFilterAttributeValue(payload.driver.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.driver.fieldName,
      value: attrValue,
    });
    delete payload.driver;
  }
  if (payload.vehicle) {
    let attrValue = [];
    if (payload.vehicle.value) {
      attrValue = getFilterAttributeValue(payload.vehicle.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.vehicle.fieldName,
      value: attrValue,
    });
    delete payload.vehicle;
  }
  if (payload.helper) {
    let attrValue = [];
    if (payload.helper.value) {
      attrValue = getFilterAttributeValue(payload.helper.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.helper.fieldName,
      value: attrValue,
    });
    delete payload.helper;
  }
  if (payload.deliveryFailedReason) {
    let attrValue = [];
    if (payload.deliveryFailedReason.value) {
      attrValue = getFilterAttributeValue(payload.deliveryFailedReason.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryFailedReason.fieldName,
      value: attrValue,
    });
    delete payload.deliveryFailedReason;
  }
  if (payload.createdBy) {
    let attrValue = [];
    if (payload.createdBy.value) {
      attrValue = getFilterAttributeValue(payload.createdBy.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.createdBy.fieldName,
      value: attrValue,
    });
    delete payload.createdBy;
  }
  if (payload.displayRouteStatus) {
    payload.attributes.push({
      key: payload.displayRouteStatus.fieldName,
      value: payload.displayRouteStatus.value || [],
    });
    delete payload.displayRouteStatus;
  }
  if (payload.deliverySlot && payload.deliverySlot.value) {
    let delSlotValue = payload.deliverySlot.value.map((item: any) => item.value);
    let dateRange = [];
    const selectAllIdx = delSlotValue.indexOf(AppConstants.SELECT_ALL.value);
    if (payload.dateRange && payload.dateRange.value) {
      const allDates = enumerateDaysBetweenDates(payload.dateRange.value.startDate, payload.dateRange.value.endDate);
      if (selectAllIdx > -1 || !delSlotValue.length) {
        payload.isDateRange = true;
        dateRange.push({
          startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
        });
      } else {
        payload.isDateRange = false;
        delSlotValue.forEach((slot: any) => {
          const splitSlot = slot.split("-");
          const startSlot = splitSlot[0].split(":");
          const endSlot = splitSlot[1].split(":");
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              });
            });
          }
        });
      }
      if (payload.reset) {
        delete payload.isDateRange;
      }
    }
    payload.dateRange = dateRange;
    delete payload.displayRouteStatus;
    delete payload.deliverySlot;
    delete payload.reset;
  }
  if (payload.deliveryExecutionStatus) {
    let attrValue = [];
    if (payload.deliveryExecutionStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryExecutionStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryExecutionStatus.fieldName,
      value: attrValue,
    });
    delete payload.deliveryExecutionStatus;
  }
  if (isLoadMore && data) {
    payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
  }
  if (data.continuationToken && payload.pageNumber) {
    payload.requestContinuationToken = data.continuationToken;
  }
  if (isExpress) {
    payload.isExpress = true;
  }
  return payload;
};

export const createPayloadForExcelExport = (payload: any, data?: any, isLoadMore?: boolean, isExpress?: boolean, isRoute?: boolean) => {
  let newPayload = createFilterPayload(payload, data, isLoadMore, isExpress, isRoute);
  var orderFilterRequestDto = { orderFilterRequestDto: newPayload };
  return orderFilterRequestDto;
};

export const createRoutesPayloadForExcelExport = (payload: any, data?: any, isLoadMore?: boolean, isExpress?: boolean, isRoute?: boolean) => {
  let newPayload = createFilterPayload(payload, data, isLoadMore, isExpress, isRoute);
  var routeFilterRequestDto = { routeFilterRequestDto: newPayload };
  return routeFilterRequestDto;
};

export const createCratesInfoPayload = (payload: any) => {
  if (payload) {
    let consignmentCode;
    let storeCode;
    if (payload.consignmentCode) {
      consignmentCode = payload.consignmentCode;
    }
    if (payload.posInformation) {
      storeCode = payload.posInformation.store;
    }
    var consignmentCodePosCodeMap = {
      consignmentCodePosCodeMap: {
        [consignmentCode]: storeCode,
      },
    };
    return consignmentCodePosCodeMap;
  }
};

export const createDraftRoutePayload = (payload: any) => {
  const routeId = sessionStorage.getItem("routeId");
  const consignments = JSON.parse(sessionStorage.getItem("extraConsignments") || "[]");
  let responsePayload: any;
  if (payload.isExpress) {
    responsePayload = {
      countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      hubCode: payload.hubCode,
      hubName: payload.hubName,
      isExpress: payload.isExpress,
    };
  } else {
    responsePayload = {
      countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      hubCode: payload.hubCode,
      hubName: payload.hubName,
    };
  }
  if (payload.slotValue) {
    let dateRange = [];
    const selectAllIdx = payload.slotValue.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.slotValue.indexOf(AppConstants.SELECT_NONE.value);
    if (payload.date) {
      const allDates = enumerateDaysBetweenDates(payload.date.startDate, payload.date.endDate);
      if (selectAllIdx > -1 || selectNoneIdx > -1 || !payload.slotValue.length) {
        responsePayload.isDateRange = true;
        dateRange.push({
          startTime: moment(payload.date.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(payload.date.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
        });
      } else {
        responsePayload.isDateRange = false;
        payload.slotValue.forEach((slot: any) => {
          const splitSlot = slot.split("-");
          const startSlot = splitSlot[0].split(":");
          const endSlot = splitSlot[1].split(":");
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              });
            });
          }
        });
      }
    }
    responsePayload.timeSlot = dateRange;
  }
  if (payload.zoneValue) {
    responsePayload.deliveryZones = getFilterAttributeValue(payload.zoneValue, true);
  }
  if (payload.editMode) {
    responsePayload.editMode = true;
  }
  if (payload.statusValue) {
    responsePayload.deliveryStatuses = getFilterAttributeValue(payload.statusValue, true);
  }
  if (routeId) {
    if (!payload.editMode) {
      if (consignments.length) {
        responsePayload.route = { id: routeId };
      }
    } else {
      responsePayload.route = { id: routeId };
    }
    if (consignments.length) {
      responsePayload.consignmentIds = consignments;
    }
    if(responsePayload?.route?.id && payload?.routeType){
      responsePayload.route.routeType = payload.routeType;
    }
  }
  return responsePayload;
};

export const getDiscardRoutePayload = (params: any) => {
  const routeId = sessionStorage.getItem("routeId");
  const consignments = JSON.parse(sessionStorage.getItem("extraConsignments") || "[]");
  const previousConsignments = params.previousCons;
  let responsePayload: any = {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    routeId: routeId || "",
    previousConsignments: previousConsignments,
  };
  if (consignments.length) {
    responsePayload.consignmentsAdded = consignments;
  }
  return responsePayload;
};

export const createPublishRoutesPayload = (routes: RowData[]) => {
  return routes.length
    ? {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        ids: routes.map((route: RowData) => route.id),
      }
    : null;
};

/* export const createConsignmentReplanningPayload = (params: any) => {
  let payload: any = {
    consignmentId: params.id
  };
  if (params.selection === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION) {
    payload.reScheduled = true;
    const splitSlot = params.slot.split('-');
    const startSlot = splitSlot[0].split(':');
    const endSlot = splitSlot[1].split(':');
    payload.timeSlot = {
      startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT_UI).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT_UI).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
    }
  } else {
    payload.reScheduled = false;
    payload.comments = params.commentsValue;
    payload.reason = params.reason ? {
      reasonCode: params.reason.reasonCode,
      reasonDescription: params.reason.reasonDescription,
      deliveryStatus: params.reason.deliveryStatus
    } : {}
  }
  return payload;
} */

export const createConsignmentReplanningPayload = (params: any) => {
  let payload: any = {
    consignmentId: params.id,
  };
  if (params.status === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION) {
    payload.reScheduled = true;
    const splitSlot = params.slot.split("-");
    const startSlot = splitSlot[0].split(":");
    const endSlot = splitSlot[1].split(":");
    payload.timeSlot = {
      startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT_UI).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT_UI).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
    };
  } else {
    payload.reScheduled = false;
    payload.status = params.selection;
    payload.comments = params.commentsValue;
    payload.reason = params.reason
      ? {
          reasonCode: params.reason.reasonCode,
          reasonDescription: params.reason.reasonDescription,
          deliveryStatus: params.reason.deliveryStatus,
        }
      : {};
  }
  return payload;
};

export const createPayloadForCalculatingLineItems = (params: any, consignment: any) => {
  let payload: any = {};
  let lineItems: any = {};
  if (params.length > 0) {
    params.forEach((item: any) => {
      lineItems[item.entryNumber] = {
        rejectedQty: item.rejectedQty,
      };
    });
  }
  payload.consignmentCode = consignment.consignmentCode;
  payload.rejectedLineItems = lineItems;
  return payload;
};

export const createPayloadForUpdatingLineItems = (params: any, consignment: any) => {
  let payload: any = {};
  let prodLineItems: any = {};
  if (params.length > 0) {
    params.forEach((item: any) => {
      prodLineItems[item.entryNumber] = {
        entryNumber: item.entryNumber,
        rejectedQty: item.rejectedQty,
        sku: item.substitutedProducts?.length === 1 ? item.substitutedProducts[0]?.sku : item.id,
        reason: {
          reasonCode: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].value : "",
          reasonDescription: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].name : "",
        },
      };
    });
  }
  payload.consignmentCode = consignment.consignmentCode;
  payload.lineItems = prodLineItems;
  return payload;
};

export const createPayloadGetAllSlots = (pos: string, deliveryZone: string) => {
  return {
    orderType: "slotted",
    pos: pos,
    zone: deliveryZone,
  };
};

export const createPayloadForReplanDuringPartialRejection = (params: any, consignment: any) => {
  let payload: any = {};
  let prodLineItems: any = {};
  let lineItems: any = {};
  if (params.length > 0) {
    params.forEach((item: any) => {
      lineItems[item.entryNumber] = {
        rejectedQty: item.rejectedQty,
      };
    });
  }
  if (params.length > 0) {
    params.forEach((item: any) => {
      prodLineItems[item.entryNumber] = {
        entryNumber: item.entryNumber,
        rejectedQty: item.rejectedQty,
        sku: item.substitutedProducts?.length === 1 ? item.substitutedProducts[0]?.sku : item.id,
        reason: {
          reasonCode: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].value : "",
          reasonDescription: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].name : "",
        },
      };
    });
  }

  payload.consignmentCode = consignment.consignmentCode;
  payload.lineItems = prodLineItems;
  payload.rejectedLineItems = lineItems;
  payload.reScheduled = false;
  payload.status = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.DELIVERED;
  payload.comments = "";
  payload.reason = params.reason
    ? {
        reasonCode: params.reason.reasonCode,
        reasonDescription: params.reason.reasonDescription,
        deliveryStatus: params.reason.deliveryStatus,
      }
    : {};
  return payload;
};

export const createPayloadBundlePartialRejection = (params: TransformedBundleProductForRejection[]) => {
  let payload: any = {};
  let prodLineItems: any = {};
  let bundleLineItems: any = {};
  if (params?.length > 0) {
    params.forEach((item: any) => {
      if (item?.rejectedQty > 0) {
        bundleLineItems[item.id] = {
          rejectedQty: item.rejectedQty,
        };
      }
    });
  }
  if (params?.length > 0) {
    params.forEach((item: any) => {
      if (item?.rejectedQty > 0) {
        prodLineItems[item.id] = {
          campaignId: item.id,
          rejectedQty: item.rejectedQty,
          reason: {
            reasonCode: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].value : "",
            reasonDescription: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].name : "",
          },
        };
      }
    });
  }
  payload.bundleLineItems = prodLineItems;
  payload.rejectedBundleLineItems = bundleLineItems;
  return payload;
};

export const createDeleteRoutePayload = (id: string) => {
  return id
    ? {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        id: id,
      }
    : null;
};

export const createBulkDeleteRoutePayload = (routes: Array<any>) => {
  return routes.length
    ? {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        routeIds: routes.map(({ id }) => id),
      }
    : null;
};

export const createRouteCreationPayload = (payload: any, editMode?: any) => {
  const isParentRoute = payload?.route.routeType === "PARENT_ROUTE";
  let transitTime: any = calculateTransitTimeForConsignments(payload.mapRouteDirections, payload.consignments);
  let routePayload: any = {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    route: {
      id: payload.route.id,
      creationTime: payload.route.creationTime,
      createdBy: payload.route.createdBy,
      countryDetails: payload.route.countryDetails,
      hubCode: payload.route.hubCode,
      hubName: payload.route.hubName,
      deliveryArea: getUniqueValues(payload.consignments, "area"),
      deliverySlot: getUniqueValues(payload.consignments, "slot"),
      totalConsignment: payload.consignments.length,
      totalCrates: getTotalCrates(payload.consignments),
      deliveryDate: moment(payload.date, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      plannedDate: payload.route.plannedDate,
      transitTime: payload.transitTime,
      consignmentsIds: isParentRoute ? payload?.route?.consignmentsIds : payload.consignments.map((item: any) => item.code),
    },
    consignmentTransitTime: transitTime,
  };
  if (payload.selectedDriver !== AppConstants.SELECT_NONE.value) {
    routePayload.route.assignee = {
      id: payload.selectedDriver,
    };
  }
  if (payload.selectedHelper !== AppConstants.SELECT_NONE.value) {
    routePayload.route.helper = {
      id: payload.selectedHelper,
    };
  }
  if (payload.selectedVehicle !== AppConstants.SELECT_NONE.value) {
    routePayload.route.assignedVehicle = {
      id: payload.selectedVehicle,
    };
  }
  return routePayload;
};

export const createGetAllDropdownsPayload = (params: any) => {
  return {
    timeSlot: {
      startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
    },
    hubCode: params.hubCode,
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
  };
};

export const createGetAllPlannedRoutesPayload = (params: any) => {
  if (params.isExpress) {
    return {
      timeSlot: {
        startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
        endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
      },
      countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      hubCode: params.hubCode,
      isExpress: params.isExpress,
    };
  } else {
    return {
      timeSlot: {
        startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
        endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
      },
      countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      hubCode: params.hubCode,
    };
  }
};

export const createUpdateOrderStatusPayload = (params: any) => {
  return {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    consignmentIds: params.consignments,
    displayRouteStatus: params.status,
    routeId: params.routeId,
  };
};

export const getPlannedRoutesData = (routeData: any) => {
  let plannedSlots = [];
  if (routeData.PLANNED) {
    const plannedValue: any = routeData.PLANNED;
    for (let slotKey in plannedValue) {
      const slotValue: any = plannedValue[slotKey];
      let slot: any = {
        code: slotKey,
        value: slotValue.slotValue,
        data: [],
      };
      let totalConsignments = 0;
      let totalCrates = 0;
      const elements: any = slotValue.elements.map((route: any) => {
        totalConsignments += route.totalConsignment;
        totalCrates += route.totalCrates;
        return {
          code: route.id,
          value: route.routeId,
          data: route,
          slot: slotKey,
          slotValue: slotValue.slotValue,
        };
      });
      slot.data = elements;
      slot.totalConsignments = totalConsignments;
      slot.totalCrates = totalCrates;
      plannedSlots.push(slot);
    }
  }
  return plannedSlots;
};

export const getConsignmentData = async (draftRouteData: any) => {
  let areas: any[] = [];
  let consignments: any[] = [];
  let slots: any = {};
  if (draftRouteData) {
    Object.keys(draftRouteData).forEach((statusKey: string) => {
      const statusValue: any = draftRouteData[statusKey];
      Object.keys(statusValue).forEach((areaKey: string) => {
        const areaIndex: number = areas.findIndex((area: any) => area.code === areaKey);
        let area: any =
          areaIndex === -1
            ? {
                code: areaKey,
                value: areaKey,
                data: [],
              }
            : { ...areas[areaIndex] };
        const areaValue: any = statusValue[areaKey];
        Object.keys(areaValue).forEach((slotKey: string) => {
          const slotValue: any = areaValue[slotKey];
          const slotIndex: number = areaIndex !== -1 ? area.data.findIndex((slot: any) => slot.code === slotKey) : -1;
          let slot: any =
            slotIndex === -1
              ? {
                  code: slotKey,
                  value: slotValue.slotValue,
                  area: areaKey,
                  data: [],
                }
              : { ...area.data[slotIndex] };
          const elements: any = slotValue.elements.map((consignment: any) => {
            return {
              code: consignment.id,
              value: consignment.id,
              data: consignment,
              slot: slotKey,
              slotValue: slotValue.slotValue,
              area: areaKey,
              status: statusKey,
            };
          });
          if (slotIndex !== -1) {
            slot.data = [...slot.data, ...elements];
            area.data[slotIndex] = slot;
          } else {
            slot.data = elements;
            area.data.push(slot);
          }
          if (slots[area.code]) {
            slots[area.code][slot.code] = slot.data;
          } else {
            slots[area.code] = {
              [slot.code]: slot.data,
            };
          }
          consignments = [...consignments, ...elements];
        });
        if (areaIndex !== -1) {
          areas[areaIndex] = area;
        } else {
          areas.push(area);
        }
      });
    });
  }
  return {
    areaWiseConsignments: areas,
    allConsignments: consignments,
    slotwiseConsignments: slots,
  };
};

export const transformRouteConsignments = (consignments: any[]) => {
  let routeConsignments: any = {};
  let seqConsignments: any = [];
  consignments.forEach((consignment: any) => {
    if(consignment.id){
      let newConsignment: any = {
        code: consignment.id,
        value: consignment.id,
        data: consignment,
        area: consignment.deliveryInformation && consignment.deliveryInformation.customerContactInfo && consignment.deliveryInformation.customerContactInfo.address.area,
        slot: consignment.deliveryInformation && consignment.deliveryInformation.deliverySlot.deliverySlotTime,
      };
      seqConsignments.push(consignment.id);
      if (routeConsignments[newConsignment.area]) {
        if (routeConsignments[newConsignment.area][newConsignment.slot]) {
          routeConsignments[newConsignment.area][newConsignment.slot].push(newConsignment);
        } else {
          routeConsignments[newConsignment.area][newConsignment.slot] = [newConsignment];
        }
      } else {
        routeConsignments[newConsignment.area] = {
          [newConsignment.slot]: [newConsignment],
        };
      }
    }
  });
  return {
    routeConsignments: routeConsignments,
    seqConsignments: seqConsignments,
  };
};

export const getAllAreasAndSlots = (areas: any[]) => {
  let allAreas: string[] = [];
  areas.forEach((area: any) => {
    const slots = area.data;
    slots.forEach((slot: any) => {
      allAreas.push(`${area.code}-${slot.code}`);
    });
    allAreas.push(area.code);
  });
  return allAreas;
};

export const getDropdownsData = (payload: any, codeKey?: string, valueKey?: string) => {
  return payload.length
    ? payload.map((item: any) => {
        return {
          code: codeKey ? item[codeKey] : item.id,
          value: valueKey ? item[valueKey] : item.name,
        };
      })
    : [];
};

export const getTotalCrates = (consignments: any[]) => {
  let totalCrates = 0;
  if (consignments.length) {
    consignments.forEach((item: any) => {
      if (item.data && item.data.deliveryInformation) {
        totalCrates += parseInt(item.data.deliveryInformation.totalCratesCount);
      }
    });
  }
  return totalCrates;
};

export const calculateTransitTime = (directions: any) => {
  let transitTime = 0;
  if (directions.legs && directions.legs.length) {
    directions.legs.forEach((leg: any) => {
      transitTime += leg.duration ? leg.duration.value : 0;
    });
    transitTime += AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.DRIVER_SERVICE_TIME * directions.legs.length;
  }
  return transitTime;
};

export const calculateTransitTimeForConsignments = (directions: any, consignments: any) => {
  let consignmentIdsTransitTime: any = {};
  if (directions.legs && directions.legs.length) {
    for (var i = 0; i < consignments.length; i++) {
      if (directions.legs[i]) {
        consignmentIdsTransitTime[consignments[i].code] = directions.legs[i].duration.value;
      } else {
        consignmentIdsTransitTime[consignments[i].code] = 0;
      }
    }
  }
  return consignmentIdsTransitTime;
};

export const getAddressInfo = (addressObj: Address) => {
  return addressObj
    ? `${addressObj.building ? addressObj.building + ", " : ""}${addressObj.apartment ? addressObj.apartment + ", " : ""}${addressObj.zoneNumber ? addressObj.zoneNumber + ", " : ""}${
        addressObj.makani ? addressObj.makani + ", " : ""
      }${addressObj.streetNumber ? addressObj.streetNumber + ", " : ""}${addressObj.streetName ? addressObj.streetName + ", " : ""}${addressObj.area ? addressObj.area + ", " : ""}${
        addressObj.landmark ? addressObj.landmark + ", " : ""
      }${addressObj.zoneNumber ? addressObj.zoneNumber : ""}
  ${addressObj.city ? addressObj.city + ", " : ""}${addressObj.postalCode ? addressObj.postalCode + ", " : ""}${addressObj.countryIsoCode ? addressObj.countryIsoCode : ""}`
    : "";
};

export const getCratesFromProducts = (items: any) => {
  let crates: any = [];
  items &&
    items.forEach((item: any) => {
      if (item.substitutedProducts && item.substitutedProducts.length) {
        item.substitutedProducts &&
          item.substitutedProducts
            .filter(({ shippedQty }: any) => {
              return +shippedQty > 0;
            })
            .forEach((element: any) => {
              if (element.associatedCrates) {
                let productCrates = element.associatedCrates;

                productCrates.forEach((crate: any) => {
                  const crateValue = getValueFromArray(crates, "crateId", crate, "crateId");
                  if (crateValue.length) {
                    const crateIndex = crates.findIndex((value: any) => value.crateId === crate.crateId);
                    const newCrate = { ...crate, quantity: parseInt(crates[crateIndex].quantity) + 1 };
                    crates.splice(crateIndex, 1, newCrate);
                  } else {
                    const newCrate = { ...crate, quantity: 1 };
                    crates.push(newCrate);
                  }
                });
              }
            });
      } else {
        if (item.shippedQty > 0 && item.associatedCrates) {
          let productCrates = item.associatedCrates;
          productCrates.forEach((crate: any) => {
            const crateValue = getValueFromArray(crates, "crateId", crate, "crateId");
            if (crateValue.length) {
              const crateIndex = crates.findIndex((value: any) => value.crateId === crate.crateId);
              const newCrate = { ...crate, quantity: parseInt(crates[crateIndex].quantity) + 1 };
              crates.splice(crateIndex, 1, newCrate);
            } else {
              const newCrate = { ...crate, quantity: 1 };
              crates.push(newCrate);
            }
          });
        }
      }
    });
  return crates;
};

export const checkTerminalStatus = (deliveryStatus: string) => {
  return AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TERMINAL_STATUSES.indexOf(deliveryStatus) > -1;
};

export const createPayloadToReleaseAssignee = (params: any) => {
  let payload: any = {};
  if (params) {
    payload.routeId = params;
  }
  return payload;
};

export const createPayloadToMarkAddressUnverified = (consignment: any, address: any) => {
  const payload = {
    consignmentCode: consignment.consignmentCode,
    address: {
      status: ADDRESS_STATUS.UNVERIFIED,
    },
  };
  return payload;
};

export const transformBundleData = (bundleProducts: BundleProduct[]): TransformedBundleProduct[] => {
  const transformedData: TransformedBundleProduct[] = [];

  bundleProducts.forEach((bundle: any) => {
    const unitBundle = bundle?.uom === "Grams" ? displayUnitGrams(bundle.uom) : "";
    const bundleObj: TransformedBundleProduct = {
      campaignId: bundle?.campaignId,
      bundleName: bundle?.name?.en,
      bundleShippedQty: bundle?.shippedQty,
      bundlePrice: bundle?.unitPrice,
      bundleRejectedQty: bundle?.rejectedQty,
      bundleProductQuantity: bundle?.quantity,
      bundleConstituents: [],
      reason: bundle?.reason,
    };

    if (bundle.items) {
      bundle.items.forEach((item: any) => {
        const unit = item?.uom === "Grams" ? displayUnitGrams(item.uom) : "";
        const constituent = {
          bundleProductName: item?.name?.en,
          bundleProductQuantity: `${item?.quantity} ${unit}`,
          bundleProductRejectedQty: item?.rejectedQty ? `${item?.rejectedQty} ${unit}` : "-",
          bundleProductShippedQty: `${item?.shippedQty || 0} ${unit}`,
        };
        bundleObj.bundleConstituents.push(constituent);
      });
    }

    transformedData.push(bundleObj);
  });

  return transformedData;
};
export const isRouteStatusOutForDelivery = (status: string | undefined) => {
  return status === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.ORDER_STATUS.OUT_FOR_DELIVERY;
};

interface RouteObject {
  id: string;
  routeId: string;
  parentRouteId?: string;
  subRoutes?: RouteObject[];
  // other fields...
}

export const transformDataForSubRoutes = (response: any) => {
  const data = lastMilePlanningRoutesDataTransform(response);
  const result: RouteObject[] = [];
  const idToObjectMap = new Map(data.map((item: RouteObject) => [item.id, item]));
  data.forEach((item: Route) => {
    if (item.parentRouteId) {
      const parentObject = idToObjectMap.get(item.parentRouteId);
      if (parentObject) {
        if (!parentObject?.subRoutes) {
          parentObject.subRoutes = [];
        }
        parentObject.subRoutes.push(item);
      } else {
        result.push(item);
      }
    } else {
      result.push(item);
    }
  });
  return result;
};

interface NameObj {
  ar: string;
  en: string;
}

export const transformedDataViewRoute = (response: any) => {
  return response?.map((route: any) => {
    return {
      routeId: route?.routeId,
      displayStatus: route?.displayStatus,
      orders: route.orders.map((order: any) => {
        return {
          consignmentCode: order?.consignmentCode,
          routeType: route?.routeType,
          slotValue: order?.slotValue,
          metadata: {
            deliveryStatus: order?.deliveryStatus,
            paymentType: order?.paymentType,
          },
          deliveryInformation: {
            totalCratesCount: order?.totalCratesCount,
            customerContactInfo: {
              address: order?.address?.fullAddress,
              phone: order?.customerPhone,
            },
          },
        };
      }),
    };
  });
};

export const getDeliveryExecutionStatusField = (fieldName: string, statusCode:string) =>{
  let statuses: any = AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DELIVERY_EXECUTION_STATUS;
  if(statusCode === "ON-TIME"){
    statuses[statusCode] = AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DELIVERY_EXECUTION_STATUS.ON_TIME;
  }
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}
