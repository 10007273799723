import { createStyles, makeStyles, StepConnector, Theme, withStyles } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";
import { alignItems } from "styled-system";

export const StepperTimelineConnector = withStyles({
  alternativeLabel: {
    top: 28,
    left: 'calc(-50% + 9px)',
    right: 'calc(50% + 9px)'
  },
  active: {
    '& $line': {
      borderColor: COLORS.SECONDARY_DARK,
    },
  },
  line: {
    borderColor: COLORS.SECONDARY_DARK,
    borderTopWidth: 4,
    borderRadius: 100,
  },
})(StepConnector);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: "100%",
      '& .padding-2': {
        padding: theme.spacing(2)
      }
    },
    statusHeaderContainer: {
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      '& .tag': {
        color: COLORS.WHITE,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        lineHeight: "10px",
        backgroundColor: COLORS.BLACK,
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: 8,
        '&.PICTON_BLUE': {
          backgroundColor: COLORS.PICTON_BLUE
        },
        '&.CREAM_CAN': {
          backgroundColor: COLORS.CREAM_CAN
        },
        '&.VISTA_BLUE': {
          backgroundColor: COLORS.VISTA_BLUE
        },
        '&.SEA_GREEN': {
          backgroundColor: COLORS.SEA_GREEN
        },
        '&.GOLDEN_BELL': {
          backgroundColor: COLORS.GOLDEN_BELL
        },
        '&.SCAMPI': {
          backgroundColor: COLORS.SCAMPI
        },
        '&.BLUE': {
          backgroundColor: COLORS.PRIMARY_MAIN
        },
        '&.SEA_GREEN2': {
          backgroundColor: COLORS.SEA_GREEN2
        },
        '&.LUCKY': {
          backgroundColor: COLORS.LUCKY
        },
        '&.DUSTY_GRAY': {
          backgroundColor: COLORS.DUSTY_GRAY
        },
        '&.JUNGLE_GREEN': {
          backgroundColor: COLORS.JUNGLE_GREEN
        },
        '&.BLACK': {
          backgroundColor: COLORS.BLACK
        },
        '&.TROPICAL_BLUE': {
          backgroundColor: COLORS.TROPICAL_BLUE,
          color: COLORS.ASTRAL
        },
        '&.CATSKILL_WHITE': {
          backgroundColor: COLORS.CATSKILL_WHITE,
          color: COLORS.ASTRAL
        },
        '&.BLUE_HAZE': {
          backgroundColor: COLORS.BLUE_HAZE,
          color: COLORS.SCAMPI
        },
        '&.BARLEY_WHITE': {
          backgroundColor: COLORS.BARLEY_WHITE,
          color: COLORS.OLD_GOLD
        },
        '&.DOUBLE_PEARL_LUSTA': {
          backgroundColor: COLORS.DOUBLE_PEARL_LUSTA,
          color: COLORS.PIMPKIN_SKIN
        },
        '&.JAGGED_ICE': {
          backgroundColor: COLORS.JAGGED_ICE,
          color: COLORS.BLUE_STONE
        },
        '&.TARA': {
          backgroundColor: COLORS.TARA,
          color: COLORS.OCEAN_GREEN
        },
        '&.JAGGED_ICE_PREPARED': {
          backgroundColor: COLORS.JAGGED_ICE,
          color: COLORS.JUNGLE_GREEN
        },
        '&.ALTO': {
          backgroundColor: COLORS.ALTO,
          color: COLORS.DUSTY_GRAY_LIGHT
        },
        '&.parent':{
          backgroundColor: COLORS.OLD_GOLD,
          color: COLORS.WHITE,
          margin: 4,
          textTransform: "uppercase",
        }
      }
    },
    container: {
      flexDirection: "column",
      flexWrap: "nowrap",
      height: "100%",
      '& .createRtItem': {
        width: '100%',
        '&.heading': {
          paddingBottom: theme.spacing(3),
          display: "flex",
          justifyContent: "space-between",
          '& .headingContainer': {
            alignItems: "center"
          },
          '& .editIcon': {
            width: 22,
            height: 22,
            marginLeft: theme.spacing(1),
            cursor: "pointer"
          },
          '& .headingTitle': {
            fontSize: 24,
            color: COLORS.BLACK,
            fontWeight: 600,
          }
        },
        '&.content': {
          height: "calc(100vh - 176px)"
        }
      }
    },
    createRtContentContainer: {
      height: "100%",
      flexWrap: "nowrap",
      '& .contentItem': {
        boxShadow: `0px 1px 4px ${COLORS.LIGHT_BLACK_SHADOW}`,
        borderRadius: theme.spacing(1),
        height: "100%",
        '&.left': {
          width: 500,
          minWidth: 500,
          marginRight: theme.spacing(3),
          '& .consignmentsContainer': {
            flexDirection: "column",
            height: "100%",
            flexWrap: "nowrap",
            overflow: "hidden",
            '& .consignmentItem': {
              '& .headerSectionContainer': {
                flexDirection: "column",
                padding: theme.spacing(2, 2, 1, 2),
                '&.viewMode': {
                  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                },
                '& .searchTextBox': {
                  '& .MuiOutlinedInput-root': {
                    width: 210,
                  }
                },
                '& .MuiOutlinedInput-root': {
                  width: 143,
                  minWidth: 143,
                  height: 24,
                  borderRadius: theme.spacing(.5)
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: 10,
                  padding: 0,
                  paddingLeft: theme.spacing(.5)
                },
                '& .MuiSelect-iconOutlined': {
                  right: 0
                },
                '& .MuiSelect-root': {
                  '& .MuiChip-root': {
                    fontSize: 10,
                    height: 15
                  }
                },
                '& .headerContainer': {
                  alignItems: "center",
                  paddingBottom: theme.spacing(1.5),
                  '& .headerItem': {
                    '&.heading': {
                      flexGrow: 1,
                      '& .refreshIcon': {
                        width: 16,
                        height: 16,
                        cursor: "pointer",
                        marginLeft: theme.spacing(0.5)
                      },
                      '& p': {
                        fontSize: 16,
                        fontWeight: 600
                      }
                    },
                    '&.icons': {
                      display: "flex",
                      alignItems: "center",
                      '& .MuiOutlinedInput-adornedEnd': {
                        paddingRight: 0,
                        '& .MuiSvgIcon-root': {
                          padding: theme.spacing(.25)
                        }
                      },
                      '& .MuiInputAdornment-positionEnd': {
                        marginLeft: 0
                      },
                      '& .MuiIconButton-sizeSmall': {
                        padding: 0,
                        '&.MuiIconButton-edgeEnd': {
                          marginRight: 0
                        }
                      },
                      '& .MuiSvgIcon-root': {
                        width: 19,
                        height: 19
                      },
                      '& .icon': {
                        padding: theme.spacing(0.5)
                      }
                    }
                  }
                },
                '& .filtersContainer': {
                  '& .filterItem': {
                    paddingRight: theme.spacing(0.5),
                    '&:last-child': {
                      paddingRight: 0
                    },
                    '& .icon-datepicker': {
                        marginLeft: 0
                     },
                     '& .filterName': {
                      fontSize: 9,
                      fontWeight:600,
                      marginBottom:4 
                   },
                  }
                }
              },
              '&.content': {
                height: "calc(100% - 80px)",
                '& .contentContainer': {
                  position: "relative",
                  height: "100%"
                },
                '& .hubLabel': {
                  position: "absolute",
                  top: theme.spacing(1),
                  right: theme.spacing(8.5),
                  '&.isRoute': {
                    right: theme.spacing(2)
                  },
                  '& .hubLabelContainer': {
                    alignItems: "center",
                    height: 21,
                    '& .hubLabelText': {
                      fontSize: 12,
                      fontWeight: 600,
                      maxWidth: 200,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      '& .label': {
                        fontWeight: 500,
                        paddingRight: theme.spacing(0.5)
                      }
                    }
                  }
                },
                '& .toggleAll': {
                  position: "absolute",
                  top: theme.spacing(1),
                  right: theme.spacing(2.25),
                  cursor: "pointer",
                  userSelect: "none",
                  '& .toggleAllContainer': {
                    alignItems: "center",
                    '& .toggleItem': {
                      paddingLeft: theme.spacing(0.5),
                      '&.iconItem': {
                        transform: "rotate(0deg)",
                        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        '&.expanded': {
                          transform: "rotate(180deg)"
                        }
                      },
                      '& .toggleText': {
                        fontSize: 14,
                        fontWeight: 600
                      },
                      '& .toggleIcon': {
                        width: 21,
                        height: 21,
                        verticalAlign: "bottom"
                      }
                    }
                  }
                },
                '& .slotContentContainer': {
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  '&.viewMode': {
                    overflow: "auto",
                    paddingBottom: theme.spacing(2),
                    '& .slotItemContainer': {
                      paddingLeft: theme.spacing(2)
                    },
                    "& .MuiAccordionSummary-root":{
                       minHeight: 'unset'
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded":{
                       margin : 'unset'
                    },
                    "& .MuiAccordionDetails-root": {
                       display:"inline",
                    },
                    "& .MuiAccordion-root.Mui-expanded":{
                      margin: 0,
                   },
                  },
                  '& .slotItemContainer': {
                    alignItems: "flex-start",
                    padding: theme.spacing(1, 2, 1, 6),
                    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                    flexWrap: "nowrap",
                    '&.selected': {
                      backgroundColor: COLORS.MADISON_LIGHT,
                      borderLeft: `3px solid ${theme.palette.primary.main}`
                    },
                    '&.drafted': {
                      color: COLORS.GRAY_DARK,
                      paddingLeft: theme.spacing(3.5)
                    },
                    '&.terminal': {
                      color: COLORS.GRAY_DARK,
                      borderColor: COLORS.GRAY_DARK
                    },
                    '& .slotContentItem': {
                      '&.checkbox': {
                        '& .checkboxContainer': {
                          alignItems: "center",
                          flexWrap: "nowrap",
                          '& .lockIcon': {
                            width: 16,
                            height: 16,
                            marginRight: theme.spacing(1)
                          }
                        },
                        paddingRight: theme.spacing(1.5)
                      },
                      '&.consignmentDetails': {
                        flexGrow: 1,
                        height: "100%",
                        '& .consignmentDetailsContainer': {
                          justifyContent: "space-between",
                          height: "100%",
                          flexWrap: "nowrap",
                          '& .detailsItem': {
                            '& .consignmentHeader': {
                              flexDirection: "column",
                              height: "100%",
                              flexWrap: "nowrap",
                              overflow: "hidden",
                              '& .consignmentHeaderItem': {
                                '&.details': {
                                  flexGrow: 1,
                                  paddingTop: theme.spacing(0.5)
                                },
                                '& .headerDetailsContainer': {
                                  flexWrap: "nowrap",
                                  '& .itemContainer': {
                                    flexWrap: "nowrap"
                                  },
                                  '& .consignmentAddressIcon':{
                                     marginLeft: "8px", 
                                     minWidth:"14px",
                                     maxWidth:"14px"
                                  },
                                },
                                 '&.status': {
                                   display: "flex",
                                   alignItems: "center"
                                },
                              }
                            },

                            '& .consignmentAddressCTA':{
                                     fontSize: "10px", 
                                     color: "#0E5AA7", 
                                     cursor: "pointer"
                            },
                            '& .headingContainer': {
                              alignItems: "center",
                              height: "100%",
                              flexWrap: "nowrap",
                              '& .heading': {
                                fontSize: 12,
                                fontWeight: 600,
                                cursor: "pointer"
                              },
                            },
                            '& .seqNoContainer': {
                              backgroundColor: COLORS.ALERT_ORANGE,
                              borderRadius: 2,
                              '& .seqNo': {
                                fontSize: 10,
                                fontWeight: 600,
                                color: COLORS.MINE_SHAFT,
                                lineHeight: '16px',
                                padding: theme.spacing(0, 1)
                              }
                            },
                            '& .count': {
                              fontSize: 10,
                              lineHeight: "15px",
                              fontWeight: 500,
                              padding: theme.spacing(0, 0.75)
                            },
                            '& .icon': {
                              width: 16,
                              height: 16
                            },
                            '&.desc': {
                              paddingTop: theme.spacing(1)
                            },
                            '& .descContainer': {
                              alignItems: "center",
                              flexWrap: "nowrap",
                              '& .location': {
                                width: 356
                              },
                              '& .itemContainer': {
                                alignItems: "center",
                                paddingRight: theme.spacing(2.5),
                                flexWrap: "nowrap",
                                '& .locDesc': {
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis"
                                }
                              },
                            }
                          }
                        },
                      }
                    }
                  }
                },
                '& .MuiTabs-root': {
                  padding: theme.spacing(0, 2),
                  minHeight: 42,
                  borderBottom: `1px solid ${theme.palette.secondary.main}`
                },
                '& .tabPanel': {
                  padding: 0,
                  height: 'calc(100% - 42px)',
                  overflow: "auto",
                  '& .tabPanelContainer': {
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    '& .delSlotContainer': {
                      flexDirection: "column",
                      flexWrap: "nowrap",
                    },
                    '& .highlightText': {
                      backgroundColor: COLORS.SUCCESS_GREEN
                    },
                    '& .accordionRoot': {
                      boxShadow: "none",
                      margin: 0,
                      '&::before': {
                        opacity: 0
                      }
                    },
                    '& .accordianSummary': {
                      border: "none",
                      borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                      '&.delArea': {
                        minHeight: 46,
                        height: 46,
                        '& .areaHeaderContainer': {
                          alignItems: "center",
                          '& .areaHeaderItem': {
                            paddingRight: theme.spacing(1),
                            '& .itemContainer': {
                              alignItems: "center",
                              '& .icon': {
                                padding: theme.spacing(0.5)
                              },
                              '& .count': {
                                fontSize: 10,
                                fontWeight: 500,
                                lineHeight: "15px"
                              },
                              '& .heading': {
                                fontSize: 14,
                                fontWeight: 600,
                                color: COLORS.MINE_SHAFT
                              }
                            }
                          }
                        }
                      },
                      '&.delSlot': {
                        minHeight: 32,
                        height: 32,
                        backgroundColor: COLORS.MINE_SHAFT_LIGHT,
                        '& .slotHeaderContainer': {
                          alignItems: "center",
                          '& .Mui-disabled': {
                            pointerEvents: 'auto',
                            cursor: 'not-allowed',
                          },
                          '& .slotHeaderItem': {
                            paddingRight: theme.spacing(1),
                            '& .itemContainer': {
                              alignItems: "center",
                              '& .icon': {
                                padding: theme.spacing(0.5)
                              },
                              '& .count': {
                                fontSize: 10,
                                fontWeight: 500,
                                lineHeight: "15px"
                              },
                              '& .heading': {
                                fontSize: 12,
                                fontWeight: 500,
                                paddingLeft: theme.spacing(1.5)
                              }
                            }
                          }
                        }
                      }
                    },
                    '& .accordianDetails': {
                      padding: 0,
                      '&.routes': {
                        '& .slotItemContainer': {
                          height: 56
                        }
                      }
                    },
                    '&.planned': {
                      paddingTop: theme.spacing(1.5),
                      '& .heading': {
                        paddingLeft: '0px !important'
                      },
                      '& .accordianDetails': {
                        '& .slotItemContainer': {
                          padding: '10px 8px 10px 16px !important',
                          cursor: 'pointer'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        '&.right': {
          flexGrow: 1,
          width: 'auto',
          overflow: 'auto',
        },
        '& .detailsContainer': {
          flexDirection: "column",
          height: "100%",
          flexWrap: "nowrap",
          '& .detailItem': {
            '&.routeDetails': {
              backgroundColor: COLORS.MINE_SHAFT_LIGHT,
              padding: theme.spacing(2),
              '&.viewMode': {
                '& .routeDetailsContainer .routeDetailsItem.basicDesc': {
                  paddingBottom: 0
                }
              },
              '& .routeDetailsContainer': {
                flexDirection: "column",
                flexWrap: "nowrap",
                '& .routeDetailsItem': {
                  '&.header': {
                    display: "flex",
                    justifyContent: "space-between",
                    '& .routeIdHeaderContainer': {
                      alignItems: "center",
                      justifyContent: "space-between",
                      '& .ddnsItem': {
                        '& .MuiFormControl-root': {
                          paddingRight: theme.spacing(1.5),
                          '&:last-child': {
                            paddingRight: 0
                          }
                        },
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: COLORS.WHITE
                        }
                      }
                    },
                    paddingBottom: theme.spacing(2)
                  },
                  '& .heading': {
                    fontSize: 16,
                    fontWeight: 600,
                    color: COLORS.BLACK
                  },
                  "& .subHeading": {
                    fontSize: 14,
                    fontWeight: 500,
                    color: COLORS.BLACK,
                  },
                  '&.basicDesc': {
                    paddingBottom: theme.spacing(3)
                  },
                  '&.noSelectedArea': {
                    padding: theme.spacing(3),
                    backgroundColor: COLORS.WHITE,
                    border: `1px dashed ${theme.palette.secondary.dark}`,
                    borderRadius: theme.spacing(1),
                    '& p': {
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: 500,
                      color: COLORS.DOVE_GRAY
                    }
                  },
                  '&.timeline': {
                    alignSelf: "flex-start",
                    height: 80,
                    maxWidth: '100%',
                    overflow: 'auto',
                  },
                  '&.timelineWarning': {
                    paddingBottom: theme.spacing(1.5),
                    '& .warningContainer': {
                      alignItems: "center",
                      '& .warningItem': {
                        paddingRight: theme.spacing(1),
                        '& p': {
                          fontSize: 11,
                          fontWeight: 500,
                          color: COLORS.DOVE_GRAY
                        }
                      },
                      '& .warningIcon': {
                        width: 20,
                        height: 20,
                        verticalAlign: "middle",

                      }
                    },

                  },
                  '& .basicDescContainer': {
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    '& .basicItem': {
                      paddingBottom: theme.spacing(2),
                      '&:last-child': {
                        paddingBottom: 0
                      },
                      '&.driver': {
                        paddingRight: theme.spacing(2)
                      },
                      '&.descriptions': {
                        flexGrow: 1
                      },
                      '& .descCommonContainer': {
                        '& .commonItem': {
                          flex: "1 0 0",
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: COLORS.WHITE
                          },
                          '&:last-child': {
                            flex: "0.5 0 0"
                          },
                          '& .descContainer': {
                            flexDirection: "column",
                            '& .capacityBreachContainer': {
                              alignItems: "center",
                              '& .infoErrorIcon': {
                                width: 16,
                                height: 16,
                                marginLeft: theme.spacing(1)
                              }
                            },
                            '& p': {
                              fontSize: 12,
                              fontWeight: 500,
                              lineHeight: "18px"
                            },
                            '& .value': {
                              alignSelf: "flex-start",
                              '& p': {
                                fontWeight: 600
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            '&.mapZone': {
              flexGrow: 1,
              backgroundColor: theme.palette.secondary.dark,
              position: "relative",
              overflow: "auto",
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
              '& .listViewTimelineContent': {
                backgroundColor: COLORS.WHITE,
                padding: theme.spacing(2, 3),
                height: "100%",
                overflow: "auto"
              },
              '& .mapSwitchBtnContainer': {
                position: "absolute",
                alignItems: "center",
                width: 110,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: theme.spacing(1),
                backgroundColor: COLORS.WHITE,
                top: theme.spacing(1),
                right: theme.spacing(6.5),
                flexWrap: "nowrap",
                '& .switchBtnItem': {
                  cursor: "pointer",
                  '&.selected': {
                    backgroundColor: COLORS.MADISON_LIGHT,
                    '&.mapView': {
                      borderTopLeftRadius: theme.spacing(1),
                      borderBottomLeftRadius: theme.spacing(1),
                      '& path': {
                        stroke: theme.palette.primary.main
                      }
                    },
                    '&.listView': {
                      borderTopRightRadius: theme.spacing(1),
                      borderBottomRightRadius: theme.spacing(1),
                      '& path': {
                        fill: theme.palette.primary.main
                      }
                    },
                    '& .title': {
                      color: theme.palette.primary.main,
                      fontWeight: 600
                    }
                  },
                  '& .switchBtnItemContainer': {
                    flexDirection: "column",
                    alignItems: "center",
                    '& .title': {
                      fontSize: 12,
                      fontWeight: 500,
                      paddingBottom: theme.spacing(0.5)
                    }
                  },
                  '& .switchBtnIcon': {
                    width: 54,
                    height: 40,
                    verticalAlign: "middle",
                    padding: theme.spacing(0, 2),
                    backgroundColor: "transparent"
                  },
                }

              }
            },
            '&.buttons': {
              '& .buttonsContainer': {
                padding: theme.spacing(2),
                justifyContent: "flex-end",
                '& .btn': {
                  marginRight: theme.spacing(1.5),
                  '&:last-child': {
                    marginRight: 0
                  }
                },
                '& .refreshMapBtn': {
                  marginRight: "auto"
                },
                '& .saveBtn': {
                  padding: theme.spacing(1, 5.5)
                }
              }
            },
            '&.planned': {
              '& .routeDetailsItem': {
                '&.basicDesc': {
                  paddingBottom: '0 !important'
                }
              }
            },
          }
        }
      }
    },
    timelineStepper: {
      padding: 0,
      alignItems: "center",
      backgroundColor: 'transparent',
      '& .timelineStep': {
        paddingLeft: 16,
        paddingRight: 16,
        '&.isDragging': {
          '& .MuiStepConnector-alternativeLabel': {
            display: "none"
          }
        },
        '&.first': {
          paddingLeft: 0
        }
      },
      '& .MuiStepLabel-root': {
        flexDirection: 'column-reverse',
        '& .MuiStepLabel-labelContainer': {
          '& .MuiStepLabel-alternativeLabel': {
            whiteSpace: "nowrap",
            width: 32,
            textOverflow: "ellipsis",
            overflow: "hidden"
          }
        },
        '&.MuiStepLabel-alternativeLabel': {
          flexDirection: 'column-reverse'
        }
      },
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0
      },
      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        marginTop: 0
      },
      '& .MuiStepLabel-label': {
        fontSize: 10,
        color: '#252525',
        paddingBottom: 6,
        textAlign: "center"
      }
    },
    listViewTimeline: {
      padding: "0 !important",
      margin: 0,
      '& .timelineItem': {
        minHeight: 64,
        '&.first': {
          minHeight: 62,
          '& .MuiTimelineSeparator-root': {
            padding: 0
          }
        },
        '& .MuiTimelineSeparator-root': {
          paddingLeft: 3
        },
        '&:last-child': {
          minHeight: "auto",
          '& .primaryConnector': {
            display: "none"
          },
        },
        '&:before': {
          content: "none"
        },
        '& .timelineDot': {
          padding: 0,
          margin: 0,
          border: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
          '&.listItem': {
            paddingTop: 9
          },
          '& .timelineIcon': {
            padding: 0,
          },
          '& .countIcon': {
            width: 24,
            height: 24,
            borderRadius: "50%",
            backgroundColor: COLORS.MAROON,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            '& .count': {
              fontSize: 10,
              fontWeight: 600,
              lineHeight: "12px",
              color: COLORS.WHITE
            }
          },
          '& .areaIcon': {
            width: 32,
            height: 32
          }
        },
        '& .primaryConnector': {
          backgroundColor: COLORS.TRANSPARENT,
          borderLeft: `1px dashed ${COLORS.SILVER}`
        },
        '& .timelineContent': {
          padding: 0,
          paddingLeft: theme.spacing(1.5),
          '& .contentContainer': {
            alignItems: "center",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: theme.spacing(1),
            flexWrap: "nowrap",
            marginBottom: theme.spacing(2),
            '& .listViewContentItem': {
              '&.itemHeading': {
                padding: theme.spacing(0, 2),
                cursor: "pointer"
              },
              '&.itemSubHeading': {
                padding: theme.spacing(0, 1),
                
              },
              '& .id': {
                fontSize: 12,
                fontWeight: 600,
                color: COLORS.MINE_SHAFT,
                whiteSpace: "nowrap"
              },
              '& .subHeading': {
                fontSize: 12,
                fontWeight: 600,
                color: COLORS.MINE_SHAFT,
                whiteSpace: "normal",
                margin:'auto',
                padding: theme.spacing(0, 0, 0, 0.5),
              },
              '&.itemDetails': {
                flexGrow: 1,
                padding: theme.spacing(1, 1.5, 1)
              },
              '& .itemDetailsContainer': {
                flexWrap: "nowrap",
                alignItems: "center",
                '& .detailsItem': {
                  paddingRight: theme.spacing(1.5)
                },
                '& .itemContainer': {
                  alignItems: "center",
                  flexWrap: "nowrap",
                  '& .icon': {
                    padding: theme.spacing(0.5)
                  },
                  '& .count': {
                    fontSize: 10,
                    fontWeight: 500,
                    lineHeight: "15px",
                    whiteSpace: "nowrap",
                    '&.address': {
                      whiteSpace: "normal",
                    }
                  },
                  '& .valueDetails': {
                    fontSize: 10,
                    fontWeight: 500,
                    lineHeight: "15px",
                    whiteSpace: "normal",
                    '&.address': {
                      whiteSpace: "normal",
                    }
                  }
                },
                '& .flexWrapNoWrap': {
                  flexWrap: "nowrap",
                }
              }
            }
          },
          '& .titleHeading': {
            fontSize: 10,
            lineHeight: "15px",
            fontWeight: 500,
            color: COLORS.BLACK,
            paddingTop: theme.spacing(1)
          }
        }
      }
    },
    viewModeSubroute:{
      margin: theme.spacing(0.5, 0, 0, 0),
      padding: 0,
      '& .slotItemContainer': {
        '&:last-child': {
          borderBottom: 'none !important'
        },
      }
    },
    subRoute: {
      margin: theme.spacing(0, 0, 0, 0),
      boxShadow: 'none',
      borderRadius: 'none',
      "& .MuiAccordion-root.Mui-expanded" : { margin: theme.spacing(0, 0, 0, 0) },
      '& .MuiAccordionSummary-root' : {},
      '& .MuiAccordionSummary-content':{
        display:'flex',
        alignItems:'center'
      },
      '& .tag': {
        color: COLORS.WHITE,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.25, 0.5),
        lineHeight: "14px",
        backgroundColor: COLORS.BLACK,
        fontWeight: 600,
        fontSize: 8,
        textTransform: "uppercase",
        '&.subRouteTag':{
          backgroundColor: COLORS.INFO_BLUE,
          color: COLORS.WHITE,
          margin: 4
        },
        '&.status':{
          backgroundColor: COLORS.TROPICAL_BLUE ,
          color: COLORS.ASTRAL,
          margin: 4
        }
      }
    },
    confirmDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(8.25),
      '& .dialogBtn': {
        '&.secondary': {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1)
        },
        '&.primary': {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1)
        }
      }
    },
      confirmDialogAddressButtonsContainer: {
      justifyContent: "center",
      "& .dialogBtn": {
        "&.primary": {
          marginLeft: theme.spacing(1),
        },
      },
    },
    confirmDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .contentItem': {
        '& p': {
          textAlign: "center",
          lineHeight: 1.2,
          '&.alignLeft': {
            textAlign: "left",
          }
        },
        '&.heading': {
          padding: theme.spacing(0, 15.75, 5),
          '& p': {
            fontSize: 24,
            fontWeight: 600
          }
        },
        '&.desc': {
          padding: theme.spacing(0, 2.5),
          '& p': {
            fontSize: 18,
            fontWeight: 400,
            '& .count': {
              fontWeight: 600
            }
          }
        }
      }
    },
    capacityBreachDialogTitle: {
      fontSize: 20,
      fontWeight: 600,
      paddingLeft: theme.spacing(2.5)
    },
    capacityBreachDialogButtonsContainer: {
      justifyContent: "flex-end",
      paddingBottom: theme.spacing(3.75),
      '& .dialogBtn': {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(4.5),
        '&.secondary': {
          marginRight: theme.spacing(1)
        },
        '&.primary': {
          marginLeft: theme.spacing(1)
        }
      }
    },
    confirmWithTitleDialogPaperPropsRoot: {
      width: 600,
      height: 240,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    confirmDialogPaperPropsRoot: {
      width: 600,
      height: 350,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    addressConfirmDialogPaperPropsRoot: {
       width: 338,
      height: 214,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    endSessionSelectionDialogPaperPropsRoot: {
      width: 428,
      height: 242,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    endSessionSelectionDialogTitle: {
      fontSize: 20,
      fontWeight: 600,
      padding: "6px 0px 0px 12px"
    },

    endSessionSelectionDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .contentItem': {
        '&.heading': {
          '& p': {
            fontSize: 14,
            fontWeight: 400
          }
        },
        '&.hub': {
          paddingTop: theme.spacing(3.75)
        }
      }
    },
    content: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "15px",
      color: "#252525"
    },
    endSessionDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(1),
      '& .dialogBtn': {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(4.5),
        '&.secondary': {
          marginRight: theme.spacing(1)
        },
        '&.primary': {
          marginLeft: theme.spacing(1)
        }
      }
    },
    selectMenuPropsList: {
      '& .MuiListItem-root': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
        '& .MuiListItemText-primary': {
          fontSize: 10
        }
      },
      '& .MuiCheckbox-root': {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75)
      }
    },
    selectMenuPaperProps: {
      maxHeight: 200,
      width: 143,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      overflow: "auto",
      boxShadow: "none"
    },
   titleContainer: {
      "& .headingContainer": {
        flexDirection: "column",
        "& .titleHeading": {
          fontSize: 18,
          fontWeight: 500,
          "& .content": {
            fontWeight: 600,
          },
        },
        "& .titleContent": {
          fontSize: 12,
          fontWeight: 600,
          "&.link": {
            cursor: "pointer",
          },
        },
      },
    },
    addressContent: {
        fontSize: "14px", 
        fontWeight: 500, 
     },
     flexWrapNoWrap: {
      flexWrap: "nowrap",
    }
  })

);
