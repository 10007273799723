import React, { useCallback, forwardRef, ReactElement, Ref, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../config/redux/reducers";
import {
  getConsignmentStatusField,
  createExpressOrderReplanningPayload,
  createPayloadForReasons,
  createPayloadForReplanDuringPartialRejection,
} from "../mocks/express/response.transforms";
import { createPayloadBundlePartialRejection } from '../mocks/routePlanning/response.transforms';
import {
  fetchExpressOrderDeliveryStatuses,
  fetchDeliveryReasons,
  expressOrderReplanning,
  replanDuringPartialRejection,
} from "./redux/expressSlice";
//common components
import CustomDialog from "../common/Dialog";
import SelectBox from "../common/SelectBox";
import TextBox from "../common/TextBox";

//Material UI Components
import { useStyles } from "./ExpressOrderEdit.styles";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Button } from "@material-ui/core";

//constants
import AppConstants from "../constants";
import { EXPRESS_DELIVERY_TYPE } from "constants/ExpressConstants";
import { TextConstants } from "constants/TextConstants";

//Assets
import warning from "../assets/icons/warning.svg";
import warningIcon from "./../assets/icons/warningOctagon.svg";

import ExpUpdatePartialRejection from "./ExpUpdatePartialRejection";
interface ConsignmentEditProps {
  open: boolean;
  consignment: any;
  isExpress?: any;
  shippingStatus?: any;
  items: any;
  bundleProduct?: any;
  closePopup: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction='left' ref={ref} {...props} />;
});
const ConsignmentEdit = (props: ConsignmentEditProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, consignment, items, closePopup, bundleProduct } = props;

  const {
    orderDeliveryStatuses,
    reasonsDropdownList,
    deliveryReasons,
    consignmentDataAfterReplanning,
    replanningConsignmentSuccess,
  } = useSelector((state: AppState) => state.expressDashboard);
  const { countryCode } = useSelector((state: AppState) => state.common);
  const [selectedStatus, setSelectedStatus] = useState(AppConstants.SELECT_NONE.value);
  const [selectedReason, setSelectedReason] = useState(AppConstants.SELECT_NONE.value);
  const [showReason, setShowReason] = useState(false);
  const [commentsValue, setCommentsValue] = useState("");
  const [errors, setErrors] = useState({
    reason: "",
    comments: "",
  });
  const [isStatusDelivered, setIsStatusDelivered] = useState(true);
  const [openExpUpdateRejectedDialog, setOpenExpUpdateRejectedDialog] = useState(false);
  const [openPartialRejectionPopup, setOpenPartailRejectionPopup] = useState(false);
  let consignmentData = consignment;
  let metadata: any = {};
  let metadataAfterReplanning: any = {};

  if (consignment) {
    metadata = consignment.metadata;
  }
  if (consignmentDataAfterReplanning) {
    metadataAfterReplanning = consignmentDataAfterReplanning.metadata
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot,
    },
    square: true,
  };

  const confirmDialogPaperProps = {
    classes: {
      root: classes.confirmDialogPaperPropsRoot,
    },
  };

  const validateForm = useCallback(() => {
    let newErrors = {};
    if (selectedReason === AppConstants.SELECT_NONE.value) {
      newErrors = { ...newErrors, reason: "Please select reason" };
    }
    if (selectedReason === "OH04" || selectedReason === "DRD5") {
      if (commentsValue === "") {
        newErrors = { ...newErrors, comments: "Please provide comments" };
      }
    }
    setErrors({ ...errors, ...newErrors });
    return Object.keys(newErrors).length;
  }, [errors, selectedReason, commentsValue]);

  const handleReasonChange = useCallback(
    (value: any) => {
      setSelectedReason(value);
      if (value) {
        setErrors({ ...errors, reason: "", comments: "" });
      }
    },
    [errors]
  );

  const handleCommentsTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCommentsValue(event.target.value);
      if (event.target.value) {
        setErrors({ ...errors, comments: "" });
      }
    },
    [errors]
  );

  const closePartialRejectionPopup = useCallback(() => {
    setOpenPartailRejectionPopup(false);
  }, []);

  const handleReplanningForDelivered = useCallback(() => {
    const payload = createExpressOrderReplanningPayload({
      id: consignment.consignmentCode,
      commentsValue: commentsValue,
      selection: selectedStatus,
    });
    dispatch(
      expressOrderReplanning({
        params: payload,
      })
    );
    closePartialRejectionPopup();
  }, [consignment, selectedStatus, commentsValue, closePartialRejectionPopup, dispatch]);

  const handleOrderReplanning = useCallback(() => {
    if (validateForm() === 0) {
      const payload = createExpressOrderReplanningPayload({
        id: consignment.consignmentCode,
        selection: selectedStatus,
        reason: selectedReason,
        commentsValue: commentsValue,
      });
      if (selectedReason !== AppConstants.SELECT_NONE.value) {
        let updatedReason;
        const deliveryReason =
          deliveryReasons &&
          deliveryReasons.length &&
          deliveryReasons.filter((reason: any) => reason.reasonCode === selectedReason);
        if (deliveryReason.length) {
          updatedReason = Object.assign({ comment: commentsValue }, deliveryReason[0]);
          payload.reason = updatedReason;
        }
        dispatch(
          expressOrderReplanning({
            params: payload,
          })
        );
      }
    }
  }, [consignment, selectedStatus, commentsValue, deliveryReasons, selectedReason, validateForm, dispatch]);

  const dialogActionHandler = useCallback(
    (type: string) => {
      if (type === "update") {
        if (isStatusDelivered) {
          setOpenExpUpdateRejectedDialog(true);
        } else {
          handleOrderReplanning();
        }
      } else {
        closePopup();
      }
    },
    [isStatusDelivered, closePopup, handleOrderReplanning]
  );

  const fetchReasons = useCallback(
    (value: any, consignment: any) => {
      if (Object.keys(consignment).length) {
        const payload = createPayloadForReasons(countryCode, value);
        dispatch(
          fetchDeliveryReasons({
            params: payload,
          })
        );
      }
    },
    [dispatch, countryCode]
  );

  const handleStatusChange = useCallback(
    (value: any, consignment: any) => {
      setSelectedStatus(value);
      if (value !== AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DELIVERED) {
        fetchReasons(value, consignment);
        setShowReason(true);
        setSelectedReason(AppConstants.SELECT_NONE.value);
        setIsStatusDelivered(false);
      } else {
        setIsStatusDelivered(true);
        setShowReason(false);
        setSelectedReason(AppConstants.SELECT_NONE.value);
      }
    },
    [fetchReasons]
  );

  const fetchDeliveryFailedReasons = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        const payload = createPayloadForReasons(countryCode, false);
        dispatch(
          fetchDeliveryReasons({
            params: payload,
          })
        );
      }
    },
    [dispatch, countryCode]
  );

  const handleExpressOrderStatuses = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchExpressOrderDeliveryStatuses({
            params: consignmentCode,
          })
        );
      }
    },
    [dispatch]
  );

  const handleRejectedDialogClose = useCallback(() => {
    setOpenExpUpdateRejectedDialog(false);
  }, []);

  const updateRejectedDialogActionHandler = useCallback(
    (type: string) => {
      if (type === "save") {
        handleReplanningForDelivered();
      } else {
        setOpenPartailRejectionPopup(true);
      }
      handleRejectedDialogClose();
    },
    [handleRejectedDialogClose, handleReplanningForDelivered]
  );

  const handlePartialRejectionUpdateActions = useCallback(
    (params: any) => {
      if (params.type === "save") {
        const payload1 = createPayloadForReplanDuringPartialRejection(params.values, params.consignment);
        let payload = payload1
        if(params?.bundleItems?.length){
          const payload2 = createPayloadBundlePartialRejection(params.bundleItems)
          payload = { ...payload1, ...payload2 };
        }
        dispatch(
          replanDuringPartialRejection({
            params: payload,
          })
        );
        closePartialRejectionPopup();
        closePopup();
      }
    },
    [dispatch, closePartialRejectionPopup, closePopup]
  );

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className='headingContainer' container>
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography className='titleHeading'>
                    <span className='label'> {TextConstants.EDIT_CONSIGNMENT_TITLE} </span>
                    <span className='content'> {consignment.consignmentCode} </span>
                  </Typography>
                </Grid>
                {metadataAfterReplanning?.deliveryStatus ? (
                  <Grid className='tagContainer' item>
                    <Typography
                      className={[
                        "tag",
                        getConsignmentStatusField("color", metadataAfterReplanning?.deliveryStatus),
                      ].join(" ")}
                    >
                      {metadataAfterReplanning?.deliveryStatus && metadataAfterReplanning.deliveryStatus}
                    </Typography>
                  </Grid>
                ) : (
                    metadata?.deliveryStatus && (
                      <Grid className='tagContainer' item>
                        <Typography
                          className={[
                            "tag",
                            getConsignmentStatusField("color", metadata && metadata.deliveryStatus),
                          ].join(" ")}
                        >
                          {metadata?.deliveryStatus && metadata.deliveryStatus}
                        </Typography>
                      </Grid>
                    )
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className='selectedContentArea' item>
          <Grid className='contentAreaContainer cancel' container>
            <Grid className='contentAreaItem' item>
              <Grid className='itemContainer' container>
                <Grid className='itemLabel' item>
                  <Typography>{TextConstants.UPDATE_CONSIGNMENT_STATUS}</Typography>
                </Grid>
                <Grid className='itemInput' item>
                  <SelectBox
                    fullWidth
                    value={selectedStatus}
                    id='status'
                    inputProps={{
                      name: "status",
                      id: "sStatus",
                    }}
                    required
                    handleChange={(e) => handleStatusChange(e, consignmentData)}
                    items={orderDeliveryStatuses || []}
                  ></SelectBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className='selectedContentArea' item>
          <Grid className='contentAreaContainer cancel' container>
            {showReason ? (
              <Grid className='contentAreaItem' item>
                <Grid className='itemContainer' container>
                  <Grid className='itemLabel' item>
                    <Typography>{TextConstants.REASON}</Typography>
                  </Grid>
                  <Grid className='itemInput' item>
                    <SelectBox
                      fullWidth
                      value={selectedReason}
                      id='EditConsignmentReason'
                      inputProps={{
                        name: "reason",
                        id: "EditConsignmentReason",
                      }}
                      required
                      handleChange={handleReasonChange}
                      items={reasonsDropdownList || []}
                      error={!!errors.reason}
                      helperText={errors.reason}
                    ></SelectBox>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid className='contentAreaItem' item>
              <Grid className='itemContainer' container>
                <Grid className='itemLabel' item>
                  <Typography>Comments</Typography>
                </Grid>
                <Grid className='itemInput' item>
                  <TextBox
                    fullWidth
                    multiline
                    variant='outlined'
                    rows={4}
                    onChange={handleCommentsTextChange}
                    error={!!errors.comments}
                    helperText={errors.comments}
                    value={commentsValue}
                    textBoxId='EditConsignmentComments'
                    placeholderText='Enter a comment'
                  ></TextBox>
                </Grid>
              </Grid>
            </Grid>
            {selectedStatus === EXPRESS_DELIVERY_TYPE.UNDELIVERED && (
              <Grid className='contentAreaItem warning' item>
                <Grid className='warningItem'>
                  <img src={warning} alt='warning' />
                  <Typography className='warningTitle'>{TextConstants.CONSIGNMENT_WARNING}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getDialogActions = () => {
    return (
      <Grid className={classes.buttons} container>
        <Button
          className='userBtn secondary'
          variant='contained'
          onClick={() => {
            dialogActionHandler("cancel");
          }}
        >
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button
          className='userBtn primary'
          variant='contained'
          onClick={() => {
            dialogActionHandler("update");
          }}
        >
          {AppConstants.BUTTONS.SAVE}
        </Button>
      </Grid>
    );
  };

  const getRejectedDialogTitleContent = () => {
    return (
      <Grid container className={classes.confirmDialogTitleContainer}>
        <Grid item className='titleItem'>
          <img src={warningIcon} alt='warning' className='titleItem icon' />
        </Grid>
        <Grid item className='titleItem'>
          <Typography className='titleItem text'>{TextConstants.REJECT_ITEMS_TITLE}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getRejectedDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className='contentItem' item>
          <Typography className='contentDetail'>{TextConstants.REJECT_ITEMS_CONTENT}</Typography>
          <Typography className='contentDetail secondItem'>{TextConstants.REJECT_ITEMS_CONTENT_DESC}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getRejectedDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button
          className='dialogBtn secondary'
          variant='contained'
          onClick={() => {
            updateRejectedDialogActionHandler("edit");
          }}
        >
          {AppConstants.BUTTONS.EDIT_ITEMS}
        </Button>
        <Button
          className='dialogBtn primary'
          variant='contained'
          onClick={() => {
            updateRejectedDialogActionHandler("save");
          }}
        >
          {AppConstants.BUTTONS.SAVE}
        </Button>
      </Grid>
    );
  };

  const handleDialogClose = () => {
    setErrors({ ...errors, reason: "", comments: "" });
    closePopup();
  };

  useEffect(() => {
    if (replanningConsignmentSuccess) {
      closePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replanningConsignmentSuccess]);

  useEffect(() => {
    if (consignment) {
      setCommentsValue("");
      setShowReason(false);
      setSelectedReason(AppConstants.SELECT_NONE.value);
      setSelectedStatus(AppConstants.SELECT_NONE.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignment, open]);

  useEffect(() => {
    if (open && consignment != null) {
      handleExpressOrderStatuses(consignment);
    }
  }, [open, consignment, fetchDeliveryFailedReasons, handleExpressOrderStatuses]);

  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={getDialogActions()}
        handleClose={handleDialogClose}
      />
      <CustomDialog
        hideCloseIcon={true}
        open={openExpUpdateRejectedDialog}
        PaperProps={confirmDialogPaperProps}
        title={getRejectedDialogTitleContent()}
        content={getRejectedDialogDetailsContent()}
        actions={getRejectedDialogActions()}
        handleClose={handleRejectedDialogClose}
      />
      {openPartialRejectionPopup && (
       <ExpUpdatePartialRejection
        open={openPartialRejectionPopup}
        items={items}
        consignment={consignment}
        bundleProduct={bundleProduct}
        closePopup={closePartialRejectionPopup}
        handleDialogAction={handlePartialRejectionUpdateActions}
      />)}
    </>
  );
};

export default ConsignmentEdit;
