import React, { forwardRef, ReactElement, Ref, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsignmentHistoryAndProducts, closeDialog, fetchConsignmentUnOrderedProducts } from "./redux/delveSlice";

import moment from "moment";

import { AppState } from "../../config/redux/reducers";
import { useStyles } from "./DelveOrderDetails.styles";

//Material UI Components
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { Grid } from "@material-ui/core";

//Common Components
import CustomDialog from "../../common/Dialog";
import { Product, getCratesFromProducts, getSortedProducts, getUnOrderedProductsFromMetaData, getValueFromMetaData } from "../../mocks/delve/response.transforms";

//Utils
import { isArrayNonEmpty, isNonEmptyObject, isUndefined, localTimeString } from "utils/helpers.utils";
import AppConstants from "../../constants";

// Components
import Loader from "../../common/Loader";
import DelveOrderDetailsTitleContent from "./DelveOrderDetailsCommon/DelveOrderDetailsTitleContent";
import DelveOrderDetailsConsignmentDetails from "./DelveOrderDetailsCommon/DelveOrderDetailsConsignmentDetails";
import DelveOrderDetailsItemsSummaryContent from "./DelveOrderDetailsCommon/DelveOrderDetailsItemsSummaryContent";
import DelveOrderDetailsHubAddressContent from "./DelveOrderDetailsCommon/DelveOrderDetailsHubAddressContent";
import DelveOrderDetailsCustomerDetailsContent from "./DelveOrderDetailsCommon/DelveOrderDetailsCustomerDetailsContent";
import DelveOrderDetailsByCratesDetailsContent from "./DelveOrderDetailsCommon/DelveOrderDetailsByCratesDetailsContent";
import DelveOrderDetailsItemReportedTitleContent from "./DelveOrderDetailsCommon/DelveOrderDetailsItemReportedTitleContent";
import DelveOrderDetailsItemReportedDetailsContent from "./DelveOrderDetailsCommon/DelveOrderDetailsItemReportedDetailsContent";
import {
  CONSIGNMENT_OBJECT_INTERFACE,
  CRATES_DETAILS_OBJECT_INTERFACE,
  CUSTOMER_CONTACT_INFO_ADDRESS_INTERFACE,
  CUSTOMER_CONTACT_INFO_INTERFACE,
  DELIVERY_INFORMATION_INTERFACE,
  DELVE_ITEMS_INTERFACE,
  DELVE_META_DATA_INTERFACE,
  GEO_LOCATION_OBJECT_INTERFACE,
  POS_INFORMATION_CONTACT_INFO_INTERFACE,
  UN_ORDERED_PRODUCTS_RECORD_INTERFACE,
} from "../../constants/DelveOrderListConstant";

interface DelveOrderDetailsProps {
  open: boolean;
  consignment: any;
  closePopup: () => void;
  tabValue: any;
}

const Transition = forwardRef(function Transition(props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DelveOrderDetails = (props: DelveOrderDetailsProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { productsList, productsAfterUpdateLineItem, ordersData, unOrderedProductsList } = useSelector((state: AppState) => state.delveOrderList);
  const { countryCode } = useSelector((state: AppState) => state.common);

  const { open, consignment, closePopup, tabValue } = props;

  const [toggleCustMap, setToggleCustMap] = useState(false);
  const [toggleSellerMap, setToggleSellerMap] = useState(false);
  const [showOriginalName, setShowOriginalName] = useState(false);
  const [orderDetailsState, setOrderDetailsState] = useState({
    loading: false,
    deliveryInformation: {} as DELIVERY_INFORMATION_INTERFACE,
    metadata: {} as DELVE_META_DATA_INTERFACE,
    custInfo: {} as CUSTOMER_CONTACT_INFO_INTERFACE,
    custGeoLocation: { name: "", location: { lat: 0, lng: 0 } } as GEO_LOCATION_OBJECT_INTERFACE,
    sellerGeoLocation: { name: "", location: { lat: 0, lng: 0 } } as GEO_LOCATION_OBJECT_INTERFACE,
    posInfo: { address: {} } as POS_INFORMATION_CONTACT_INFO_INTERFACE,
    items: [] as DELVE_ITEMS_INTERFACE[],
    crates_details_object: {} as CRATES_DETAILS_OBJECT_INTERFACE,
    deliveryDate: "" as string,
    deliverySlot: "" as string,
    hubName: "" as string,
    unOrderedProductsRecord: [] as Array<any>,
  });

  const [reported_item_view, set_reported_item_view] = useState({ open: false, crateId: "" });

  let itemsVerified = ordersData.elements[0] && ordersData.elements[0].metadata && getValueFromMetaData(ordersData.elements[0].metadata, tabValue, "itemsVerified");

  const dialogPaperProps = { classes: { root: classes.dialogPaperPropsRoot }, square: true };

  const designProductAndCratesObject = (consignment: CONSIGNMENT_OBJECT_INTERFACE, productsList: Product, unOrderedProductsList?: any) => {
    if (consignment) {
      const deliveryInformation = consignment.deliveryInformation;
      const metadata = consignment.metadata;
      const custInfo: CUSTOMER_CONTACT_INFO_INTERFACE = deliveryInformation && deliveryInformation.customerContactInfo;
      const custGeoLocation = { name: "", location: { lat: 0, lng: 0 } };
      if (custInfo && custInfo.address) {
        custGeoLocation.name = custInfo.name;
        custGeoLocation.location.lat = parseFloat(custInfo.address.latitude) || 0;
        custGeoLocation.location.lng = parseFloat(custInfo.address.longitude) || 0;
      }
      let posInfo = { address: {} } as POS_INFORMATION_CONTACT_INFO_INTERFACE;
      let hubName = "" as string;
      if (consignment.posInformation) {
        posInfo = consignment.posInformation.contactInfo;
        hubName = consignment.posInformation.storeName || consignment.posInformation.store;
      }
      let deliveryDate = "";
      let deliverySlot = "";
      if (deliveryInformation && deliveryInformation.deliverySlot) {
        const startTime = new Date(deliveryInformation.deliverySlot.startTime);
        const endTime = new Date(deliveryInformation.deliverySlot.endTime);
        deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.DATE_FORMAT);
        deliverySlot = `${localTimeString(startTime)} - ${localTimeString(endTime)}`;
      }
      const sellerGeoLocation = { name: "", location: { lat: 0, lng: 0 } };
      if (posInfo && posInfo.address) {
        sellerGeoLocation.name = hubName;
        sellerGeoLocation.location.lat = parseFloat(posInfo.address.latitude) || 0;
        sellerGeoLocation.location.lng = parseFloat(posInfo.address.longitude) || 0;
      }
      let items = getSortedProducts(productsList, tabValue, unOrderedProductsList);
      if (productsAfterUpdateLineItem && productsAfterUpdateLineItem.length > 0) {
        items = productsAfterUpdateLineItem;
      }
      let crates_details_object = getCratesFromProducts(productsList, tabValue, unOrderedProductsList);

      setOrderDetailsState({
        ...orderDetailsState,
        loading: false,
        deliveryInformation,
        metadata,
        custInfo,
        custGeoLocation,
        posInfo,
        hubName,
        deliveryDate,
        deliverySlot,
        sellerGeoLocation,
        items,
        crates_details_object,
      });
    }
  };

  const handleStatusHistoryAndProducts = async (consignment: CONSIGNMENT_OBJECT_INTERFACE) => {
    if (!isUndefined(consignment) && isNonEmptyObject(consignment) && Object.keys(consignment).length) {
      setOrderDetailsState({ ...orderDetailsState, loading: true });
      let consignmentCode = consignment && consignment.consignmentCode;
      const productsList = await dispatch(fetchConsignmentHistoryAndProducts({ params: consignmentCode, tabValue: tabValue }));
      if (!isUndefined(productsList) && !isUndefined(productsList.payload) && !isUndefined(productsList.payload.products)) {
        const unOrderedProducts: boolean = getUnOrderedProductsFromMetaData(consignment, tabValue);
        if (unOrderedProducts) {
          const unOrderedProductsList = await dispatch(fetchConsignmentUnOrderedProducts({ params: consignmentCode }));
          if (!isUndefined(unOrderedProductsList) && !isUndefined(unOrderedProductsList.payload) && !isUndefined(unOrderedProductsList.payload.results)) {
            designProductAndCratesObject(consignment, productsList.payload.products, unOrderedProductsList.payload.results);
          }
        } else {
          designProductAndCratesObject(consignment, productsList.payload.products);
        }
      } else {
        setOrderDetailsState({ ...orderDetailsState, loading: false });
      }
    }
  };

  const handleOriginalName = () => {
    if (!showOriginalName) {
      setShowOriginalName(true);
    } else {
      setShowOriginalName(false);
    }
  };

  const handleDialogClose = () => {
    closePopup();
    dispatch(closeDialog());
  };

  const getTitleContent = () => {
    return <DelveOrderDetailsTitleContent classes={classes} consignment={consignment} metadata={orderDetailsState.metadata} />;
  };

  const updateToggleSellerMap = (toggleSellerMap: boolean) => {
    setToggleSellerMap(toggleSellerMap);
  };

  const updateToggleCustMap = (toggleCustMap: boolean) => {
    setToggleCustMap(toggleCustMap);
  };

  const show_reported_item_view = (open: boolean, crateId: string) => {
    set_reported_item_view({ open: open, crateId: crateId });
  };

  const handle_close_reported_item_view = () => {
    set_reported_item_view({ open: false, crateId: "" });
  };

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <DelveOrderDetailsConsignmentDetails
          classes={classes}
          consignment={consignment}
          deliveryDate={orderDetailsState.deliveryDate}
          deliverySlot={orderDetailsState.deliverySlot}
          metadata={orderDetailsState.metadata}
          custInfo={orderDetailsState.custInfo}
          deliveryInformation={orderDetailsState.deliveryInformation}
          items={orderDetailsState.items}
          itemsVerified={itemsVerified}
          tabValue={tabValue}
        />
        <DelveOrderDetailsByCratesDetailsContent
          classes={classes}
          metadata={orderDetailsState.metadata}
          crates_details_object={orderDetailsState.crates_details_object}
          show_reported_item_view={show_reported_item_view}
          tabValue={tabValue}
        />
        <DelveOrderDetailsItemsSummaryContent
          classes={classes}
          items={orderDetailsState.items}
          itemsVerified={itemsVerified}
          metadata={orderDetailsState.metadata}
          deliveryInformation={orderDetailsState.deliveryInformation}
          showOriginalName={showOriginalName}
          handleOriginalName={handleOriginalName}
          tabValue={tabValue}
        />
        <DelveOrderDetailsCustomerDetailsContent
          classes={classes}
          custInfo={orderDetailsState.custInfo}
          deliveryInformation={orderDetailsState.deliveryInformation}
          toggleCustMap={toggleCustMap}
          custGeoLocation={orderDetailsState.custGeoLocation}
          updateToggleCustMap={updateToggleCustMap}
        />
        <DelveOrderDetailsHubAddressContent
          classes={classes}
          hubName={orderDetailsState.hubName}
          posInfo={orderDetailsState.posInfo}
          toggleSellerMap={toggleSellerMap}
          custGeoLocation={orderDetailsState.custGeoLocation}
          sellerGeoLocation={orderDetailsState.sellerGeoLocation}
          updateToggleSellerMap={updateToggleSellerMap}
        />
      </Grid>
    );
  };

  const getReportedItemTitleContent = () => {
    return <DelveOrderDetailsItemReportedTitleContent classes={classes} crateId={reported_item_view.crateId} handleClose={handle_close_reported_item_view} />;
  };

  const getReportedItemDetailsContent = () => {
    return (
      <DelveOrderDetailsItemReportedDetailsContent
        classes={classes}
        crateId={reported_item_view.crateId}
        metadata={orderDetailsState.metadata}
        crates_details_object={orderDetailsState.crates_details_object}
      />
    );
  };

  useEffect(() => {
    if (!isUndefined(consignment) && isNonEmptyObject(consignment) && Object.keys(consignment).length) {
      handleStatusHistoryAndProducts(consignment);
    }
  }, [consignment]);

  return (
    <>
      {orderDetailsState.loading ? (
        <Loader></Loader>
      ) : (
        <>
          <CustomDialog
            open={open}
            TransitionComponent={Transition}
            PaperProps={dialogPaperProps}
            title={getTitleContent()}
            content={getDetailsContent()}
            actions={null}
            handleClose={handleDialogClose}
          ></CustomDialog>

          {/* Reported Items View */}
          <CustomDialog
            hideCloseIcon={true}
            open={reported_item_view.open}
            TransitionComponent={Transition}
            PaperProps={dialogPaperProps}
            title={getReportedItemTitleContent()}
            content={getReportedItemDetailsContent()}
            actions={null}
            handleClose={handle_close_reported_item_view}
          ></CustomDialog>
        </>
      )}
    </>
  );
};

export default DelveOrderDetails;