import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { AppState } from '../../config/redux/reducers';
//Material UI Components
import { Grid, Typography } from "@material-ui/core";
import _ from "lodash";

//Style
import { useStyles } from "./hubwiseDashboard.styles";

//Constants
import AppConstants from "../../constants";

//Common Components
import SelectBox from "../../common/SelectBox";
import DateRangePickerComponent from "../../common/DateRangeComponent/index";
import Loader from "../../common/Loader";
import calendarIcon from "../../assets/icons/calendar-icon.svg";
import HubsGrid from "./HubsGrid";
import { fetchConsignmentsSlotsHubWise, fetchFiltersHubWise, fetchHubWiseData } from "./redux/hubWiseSlice";
import { createHubWisePerformancePayload } from "../../mocks/hubWiseDashboard/response.transforms";
import CustomSnackbar from '../../common/CustomSnackbar';
import { userLogout } from "../../Login/redux/loginSlice";

interface DashboardProps { }

const HubwiseDashboard = (props: DashboardProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { countryCode, hubCode } = useSelector(
        (state: AppState) => state.common
    );
    const { filtersDropdowns, hubWiseDataLoading, error, errorCode, hubWiseDataList } = useSelector(
        (state: AppState) => state.hubWise,
    );

    const { allSlots } = useSelector(
        (state: AppState) => state.hubWise, _.isEqual
    );
    const country = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value;
    const [category, setCategory] = useState(AppConstants.CATEGORY.value);
    const [orderType, setOrderType] = useState(AppConstants.DELIVERY_TYPE.value);
    const [slots, setSlots] = useState(() => [AppConstants.SELECT_ALL.value]);
    const [datePickerValue, setDatePickerValue] = useState({
        startDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
        endDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
    });
    const count = 0;
    const rowsPerPage = useState(100);
    const pageNo = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
    const snackbarMessage = useRef("");
    const [isThroughHandler, setIsThroughHandler] = useState(false);

    const openSnackbarPopup = (msg: string, type: string) => {
        snackbarMessage.current = "No Data Available";
        snackbarType.current = type;
        setOpenSnackbar(true);
    }

    const handleSnackbarClose = useCallback(
        () => {
            setOpenSnackbar(false);
        },
        [],
    );

    const handleSnackbarExited = useCallback(
        () => {
            if (errorCode === AppConstants.DASHBOARD_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
                dispatch(
                    userLogout()
                );
            }
        },
        [dispatch, errorCode]
    );

    const handleDateChange = ((dateRange: any) => {
        setDatePickerValue(dateRange);
        setIsThroughHandler(true);
        handleHubWisePerformanceParameters({
            slots: slots,
            category: category,
            hubCode: hubCode,
            dateRange: dateRange,
            orderType: orderType,
        });
    });

    const handleCategoryChange = ((selectedCategory: any) => {
        setCategory(selectedCategory);
        setIsThroughHandler(true);
    });

    const handleDeliverySlotChange = ((selectedSlots: any) => {
        setSlots(selectedSlots);
        setIsThroughHandler(true);
        handleHubWisePerformanceParameters({
            slots: selectedSlots,
            category: category,
            hubCode: hubCode,
            dateRange: datePickerValue,
            orderType: orderType,
        });
    });

    const handleOrderTypeChange = ((orderTypeValue: any) => {
        setOrderType(orderTypeValue);
        setIsThroughHandler(true);
        handleHubWisePerformanceParameters({
            slots: slots,
            category: category,
            hubCode: hubCode,
            dateRange: datePickerValue,
            orderType: orderTypeValue,
        });
    });


    useEffect(() => {
        if (error) {
            if (error === "INTERNAL_SERVER_ERROR") {
                snackbarMessage.current = "Something went wrong, Please try again later";
                snackbarType.current = AppConstants.SNACKBAR.TYPES.ERROR;
                setOpenSnackbar(true);
            } else {
                openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
            }
        }
    }, [error])

    const handleConsignmentsSlots = useCallback(
        (params: any) => {
            const updatedParams = createHubWisePerformancePayload({
                ...params,
                countryCode: country,
            });
            dispatch(
                fetchConsignmentsSlotsHubWise({
                    params: updatedParams,
                }),
            );
        },
        [dispatch, country]
    );

    const fetchAllFilters = useCallback(
        () => {
            dispatch(
                fetchFiltersHubWise()
            )
        },
        [dispatch]
    );

    useEffect(() => {
        fetchAllFilters();
        if (category === AppConstants.CATEGORY.value) {
            handleConsignmentsSlots({
                slots: slots,
                category: category,
                hubCode: hubCode,
                dateRange: datePickerValue,
                orderType: orderType,
            });
        }  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datePickerValue, category, orderType, hubCode])

    const handleHubWisePerformanceParameters = useCallback(
        (params: any) => {
            const updatedParams = createHubWisePerformancePayload({
                ...params,
                countryCode: country,

            });
            dispatch(
                fetchHubWiseData({
                    params: updatedParams,
                }),
            );
        },
        [dispatch, country]
    );

    useEffect(() => {
        if (!isThroughHandler) {
            handleHubWisePerformanceParameters({
                slots: slots,
                category: category,
                hubCode: hubCode,
                dateRange: datePickerValue,
                orderType: orderType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datePickerValue, category, orderType, hubCode, slots])


    return (
        <div className={classes.root}>
            <Grid container justify="space-between">
                <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start", width: "50%" }}>
                    <Typography className={classes.title}>Dashboard</Typography>
                </Grid>
                <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid container spacing={2} style={{ flexWrap: "nowrap" }}>
                        <Grid item className={classes.tilesView}>
                            <SelectBox
                                fullWidth
                                className={classes.select}
                                value={category}
                                id="category"
                                label="Category"
                                items={AppConstants.CATEGORIES_HUBWISE.category}
                                handleChange={handleCategoryChange}
                            ></SelectBox>
                        </Grid>
                        <Grid item className={classes.tilesView}>
                            <SelectBox
                                fullWidth
                                className={classes.select}
                                value={orderType}
                                id="deliveryType"
                                label="Delivery Type"
                                items={filtersDropdowns.orderTypeList[country] || []}
                                handleChange={handleOrderTypeChange}
                            ></SelectBox>
                        </Grid>
                        <Grid item className={classes.tilesView}>
                            <SelectBox
                                fullWidth
                                className={classes.select}
                                value={slots}
                                id="slot"
                                label="Slots"
                                multiple
                                items={allSlots || []}
                                handleChange={handleDeliverySlotChange}
                            ></SelectBox>
                        </Grid>
                        <Grid item className={classes.tilesView}>
                            <DateRangePickerComponent
                                fullWidth
                                keyId="create-route-date-picker"
                                label="Date"
                                className={classes.icon}
                                value={datePickerValue}
                                isTextField={true}
                                iconSrc={calendarIcon}
                                autoUpdateInput={false}
                                autoApply={true}
                                maxSpan={{
                                    day: "0",
                                }}
                                showDropdowns={true}
                                linkedCalendars={true}
                                locale={{
                                    format: AppConstants.DATE_FORMAT,
                                }}
                                updateDateRange={handleDateChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                <>
                    {hubWiseDataLoading && <Loader></Loader>}
                    {hubWiseDataList.length ? (
                        <HubsGrid
                            count={count}
                            page={pageNo}
                            rowsPerPage={rowsPerPage}
                            classes={classes}
                            hubList={hubWiseDataList}
                        />
                    )
                        : (
                            <div style={{ height: "calc(100vh - 305px)" }}>
                                <div className={classes.container} style={{ marginTop: "24px", height: "120%", marginBottom: "24px" }}>
                                    <div className={classes.loadingDivInner}>
                                        <span className={classes.spanStyle}>No Data Available</span>
                                    </div>
                                </div>
                            </div>
                        )}
                </>
            }
            <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
        </div >
    );
};

export default HubwiseDashboard;
