import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers";

export default configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['OrderList/ExportToExcel/fulfilled', 'Common/MapDirectionsUpdate/fulfilled'],
      ignoredPaths: ['common.mapRouteDirections'],
    }
  }),
});
