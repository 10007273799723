const StagingAreaConstants = {
  STAGING_AREA_CONSTANTS: {
    VALIDATIONS: {
      NAME: {
        REQUIRED: {
          msg: "Name is required",
        },
        VALIDITY: {
          msg: "Special Case Character not allowed",
          value: /^[a-z0-9]+$/i,
        },
        MIN: {
          msg: "Minimum 2 character required",
          value: 2,
        },
        MAX: {
          msg: "should not be more than 4 character",
          value: 4,
        },
      },
    },
    SUCCESS_MSG: {
      create: "Staging location successfully added",
      update: "Staging location successfully updated",
      delete: "Staging location deleted successfully",
    },
  },
};
export default StagingAreaConstants;
