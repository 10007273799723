import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 24,
          fontWeight: 600,
          '& .content': {
            fontWeight: 600
          }
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        }
      },
      '& .tagContainer': {
        marginLeft: theme.spacing(1),
        '& .tag': {
          color: COLORS.WHITE,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5, 1),
          lineHeight: "14px",
          backgroundColor: COLORS.BLACK,
          fontWeight: 600,
          textTransform: "uppercase",
          fontSize: 12,
          '&.PICTON_BLUE': {
            backgroundColor: COLORS.PICTON_BLUE
          },
          '&.MINSK': {
            backgroundColor: COLORS.SCAMPI
          },
          '&.AMBER': {
            backgroundColor: COLORS.AMBER
          },
          '&.SEA_GREEN': {
            backgroundColor: COLORS.PERSIAN_GREEN_DARK
          },
          '&.RED': {
            backgroundColor: COLORS.PALE_RED
          },
          '&.MADISON_BLUE': {
            backgroundColor: COLORS.GREEN_BLUE
          },
          '&.DUSTY_GRAY': {
            backgroundColor: COLORS.DUSTY_GRAY
          }
        }
      },
    },
    contentContainer: {
      borderTop: "1px solid #E6E6E5",
      flexDirection: "column",
      flexWrap: "nowrap",
      height: "58px",
      '& .content': {
        padding: "12px",
        /* '& .itemColumn': {
          padding: theme.spacing(0),
          '&.item1': {
            "& p": {
              fontWeight: 400,
              textTransform: "capitalize"
            },
            '& .rejectedReason': {
              fontSize: 10,
              color: COLORS.ALIZARIN_CRIMSON
            },
            '& .viewOriginalLink': {
              fontSize: 9,
              color: COLORS.COPY,
              cursor: "pointer",
              paddingRight: 6
            },
            '& .originalName': {
              fontSize: 14,
              lineHeight: "17px",
              fontWeight: 400,
            }
          },
          '&.item2': {
            '& p': {
              textAlign: "center",
              '& .green': {
                color: COLORS.PERSIAN_GREEN
              }
            }
          },
          '&.item3': {
            '& p': {
              textAlign: "right"
            }
          }
        } */
      },
      '& .itemContainer': {
        borderBottom: "1px solid #E6E6E6",
        padding: "14px",
        '& .itemColumn': {
          padding: theme.spacing(0),
          '&.item1': {
            "& p": {
              fontWeight: 400,
              textTransform: "capitalize"
            },
            '& .rejectedReason': {
              fontSize: 10,
              color: COLORS.ALIZARIN_CRIMSON
            },
            '& .viewOriginalLink': {
              fontSize: 9,
              color: COLORS.COPY,
              cursor: "pointer",
              paddingRight: 6
            },
            '& .originalName': {
              fontSize: 14,
              lineHeight: "17px",
              fontWeight: 400,
            }
          },
          '&.item2': {
            '& p': {
              textAlign: "center",
              '& .green': {
                color: COLORS.PERSIAN_GREEN
              }
            }
          },
          '&.item3': {
            '& p': {
              textAlign: "right"
            }
          }
        }
      },
      '& .itemsContent': {
        fontSize: 14,
        fontWeight: 500,
        color: "#252525",
        lineHeight: "17px",
        '& p': {
          textAlign: "center",
        },
        '& .green': {
          color: COLORS.PERSIAN_GREEN
        },
        '& .new': {
          borderRadius: "4px",
          minWidth: "0px",
          '& .MuiSelect': {
            select: {
              fontSize: "10px",
              fontWeight: 500
            }
          }
        },
        '& .select': {
          borderRadius: "4px",
          minWidth: "0px",
          width: "62px"
        },
        '& .MuiOutlinedInput': {
          root: {
            borderRadius: "4px",
            minWidth: "0px"
          }
        }
      },
      '& .paymentContainer': {
        padding: 12,
        '& .totalDivider': {
          margin: "11px 0px"
        },
        '& .paymentItem': {
          '&:first-child p': {
            fontSize: 16,
            fontWeight: 600
          },
          '& .vat': {
            fontWeight: 600,
            fontSize: 16,
            marginLeft: "4px"
          },
          padding: "5px 0",
          '& .paymentItemColumn': {
            '& p': {
              fontSize: 14,
              lineHeight: "17px"
            },

            '&.right': {
              marginLeft: "auto"
            }
          }
        }
      }
    },
    selectRoot: {
      color: "#2D2D2D",
      fontSize: "10px",
      fontWeight: 500
    },
    icon: {
      color: "#000000"
    },
    generalReasonRoot: {
      fontSize: "12px",
      fontWeight: 600,
      color: "#0E5AA7"
    },
    generalReasonIcon: {
      color: "#000000"
    },
    menuPropsList: {
      '& .MuiListItem-root': {
        fontSize: 20,
        whiteSpace: "normal",
        height: "55px"
      },
    },
    menuPropsPaper: {
      maxHeight: 200,
      minWidth: 200,
      border: `1px solid ${COLORS.WHITE}`,
      background: "#FFFFFF",
      borderRadius: "0px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      overflow: "auto",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      '& .MuiList-padding': {
        paddingTop: "0px",
        paddingBottom: "0px"
      }
    },
    title: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Montserrat",
      color: "#505050"
    },
    buttons: {
      width: "100%",
      '& .userBtn': {
        '&.secondary': {
          marginRight: theme.spacing(2)
        },
        flexGrow: 1
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 636,
    },
    '& .react-pdf__Page': {
      marginTop: "10px",
    },
    '& .react - pdf__Page__textContent': {
      border: "1px solid darkgrey",
      boxShadow: "5px 5px 5px 1px #ccc",
      borderRadius: "5px",
    },
    '& .react - pdf__Page__annotations.annotationLayer': {
      padding: "20px",
    },
    '& .react - pdf__Page__canvas': {
      margin: "0 auto",
    },
  })
);