import { generateRandomNumber } from "./../utils/helpers.utils";

export enum ACTION_BUTTON {
  PUBLISH = 'publish', 
  BULK_DELETE ="bulkDelete"
}

const PlanningConstants = {
  PLANNING_CONSTANTS: {
    CONSIGNMENTS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Orders",
        HeaderData: [
          {
            field: 'id',
            headerName: 'Id',
            hide: true
          },
          {
            field: 'hubCode',
            headerName: 'Hub Code',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubCode",
              fieldName: "hub",
              fieldFullName: "c.posInformation.store",
              multiple: true,
              key: `autoComplete-hub-${generateRandomNumber(0, 1000)}`,
              label: "Hub Code",
              items: []
            }
          },
          {
            field: 'hubName',
            headerName: 'Hub Name',
            width: 200,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubName",
              fieldName: "hubName",
              fieldFullName: "c.posInformation.store",
              multiple: true,
              key: `autoComplete-hubName-${generateRandomNumber(0, 1000)}`,
              label: "Hub Name",
              items: []
            }
          },
          {
            field: 'consignmentId',
            headerName: 'Consignment ID',
            width: 150
          },
           {
            field: "deliveryArea",
            headerName: "Customer Area",
            width: 180,
            tooltip: true,
            tooltipDataKey: "address",
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryArea",
              fieldName: "deliveryArea",
              fieldFullName:
                "c.deliveryInformation.customerContactInfo.address.area",
              multiple: true,
              key: `autoComplete-deliveryArea-${generateRandomNumber(0, 1000)}`,
              label: "Area",
              category: "std Area",
              items: [],
            },
          },
            {
            field: "deliveryZone", 
            headerName: "Zone",
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryZone",
              fieldName: "deliveryZone",
              fieldFullName: "c.metadata.deliveryZone",
              multiple: true,
              key: `autoComplete-deliveryZone-${generateRandomNumber(0, 1000)}`,
              label: "Zone",
              items: []
            }
          },
            {
            field: "addressStatus",
            headerName: "Address Status",
            width: 160,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "addressStatus",
              fieldName: "addressStatus",
              fieldFullName:
                "c.deliveryInformation.customerContactInfo.address.status",
              multiple: true,
              key: `autoComplete-addressStatus-${generateRandomNumber(0, 1000)}`,
              label: "Address Status",
              items: [],
            },
          },
          {
            field: 'type',
            headerName: 'Type',
            width: 120,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "type",
              fieldName: "orderType",
              fieldFullName: "c.metadata.category",
              multiple: true,
              key: `autoComplete-orderType-${generateRandomNumber(0, 1000)}`,
              label: "Order Type",
              items: [],
            }
          },
          {
            type: "date",
            field: 'deliveryDate',
            headerName: 'Delivery Date',
            width: 160,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "deliveryDate",
              fieldName: "dateRange",
              key: `dateRange-filter-${generateRandomNumber(0, 1000)}`,
              placeholderText: 'Delivery Date',
              label: 'Delivery Date',
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: {
                month: "1"
              },
              locale: {},
            }
          },
          {
            field: 'startEndTime',
            headerName: 'Delivery Slot',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldName: "deliverySlot",
              multiple: true,
              key: `autoComplete-deliverySlot-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Slot",
              items: [],
              fieldKey: "startEndTime",
            }
          },
          {
            field: 'deliveryStatus',
            headerName: 'Status',
            width: 170,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryStatus",
              fieldName: "deliveryStatus",
              fieldFullName: "c.metadata.deliveryStatus",
              multiple: true,
              key: `autoComplete-delStatus-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Status",
              items: []
            }
          },
          {
            field: 'statusDate',
            headerName: 'Last Updated',
            width: 160
          },
          {
            field: 'numberOfItems',
            headerName: 'No. of Items',
            width: 130
          },
          {
            field: 'crates',
            headerName: 'Crates',
            width: 80
          },
          {
            field: 'orderId',
            headerName: 'Order ID',
            width: 130
          },
          {
            field: 'transactionDate',
            headerName: 'Order Creation Date',
            width: 180
          },
          {
            field: 'customerName',
            headerName: 'Customer Name',
            width: 150
          },
          {
            field: 'customerEmail',
            headerName: 'Customer Email',
            width: 150
          },
          {
            field: 'customerMobileNumber',
            headerName: 'Customer Mobile',
            width: 160
          },
          {
            field: 'paymentType',
            headerName: 'Payment Type',
            width: 160,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "paymentType",
              fieldName: "paymentType",
              fieldFullName: "c.metadata.paymentType",
              multiple: true,
              key: `autoComplete-paymentType-${generateRandomNumber(0, 1000)}`,
              label: "Payment Type",
              items: []
            }
          },
          {
            field: 'totalAmount',
            headerName: 'Total Amount',
            width: 140
          },
          {
            field: 'codAmount',
            headerName: 'COD Amount',
            width: 130
          },
          {
            field: 'collectedAmount',
            headerName: 'Collected Amount',
            width: 160
          },
          {
            field: 'paybill',
            headerName: 'Pay Bill No',
            width: 160,
            hide: true
          },
          {
            field: 'mpesaphoneNumber',
            headerName: 'MPesa Request Phone',
            width: 160,
            hide: true
          },
          {
            field: 'refNo',
            headerName: 'Reference No',
            width: 180,
            hide: true
          },
          {
            field: 'requestedAmt',
            headerName: 'Amount Requested',
            width: 140,
            hide: true
          },
          {
            field: 'mpesaAttemptNo',
            headerName: 'MPesa Attempt No',
            width: 140,
            hide: true
          },
          {
            field: 'mpesaStatus',
            headerName: 'MPesa Status',
            width: 180,
            hide: true
          },
          {
            field: 'timestamp',
            headerName: 'Timestamp',
            width: 160,
            hide: true
          },
          {
            field: 'carrier',
            headerName: 'Carrier',
            width: 120,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "carrier",
              fieldName: "carrier",
              fieldFullName: "c.deliveryInformation.carrier",
              multiple: true,
              key: `autoComplete-carrier-${generateRandomNumber(0, 1000)}`,
              label: "Carrier",
              items: []
            }
          },
          {
            field: 'deliveryProposition',
            headerName: 'Delivery Proposition',
            width: 200,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryProposition",
              fieldName: "deliveryProposition",
              fieldFullName: "c.metadata.deliveryType",
              multiple: true,
              key: `autoComplete-deliveryProposition-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Proposition",
              items: []
            }
          },
          {
            field: 'routeId',
            headerName: 'Route ID',
            width: 130,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "routeId",
              fieldName: "routeId",
              fieldFullName: "c.metadata.routeName",
              multiple: true,
              key: `autoComplete-routeId-${generateRandomNumber(0, 1000)}`,
              label: "Route ID",
              items: []
            }
          },
          {
            field: 'routeStatus',
            headerName: 'Route Status',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "routeStatus",
              fieldName: "routeStatus",
              fieldFullName: "c.metadata.routeStatus",
              multiple: true,
              key: `autoComplete-routeStatus-${generateRandomNumber(0, 1000)}`,
              label: "Route Status",
              items: []
            }
          },
          {
            field: 'driver',
            headerName: 'Driver',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "driver",
              fieldName: "driver",
              fieldFullName: "c.deliveryInformation.driver.id",
              multiple: true,
              key: `autoComplete-driver-${generateRandomNumber(0, 1000)}`,
              label: "Driver",
              items: []
            }
          },
           {
            field: 'vendor',
            headerName: 'Vendor',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "vendor",
              fieldName: "vendor",
              fieldFullName: "c.deliveryInformation.driver.vendor",
              multiple: true,
              key: `autoComplete-vendor-${generateRandomNumber(0, 1000)}`,
              label: "Vendor",
              items: []
            }
          },
          {
            field: 'vehicle',
            headerName: 'Vehicle',
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "vehicle",
              fieldName: "vehicle",
              fieldFullName: "c.deliveryInformation.vehicle.id",
              multiple: true,
              key: `autoComplete-vehicle-${generateRandomNumber(0, 1000)}`,
              label: "Vehicle",
              items: []
            }
          },
          {
            field: 'helper',
            headerName: 'Helper',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "helper",
              fieldName: "helper",
              fieldFullName: "c.deliveryInformation.helper.id",
              multiple: true,
              key: `autoComplete-helper-${generateRandomNumber(0, 1000)}`,
              label: "Helper",
              items: []
            }
          },
          {
            field: 'plannedDuration',
            headerName: 'Planned Duration (in mins)',
            width: 220
          },
          {
            field: "deliveryExecutionStatus",
            headerName: "Delivery Status",
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryExecutionStatus",
              fieldName: "deliveryExecutionStatus",
              fieldFullName: "c.deliveryInformation.deliveryExecutionStatus",
              multiple: true,
              key: `autoComplete-deliveryExecutionStatus-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Status",
              items: [],
            },
          },
          {
            field: 'actualDuration',
            headerName: 'Actual Duration (in mins)',
            width: 210
          },
          {
            field: "deliveryFailedReason",
            headerName: "Undelivered Reason",
            width: 200,
          },
        ],
        headerButtons: [
          {
            name: "createRoute",
            displayName: "CREATE ROUTE",
            disabled: false,
            hide: false,
            classes: 'primary'
          },
          {
            name: "colsettings",
            displayName: "Column Settings",
            disabled: false,
            childElements: [
              {
                name: "excel",
                displayName: "EXPORT DATA",
                disabled: false
              }
            ]
          }
        ],
        loadMore: {
          name: 'loadMore',
          disabled: false,
          pageSize: 20
        },
        defaultFiltersObj: {
          hub: {
            key: "hub",
            value: [],
            fieldName: 'c.posInformation.store'
          },
          hubName: {
            key: "hubName",
            value: [],
            fieldName: 'c.posInformation.store'
          },
          deliveryArea: {
            key: "deliveryArea",
            value: [],
            fieldName: 'c.deliveryInformation.customerContactInfo.address.area'
          },
           addressStatus: {
            key: "addressStatus",
            value: [],
            fieldName: 'c.deliveryInformation.customerContactInfo.address.status'
          },
          deliveryZone: {
            key: "deliveryZone",
            value: [],
            fieldName: 'c.metadata.deliveryZone'
          },
          orderType: {
            key: "orderType",
            value: [],
            fieldName: 'c.metadata.category'
          },
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
            fieldName: 'c.metadata.deliveryStatus'
          },
          paymentType: {
            key: "paymentType",
            value: [],
            fieldName: 'c.metadata.paymentType'
          },
          carrier: {
            key: "carrier",
            value: [],
            fieldName: 'c.deliveryInformation.carrier'
          },
          deliveryProposition: {
            key: "deliveryProposition",
            value: ["STD"],
            fieldName: 'c.metadata.deliveryType',
            ignoreCount:true
          },
          routeId: {
            key: "routeId",
            value: [],
            fieldName: 'c.metadata.routeId'
          },
          routeStatus: {
            key: "routeStatus",
            value: [],
            fieldName: 'c.metadata.routeStatus'
          },
          driver: {
            key: "driver",
            value: [],
            fieldName: 'c.deliveryInformation.driver.id'
          },
          vendor: {
            key: "vendor",
            value: [],
            fieldName: 'c.deliveryInformation.driver.vendor'
          },
          vehicle: {
            key: "vehicle",
            value: [],
            fieldName: 'c.deliveryInformation.vehicle.id'
          },
          helper: {
            key: "helper",
            value: [],
            fieldName: 'c.deliveryInformation.helper.id'
          },
          deliveryFailedReason: {
            key: "deliveryFailedReason",
            value: [],
            fieldName: 'c.reason.reasonCode'
          },
          deliveryExecutionStatus: {
            key: "deliveryExecutionStatus",
            value: [],
            fieldName: 'c.deliveryInformation.deliveryExecutionStatus'
          },
          pageNumber: 0,
          displayRouteStatus: {
            key: "displayRouteStatus",
            value: ['UNPLANNED'],
            fieldName: 'c.metadata.displayRouteStatus',
            ignoreCount:true
          },
          dateRange: {
            key: "dateRange",
            value: {
              startDate: new Date(),
              endDate: new Date()
            }
          },
          deliverySlot: {
            key: "deliverySlot",
            value: []
          }
        },
        SEARCH_TYPES: [
          {
            name: "Consignment Id",
            value: "c.consignmentCode"
          },
          {
            name: "Order Id",
            value: "c.orderCode"
          },
          {
            name: "Crate Id",
            value: "c.crateId"
          },
          {
            name: "Phone",
            value: "c.deliveryInformation.customerContactInfo.phone"
          },
          {
            name: "Name",
            value: "c.deliveryInformation.customerContactInfo.name"
          },
          {
            name: "Email",
            value: "c.deliveryInformation.customerContactInfo.email"
          }
        ],
        sortModel: {
          field: "deliveryDate",
          direction: "asc"
        }
      },
      FILTER_CHIPS: [
        {
          code: 'UNPLANNED',
          value: 'Unplanned'
        },
        {
          code: 'PLANNED',
          value: 'Planned'
        },
        {
          code: 'PUBLISHED',
          value: 'Published'
        },
        {
          code: 'PREPARING',
          value: 'Preparing'
        },
        {
          code: 'PREPARED',
          value: 'Prepared'
        },
        {
          code: 'OUT_FOR_DELIVERY',
          value: 'Out For Delivery'
        },
        {
          code: 'COMPLETED',
          value: 'Completed'
        },
        {
          code: "ALL",
          value: "All"
        }
      ],
      STATUSES: {
        "NEW": {
          color: "PICTON_BLUE",
          hintText: ""
        },
        "READY_TO_PREPARE": {
          color: "CREAM_CAN",
          hintText: ""
        },
        "READY_TO_DISPATCH": {
          color: "BLUE",
          hintText: ""
        },
        "PREPARING": {
          color: "VISTA_BLUE",
          hintText: ""
        },
        "PREPARED": {
          color: "SEA_GREEN",
          hintText: ""
        },
        "OUT_FOR_DELIVERY": {
          color: "GOLDEN_BELL",
          hintText: ""
        },
        "ON_HOLD": {
          color: "SCAMPI",
          hintText: ""
        },
        "PENDING_DELIVERY": {
          color: "BLUE",
          hintText: ""
        },
        "DELIVERED": {
          color: "SEA_GREEN2",
          hintText: ""
        },
        "PARTIALLY_DELIVERED": {
          color: "LUCKY",
          hintText: ""
        },
        "CANCELLED": {
          color: "DUSTY_GRAY",
          hintText: ""
        },
        "RETURNED_TO_STORE/HUB": {
          color: "JUNGLE_GREEN",
          hintText: ""
        },
        "DELIVERY_FAILED": {
          color: "BLACK",
          hintText: ""
        },
        "PACKING_COMPLETE": {
          color: "MINSK",
          hintText: ""
        },
        "ASSIGNED": {
          color: "BLUE",
          hintText: ""
        }
      },
      ORDER_STATUS: {
        NEW: "NEW",
        READY_TO_PREPARE: "READY_TO_PREPARE",
        READY_TO_DISPATCH: "READY_TO_DISPATCH",
        PREPARING: "PREPARING",
        PREPARED: "PREPARED",
        OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
        ON_HOLD: "ON_HOLD",
        PENDING_DELIVERY: "PENDING_DELIVERY",
        DELIVERED: "DELIVERED",
        PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
        CANCELLED: "CANCELLED",
        "RETURNED_TO_STORE/HUB": "RETURNED_TO_STORE/HUB",
        DELIVERY_FAILED: "DELIVERY_FAILED",
        PACKING_COMPLETE: "PACKING_COMPLETE",
        ASSIGNED:"ASSIGNED"
      },
      PC_NOT_RECEIVED: "PC_NOT_RECEIVED",
      COMPLETED: "COMPLETED",
      TERMINAL_STATUSES: ['DELIVERY_FAILED', 'CANCELLED', 'DELIVERED', 'PARTIALLY_DELIVERED', 'ON_HOLD','OUT_FOR_DELIVERY'],
      NON_EDITABLE_STATUSES: ['DELIVERY_FAILED', 'CANCELLED', 'PARTIALLY_DELIVERED', 'DELIVERED'],
      REFRESHABLE_STATUSES:['NEW'],
      CANCELLED: 'CANCELLED',
      DEFAULT_FILTER_CHIP: 'UNPLANNED',
      REPLANNING_CONSIGNMENT_SUCCESS_MSG: 'Consignment updated successfully.',
      ADDRESS_MARKED_UNVERIFIED: "Address has been marked as inaccurate",
      DEFAULT_REPLANNING_SELECTION: 'reschedule',
      COD_TEXT: "COD",
      CARD_ON_DELIVERY:"Card on delivery",
      DISPLAY_ROUTE_STATUS_FILTER_OBJ: {
        key: "displayRouteStatus",
        value: ['UNPLANNED'],
        fieldName: 'c.metadata.displayRouteStatus',
        ignoreCount:true
      },
      CRATE_ID: "Crate Id",
      TAB_VALUES_DETAIL: {
        ORDER_DETAIL: "Order Detail",
        ORDER_LOG: "Order",
      },
      DELIVERY_EXECUTION_STATUS: {
        DELAYED:{
          color: "ORANGE",
        },
        ON_TIME:{
          color: "SUCCESS_GREEN",
        },
      },
    },
   
    ROUTES_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Routes",
        HeaderData: [
          {
            field: 'id',
            headerName: 'Id',
            hide: true
          },
          {
            field: 'hubCode',
            headerName: 'Hub Code',
            width: 165,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubCode",
              fieldName: "hub",
              fieldFullName: "c.hubCode",
              multiple: true,
              key: `autoComplete-hub-${generateRandomNumber(0, 1000)}`,
              label: "Hub Code",
              items: []
            }
          },
          {
            field: 'hubName',
            headerName: 'Hub Name',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubName",
              fieldName: "hubName",
              fieldFullName: "c.hubCode",
              multiple: true,
              key: `autoComplete-hubName-${generateRandomNumber(0, 1000)}`,
              label: "Hub Name",
              items: []
            }
          },
          {
            field: 'routeId',
            headerName: 'Route ID',
            width: 155,
            filter: true,
            showArrowIcon:true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "routeId",
              fieldName: "routeId",
              fieldFullName: "c.routeId",
              multiple: true,
              key: `autoComplete-routeId-${generateRandomNumber(0, 1000)}`,
              label: "Route ID",
              items: []
            }
          },
          {
            field: 'deliveryArea',
            headerName: 'Area',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryArea",
              fieldName: "deliveryArea",
              fieldFullName: "c.deliveryArea",
              multiple: true,
              key: `autoComplete-deliveryArea-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Area",
              items: []
            }
          },
          {
            type: "date",
            field: 'deliveryDate',
            headerName: 'Delivery Date',
            width: 160,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "deliveryDate",
              fieldName: "dateRange",
              key: `dateRange-filter-${generateRandomNumber(0, 1000)}`,
              placeholderText: 'Delivery Date',
              label: 'Delivery Date',
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: {
                month: "1"
              },
              locale: {},
            }
          },
          {
            field: 'deliverySlot',
            headerName: 'Delivery Slot',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldName: "deliverySlot",
              multiple: true,
              key: `autoComplete-deliverySlot-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Slot",
              items: [],
              fieldKey: "deliverySlot",
            }
          },
          {
            field: 'consignments',
            headerName: 'Consignments',
            width: 110
          },
          {
            field: 'crates',
            headerName: 'Crates',
            width: 80
          },
          {
            field: 'carrier',
            headerName: 'Carrier',
            width: 120,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "carrier",
              fieldName: "carrier",
              fieldFullName: "c.carrier",
              multiple: true,
              key: `autoComplete-carrier-${generateRandomNumber(0, 1000)}`,
              label: "Carrier",
              items: []
            }
          },
          {
            field: 'deliveryProposition',
            headerName: 'Delivery Proposition',
            width: 200,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryProposition",
              fieldName: "deliveryProposition",
              fieldFullName: "c.deliveryProposition",
              multiple: true,
              key: `autoComplete-deliveryProposition-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Proposition",
              items: []
            },
          },
          {
            field: 'routeStatus',
            headerName: 'Route Status',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "routeStatus",
              fieldName: "routeStatus",
              fieldFullName: "c.status",
              multiple: true,
              key: `autoComplete-routeStatus-${generateRandomNumber(0, 1000)}`,
              label: "Route Status",
              items: []
            },
          },
          {
            field: 'driver',
            headerName: 'Driver',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "driver",
              fieldName: "driver",
              fieldFullName: "c.assignee.id",
              multiple: true,
              key: `autoComplete-driver-${generateRandomNumber(0, 1000)}`,
              label: "Driver",
              items: []
            }
          },
          {
            field: 'vehicle',
            headerName: 'Vehicle',
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "vehicle",
              fieldName: "vehicle",
              fieldFullName: "c.assignedVehicle.id",
              multiple: true,
              key: `autoComplete-vehicle-${generateRandomNumber(0, 1000)}`,
              label: "Vehicle",
              items: []
            }
          },
          {
            field: 'helper',
            headerName: 'Helper',
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "helper",
              fieldName: "helper",
              fieldFullName: "c.helper.id",
              multiple: true,
              key: `autoComplete-helper-${generateRandomNumber(0, 1000)}`,
              label: "Helper",
              items: []
            }
          },
          {
            field: 'createdBy',
            headerName: 'Created By',
            width: 220,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "createdBy",
              fieldName: "createdBy",
              fieldFullName: "c.createdBy",
              multiple: true,
              key: `autoComplete-createdBy-${generateRandomNumber(0, 1000)}`,
              label: "Created By",
              items: []
            }
          },
          {
            field: 'plannedDuration',
            headerName: 'Planned Duration',
            width: 180
          },
          {
            field: 'actualDuration',
            headerName: 'Actual Duration',
            width: 180
          },
          {
            field: 'actualDistance',
            headerName: 'Actual Distance',
            width: 180
          },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 100
          }
        ],
        headerButtons: [
          {
            name: ACTION_BUTTON.BULK_DELETE,
            displayName: "",
            disabled: false,
            classes: 'customButton',
            hide: false
          },
          {
            name: ACTION_BUTTON.PUBLISH,
            displayName: "PUBLISH ROUTES",
            disabled: false,
            hide: false,
            classes: 'primary'
          },
          {
            name: "colsettings",
            displayName: "Column Settings",
            disabled: false,
            childElements: [
              {
                name: "excel",
                displayName: "EXPORT DATA",
                disabled: false
              }
            ]
          }
        ],
        loadMore: {
          name: 'loadMore',
          disabled: false,
          pageSize: 20
        },
        defaultFiltersObj: {
          hub: {
            key: "hub",
            value: [],
            fieldName: 'c.hubCode'
          },
          hubName: {
            key: "hubName",
            value: [],
            fieldName: 'c.hubCode'
          },
          routeId: {
            key: "routeId",
            value: [],
            fieldName: 'c.routeId'
          },
          deliveryArea: {
            key: "deliveryArea",
            value: [],
            fieldName: 'c.deliveryArea'
          },
          carrier: {
            key: "carrier",
            value: [],
            fieldName: 'c.carrier'
          },
          deliveryProposition: {
            key: "deliveryProposition",
            value: [],
            fieldName: 'c.deliveryProposition'
          },
          routeStatus: {
            key: "routeStatus",
            value: [],
            fieldName: 'c.status'
          },
          driver: {
            key: "driver",
            value: [],
            fieldName: 'c.assignee.id'
          },
          vehicle: {
            key: "vehicle",
            value: [],
            fieldName: 'c.assignedVehicle.id'
          },
          helper: {
            key: "helper",
            value: [],
            fieldName: 'c.helper.id'
          },
          createdBy: {
            key: "createdBy",
            value: [],
            fieldName: 'c.createdBy'
          },
          pageNumber: 0,
          displayRouteStatus: {
            key: "displayRouteStatus",
            value: ['PLANNED'],
            fieldName: 'c.displayRouteStatus',
            ignoreCount:true
          },
          dateRange: {
            key: "dateRange",
            value: {
              startDate: new Date(),
              endDate: new Date()
            }
          },
          deliverySlot: {
            key: "deliverySlot",
            value: []
          }
        },
        SEARCH_TYPES: [
          {
            name: "Route Id",
            value: "c.routeId"
          }
        ],
        sortModel: {
          field: "deliveryDate",
          direction: "asc"
        }
      },
      FILTER_CHIPS: [
        {
          code: 'UNPLANNED',
          value: 'Unplanned'
        },
        {
          code: 'PLANNED',
          value: 'Planned'
        },
        {
          code: 'PUBLISHED',
          value: 'Published'
        },
        {
          code: 'PREPARING',
          value: 'Preparing'
        },
        {
          code: 'PREPARED',
          value: 'Prepared'
        },
        {
          code: 'OUT_FOR_DELIVERY',
          value: 'Out For Delivery'
        },
        {
          code: 'COMPLETED',
          value: 'Completed'
        },
        {
          code: "ALL",
          value: "All"
        }
      ],
      SUB_ROUTE_TAG:{
        color: "TROPICAL_BLUE",
        hintText: ""
      },
      STATUSES: {
        "DRAFTED": {
          color: "TROPICAL_BLUE",
          hintText: ""
        },
        "CREATED": {
          color: "CATSKILL_WHITE",
          hintText: ""
        },
        "ASSIGNED": {
          color: "BLUE_HAZE",
          hintText: ""
        },
        "PREPARING": {
          color: "BARLEY_WHITE",
          hintText: ""
        },
        "OUT_FOR_DELIVERY": {
          color: "DOUBLE_PEARL_LUSTA",
          hintText: ""
        },
        "COMPLETED": {
          color: "JAGGED_ICE",
          hintText: ""
        },
        "PUBLISHED": {
          color: "TARA",
          hintText: ""
        },
        "PREPARED": {
          color: "JAGGED_ICE_PREPARED",
          hintText: ""
        },
        "CANCELLED": {
          color: "ALTO",
          hintText: ""
        }
      },
      DEFAULT_FILTER_CHIP: 'PLANNED',
      PUBLISHED_FILTER_CHIP:'PUBLISHED',
      UNEDITABLE_CHIP: 'COMPLETED',
      DELETE_STATUS:['PLANNED','PUBLISHED','PREPARING','PREPARED'],
      EDIT_STATUS_ROUTE:['PLANNED','PUBLISHED','PREPARING','PREPARED', 'OUT_FOR_DELIVERY'],
      PUBLISH_ROUTES_SUCCESS_MSG: 'have been published successfully.',
      OUT_FOR_DELIVERY:'OUT_FOR_DELIVERY',
      UPDATE_ROUTE_SUCCESS_MSG: 'has been updated.',
      DELETE_ROUTE_SUCCESS_MSG: 'has been deleted!',
      PUBLISH_ROUTES_ERROR_MSG: 'could not be published',
      BULK_DELETE_ERROR_MSG:'Route(s) could not be deleted',
      TAB_VALUES: {
        CONSIGNMENTS: 'consignments',
        ROUTES: 'routes',
        ALLROUTES: 'All Routes'
      },
      DISPLAY_ROUTE_STATUS_FILTER_OBJ: {
        key: "displayRouteStatus",
        value: ['PLANNED'],
        fieldName: 'c.displayRouteStatus',
        ignoreCount:true
      },
      DISPLAY_PUBLISHED_FILTER_OBJ: {
        key: "displayRouteStatus",
        value: ['PUBLISHED'],
        fieldName: 'c.displayRouteStatus',
        ignoreCount:true
      },
    },
    CREATE_ROUTE_DATA: {
      CREATE_ROUTE_SUCCESS_MSG: 'has been created successfully',
      DRAFTED_CONSIGNMENT_TOOLTIP_MSG: 'This consignment is being used in another route',
      CAPACITY_BREACH_MSGS: {
        CONSIGNMENTS: "You are exceeding capacity for the consignments",
        CRATES: "You are exceeding capacity for the crates",
        TRANSIT_TIME: "You are exceeding capacity for the transit time",
        CONSIGNMENTS_AND_CRATES: "You are exceeding capacity for the consignments and crates",
        CONSIGNMENTS_AND_TRANSIT_TIME: "You are exceeding capacity for the consignments and transit time",
        CRATES_AND_TRANSIT_TIME: "You are exceeding capacity for the crates and transit time",
        ALL_VALUES: "You are exceeding capacity for the consignments, crates and transit time",
      },
      DRIVER_SERVICE_TIME: 600,
      TAB_VALUES: {
        UNPLANNED: 'unplanned',
        PLANNED: 'planned'
      }
    },
    // EXCEL_FILE_NAME: "consignments.xlsx",
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
        CONCURRENT_CONSIGNMENT: "CWO15"
      }
    },
    ALL_FILTER_CHIP: "ALL",
    HUB_FOR_ROUTES: 'hubForRoutes',
    CHANGE_HUB: 'Change Hub',
    ROUTE_DATA_PERSIST :'RoutePayload',
    DRIVER_LOCATION_REFRESH_INTERVAL: 300000,
    REDUCE_DRIVER_LOCATION_COUNT_DOWN_INTERVAL: 1000,
  },
}

export default PlanningConstants;

