import { TableCell, TableCellProps, Tooltip, Grid, Typography, IconButton } from '@material-ui/core';
import React, { useCallback } from 'react';
import { ColDef, RowData } from '.';
import { useStyles } from './DataTableCellElement.styles';
import verifiedIcon from './../../../assets/icons/verified-icon.svg';
import unverifiedIcon from './../../../assets/icons/unverified-icon.svg';
import pendingIcon from './../../../assets/icons/pending-icon.svg';
import { ADDRESS_STATUS } from './../../../constants/CommonConstants';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
export interface DataTableCellElementProps extends TableCellProps {
  column: ColDef;
  value: any;
  rowHeight: number;
  rowData: RowData;
  cellClickCallback?: (column: ColDef, value: any, rowData: RowData) => void;
  setOpen?:(param:boolean)=>void;
  open?: boolean
  subElement?: boolean
}

const DataTableCellElement = (props: DataTableCellElementProps) => {
  const classes = useStyles();
  const {
    column,
    value,
    rowHeight,
    rowData,
    cellClickCallback,
    setOpen,
    open,
    subElement,
    ...rest
  } = props;
  const handleCellClick = useCallback(
    (e:any) => {
      if (!window.getSelection()?.toString()) {
        cellClickCallback && cellClickCallback(column, value, rowData);
      }
    },
    [cellClickCallback, column, value, rowData],
  );

  const handleIconClick = useCallback(
    (e:any) => {
      setOpen && setOpen(!open)
      e.stopPropagation();
    },
    [open],
  );

  const addressStatus = (status: any) => {
    if (status === ADDRESS_STATUS.VERIFIED) {
      return <img src={verifiedIcon} alt="verified-icon" />
    } else {
      if (status === ADDRESS_STATUS.UNVERIFIED) {
        return <img src={unverifiedIcon} alt="unverified-icon" />
      } else {
        return <img src={pendingIcon} alt="pending-icon" />
      }
    }
  }

  const renderTooltip = (column:any) => {
    return (
      <Grid container className={classes.tooltipTitleSection}>
        {column?.field === "deliveryArea" &&
          <Grid container>
            <Grid item xs={10}>
              <Typography className={classes.tooltipHeading}>
                Customer Address
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {addressStatus(rowData.addressStatus)}
            </Grid>
          </Grid>
        }
        <Grid item className={classes.tooltipData}>
          {rowData[column?.tooltipDataKey]}
        </Grid>
      </Grid>
    );
  }

  return (
    !column.hide
      ? column && column?.tooltip  ?
        <Tooltip arrow classes={{
          popper: classes.popperStyle,
          arrow: classes.arrowStyle,
          tooltip: classes.tooltipStyle
        }} title={renderTooltip(column)}>
          <TableCell
            {...rest}
            align={column.type === 'number' ? 'right' : 'left'}
            style={{ height: rowHeight, minHeight: rowHeight, maxHeight: rowHeight, width: column.width, minWidth: column.width, maxWidth: column.width, cursor: "pointer" }}
            className={classes.root}
            onClick={handleCellClick}
          >
            {
              column.renderCell
                ?
                <column.renderCell field={column.field} value={value} rowData={rowData} />
                : value
            }
          </TableCell>
        </Tooltip >
        : <TableCell
          {...rest}
          align={column.type === 'number' ? 'right' : 'left'}
          style={{ height: rowHeight, minHeight: rowHeight, maxHeight: rowHeight, width: column.width, minWidth: column.width, maxWidth: column.width }}
          className={classes.root}
          onClick={(e)=>handleCellClick(e)}
        >
          {subElement &&  column.renderSubElementCell ? <column.renderSubElementCell field={column.field} value={value} rowData={rowData} /> :
            column.renderCell
              ?
              <column.renderCell field={column.field} value={value} rowData={rowData} />
              : value
          }
          {column.showArrowIcon && rowData?.subRoutes &&  <IconButton
              aria-label="expand row"
              size="small"
              className={classes.customIcon}
              onClick={handleIconClick}
            >
           {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>}
        </TableCell>
      : <></>
  )
}

export default DataTableCellElement;