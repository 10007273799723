import moment from "moment";
import AppConstants from "../../constants";
import { getUniqueValues, localTimeString, calculateTimeBetweenSlots, fixDecimals } from "../../utils/helpers.utils";

export interface DriversDataTransform {
    pageNumber: number;
    totalElements: number;
    totalPages: number;
    elements: Driver[];
}

export interface Driver {
    driverId: string;
    vendor: string,
    driverName: string,
    phone: string;
    status: string;
    hubCodes: any;
    hubNames: any;
    vehicleType: string;
    assignmentMode: string;
}

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
    let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
    const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
        currentValue = [];
    }
    if (selectNoneIdx > -1) {
        currentValue = [];
    }
    return currentValue;
}

export const driversDataTransform = (response: DriversDataTransform): any[] => {
    const transformedArray: any[] = [];
    response.elements.forEach((driver: Driver) => {
        const {
            driverId,
            vendor,
            driverName,
            phone,
            status,
            hubCodes,
            hubNames,
            vehicleType,
            assignmentMode
        } = driver;
        transformedArray.push({
            driverId: driverId,
            hubCode: hubCodes,
            hubNames: hubNames,
            vendor: vendor,
            driverName: driverName,
            phone: phone,
            status: status,
            vehicle: vehicleType,
            modes: assignmentMode
        });
    });
    return [...transformedArray];
};

export const createChipsCountFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, countryCode?: any) => {
    payload.attributes = [];
    if (countryCode) {
        payload.countryISOCode = countryCode
    }
    if (payload.hubCode.value.length > 0) {
        let hubValue = payload.hubCode.value.map((item: any) => item.value);
        let hubNameValue = [];
        if (payload.hubName && payload.hubName.value.length) {
            hubNameValue = payload.hubName.value.map((item: any) => item.value);
        }
        hubValue = hubValue.concat(hubNameValue);
        const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
        const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
        if (selectAllIdx > -1) {
            hubValue = [];
        }
        if (selectNoneIdx > -1) {
            hubValue = [];
        }
        if (hubValue.length) {
            payload.attributes.push({
                key: payload.hubCode.key,
                value: getUniqueValues(hubValue)
            })
        }
        delete payload.hubCode;
    } else {
        delete payload.hubCode;
    }
    if (payload.hubName.value.length > 0) {
        let attrValue = [];
        if (payload.hubName.value) {
            attrValue = getFilterAttributeValue(payload.hubName.value);
            payload.attributes.push({
                key: payload.hubName.key,
                value: attrValue
            })
        }
    } else {
        delete payload.hubName;
    }
    if (payload.status.value.length > 0) {
        let attrValue = [];
        if (payload.status.value) {
            attrValue = getFilterAttributeValue(payload.status.value);
            payload.attributes.push({
                key: payload.status.key,
                value: attrValue
            })
        }
    } else {
        delete payload.status;
    }
    if (payload.modes.value.length > 0) {
        let attrValue = [];
        if (payload.modes.value) {
            attrValue = getFilterAttributeValue(payload.modes.value);
            payload.attributes.push({
                key: "assignmentMode",
                value: attrValue
            })
        }
    } else {
        delete payload.modes;
    }
    if (payload.vendor.value.length > 0) {
        let attrValue = [];
        if (payload.vendor.value) {
            attrValue = getFilterAttributeValue(payload.vendor.value);
            payload.attributes.push({
                key: payload.vendor.key,
                value: attrValue
            })
        }
    } else {
        delete payload.vendor;
    }

    delete payload.status;
    delete payload.modes;
    delete payload.hubCode;
    delete payload.hubName;
    delete payload.vehicle;
    delete payload.reset;
    delete payload.vendor;
    return payload;
};

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, countryCode?: any, selectedChip?: any) => {
    payload.attributes = [];
    if (countryCode) {
        payload.countryISOCode = countryCode
    }
    if (selectedChip === "ALL") {

        if (payload.status.value.length > 0) {
            let attrValue = [];
            if (payload.status.value) {
                attrValue = getFilterAttributeValue(payload.status.value);
                payload.attributes.push({
                    key: payload.status.key,
                    value: attrValue
                })
            }
        } else {
            let obj = {
                key: "status",
                value: []
            }
            payload.attributes.push(obj);
            delete payload.status;
        }
    } else {
        let obj = {
            key: "status",
            value: [selectedChip]
        }
        payload.attributes.push(obj);
    }
    if (payload.hubCode.value.length > 0) {
        let hubValue = payload.hubCode.value.map((item: any) => item.value);
        let hubNameValue = [];
        if (payload.hubName && payload.hubName.value.length) {
            hubNameValue = payload.hubName.value.map((item: any) => item.value);
        }
        hubValue = hubValue.concat(hubNameValue);
        const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
        const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
        if (selectAllIdx > -1) {
            hubValue = [];
        }
        if (selectNoneIdx > -1) {
            hubValue = [];
        }
        if (hubValue.length) {
            payload.attributes.push({
                key: payload.hubCode.key,
                value: getUniqueValues(hubValue)
            })
        }
        delete payload.hubCode;
    } else {
        delete payload.hubCode;
    }
    if (payload.hubName.value.length > 0) {
        let attrValue = [];
        if (payload.hubName.value) {
            attrValue = getFilterAttributeValue(payload.hubName.value);
            payload.attributes.push({
                key: payload.hubName.key,
                value: attrValue
            })
        }
    } else {
        delete payload.hubName;
    }
    if (payload.vendor.value.length > 0) {
        let attrValue = [];
        if (payload.vendor.value) {
            attrValue = getFilterAttributeValue(payload.vendor.value);
            payload.attributes.push({
                key: payload.vendor.key,
                value: attrValue
            })
        }
    } else {
        delete payload.vendor;
    }
    if (payload.modes.value.length > 0) {
        let attrValue = [];
        if (payload.modes.value) {
            attrValue = getFilterAttributeValue(payload.modes.value);
            payload.attributes.push({
                key: "assignmentMode",
                value: attrValue
            })
        }
    } else {
        delete payload.modes;
    }
    if (payload.vehicle.value.length > 0) {
        let attrValue = [];
        if (payload.vehicle.value) {
            attrValue = getFilterAttributeValue(payload.vehicle.value);
            payload.attributes.push({
                key: "vehicleType",
                value: attrValue
            })
        }
    } else {
        delete payload.vehicle;
    }
    if (isLoadMore && data) {
        payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
    }
    delete payload.status;
    delete payload.hubCode;
    delete payload.hubName;
    delete payload.vehicle;
    delete payload.reset;
    delete payload.vendor;
    delete payload.modes;
    return payload;
};

export const getDriverStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.DRIVER_STATUSES;
    let newStatus = statusCode && statusCode.toUpperCase();
    const driverStatuses: any = AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    let convertedStatus = newStatus && driverStatuses[newStatus] ? driverStatuses[newStatus] : "";
    return statusCode && statuses[convertedStatus.value] ? statuses[convertedStatus.value][fieldName] : "";
}

export const getDriverStatusFieldAfterMarkingAway = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.DRIVER_STATUSES;
    let newStatus = statusCode && statusCode.toUpperCase();
    return statusCode && statuses[newStatus] ? statuses[newStatus][fieldName] : "";
}

export const getFieldName = (fieldName: string) => {
    const statuses: any = AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.DRIVER_STATUSES;
    return fieldName && statuses[fieldName] ? statuses[fieldName].value : "";
}

export const createPayloadForDriverActivity = (user: any, time: any, country: any) => {
    const startDate = moment(time.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_API);
    const endDate = moment(time.endDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_API);
    const payload = {
        driverId: user.driverId,
        countryCode: country,
        startTime: startDate,
        endTime: endDate
    }
    return payload;
};


export const createPayloadToUpdateDriverAway = (user: any, status: any, country: any) => {
    const payload = {
        driverId: user.driverId,
        countryCode: country,
        driverAway: status
    }
    return payload;
};

export const createPayloadToUpdateAssignmentMode = (user: any, status: any, country: any) => {
    const payload = {
        driverId: user.driverId,
        countryCode: country,
        assignmentMode: status
    }
    return payload;
};

export const createPayloadToReleaseDriver = (user: Driver) => {
    const payload = {
        driverId: user.driverId,
    }
    return payload;
};