import { Card, Grid, Typography } from "@material-ui/core";
import React from "react";

import BarCharts from "../../../common/BarCharts";

import { COLORS } from "../../../config/theme/baseTheme";
import { ShowMessageInsideCard } from "../Utils/DelveDashboardViewUtils";
import { isInputAnArray, isUndefined, isNonEmptyArray } from "../../../utils/helpers.utils";

interface DelveDashboardIssueMappingDetailsInterface {
  classes: any;
  recordObj: any;
}

const BARCOLORS_VOLUME = [COLORS.ALICE_BLUE, COLORS.INFO_BLUE, COLORS.VIBRANT_YELLOW, COLORS.VENETIAN_RED, COLORS.FOUNTAIN_BLUE, COLORS.BRICK_RED, COLORS.VERY_LIGHT_GREY];

const DelveDashboardIssueMappingDetails = (props: DelveDashboardIssueMappingDetailsInterface) => {
  const { classes, recordObj } = props;

  const isDashboardRecordContainsHubSeriesObj = () => {
    return !isUndefined(recordObj) && !isUndefined(recordObj.hubSeriesObj) && isInputAnArray(recordObj.hubSeriesObj) && isNonEmptyArray(recordObj.hubSeriesObj);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.issueGridItemStyle}>
        <Typography className={classes.issueGridItemSpanStyle}>{`Issue Mapping (${recordObj?.totalIssueSummaryCount || 0} Total issues)`}</Typography>
      </Grid>
      <Card className={classes.issueSummaryGridCard}>
        {isDashboardRecordContainsHubSeriesObj() ? (
          <BarCharts days={recordObj?.hubFooterData || []} color={BARCOLORS_VOLUME} seriesObj={recordObj.hubSeriesObj || []} showLegend={true} />
        ) : (
          <ShowMessageInsideCard classes={classes} message={`No Data Available`} />
        )}
      </Card>
    </Grid>
  );
};

export default React.memo(DelveDashboardIssueMappingDetails);
