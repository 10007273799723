import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchOrdersFilterChipsDisplayCount, fetchRoutesFilterChipsDisplayCount, fetchPlanningOrderListByFilter, fetchPlanningRouteListByFilter, publishRoutes, deleteRoute, clearPublishedRoutesErrors, clearUpdatedRoute, resetPlanningError, resetConsignmentReplanningSuccess, fetchDeliveryReasons, fetchDynamicFiltersOrdersDropdownValues, fetchDynamicFiltersRoutesDropdownValues, clearCurrentSlot, exportToExcel, exportRoutesToExcel } from "./redux/valetTrolleySlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  createDeleteRoutePayload,
  createFilterPayload,
  createPublishRoutesPayload,
  getConsignmentStatusField,
  getRouteStatusField,
  lastMileVTOrdersDataTransform,
  lastMileVTRoutesDataTransform,
  createPayloadForExcelExport,
  createRoutesPayloadForExcelExport
} from "../mocks/valetTrolley/response.transforms";
import { userLogout } from "../Login/redux/loginSlice";
import { useHistory } from "react-router";

//Assets
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import { ReactComponent as deleteIcon } from './../assets/icons/delete-icon.svg';
import { ReactComponent as checkIcon } from './../assets/icons/circle-checked-icon.svg';

//Common Components
import { AppState } from "../config/redux/reducers";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import CustomSnackbar from "../common/CustomSnackbar";
import { CellParams, RowData, RowParams, SelectionChangeParams, SortModel } from "../common/GridWrapper/DataTable";
import CustomDialog from "../common/Dialog";
import CustomTooltip from "common/Tooltip";
import SelectBox from "common/SelectBox";

//Styles
import { useStyles } from "./valetTrolleyStyles";

//Constants, Utils
import AppConstants from "../constants";
import { getSlotUIValue, getValueFromArray } from "utils/helpers.utils";
import _ from "lodash";

//Material UI Components
import { Button, Grid, SvgIcon, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import { TabContext, TabPanel } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

//Components
import ValetTrolleyOrderDetails from "./ValetTrolleyOrderDetails";

interface ValetTrolleyProps {
  match?: any;
  location?: any;
}

const ValetTrolley = (props: ValetTrolleyProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { location } = props;
  const componentClasses = useStyles();
  const { ordersData, routesData, tableLoading, ordersFiltersDropdowns, routesFiltersDropdowns, vtOrdersFilterChipsDisplayCount, routesFilterChipsDisplayCount, publishedRoutes, deletedRoute, publishedRoutesErrors, updatedRoute, routeDiscardSuccess, vtReplanningConsignmentSuccess, isUpdateAddress, loading, error, errorCode, exportError, excelLoading } = useSelector(
    (state: AppState) => state.valetTrolley
  );
  const { countryCode, hubCode, userDetailsDropdowns } = useSelector(
    (state: AppState) => state.common
  );
  const { isLastMileViewer, isOnlyStdLastMileViewer } = useSelector(
    (state: AppState) => state.userLogin
  );

  const onLoadRef = useRef(true);
  const [ordersListToShow, setOrdersListToShow] = useState([]);
  const [routesListToShow, setRoutesListToShow] = useState([]);
  const [ordersSearchValue, setOrdersSearchValue] = useState("");
  const [routesSearchValue, setRoutesSearchValue] = useState("");
  const [isOrderSearched, setIsOrderSearched] = useState(false);
  const [isRouteSearched, setIsRouteSearched] = useState(false);
  const [consignmentData, setConsignmentData] = useState({});
  const [isDisplayCount, setIsDisplayCount] = useState(false);
  const [openConsignmentDetailsPopup, setOpenConsignmentDetailsPopup] = useState(false);
  const [tabValue, setTabValue] = useState(location.state && location.state.tabValue ? location.state.tabValue : AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.VALET_TROLLEY);
  const [activeOrdersFilterChip, setActiveOrdersFilterChip] = useState(AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);
  const [activeRoutesFilterChip, setActiveRoutesFilterChip] = useState(AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.DEFAULT_FILTER_CHIP);

  const [selectedRoutes, setSelectedRoutes] = useState([] as RowData[]);
  const [isTabChange, setIsTabChange] = useState(false);

  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [editRoute, setEditRoute] = useState<any>(null);
  const [viewRoute, setViewRoute] = useState<any>(null);
  const [createRouteHub, setCreateRouteHub] = useState(sessionStorage.getItem(AppConstants.HUB_CODE) || AppConstants.SELECT_NONE.value);
  const [openCreateRouteHubSelectionPopup, setOpenCreateRouteHubSelectionPopup] = useState(false);
  const [createRoute, setCreateRoute] = useState(false);
  const [routeToBeDeleted, setRouteToBeDeleted] = useState<any>({});
  const [openPublishErrorMsgPopup, setOpenPublishErrorMsgPopup] = useState(false);
  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA, HeaderData: AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'consignmentId') {
        newHeader.renderCell = (params: CellParams) => (
          <Typography className="idLink">{params.value}</Typography>
        )
      }
      if (newHeader.field === 'deliveryStatus') {
        newHeader.renderCell = (params: any) => (
          params.value ?
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={['tag', getConsignmentStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
            : <></>
        );
      }
      if (newHeader.field === 'routeStatus') {
        newHeader.renderCell = (params: any) => (
          params.value ?
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={['tag', getRouteStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
            : <></>
        );
      }
      return newHeader;
    }),
    defaultFiltersObj: {
      ...AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj, dateRange: {
        ...AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange, value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT)
        }
      }
    }
  });
  const [routesTableWrapperData, setRoutesTableWrapperData] = useState({
    ...AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA, HeaderData: AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'routeId') {
        newHeader.renderCell = (params: CellParams) => (
          <Typography className="idLink">{params.value}</Typography>
        )
      }
      if (newHeader.field === 'actions') {
        newHeader.renderCell = (params: CellParams) => (
          <Grid container className={componentClasses.actionsHeader}>
            <Grid className="actionItem" item>
              <SvgIcon className="icon editIcon" onClick={handleEditRouteIconClick(params.value)} component={editIcon} />
            </Grid>
            <Grid item className="actionItem">
              <SvgIcon className="icon deleteIcon" onClick={handleDeleteRouteIconClick(params.value)} component={deleteIcon} />
            </Grid>
          </Grid>
        );
      }
      if (newHeader.field === 'deliveryArea') {
        newHeader.renderCell = (params: CellParams) => {
          return (
            <Grid container className={componentClasses.deliveryAreaHeader}>
              <CustomTooltip title={getDeliveryAreas(params.value)}>
                <Typography className="columnValue">{params.value.length && params.value.length > 1 ? `Multiple(${params.value.length})` : params.value[0]}</Typography>
              </CustomTooltip>
            </Grid>
          );
        }
      }
      if (newHeader.field === 'deliverySlot') {
        newHeader.renderCell = (params: CellParams) => {
          const firstDelSlot = params.value.length && params.value[0].split('-');
          const slotValue = getSlotUIValue(firstDelSlot);
          return (
            <Grid container className={componentClasses.deliveryAreaHeader}>
              <CustomTooltip title={getDeliverySlots(params.value)}>
                <Typography className="columnValue">{params.value.length && params.value.length > 1 ? `Multiple(${params.value.length})` : `${slotValue.startTime} - ${slotValue.endTime}`}</Typography>
              </CustomTooltip>
            </Grid>
          );
        }
      }
      if (newHeader.field === 'routeStatus') {
        newHeader.renderCell = (params: any) => (
          <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
            <Typography className={['tag', getRouteStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
          </Grid>
        );
      }
      return newHeader;
    }),
    defaultFiltersObj: {
      ...AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj, dateRange: {
        ...AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange, value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT)
        }
      }
    }
  });
  const [ordersActiveFilters, setOrdersActiveFilters] = useState({ ...ordersTableWrapperData.defaultFiltersObj });
  const [routesActiveFilters, setRoutesActiveFilters] = useState({ ...routesTableWrapperData.defaultFiltersObj });

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const [renderExcelProgress, setRenderExcelProgress] = useState(false);

  const publishDialogPaperProps = {
    classes: {
      root: componentClasses.publishDialogPaperPropsRoot
    }
  }

  const deleteDialogPaperProps = {
    classes: {
      root: componentClasses.deleteDialogPaperPropsRoot
    }
  }

  const createRouteHubSelectionDialogPaperProps = {
    classes: {
      root: componentClasses.hubSelectionDialogPaperPropsRoot
    }
  }

  const fetchDynamicFilters = useCallback(
    (filters: any, isRoute?: boolean) => {
      if (isRoute) {
        dispatch(
          fetchDynamicFiltersRoutesDropdownValues({
            filters: filters
          })
        )
      } else {
        dispatch(
          fetchDynamicFiltersOrdersDropdownValues({
            filters: filters
          })
        )
      }
    },
    [dispatch]
  );

  const handleHeaderButtonsDisableState = useCallback(
    (isRoute?: boolean) => {
      if (isRoute) {
        let headerButtons = routesTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case "publish":
              btn.disabled = !selectedRoutes.length;
              break;
            default:
              break;
          }
          return btn;
        });
        setRoutesTableWrapperData({ ...routesTableWrapperData, headerButtons: headerButtons });
      } else {
        let headerButtons = ordersTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case "createRoute":
              if (isLastMileViewer || isOnlyStdLastMileViewer) {
                btn.hide = true;
              };
              break;
            default:
              break;
          }
          return btn;
        });
        setOrdersTableWrapperData({ ...ordersTableWrapperData, headerButtons: headerButtons });
      }
    },
    [ordersTableWrapperData, routesTableWrapperData, selectedRoutes, isLastMileViewer, isOnlyStdLastMileViewer]
  );

  const handleHeaderButtonsHideState = useCallback(
    (isRoute?: boolean) => {
      if (isRoute) {
        let headerButtons = routesTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case 'publish':
              btn.hide = activeRoutesFilterChip !== AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.DEFAULT_FILTER_CHIP;
              break;
            default:
              break;
          }
          return btn;
        });
        setRoutesTableWrapperData({ ...routesTableWrapperData, headerButtons: headerButtons });
      } else {
        let headerButtons = ordersTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case 'createRoute':
              btn.hide = activeOrdersFilterChip !== AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP;
              break;
            default:
              break;
          }
          return btn;
        });
        setOrdersTableWrapperData({ ...ordersTableWrapperData, headerButtons: headerButtons });
      }
    },
    [ordersTableWrapperData, routesTableWrapperData, activeOrdersFilterChip, activeRoutesFilterChip]
  );

  const handleLoadMoreDisableState = useCallback(
    (isRoute?: boolean) => {
      if (isRoute) {
        let loadMoreState = routesTableWrapperData.loadMore;
        loadMoreState.disabled = routesData.totalPages ? routesData.pageNumber + 1 >= routesData.totalPages : true;
        setRoutesTableWrapperData({ ...routesTableWrapperData, loadMore: loadMoreState });
      } else {
        let loadMoreState = ordersTableWrapperData.loadMore;
        loadMoreState.disabled = ordersData.totalPages ? ordersData.pageNumber + 1 >= ordersData.totalPages : true;
        setOrdersTableWrapperData({ ...ordersTableWrapperData, loadMore: loadMoreState });
      }
    },
    [routesTableWrapperData, ordersTableWrapperData, ordersData, routesData]
  );

  const getFilterChipsCount = useCallback(
    (payload: any, isRoute?: boolean) => {
      setIsDisplayCount(false);
      let updatedPayload = _.cloneDeep(payload);
      if (isRoute) {
        updatedPayload.attributes = updatedPayload.attributes.filter((attr: any) => attr.key !== 'c.displayRouteStatus');
        dispatch(
          fetchRoutesFilterChipsDisplayCount({
            filters: updatedPayload
          })
        );
        setIsDisplayCount(true);
      } else {
        updatedPayload.attributes = updatedPayload.attributes.filter((attr: any) => attr.key !== 'c.metadata.displayRouteStatus');
        dispatch(
          fetchOrdersFilterChipsDisplayCount({
            filters: updatedPayload
          })
        );
        setIsDisplayCount(true);
      }

    },
    [dispatch],
  )

  const ordersSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    const activeFilters = _.cloneDeep(filters);
    onLoadRef.current = false;
    setOrdersSearchValue(updatedSearchValue);
    setIsOrderSearched(false);
    if (updatedSearchValue) {
      setIsOrderSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey]
          }
          ordersFilterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const routesSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    const activeFilters = _.cloneDeep(filters);
    onLoadRef.current = false;
    setRoutesSearchValue(updatedSearchValue);
    setIsRouteSearched(false);
    if (updatedSearchValue) {
      setIsRouteSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey]
          }
          routesFilterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const ordersFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
        if (!isChipFilterClicked) {
          if (activeOrdersFilterChip === AppConstants.VALET_TROLLEY_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeOrdersFilterChip];
          }
        }
        const payload = createFilterPayload(payloadFilters, ordersData, isLoadMore);
        if (!isLoadMore) {
          fetchDynamicFilters(payload);
        }
        if(isLoadMore){
          setIsTabChange(false);
        }
        getFilterChipsCount(payload);
        dispatch(
          fetchPlanningOrderListByFilter({
            filters: payload
          })
        );
        setOrdersActiveFilters(filters);
      }
      setRenderExcelProgress(false);
    },
    [countryCode, ordersData, ordersTableWrapperData, activeOrdersFilterChip, fetchDynamicFilters, dispatch, getFilterChipsCount],
  );

  const handleExcelExport = useCallback(
    (filters: any, isRoute: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        if (isRoute) {
          if (activeRoutesFilterChip === AppConstants.VALET_TROLLEY_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeRoutesFilterChip];
          }
          const payload = createRoutesPayloadForExcelExport(payloadFilters, routesData, false, true);
          dispatch(
            exportRoutesToExcel({
              payload: payload
            })
          );
        } else {
          if (activeOrdersFilterChip === AppConstants.VALET_TROLLEY_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeOrdersFilterChip];
          }
          const payload = createPayloadForExcelExport(payloadFilters, ordersData, false, false);
          dispatch(
            exportToExcel({
              payload: payload
            })
          );
        }
      }
      setRenderExcelProgress(true);
    },
    [dispatch, ordersData, routesData, countryCode, activeOrdersFilterChip, activeRoutesFilterChip]
  )

  const routesFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        payloadFilters.pageSize = routesTableWrapperData.loadMore.pageSize;
        if (!isChipFilterClicked) {
          if (activeRoutesFilterChip === AppConstants.VALET_TROLLEY_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeRoutesFilterChip];
          }
        }
        const payload = createFilterPayload(payloadFilters, routesData, isLoadMore, true);
        if (!isLoadMore) {
          fetchDynamicFilters(payload, true);
        }
        dispatch(
          fetchPlanningRouteListByFilter({
            filters: payload
          })
        );
        getFilterChipsCount(payload, true);
        setRoutesActiveFilters(filters);
      }
      setRenderExcelProgress(false);
    },
    [countryCode, routesData, routesTableWrapperData, activeRoutesFilterChip, fetchDynamicFilters, dispatch, getFilterChipsCount],
  );


  const fetchAllDeliveryReasons = useCallback(
    () => {
      dispatch(
        fetchDeliveryReasons()
      )
    },
    [dispatch],
  )

  const handleConsignmentReplanningReset = useCallback(
    () => {
      dispatch(
        resetConsignmentReplanningSuccess()
      )
    },
    [dispatch],
  );

  const handleHubSelectionDialogClose = useCallback(
    () => {
      setOpenCreateRouteHubSelectionPopup(false);
    },
    [],
  );

  const handleClearCurrentSlot = useCallback(
    () => {
      dispatch(
        clearCurrentSlot()
      );
    },
    [dispatch],
  );

  const hubSelectionDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'continue') {
        handleClearCurrentSlot();
        setCreateRoute(true);
        setEditRoute(null);
      }
      handleHubSelectionDialogClose();
    },
    [handleHubSelectionDialogClose, handleClearCurrentSlot],
  )

  const handleHubChangePopup = useCallback(
    () => {
      const hubs = userDetailsDropdowns.hubList ? userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] : [];
      if (hubs.length > 1) {
        setOpenCreateRouteHubSelectionPopup(true);
      } else {
        setCreateRouteHub(hubs.length ? hubs[0].value : AppConstants.SELECT_NONE.value);
        hubSelectionDialogActionHandler('continue');
      }
    },
    [userDetailsDropdowns, countryCode, hubSelectionDialogActionHandler],
  )

  const handleOrdersHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case 'createRoute':
            handleHubChangePopup();
            break;
          case 'excel':
            handleExcelExport(ordersActiveFilters, false);
            break;
          default:
            break;
        }
      }
    },
    [handleExcelExport, handleHubChangePopup, ordersActiveFilters]
  );

  const handleConsignmentCellClick = useCallback(
    (params: CellParams) => {
      if (params.field === 'consignmentId') {
        const selectedRow: any = params.rowData;
        const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
        setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
        setOpenConsignmentDetailsPopup(true);
        handleConsignmentReplanningReset();
      }
    },
    [ordersData, handleConsignmentReplanningReset],
  );

  const handleRowClick = useCallback(
    (params: RowParams) => {
      const selectedRow: any = params.data;
      const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
      setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
      setOpenConsignmentDetailsPopup(true);
      handleConsignmentReplanningReset();
    },
    [ordersData, handleConsignmentReplanningReset],
  );

  const handleRouteCellClick = useCallback(
    (params: CellParams) => {
      if (params.field !== 'actions') {
        const selectedRow: any = params.rowData;
        const selectedRoute = routesData.elements.filter((item: any) => item.id === selectedRow.id);
        handleClearCurrentSlot();
        setViewRoute(selectedRoute.length ? selectedRoute[0] : {});
        setCreateRoute(false);
      }
    },
    [routesData, handleClearCurrentSlot],
  )

  const closeConsignmentDetailsPopup = useCallback(
    () => {
      setOpenConsignmentDetailsPopup(false);
    },
    [],
  );



  const handleRoutesHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case 'publish':
            setOpenPublishDialog(true);
            break;
          case 'excel':
            handleExcelExport(routesActiveFilters, true);
            break;
          default:
            break;
        }
      }
    },
    [handleExcelExport, routesActiveFilters]
  );

  const getPublishDialogTitleContent = () => {
    return (
      <></>
    )
  };

  const getPublishDialogDetailsContent = () => {
    return (
      <Grid className={componentClasses.publishDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>Are you sure you want to publish these routes?</Typography>
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography><span className="count">{selectedRoutes.length > 1 ? `${selectedRoutes.length} Routes` : `${selectedRoutes.length} Route`}</span>{selectedRoutes.length > 1 ? ' are ' : ' is '}being published. Once published, the drivers will be notified of the assigned routes.</Typography>
        </Grid>
      </Grid>
    )
  };

  const handlePublishDialogClose = useCallback(
    () => {
      setOpenPublishDialog(false);
    },
    [],
  )

  const getDeleteDialogTitleContent = () => {
    return (
      <></>
    )
  };

  const getDeleteDialogDetailsContent = () => {
    return (
      <Grid className={componentClasses.deleteDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>{`Are you sure you want to delete Route ${routeToBeDeleted.routeId}`}</Typography>
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography>Once deleted, the consignments in this route will be moved back to the unplanned tab.</Typography>
        </Grid>
      </Grid>
    )
  };

  const handleHubChange = useCallback(
    (value: any) => {
      setCreateRouteHub(value);
    },
    [],
  )

  const getHubSelectionDialogTitleContent = () => {
    return (
      <Typography className={componentClasses.hubSelectionDialogTitle}>Select Hub</Typography>
    )
  }

  const getHubSelectionDialogDetailsContent = () => {
    return (
      <Grid className={componentClasses.hubSelectionDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>Please select the Hub to create routes</Typography>
        </Grid>
        <Grid className="contentItem hub" item>
          <SelectBox
            fullWidth
            className="hubSelectBox"
            handleChange={handleHubChange}
            value={createRouteHub}
            id="createRoute-hub-select-box"
            label="Hub"
            items={userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || []}
          ></SelectBox>
        </Grid>
      </Grid>
    )
  };

  const getDeliveryAreas = (areas: any[]) => {
    return areas.length && areas.map((area: string, index: number) => (
      <Typography key={`tooltipArea-${area}-${index}`} className="tooltipItem">{area}</Typography>
    ));
  }

  const getDeliverySlots = (slots: any[]) => {
    return slots.length && slots.map((slot: string, index: number) => {
      const delSlot = slot.split('-');
      const slotValue = getSlotUIValue(delSlot);
      return (
        <Typography key={`tooltipArea-${slot}-${index}`} className="tooltipItem">{`${slotValue.startTime} - ${slotValue.endTime}`}</Typography>
      )
    });
  }

  const handleDeleteRouteIconClick = (value: any) => () => {
    setRouteToBeDeleted(value);
    setOpenDeleteDialog(true);
  }

  const handleEditRouteIconClick = (value: any) => () => {
    handleClearCurrentSlot();
    setEditRoute(value);
    setCreateRoute(false);
  }

  const handleDeleteDialogClose = useCallback(
    () => {
      setRouteToBeDeleted({});
      setOpenDeleteDialog(false);
    },
    [],
  );

  const handleDeleteRoute = useCallback(
    () => {
      const deletePayload = createDeleteRoutePayload(routeToBeDeleted.id);
      if (deletePayload) {
        dispatch(
          deleteRoute({
            payload: deletePayload
          })
        )
      }
    },
    [dispatch, routeToBeDeleted],
  )

  const deleteRouteDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'delete') {
        handleDeleteRoute();
      }
      handleDeleteDialogClose();
    },
    [handleDeleteDialogClose, handleDeleteRoute],
  )

  const getDeleteDialogActions = () => {
    return (
      <Grid className={componentClasses.deleteDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { deleteRouteDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { deleteRouteDialogActionHandler('delete') }}>{AppConstants.BUTTONS.PROCEED}</Button>
      </Grid>
    )
  }

  const getHubSelectionDialogActions = () => {
    return (
      <Grid className={componentClasses.hubSelectionDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { hubSelectionDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" disabled={createRouteHub === AppConstants.SELECT_NONE.value} onClick={() => { hubSelectionDialogActionHandler('continue') }}>{AppConstants.BUTTONS.CONTINUE}</Button>
      </Grid>
    )
  }

  const handlePublishRoutes = useCallback(
    () => {
      const publishPayload = createPublishRoutesPayload(selectedRoutes);
      if (publishPayload) {
        dispatch(
          publishRoutes({
            payload: publishPayload
          })
        )
      }
    },
    [dispatch, selectedRoutes],
  )

  const publishRoutesDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'publish') {
        handlePublishRoutes();
      }
      handlePublishDialogClose();
    },
    [handlePublishDialogClose, handlePublishRoutes],
  )

  const getPublishDialogActions = () => {
    return (
      <Grid className={componentClasses.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { publishRoutesDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { publishRoutesDialogActionHandler('publish') }}>{AppConstants.BUTTONS.PROCEED}</Button>
      </Grid>
    )
  }

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
    },
    [],
  );

  const handleProgressClose = useCallback(
    () => {
      setRenderExcelProgress(false);
    },
    [],
  );

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSnackbarExited = useCallback(
    () => {
      if (errorCode === AppConstants.VALET_TROLLEY_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
      if (error) {
        dispatch(
          resetPlanningError()
        )
      }
      if (updatedRoute && updatedRoute.route) {
        dispatch(
          clearUpdatedRoute()
        )
      }
    },
    [dispatch, error, errorCode, updatedRoute]
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      setTabValue(value);
      let updatedFilters: any = {};
      if (value === AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES) {
        updatedFilters = { ...routesActiveFilters, hub: { ...routesActiveFilters.hub, value: ordersActiveFilters.hub.value }, hubName: { ...routesActiveFilters.hubName, value: ordersActiveFilters.hubName.value }, dateRange: ordersActiveFilters.dateRange, deliverySlot: ordersActiveFilters.deliverySlot };
        setIsRouteSearched(false);
        setRoutesActiveFilters(updatedFilters);
      } else {
        updatedFilters = { ...ordersActiveFilters, hub: { ...ordersActiveFilters.hub, value: routesActiveFilters.hub.value }, hubName: { ...ordersActiveFilters.hubName, value: routesActiveFilters.hubName.value }, dateRange: routesActiveFilters.dateRange, deliverySlot: routesActiveFilters.deliverySlot };
        setIsOrderSearched(false);
        setOrdersActiveFilters(updatedFilters);
      }
    },
    [ordersActiveFilters, routesActiveFilters],
  )

  const handleChipFilter = useCallback(
    (chip: any, activeFilters: any, isRoute?: boolean) => {
      let filters = { ...activeFilters };
      setIsTabChange(true);
      onLoadRef.current = false;
      if (isRoute) {
        filters = chip.code === AppConstants.VALET_TROLLEY_CONSTANTS.ALL_FILTER_CHIP ? { ...filters, displayRouteStatus: null } : { ...filters, displayRouteStatus: { ...AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.DISPLAY_ROUTE_STATUS_FILTER_OBJ, value: [chip.code] } }
        setIsRouteSearched(false);
        if (chip.code === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP) {
          setTabValue(AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.VALET_TROLLEY);
          setActiveOrdersFilterChip(chip.code);
        }
        if (chip.code !== activeRoutesFilterChip) {
          setActiveRoutesFilterChip(chip.code);
          routesFilterCallback(filters, false, true);
          const headerData = routesTableWrapperData.HeaderData.map((header: any) => {
            let newHeader = { ...header };
            if (newHeader.field === 'actions') {
              newHeader.renderCell = chip.code === AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.UNEDITABLE_CHIP ? () => (<></>) : newHeader.renderCell;
            }
            return newHeader;
          })
          setRoutesTableWrapperData({ ...routesTableWrapperData, HeaderData: headerData });
        }
      } else {
        filters = chip.code === AppConstants.VALET_TROLLEY_CONSTANTS.ALL_FILTER_CHIP ? { ...filters, displayRouteStatus: null } : { ...filters, displayRouteStatus: { ...AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DISPLAY_ROUTE_STATUS_FILTER_OBJ, value: [chip.code] } }
        setIsOrderSearched(false);
        if (chip.code !== activeOrdersFilterChip) {
          setActiveOrdersFilterChip(chip.code);
          ordersFilterCallback(filters, false, true);
        }
      }
      setRenderExcelProgress(false);
    },
    [routesFilterCallback, ordersFilterCallback, routesTableWrapperData, activeOrdersFilterChip, activeRoutesFilterChip],
  )

  const updateSelectedRoutes = useCallback(
    (params: SelectionChangeParams) => {
      setSelectedRoutes(params.rows);
    },
    []
  );

  const getFilterChips = (isRoute?: boolean) => {
    const filterChips = isRoute ? AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.FILTER_CHIPS : AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.FILTER_CHIPS;
    return filterChips.map((chip: any, index: number) => (
      <>
        <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem ${index === 0 ? 'firstItem' : ''} ${isRoute ? activeRoutesFilterChip === chip.code ? 'active' : '' : activeOrdersFilterChip === chip.code ? 'active' : ''}`} onClick={() => handleChipFilter(chip, isRoute ? routesActiveFilters : ordersActiveFilters, isRoute)}>

          <Grid container className="chipContainer">
            <Grid item className="chipItem name">
              <Typography>{chip.value}</Typography>
            </Grid>
            <Grid item className="chipItem count">
              <Typography>{chip.code === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP ? vtOrdersFilterChipsDisplayCount.consignmentCount[chip.code] : isRoute ? routesFilterChipsDisplayCount.routCount[chip.code] : vtOrdersFilterChipsDisplayCount.consignmentCount[chip.code]}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    ))
  };

  const getPublishedErrorContentItems = () => {
    return publishedRoutesErrors.length ? publishedRoutesErrors.map((error: any) => (
      <Grid key={error.id} item className="contentItem">
        <Grid container className="content">
          <Grid item className="dotIcon"></Grid>
          <Grid item className="description">
            <Typography className="text">{error.message}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )) : null;
  };

  const handlePublishErrorPopupClose = useCallback(
    () => {
      setOpenPublishErrorMsgPopup(false);
      dispatch(
        clearPublishedRoutesErrors()
      );
    },
    [dispatch],
  );

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    history.replace({ pathname: location.pathname, state: {} })
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    onLoadRef.current = true;
    fetchAllDeliveryReasons();
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    if (publishedRoutes.length) {
      onLoadRef.current = false;
      openSnackbarPopup(`"${publishedRoutes.length} Route(s)" ${AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.PUBLISH_ROUTES_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      routesFilterCallback(routesActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedRoutes])

  useEffect(() => {
    if (updatedRoute && updatedRoute.route) {
      openSnackbarPopup(`Route "${updatedRoute.route.routeId}" ${AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.UPDATE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
    }
    if (!(updatedRoute && routeDiscardSuccess)) {
      if (editRoute) {
        // history.push(AppConstants.ROUTES.EDIT_ROUTE);
        history.push(`${AppConstants.ROUTES.VALET_TROLLEY_EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          route: editRoute,
          slots: editRoute.deliverySlot || [],
          redirect: {
            route: AppConstants.ROUTES.PLANNING,
            state: {
              tabValue: AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES
            }
          }
        });
      }
      if (createRoute) {
        const hubName = getValueFromArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [], "value", createRouteHub);
        history.push(AppConstants.ROUTES.VALET_TROLLEY_CREATE_ROUTE, {
          hubCode: createRouteHub,
          hubName: hubName.length ? hubName[0].name : createRouteHub,
        });
      }
      if (viewRoute) {
        history.push(`${AppConstants.ROUTES.VALET_TROLLEY_VIEW_ROUTE}/${viewRoute.id}`, {
          plannedDate: viewRoute.plannedDate,
          hubCode: viewRoute.hubCode,
          hubName: viewRoute.hubName,
          slots: viewRoute.deliverySlot || [],
          route: viewRoute
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRoute, routeDiscardSuccess])

  useEffect(() => {
    if (editRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(
          clearUpdatedRoute()
        );
      } else {
        history.push(`${AppConstants.ROUTES.VALET_TROLLEY_EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          route: editRoute,
          slots: editRoute.deliverySlot || [],
          redirect: {
            route: AppConstants.ROUTES.PLANNING,
            state: {
              tabValue: AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES
            }
          }
        });
      }
    }
    if (createRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(
          clearUpdatedRoute()
        );
      } else {
        const hubName = getValueFromArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [], "value", createRouteHub);
        history.push(AppConstants.ROUTES.VALET_TROLLEY_CREATE_ROUTE, {
          hubCode: createRouteHub,
          hubName: hubName.length ? hubName[0].name : createRouteHub,
        });
      }
    }
    if (viewRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(
          clearUpdatedRoute()
        );
      } else {
        history.push(`${AppConstants.ROUTES.VALET_TROLLEY_VIEW_ROUTE}/${viewRoute.id}`, {
          plannedDate: viewRoute.plannedDate,
          hubCode: viewRoute.hubCode,
          hubName: viewRoute.hubName,
          slots: viewRoute.deliverySlot || [],
          route: viewRoute
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRoute, createRoute, viewRoute])

  useEffect(() => {
    if (deletedRoute) {
      onLoadRef.current = false;
      openSnackbarPopup(`Route "${deletedRoute.id}" ${AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.DELETE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      routesFilterCallback(routesActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedRoute])

  useEffect(() => {
    if (vtReplanningConsignmentSuccess) {
      openSnackbarPopup(AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.REPLANNING_CONSIGNMENT_SUCCESS_MSG, AppConstants.SNACKBAR.TYPES.SUCCESS);
      setIsOrderSearched(false);
      ordersFilterCallback(ordersActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vtReplanningConsignmentSuccess])

  useEffect(() => {
    if (isUpdateAddress) {
      setIsOrderSearched(false);
      ordersFilterCallback(ordersActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateAddress])

  useEffect(() => {
    if (publishedRoutesErrors.length) {
      setOpenPublishErrorMsgPopup(true);
    }
  }, [publishedRoutesErrors])

  useEffect(() => {
    if (isOrderSearched && isDisplayCount) {
      const chips = Object.keys(vtOrdersFilterChipsDisplayCount.consignmentCount).filter((key: string) => vtOrdersFilterChipsDisplayCount.consignmentCount[key] > 0);
      let selectedChip;
      let selectedChipValue;
      if (chips.length === 2) {
        selectedChip = chips.filter((key: string) => key !== AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      } else {
        selectedChip = chips.filter((key: string) => key === AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      }
      handleChipFilter({ code: chips.length ? chips[0] === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.CANCELLED && chips.length === 2 ? chips[1] : selectedChipValue : activeOrdersFilterChip }, ordersActiveFilters)
    } else {
      setOrdersListToShow(lastMileVTOrdersDataTransform(ordersData) as any);
      handleLoadMoreDisableState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vtOrdersFilterChipsDisplayCount, ordersData]);

  useEffect(() => {
    if (isRouteSearched) {
      const chips = Object.keys(routesFilterChipsDisplayCount.routCount).filter((key: string) => routesFilterChipsDisplayCount.routCount[key] > 0);
      handleChipFilter({ code: chips.length ? chips[0] : activeRoutesFilterChip }, routesActiveFilters, true);
    } else {
      setRoutesListToShow(lastMileVTRoutesDataTransform(routesData) as any);
      handleLoadMoreDisableState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesFilterChipsDisplayCount, routesData]);

  useEffect(() => {
    handleHeaderButtonsDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersListToShow]);

  useEffect(() => {
    handleHeaderButtonsDisableState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesListToShow, selectedRoutes]);

  useEffect(() => {
    const ordersHeaderData = ordersTableWrapperData.HeaderData.map((header: any) => {
      if (header.filterObj) {
        if (header.filterObj.fieldName === 'hub') {
          header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
        }
        if (header.filterObj.fieldName === 'hubName') {
          header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
        }
        if (header.filterObj.fieldName === 'deliveryArea') {
          header.filterObj.items = ordersFiltersDropdowns.deliveryAreaList || [];
        }
        if (header.filterObj.fieldName === 'orderType') {
          header.filterObj.items = ordersFiltersDropdowns.typeList || [];
        }
        if (header.filterObj.fieldName === 'deliverySlot') {
          header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
        }
        if (header.filterObj.fieldName === 'deliveryStatus') {
          header.filterObj.items = ordersFiltersDropdowns.statusList || [];
        }
        if (header.filterObj.fieldName === 'paymentType') {
          header.filterObj.items = ordersFiltersDropdowns.paymentTypeList || [];
        }
        if (header.filterObj.fieldName === 'carrier') {
          header.filterObj.items = ordersFiltersDropdowns.carrierList || [];
        }
        if (header.filterObj.fieldName === 'deliveryProposition') {
          header.filterObj.items = ordersFiltersDropdowns.deliveryPropositionList || [];
        }
        if (header.filterObj.fieldName === 'routeId') {
          header.filterObj.items = ordersFiltersDropdowns.routeIdList || [];
        }
        if (header.filterObj.fieldName === 'routeStatus') {
          header.filterObj.items = ordersFiltersDropdowns.routeStatusList || [];
        }
        if (header.filterObj.fieldName === 'driver') {
          header.filterObj.items = ordersFiltersDropdowns.driverList || [];
        }
        if (header.filterObj.fieldName === 'vehicle') {
          header.filterObj.items = ordersFiltersDropdowns.vehicleList || [];
        }
        if (header.filterObj.fieldName === 'helper') {
          header.filterObj.items = ordersFiltersDropdowns.helperList || [];
        }
        if (header.filterObj.fieldName === 'deliveryFailedReason') {
          header.filterObj.items = ordersFiltersDropdowns.undeliveredReasonList || [];
        }
      }
      return header;
    });
    const routesHeaderData = routesTableWrapperData.HeaderData.map((header: any) => {
      if (header.filterObj) {
        if (header.filterObj.fieldName === 'hub') {
          header.filterObj.items = routesFiltersDropdowns.hubCodeList || [];
        }
        if (header.filterObj.fieldName === 'hubName') {
          header.filterObj.items = routesFiltersDropdowns.hubNameList || [];
        }
        if (header.filterObj.fieldName === 'deliverySlot') {
          header.filterObj.items = routesFiltersDropdowns.deliverySlotList || [];
        }
        if (header.filterObj.fieldName === 'routeId') {
          header.filterObj.items = routesFiltersDropdowns.routeIdList || [];
        }
        if (header.filterObj.fieldName === 'deliveryArea') {
          header.filterObj.items = routesFiltersDropdowns.deliveryAreaList || [];
        }
        if (header.filterObj.fieldName === 'carrier') {
          header.filterObj.items = routesFiltersDropdowns.carrierList || [];
        }
        if (header.filterObj.fieldName === 'deliveryProposition') {
          header.filterObj.items = routesFiltersDropdowns.deliveryPropositionList || [];
        }
        if (header.filterObj.fieldName === 'routeStatus') {
          header.filterObj.items = routesFiltersDropdowns.routeStatusList || [];
        }
        if (header.filterObj.fieldName === 'driver') {
          header.filterObj.items = routesFiltersDropdowns.driverList || [];
        }
        if (header.filterObj.fieldName === 'vehicle') {
          header.filterObj.items = routesFiltersDropdowns.vehicleList || [];
        }
        if (header.filterObj.fieldName === 'helper') {
          header.filterObj.items = routesFiltersDropdowns.helperList || [];
        }
        if (header.filterObj.fieldName === 'createdBy') {
          header.filterObj.items = routesFiltersDropdowns.createdByList || [];
        }
      }
      return header;
    });
    const hubsToAdd = hubCode.map((hub: string) => ({
      name: hub,
      value: hub
    }));
    let updatedFilters: any = {};
    setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: ordersHeaderData });
    setRoutesTableWrapperData({ ...routesTableWrapperData, HeaderData: routesHeaderData });
    setActiveOrdersFilterChip(AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);
    setActiveRoutesFilterChip(AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.DEFAULT_FILTER_CHIP);
    if (tabValue === AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.VALET_TROLLEY) {
      setIsOrderSearched(false);
      updatedFilters = { ...ordersActiveFilters, hub: { ...ordersActiveFilters.hub, value: hubsToAdd } };
      if (!onLoadRef.current) {
        ordersFilterCallback(updatedFilters);
      }
    } else {
      setIsRouteSearched(false);
      updatedFilters = { ...routesActiveFilters, hub: { ...routesActiveFilters.hub, value: hubsToAdd } };
      if (!onLoadRef.current) {
        routesFilterCallback(updatedFilters);
      }
    }
    onLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubCode]);

  useEffect(() => {
    if (ordersTableWrapperData && ordersTableWrapperData.HeaderData) {
      let headerData = ordersTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === 'hub') {
            header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
          }
          if (header.filterObj.fieldName === 'hubName') {
            header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === 'deliveryArea') {
            header.filterObj.items = ordersFiltersDropdowns.deliveryAreaList || [];
          }
          if (header.filterObj.fieldName === 'orderType') {
            header.filterObj.items = ordersFiltersDropdowns.typeList || [];
          }
          if (header.filterObj.fieldName === 'deliverySlot') {
            header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === 'deliveryStatus') {
            header.filterObj.items = ordersFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === 'paymentType') {
            header.filterObj.items = ordersFiltersDropdowns.paymentTypeList || [];
          }
          if (header.filterObj.fieldName === 'carrier') {
            header.filterObj.items = ordersFiltersDropdowns.carrierList || [];
          }
          if (header.filterObj.fieldName === 'deliveryProposition') {
            header.filterObj.items = ordersFiltersDropdowns.deliveryPropositionList || [];
          }
          if (header.filterObj.fieldName === 'routeId') {
            header.filterObj.items = ordersFiltersDropdowns.routeIdList || [];
          }
          if (header.filterObj.fieldName === 'routeStatus') {
            header.filterObj.items = ordersFiltersDropdowns.routeStatusList || [];
          }
          if (header.filterObj.fieldName === 'driver') {
            header.filterObj.items = ordersFiltersDropdowns.driverList || [];
          }
          if (header.filterObj.fieldName === 'vehicle') {
            header.filterObj.items = ordersFiltersDropdowns.vehicleList || [];
          }
          if (header.filterObj.fieldName === 'helper') {
            header.filterObj.items = ordersFiltersDropdowns.helperList || [];
          }
          if (header.filterObj.fieldName === 'deliveryFailedReason') {
            header.filterObj.items = ordersFiltersDropdowns.undeliveredReasonList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: headerData });
    }
    if (routesTableWrapperData && routesTableWrapperData.HeaderData) {
      let headerData = routesTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === 'hub') {
            header.filterObj.items = routesFiltersDropdowns.hubCodeList || [];
          }
          if (header.filterObj.fieldName === 'hubName') {
            header.filterObj.items = routesFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === 'deliverySlot') {
            header.filterObj.items = routesFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === 'routeId') {
            header.filterObj.items = routesFiltersDropdowns.routeIdList || [];
          }
          if (header.filterObj.fieldName === 'deliveryArea') {
            header.filterObj.items = routesFiltersDropdowns.deliveryAreaList || [];
          }
          if (header.filterObj.fieldName === 'carrier') {
            header.filterObj.items = routesFiltersDropdowns.carrierList || [];
          }
          if (header.filterObj.fieldName === 'deliveryProposition') {
            header.filterObj.items = routesFiltersDropdowns.deliveryPropositionList || [];
          }
          if (header.filterObj.fieldName === 'routeStatus') {
            header.filterObj.items = routesFiltersDropdowns.routeStatusList || [];
          }
          if (header.filterObj.fieldName === 'driver') {
            header.filterObj.items = routesFiltersDropdowns.driverList || [];
          }
          if (header.filterObj.fieldName === 'vehicle') {
            header.filterObj.items = routesFiltersDropdowns.vehicleList || [];
          }
          if (header.filterObj.fieldName === 'helper') {
            header.filterObj.items = routesFiltersDropdowns.helperList || [];
          }
          if (header.filterObj.fieldName === 'createdBy') {
            header.filterObj.items = routesFiltersDropdowns.createdByList || [];
          }
        }
        return header;
      });
      setRoutesTableWrapperData({ ...routesTableWrapperData, HeaderData: headerData });
    }
    if (isLastMileViewer || isOnlyStdLastMileViewer) {
      const routesHeaderData = routesTableWrapperData.HeaderData.map((header: any) => {
        let newHeader = {...header};
        if (newHeader.field === "actions") {
          newHeader.hide = true;
        }
        return newHeader;
      });
      setRoutesTableWrapperData({...routesTableWrapperData, HeaderData: routesHeaderData});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersFiltersDropdowns, routesFiltersDropdowns, isLastMileViewer, isOnlyStdLastMileViewer]);

  useEffect(() => {
    handleHeaderButtonsHideState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrdersFilterChip]);

  useEffect(() => {
    handleHeaderButtonsHideState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoutesFilterChip]);

  return (
    <div className={componentClasses.root}>
      {loading && <Loader></Loader>}
      <Grid className={componentClasses.container} container>
        <Grid className="planningItem content" item>
          <TabContext value={tabValue}>
            <Tabs TabIndicatorProps={{ style: { backgroundColor: "#1F1F1F" } }} value={tabValue} aria-label="ValetTrolley/Routes Navigation Tabs" style={{ paddingLeft: "20px", paddingTop: "8px", lineHeight: "24px", minHeight: 0, backgroundColor: "rgba(244, 244, 244, 0.6)", paddingBottom: "16px" }} onChange={handleTabChange} >
              <Tab label="Orders" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.VALET_TROLLEY} />
              <Tab label="Routes" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES} />
            </Tabs>
            <TabPanel className="tabPanel" value={AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.VALET_TROLLEY}>
              <Grid container className={componentClasses.tabPanelContainer}>
                <Grid item className="panelItem chipFilter">
                  <Grid container className="chipsContainer">
                    {
                      getFilterChips()
                    }
                  </Grid>
                </Grid>
                <Grid item className="panelItem gridWrapper">
                  <GridWrapper
                    showHeader={false}
                    checkboxSelection={false}
                    headerData={ordersTableWrapperData.HeaderData}
                    rowData={ordersListToShow}
                    isTabChange={isTabChange}
                    loading={loading ? false : tableLoading}
                    searchTypes={ordersTableWrapperData.SEARCH_TYPES}
                    defaultFilter={ordersTableWrapperData.defaultFiltersObj}
                    activeFilter={ordersActiveFilters}
                    headerButtons={ordersTableWrapperData.headerButtons}
                    title={ordersTableWrapperData.title}
                    disableSelectionOnClick={true}
                    sortModel={ordersTableWrapperData.sortModel as SortModel}
                    searchValue={ordersSearchValue}
                    loadMore={{ ...ordersTableWrapperData.loadMore, rowCount: ordersData.totalElements || 0 }}
                    headerBtnClick={handleOrdersHeaderButtonClick}
                    searchCallback={ordersSearchFilterHandler}
                    onCellClick={handleConsignmentCellClick}
                    filterGrid={ordersFilterCallback}
                    onRowClick={handleRowClick}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel className="tabPanel routes" value={AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES}>
              <Grid container className={componentClasses.tabPanelContainer}>
                <Grid item className="panelItem chipFilter">
                  <Grid container className="chipsContainer">
                    {
                      getFilterChips(true)
                    }
                  </Grid>
                </Grid>
                <Grid item className="panelItem gridWrapper">
                  <GridWrapper
                    showHeader={false}
                    checkboxSelection={!(isLastMileViewer || isOnlyStdLastMileViewer)}
                    headerData={routesTableWrapperData.HeaderData}
                    rowData={routesListToShow}
                    isTabChange={isTabChange}
                    loading={loading ? false : tableLoading}
                    searchTypes={routesTableWrapperData.SEARCH_TYPES}
                    defaultFilter={routesTableWrapperData.defaultFiltersObj}
                    activeFilter={routesActiveFilters}
                    headerButtons={routesTableWrapperData.headerButtons}
                    title={routesTableWrapperData.title}
                    disableSelectionOnClick={true}
                    sortModel={routesTableWrapperData.sortModel as SortModel}
                    searchValue={routesSearchValue}
                    loadMore={{ ...routesTableWrapperData.loadMore, rowCount: routesData.totalElements || 0 }}
                    onSelectionChange={updateSelectedRoutes}
                    headerBtnClick={handleRoutesHeaderButtonClick}
                    onCellClick={handleRouteCellClick}
                    searchCallback={routesSearchFilterHandler}
                    filterGrid={routesFilterCallback}
                  />
                </Grid>
              </Grid>
              {
                openPublishErrorMsgPopup &&
                <Grid container className={componentClasses.publishErrorPopupContainer}>
                  <Grid item className="popupItem titleBlock">
                    <Grid container className="titleContainer">
                      <Grid item className="titleItem">
                        <Typography className="title">{`${publishedRoutesErrors.length} Route(s) ${AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.PUBLISH_ROUTES_ERROR_MSG}`}</Typography>
                      </Grid>
                      <Grid item className="titleItem">
                        <IconButton aria-label="close" className="closeBtn" onClick={handlePublishErrorPopupClose}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="popupItem contentBlock">
                    <Grid container className="contentContainer">
                      {getPublishedErrorContentItems()}
                    </Grid>
                  </Grid>
                </Grid>
              }
            </TabPanel>

          </TabContext>


        </Grid>
        <CustomDialog
          open={openCreateRouteHubSelectionPopup}
          PaperProps={createRouteHubSelectionDialogPaperProps}
          title={getHubSelectionDialogTitleContent()}
          content={getHubSelectionDialogDetailsContent()}
          actions={getHubSelectionDialogActions()}
          handleClose={handleHubSelectionDialogClose}
        ></CustomDialog>
        <ValetTrolleyOrderDetails open={openConsignmentDetailsPopup} consignment={consignmentData} closePopup={closeConsignmentDetailsPopup}></ValetTrolleyOrderDetails>
        <CustomDialog
          open={openPublishDialog}
          PaperProps={publishDialogPaperProps}
          title={getPublishDialogTitleContent()}
          content={getPublishDialogDetailsContent()}
          actions={getPublishDialogActions()}
          handleClose={handlePublishDialogClose}
        ></CustomDialog>
        <CustomDialog
          open={openDeleteDialog}
          PaperProps={deleteDialogPaperProps}
          title={getDeleteDialogTitleContent()}
          content={getDeleteDialogDetailsContent()}
          actions={getDeleteDialogActions()}
          handleClose={handleDeleteDialogClose}
        ></CustomDialog>
        {!error && renderExcelProgress ? (
          <Grid container className={componentClasses.excelDownloadStatus}>
            {excelLoading ? (
              <Grid item>
                <Typography className={componentClasses.excelDownloadStatusText}>
                  Your files are getting downloaded
                </Typography>
              </Grid>) : (
                <Grid item>
                  <Typography className={componentClasses.excelDownloadStatusText}>
                    Your files have been downloaded
                  </Typography>
                </Grid>
              )}
            {excelLoading ? <Loader></Loader> :
              <Grid item style={{ display: "flex" }}>
                <SvgIcon className={componentClasses.checkedIconStyle} component={checkIcon} />
                <IconButton aria-label="close" className={componentClasses.close} onClick={handleProgressClose}>
                  <CloseIcon className={componentClasses.closeIcon} />
                </IconButton>
              </Grid>
            }
          </Grid>) : (
            exportError === "INTERNAL_SERVER_ERROR" && renderExcelProgress ? (
              <Grid container className={componentClasses.excelDownloadError}>
                <Grid item>
                  <Typography className={componentClasses.excelDownloadStatusText}>
                    Error Downloading the file. Try Again!!
              </Typography>
                </Grid>
                <Grid item style={{ display: "flex" }}>
                  <IconButton aria-label="close" className={componentClasses.close} onClick={handleProgressClose}>
                    <CloseIcon className={componentClasses.closeIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null)}
        <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
      </Grid>

    </div>
  );
};

export default ValetTrolley;