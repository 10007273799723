import React, { forwardRef, ReactElement, Ref, useCallback, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import * as yup from 'yup';
import { useStyles } from './HubAddOrEditDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography } from '@material-ui/core';
import AppConstants from '../constants';
import { useFormik } from 'formik';
import TextBox from '../common/TextBox';
import SelectBox from '../common/SelectBox';
import { useSelector } from 'react-redux';
import { AppState } from '../config/redux/reducers';

interface HubAddOrEditDetailsProps {
  open: boolean;
  user: any;
  isEdit: boolean;
  closePopup: () => void;
  handleDialogAction?: (params: any) => void;
}

const inputValidationSchema = yup.object({
  hubId: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.HUBID.REQUIRED.msg),
  hubName: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.HUBNAME.REQUIRED.msg),
  latitude: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.LATITUDE.REQUIRED.msg),
  longitude: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.LONGITUDE.REQUIRED.msg),
  status: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.HUB_STATUS.REQUIRED.msg)
    .matches(new RegExp(AppConstants.HUB_CONSTANTS.VALIDATIONS.HUB_STATUS.REQUIRED.value), AppConstants.HUB_CONSTANTS.VALIDATIONS.HUB_STATUS.REQUIRED.msg),
  serviceType: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.SERVICE_TYPE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.HUB_CONSTANTS.VALIDATIONS.SERVICE_TYPE.REQUIRED.value), AppConstants.HUB_CONSTANTS.VALIDATIONS.SERVICE_TYPE.REQUIRED.msg),
  category: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.SERVICE_TYPE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.HUB_CONSTANTS.VALIDATIONS.SERVICE_TYPE.REQUIRED.value), AppConstants.HUB_CONSTANTS.VALIDATIONS.CATEGORY.REQUIRED.msg),
  country: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg)
    .matches(new RegExp(AppConstants.HUB_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.value), AppConstants.HUB_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg),
  address: yup
    .string()
    .required(AppConstants.HUB_CONSTANTS.VALIDATIONS.ADDRESS.REQUIRED.msg),
});



const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const HubAddOrEditDetails = (props: HubAddOrEditDetailsProps) => {

  const classes = useStyles();
  const { open, user, isEdit, closePopup, handleDialogAction } = props;
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const { addUserDropdowns } = useSelector(
    (state: AppState) => state.hub
  );
  const [resetFormFlag, setResetFormFlag] = useState(false);

  let countryCodeValue = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const getInitialValues = () => {
    return isEdit ? {
      hubId: user.hubId ? user.hubId : '',
      hubName: user.hubName ? user.hubName : '',
      latitude: user.latitude ? user.latitude : '',
      longitude: user.longitude ? user.longitude : '',
      status: user.hubStatus ? user.hubStatus : '',
      serviceType: user.serviceType ? user.serviceType[0] : '',
      category: user.category ? user.category[0] : '',
      country: countryCodeValue,
      address: user.area ? user.area : '',
    } : {
        hubId: '',
        hubName: '',
        latitude: '',
        longitude: '',
        status: AppConstants.SELECT_NONE.value,
        serviceType: AppConstants.SELECT_NONE.value,
        category: AppConstants.SELECT_NONE.value,
        country: AppConstants.SELECT_NONE.value,
        address: '',
      }
  }
  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: inputValidationSchema,
    validate: () => {
      setResetFormFlag(false);
    },
    onSubmit: (values) => {
      dialogActionHandler('submit', values);
    }
  });

  const handleDialogClose = useCallback(
    () => {
      closePopup();
    },
    [closePopup],
  );

  const dialogActionHandler = useCallback(
    (type: string, values?: any) => {
      handleDialogAction && handleDialogAction({
        type: type,
        values: values,
        isEdit: isEdit
      });
    },
    [handleDialogAction, isEdit],
  );

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label">{isEdit ? 'Edit Hub' : 'Add Hub'}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }

  const getDetailsContent = () => {
    return (
      <form onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.contentContainer} container>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Hub Id</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  disabled={isEdit}
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.hubId}
                  error={inputFormik.touched.hubId && Boolean(inputFormik.errors.hubId)}
                  helperText={inputFormik.touched.hubId && inputFormik.errors.hubId}
                  textBoxId="AddHubIdTextbox"
                  name="hubId"
                  type="text"
                  placeholderText="Hub Id"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Hub Name</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.hubName}
                  error={inputFormik.touched.hubName && Boolean(inputFormik.errors.hubName)}
                  helperText={inputFormik.touched.hubName && inputFormik.errors.hubName}
                  textBoxId="AddhubNameTextbox"
                  name="hubName"
                  type="text"
                  placeholderText="Hub Name"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Latitude</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.latitude}
                  error={inputFormik.touched.latitude && Boolean(inputFormik.errors.latitude)}
                  helperText={inputFormik.touched.latitude && inputFormik.errors.latitude}
                  textBoxId="AddlatitudeTextbox"
                  name="latitude"
                  type="text"
                  placeholderText="Latitude"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Longitude</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.longitude}
                  error={inputFormik.touched.longitude && Boolean(inputFormik.errors.longitude)}
                  helperText={inputFormik.touched.longitude && inputFormik.errors.longitude}
                  textBoxId="AddlongitudeTextbox"
                  name="longitude"
                  type="text"
                  placeholderText="Longitude"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Status</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.status}
                  error={inputFormik.touched.status && Boolean(inputFormik.errors.status)}
                  helperText={inputFormik.touched.status && inputFormik.errors.status}
                  reset={resetFormFlag}
                  id="AddHubStatusSelectBox"
                  inputProps={{
                    name: "status",
                    id: "AddHubStatusSelectBox"
                  }}
                  items={AppConstants.HUB_CONSTANTS.HUB_STATUS}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Service Type</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.serviceType}
                  error={inputFormik.touched.serviceType && Boolean(inputFormik.errors.serviceType)}
                  helperText={inputFormik.touched.serviceType && inputFormik.errors.serviceType}
                  reset={resetFormFlag}
                  id="AddserviceTypeSelectBox"
                  inputProps={{
                    name: "serviceType",
                    id: "AddserviceTypeSelectBox"
                  }}
                  items={AppConstants.HUB_CONSTANTS.HUB_SERVICE_TYPE}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Category</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.category}
                  error={inputFormik.touched.category && Boolean(inputFormik.errors.category)}
                  helperText={inputFormik.touched.category && inputFormik.errors.category}
                  reset={resetFormFlag}
                  id="AddCategorySelectBox"
                  inputProps={{
                    name: "category",
                    id: "AddCategorySelectBox"
                  }}
                  items={AppConstants.HUB_CONSTANTS.CATEGORY}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Typography className="hubAddress">Hub Address</Typography>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Country</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.country}
                  error={inputFormik.touched.country && Boolean(inputFormik.errors.country)}
                  helperText={inputFormik.touched.country && inputFormik.errors.country}
                  reset={resetFormFlag}
                  id="AddHubCountrySelectBox"
                  inputProps={{
                    name: "country",
                    id: "AddHubCountrySelectBox"
                  }}
                  items={addUserDropdowns.countriesList["ROLE_SUPER_ADMIN"] || []}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Address</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.address}
                  error={inputFormik.touched.address && Boolean(inputFormik.errors.address)}
                  helperText={inputFormik.touched.address && inputFormik.errors.address}
                  textBoxId="AddhubAddressTextbox"
                  name="address"
                  type="text"
                  placeholderText="Address"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  const getDialogActions = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className="buttonsContainer" container>
          <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
          <Button className="userBtn primary" variant="contained" type="submit">{isEdit ? AppConstants.BUTTONS.SAVE : AppConstants.BUTTONS.ADD_HUB}</Button>
        </Grid>
      </form>
    );
  };

  useEffect(() => {
    if (open) {
      inputFormik.resetForm();
      setResetFormFlag(true);
      inputFormik.setValues(getInitialValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      actions={getDialogActions()}
      content={getDetailsContent()}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default HubAddOrEditDetails;