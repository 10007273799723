
import _ from "lodash";
import lodashIsNull from 'lodash/isNull';
import lodashCloneDeep from 'lodash/cloneDeep';

export function isNull(value: any): value is null {
    return lodashIsNull(value);
}

export function cloneDeep(obj: any) {
    return lodashCloneDeep(obj);
}