import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const progressStyles = makeStyles((theme) =>
    createStyles({
        linearProgressRoot: {
            height: "8px",
            borderRadius: "4px",
        },
        linearProgress: {
            marginTop: "8px",
        },
        linearProgressBarRoot: {
            borderRadius: 4,
            backgroundColor: COLORS.SECONDARY_DARK,
        },
        linearProgressColorDefault: {
            backgroundColor: COLORS.SECONDARY_DARK,
        },
        linearProgressBarColor: {
            backgroundColor: COLORS.LIGHT_PERSIAN_GREEN,
        },
        linearProgressBarColorInProgress: {
            backgroundColor: COLORS.GOLDEN_BELL,
        },
    }));