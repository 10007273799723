import React, { forwardRef, ReactElement, Ref, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//common components
import CustomDialog from '../common/Dialog';
import { useStyles } from './PosDetails.styles';
//material-ui components
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Button } from '@material-ui/core';
//redux
import { AppState } from "../config/redux/reducers";
import { regenerateQR, closeDialog } from './redux/posSlice';
//constants
import AppConstants from "../constants";

import { saveAs } from 'file-saver';

interface PosDetailsProps {
    open: boolean;
    hub: any;
    closePopup: () => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const PosDetails = (props: PosDetailsProps) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { regeneratedQRCode } = useSelector(
        (state: AppState) => state.pos
    )
    const {isLastMileViewer, isOnlyExpLastMileViewer} = useSelector((state: AppState) => state.userLogin);
    const { open, hub, closePopup } = props;
    let tableRecord: any = {};
    let hubItems: any = [];

    if (hub) {
        tableRecord = hub.tableRecord;
        if (hub.countryDetails) {
            for (let country in hub.countryDetails) {
                let countryHubs = hub.countryDetails[country];
                countryHubs.filter((hub: any) => {
                    hubItems.push(hub);
                    return true;
                })
            }
        }
    }
    const dialogPaperProps = {
        classes: {
            root: classes.dialogPaperPropsRoot
        },
        square: true
    }

    const dialogActionHandler = useCallback(
        (type: string, hub: any) => {
            if (type === 'update') {
                dispatch(
                    regenerateQR(
                        {
                            params: hub.hubId
                        }
                    )
                )
            } else {
                if (regeneratedQRCode) {
                    saveAs(regeneratedQRCode, `${hub.hubId}.jpg`)
                } else {
                    saveAs(hub && hub.hubQRImage, `${hub.hubId}.jpg`)
                }
            }
        },
        [dispatch, regeneratedQRCode],
    );

    const handleDialogClose = useCallback(
        () => {
            closePopup();
            dispatch(
                closeDialog()
            );
        },
        [closePopup, dispatch],
    );

    const getTitleContent = () => {
        return (
            <Grid className={classes.titleContainer} container>
                <Grid item>
                    <Grid className="headingContainer" container>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Typography className="titleHeading">
                                        <span className="label"> POS Name : </span>
                                        <span className="content">{tableRecord && tableRecord.hubName} </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }

    const getDetailsContent = () => {
      return (
        <Grid className={classes.contentContainer} container>
          <Grid item>
            <Grid className='detailsContent' container>
              <Grid container className='contentRow' item>
                <Grid container>
                  <Grid xs={5} className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Hub ID</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>{(tableRecord && tableRecord.hubId) || "-"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={4} className='contentColumn second' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Hub Address</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>{(tableRecord && tableRecord.area) || "-"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={3} className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Service Type</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>
                          {tableRecord && tableRecord.serviceType.join(" , ")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className='contentRow' item>
                <Grid container>
                  <Grid xs={5} className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Latitude/Longitude</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>{(tableRecord && tableRecord.latLong) || "-"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={4} className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Status</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>{(tableRecord && tableRecord.status) || "-"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={3} className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Country</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>{(tableRecord && tableRecord.country) || "-"}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className='contentRow' item>
                <Grid container>
                  <Grid container direction='column'>
                    {regeneratedQRCode ? (
                      <Grid item className={classes.qrImageSection}>
                        <img className={classes.qrImage} src={regeneratedQRCode} alt='icons' />
                      </Grid>
                    ) : (
                      <Grid item className={classes.qrImageSection}>
                        <img
                          className={classes.qrImage}
                          src={(tableRecord && tableRecord.hubQRImage) || ""}
                          alt='icons'
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.buttons} container>
              <Button
                className='userBtn secondary'
                variant='contained'
                onClick={() => {
                  dialogActionHandler("cancel", tableRecord);
                }}
              >
                {AppConstants.BUTTONS.DOWNLOAD_QR}
              </Button>
              {!(isLastMileViewer || isOnlyExpLastMileViewer) && (
                <Button
                  className='userBtn primary'
                  variant='contained'
                  onClick={() => {
                    dialogActionHandler("update", tableRecord);
                  }}
                >
                  {AppConstants.BUTTONS.REGENERATE_QR}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    };

    const getDialogActions = () => {
        return (
            <></>
        );
    };

    return (
        <CustomDialog
            open={open}
            TransitionComponent={Transition}
            PaperProps={dialogPaperProps}
            title={getTitleContent()}
            content={getDetailsContent()}
            actions={getDialogActions()}
            handleClose={handleDialogClose}
        ></CustomDialog>
    )
}

export default PosDetails;