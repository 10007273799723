import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        flexGrow: 1,
        height: "100%"
      },
      container: {
        flexDirection: "column",
        height: "100%",
        flexWrap: "nowrap"
      },
      filterSection: {
        paddingRight: 12,
        flexGrow: 1,
        '& .filterHeading': {
          alignSelf: "flex-start",
          '& .headingContainer': {
            cursor: "pointer",
            '& .heading': {
              fontSize: 16,
              fontWeight: 600,
              color: theme.palette.primary.main,
            },
            '& .logo': {
              transform: "rotate(0deg)",
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              '&.open': {
                transform: "rotate(180deg)",
              }
            }
          }
        },
        '& .filtersContent': {
          display: "flex",
          gap: 8,
          '& .buttons': {
            marginLeft: "auto",
            alignSelf: "center"
          }
        }
      },
      exceptionsFilterSection: {
        padding: theme.spacing(1, 2, 1, 1),
        flexGrow: 1,
        '& .filterHeading': {
          alignSelf: "flex-start",
          '& .headingContainer': {
            cursor: "pointer",
            '& .heading': {
              fontSize: 16,
              fontWeight: 600,
              color: theme.palette.primary.main,
            },
            '& .logo': {
              transform: "rotate(0deg)",
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              '&.open': {
                transform: "rotate(180deg)",
              }
            }
          }
        },
        '& .filtersContent': {
          '& .buttons': {
            marginLeft: "auto",
            alignSelf: "center"
          }
        }
      },
      heading: {
        color: theme.palette.primary.main
      },
      button: {
        margin: theme.spacing(0.5),
        '&.reset-btn': {
          marginRight: theme.spacing(0)
        }
      },
      tableSection: {
        width: '100%',
        height: '100%'
      }
    }),
);