import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import networkHandler from "../../../network/networkHandler";
import {EApiMiddlewareMethods} from "../../../network/networkHandler.types";
import {StdCodReconState} from "./stdCodPortalSlice.types";
import AppConstants from "../../../constants";
import {transformSelectValues, saveFile} from "../../../utils/helpers.utils";
import moment from "moment";

export const fetchDriversList = createAsyncThunk(
  "StdCodPortal/FetchDriversList",
  async (
    {
      params,
    }: {
      params: any;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "reconcile/filter-drivers",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrdersList = createAsyncThunk(
  "StdCodPortal/FetchOrdersList",
  async (
    {
      params,
    }: {
      params: any;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "reconcile/filter-orders",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "StdCodPortal/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "export/cod",
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob",
      } as any;
      const {data} = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reconcileCollectedAmtForStdOrder = createAsyncThunk(
  "StdCodPortal/ReconcileCollectedAmtForStdOrder",
  async (
    {
      params,
    }: {
      params: any;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "reconcile/collect",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const openCollectionConfirmation = createAsyncThunk("StdCodPortal/OpenCollectionConfirmation", () => {
  return false;
});

export const createCodReceiptDraft = createAsyncThunk(
  "StdCodPortal/CreateCodReceiptDraft",
  async (
    {
      params,
    }: {
      params: any;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "reconcile/receipt/draft",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadCodReceipt = createAsyncThunk(
  "StdCodPortal/UploadCodReceipt",
  async (
    {
      params,
    }: {
      params: any;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "reconcile/receipt",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      request.headers = {
        "content-type": "multipart/form-data",
      };
      const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePrevUploadedCodReceipt = createAsyncThunk(
  "StdCodPortal/DeletePrevUploadedCodReceipt",
  async (
    {
      params,
    }: {
      params: any;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "reconcile/receipt",
        method: EApiMiddlewareMethods.DELETE,
        data: params,
      } as any;
      const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitCodReceipts = createAsyncThunk(
  "StdCodPortal/SubmitCodReceipts",
  async (
    {
      params,
    }: {
      params: any;
    },
    {rejectWithValue}
  ) => {
    try {
      const request = {
        url: "reconcile/receipt/submit",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadCodClick = createAsyncThunk("StdCodPortal/UploadClick", () => {
  return false;
});

const initialState: StdCodReconState = {
  loading: false,
  error: "",
  errorCode: "",
  driversData: {
    elements: [],
    pageNumber: 0,
  },
  ordersData: {
    elements: [],
    pageNumber: 0,
  },
  excelLoading: false,
  driversFiltersDropdowns: {
    driverNameList: [],
    vendorNameList: [],
  },
  ordersFiltersDropdowns: {
    deliveryStatusList: [],
  },
  orderAfterCollection: {},
  isAmtCollected: false,
  stdDraftReceiptData: {},
  stdReceiptDataAfterUpload: {},
  isStdFileUploaded: false,
  stdUploadError: "",
  stdUploadErrorCode: "",
  stdReceiptDataAfterSubmit: {},
  stdReceiptsSubmittedFlag: false,
  ordersOnDriverDetails: "",
  codAmtOnDriverDetails: "",
  codPendingOnDriverDetails: "",
  codReceivedOnDriverDetails: "",
  isErrorOnStdUpload: false,
  totalOrdersAmtOnDriverDetails: "",
};

const stdCodPortalSlice = createSlice({
  name: "StandardCodPortal",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchDriversList.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchDriversList.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: {params},
          },
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driversData = {
          ...payload,
          elements:
            params.pageNumber === 0 ? [...payload.elements] : [...state.driversData.elements, ...payload.elements],
          pageNumber: payload.pageNumber,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        };
        if (payload?.filters) {
          state.driversFiltersDropdowns = {
            driverNameList: transformSelectValues(payload.filters.driverNames || []),
            vendorNameList: transformSelectValues(payload.filters.vendorNames || []),
          };
        }
        state.stdReceiptDataAfterUpload = {};
        state.isStdFileUploaded = false;
      })
      .addCase(fetchDriversList.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrdersList.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchOrdersList.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: {params},
          },
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.ordersData = {
          ...payload,
          elements: params.pageNumber === 0 ? payload?.elements : [...state.ordersData.elements, ...payload?.elements],
          pageNumber: payload.pageNumber,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        };
        if (payload?.filters) {
          state.ordersFiltersDropdowns = {
            deliveryStatusList: transformSelectValues(payload.filters.statuses || []),
          };
        }
        state.ordersOnDriverDetails = payload?.totalOrders;
        state.codAmtOnDriverDetails = payload?.totalCODAmount;
        state.codPendingOnDriverDetails = payload?.totalCODPending;
        state.codReceivedOnDriverDetails = payload?.totalCODReceived;
        state.totalOrdersAmtOnDriverDetails = payload?.totalOrdersCODAmount;
      })
      .addCase(fetchOrdersList.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const {payload} = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `COD_RECON_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(reconcileCollectedAmtForStdOrder.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(reconcileCollectedAmtForStdOrder.fulfilled, (state, action) => {
        const {payload} = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.orderAfterCollection = payload?.results;
        state.isAmtCollected = true;
      })
      .addCase(reconcileCollectedAmtForStdOrder.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(openCollectionConfirmation.fulfilled, (state, action) => {
        state.isAmtCollected = false;
      })
      .addCase(createCodReceiptDraft.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(createCodReceiptDraft.fulfilled, (state, action) => {
        const {payload} = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.stdDraftReceiptData = payload;
      })
      .addCase(createCodReceiptDraft.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(uploadCodReceipt.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(uploadCodReceipt.fulfilled, (state, action) => {
        const {payload} = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.isStdFileUploaded = true;
        state.stdReceiptsSubmittedFlag = false;
        state.stdReceiptDataAfterUpload = payload;
      })
      .addCase(uploadCodReceipt.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.isErrorOnStdUpload = true;
        state.stdUploadErrorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.stdUploadError = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(submitCodReceipts.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(submitCodReceipts.fulfilled, (state, action) => {
        const {payload} = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.stdReceiptsSubmittedFlag = true;
        state.isStdFileUploaded = false;
        state.stdReceiptDataAfterSubmit = payload;
      })
      .addCase(submitCodReceipts.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(deletePrevUploadedCodReceipt.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(deletePrevUploadedCodReceipt.fulfilled, (state, action) => {
        const {payload} = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.stdDraftReceiptData = payload;
      })
      .addCase(deletePrevUploadedCodReceipt.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(uploadCodClick.fulfilled, (state,action)=>{
        state.isStdFileUploaded = false;
        state.stdReceiptsSubmittedFlag = false;
        state.isErrorOnStdUpload = false;
    });
  },
});

export default stdCodPortalSlice.reducer;
