import React from 'react';
import { Grid, Typography } from "@material-ui/core";

import SelectBox, { SelectItemProps } from '../../../common/SelectBox';



interface AssignDriverDialogInterface {
  classes: any;
  selectedDate: any;
  handleStatusChange:  (params: any) => void
  handleSlotChange: (params: any) => void
  handleZoneChange: (params: any) => void
  slotValue: any
  zoneValue: any
  statusValue: any
  consignmentStatusList: any 
  slotsList:any
  zoneList:any
}

const Filters = (props: AssignDriverDialogInterface) => {
    const {classes, handleStatusChange, handleSlotChange, handleZoneChange, slotValue, zoneValue, statusValue, consignmentStatusList, slotsList, zoneList} = props
    
  return (
   <>
      <Grid className="filterItem status" item>
        <SelectBox
          multiple
          handleChange={handleStatusChange}
          label={'Status'}
          menuPropsList={classes.selectMenuPropsList}
          menuPaperProps={classes.selectMenuPaperProps}
          value={statusValue}
          id="create-route-status-select-box"
          items={consignmentStatusList || []}
        ></SelectBox>
      </Grid>
      <Grid className="filterItem slot" item>
        <SelectBox
          multiple
          label={'Slot'}
          handleChange={handleSlotChange}
          value={slotValue}
          menuPropsList={classes.selectMenuPropsList}
          menuPaperProps={classes.selectMenuPaperProps}
          id="create-route-slot-select-box"
          items={slotsList || []}
        ></SelectBox>
      </Grid>
      <Grid className="filterItem zoneFilter" item>
        <SelectBox
          multiple
          handleChange={handleZoneChange}
          label={'Zone'}
          value={zoneValue}
          menuPropsList={classes.selectMenuPropsList}
          menuPaperProps={classes.selectMenuPaperProps}
          id="create-route-slot-select-box"
          items={zoneList || []}
        ></SelectBox>
      </Grid>
    </>
  );
};

export default React.memo(Filters);
