import React from "react";

//Material UI Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { headStyles } from "./HubsTableHead.styles";
import infoIcon from "../../assets/icons/info-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";

//Components
import HubsRow from "./HubsRow";

interface HubsGridProps {
  count: any;
  page: any;
  rowsPerPage: any;
  classes: any;
  hubList: any;
}

interface EnhancedTableHeadProps {
  classes: any;
  rowCount: any;
}

const headCells = [
  {
    id: "HUBS",
    numeric: false,
    disablePadding: true,
    label: "Hubs",
    sortable: true,
    minWidth: "320px",
    maxWidth: "320px",
    showIcon: false,

  },
  {
    id: "CONSIGNMENTS",
    numeric: false,
    disablePadding: true,
    label: "Consignments",
    minWidth: "160px",
    maxWidth: "160px",
    showIcon: false,
  },
  {
    id: "ONTIME_HANDOVER",
    numeric: false,
    disablePadding: true,
    label: "Ontime Handover",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    showIcon: true,
    iconText: "On time handed over v/s Handed over consignments",
  },
  {
    id: "ONTIME_DELIVERY",
    numeric: false,
    disablePadding: true,
    label: "Ontime Delivery",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    showIcon: true,
    iconText: "On time delivery v/s Delivered consignments",
  },

  {
    id: "ORDERS_RISK",
    numeric: false,
    disablePadding: true,
    label: "Orders At Risk",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    showIcon: true,
    iconText: "Consignments not delivered 15 minutes before slot end time",
  },
  {
    id: "ARROW",
    numeric: false,
    disablePadding: true,
    label: "",
    sortable: true,
    minWidth: "160px",
    maxWidth: "160px",
    showIcon: false,
  },
];

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow className={classes.tableHeadStyle}>
        {headCells.map((headCell) => (
          <>
            < TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              className={classes.tableDataRowCell}
              style={{ minWidth: headCell.minWidth, maxWidth: headCell.maxWidth }}
            >
              {headCell.label}

              {headCell.showIcon ? <Tooltip
                arrow
                title={headCell && headCell.iconText ? headCell.iconText : ""}
                classes={{
                  popper: classes.popperStyle,
                  arrow: classes.arrowStyle,
                  tooltip: classes.tooltipStyle,
                }}
              ><img src={infoIcon} className={classes.infoIconStyle} alt="info-icon" /></Tooltip> : ""}
            </TableCell>
          </>
        ))}
      </TableRow>
    </TableHead >
  );
}

const HubsGrid = (props: HubsGridProps) => {
  const headClasses = headStyles();
  const emptyRows = 0;
  const { classes, hubList } = props;
  return (
    <>
      <TableContainer className={classes.container}>
        <Table padding="none" size="small" stickyHeader={true} style={{ minWidth: "calc(100vw - 95px)" }}>
          <EnhancedTableHead
            classes={headClasses}
            rowCount={hubList.length}
          />
          <TableBody>
            {hubList && hubList.length && hubList.map((data: any, index: any) => {
              return (
                <>
                  <HubsRow
                    classes={classes}
                    hubData={data.hubKPI}
                    hubName={data.hubName}
                    hubCode={data.hubCode}
                    hubDataIndex={index}
                  />
                </>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 56 * emptyRows }}>
                <TableCell colSpan={10} className={classes.tableDataRowCell} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default HubsGrid;

