import { Checkbox, TableCell, TableRow, TableRowProps, IconButton, Collapse } from '@material-ui/core';
import React, { useCallback } from 'react';
import { ColDef, RowData, RowId } from '.';
import DataTableCellElement from './DataTableCellElement';
import { useStyles } from './DataTableRowElement.styles';

export interface DataTableRowElementProps extends TableRowProps {
  rowData: RowData;
  columns: ColDef[];
  checkboxSelection: boolean;
  rowHeight: number;
  index: number;
  disableSelectionOnClick?: boolean;
  isItemSelected?: boolean;
  rowClickCallback?: (rowData: RowData) => void;
  rowSelectionCallback?: (rowId: RowId) => void;
  cellClickCallback?: (column: ColDef, value: any, rowData: RowData) => void;
  isSelected?: (rowId: RowId) => boolean;
  subElement?: boolean;
  setOpen?:(param:boolean)=>void;
  open?: boolean
}

const DataTableRowElement = (props: DataTableRowElementProps) => {
  const classes = useStyles();
  const {
    rowData,
    columns,
    rowHeight,
    checkboxSelection,
    index,
    disableSelectionOnClick,
    isItemSelected,
    isSelected,
    rowClickCallback,
    rowSelectionCallback,
    cellClickCallback,
    subElement,
    setOpen,
    open,
    ...rest
  } = props;

  const handleRowSelection = useCallback(
    () => {
      rowSelectionCallback && rowSelectionCallback(rowData.id);
    },
    [rowData, rowSelectionCallback],
  )

  const handleRowClick = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (event && event.target && event.target instanceof HTMLInputElement) {
        return;
      }
      if (checkboxSelection) {
        if (disableSelectionOnClick) {
          rowClickCallback && rowClickCallback(rowData);
          return;
        }
        handleRowSelection();
      } else {
        rowClickCallback && rowClickCallback(rowData);
      }
    },
    [checkboxSelection, disableSelectionOnClick, handleRowSelection, rowClickCallback, rowData],
  );

  return (
    <>
      <TableRow
        {...rest}
        className={index % 2 === 0 ? classes.root : classes.evenRoot}
        style={{
          height: rowHeight,
          minHeight: rowHeight,
          maxHeight: rowHeight,
        }}
        selected={isItemSelected}
        onClick={handleRowClick}
      >
       
        {checkboxSelection && (
          <TableCell>
            <Checkbox
              checked={isItemSelected}
              size="small"
              color="primary"
              onChange={handleRowSelection}
              disabled ={subElement && true}
            />
          </TableCell>
        )}
    
        {columns.map((col: ColDef, index: number) => (
          <DataTableCellElement
            key={`${col.field}${index}`}
            column={col}
            rowHeight={rowHeight}
            value={rowData[col.field]}
            rowData={rowData}
            cellClickCallback={cellClickCallback}
            setOpen={setOpen}
            open={open}
            subElement={subElement && true} 
          />
        ))}
      </TableRow>
    </>
  );
}

export default DataTableRowElement;