
/* global google */
import React, { useEffect, useState } from "react";
import { DirectionsRenderer } from "react-google-maps";
import { useDispatch } from "react-redux";

import { updateMapDirections } from "config/redux/common/commonSlice";
import { CustomMarker } from ".";
import { isUndefined } from "../../utils/helpers.utils";

interface MapDirectionsRendererProps {
  places: CustomMarker[];
  travelMode: google.maps.TravelMode;
  roundTrip?: boolean;
  optimizeRoute?: boolean;
  updateMap?: boolean;
}
const MapDirectionsRenderer = (props: MapDirectionsRendererProps) => {
  const { places, travelMode, roundTrip, optimizeRoute, updateMap } = props;

  const dispatch = useDispatch();

  const [directions, setDirections] = useState<any>(null);

  useEffect(() => {
    if (updateMap) {
      let waypoints: any = places.filter((place: CustomMarker) => isUndefined(place?.stopover)).map((place: CustomMarker) => ({ location: place.location, stopover: true }));
      const origin = waypoints.shift().location;
      let destination = { ...origin };
      if (!roundTrip) {
        destination = waypoints.pop().location;
      }
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: travelMode,
          waypoints: waypoints,
          optimizeWaypoints: optimizeRoute
        },
        (result: any, status: any) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMap, optimizeRoute]);

  useEffect(() => {
    dispatch(
      updateMapDirections(directions && directions.routes ? directions.routes[0] : {})
    );
  }, [directions, dispatch])

  return (
    directions && (
      <DirectionsRenderer options={{ suppressMarkers: true }} directions={directions} />
    )
  );
}

export default MapDirectionsRenderer

