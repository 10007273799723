import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    container: {
      flexDirection: "column",
      '& .planningItem': {
        width: '100%',
        '& .MuiTab-root': {
          marginRight: 24,
          color: "#979797"
        },
        "& .MuiTab-root.Mui-selected": {
          color: '#1F1F1F'
        },
        '&.heading': {
          '& p': {
            fontSize: 20,
            lineHeight: "30px",
            color: COLORS.PRIMARY_MAIN,
            fontFamily: "Montserrat",
            fontWeight: 600,
          }
        },
        '&.content': {
          backgroundColor: COLORS.WHITE,
          borderRadius: theme.spacing(1),
          height: "calc(100vh - 172px)",
          '& .tabPanel': {
            height: "calc(100% - 56px)",
            padding: "0px 12px 20px 12px",
            '&.routes': {
              position: "relative"
            }
          }
        }
      }
    },
    tabPanelContainer: {
      flexDirection: "column",
      height: "100%",
      '& .panelItem': {
        width: "100%",
        '&.chipFilter': {
          padding: "12px 0px 10px 0px",
          '& .chipsContainer': {
            justifyContent: 'space-between',
            '& .chipFilterItem': {
              height: 36,
              minWidth: "11%",
              borderRadius: theme.spacing(1),
              border: "1px solid rgba(10, 67, 124, 0.2)",
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
              '&:last-child': {
                marginRight: 0
              },
              '&.firstItem': {
                border: `2px solid ${theme.palette.primary.main}`
              },
              '&.active': {
                background: "#0A437C",
                border: "none",
                boxShadow: "none",
                '& .chipItem p': {
                  color: COLORS.WHITE
                }
              },
              '& .chipContainer': {
                alignItems: "center",
                height: "100%",
                '& .chipItem': {
                  padding: theme.spacing(0, 1.5),
                  '& p': {
                    fontWeight: 500,
                    color: COLORS.MINE_SHAFT,
                  },
                  '&.name': {
                    '& p': {
                      fontSize: 14
                    }
                  },
                  '&.count': {
                    marginLeft: "auto",
                    '& p': {
                      fontSize: 24
                    }
                  }
                }
              }
            }
          }
        },
        '&.gridWrapper': {
          height: "calc(100% - 98px)",
          '& .idLink': {
            cursor: "pointer",
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: 12
          }
        }
      }
    },
    publishDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .contentItem': {
        '& p': {
          textAlign: "center",
          lineHeight: 1.2
        },
        '&.heading': {
          padding: theme.spacing(0, 15.75, 5),
          '& p': {
            fontSize: 24,
            fontWeight: 600
          }
        },
        '&.desc': {
          padding: theme.spacing(0, 2.5),
          '& p': {
            fontSize: 18,
            fontWeight: 400,
            '& .count': {
              fontWeight: 600
            }
          }
        }
      }
    },
    deleteDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .contentItem': {
        '& p': {
          textAlign: "center",
          lineHeight: 1.2
        },
        '&.heading': {
          padding: theme.spacing(0, 15.75, 5),
          '& p': {
            fontSize: 24,
            fontWeight: 600
          }
        },
        '&.desc': {
          padding: theme.spacing(0, 2.5),
          '& p': {
            fontSize: 18,
            fontWeight: 400,
            '& .count': {
              fontWeight: 600
            }
          }
        }
      }
    },
    hubSelectionDialogTitle: {
      fontSize: 20,
      fontWeight: 600
    },
    hubSelectionDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .contentItem': {
        '&.heading': {
          '& p': {
            fontSize: 14,
            fontWeight: 400
          }
        },
        '&.hub': {
          paddingTop: theme.spacing(3.75)
        }
      }
    },
    publishDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(8.25),
      '& .dialogBtn': {
        '&.secondary': {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1)
        },
        '&.primary': {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1)
        }
      }
    },
    deleteDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(8.25),
      '& .dialogBtn': {
        '&.secondary': {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1)
        },
        '&.primary': {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1)
        }
      }
    },
    hubSelectionDialogButtonsContainer: {
      justifyContent: "flex-end",
      paddingBottom: theme.spacing(7),
      '& .dialogBtn': {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(4.5),
        '&.secondary': {
          marginRight: theme.spacing(1)
        },
        '&.primary': {
          marginLeft: theme.spacing(1)
        }
      }
    },
    publishDialogPaperPropsRoot: {
      width: 600,
      height: 350,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    deleteDialogPaperPropsRoot: {
      width: 600,
      height: 350,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    hubSelectionDialogPaperPropsRoot: {
      width: 500,
      height: 300,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    publishErrorPopupContainer: {
      position: "absolute",
      bottom: 0,
      height: 100,
      backgroundColor: "#FEECEC",
      flexDirection: "column",
      flexWrap: "nowrap",
      padding: theme.spacing(2),
      '& .titleBlock': {
        '& .titleContainer': {
          alignItems: "center",
          justifyContent: "space-between",
          '& .titleItem': {
            '& .title': {
              fontSize: 14,
              fontWeight: 600
            },
            '& .closeBtn': {
              padding: theme.spacing(0)
            }
          }
        },
      },
      '& .contentBlock': {
        flexGrow: 1,
        overflow: "auto",
        '& .contentContainer': {
          flexDirection: "column",
          '& .contentItem': {
            paddingBottom: theme.spacing(1.25),
            '&:last-child': {
              paddingBottom: theme.spacing(0)
            },
            '& .content': {
              alignItems: "center",
              '& .dotIcon': {
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: "#F34041",
                marginRight: theme.spacing(2)
              },
              '& .text': {
                fontSize: 14,
                fontWeight: 500
              }
            }
          }
        }
      }
    },
    actionsHeader: {
      alignItems: "center",
      '& .actionItem': {
        cursor: "pointer",
        '& .icon': {
          padding: theme.spacing(0.25),
          '&.editIcon': {
            marginRight: theme.spacing(0.75)
          }
        }
      }
    },
    deliveryAreaHeader: {
      alignItems: "center",
      '& .columnValue': {
        fontSize: 12
      }
    },
    statusHeaderContainer: {
      '& .tag': {
        color: COLORS.WHITE,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        lineHeight: "14px",
        backgroundColor: COLORS.BLACK,
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: 12,
        '&.PICTON_BLUE': {
          backgroundColor: COLORS.PICTON_BLUE
        },
        '&.CREAM_CAN': {
          backgroundColor: COLORS.CREAM_CAN
        },
        '&.VISTA_BLUE': {
          backgroundColor: COLORS.VISTA_BLUE
        },
        '&.SEA_GREEN': {
          backgroundColor: COLORS.SEA_GREEN
        },
        '&.GOLDEN_BELL': {
          backgroundColor: COLORS.GOLDEN_BELL
        },
        '&.SCAMPI': {
          backgroundColor: COLORS.SCAMPI
        },
        '&.BLUE': {
          backgroundColor: COLORS.PRIMARY_MAIN
        },
        '&.SEA_GREEN2': {
          backgroundColor: COLORS.SEA_GREEN2
        },
        '&.LUCKY': {
          backgroundColor: COLORS.LUCKY
        },
        '&.DUSTY_GRAY': {
          backgroundColor: COLORS.DUSTY_GRAY
        },
        '&.JUNGLE_GREEN': {
          backgroundColor: COLORS.JUNGLE_GREEN
        },
        '&.BLACK': {
          backgroundColor: COLORS.BLACK
        },
        '&.TROPICAL_BLUE': {
          backgroundColor: COLORS.TROPICAL_BLUE,
          color: COLORS.ASTRAL
        },
        '&.CATSKILL_WHITE': {
          backgroundColor: COLORS.CATSKILL_WHITE,
          color: COLORS.ASTRAL
        },
        '&.BLUE_HAZE': {
          backgroundColor: COLORS.BLUE_HAZE,
          color: COLORS.SCAMPI
        },
        '&.BARLEY_WHITE': {
          backgroundColor: COLORS.BARLEY_WHITE,
          color: COLORS.OLD_GOLD
        },
        '&.DOUBLE_PEARL_LUSTA': {
          backgroundColor: COLORS.DOUBLE_PEARL_LUSTA,
          color: COLORS.PIMPKIN_SKIN
        },
        '&.JAGGED_ICE': {
          backgroundColor: COLORS.JAGGED_ICE,
          color: COLORS.BLUE_STONE
        },
        '&.TARA': {
          backgroundColor: COLORS.TARA,
          color: COLORS.OCEAN_GREEN
        },
        '&.JAGGED_ICE_PREPARED': {
          backgroundColor: COLORS.JAGGED_ICE,
          color: COLORS.JUNGLE_GREEN
        },
        '&.ALTO': {
          backgroundColor: COLORS.ALTO,
          color: COLORS.DUSTY_GRAY_LIGHT
        },
        '&.MINSK': {
          backgroundColor: COLORS.SCAMPI,
          color: COLORS.WHITE
        }
      }
    },
    excelDownloadStatus: {
      background: COLORS.INFO_BLACK,
      borderRadius: "8px 8px 0px 0px",
      position: "fixed",
      bottom: "12px",
      height: 48,
      width: 336,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    excelDownloadStatusText: {
      color: COLORS.WHITE,
      fontSize: 14,
      lineHeight: "17px",
      fontWeight: 500,
      fontFamily: "Montserrat"
    },
    checkedIconStyle: {
      width: 33,
      height: 33,
      padding: "6px 0px 0px 16px"
    },
    close: {
      color: COLORS.WHITE
    },
    closeIcon: {
      width: 16,
      height: 16
    },
    excelDownloadError: {
      background: COLORS.LIGHT_RED,
      borderRadius: "8px 8px 0px 0px",
      position: "fixed",
      bottom: "23px",
      height: 48,
      width: 336,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    button: {
      margin: theme.spacing(0.5),
      '&:last-child': {
        marginRight: theme.spacing(0)
      }
    },
    colSettings: {
      float: "right",
      marginTop: -3
    },
    buttonContainer: {
      marginLeft: "auto",
      '& .clearAllFilterBtn': {
        fontSize: 14,
        fontWeight: 600,
        marginRight: theme.spacing(2),
        fontFamily: 'Montserrat',
        backgroundColor: COLORS.ALIZARIN_CRIMSON,
        '&:hover': {
          backgroundColor: COLORS.ALIZARIN_CRIMSON
        }
      }
    },
  }),
);
