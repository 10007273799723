import React, { useState, useEffect } from "react";
import MultiSelect from './Multiselect/index'

export interface SelectItemProps {
  name?: string;
  value: any;
}

interface AutoCompleteProps {
  handleChange: (value: any) => void;
  value: any;
  id: string;
  menuItems:  Array<SelectItemProps>;
  disabled: boolean;
  label: string
  fieldLabel?: string
  multiple?: boolean
}
const SearchableDropdown = (props: AutoCompleteProps) => {
   const { fieldLabel = "", id, label, menuItems, value, handleChange, disabled, multiple=true } = props
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
     useEffect(() => {
      setSelectedOptions(menuItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);
  const getOptionLabel = (option: any) => `${option.name}`;
  const handleToggleOption = (selectedOptions: any) => {
       setSelectedOptions(selectedOptions);
       let result;
       if(multiple){
         result = selectedOptions.map((a:any) => a.value);
       }
       else{
           result = selectedOptions.value
       }
       handleChange(result)
  }
 
  const handleClearOptions = () => { setSelectedOptions([])};
  const handleSelectAll = (isSelected: any) => {
    if (isSelected) {
      setSelectedOptions(menuItems);
      let result = menuItems.map((a:any) => a.value);
      const allResult= ['all',...result]
       handleChange(allResult)
    } else {
      handleClearOptions();
    }
  };
  return (
    <MultiSelect
      multiple
      items={menuItems}
      getOptionLabel={getOptionLabel}
      selectedValues={selectedOptions}
      selectedVal={value}
      label={label}
      id={id}
      onToggleOption={handleToggleOption}
      onClearOptions={handleClearOptions}
      onSelectAll={handleSelectAll}
    />
  );
};

export default SearchableDropdown;
