import React, { useCallback, useState, useEffect} from "react";

//common components
import AppConstants from "../../constants";

//Material UI Components
import { Grid, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";

//component
import HistoryExpress from "./HistoryExpress";
import HistoryStandard from "./HistoryStandard";

interface ExpressProps {
  orderType?: string
}

const CustomerCommunication = (props: ExpressProps) => {
const {orderType } = props
  const [tabValue, setTabValue] = useState(
    orderType ===
      AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_HISTORY
        .EXPRESS
      ? AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
          .TAB_VALUES_HISTORY.EXPRESS
      : AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
          .TAB_VALUES_HISTORY.STANDARD
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      setTabValue(value);
    },
    []
  );

  useEffect(() => {
    if (orderType) {
      setTabValue(orderType)
    }
  }, [orderType]);

  return (
    <>
      <TabContext value={tabValue}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#1F1F1F" } }}
          value={tabValue}
          aria-label="Consignments/Routes Navigation Tabs"
          style={{
            paddingLeft: "20px",
            paddingTop: "8px",
            lineHeight: "24px",
            minHeight: 0,
            backgroundColor: "rgba(244, 244, 244, 0.6)",
          }}
          onChange={handleTabChange}
        >
          <Tab
            label="Standard"
            style={{ fontSize: "16px", fontWeight: 600 }}
            value={
              AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_HISTORY
                .STANDARD
            }
          />
          <Tab
            label="Express"
            style={{ fontSize: "16px", fontWeight: 600 }}
            value={
              AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_HISTORY
                .EXPRESS
            }
          />
        </Tabs>
        <TabPanel
          className="tabPanel"
          value={
            AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_HISTORY
              .STANDARD
          }
          style={{
            paddingTop: "0px",
          }}
        >
          <HistoryStandard/>
        </TabPanel>
        <TabPanel
          className="tabPanel"
          value={
            AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_HISTORY
              .EXPRESS
          }
          style={{
            paddingTop: "0px",
          }}
        >
          <Grid item >
            <HistoryExpress />
          </Grid>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default CustomerCommunication;
