import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, ListItemText } from '@material-ui/core';
import { useStyles } from './AutoComplete.styles';
import AppConstants from '../../constants';
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import TextBox from '../TextBox';
import { SelectItemProps } from 'common/SelectBox';

export interface AutoCompleteItemProps {
  name?: string;
  value: any;
}

interface AutoCompleteProps {
  handleChange?: (value: any) => void;
  handleClose?: (value: any) => void;
  value: any;
  multiple: boolean;
  id: string;
  items: Array<AutoCompleteItemProps>;
  reset?: boolean;
  resetAll?: boolean;
}
const AutoComplete = (props: AutoCompleteProps) => {

  const classes = useStyles();

  const { handleChange, handleClose, value, multiple, id, items, reset, resetAll } = props;
  const [itemsDdn, setItemsDdn] = useState(items.slice());
  const [ddnValue, setDdnValue] = useState<any>(value || AppConstants.SELECT_NONE);

  const handleDdnChange = useCallback(
    (event: any, newValue: any) => {
      let selected = newValue;
      const selectedValues = selected.map((item: AutoCompleteItemProps) => item.value);
      const ddnValues = ddnValue.map((item: AutoCompleteItemProps) => item.value);
      if (multiple) {
        const selectAllIdx = selectedValues.indexOf(AppConstants.SELECT_ALL.value);
        const selectAllPreviousIdx = ddnValues.indexOf(AppConstants.SELECT_ALL.value);
        if (selectAllIdx > -1) {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = selected.filter((item: AutoCompleteItemProps) => item.value !== AppConstants.SELECT_ALL.value);
            } else {
              selected = itemsDdn.slice();
            }
          }
        } else {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = [];
            } else if (selected.length === itemsDdn.length - 1) {
              selected = itemsDdn.slice();
            }
          }
        }
        if (!selected.length) {
          selected = [];
        }
      }
      setDdnValue(selected);
      handleChange && handleChange(selected);
    },
    [ddnValue, itemsDdn, multiple, handleChange],
  );

  const onClose = useCallback(
    (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
      if (reason === 'toggleInput') {
        return;
      }
      handleClose && handleClose(ddnValue);
    },
    [handleClose, ddnValue],
  );

  const handleSelectedValues = useCallback(
    (option: SelectItemProps, selected: SelectItemProps) => {
      let returnValue = false;
      if (ddnValue.findIndex((item: SelectItemProps) => item.value === selected.value) === -1) {
        setDdnValue([...ddnValue, selected]);
      }
      if (selected.value === AppConstants.SELECT_ALL.value) {
        if (ddnValue.findIndex((item: SelectItemProps) => item.value === AppConstants.SELECT_ALL.value) > -1 && ddnValue.findIndex((item: SelectItemProps) => item.value === option.value) === -1) {
          setDdnValue([...ddnValue, option]);
        }
      }
      if (option.value === AppConstants.SELECT_ALL.value) {
        returnValue = option.value === selected.value;
        if (selected.value !== AppConstants.SELECT_ALL.value) {
          if (ddnValue.length === items.length) {
            if (ddnValue.findIndex((item: SelectItemProps) => item.value === AppConstants.SELECT_ALL.value) === -1) {
              setDdnValue([...ddnValue, AppConstants.SELECT_ALL]);
            }
            returnValue = true;
          } else {
            returnValue = false;
          }
        }
      } else {
        returnValue = option.value === selected.value;
      }
      return returnValue;
    },
    [ddnValue, items],
  );

  useEffect(() => {
    if (multiple) {
      setItemsDdn([AppConstants.SELECT_ALL, ...items]);
      setDdnValue(value || []);
    } else {
      setDdnValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (reset) {
      if (multiple) {
        setDdnValue([]);
      } else {
        setDdnValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (resetAll) {
      if (multiple) {
        setDdnValue([]);
      } else {
        setDdnValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAll])

  return (
    <>
      <Autocomplete
        fullWidth
        open
        id={id}
        onClose={onClose}
        multiple={multiple}
        className={classes.root}
        classes={{
          popper: classes.popper
        }}
        disableCloseOnSelect
        disablePortal
        value={ddnValue}
        onChange={handleDdnChange}
        renderTags={() => null}
        noOptionsText={AppConstants.NO_OPTIONS_TEXT}
        getOptionSelected={handleSelectedValues}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {
              multiple && <Checkbox color="primary" checked={selected} />
            }
            <ListItemText className="optionName" primary={option.name} />
          </React.Fragment>
        )}
        options={itemsDdn}
        getOptionLabel={(option: SelectItemProps) => option.name || ''}
        renderInput={(params: any) => (
          <TextBox
            inputRef={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            onChange={params.inputProps.onChange}
            variant="outlined"
            textBoxId="autoCompleteSearchTextBox"
            placeholderText="Search"
          />
        )}
      />
    </>
  );
}

export default AutoComplete;