import moment from "moment";
import AppConstants from "../../constants";
import { enumerateDaysBetweenDates, getUniqueValues, getValueFromArray, localTimeString } from "../../utils/helpers.utils";

export interface MetaData {
  paymentType: string;
  totalAmount: string;
  transactionDate: Date;
  amountToCollect: string;
  collectedAmount: string;
  currency: string;
  category: string;
  deliveryType: string;
  modeOfCollection: string;
  invoiceNumber: string;
  internalExternal: string;
  origin: string;
  status: string;
  deliveryStatus: string;
  numberOfItems: number;
  lastStatusUpdatedTime: string;
}

export interface Name {
  ar: string;
  en: string;
}

export interface Product {
  sku: string;
  entryNumber: string;
  hasRejectedProducts: boolean;
  name: Name;
  quantity: string;
  acceptedQty: string
  unitPrice: string;
  imgUrl: string;
  vatPercentage: string;
  uom: string;
  orderedBarcode: string;
  associatedBarcodes: string[];
  substitution: boolean;
}

export interface Address {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  makani: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
}

export interface ContactInfo {
  name: string;
  phone: string;
  altphone: string;
  email: string;
  address: Address;
}

export interface PosInformation {
  store: string;
  storeName: string;
  contactInfo: ContactInfo;
}

export interface DeliverySlot {
  startTime: Date;
  endTime: Date;
  deliverySlotTime: string;
}

export interface CustomerContactInfo {
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface DeliveryInformation {
  deliveryInstructions: string;
  deliveryCost: string;
  carrier: string;
  carrierTrackingId: string;
  shipmentSize: string;
  totalCratesCount: number;
  deliverySlot: DeliverySlot;
  customerContactInfo: CustomerContactInfo;
  transitTime: string;
  actualTransitTime: string;
  dynamicTransitTime: string;
  estimatedTransitTime: string;
}

export interface PickUpContactInfo {
  companyName: string;
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface PickupSlot {
  startTime: Date;
  endTime: Date;
  deliverySlotTime: string;
}

export interface PickUpInformation {

  pickUpContactInfo: PickUpContactInfo;
  pickupSlot: PickupSlot;
}

export interface Reason {
  deliveryStatus: string;
  reasonCode: string;
  reasonDescription: string;
}

export interface CNCOrder {
  id: string;
  orderCode: string,
  consignmentCode: string,
  hasRejectedProducts: boolean,
  retryCount: number,
  metadata: MetaData;
  products: Product[];
  reason: Reason;
  posInformation: PosInformation;
  deliveryInformation: DeliveryInformation;
  pickUpInformation: PickUpInformation;
  statuses: Statuses[];
}

export interface CountryDetails {
  countryCode: string;
  countryName: string;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface CNCOrdersDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: CNCOrder[];
}
export interface Statuses {
  statusCode: string;
  statusName: string;
  statusDate: string;
  reason: Reason;
}

export const lastMilePlanningOrdersDataTransform = (response: CNCOrdersDataResponse): any[] => {
  const transformedArray: any[] = [];
  response.elements.forEach((order: CNCOrder) => {
    const {
      id,
      metadata,
      posInformation: { store, storeName },
      deliveryInformation,
      pickUpInformation,
      orderCode,
      products,
      reason,
      consignmentCode,
      statuses,
    } = order;
    // TODO: map/add actual objects too for drilling down content or store in state
    const collectionDate = moment(pickUpInformation && pickUpInformation.pickupSlot && pickUpInformation.pickupSlot.startTime, AppConstants.DATE_FORMAT_BACKEND);
    const customerContactInfo = deliveryInformation.customerContactInfo || {
      address: {}
    };
    const transactionDate = moment(metadata.transactionDate, AppConstants.DATE_FORMAT_BACKEND);
    const statusDate = metadata.lastStatusUpdatedTime ? moment(metadata.lastStatusUpdatedTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.TIMESTAMP) : statuses && statuses.length ? moment(statuses[0].statusDate, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.TIMESTAMP) : "";
    const startTime = new Date(pickUpInformation.pickupSlot.startTime);
    const endTime = new Date(pickUpInformation.pickupSlot.endTime);
    transformedArray.push({
      id: id,
      collectionHubCode: pickUpInformation && pickUpInformation.pickUpContactInfo && pickUpInformation.pickUpContactInfo.companyName,
      collectionHubName: pickUpInformation && pickUpInformation.pickUpContactInfo && pickUpInformation.pickUpContactInfo.name,
      deliveryArea: customerContactInfo.address ? customerContactInfo.address.area : "",
      consignmentId: consignmentCode,
      deliveryStatus: metadata.deliveryStatus,
      orderId: orderCode,
      transactionDate: transactionDate.format(AppConstants.DATE_FORMAT),
      type: metadata.category,
      collectionDate: collectionDate.format(AppConstants.DATE_FORMAT),
      deliverySlot: `${localTimeString(startTime)} - ${localTimeString(endTime)}`,
      customerName: customerContactInfo.name,
      customerEmail: customerContactInfo.email,
      customerMobileNumber: customerContactInfo.phone,
      hubCode: store,
      hubName: storeName,
      statusDate: statusDate,
      numberOfItems: metadata.numberOfItems && metadata.numberOfItems > 0 ? metadata.numberOfItems : products && products.length ? products.length : 0,
      paymentType: metadata.paymentType,
      cancellationReason: reason ? reason.reasonDescription : statuses && statuses.length && statuses[0].reason ? statuses[0].reason.reasonDescription : "",
    });
  });
  return [...transformedArray];
};

export const getCNCConsignmentStatusesField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

export const sortedCountryData = (
  columnName: string,
  isDescending: boolean
) => (a: any, b: any) => {
  const aVal = isDescending ? a : b;
  const bVal = isDescending ? b : a;
  if (
    aVal[columnName].match(/^[0-9]+$/) &&
    bVal[columnName].match(/^[0-9]+$/)
  ) {
    return parseFloat(aVal[columnName]) - parseFloat(bVal[columnName]);
  } else {
    if (aVal[columnName] < bVal[columnName]) {
      return -1;
    } else if (aVal[columnName] > bVal[columnName]) {
      return 1;
    } else {
      return 0;
    }
  }
};

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
  let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
  const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
  const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
  if (selectAllIdx > -1) {
    currentValue = [];
  }
  if (selectNoneIdx > -1) {
    currentValue = [];
  }
  return currentValue;
}

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, isRoute?: boolean) => {
  payload.attributes = [];
  if (payload.hub) {
    if (payload.hub.value) {
      let hubValue = payload.hub.value.map((item: any) => item.value);
      let hubNameValue = [];
      if (payload.hubName && payload.hubName.value.length) {
        hubNameValue = payload.hubName.value.map((item: any) => item.value);
      }
      hubValue = hubValue.concat(hubNameValue);
      const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        hubValue = [];
      }
      if (selectNoneIdx > -1) {
        hubValue = [];
      }
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: getUniqueValues(hubValue)
      })
      delete payload.hub;
      delete payload.hubName;
    } else {
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: []
      });
      delete payload.hub;
      delete payload.hubName;
    }
  }
  if (payload.orderType) {
    let attrValue = [];
    if (payload.orderType.value) {
      attrValue = getFilterAttributeValue(payload.orderType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.orderType.fieldName,
      value: attrValue
    })
    delete payload.orderType;
  }
  if (payload.deliveryStatus) {
    let attrValue = [];
    if (payload.deliveryStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryStatus.fieldName,
      value: attrValue
    })
    delete payload.deliveryStatus;
  }
  if (payload.cancellationReason) {
    let attrValue = [];
    if (payload.cancellationReason.value) {
      attrValue = getFilterAttributeValue(payload.cancellationReason.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.cancellationReason.fieldName,
      value: attrValue
    })
    delete payload.cancellationReason;
  }

  if (payload.collectionHubCode) {
    let attrValue = [];
    if (payload.collectionHubCode.value) {
      attrValue = getFilterAttributeValue(payload.collectionHubCode.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.collectionHubCode.fieldName,
      value: attrValue
    })
    delete payload.collectionHubCode;
  }

  if (payload.collectionHubName) {
    let attrValue = [];
    if (payload.collectionHubName.value) {
      attrValue = getFilterAttributeValue(payload.collectionHubName.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.collectionHubName.fieldName,
      value: attrValue
    })
    delete payload.collectionHubName;
  }
  if (payload.modeOfCollection) {
    payload.attributes.push({
      key: payload.modeOfCollection.fieldName,
      value: payload.modeOfCollection.value || []
    })
    delete payload.modeOfCollection;
  }
  if (payload.deliveryType) {
    payload.attributes.push({
      key: payload.deliveryType.fieldName,
      value: payload.deliveryType.value || []
    })
    delete payload.deliveryType;
  }
  if (payload.displayRouteStatus) {
    payload.attributes.push({
      key: payload.displayRouteStatus.fieldName,
      value: payload.displayRouteStatus.value || []
    })
    delete payload.displayRouteStatus;
  }
  if (payload.deliverySlot && payload.deliverySlot.value) {
    let delSlotValue = payload.deliverySlot.value.map((item: any) => item.value);
    let dateRange = [];
    const selectAllIdx = delSlotValue.indexOf(AppConstants.SELECT_ALL.value);
    if (payload.dateRange && payload.dateRange.value) {
      const allDates = enumerateDaysBetweenDates(payload.dateRange.value.startDate, payload.dateRange.value.endDate);
      if (selectAllIdx > -1 || !delSlotValue.length) {
        payload.isDateRange = true;
        dateRange.push({
          startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
        });
      } else {
        payload.isDateRange = false;
        delSlotValue.forEach((slot: any) => {
          const splitSlot = slot.split('-');
          const startSlot = splitSlot[0].split(':');
          const endSlot = splitSlot[1].split(':');
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              })
            })
          }
        })
      }
      if (payload.reset) {
        delete payload.isDateRange;
      }
    }
    payload.isCNC = true;
    payload.dateRange = dateRange;
    delete payload.displayRouteStatus;
    delete payload.deliverySlot;
    delete payload.reset;
  }
  if (isLoadMore && data) {
    payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
  }
  if (data.continuationToken && payload.pageNumber) {
    payload.requestContinuationToken = data.continuationToken
  }
  return payload;
};

export const createPayloadForExcelExport = (payload: any, data?: any, isLoadMore?: boolean, isRoute?: boolean) => {
  let newPayload = createFilterPayload(payload, data, isLoadMore, isRoute);
  var orderFilterRequestDto = { orderFilterRequestDto: newPayload };
  return orderFilterRequestDto;
};

export const createCratesInfoPayload = (payload: any) => {
  if (payload) {
    let consignmentCode;
    let storeCode;
    if (payload.consignmentCode) {
      consignmentCode = payload.consignmentCode;
    }
    if (payload.posInformation) {
      storeCode = payload.posInformation.store
    };
    var consignmentCodePosCodeMap = {
      consignmentCodePosCodeMap: {
        [consignmentCode]: storeCode
      }
    }
    return consignmentCodePosCodeMap;
  }
}

export const createCNCReplanningPayload = (params: any) => {
  let payload: any = {
    consignmentId: params.id
  };
  payload.status = params.cncStatus;
  payload.comments = params.commentsValue;
  payload.reason = params.reason ? {
    reasonCode: params.reason.reasonCode,
    reasonDescription: params.reason.reasonDescription,
    deliveryStatus: params.reason.deliveryStatus
  } : {}

  return payload;
}

export const getConsignmentData = async (draftRouteData: any) => {
  let areas: any[] = [];
  let consignments: any[] = [];
  let slots: any = {};
  if (draftRouteData) {
    Object.keys(draftRouteData).forEach((statusKey: string) => {
      const statusValue: any = draftRouteData[statusKey]
      Object.keys(statusValue).forEach((areaKey: string) => {
        const areaIndex: number = areas.findIndex((area: any) => area.code === areaKey);
        let area: any = areaIndex === -1 ? {
          code: areaKey,
          value: areaKey,
          data: []
        } : { ...areas[areaIndex] };
        const areaValue: any = statusValue[areaKey];
        Object.keys(areaValue).forEach((slotKey: string) => {
          const slotValue: any = areaValue[slotKey];
          const slotIndex: number = areaIndex !== -1 ? area.data.findIndex((slot: any) => slot.code === slotKey) : -1;
          let slot: any = slotIndex === -1 ? {
            code: slotKey,
            value: slotValue.slotValue,
            area: areaKey,
            data: []
          } : { ...area.data[slotIndex] };
          const elements: any = slotValue.elements.map((consignment: any) => {
            return {
              code: consignment.id,
              value: consignment.id,
              data: consignment,
              slot: slotKey,
              slotValue: slotValue.slotValue,
              area: areaKey,
              status: statusKey
            };
          });
          if (slotIndex !== -1) {
            slot.data = [...slot.data, ...elements];
            area.data[slotIndex] = slot;
          } else {
            slot.data = elements;
            area.data.push(slot);
          }
          if (slots[area.code]) {
            slots[area.code][slot.code] = slot.data;
          } else {
            slots[area.code] = {
              [slot.code]: slot.data
            }
          }
          consignments = [...consignments, ...elements];
        })
        if (areaIndex !== -1) {
          areas[areaIndex] = area;
        } else {
          areas.push(area);
        }
      })
    })
  }
  return {
    areaWiseConsignments: areas,
    allConsignments: consignments,
    slotwiseConsignments: slots
  };
}


export const calculateTransitTime = (directions: any) => {
  let transitTime = 0;
  if (directions.legs && directions.legs.length) {
    directions.legs.forEach((leg: any) => {
      transitTime += leg.duration ? leg.duration.value : 0;
    });
    transitTime += (AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.DRIVER_SERVICE_TIME * directions.legs.length);
  }
  return transitTime;
}

export const calculateTransitTimeForConsignments = (directions: any, consignments: any) => {
  let consignmentIdsTransitTime: any = {};
  if (directions.legs && directions.legs.length) {
    for (var i = 0; i < consignments.length; i++) {
      if (directions.legs[i]) {
        consignmentIdsTransitTime[consignments[i].code] = directions.legs[i].duration.value
      }
      else {
        consignmentIdsTransitTime[consignments[i].code] = 0
      }
    }
  }
  return consignmentIdsTransitTime;
}

export const getAddressInfo = (addressObj: Address) => {
  return addressObj ? `${addressObj.building ? addressObj.building + ', ' : ""}${addressObj.apartment ? addressObj.apartment + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber + ', ' : ""}${addressObj.makani ? addressObj.makani + ', ' : ""}${addressObj.streetNumber ? addressObj.streetNumber + ', ' : ""}${addressObj.streetName ? addressObj.streetName + ', ' : ""}${addressObj.area ? addressObj.area + ', ' : ""}${addressObj.landmark ? addressObj.landmark + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber : ""}
  ${addressObj.city ? addressObj.city + ', ' : ""}${addressObj.postalCode ? addressObj.postalCode + ', ' : ""}${addressObj.countryIsoCode ? addressObj.countryIsoCode : ""}` : "";
}

export const getCratesFromProducts = (items: any) => {
  let crates: any = [];
  items && items.filter(({ shippedQty }: any) => { return +shippedQty > 0 }).forEach((item: any) => {
    if (item.associatedCrates) {
      let productCrates = item.associatedCrates;
      productCrates.forEach((crate: any) => {
        const crateValue = getValueFromArray(crates, "crateId", crate, "crateId");
        if (crateValue.length) {
          const crateIndex = crates.findIndex((value: any) => value.crateId === crate.crateId);
          const newCrate = { ...crate, quantity: parseInt(crates[crateIndex].quantity) + 1 };
          crates.splice(crateIndex, 1, newCrate);
        } else {
          const newCrate = { ...crate, quantity: 1 };
          crates.push(newCrate);
        }
      })
    }
  });
  return crates;
}

export const checkTerminalStatus = (deliveryStatus: string) => {
  return AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TERMINAL_STATUSES.indexOf(deliveryStatus) > -1;
}
