import React from "react";

import { Grid, Typography } from "@material-ui/core";

import TotalOrdersIcon from "../../../assets/icons/delve-dashboard-order-status-total-order.svg";
import FullyVerifiedIcon from "../../../assets/icons/delve-dashboard-order-status-fully-verified.svg";
import UnverifiedIcon from "../../../assets/icons/delve-dashboard-order-status-unverified.svg";
import PartiallyVerifiedIcon from "../../../assets/icons/delve-dashboard-order-status-partially-verified.svg";
import CratesVerifiedIcon from "../../../assets/icons/delve-dashboard-order-status-crates-verified.svg";
import CratesUnverifiedIcon from "../../../assets/icons/delve-dashboard-order-status-crates-unverified.svg";
import ItemsVerifiedIcon from "../../../assets/icons/delve-dashboard-order-status-items-verified.svg";
import ItemsUnverifiedIcon from "../../../assets/icons/delve-dashboard-order-status-items-unverified.svg";
import IssuesReportedIcon from "../../../assets/icons/delve-dashboard-order-status-issues-reported.svg";

import { checkIfInputIsValidObjectWithKeys, formatNumberWithCommas, isUndefined } from "../../../utils/helpers.utils";
import {
  capitalizeIssueNameString,
  getCratesUnVerfiedPercentage,
  getCratesVerfiedPercentage,
  getIssuesReportedPercentage,
  getItemsUnVerfiedPercentage,
  getItemsVerfiedPercentage,
  getOrdersPartiallyVerfiedPercentage,
  getOrdersUnVerfiedPercentage,
  getOrdersVerfiedPercentage,
  getUnVerfiedConsignmentPercentage,
  getVerfiedConsignmentPercentage,
} from "../Utils/DelveDashboardUtils";

interface DelveDashboardOrderStatusDetailsInterface {
  classes: any;
  recordObj: any;
}

const DelveDashboardOrderStatusDetails = (props: DelveDashboardOrderStatusDetailsInterface) => {
  const { classes, recordObj } = props;

  const isDashboardRecordContainsOrderCount = () => {
    return !isUndefined(recordObj) && !isUndefined(recordObj.orderCount) && checkIfInputIsValidObjectWithKeys(recordObj.orderCount);
  };

  const getIconNameByOrderCountKey = (keyName: any) => {
    switch (keyName) {
      case "totalOrders":
      case "totalConsignments":
        return TotalOrdersIcon;
      case "ordersVerified":
      case "verifiedConsignments":
        return FullyVerifiedIcon;
      case "ordersUnverified":
      case "unverifiedConsignments":
        return UnverifiedIcon;
      case "ordersPartialVerified":
        return PartiallyVerifiedIcon;
      case "cratesVerified":
        return CratesVerifiedIcon;
      case "cratesUnverified":
        return CratesUnverifiedIcon;
      case "itemsVerified":
        return ItemsVerifiedIcon;
      case "itemsUnverified":
        return ItemsUnverifiedIcon;
      case "issuesFound":
        return IssuesReportedIcon;
      default:
        return FullyVerifiedIcon;
    }
  };

  const getPercentageCountValue = (keyName: any, orderCountList: any) => {
    switch (keyName) {
      case "ordersVerified":
        return getOrdersVerfiedPercentage(orderCountList);
      case "ordersPartialVerified":
        return getOrdersPartiallyVerfiedPercentage(orderCountList);
      case "ordersUnverified":
        return getOrdersUnVerfiedPercentage(orderCountList);
      case "cratesVerified":
        return getCratesVerfiedPercentage(orderCountList);
      case "cratesUnverified":
        return getCratesUnVerfiedPercentage(orderCountList);
      case "itemsVerified":
        return getItemsVerfiedPercentage(orderCountList);
      case "itemsUnverified":
        return getItemsUnVerfiedPercentage(orderCountList);
      case "issuesFound":
        return getIssuesReportedPercentage(orderCountList);
      case "verifiedConsignments":
        return getVerfiedConsignmentPercentage(orderCountList);
      case "unverifiedConsignments":
        return getUnVerfiedConsignmentPercentage(orderCountList);
      default:
        return "";
    }
  };

  return (
    <Grid container spacing={0} className={`${classes.gritContainerItem} ${classes.orderCountDetailsMainContainer}`}>
      {isDashboardRecordContainsOrderCount() &&
        Object.keys(recordObj.orderCount).map((item: any, itemIndex: any) => (
          <Grid item xs key={`${itemIndex}-itemIndex-orderCount`} className={classes.orderCountDetailsMainBox}>
            <Grid container className={classes.orderCountDetailsIconCountContainer}>
              <Grid item className={classes.orderCountIconContainer}>
                <img src={getIconNameByOrderCountKey(item)} alt="order-status-icon" />
              </Grid>
              <Grid item className={classes.orderCountDetailsCountContainer}>
                <Typography className={classes.orderDetailsCountSpanStyle}>{`${recordObj.orderCount[item].count}`}</Typography>
                <Typography className={classes.orderPercentageDetailsTitleSpanStyle}>{`${getPercentageCountValue(item, recordObj.orderCount)}`}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.orderCountDetailsTitleSpanContainer}>
              <Typography className={classes.orderCountDetailsTitleSpanStyle}>{capitalizeIssueNameString(item)}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.orderCountDetailsCostContainer}>
              <Typography className={classes.orderCountDetailsCostSpanStyle}>{`${formatNumberWithCommas(recordObj.orderCount[item].cost)} ${recordObj?.currency || ""}`}</Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default React.memo(DelveDashboardOrderStatusDetails);
