// Deployemnt
export const basicAuthorization = process.env.REACT_APP_AUTHORIZATION;
export const reportBaseURL = `${process.env.REACT_APP_REPORT_BASE_URL}/lastmile`;
export const userBaseURL = `${process.env.REACT_APP_USER_BASE_URL}/lastmile`;
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const expressBaseURL = `${process.env.REACT_APP_EXPRESS_URL}/lastmile`;
export const expressDriverBaseURL = `${process.env.REACT_APP_EXPRESS_DRIVER_URL}/lastmile`;
export const delveBaseURL = `${process.env.REACT_APP_LASTMILE_DELVE_URL}/lastmile`;
export const apigeeServiceURL = `${process.env.REACT_APP_LASTMILE_APIGEE_URL}/lastmile/v1`;
export const camsServiceURL = `${process.env.REACT_APP_CAMS_URL}/lastmile`;
export const growthBookURL = `${process.env.REACT_APP_GB_URL}`;
export const growthBookKey = `${process.env.REACT_APP_GB_Key}`;
export const undeliveredReasonFeatureEnabledForCountries = `${process.env.REACT_APP_UNDELIVERED_REASON_FEATURE_ENABLED}`;
export const customerCommunicationFeatureEnabledForCountries = `${process.env.REACT_APP_CUSTOMER_COMMUNICATION_FEATURE_ENABLED}`;
export const subRouteCreateFeatureEnabledForCountries = `${process.env.REACT_APP_SUB_ROUTE_FEATURE_ENABLED}`;

// Local
// export const basicAuthorization = process.env.REACT_APP_AUTHORIZATION;
// export const reportBaseURL = `${process.env.REACT_APP_REPORT_BASE_URL}/lastmile`;
// export const userBaseURL = `${process.env.REACT_APP_USER_BASE_URL}/lastmile`;
// export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
// export const expressBaseURL = `${process.env.REACT_APP_EXPRESS_URL}/lastmile`;
// export const expressDriverBaseURL = `${process.env.REACT_APP_EXPRESS_DRIVER_URL}/lastmile`;
// export const delveBaseURL = `${process.env.REACT_APP_LASTMILE_DELVE_URL}/lastmile`;
// export const apigeeServiceURL = `${process.env.REACT_APP_LASTMILE_APIGEE_URL}/lastmile/v1`;
// export const camsServiceURL = `${process.env.REACT_APP_CAMS_URL}/lastmile`;
// export const growthBookURL = `${process.env.REACT_APP_GB_URL}`;
// export const growthBookKey = `${process.env.REACT_APP_GB_Key}`;
