import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: "100%",
      backgroundColor: COLORS.ROUTE_CONTAINER_BACKGROUND,
    },
    container: {
      flexDirection: "column",
      flexWrap: "nowrap",
    },
    mainContainer: {
      flexWrap: "nowrap",
      height: 286,
      paddingLeft: theme.spacing(1),
    },
    heading: {
      fontWeight: 600,
      color: COLORS.MINE_SHAFT,
      fontSize: 24,
      lineHeight: "29px",
    },
    subHeading: {
      fontWeight: 500,
      color: COLORS.COPY,
      fontSize: 14,
      lineHeight: "17px",
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    infoText: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(1),
    },
    accordion: {
      backgroundColor: COLORS.WHITE,
      boxShadow: `0px 1px 4px ${COLORS.LIGHT_BLACK_SHADOW}`,
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    accordionSummary: {
      color: COLORS.COPY,
      fontSize: 18,
      fontWeight: 600,
      "&.MuiAccordionSummary-root": {
        borderBottom: `0px solid ${COLORS.TRANSPARENT}`,
      },
    },
    accordionStyle: {
      marginTop: theme.spacing(2),
    },
    accordionTitle: {
      fontSize: 18,
      fontWeight: 600,
    },
    expectedAmtText: {
      fontWeight: 500,
      fontSize: 20,
      color: COLORS.MINE_SHAFT,
      lineHeight: "24px",
      paddingLeft: theme.spacing(1),
    },
    daysText: {
      color: COLORS.MINE_SHAFT,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "16px",
      marginLeft: theme.spacing(1),
    },
    dataDivs: {
      background: COLORS.WHITE,
      boxSizing: "border-box",
      borderRadius: theme.spacing(1),
      border: `1px solid ${COLORS.SECONDARY_DARK}`,
      padding: theme.spacing(2),
      width: 166,
    },
    amountCounts: {
      paddingTop: theme.spacing(1.5)
    },
    firstAmountDiv: {
      paddingRight: theme.spacing(0.75),
    },
    secondAmountDiv: {
      paddingLeft: theme.spacing(0.75),
    },
    amountStatusText: {
      fontWeight: 500,
      fontSize: 14,
      color: COLORS.COPY,
      lineHeight: "17px",
    },
    amountStatusData: {
      fontWeight: 600,
      fontSize: 14,
      color: COLORS.MINE_SHAFT,
      lineHeight: "17px",
      paddingTop: theme.spacing(1),
    },
    amountStatusSummaryData: {
      fontWeight: 600,
      fontSize: 14,
      color: COLORS.MINE_SHAFT,
      lineHeight: "17px",
      paddingTop: theme.spacing(1),
      paddingLeft: 15,
    },
    orderStatusBottonIcon: {
      fontSize: 12,
      border: `2px solid ${COLORS.WHITE}`,
      borderRadius: "50%",
      marginRight: theme.spacing(0.75),
      marginBottom: "auto",
    },
    orderStatusBottonIconCollected: {
      color: COLORS.PRIMARY_MAIN,
    },
    orderStatusBottonIconHandedOver: {
      color: COLORS.LIGHT_GREEN,
    },
    orderStatusBottonIconPending: {
      color: COLORS.ALERT_ORANGE,
    },
    orderStatusBottonIconExcess: {
      color: COLORS.PICTON_BLUE,
    },
    orderStatusBottonIconShortage: {
      color: COLORS.CORAL_PINK,
    },
  })
);
