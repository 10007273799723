import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchOrdersFilterChipsDisplayCount, fetchRoutesFilterChipsDisplayCount, fetchPlanningOrderListByFilter, fetchPlanningRouteListByFilter, publishRoutes, deleteRoute, bulkDeleteRoute,  clearPublishedRoutesErrors, clearUpdatedRoute, resetPlanningError, resetConsignmentReplanningSuccess, fetchDeliveryReasons, fetchDeliveryReasonsCountryBased, fetchDynamicFiltersOrdersDropdownValues, fetchDynamicFiltersRoutesDropdownValues, clearCurrentSlot, exportToExcel, exportRoutesToExcel, resetRouteConsignmentsState, resetSubrouteState } from "./redux/planningSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createDeleteRoutePayload,
  createBulkDeleteRoutePayload,
  createFilterPayload,
  createPublishRoutesPayload,
  getConsignmentStatusField,
  getRouteStatusField,
  lastMilePlanningOrdersDataTransform,
  lastMilePlanningRoutesDataTransform,
  transformDataForSubRoutes,
  createPayloadForExcelExport,
  createRoutesPayloadForExcelExport
} from "../mocks/routePlanning/response.transforms";
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import { ReactComponent as deleteIcon } from './../assets/icons/delete-icon.svg';
import { ReactComponent as checkIcon } from './../assets/icons/circle-checked-icon.svg';
import { AppState } from "../config/redux/reducers";
import { useStyles } from "./RoutePlanning.styles";
import AllRoutes from "./AllRoutes/AllRoutes";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import {persistState} from '../common/PersistData'
import CustomSnackbar from "../common/CustomSnackbar";
import { userLogout } from "../Login/redux/loginSlice";
import _ from "lodash";
import { CellParams, RowData, RowParams, SelectionChangeParams, SortModel } from "../common/GridWrapper/DataTable";
import { Button, Grid, IconButton, SvgIcon, Tab, Tabs, Typography } from "@material-ui/core";
import { TabContext, TabPanel } from '@material-ui/lab';
import { useHistory } from "react-router";
import CustomDialog from "../common/Dialog";
import CloseIcon from '@material-ui/icons/Close';
import ConsignmentDetails from "./ConsignmentDetails";
import CustomTooltip from "common/Tooltip";
import { getSlotUIValue, getValueFromArray, isArrayNonEmpty, isInputAnArray, isNull, isUndefined, isNonEmptyObject } from "../utils/helpers.utils";
import moment from "moment";
import SelectBox from "common/SelectBox";
import {Countdown} from '../utils/countdownTimer'
import {TIMER_CONST} from "../constants/CommonConstants"
import {ACTION_BUTTON} from "../constants/PlanningConstants";
import { ROUTE_TYPE, ALL_ROUTES_TEXT_CONTANT} from "./AllRoutes/utils/AllRouteConstant";
import RenderRescheduleOrders from "../common/RescheduleOrders/RenderRescheduleOrders";

interface RoutePlanningProps {
  match?: any;
  location?: any;
}

const RoutePlanning = (props: RoutePlanningProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
 // const feature = useFeatureIsOn("newtest-feature-flag")
  const { location } = props;
  const componentClasses = useStyles();
  const {
    ordersData,
    routesData,
    tableLoading,
    ordersFiltersDropdowns,
    routesFiltersDropdowns,
    ordersFilterChipsDisplayCount,
    routesFilterChipsDisplayCount,
    publishedRoutes,
    deletedRoute,
    publishedRoutesErrors,
    updatedRoute,
    routeDiscardSuccess,
    replanningConsignmentSuccess,
    loading,
    error,
    errorCode,
    excelLoading,
    exportError,
    isPolled,
    bulkDeletedRoute,
  } = useSelector((state: AppState) => state.routePlanning);
  
   let { isAddressUpdated } = useSelector(
    (state: AppState) => state.routePlanning
  );
  const { countryCode, hubCode, userDetailsDropdowns } = useSelector(
    (state: AppState) => state.common
  );
  const { isLastMileViewer, isOnlyStdLastMileViewer } = useSelector(
    (state: AppState) => state.userLogin
  );

  const onLoadRef = useRef(true);
  const [showTime, setShowTime] = useState(false);
  const [navigatedFromPublish, setNavigatedFromPublish] = useState(false)
  const [ordersListToShow, setOrdersListToShow] = useState([]);
  const [routesListToShow, setRoutesListToShow] = useState([]);
  const [ordersSearchValue, setOrdersSearchValue] = useState("");
  const [routesSearchValue, setRoutesSearchValue] = useState("");
  const [isOrderSearched, setIsOrderSearched] = useState(false);
  const [isRouteSearched, setIsRouteSearched] = useState(false);
  const [consignmentData, setConsignmentData] = useState({});
  const [isDisplayCount, setIsDisplayCount] = useState(false);
  const [openConsignmentDetailsPopup, setOpenConsignmentDetailsPopup] = useState(false);
  const [activeOrdersFilterChip, setActiveOrdersFilterChip] = useState(AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);
  const persitedRouteFilterChip = persistState.load(AppConstants.PLANNING_CONSTANTS.ROUTE_DATA_PERSIST)?.activeRoutesFilterChip
  const [activeRoutesFilterChip, setActiveRoutesFilterChip] = useState(persitedRouteFilterChip ? persitedRouteFilterChip :AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.DEFAULT_FILTER_CHIP);

  const [selectedRoutes, setSelectedRoutes] = useState([] as RowData[]);
  const [bulkDeleteClicked, setBulkDeleteClicked]  = useState(false);
  const [isTabChange, setIsTabChange] = useState(false);
  const [lastTabClicked, setLastTabClicked ] = useState('');
  const [hubForRoutes, setHubForRoutes] = useState('');
  const [hubNameForRoutes, setHubNameForRoutes] = useState('');
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [editRoute, setEditRoute] = useState<any>(null);
  const [viewRoute, setViewRoute] = useState<any>(null);
  const [createRouteHub, setCreateRouteHub] = useState(AppConstants.SELECT_NONE.value);
  const [openCreateRouteHubSelectionPopup, setOpenCreateRouteHubSelectionPopup] = useState(false);
  const [createRoute, setCreateRoute] = useState(false);
  const [routeToBeDeleted, setRouteToBeDeleted] = useState<any>({});
  const [openPublishErrorMsgPopup, setOpenPublishErrorMsgPopup] = useState(false);
  const [routeState, setRouteState] = useState({ reselctSelectedCheckbox: false })
  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA, HeaderData: AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'consignmentId') {
        newHeader.renderCell = (params: CellParams) => (
          <div className="consignmentDivStyle">
            <Typography className="idLink">{`${params.value}`}</Typography>
            {params?.rowData?.rescheduled && <RenderRescheduleOrders />}            
          </div>
        )
      }
      if (newHeader.field === 'deliveryStatus') {
        newHeader.renderCell = (params: any) => (
          params.value ?
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={['tag', getConsignmentStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
            : <></>
        );
      }
      if (newHeader.field === 'routeStatus') {
        newHeader.renderCell = (params: any) => (
          params.value ?
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={['tag', getRouteStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
            : <></>
        );
      }
      return newHeader;
    }),
    defaultFiltersObj: {
      ...AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj, dateRange: {
        ...AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange, value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT)
        }
      }
    }
  });
  const [routesTableWrapperData, setRoutesTableWrapperData] = useState({
    ...AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA, HeaderData: AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'routeId') {
        newHeader.renderCell = (params: CellParams) => (
          <>
            <span className="idLink">{params.value}</span>
            { params?.rowData?.subRoutes?.length > 0 
              ? <span className={componentClasses.parenRouteTag}>{ALL_ROUTES_TEXT_CONTANT.PARENT_ROUTE}</span> 
              : params?.rowData?.routeType ===  ROUTE_TYPE.PARENT_ROUTE 
                ? <span className={componentClasses.parenRouteTag}>{ALL_ROUTES_TEXT_CONTANT.PARENT_ROUTE}</span> 
                : params?.rowData?.routeType ===  ROUTE_TYPE.SUB_ROUTE 
                  ? <span className={componentClasses.subRouteTag}>{ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE}</span> 
                  : <></>
            }
          </>
        );
        newHeader.renderSubElementCell = (params: CellParams) => (
          <>
            <Grid className={componentClasses.subRouteId} container alignItems="center">
              <Typography className="idLink">{params.value}</Typography>
              <Typography className='tag subRoute'>{ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE}</Typography>
            </Grid>
          </>
      
       );
      }
      if (newHeader.field === 'actions') {
        newHeader.renderCell = (params: CellParams) => (
          <>
           <Grid container className={componentClasses.actionsHeader}>
            <Grid className="actionItem" item>
              <SvgIcon className="icon editIcon" onClick={handleEditRouteIconClick(params.value)} component={editIcon} />
            </Grid>
            <Grid item className="actionItem">
              <SvgIcon className="icon deleteIcon" onClick={handleDeleteRouteIconClick(params.value)} component={deleteIcon} />
            </Grid>
          </Grid>
          </>
         
        );
        newHeader.renderSubElementCell = (params: CellParams) => (
          <>
            <Grid container className={componentClasses.actionsHeader}>
            <Grid className="actionItem" item>
              <SvgIcon className="icon editIcon" onClick={handleEditRouteIconClick(params.value)} component={editIcon} />
            </Grid>
           </Grid>
          </> 
          
         );
      }
      if (newHeader.field === 'deliveryArea') {
        newHeader.renderCell = (params: CellParams) => {
          return (
            <Grid container className={componentClasses.deliveryAreaHeader}>
              <CustomTooltip title={getDeliveryAreas(params.value)}>
                <Typography className="columnValue">{params.value.length && params.value.length > 1 ? `Multiple(${params.value.length})` : params.value[0]}</Typography>
              </CustomTooltip>
            </Grid>
          );
        }
      }
      if (newHeader.field === 'deliverySlot') {
        newHeader.renderCell = (params: CellParams) => {
          const firstDelSlot = params.value.length && params.value[0].split('-');
          const slotValue = getSlotUIValue(firstDelSlot);
          return (
            <Grid container className={componentClasses.deliveryAreaHeader}>
              <CustomTooltip title={getDeliverySlots(params.value)}>
                <Typography className="columnValue">{params.value.length && params.value.length > 1 ? `Multiple(${params.value.length})` : `${slotValue.startTime} - ${slotValue.endTime}`}</Typography>
              </CustomTooltip>
            </Grid>
          );
        }
      }
      if (newHeader.field === 'routeStatus') {
        newHeader.renderCell = (params: any) => (
          <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
            <Typography className={['tag', getRouteStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
          </Grid>
        );
      }
      return newHeader;
    }),
    defaultFiltersObj: {
      ...AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj, dateRange: {
        ...AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange, value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT)
        }
      }
    }
  });

  const [routesTableInitialData, setRoutesTableInitialData] = useState({
    ...AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA, HeaderData: AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
  
      if (newHeader.field === 'actions') {
        newHeader.renderCell = (params: CellParams) => (
          <>
           <Grid container className={componentClasses.actionsHeader}>
            <Grid className="actionItem" item>
              <SvgIcon className="icon editIcon" onClick={handleEditRouteIconClick(params.value)} component={editIcon} />
            </Grid>
            {AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.OUT_FOR_DELIVERY !== (params.rowData?.routeStatus.toString().toUpperCase()) && params.value.routeType !== ROUTE_TYPE.SUB_ROUTE   && <Grid item className="actionItem">
              <SvgIcon className="icon deleteIcon" onClick={handleDeleteRouteIconClick(params.value)} component={deleteIcon} />
            </Grid>}
          </Grid>
          </>
         
        );
        newHeader.renderSubElementCell = (params: CellParams) => (
          <>
            <Grid container className={componentClasses.actionsHeader}>
            <Grid className="actionItem" item>
              <SvgIcon className="icon editIcon" onClick={handleEditRouteIconClick(params.value)} component={editIcon} />
            </Grid>
           </Grid>
          </> 
          
         );
      }
      return newHeader;
    })
  });
  const [ordersActiveFilters, setOrdersActiveFilters] = useState({ ...ordersTableWrapperData.defaultFiltersObj });
  const persitedRouteData = persistState.load(AppConstants.PLANNING_CONSTANTS.ROUTE_DATA_PERSIST)?.routesActiveFilters
  const [tabValue, setTabValue] = useState(isNonEmptyObject(persitedRouteData) ? AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES : location.state && location.state.tabValue ? location.state.tabValue : AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.CONSIGNMENTS);

  const [routesActiveFilters, setRoutesActiveFilters] = useState(isNonEmptyObject(persitedRouteData) ? {...persitedRouteData} : { ...routesTableWrapperData.defaultFiltersObj });
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const [renderExcelProgress, setRenderExcelProgress] = useState(false);
  const [isExpress, setIsExpress] = useState(false);

  const publishDialogPaperProps = {
    classes: {
      root: componentClasses.publishDialogPaperPropsRoot
    }
  }

  const deleteDialogPaperProps = {
    classes: {
      root: componentClasses.deleteDialogPaperPropsRoot
    }
  }

  const createRouteHubSelectionDialogPaperProps = {
    classes: {
      root: componentClasses.hubSelectionDialogPaperPropsRoot
    }
  }

  const fetchDynamicFilters = useCallback(
    (filters: any, isRoute?: boolean) => {
      if (isRoute) {
        dispatch(
          fetchDynamicFiltersRoutesDropdownValues({
            filters: filters
          })
        )
      } else {
        dispatch(
          fetchDynamicFiltersOrdersDropdownValues({
            filters: filters
          })
        )
      }
    },
    [dispatch]
  );

  const handleHeaderButtonsDisableState = useCallback(
    (isRoute?: boolean) => {
      if (isRoute) {
        let headerButtons = routesTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case ACTION_BUTTON.PUBLISH:
              btn.disabled = !selectedRoutes.length;
              break;
            case ACTION_BUTTON.BULK_DELETE:
              btn.disabled = !selectedRoutes.length;
              btn.displayName=  selectedRoutes.length ? ` Delete ${selectedRoutes.length} Selected`: 'Delete Routes'
              break;  
            default:
              break;
          }
          return btn;
        });
        setRoutesTableWrapperData({ ...routesTableWrapperData, headerButtons: headerButtons });
      } else {
        let headerButtons = ordersTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case "createRoute":
            if(isLastMileViewer || isOnlyStdLastMileViewer){
              btn.hide = true;
            }
            default:
              break;
          }
          return btn;
        });
        setOrdersTableWrapperData({ ...ordersTableWrapperData, headerButtons: headerButtons });
      }
    },
    [ordersTableWrapperData, routesTableWrapperData, selectedRoutes]
  );

  const handleHeaderButtonsHideState = useCallback(
    (isRoute?: boolean) => {
      if (isRoute) {
        let headerButtons = routesTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case ACTION_BUTTON.PUBLISH:
              btn.hide = activeRoutesFilterChip !== AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.DEFAULT_FILTER_CHIP;
              break;
            case ACTION_BUTTON.BULK_DELETE:
              btn.hide = AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.DELETE_STATUS.indexOf(activeRoutesFilterChip) < 0; 
                break;
            default:
              break;
          }
          return btn;
        });
        setRoutesTableWrapperData({ ...routesTableWrapperData, headerButtons: headerButtons });
      } else {
        let headerButtons = ordersTableWrapperData.headerButtons.map((btn: any) => {
          switch (btn.name) {
            case 'createRoute':
              btn.hide = activeOrdersFilterChip !== AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP;
              break;
            default:
              break;
          }
          return btn;
        });
        setOrdersTableWrapperData({ ...ordersTableWrapperData, headerButtons: headerButtons });
      }
    },
    [ordersTableWrapperData, routesTableWrapperData, activeOrdersFilterChip, activeRoutesFilterChip]
  );

  const handleLoadMoreDisableState = useCallback(
    (isRoute?: boolean) => {
      if (isRoute) {
        let loadMoreState = routesTableWrapperData.loadMore;
        loadMoreState.disabled = routesData.totalPages ? routesData.pageNumber + 1 >= routesData.totalPages : true;
        setRoutesTableWrapperData({ ...routesTableWrapperData, loadMore: loadMoreState });
      } else {
        let loadMoreState = ordersTableWrapperData.loadMore;
        loadMoreState.disabled = ordersData.totalPages ? ordersData.pageNumber + 1 >= ordersData.totalPages : true;
        setOrdersTableWrapperData({ ...ordersTableWrapperData, loadMore: loadMoreState });
      }
    },
    [routesTableWrapperData, ordersTableWrapperData, ordersData, routesData]
  );

  const getFilterChipsCount = useCallback(
    (payload: any, isRoute?: boolean) => {
      setIsDisplayCount(false);
      let updatedPayload = _.cloneDeep(payload);
      if (isRoute) {
        updatedPayload.attributes = updatedPayload.attributes.filter((attr: any) => attr.key !== 'c.displayRouteStatus');
        dispatch(
          fetchRoutesFilterChipsDisplayCount({
            filters: updatedPayload
          })
        );
        setIsDisplayCount(true);
      } else {
        updatedPayload.attributes = updatedPayload.attributes.filter((attr: any) => attr.key !== 'c.metadata.displayRouteStatus');
        dispatch(
          fetchOrdersFilterChipsDisplayCount({
            filters: updatedPayload
          })
        );
        setIsDisplayCount(true);
      }

    },
    [dispatch],
  )

  const ordersSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    const activeFilters = _.cloneDeep(filters);
    onLoadRef.current = false;
    setOrdersSearchValue(updatedSearchValue);
    setIsOrderSearched(false);
    if (updatedSearchValue) {
      setIsOrderSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey]
          }
          ordersFilterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const routesSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    const activeFilters = _.cloneDeep(filters);
    onLoadRef.current = false;
    setRoutesSearchValue(updatedSearchValue);
    setIsRouteSearched(false);
    if (updatedSearchValue) {
      setIsRouteSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey]
          }
          routesFilterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const ordersFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean) => {
      if (filters) {
                let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
        if (!isChipFilterClicked) {
          if (activeOrdersFilterChip === AppConstants.PLANNING_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            if (payloadFilters.displayRouteStatus && payloadFilters.displayRouteStatus.value) {
              payloadFilters.displayRouteStatus.value = [activeOrdersFilterChip];
            }
          }
        }
        const payload = createFilterPayload(payloadFilters, ordersData, isLoadMore, isExpress);
        if (!isLoadMore) {
          fetchDynamicFilters(payload);
        }
        if(isLoadMore){
          setIsTabChange(false);
        }
        getFilterChipsCount(payload);
        dispatch(
          fetchPlanningOrderListByFilter({
            filters: payload
          })
        );
        setOrdersActiveFilters(filters);
      }
      setRenderExcelProgress(false);
    },
    [countryCode, ordersData, ordersTableWrapperData, activeOrdersFilterChip, isExpress, fetchDynamicFilters, dispatch, getFilterChipsCount],
  );

  const handleExcelExport = useCallback(
    (filters: any, isRoute: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        if (isRoute) {
          if (activeRoutesFilterChip === AppConstants.PLANNING_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeRoutesFilterChip];
          }
          const payload = createRoutesPayloadForExcelExport(payloadFilters, routesData, false, isExpress, true);
          dispatch(
            exportRoutesToExcel({
              payload: payload
            })
          );
        } else {
          if (activeOrdersFilterChip === AppConstants.PLANNING_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeOrdersFilterChip];
          }
          const payload = createPayloadForExcelExport(payloadFilters, ordersData, false, isExpress, false);
          dispatch(
            exportToExcel({
              payload: payload
            })
          );
        }
      }
      setRenderExcelProgress(true);
    },
    [dispatch, ordersData, routesData, isExpress, countryCode, activeOrdersFilterChip, activeRoutesFilterChip]
  )

  const routesFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
                payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        payloadFilters.pageSize = routesTableWrapperData.loadMore.pageSize;
        if (!isChipFilterClicked) {
          if (activeRoutesFilterChip === AppConstants.PLANNING_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeRoutesFilterChip];
          }
        }
       const payload = createFilterPayload(payloadFilters, routesData, isLoadMore, isExpress, true);
        if (!isLoadMore) {
          fetchDynamicFilters(payload, true);
        }
        if(isLoadMore){
          setIsTabChange(false);
        }
        dispatch(
          fetchPlanningRouteListByFilter({
            filters: payload
          })
        );
        getFilterChipsCount(payload, true);
        setRoutesActiveFilters(filters);
      }
      setRenderExcelProgress(false);
    },
    [countryCode, routesData, routesTableWrapperData, activeRoutesFilterChip, isExpress, fetchDynamicFilters, dispatch, getFilterChipsCount],
  );



  const handleConsignmentReplanningReset = useCallback(
    () => {
      dispatch(
        resetConsignmentReplanningSuccess()
      )
    },
    [dispatch],
  );

  const handleHubSelectionDialogClose = useCallback(
    () => {
      setOpenCreateRouteHubSelectionPopup(false);
    },
    [],
  );

  const handleClearCurrentSlot = useCallback(
    () => {
      dispatch(
        clearCurrentSlot()
      );
    },
    [dispatch],
  );

  const hubSelectionDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'continue') {
        if(lastTabClicked ){
          if(hubForRoutes){
            setTabValue(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES);
          }
          else{
            setOpenCreateRouteHubSelectionPopup(false)
          }
        }
        else{
          handleClearCurrentSlot();
          setCreateRoute(true);
          setEditRoute(null);
        }
      }
      handleHubSelectionDialogClose();
    },
    [handleHubSelectionDialogClose, handleClearCurrentSlot, lastTabClicked, hubForRoutes],
  )

  const handleHubChangePopup = useCallback(
    () => {
      const hubs = userDetailsDropdowns.hubList ? userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] : [];
      if (hubs.length > 1) {
        setOpenCreateRouteHubSelectionPopup(true);
      } else {
        if(lastTabClicked ){
          setTabValue(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES);
          setHubForRoutes(hubs.length ? hubs[0].value : AppConstants.SELECT_NONE.value)
        }else{
          setCreateRouteHub(hubs.length ? hubs[0].value : AppConstants.SELECT_NONE.value);
        }
        hubSelectionDialogActionHandler('continue');
      }
    },
    [userDetailsDropdowns, countryCode, hubSelectionDialogActionHandler],
  )

  const handleOrdersHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case 'createRoute':
            handleHubChangePopup();
            break;
          case 'excel':
            handleExcelExport(ordersActiveFilters, false);
            break;
          default:
            break;
        }
      }
    },
    [handleExcelExport, handleHubChangePopup, ordersActiveFilters]
  );

  const handleConsignmentCellClick = useCallback(
    (params: CellParams) => {
      if (params.field === 'consignmentId') {
        const selectedRow: any = params.rowData;
        const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
        setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
        setOpenConsignmentDetailsPopup(true);
        handleConsignmentReplanningReset();
      }
    },
    [ordersData, handleConsignmentReplanningReset],
  );

  const handleRowClick = useCallback(
    (params: RowParams) => {
      const selectedRow: any = params.data;
      const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
      setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
      setOpenConsignmentDetailsPopup(true);
      handleConsignmentReplanningReset();
    },
    [ordersData, handleConsignmentReplanningReset],
  );

  const handleRouteCellClick = useCallback(
    (params: CellParams) => {
      if (params.field !== 'actions') {
        const selectedRow: any = params.rowData;
        const selectedRoute = routesData.elements.filter((item: any) => item.id === selectedRow.id);
        handleClearCurrentSlot();
        setViewRoute(selectedRoute.length ? selectedRoute[0] : {});
        setCreateRoute(false);
      }
    },
    [routesData, handleClearCurrentSlot],
  )

  const closeConsignmentDetailsPopup = useCallback(
    () => {
      setOpenConsignmentDetailsPopup(false);
    },
    [],
  );



  const handleRoutesHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case ACTION_BUTTON.PUBLISH:
            setOpenPublishDialog(true);
            break;
          case ACTION_BUTTON.BULK_DELETE:
            handleDeleteBulkRouteButtonClick();
            break;
          case 'excel':
            handleExcelExport(routesActiveFilters, true);
            break;
          default:
            break;
        }
      }
    },
    [handleExcelExport, routesActiveFilters]
  );

  const getPublishDialogTitleContent = () => {
    return (
      <></>
    )
  };

  const getPublishDialogDetailsContent = () => {
    return (
      <Grid className={componentClasses.publishDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>Are you sure you want to publish these routes?</Typography>
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography><span className="count">{selectedRoutes.length > 1 ? `${selectedRoutes.length} Routes` : `${selectedRoutes.length} Route`}</span>{selectedRoutes.length > 1 ? ' are ' : ' is '}being published. Once published, the drivers will be notified of the assigned routes.</Typography>
        </Grid>
      </Grid>
    )
  };

  const handlePublishDialogClose = useCallback(
    () => {
      setOpenPublishDialog(false);
    },
    [],
  )

  const getDeleteDialogTitleContent = () => {
    return (
      <></>
    )
  };

  const getDeleteDialogDetailsContent = () => {
    return (
      <Grid className={componentClasses.deleteDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          {!bulkDeleteClicked && <Typography>{`Are you sure you want to delete Route ${routeToBeDeleted.routeId}`}</Typography>}
          {bulkDeleteClicked && <Typography>{`Are you sure you want to delete ${selectedRoutes.length} route(s)`}</Typography>}
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography>Once deleted, the consignments in this route will be moved back to the unplanned tab.</Typography>
        </Grid>
      </Grid>
    )
  };

  const handleHubChange = useCallback(
    (value: any) => {
      sessionStorage.setItem(AppConstants.PLANNING_CONSTANTS.HUB_FOR_ROUTES, "");
      setCreateRouteHub(value);
      if (lastTabClicked) {
        const hubName: any = getValueFromArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [], "value", value);
        setHubForRoutes(value);
        setCreateRouteHub(value);
        setHubNameForRoutes(hubName);
        sessionStorage.setItem(AppConstants.PLANNING_CONSTANTS.HUB_FOR_ROUTES, value);
      }
    },
    [lastTabClicked]
  );

  const getHubSelectionDialogTitleContent = () => {
    return (
      <Typography className={componentClasses.hubSelectionDialogTitle}>Select Hub</Typography>
    )
  }

  const getHubSelectionDialogDetailsContent = () => {
    return (
      <Grid className={componentClasses.hubSelectionDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>Please select the Hub to create routes</Typography>
        </Grid>
        <Grid className="contentItem hub" item>
          <SelectBox
            fullWidth
            className="hubSelectBox"
            handleChange={handleHubChange}
            value={createRouteHub}
            id="createRoute-hub-select-box"
            label="Hub"
            enableSearch={true}
            items={userDetailsDropdowns.hubListWithCodeAndName[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || []}
          ></SelectBox>
        </Grid>
      </Grid>
    )
  };

  const getDeliveryAreas = (areas: any[]) => {
    return areas.length && areas.map((area: string, index: number) => (
      <Typography key={`tooltipArea-${area}-${index}`} className="tooltipItem">{area}</Typography>
    ));
  }

  const getDeliverySlots = (slots: any[]) => {
    return slots.length && slots.map((slot: string, index: number) => {
      const delSlot = slot.split('-');
      const slotValue = getSlotUIValue(delSlot);
      return (
        <Typography key={`tooltipArea-${slot}-${index}`} className="tooltipItem">{`${slotValue.startTime} - ${slotValue.endTime}`}</Typography>
      )
    });
  }

  const handleDeleteRouteIconClick = (value: any) => () => {
    setBulkDeleteClicked(false);
    setRouteToBeDeleted(value);
    setOpenDeleteDialog(true);
  }

  const handleDeleteBulkRouteButtonClick = () => {
    setBulkDeleteClicked(true);
    setOpenDeleteDialog(true);
  }

  const handleEditRouteIconClick = (value: any) => () => {
    handleClearCurrentSlot();
    setEditRoute(value);
    setCreateRoute(false);
  }

  const handleDeleteDialogClose = useCallback(
    () => {
      setRouteToBeDeleted({});
      setBulkDeleteClicked(false);
      setOpenDeleteDialog(false);
    },
    [],
  );

  const handleBulkOrSingleDelete = useCallback(
    (routesData) => {
      const bulkDeletePayload = createBulkDeleteRoutePayload(routesData);
      if (bulkDeletePayload) {
        dispatch(
          bulkDeleteRoute({
            payload: bulkDeletePayload
          })
        )
      }
    },
    [dispatch],
  )

  const handleDeleteRoute = useCallback(() => {
    handleBulkOrSingleDelete([routeToBeDeleted]);
  }, [routeToBeDeleted]);

  const handleBulkDelete = useCallback(
    () => {
      handleBulkOrSingleDelete(selectedRoutes)
    },
    [selectedRoutes],
  ) 

  const deleteRouteDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'delete') {
        handleDeleteRoute();
      }
      if(type === 'bulkDelete'){
        handleBulkDelete()
      }
      handleDeleteDialogClose();
    },
    [handleDeleteDialogClose, handleDeleteRoute, handleBulkDelete],
  )

  const getDeleteDialogActions = () => {
    return (
      <Grid className={componentClasses.deleteDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { deleteRouteDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { bulkDeleteClicked ? deleteRouteDialogActionHandler('bulkDelete') : deleteRouteDialogActionHandler('delete') }}>{AppConstants.BUTTONS.PROCEED}</Button>
      </Grid>
    )
  }

  const getHubSelectionDialogActions = () => {
    return (
      <Grid className={componentClasses.hubSelectionDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { hubSelectionDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" disabled={createRouteHub === AppConstants.SELECT_NONE.value} onClick={() => { hubSelectionDialogActionHandler('continue') }}>{AppConstants.BUTTONS.CONTINUE}</Button>
      </Grid>
    )
  }



  const handlePublishRoutes = useCallback(
    () => {
      const publishPayload = createPublishRoutesPayload(selectedRoutes);
      if (publishPayload) {
        dispatch(
          publishRoutes({
            payload: publishPayload
          })
        )
      }
    },
    [dispatch, selectedRoutes],
  )

  const publishRoutesDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'publish') {
        handlePublishRoutes();
      }
      handlePublishDialogClose();
    },
    [handlePublishDialogClose, handlePublishRoutes],
  )

  const getPublishDialogActions = () => {
    return (
      <Grid className={componentClasses.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { publishRoutesDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { publishRoutesDialogActionHandler('publish') }}>{AppConstants.BUTTONS.PROCEED}</Button>
      </Grid>
    )
  }

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
    },
    [],
  );

  const handleProgressClose = useCallback(
    () => {
      setRenderExcelProgress(false);
    },
    [],
  );

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSnackbarExited = useCallback(
    () => {
      if (errorCode === AppConstants.PLANNING_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
      if (error) {
        dispatch(
          resetPlanningError()
        )
      }
      if (updatedRoute && updatedRoute.route) {
        dispatch(
          clearUpdatedRoute()
        )
      }
    },
    [dispatch, error, errorCode, updatedRoute]
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, value: string) => {
    persistState.remove(AppConstants.PLANNING_CONSTANTS.ROUTE_DATA_PERSIST)
    if (value == AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES) {
      setLastTabClicked(value);
      setCreateRouteHub(AppConstants.SELECT_NONE.value);
      let hubValue: string | null | undefined = sessionStorage.getItem(AppConstants.PLANNING_CONSTANTS.HUB_FOR_ROUTES);          
      if (hubValue && !isNull(hubValue) ) {
        const hubName: any = getValueFromArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [], "value", hubValue);
        setHubForRoutes(hubValue);
        setHubNameForRoutes(hubName);
        setTabValue(value);
      } else if (
        !isUndefined(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode]) &&
        isInputAnArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode]) &&
        isArrayNonEmpty(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode]) &&
        userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode]?.length === 1
      ) {
        const hubName: any = userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode];
        hubValue = hubName[0]?.value;
        setHubForRoutes(hubValue || AppConstants.SELECT_NONE.value);
        setHubNameForRoutes(hubName);
        setTabValue(value);
      } else {
        setOpenCreateRouteHubSelectionPopup(true);
      }
    } else {
      setHubForRoutes("");
      setLastTabClicked("");
      setTabValue(value);
    }
    let updatedFilters: any = {};
    if (value === AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES) {
      updatedFilters = {
        ...routesActiveFilters,
        hub: { ...routesActiveFilters.hub, value: ordersActiveFilters.hub.value },
        hubName: { ...routesActiveFilters.hubName, value: ordersActiveFilters.hubName.value },
        dateRange: ordersActiveFilters.dateRange,
        deliverySlot: ordersActiveFilters.deliverySlot,
      };
      setIsRouteSearched(false);
    } else {
      updatedFilters = {
        ...ordersActiveFilters,
        hub: { ...ordersActiveFilters.hub, value: routesActiveFilters.hub.value },
        hubName: { ...ordersActiveFilters.hubName, value: routesActiveFilters.hubName.value },
        dateRange: routesActiveFilters.dateRange,
        deliverySlot: routesActiveFilters.deliverySlot,
      };
      setIsOrderSearched(false);
    }
  }

  const handleChipFilter = useCallback(
    (chip: any, activeFilters: any, isRoute?: boolean) => {
      let filters = { ...activeFilters };
      setIsTabChange(true);
      onLoadRef.current = false;
      if (isRoute) {      
        setRouteState({...routeState, reselctSelectedCheckbox: true});
        if(navigatedFromPublish){
          filters = chip.code === AppConstants.PLANNING_CONSTANTS.ALL_FILTER_CHIP ? { ...filters, displayRouteStatus: null } : { ...filters, displayRouteStatus: { ...AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.DISPLAY_PUBLISHED_FILTER_OBJ, value: [chip.code] } }
        }
        else{
          filters = chip.code === AppConstants.PLANNING_CONSTANTS.ALL_FILTER_CHIP ? { ...filters, displayRouteStatus: null } : { ...filters, displayRouteStatus: { ...AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.DISPLAY_ROUTE_STATUS_FILTER_OBJ, value: [chip.code] } }
        }
        setIsRouteSearched(false);
        if (chip.code === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP) {
          setTabValue(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.CONSIGNMENTS);
          setActiveOrdersFilterChip(chip.code);
        }
        if (chip.code !== activeRoutesFilterChip) {
          setActiveRoutesFilterChip(chip.code);
          routesFilterCallback(filters, false, true);
          let hederTemp:any;
          routesTableInitialData.HeaderData.map((header: any) => {
            hederTemp = { ...header };
          })
          const headerData = routesTableWrapperData.HeaderData.map((header: any) => {
            let newHeader = { ...header };
            if (newHeader.field === 'actions') {
              newHeader.renderCell = AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.EDIT_STATUS_ROUTE.indexOf(chip.code) < 0 ? () => (<></>) : hederTemp.renderCell;
              newHeader.renderSubElementCell = AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.EDIT_STATUS_ROUTE.indexOf(chip.code) < 0 ? () => (<></>) : hederTemp.renderSubElementCell;
            }
            return newHeader;
          })
          setRoutesTableWrapperData({ ...routesTableWrapperData, HeaderData: headerData });
        }
      } else {
        filters = chip.code === AppConstants.PLANNING_CONSTANTS.ALL_FILTER_CHIP ? { ...filters, displayRouteStatus: null } : { ...filters, displayRouteStatus: { ...AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DISPLAY_ROUTE_STATUS_FILTER_OBJ, value: [chip.code] } }
        setIsOrderSearched(false);
        if (chip.code !== activeOrdersFilterChip) {
          setActiveOrdersFilterChip(chip.code);
          ordersFilterCallback(filters, false, true);
        }
      }
      setRenderExcelProgress(false);
    },
    [routesFilterCallback, ordersFilterCallback, routesTableWrapperData, activeOrdersFilterChip, activeRoutesFilterChip],
  )

  const updateSelectedRoutes = useCallback(
    (params: SelectionChangeParams) => {
      setRouteState({...routeState, reselctSelectedCheckbox: false});
      setSelectedRoutes(params.rows);
    },
    []
  );


  const viewPublishRoute = useCallback(
    () => {
      setTabValue(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES);
      setNavigatedFromPublish(true)
      setActiveRoutesFilterChip(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.PUBLISHED_FILTER_CHIP)
    },
    []
  );

  const getFilterChips = (isRoute?: boolean) => {
    const filterChips = isRoute ? AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.FILTER_CHIPS : AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.FILTER_CHIPS;
    return filterChips.map((chip: any, index: number) => (
      <>
        <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem ${index === 0 ? 'firstItem' : ''} ${isRoute ? activeRoutesFilterChip === chip.code ? 'active' : '' : activeOrdersFilterChip === chip.code ? 'active' : ''}`} onClick={() => handleChipFilter(chip, isRoute ? routesActiveFilters : ordersActiveFilters, isRoute)}>

          <Grid container className="chipContainer">
            <Grid item className="chipItem name">
              <Typography>{chip.value}</Typography>
            </Grid>
            <Grid item className="chipItem count">
              <Typography>{chip.code === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP ? ordersFilterChipsDisplayCount.consignmentCount[chip.code] : isRoute ? routesFilterChipsDisplayCount.routCount[chip.code] : ordersFilterChipsDisplayCount.consignmentCount[chip.code]}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    ))
  };

  const getPublishedErrorContentItems = () => {
    return publishedRoutesErrors.length ? publishedRoutesErrors.map((error: any) => (
      <Grid key={error.id} item className="contentItem">
        <Grid container className="content">
          <Grid item className="dotIcon"></Grid>
          <Grid item className="description">
            <Typography className="text">{error.message}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )) : null;
  };

  const handlePublishErrorPopupClose = useCallback(
    () => {
      setOpenPublishErrorMsgPopup(false);
      dispatch(
        clearPublishedRoutesErrors()
      );
    },
    [dispatch],
  );

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    history.replace({ pathname: location.pathname, state: {} })
  }

  useEffect(() => {
    dispatch(resetRouteConsignmentsState());
    dispatch(resetSubrouteState());
    isAddressUpdated = false;
    window.addEventListener('beforeunload', handleBeforeUnload);
    onLoadRef.current = true;
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      sessionStorage.setItem(AppConstants.PLANNING_CONSTANTS.HUB_FOR_ROUTES, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error, isExpress])



 useEffect(() => {
    if (publishedRoutes.length && tabValue !== AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES && tabValue === AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES ) {
      onLoadRef.current = false;
      openSnackbarPopup(`"${publishedRoutes.length} Route(s)" ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.PUBLISH_ROUTES_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      routesFilterCallback(routesActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedRoutes])

  useEffect(() => {
    if (updatedRoute && updatedRoute.route) {
      openSnackbarPopup(`Route "${updatedRoute.route.routeId}" ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.UPDATE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
    }
    if (!(updatedRoute && routeDiscardSuccess)) {
      if (editRoute) {
        // history.push(AppConstants.ROUTES.EDIT_ROUTE);
        history.push(`${AppConstants.ROUTES.EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          route: editRoute,
          isExpress: isExpress,
          slots: editRoute.deliverySlot || [],
          redirect: {
            route: AppConstants.ROUTES.PLANNING,
            state: {
              tabValue: AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES
            }
          }
        });
      }
      if (createRoute) {
        const hubName = getValueFromArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [], "value", createRouteHub);
        history.push(AppConstants.ROUTES.CREATE_ROUTE, {
          hubCode: createRouteHub,
          hubName: hubName.length ? hubName[0].name : createRouteHub,
          isExpress: isExpress
        });
      }
      if (viewRoute) {
        history.push(`${AppConstants.ROUTES.VIEW_ROUTE}/${viewRoute.id}`, {
          plannedDate: viewRoute.plannedDate,
          hubCode: viewRoute.hubCode,
          hubName: viewRoute.hubName,
          slots: viewRoute.deliverySlot || [],
          route: viewRoute,
          isExpress: isExpress,
          parentRoute: viewRoute?.routeType,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRoute, routeDiscardSuccess, isExpress])

  useEffect(() => {
    if (editRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(
          clearUpdatedRoute()
        );
      } else {
        history.push(`${AppConstants.ROUTES.EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          route: editRoute,
          isExpress: isExpress,
          slots: editRoute.deliverySlot || [],
          redirect: {
            route: AppConstants.ROUTES.PLANNING,
            state: {
              tabValue: AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES
            }
          }
        });
      }
    }
    if (createRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(
          clearUpdatedRoute()
        );
      } else {
        const hubName = getValueFromArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [], "value", createRouteHub);
        history.push(AppConstants.ROUTES.CREATE_ROUTE, {
          hubCode: createRouteHub,
          hubName: hubName.length ? hubName[0].name : createRouteHub,
          isExpress: isExpress
        });
      }
    }
    if (viewRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(
          clearUpdatedRoute()
        );
      } else {
        persistState.add(AppConstants.PLANNING_CONSTANTS.ROUTE_DATA_PERSIST, {
          routesActiveFilters: routesActiveFilters,
          activeRoutesFilterChip: activeRoutesFilterChip,
        });
        history.push(`${AppConstants.ROUTES.VIEW_ROUTE}/${viewRoute.id}`, {
          plannedDate: viewRoute.plannedDate,
          hubCode: viewRoute.hubCode,
          hubName: viewRoute.hubName,
          slots: viewRoute.deliverySlot || [],
          route: viewRoute,
          isExpress: isExpress,
          parentRoute: viewRoute?.routeType,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRoute, createRoute, viewRoute, isExpress])

  useEffect(() => {
    if (deletedRoute) {
      onLoadRef.current = false;
      openSnackbarPopup(`Route "${deletedRoute.id}" ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.DELETE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      routesFilterCallback(routesActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedRoute])

  useEffect(() => {
    if (bulkDeletedRoute && tabValue!== AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES) {
      onLoadRef.current = false;
      openSnackbarPopup(`Route(s) ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.DELETE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      routesFilterCallback(routesActiveFilters);
    }
  }, [bulkDeletedRoute])

  useEffect(() => {
    if (replanningConsignmentSuccess || isPolled ) {
      openSnackbarPopup(AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.REPLANNING_CONSIGNMENT_SUCCESS_MSG, AppConstants.SNACKBAR.TYPES.SUCCESS);
      setIsOrderSearched(false);
      ordersFilterCallback(ordersActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replanningConsignmentSuccess, isPolled])

  useEffect(() => {
    if (isAddressUpdated) {
      openSnackbarPopup(AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.ADDRESS_MARKED_UNVERIFIED, AppConstants.SNACKBAR.TYPES.SUCCESS);
      setIsOrderSearched(false);
      ordersFilterCallback(ordersActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressUpdated])

  useEffect(() => {
    if (publishedRoutesErrors.length) {
      setOpenPublishErrorMsgPopup(true);
    }
  }, [publishedRoutesErrors])

  useEffect(() => {
    if (isOrderSearched && isDisplayCount) {
      const chips = Object.keys(ordersFilterChipsDisplayCount.consignmentCount).filter((key: string) => ordersFilterChipsDisplayCount.consignmentCount[key] > 0);
      let selectedChip;
      let selectedChipValue;
      if (chips.length === 2) {
        selectedChip = chips.filter((key: string) => key !== AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      } else {
        selectedChip = chips.filter((key: string) => key === AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      }
      handleChipFilter({ code: chips.length ? chips[0] === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.CANCELLED && chips.length === 2 ? chips[1] : selectedChipValue : activeOrdersFilterChip }, ordersActiveFilters)
    } else {
      setOrdersListToShow(lastMilePlanningOrdersDataTransform(ordersData) as any);
      handleLoadMoreDisableState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersFilterChipsDisplayCount, ordersData, isExpress]);

  useEffect(() => {
    if (isRouteSearched) {
      const chips = Object.keys(routesFilterChipsDisplayCount.routCount).filter((key: string) => routesFilterChipsDisplayCount.routCount[key] > 0);
      handleChipFilter({ code: chips.length ? chips[0] : activeRoutesFilterChip }, routesActiveFilters, true);
    } else {
      setRoutesListToShow(transformDataForSubRoutes(routesData) as any);
      handleLoadMoreDisableState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesFilterChipsDisplayCount, routesData]);

  useEffect(() => {
    handleHeaderButtonsDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersListToShow, isExpress]);

  useEffect(() => {
    handleHeaderButtonsDisableState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesListToShow, selectedRoutes, isExpress]);

  useEffect(() => {    
    const ordersHeaderData = ordersTableWrapperData.HeaderData.map((header: any) => {
      if (header.filterObj) {
        if (header.filterObj.fieldName === 'hub') {
          header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
        }
        if (header.filterObj.fieldName === 'hubName') {
          header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
        }
        if (header.filterObj.fieldName === 'deliveryArea') {
          header.filterObj.items = ordersFiltersDropdowns.deliveryAreaList || [];
        }
        if (header.filterObj.fieldName === "addressStatus") {
            header.filterObj.items = ordersFiltersDropdowns.addressStatusList || [];
        }
        if (header.filterObj.fieldName === "deliveryZone") {
            header.filterObj.items = ordersFiltersDropdowns.deliveryZonesList || [];
         }
        if (header.filterObj.fieldName === "vendor") {
            header.filterObj.items = ordersFiltersDropdowns.vendorList || [];
        }
        if (header.filterObj.fieldName === 'orderType') {
          header.filterObj.items = ordersFiltersDropdowns.typeList || [];
        }
        if (header.filterObj.fieldName === 'deliverySlot') {
          header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
        }
        if (header.filterObj.fieldName === 'deliveryStatus') {
          header.filterObj.items = ordersFiltersDropdowns.statusList || [];
        }
        if (header.filterObj.fieldName === 'paymentType') {
          header.filterObj.items = ordersFiltersDropdowns.paymentTypeList || [];
        }
        if (header.filterObj.fieldName === 'carrier') {
          header.filterObj.items = ordersFiltersDropdowns.carrierList || [];
        }
        if (header.filterObj.fieldName === 'deliveryProposition') {
          header.filterObj.items = ordersFiltersDropdowns.deliveryPropositionList || [];
        }
        if (header.filterObj.fieldName === 'routeId') {
          header.filterObj.items = ordersFiltersDropdowns.routeIdList || [];
        }
        if (header.filterObj.fieldName === 'routeStatus') {
          header.filterObj.items = ordersFiltersDropdowns.routeStatusList || [];
        }
        if (header.filterObj.fieldName === 'driver') {
          header.filterObj.items = ordersFiltersDropdowns.driverList || [];
        }
        if (header.filterObj.fieldName === 'vehicle') {
          header.filterObj.items = ordersFiltersDropdowns.vehicleList || [];
        }
        if (header.filterObj.fieldName === 'helper') {
          header.filterObj.items = ordersFiltersDropdowns.helperList || [];
        }
        if (header.filterObj.fieldName === 'deliveryFailedReason') {
          header.filterObj.items = ordersFiltersDropdowns.undeliveredReasonList || [];
        }
        if (header.filterObj.fieldName === 'deliveryExecutionStatus') {
          header.filterObj.items = ordersFiltersDropdowns.executionStatusList || [];
        }
      }
      return header;
    });
    const routesHeaderData = routesTableWrapperData.HeaderData.map((header: any) => {
      if (header.filterObj) {
        if (header.filterObj.fieldName === 'hub') {
          header.filterObj.items = routesFiltersDropdowns.hubCodeList || [];
        }
        if (header.filterObj.fieldName === 'hubName') {
          header.filterObj.items = routesFiltersDropdowns.hubNameList || [];
        }
        if (header.filterObj.fieldName === 'deliverySlot') {
          header.filterObj.items = routesFiltersDropdowns.deliverySlotList || [];
        }
        if (header.filterObj.fieldName === 'routeId') {
          header.filterObj.items = routesFiltersDropdowns.routeIdList || [];
        }
        if (header.filterObj.fieldName === 'deliveryArea') {
          header.filterObj.items = routesFiltersDropdowns.deliveryAreaList || [];
        }
        if (header.filterObj.fieldName === 'carrier') {
          header.filterObj.items = routesFiltersDropdowns.carrierList || [];
        }
        if (header.filterObj.fieldName === 'deliveryProposition') {
          header.filterObj.items = routesFiltersDropdowns.deliveryPropositionList || [];
        }
        if (header.filterObj.fieldName === 'routeStatus') {
          header.filterObj.items = routesFiltersDropdowns.routeStatusList || [];
        }
        if (header.filterObj.fieldName === 'driver') {
          header.filterObj.items = routesFiltersDropdowns.driverList || [];
        }
        if (header.filterObj.fieldName === 'vehicle') {
          header.filterObj.items = routesFiltersDropdowns.vehicleList || [];
        }
        if (header.filterObj.fieldName === 'helper') {
          header.filterObj.items = routesFiltersDropdowns.helperList || [];
        }
        if (header.filterObj.fieldName === 'createdBy') {
          header.filterObj.items = routesFiltersDropdowns.createdByList || [];
        }
      }
      return header;
    });
    const hubsToAdd = hubCode.map((hub: string) => ({ name: hub, value: hub }));
    const globalHubApplied =  hubsToAdd.findIndex((p: { value: string; }) => p.value == AppConstants.SELECT_ALL.value);
    let updatedFilters: any = {};
    setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: ordersHeaderData });
    setRoutesTableWrapperData({ ...routesTableWrapperData, HeaderData: routesHeaderData });
    setActiveOrdersFilterChip(AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);
    if(navigatedFromPublish){
      setActiveRoutesFilterChip(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.PUBLISHED_FILTER_CHIP);
    }
    
    if (tabValue === AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.CONSIGNMENTS) {
      setIsOrderSearched(false);
      updatedFilters = { ...ordersActiveFilters, hub: { ...ordersActiveFilters.hub, value: globalHubApplied < 0 && hubsToAdd.length ? hubsToAdd : [] }  };
      if (!onLoadRef.current) {
        ordersFilterCallback(updatedFilters);
      }
    } else if (tabValue === AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES) {
      setIsRouteSearched(false);
      updatedFilters = { ...routesActiveFilters, hub: { ...routesActiveFilters.hub, value: globalHubApplied < 0 && hubsToAdd.length ? hubsToAdd : [] }  };
      if (!onLoadRef.current) {
        routesFilterCallback(updatedFilters);
      }
    } else {
      setHubForRoutes("");
      setLastTabClicked("");
      sessionStorage.setItem(AppConstants.PLANNING_CONSTANTS.HUB_FOR_ROUTES, "");
      const hubValueIndex = hubCode.findIndex((hub: string) => hub !== AppConstants.SELECT_ALL.value)
      if(hubValueIndex !== -1){
        const hubValue = hubCode[hubValueIndex];
        sessionStorage.setItem(AppConstants.PLANNING_CONSTANTS.HUB_FOR_ROUTES, hubValue);
        const hubName: any = getValueFromArray(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [], "value", hubValue);
        setHubForRoutes(hubValue);
        setHubNameForRoutes(hubName);
      } else {
        setIsOrderSearched(false);
        updatedFilters = { ...ordersActiveFilters, hub: { ...ordersActiveFilters.hub, value: globalHubApplied < 0 && hubsToAdd.length ? hubsToAdd : [] }  };
        if (!onLoadRef.current) {
          ordersFilterCallback(updatedFilters);
        }
        setTabValue(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.CONSIGNMENTS);
      }
    }
    onLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubCode, isExpress, navigatedFromPublish]);

  useEffect(() => {
    if (ordersTableWrapperData && ordersTableWrapperData.HeaderData) {
      let headerData = ordersTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === 'hub') {
            header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
          }
          if (header.filterObj.fieldName === 'hubName') {
            header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === 'deliveryArea') {
            header.filterObj.items = ordersFiltersDropdowns.deliveryAreaList || [];
          }
          if (header.filterObj.fieldName === "addressStatus") {
              header.filterObj.items = ordersFiltersDropdowns.addressStatusList || [];
           }
          if (header.filterObj.fieldName === "deliveryZone") {
               header.filterObj.items = ordersFiltersDropdowns.deliveryZonesList || [];
           }
          if (header.filterObj.fieldName === "vendor") {
            header.filterObj.items = ordersFiltersDropdowns.vendorList || [];
          }
          if (header.filterObj.fieldName === 'orderType') {
            header.filterObj.items = ordersFiltersDropdowns.typeList || [];
          }
          if (header.filterObj.fieldName === 'deliverySlot') {
            header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === 'deliveryStatus') {
            header.filterObj.items = ordersFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === 'paymentType') {
            header.filterObj.items = ordersFiltersDropdowns.paymentTypeList || [];
          }
          if (header.filterObj.fieldName === 'carrier') {
            header.filterObj.items = ordersFiltersDropdowns.carrierList || [];
          }
          if (header.filterObj.fieldName === 'deliveryProposition') {
            header.filterObj.items = ordersFiltersDropdowns.deliveryPropositionList || [];
          }
          if (header.filterObj.fieldName === 'routeId') {
            header.filterObj.items = ordersFiltersDropdowns.routeIdList || [];
          }
          if (header.filterObj.fieldName === 'routeStatus') {
            header.filterObj.items = ordersFiltersDropdowns.routeStatusList || [];
          }
          if (header.filterObj.fieldName === 'driver') {
            header.filterObj.items = ordersFiltersDropdowns.driverList || [];
          }
          if (header.filterObj.fieldName === 'vehicle') {
            header.filterObj.items = ordersFiltersDropdowns.vehicleList || [];
          }
          if (header.filterObj.fieldName === 'helper') {
            header.filterObj.items = ordersFiltersDropdowns.helperList || [];
          }
          if (header.filterObj.fieldName === 'deliveryFailedReason') {
            header.filterObj.items = ordersFiltersDropdowns.undeliveredReasonList || [];
          }
          if (header.filterObj.fieldName === 'deliveryExecutionStatus') {
            header.filterObj.items = ordersFiltersDropdowns.executionStatusList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: headerData });
    }
    if (routesTableWrapperData && routesTableWrapperData.HeaderData) {
      let headerData = routesTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === 'hub') {
            header.filterObj.items = routesFiltersDropdowns.hubCodeList || [];
          }
          if (header.filterObj.fieldName === 'hubName') {
            header.filterObj.items = routesFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === 'deliverySlot') {
            header.filterObj.items = routesFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === 'routeId') {
            header.filterObj.items = routesFiltersDropdowns.routeIdList || [];
          }
          if (header.filterObj.fieldName === 'deliveryArea') {
            header.filterObj.items = routesFiltersDropdowns.deliveryAreaList || [];
          }
          if (header.filterObj.fieldName === 'carrier') {
            header.filterObj.items = routesFiltersDropdowns.carrierList || [];
          }
          if (header.filterObj.fieldName === 'deliveryProposition') {
            header.filterObj.items = routesFiltersDropdowns.deliveryPropositionList || [];
          }
          if (header.filterObj.fieldName === 'routeStatus') {
            header.filterObj.items = routesFiltersDropdowns.routeStatusList || [];
          }
          if (header.filterObj.fieldName === 'driver') {
            header.filterObj.items = routesFiltersDropdowns.driverList || [];
          }
          if (header.filterObj.fieldName === 'vehicle') {
            header.filterObj.items = routesFiltersDropdowns.vehicleList || [];
          }
          if (header.filterObj.fieldName === 'helper') {
            header.filterObj.items = routesFiltersDropdowns.helperList || [];
          }
          if (header.filterObj.fieldName === 'createdBy') {
            header.filterObj.items = routesFiltersDropdowns.createdByList || [];
          }
        }
        return header;
      });
      setRoutesTableWrapperData({ ...routesTableWrapperData, HeaderData: headerData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersFiltersDropdowns, routesFiltersDropdowns, isExpress]);

  useEffect(() => {
    handleHeaderButtonsHideState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrdersFilterChip, isExpress]);

  useEffect(() => {
    handleHeaderButtonsHideState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoutesFilterChip]);

  useEffect( () => () => {
    if(isNonEmptyObject(persitedRouteData)){
      persistState.remove(AppConstants.PLANNING_CONSTANTS.ROUTE_DATA_PERSIST)
    }

  }, [persitedRouteData] );

  useEffect(() => {
    if (countryCode === AppConstants.COUNTRY_KENYA) {
      const ordersHeaderData = ordersTableWrapperData.HeaderData.map((header: any) => {
        let newHeader = { ...header };
        if (newHeader.field === 'paybill') {
          newHeader.hide = false
        }
        if (newHeader.field === 'mpesaphoneNumber') {
          newHeader.hide = false
        }
        if (newHeader.field === 'refNo') {
          newHeader.hide = false
        }
        if (newHeader.field === 'mpesaAttemptNo') {
          newHeader.hide = false
        }
        if (newHeader.field === 'mpesaStatus') {
          newHeader.hide = false
        }
        if (newHeader.field === 'requestedAmt') {
          newHeader.hide = false
        }
        if (newHeader.field === 'timestamp') {
          newHeader.hide = false
        }
        return newHeader;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: ordersHeaderData });
    } else {
      const ordersHeaderData = ordersTableWrapperData.HeaderData.map((header: any) => {
        let newHeader = { ...header };
        if (newHeader.field === 'paybill') {
          newHeader.hide = true
        }
        if (newHeader.field === 'mpesaphoneNumber') {
          newHeader.hide = true
        }
        if (newHeader.field === 'refNo') {
          newHeader.hide = true
        }
        if (newHeader.field === 'mpesaAttemptNo') {
          newHeader.hide = true
        }
        if (newHeader.field === 'mpesaStatus') {
          newHeader.hide = true
        }
        if (newHeader.field === 'requestedAmt') {
          newHeader.hide = true
        }
        if (newHeader.field === 'timestamp') {
          newHeader.hide = true
        }
        return newHeader;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: ordersHeaderData });
    }
    if (isLastMileViewer || isOnlyStdLastMileViewer) {
      const routesHeaderData = routesTableWrapperData.HeaderData.map((header: any) => {
        let newHeader = {...header};
        if (newHeader.field === "actions") {
          newHeader.hide = true;
        }
        return newHeader;
      });
      setRoutesTableWrapperData({...routesTableWrapperData, HeaderData: routesHeaderData});
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  useEffect(() => {
    if (location.pathname === AppConstants.ROUTES.EXPRESS) {
      setIsExpress(true);
    } else {
      setIsExpress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isExpress]);


const handleTimerInstances = (id: any) => {
  Countdown.startCountdown(id, TIMER_CONST.globalTimeForRefresh);
  setShowTime(true);
};

  const handleChangeAllRoutesHub = () => {
    setTabValue(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.CONSIGNMENTS);
    setLastTabClicked(AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES);
    setOpenCreateRouteHubSelectionPopup(true);
  };

  return (
    <div className={componentClasses.root}>
      {loading && <Loader></Loader>}
      <Grid className={componentClasses.container} container>
        <Grid className="planningItem content" item>
          <TabContext value={tabValue}>
            <Tabs TabIndicatorProps={{ style: { backgroundColor: "#1F1F1F" } }} value={tabValue} aria-label="Consignments/Routes Navigation Tabs" style={{ paddingLeft: "20px", paddingTop: "8px", lineHeight: "24px", minHeight: 0, backgroundColor: "rgba(244, 244, 244, 0.6)", paddingBottom: "16px" }} onChange={handleTabChange} >
              <Tab label="Consignments" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.CONSIGNMENTS} />
              <Tab label="Routes" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES} />
              <Tab label="All Routes" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES} />
            </Tabs>
            <TabPanel className="tabPanel" value={AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.CONSIGNMENTS}>
              <Grid container className={componentClasses.tabPanelContainer}>
                <Grid item className="panelItem chipFilter">
                  <Grid container className="chipsContainer">
                    {
                      getFilterChips()
                    }
                  </Grid>
                </Grid>
                <Grid item className="panelItem gridWrapper">
                  <GridWrapper
                    showHeader={false}
                    checkboxSelection={false}
                    headerData={ordersTableWrapperData.HeaderData}
                    rowData={ordersListToShow}
                    isTabChange={isTabChange}
                    loading={loading ? false : tableLoading}
                    searchTypes={ordersTableWrapperData.SEARCH_TYPES}
                    defaultFilter={ordersTableWrapperData.defaultFiltersObj}
                    activeFilter={ordersActiveFilters}
                    headerButtons={ordersTableWrapperData.headerButtons}
                    title={ordersTableWrapperData.title}
                    disableSelectionOnClick={true}
                    sortModel={ordersTableWrapperData.sortModel as SortModel}
                    searchValue={ordersSearchValue}
                    loadMore={{ ...ordersTableWrapperData.loadMore, rowCount: ordersData.totalElements || 0 }}
                    headerBtnClick={handleOrdersHeaderButtonClick}
                    searchCallback={ordersSearchFilterHandler}
                    onCellClick={handleConsignmentCellClick}
                    filterGrid={ordersFilterCallback}
                    onRowClick={handleRowClick}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel className="tabPanel routes" value={AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES}>
              <Grid container className={componentClasses.tabPanelContainer}>
                <Grid item className="panelItem chipFilter">
                  <Grid container className="chipsContainer">
                    {
                      getFilterChips(true)
                    }
                  </Grid>
                </Grid>
                <Grid item className="panelItem gridWrapper">
                  <GridWrapper
                    showHeader={false}
                    checkboxSelection={!(isLastMileViewer || isOnlyStdLastMileViewer)}
                    headerData={routesTableWrapperData.HeaderData}
                    rowData={routesListToShow}
                    isTabChange={isTabChange}
                    loading={loading ? false : tableLoading}
                    searchTypes={routesTableWrapperData.SEARCH_TYPES}
                    defaultFilter={routesTableWrapperData.defaultFiltersObj}
                    activeFilter={routesActiveFilters}
                    headerButtons={routesTableWrapperData.headerButtons}
                    title={routesTableWrapperData.title}
                    disableSelectionOnClick={true}
                    sortModel={routesTableWrapperData.sortModel as SortModel}
                    searchValue={routesSearchValue}
                    loadMore={{ ...routesTableWrapperData.loadMore, rowCount: routesData.totalElements || 0 }}
                    onSelectionChange={updateSelectedRoutes}
                    headerBtnClick={handleRoutesHeaderButtonClick}
                    onCellClick={handleRouteCellClick}
                    searchCallback={routesSearchFilterHandler}
                    filterGrid={routesFilterCallback}
                    reselctSelectedCheckbox={routeState.reselctSelectedCheckbox}
                  />
                </Grid>
              </Grid>
              {
                openPublishErrorMsgPopup &&
                <Grid container className={componentClasses.publishErrorPopupContainer}>
                  <Grid item className="popupItem titleBlock">
                    <Grid container className="titleContainer">
                      <Grid item className="titleItem">
                        <Typography className="title">{`${publishedRoutesErrors.length} Route(s) ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.PUBLISH_ROUTES_ERROR_MSG}`}</Typography>
                      </Grid>
                      <Grid item className="titleItem">
                        <IconButton aria-label="close" className="closeBtn" onClick={handlePublishErrorPopupClose}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="popupItem contentBlock">
                    <Grid container className="contentContainer">
                      {getPublishedErrorContentItems()}
                    </Grid>
                  </Grid>
                </Grid>
              }
            </TabPanel>
            <TabPanel className="tabPanel allRoutes" value={AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ALLROUTES}>
              <Grid container className={componentClasses.tabPanelContainer}>
               <AllRoutes hubId={hubForRoutes} hubName={hubNameForRoutes} viewPublishRoute={viewPublishRoute} handleChangeAllRoutesHub={handleChangeAllRoutesHub} />
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
        {openConsignmentDetailsPopup && <ConsignmentDetails isExpress={isExpress} open={openConsignmentDetailsPopup} consignment={consignmentData} closePopup={closeConsignmentDetailsPopup} handleTimerInstances={handleTimerInstances} timerStarted={showTime} setShowTime={setShowTime}></ConsignmentDetails> }
        {openPublishDialog && <CustomDialog
          open={openPublishDialog}
          PaperProps={publishDialogPaperProps}
          title={getPublishDialogTitleContent()}
          content={getPublishDialogDetailsContent()}
          actions={getPublishDialogActions()}
          handleClose={handlePublishDialogClose}
        ></CustomDialog>}
        <CustomDialog
          open={openDeleteDialog}
          PaperProps={deleteDialogPaperProps}
          title={getDeleteDialogTitleContent()}
          content={getDeleteDialogDetailsContent()}
          actions={getDeleteDialogActions()}
          handleClose={handleDeleteDialogClose}
        ></CustomDialog>
        <CustomDialog
          open={openCreateRouteHubSelectionPopup}
          PaperProps={createRouteHubSelectionDialogPaperProps}
          title={getHubSelectionDialogTitleContent()}
          content={getHubSelectionDialogDetailsContent()}
          actions={getHubSelectionDialogActions()}
          handleClose={handleHubSelectionDialogClose}
        ></CustomDialog>
        {!error && renderExcelProgress ? (
          <Grid container className={componentClasses.excelDownloadStatus}>
            {excelLoading ? (
              <Grid item>
                <Typography className={componentClasses.excelDownloadStatusText}>
                  Your files are getting downloaded
                </Typography>
              </Grid>) : (
                <Grid item>
                  <Typography className={componentClasses.excelDownloadStatusText}>
                    Your files have been downloaded
                  </Typography>
                </Grid>
              )}
            {excelLoading ? <Loader></Loader> :
              <Grid item style={{ display: "flex" }}>
                <SvgIcon className={componentClasses.checkedIconStyle} component={checkIcon} />
                <IconButton aria-label="close" className={componentClasses.close} onClick={handleProgressClose}>
                  <CloseIcon className={componentClasses.closeIcon} />
                </IconButton>
              </Grid>
            }
          </Grid>) : (
            exportError === "INTERNAL_SERVER_ERROR" && renderExcelProgress ? (
              <Grid container className={componentClasses.excelDownloadError}>
                <Grid item>
                  <Typography className={componentClasses.excelDownloadStatusText}>
                    Error Downloading the file. Try Again!!
              </Typography>
                </Grid>
                <Grid item style={{ display: "flex" }}>
                  <IconButton aria-label="close" className={componentClasses.close} onClick={handleProgressClose}>
                    <CloseIcon className={componentClasses.closeIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null)}

        <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
      </Grid>
    </div >
  );
};

export default RoutePlanning;