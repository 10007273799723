import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import SelectBox, { SelectItemProps } from "../../common/SelectBox";
import TextBox from "../../common/TextBox";
import AppConstants from "../../constants";
import CustomSnackbar from '../../common/CustomSnackbar';
//common components

import CustomDialog from "../../common/Dialog";
import {
  fetchCommunicationReason,
  fetchCommunicationReasonsExpress,
  resetData,
  sendCommunication,
  sendCommunicationExpress
} from "../redux/customerCommSlice";

//assets
import { AppState } from "../../config/redux/reducers";
//Material UI Components
import { Grid, Typography, Button } from "@material-ui/core";
import { useFormik } from 'formik';
import * as yup from 'yup';
//styles
import { useStyles } from "./sendComm.styles";

interface CODUploadReceiptProps {
  open: boolean;
  closePopup: () => void;
  selectedValue: any;
  changeTabAfterSuccess:(orderType:string) => void
  orderType: string;
}

interface formikValues {
  reason: string;
  comments: string 
}


const SendCommunication = (props: CODUploadReceiptProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, closePopup, selectedValue, changeTabAfterSuccess, orderType } = props;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedReason, setSelectedReason] = useState<SelectItemProps>(AppConstants.SELECT_NONE);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [commentsValue, setCommentsValue] = useState("");
  const { reasonsDropdownList, error, success } = useSelector(
    (state: AppState) => state.customerCommunication
  );

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot,
    },
  };

  const dialogPaperPropsConfirm = {
    classes: {
      root: classes.dialogPaperPropsConfirmRoot,
    },
  };

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const validationSchemaComment = yup.object({
    reason: yup
      .string()
      .required(AppConstants.EXPRESS_CONSTANTS.VALIDTION_ADDITIONAL_COMMENT.REASON.REQUIRED.msg)
      .test(
        "empty string test",
        AppConstants.EXPRESS_CONSTANTS.VALIDTION_ADDITIONAL_COMMENT.REASON.REQUIRED.msg,
        (val?:string) => {
          return val?.trim() !== AppConstants.SELECT_NONE.value;
        }
      ),
      comments: yup
      .string().when('reason', {
        is: (reason:string) => reason === 'CR06', 
        then: yup.string().required(AppConstants.EXPRESS_CONSTANTS.VALIDTION_ADDITIONAL_COMMENT.COMMENT.REQUIRED.msg),
    })
  });

  const inputFormik = useFormik({
    initialValues: {
      reason: AppConstants.SELECT_NONE.value,
      comments: '',
    },
    validationSchema: validationSchemaComment,
    onSubmit: (values) => {
      let res = reasonsDropdownList.filter(
        (e: { value: string }) => e.value === values.reason
      );
     
      handleCommunicationSubmit(values, res)
    }
  });

  const handleCommunicationSubmit = (values:formikValues, res: Array<SelectItemProps>) => {
    setOpenConfirm(true)
    if (res.length) {
      setSelectedReason(res[0]);
    }
    if(values.comments){
      setCommentsValue(values.comments);
    }
  }

  const handleConfirmSubmit = useCallback(() => {
    let result = selectedValue.map(
      (a: { consignmentId: any }) => a.consignmentId
    );
    const payload = {
      reason: {
        reasonCode: selectedReason.value,
        reasonDescription: selectedReason.name,
        comment: commentsValue
      },
      consignmentIds: result,
    };
    if(orderType === AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.ORDER_TYPE.EXPRESS){
      dispatch(sendCommunicationExpress({ params: payload }));
    }
    else if(orderType === AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.ORDER_TYPE.STANDARD){
      dispatch(sendCommunication({ params: payload }));
    }
  }, [dispatch, selectedValue, selectedReason, commentsValue]);

  const fetchAllDeliveryReasons = useCallback(() => {
    if(orderType === AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.ORDER_TYPE.EXPRESS){
      const payload= {
        "type": "COMMUNICATION"
      }
      dispatch(fetchCommunicationReasonsExpress({params: payload}));
    }
    else if(orderType === AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.ORDER_TYPE.STANDARD){
      dispatch(fetchCommunicationReason());
    }
   
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      fetchAllDeliveryReasons();
    }
  }, [open]);

  useEffect(() => {
    if (success) {
      openSnackbarPopup(success, AppConstants.SNACKBAR.TYPES.SUCCESS);
      setOpenConfirm(false)
      inputFormik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleDialogClose = () => {
    closePopup();
    inputFormik.resetForm()
  };

  const handleDialogCloseConfirm=() =>{
    setOpenConfirm(false)
  }

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
      if(success){
        changeTabAfterSuccess(orderType)
        dispatch(resetData())
      }
    },
    [dispatch, success],
  )

  const getTitleContent = () => {
    return (
      <Typography className={classes.commDialogTitle}>
        Selecting a reason 
      </Typography>
    );
  };

  const getTitleConfirm = () => {
    return (
      <Typography className={`${classes.commDialogTitle} ${classes.containerCenter}`  }>
      Please confirm submit
      </Typography>
    );
  };

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleOpenSnackbarOnEnter = useCallback(
    (node: HTMLElement, isAppearing: boolean) => {
            if (snackbarType.current === AppConstants.SNACKBAR.TYPES.ERROR && !error) {
        setOpenSnackbar(false);
      }
    },
    [error]
  );
  

  const getDetailsContentConfirm = () => {
    return (
      <div className={`${classes.container} ${classes.containerCenter}`  }>
        <Grid container className={classes.contentItemConfirm}>
          <Grid container>
           <Typography>{`Are you sure you want to send a delayed delivery notification to ${selectedValue.length} consignment(s) with ${selectedReason.name}`}</Typography>
         
            <Grid className={classes.buttonsGroup} item>
              <Button className="dialogBtn secondary"  variant="contained" onClick={()=>setOpenConfirm(false)}>{AppConstants.BUTTONS.CANCEL}</Button>
              <Button
                className="dialogBtn primary"
                variant="contained"
                onClick={() => handleConfirmSubmit()}
              >
                {AppConstants.BUTTONS.CONFIRM}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getDetailsContent = () => {
    return (
            <div className={classes.container}>
     <form onSubmit={inputFormik.handleSubmit}>
        <Grid container className={classes.contentItem}>
          <Grid container>
            <Grid item className="dropDownContainer"  xs={12}>
              <SelectBox
               
                value={inputFormik.values.reason}
                error={inputFormik.touched.reason && Boolean(inputFormik.errors.reason)}
                helperText={inputFormik.touched.reason && inputFormik.errors.reason}
                id="EditCommunicationReason"
                inputProps={{
                  name: "reason",
                  id: "EditCommunicationReason",
                }}
                handleFormikChange={inputFormik.handleChange}
                items={reasonsDropdownList || []}
                label={"Select the reason for delay"}
              ></SelectBox>
            </Grid>
            <Grid container  className="commentContainer" >
              <Grid className="itemContainer" container >
                <Grid className="itemInput" item xs={12}>
                                    <TextBox
                  fullWidth
                  multiline
                  variant="outlined"
                  rows={4}
                  label={"Add Comments"}
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.comments}
                  error={inputFormik.touched.comments && Boolean(inputFormik.errors.comments)}
                  helperText={inputFormik.touched.comments && inputFormik.errors.comments}
                  textBoxId="additionalInfo"
                  name="comments"
                  type="text"
                  placeholderText='Add a comment'
                ></TextBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.buttonsGroup} item>
              <Button className="dialogBtn secondary" variant="contained" onClick={()=>handleDialogClose()}>{AppConstants.BUTTONS.BACK}</Button>
              <Button
                variant="contained"
                className={"dialogBtn primary"}
                type="submit"
              >
                {AppConstants.BUTTONS.SEND_COMM}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        </form>
      </div>
    );
  };

  return (
    <div>
      <CustomDialog
        open={open}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        handleClose={handleDialogClose}
      ></CustomDialog>
      <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onEnter={handleOpenSnackbarOnEnter} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
      <CustomDialog
        open={openConfirm}
        PaperProps={dialogPaperPropsConfirm}
        title={getTitleConfirm()}
        content={getDetailsContentConfirm()}
        handleClose={handleDialogCloseConfirm}
      ></CustomDialog>
    </div>
  );
};

export default SendCommunication;
