import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.primary.main,
      height: "100vh",
      '& .mainItem': {
        margin: "auto",
        backgroundColor: COLORS.WHITE,
        width: 736
      }
    },
    loginContainer: {
      padding: theme.spacing(8.37),
      '& .logoContainer': {
        alignItems: "center",
        paddingBottom: theme.spacing(4.5),
        '& .logo': {
          width: 350,
          height: 50
        }
      },
      '& .buttonsContainer': {
        '& .btnItem': {
          paddingBottom: theme.spacing(2),
          display: 'flex',
          '&:last-child': {
            paddingBottom: theme.spacing(0)
          },
          '& .loginBtn': {
            width: "100%",
            height: 55,
            margin: theme.spacing(0, 0.5, 0, 0.5),
          },
          '&.forgotPwdItem': {
            alignSelf: "flex-end"
          },
          '&.newUserItem': {
            alignSelf: "center"
          },
          '& .forgotPwdBtn': {
            cursor: "pointer",
            fontSize: theme.spacing(1.75),
            color: theme.palette.primary.main
          }
        }
      },
      '& .formContainer': {
        '& .formItem': {
          '& .formTitle': {
            fontSize: theme.spacing(3),
            fontWeight: 600
          },
          '& .MuiFormControl-root': {
            margin: theme.spacing(0),
            width: "100%"
          },
          '& .MuiOutlinedInput-root': {
            height: 48
          }
        }
      },
      '& .loginItem': {
        padding: theme.spacing(1.5, 0),
        '&:first-child': {
          paddingTop: theme.spacing(0)
        },
        '&:last-child': {
          paddingBottom: theme.spacing(0)
        },
        '& .formContainer': {
          '& .formItem': {
            paddingBottom: theme.spacing(3),
            '&:last-child': {
              paddingBottom: theme.spacing(0)
            }
          }
        }
      },
      '& .registerItem': {
        padding: theme.spacing(1.5, 0),
        '&:first-child': {
          paddingTop: theme.spacing(0)
        },
        '&:last-child': {
          paddingBottom: theme.spacing(0)
        },
        '& .formContainer': {
          flexWrap: "nowrap",
          '& .formItemGroup': {
            paddingBottom: theme.spacing(2),
            '&:first-child': {
              paddingBottom: theme.spacing(3)
            },
            '&:last-child': {
              paddingBottom: theme.spacing(0)
            },
            '& .formItem': {
              flexGrow: 1,
              maxWidth: "50%",
              '&.left': {
                paddingRight: theme.spacing(2.5)
              },
              '&.right': {
                paddingLeft: theme.spacing(2.5)
              }
            }
          }
        }
      }
    }
  }),
);

