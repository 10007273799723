import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";
import { relative } from "path";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            //padding: theme.spacing(2),
            height: "100%",
            
          
        },
        buttonsGroup:{
            marginLeft: 'auto',
            marginRight: 12,
            position: "relative",
            bottom:14,
            '& .MuiButton-contained': {
               borderRadius: 18,
               fontSize:12,
               margin: 4
            }
            
        },
        noData:{
          marginTop: 12
        },
        historyContent:{
            height: "calc(100vh - 176px) !important"
        },
        filterSection:{
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06) !important",
            margin: 8,
            padding: "18px 24px",
            width: "auto",
            marginBottom: 24 
        },
        customerCommunication:{
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06) !important",
            margin: 8,
            width: "auto",
            minHeight: 200,
            height: "60vh",
            '& .makeStyles-tableSection-47':{
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06) !important",
                margin: 8,
                padding: "18px 12px",
                width: "auto"
            },
            '& .makeStyles-filterSection-43':{
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06) !important",
                margin: 8,
                padding: "18px 12px",
            },
            '& .customButton':{
              backgroundColor: "unset" ,
              color: COLORS.PRIMARY_MAIN,
              borderRadius:16,
              border:`2px solid ${COLORS.PRIMARY_MAIN}`,
              '&:hover': {
                backgroundColor: "unset"
              },
              '&::after': {
                display: "inline-block",
                content: '"X"',
                textIndent: 8
              }
            }
        },
        infoSection: {
            display: "flex",
            alignItems: "center"
        },
        infoIconStyle: {
            height: "12px",
            width: "12px",
            marginLeft: "6px"
        },
        statusHeaderContainer: {
            '& .tag': {
                color: COLORS.WHITE,
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(0.5, 1),
                lineHeight: "14px",
                backgroundColor: COLORS.BLACK,
                fontWeight: 600,
                textTransform: "uppercase",
                fontSize: 12,
                '&.INFO_BLUE': {
                    backgroundColor: COLORS.INFO_BLUE
                },
                '&.READY_BLUE': {
                    backgroundColor: COLORS.READY_BLUE
                },
                '&.MINSK': {
                    backgroundColor: COLORS.MINSK
                },
                '&.PRIMARY_BLUE': {
                    backgroundColor: COLORS.PRIMARY_MAIN
                },
                '&.JUNGLE_GREEN': {
                    backgroundColor: COLORS.JUNGLE_GREEN
                },
                '&.PARTIAL_SUCCESS_GREEN': {
                    backgroundColor: COLORS.PARTIAL_SUCCESS_GREEN
                },
                '&.ERROR_RED': {
                    backgroundColor: COLORS.PALE_RED
                },
                '&.PALE_GRAY': {
                    backgroundColor: COLORS.PALE_GRAY
                },
                '&.ALERT_ORANGE': {
                    backgroundColor: COLORS.ALERT_ORANGE
                },
                '&.PICTON_BLUE': {
                    backgroundColor: COLORS.PICTON_BLUE
                  },
                  '&.CREAM_CAN': {
                    backgroundColor: COLORS.CREAM_CAN
                  },
                  '&.VISTA_BLUE': {
                    backgroundColor: COLORS.VISTA_BLUE
                  },
                  '&.SEA_GREEN': {
                    backgroundColor: COLORS.SEA_GREEN
                  },
                  '&.GOLDEN_BELL': {
                    backgroundColor: COLORS.GOLDEN_BELL
                  },
                  '&.SCAMPI': {
                    backgroundColor: COLORS.SCAMPI
                  },
                  '&.BLUE': {
                    backgroundColor: COLORS.PRIMARY_MAIN
                  },
                  '&.SEA_GREEN2': {
                    backgroundColor: COLORS.SEA_GREEN2
                  },
                  '&.LUCKY': {
                    backgroundColor: COLORS.LUCKY
                  },
                  '&.DUSTY_GRAY': {
                    backgroundColor: COLORS.DUSTY_GRAY
                  },
                  '&.BLACK': {
                    backgroundColor: COLORS.BLACK
                  },
                  '&.TROPICAL_BLUE': {
                    backgroundColor: COLORS.TROPICAL_BLUE,
                    color: COLORS.ASTRAL
                  },
                  '&.CATSKILL_WHITE': {
                    backgroundColor: COLORS.CATSKILL_WHITE,
                    color: COLORS.ASTRAL
                  },
                  '&.BLUE_HAZE': {
                    backgroundColor: COLORS.BLUE_HAZE,
                    color: COLORS.SCAMPI
                  },
                  '&.BARLEY_WHITE': {
                    backgroundColor: COLORS.BARLEY_WHITE,
                    color: COLORS.OLD_GOLD
                  },
                  '&.DOUBLE_PEARL_LUSTA': {
                    backgroundColor: COLORS.DOUBLE_PEARL_LUSTA,
                    color: COLORS.PIMPKIN_SKIN
                  },
                  '&.JAGGED_ICE': {
                    backgroundColor: COLORS.JAGGED_ICE,
                    color: COLORS.BLUE_STONE
                  },
                  '&.TARA': {
                    backgroundColor: COLORS.TARA,
                    color: COLORS.OCEAN_GREEN
                  },
                  '&.JAGGED_ICE_PREPARED': {
                    backgroundColor: COLORS.JAGGED_ICE,
                    color: COLORS.JUNGLE_GREEN
                  },
                  '&.ALTO': {
                    backgroundColor: COLORS.ALTO,
                    color: COLORS.DUSTY_GRAY_LIGHT
                  }
            },
            '& .orderTag': {
                background: "#FFFFFF",
                border: "1px solid #319E60",
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(0.5, 1),
                fontWeight: 600,
                fontSize: 12,
                color: "#319E60",
                lineHeight: "14px",
                height: "24px",
                textTransform: "uppercase",
                '&.GOLDEN_BELL': {
                    color: COLORS.GOLDEN_BELL,
                    borderColor: COLORS.GOLDEN_BELL
                },
                '&.MINSK': {
                    color: COLORS.SCAMPI,
                    borderColor: COLORS.SCAMPI
                },
                '&.READY_BLUE': {
                    color: COLORS.READY_BLUE,
                    borderColor: COLORS.READY_BLUE
                },
                '&.SCAMPI': {
                    color: COLORS.SCAMPI,
                    borderColor: COLORS.SCAMPI
                },
                '&.INFO_BLUE': {
                    color: COLORS.INFO_BLUE,
                    borderColor: COLORS.INFO_BLUE
                },
                '&.MINSK_DARK': {
                    color: COLORS.MINSK_DARK,
                    borderColor: COLORS.MINSK_DARK
                },
                '&.DRIFT_WOOD': {
                    color: COLORS.DRIFT_WOOD,
                    borderColor: COLORS.DRIFT_WOOD
                },
                '&.TAG_YEllOW': {
                    color: COLORS.TAG_YEllOW,
                    borderColor: COLORS.TAG_YEllOW
                },
                '&.PERSIAN_GREEN_DARK': {
                    color: COLORS.PERSIAN_GREEN_DARK,
                    borderColor: COLORS.PERSIAN_GREEN_DARK
                },
                '&.PARTIAL_SUCCESS_GREEN': {
                    color: COLORS.PARTIAL_SUCCESS_GREEN,
                    borderColor: COLORS.PARTIAL_SUCCESS_GREEN
                },
                '&.SILVER_CHALICE2': {
                    color: COLORS.SILVER_CHALICE2,
                    borderColor: COLORS.SILVER_CHALICE2
                },
                '&.PALE_RED': {
                    color: COLORS.PALE_RED,
                    borderColor: COLORS.PALE_RED
                },
                '&.GREEN_BLUE': {
                    color: COLORS.GREEN_BLUE,
                    borderColor: COLORS.GREEN_BLUE
                },
                '&.MAROON': {
                    color: COLORS.MAROON,
                    borderColor: COLORS.MAROON
                }
            }
        },
       
    }),
);
