const HubDashboardConstants = {
    HUB_DASHBOARD_CONSTANTS: {
        HUBS_LIST: [
            { hubName: "Carrefour Shindagha City Center", consignments: "7832", onTimeDelivery: "223", onTimeHandover: "342", ordersAtRisk: "45" },
            { hubName: "Carrefour Shindagha City Center", consignments: "732", onTimeDelivery: "12", onTimeHandover: "142", ordersAtRisk: "25" },
            { hubName: "Carrefour Shindagha City Center", consignments: "32", onTimeDelivery: "234", onTimeHandover: "23", ordersAtRisk: "35" },
            { hubName: "Carrefour Shindagha City Center", consignments: "932", onTimeDelivery: "156", onTimeHandover: "144", ordersAtRisk: "65" },
            { hubName: "Carrefour Shindagha City Center", consignments: "188", onTimeDelivery: "123", onTimeHandover: "155", ordersAtRisk: "44" },
            { hubName: "Carrefour Shindagha City Center", consignments: "200", onTimeDelivery: "111", onTimeHandover: "166", ordersAtRisk: "41" },
            { hubName: "Carrefour Shindagha City Center", consignments: "300", onTimeDelivery: "112", onTimeHandover: "177", ordersAtRisk: "48" },
            { hubName: "Carrefour Shindagha City Center", consignments: "416", onTimeDelivery: "100", onTimeHandover: "188", ordersAtRisk: "09" },
            { hubName: "Carrefour Shindagha City Center", consignments: "090", onTimeDelivery: "180", onTimeHandover: "199", ordersAtRisk: "56" },
            { hubName: "Carrefour Shindagha City Center", consignments: "890", onTimeDelivery: "130", onTimeHandover: "101", ordersAtRisk: "87" },
        ]
    },
}

export default HubDashboardConstants;
