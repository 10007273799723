import { Button, Grid} from '@material-ui/core';
import React,  { useCallback, useState }  from 'react';
import Filter from '../FilterForm';
import AppConstants from "../../constants";
import { useStyles } from './FilterForm.styles';

const FilterFormWrapper = (props: any) => {
  const classes = useStyles();
  const {
    filterForm,
    defaultValues,
    handleFormFilters,
    fetchDataCallback,
    diableClick,
    resetAllFilter,
    setResetAllFlag,
  } = props;
  const [filters, setFilters] = useState(defaultValues);
  
  const updateFilter = useCallback(
    (filter: any) => {
      setFilters((filters: any) => ({
        ...filters,
        [filter.key]: filter.value,
      }));
      setResetAllFlag(false);
    },
    []
  );

  return (
    <Grid className={classes.filterFormWrapper} container>
      {filterForm.length
        ? filterForm.map((filter: any) => (
            <Filter
              key={filter.key}
              filter={filter}
              defaultValues={defaultValues}
              initialValues={{
                name: `Select ${filter.label}`,
                value: AppConstants.SELECT_NONE.value,
              }}
              fetchDataCallback={fetchDataCallback}
              updateFilter={updateFilter}
              resetAllFilter={resetAllFilter}
            />
          ))
        : null}
      <Grid className={classes.buttonsFilterForm} item>
        <Button
          variant="contained"
          className={`${diableClick && "disableButton"}`}
          disabled={diableClick}
          onClick={() => handleFormFilters(filters)}
        >
          {AppConstants.BUTTONS.SHOW_ORDERS}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterFormWrapper;
