import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CF_iframe: {
      "& iframe": {
        position: "absolute",
        left: "-15px",
        width: "100%",
        height: "92%",
      },
    },
  })
);