import { boolean } from "yup";
import AppConstants from "../constants";
import { TAB_VALUES_CONSTANT } from "./DelveDashboardConstant";

export interface DELVE_ORDER_LIST_STATE_INTERFACE {
  loading: boolean;
  openSnackbar: boolean;
  tabValue: string;
  consignmentData: any;
  openConsignmentDetailsPopup: boolean;
  activeOrdersFilterChip: string;
  ordersSearchValue: string;
}

export const DELVE_ORDER_LIST_STATE: DELVE_ORDER_LIST_STATE_INTERFACE = {
  loading: false,
  openSnackbar: false,
  tabValue: TAB_VALUES_CONSTANT.DELIVERIES.value,
  consignmentData: {},
  openConsignmentDetailsPopup: false,
  activeOrdersFilterChip: AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.ALL_FILTER_CHIP,
  ordersSearchValue: "",
};

export interface CUSTOMER_CONTACT_INFO_ADDRESS_INTERFACE {
  addressId: string;
  apartment: string;
  area: string;
  building: string;
  city: string;
  countryIsoCode: string;
  countryName: string;
  landmark: string;
  latitude: string;
  longitude: string;
  makani: string;
  postalCode: string;
  status: string;
  streetName: string;
  streetNumber: string;
  zoneNumber: string;
}
export interface CUSTOMER_CONTACT_INFO_INTERFACE {
  address: CUSTOMER_CONTACT_INFO_ADDRESS_INTERFACE;
  email: string;
  name: string;
  phone: string;
}

export interface EXECUTED_LOCATION_INTERFACE {
  latitude: string;
  longitude: string;
}
export interface DELIVERY_PROOF_INTERFACE {
  comments: string;
  executedLocation: EXECUTED_LOCATION_INTERFACE;
  imageURLs: Array<string>;
}

export interface DELIVERY_SLOT_INTERFACE {
  deliveryDate: string;
  deliverySlotTime: string;
  endTime: string;
  rescheduled: boolean;
  startTime: string;
}

export interface DRIVER_INTERFACE {
  id: string;
  name: string;
  vendor: string;
}

export interface EXECUTED_DELIVERY_SLOT_INTERFACE {
  endTime: string;
  startTime: string;
  rescheduled: boolean;
}

export interface PASSIVE_ROUTES_INTERFACE {
  modifiedBy: string;
  modifiedTime: string;
  plannedDate: string;
  routeId: string;
  routeName: string;
}
export interface VEHICLE_INTERFACE {
  active: boolean;
  crateCapacity: number | string;
  id: string;
  name: string;
  orderCapacity: number | string;
  temperatureControlled: boolean;
}
export interface DELIVERY_INFORMATION_INTERFACE {
  actualTransitTime: string;
  carrier: string;
  carrierTrackingId: string;
  carrierTrackingUrl: string;
  customerContactInfo: CUSTOMER_CONTACT_INFO_INTERFACE;
  deliveryCost: string;
  deliveryInstructions: string;
  deliveryProof: DELIVERY_PROOF_INTERFACE;
  deliverySlot: DELIVERY_SLOT_INTERFACE;
  driver: DRIVER_INTERFACE;
  dynamicTransitTime: string | number;
  estimatedCrates: string | number;
  estimatedTransitTime: string | number;
  executedByHelper: boolean;
  executedDeliverySlot: EXECUTED_DELIVERY_SLOT_INTERFACE;
  passiveRoutes: Array<PASSIVE_ROUTES_INTERFACE>;
  preferredVehicle: string;
  shipmentSize: string;
  totalCratesCount: number;
  totalWeight: string | number;
  vehicle: VEHICLE_INTERFACE;
}

export interface QC_STATUS_DELIVERY_INTERFACE {
  reportedQty: number | string;
  verified: boolean | string;
  verifiedQty: number | string;
}

export interface QC_STATUS_RETURN_INTERFACE {
  reportedQty: number | string;
  verified: boolean | string;
  verifiedQty: number | string;
}
export interface DELVE_META_DATA_INTERFACE {
  paymentType?: string;
  paymentMethod?: string;
  paymentStatus?: string;
  totalAmount?: string;
  transactionDate?: string;
  amountToCollect?: string | number;
  currency?: string;
  category?: string;
  deliveryType?: string;
  modeOfCollection?: string;
  origin?: string;
  routeId?: string;
  routeName?: string;
  plannedBy?: string;
  qcStatusDelivery?: QC_STATUS_DELIVERY_INTERFACE;
  qcStatusReturn?: QC_STATUS_RETURN_INTERFACE;
  routeStatus?: string;
  displayRouteStatus: string;
  deliveryStatus: string;
  displayDeliveryStatus: string;
  omsStatus: string;
  numberOfItems: string | number;
  lastStatusUpdatedTime: string;
  pickingType: string;
  shippingStatus: string;
  deliveryZone: string;
  readyToDispatchDate: string;
  collectedAmountHandedOver: boolean;
}

export interface GEO_LOCATION_INTERFACE {
  lat: string | number;
  lng: string | number;
}
export interface GEO_LOCATION_OBJECT_INTERFACE {
  name: string;
  location: GEO_LOCATION_INTERFACE;
}

export interface POS_INFORMATION_CONTACT_INFO_ADDRESS_INTERFACE {
  addressId?: string;
  status?: string;
  area?: string;
  landmark?: string;
  building?: string;
  apartment?: string;
  zoneNumber?: string;
  makani?: string;
  streetNumber?: string;
  streetName?: string;
  city?: string;
  postalCode?: string;
  countryName?: string;
  countryIsoCode?: string;
  longitude: string;
  latitude: string;
}
export interface POS_INFORMATION_CONTACT_INFO_INTERFACE {
  companyName: string;
  name: string;
  phone: string;
  email: string;
  address: POS_INFORMATION_CONTACT_INFO_ADDRESS_INTERFACE;
}
export interface POS_INFORMATION_INTERFACE {
  store: string;
  storeName: string;
  contactInfo: POS_INFORMATION_CONTACT_INFO_INTERFACE;
}
export interface CONSIGNMENT_OBJECT_INTERFACE {
  id: string;
  orderCode: string;
  consignmentCode: string;
  metadata: DELVE_META_DATA_INTERFACE;
  posInformation: POS_INFORMATION_INTERFACE;
  deliveryInformation: DELIVERY_INFORMATION_INTERFACE;
  creationTime: string;
  lastModifiedBy: string;
  lastModifiedTime: string;
}
export interface Name_INTERFACE {
  ar: string;
  en: string;
}

export interface ASSOCIATED_CRATES_INTERFACE {
  crateId: string;
  scanned: boolean;
  quantity: string | number;
  locationCode: string;
}
export interface DELVE_ITEMS_INTERFACE {
  sku: string;
  entryNumber?: string | number;
  name: Name_INTERFACE;
  quantity?: string | number;
  shippedQty?: string | number;
  unitPrice?: string | number;
  imgUrl: string;
  uom: string;
  orderedBarcode?: string;
  associatedBarcodes?: Array<string>;
  associatedCrates?: Array<ASSOCIATED_CRATES_INTERFACE>;
  isAmendedEntry?: boolean;
  substitution?: boolean;
}

export interface CRATES_INTERFACE {
  verified: number;
  productQty: number;
  verifiedQty: number;
  reportedQty: number;
  issueCost: number;
  productDetails: any;
  issue_found: boolean;
}
export interface CRATES_DATA_OBJ_INETFACE {
  [key: string]: CRATES_INTERFACE;
}

export interface ITEM_ISSUES_OBJECT_INTERFACE {
  [key: string]: {
    issues_cost: number;
    reasonDescription: string;
  };
}
export interface CRATES_DETAILS_OBJECT_INTERFACE {
  crates_data_obj: CRATES_DATA_OBJ_INETFACE;
  issues_reported_cost?: number;
  item_issues_object?: ITEM_ISSUES_OBJECT_INTERFACE;
}

export interface QC_REPORTS_INTERFACE {
  reasonCode: string;
  reasonDescription: string;
}

export interface CRATE_PRODUCT_DETAILS_OBJECT_INTERFACE {
  name: Name_INTERFACE;
  quantity?: number | string;
  shippedQty?: string;
  unitPrice?: string;
  imgUrl?: string;
  qcStatusDelivery?: QC_STATUS_DELIVERY_INTERFACE;
  qcReports?: QC_REPORTS_INTERFACE;
  reported_cost?: number | string;
  reportedReasonDescription?: string;
  reportedQty?: string | number;
}

export interface UN_ORDERED_PRODUCTS_RECORD_INTERFACE {}
