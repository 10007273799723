import { generateRandomNumber } from "./../utils/helpers.utils";
const CustomerCommConstants = {
  CUSTOMER_COMM_CONSTANTS: {
    CONSIGNMENTS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Consignments",
        HeaderData: [
          {
            field: "id",
            headerName: "Id",
            hide: true,
          },
          {
            field: "hubCode",
            headerName: "Hub",
            width: 100,
           
          },
          {
            field: "hubNames",
            headerName: "Hub Name",
            width: 150,
            
          },
          {
            field: "consignmentId",
            headerName: "Consignment ID",
            width: 100,
          },
          {
            field: "deliveryArea",
            headerName: "Customer Area",
            width: 150,
            tooltip: true,
            tooltipDataKey: "address",
           
          },
          {
            field: "deliveryZone", 
            headerName: "Zone",
            width: 100,
            
          },
          {
            field: "status",
            headerName: "Status",
            width: 186,
          },
          {
            field: 'deliveryType',
            headerName: 'Type',
            width: 100,
          
          },
          {
            type: "date",
            field: "deliveryDate",
            headerName: "Delivery Date",
            width: 100,
            
          },
          {
            field: "deliverySlotTime",
            headerName: "Delivery Slot",
            width: 100,
           
          },
        ],
       
        FilterFormData: [
          {
            type: "selectBox",
            fieldName: "orderType",
            multiple: false,
            enableOnChangeFetch:true,
            key: `selectBox-orderType-${Math.floor(Math.random() * 1000)}`,
            label: "Order Type",
            items: []
          },
          {
            type: "autocomplete",
            fieldName: "hub",
            multiple: false,
            enableOnChangeFetch:true,
            key: `autocomplete-hub-${Math.floor(Math.random() * 1000)}`,
            label: "Hub",
            items: []
          },
          {
            type: "autocomplete",
            fieldName: "zones",
            multiple: true,
            key: `autocomplete-deliveryAreas-${Math.floor(Math.random() * 1000)}`,
            label: "Zones",
            items: []
          },
          {
            type: "autocomplete",
            fieldName: "deliveryAreas",
            multiple: true,
            key: `autocomplete-deliveryAreas-${Math.floor(Math.random() * 1000)}`,
            label: "Delivery Area",
            items: []
          },
          {
            type: "autocomplete",
            fieldName: "deliveryStatus",
            multiple: true,
            key: `autocomplete-delStatus-${Math.floor(Math.random() * 1000)}`,
            label: "Status",
            items: []
          },
          {
            type: "dateRange",
            fieldName: "dateRange",
            key: `dateRange-filter-${Math.floor(Math.random() * 1000)}`,
            placeholderText: 'Delivery Date',
            label: 'Delivery Date',
            isTextField:true,
            autoUpdateInput: false,
            autoApply: true,
            showDropdowns: true,
            linkedCalendars: true,
            maxSpan: {
              day: "6"
            },
            locale: {}
          },
          {
            type: "timeRange",
            fieldName: "deliveryStartTime",
            multiple: false,
            enableOnChangeFetch:true,
            key: `autocomplete-timeSlot-${Math.floor(Math.random() * 1000)}`,
            label: "Start Time",
            default:'start',
            items: []
          },
          {
            type: "timeRange",
            fieldName: "deliveryEndTime",
            multiple: false,
            enableOnChangeFetch:true,
            key: `autocomplete-timeSlot-${Math.floor(Math.random() * 1000)}`,
            label: "End Time",
            default:'end',
            items: []
          },
         
        ],
        defaultFiltersObjForm: {
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
          },
          deliveryStartTime:"",
          deliveryEndTime:"",
          zones: {
            key: "zones",
            value: [],
          },
          deliveryAreas:{
            key: "deliveryStatus",
            value:[]
          },
          dateRange: {
            startDate: new Date(),
            endDate: new Date()
          },
          orderType:{
            key:'orderType',
            value:['']
          },
          hub:{
            key:'hub',
            value:[]
          }
        },
        headerButtons: [
          {
            name: "selectedRow",
            displayName: "",
            disabled: false,
            classes: 'customButton',
            hide: true
          }
        ],
        orderType:[{name: "EXPRESS" , value: "EXPRESS"}, {name: "STANDARD" , value: "STANDARD"}],
        loadMore: {
          name: "loadMore",
          disabled: false,
          pageSize: 400,
        },
        defaultFiltersObj: {
          hub: {
            key: "hub",
            value: [],
          },
          hubName: {
            key: "hubNames",
            value: [],
          },
          deliveryArea: {
            key: "deliveryArea",
            value: [],
          },
          addressStatus: {
            key: "addressStatus",
            value: [],
          },
          deliveryZone: {
            key: "deliveryZone",
            value: [],
          },
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
          },
           orderType: {
            key: "orderType",
            value: [],
            fieldName: 'c.metadata.category'
          },
          status: {
            key: "status",
            value: [],
          },
          paymentType: {
            key: "paymentType",
            value: [],
          },
          deliveryExecutionStatus: {
            key: "deliveryExecutionStatus",
            value: [],
          },
          driverId: {
            key: "driverId",
            value: [],
          },
          deliverySlotTime: {
            key: "deliverySlotTime",
            value: [],
          },
          pageNumber: 0,
          dateRange: {
            key: "dateRange",
            value: {
              startDate: new Date(),
              endDate: new Date(),
            },
          },
        },
        SEARCH_TYPES: [
          {
            name: "Consignment Id",
            value: "consignmentCode",
          },
        ],
        sortModel: {
          field: "deliveryDate",
          direction: "asc",
        },
      },
      FILTER_CHIPS: [
        {
          code: "UNASSIGNED",
          value: "Unassigned",
        },
        {
          code: "ON_HOLD",
          value: "On Hold",
        },
        {
          code: "ASSIGNED",
          value: "Assigned",
        },
        {
          code: "OUT_FOR_DELIVERY",
          value: "Out For Delivery",
        },
        {
          code: "COMPLETED",
          value: "Completed",
        },
        {
          code: "ALL",
          value: "All",
        },
      ],
      STATUSES: {
        UNASSIGNED: {
          color: "INFO_BLUE",
        },
        ON_HOLD: {
          color: "MINSK",
        },
        ASSIGNED: {
          color: "MINSK",
        },
        READY_TO_DISPATCH: {
          color: "READY_BLUE",
        },
        OUT_FOR_DELIVERY: {
          color: "PRIMARY_BLUE",
        },
        DELIVERED: {
          color: "JUNGLE_GREEN",
        },
        PARTIALLY_DELIVERED: {
          color: "PARTIAL_SUCCESS_GREEN",
        },
        DELIVERY_ATTEMPTED: {
          color: "ALERT_ORANGE",
        },
        CANCELLED_BY_SOURCE: {
          color: "PALE_GRAY",
        },
        DELIVERY_FAILED: {
          color: "ERROR_RED",
        },
        PACKING_COMPLETE: {
          color: "MINSK",
        },
      },
      ORDER_STATUSES: {
        READY: {
          color: "INFO_BLUE",
        },
        EXPORTED: {
          color: "READY_BLUE",
        },
        READY_TO_DISPATCH: {
          color: "READY_BLUE",
        },
        PAYMENT_ACCEPTED: {
          color: "MINSK_DARK",
        },
        DISPATCHED: {
          color: "SCAMPI",
        },
        DELIVERY_ATTEMPTED: {
          color: "DRIFT_WOOD",
        },
        OUT_FOR_DELIVERY: {
          color: "TAG_YEllOW",
        },
        DELIVERED: {
          color: "PERSIAN_GREEN_DARK",
        },
        PARTIALLY_DELIVERED: {
          color: "PARTIAL_SUCCESS_GREEN",
        },
        CANCELLED: {
          color: "SILVER_CHALICE2",
        },
        DELIVERY_FAILED: {
          color: "PALE_RED",
        },
        PACKING_COMPLETE: {
          color: "GREEN_BLUE",
        },
        PICKING_STARTED: {
          color: "MAROON",
        },
      },
      ORDER_STATUS: {
        READY: "READY",
        EXPORTED: "EXPORTED",
        READY_TO_DISPATCH: "READY_TO_DISPATCH",
        PAYMENT_ACCEPTED: "PAYMENT_ACCEPTED",
        DISPATCHED: "DISPATCHED",
        DELIVERY_ATTEMPTED: "DELIVERY_ATTEMPTED",
        OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
        DELIVERED: "DELIVERED",
        PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
        CANCELLED: "CANCELLED",
        DELIVERY_FAILED: "DELIVERY_FAILED",
        PACKING_COMPLETE: "PACKING_COMPLETE",
        PICKING_STARTED: "PICKING_STARTED",
      },
      NON_TERMINAL_STATUSES:["DELIVERY_ATTEMPTED","DISPATCHED","EXPORTED","OUT_FOR_DELIVERY","PACKING_COMPLETE","PAYMENT_ACCEPTED","PICKING_STARTED","READY","READY_TO_DISPATCH"],
      DELIVERY_EXECUTION_STATUS: {
        DELAYED:{
          color: "ORANGE",
        },
        ON_TIME:{
          color: "SUCCESS_GREEN",
        },
      },
      TAB_VALUES: {
        CONSIGNMENTS: "Consignments",
        COMM_HISTORY: "Communiction History",
      },
      TAB_VALUES_HISTORY: {
        STANDARD: "STANDARD",
        EXPRESS: "EXPRESS",
      },
      ORDER_TYPE:{
        STANDARD: "STANDARD",
        EXPRESS: "EXPRESS",
      },
      DEFAULT_FILTER_CHIP: "ALL",
      ALL_FILTER_CHIP: "ALL",
      NON_EDITABLE_STATUSES: [
        "DELIVERY_FAILED",
        "CANCELLED_BY_SOURCE",
        "PARTIALLY_DELIVERED",
        "DELIVERED",
      ],
      REFRESHABLE_STATUSES:['READY','EXPORTED','PICKING_STARTED'],
      DRIVER_ASSIGNED_SUCCESS_MSG: "Driver Assigned successfully",
      DEFAULT_STATUS_SELECTION: "DELIVERED",
      PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
      REPLANNING_CONSIGNMENT_SUCCESS_MSG: "Consignment updated successfully.",
      ADDRESS_MARKED_UNVERIFIED: "Address has been marked as inaccurate",
    },
    DELIVERY_TYPE: {
      DISPATCHER: "DELIVERY_DISPATCHER",
      LINE_ITEM_REJECTION: "LINE_ITEM_REJECTION",
      ON_HOLD: "ONHOLD_DISPATCHER",
      ORDER_COMMENTS: "ORDER_COMMENTS"
    },
    DRIVERS_CATEGORY: {
      ASSIGNED: "ASSIGNED",
      UNASSIGNED: "UNASSIGNED",
      OTHERS: "OTHERS",
    },
    DRIVER_STATUS: {
      OUT_FOR_DELIVERY: "Out For Delivery",
    },
    VEHICLE_TYPE: {
      CAR: "CAR",
      BIKE: "BIKE",
    },
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
        CONCURRENT_CONSIGNMENT: "CWO15",
      },
    },
    ORDER_LOG_MODIFIED_BY: {
      MANUAL: "Manual",
      SYSTEM: "System",
    },
    VALIDTION_ADDITIONAL_COMMENT:{
      REASON: {
        REQUIRED: {
          msg: "Please select the reason"
        }
      },
      COMMENT: {
        REQUIRED: {
          msg: "Additional information is required"
        }
      },
    }
  },
  CUSTOMER_COMM_CONSTANTSـSTD: {
    CONSIGNMENTS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Orders",
        HeaderData: [
          {
            field: 'id',
            headerName: 'Id',
            hide: true
          },
          {
            field: 'hubCode',
            headerName: 'Hub Code',
            width: 150,
            
          },
          {
            field: 'hubName',
            headerName: 'Hub Name',
            width: 200,
      
          },
          {
            field: 'consignmentId',
            headerName: 'Consignment ID',
            width: 150
          },
           {
            field: "deliveryArea",
            headerName: "Customer Area",
            width: 180,
            tooltip: true,
            tooltipDataKey: "address",
           
          },
            {
            field: "deliveryZone", 
            headerName: "Zone",
            width: 150,
           
          },
          {
            field: 'deliveryStatus',
            headerName: 'Status',
            width: 170,
            
          },
          {
            field: 'type',
            headerName: 'Type',
            width: 120,
          },
          {
            type: "date",
            field: 'deliveryDate',
            headerName: 'Delivery Date',
            width: 160,
            
          },
          {
            field: 'startEndTime',
            headerName: 'Delivery Slot',
            width: 150,
         
          },
        ],
     
        loadMore: {
          name: 'loadMore',
          disabled: false,
          pageSize: 400
        },
        defaultFiltersObj: {
          pageNumber: 0,
          hub: {
            key: "hub",
            value: [],
            fieldName: 'c.posInformation.store'
          },
          hubName: {
            key: "hubName",
            value: [],
            fieldName: 'c.posInformation.store'
          },
          deliveryArea: {
            key: "deliveryArea",
            value: [],
            fieldName: 'c.deliveryInformation.customerContactInfo.address.area'
          },
           addressStatus: {
            key: "addressStatus",
            value: [],
            fieldName: 'c.deliveryInformation.customerContactInfo.address.status'
          },
          deliveryZone: {
            key: "deliveryZone",
            value: [],
            fieldName: 'c.metadata.deliveryZone'
          },
          orderType: {
            key: "orderType",
            value: [],
            fieldName: 'c.metadata.category'
          },
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
            fieldName: 'c.metadata.deliveryStatus'
          },
          dateRange: {
            key: "dateRange",
            value: {
              startDate: new Date(),
              endDate: new Date()
            }
          },
          deliverySlot: {
            key: "deliverySlot",
            value: []
          }
        },
        headerButtons: [
          {
            name: "selectedRow",
            displayName: "",
            disabled: false,
            classes: 'customButton',
            hide: true
          }
        ],
        defaultFiltersObjForm: {
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
            fieldName: 'c.metadata.deliveryStatus'
          },
          deliveryStartTime:"",
          deliveryEndTime:"",
          deliveryZone: {
            key: "deliveryZone",
            value: [],
            fieldName: 'c.metadata.deliveryZone'
          },
          deliveryArea:{
            key: "deliveryArea",
            value:[],
            fieldName: 'c.deliveryInformation.customerContactInfo.address.area'
          },
          dateRange: {
            startDate: new Date(),
            endDate: new Date()
          },
          orderType:{
            key:'orderType',
            value:[''],
            fieldName: 'c.metadata.category'
          },
          hub:{
            key:'hub',
            value:[],
            fieldName: 'c.posInformation.store'
          }
        },
        SEARCH_TYPES: [
          {
            name: "Consignment Id",
            value: "c.consignmentCode"
          }
        ],
        sortModel: {
          field: "deliveryDate",
          direction: "asc"
        }
      },
      FILTER_CHIPS: [
        {
          code: 'UNPLANNED',
          value: 'Unplanned'
        },
        {
          code: 'PLANNED',
          value: 'Planned'
        },
        {
          code: 'PUBLISHED',
          value: 'Published'
        },
        {
          code: 'PREPARING',
          value: 'Preparing'
        },
        {
          code: 'PREPARED',
          value: 'Prepared'
        },
        {
          code: 'OUT_FOR_DELIVERY',
          value: 'Out For Delivery'
        },
        {
          code: 'COMPLETED',
          value: 'Completed'
        },
        {
          code: "ALL",
          value: "All"
        }
      ],
      STATUSES: {
        "NEW": {
          color: "PICTON_BLUE",
          hintText: ""
        },
        "READY_TO_PREPARE": {
          color: "CREAM_CAN",
          hintText: ""
        },
        "READY_TO_DISPATCH": {
          color: "BLUE",
          hintText: ""
        },
        "PREPARING": {
          color: "VISTA_BLUE",
          hintText: ""
        },
        "PREPARED": {
          color: "SEA_GREEN",
          hintText: ""
        },
        "OUT_FOR_DELIVERY": {
          color: "GOLDEN_BELL",
          hintText: ""
        },
        "ON_HOLD": {
          color: "SCAMPI",
          hintText: ""
        },
        "PENDING_DELIVERY": {
          color: "BLUE",
          hintText: ""
        },
        "DELIVERED": {
          color: "SEA_GREEN2",
          hintText: ""
        },
        "PARTIALLY_DELIVERED": {
          color: "LUCKY",
          hintText: ""
        },
        "CANCELLED": {
          color: "DUSTY_GRAY",
          hintText: ""
        },
        "RETURNED_TO_STORE/HUB": {
          color: "JUNGLE_GREEN",
          hintText: ""
        },
        "DELIVERY_FAILED": {
          color: "BLACK",
          hintText: ""
        },
        "PACKING_COMPLETE": {
          color: "MINSK",
          hintText: ""
        },
        "ASSIGNED": {
          color: "BLUE",
          hintText: ""
        }
      },
      ORDER_STATUS: {
        NEW: "NEW",
        READY_TO_PREPARE: "READY_TO_PREPARE",
        READY_TO_DISPATCH: "READY_TO_DISPATCH",
        PREPARING: "PREPARING",
        PREPARED: "PREPARED",
        OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
        ON_HOLD: "ON_HOLD",
        PENDING_DELIVERY: "PENDING_DELIVERY",
        DELIVERED: "DELIVERED",
        PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
        CANCELLED: "CANCELLED",
        "RETURNED_TO_STORE/HUB": "RETURNED_TO_STORE/HUB",
        DELIVERY_FAILED: "DELIVERY_FAILED",
        PACKING_COMPLETE: "PACKING_COMPLETE",
        ASSIGNED:"ASSIGNED"
      },
      PC_NOT_RECEIVED: "PC_NOT_RECEIVED",
      COMPLETED: "COMPLETED",
      TERMINAL_STATUSES: ['DELIVERY_FAILED', 'CANCELLED', 'DELIVERED', 'PARTIALLY_DELIVERED', 'ON_HOLD','OUT_FOR_DELIVERY'],
      NON_TERMINAL_STATUSES:["NEW", "READY_TO_PREPARE", "ON_HOLD", "OUT_FOR_DELIVERY", "ASSIGNED", "PACKING_COMPLETE", "PENDING_DELIVERY", "PREPARED", "PREPARING", "READY_TO_DISPATCH", "RETURNED_TO_STORE/HUB"],
      NON_EDITABLE_STATUSES: ['DELIVERY_FAILED', 'CANCELLED', 'PARTIALLY_DELIVERED', 'DELIVERED'],
      REFRESHABLE_STATUSES:['NEW'],
      CANCELLED: 'CANCELLED',
      DEFAULT_FILTER_CHIP: 'UNPLANNED',
      REPLANNING_CONSIGNMENT_SUCCESS_MSG: 'Consignment updated successfully.',
      ADDRESS_MARKED_UNVERIFIED: "Address has been marked as inaccurate",
      DEFAULT_REPLANNING_SELECTION: 'reschedule',
      COD_TEXT: "COD",
      DISPLAY_ROUTE_STATUS_FILTER_OBJ: {
        key: "displayRouteStatus",
        value: ['UNPLANNED'],
        fieldName: 'c.metadata.displayRouteStatus'
      },
      CRATE_ID: "Crate Id",
      TAB_VALUES_DETAIL: {
        ORDER_DETAIL: "Order Detail",
        ORDER_LOG: "Order",
      },
    },
    DELIVERY_TYPE: {
      DISPATCHER: "DELIVERY_DISPATCHER",
      LINE_ITEM_REJECTION: "LINE_ITEM_REJECTION",
      ON_HOLD: "ONHOLD_DISPATCHER",
      ORDER_COMMENTS: "ORDER_COMMENTS"
    },
    DRIVERS_CATEGORY: {
      ASSIGNED: "ASSIGNED",
      UNASSIGNED: "UNASSIGNED",
      OTHERS: "OTHERS",
    },
    DRIVER_STATUS: {
      OUT_FOR_DELIVERY: "Out For Delivery",
    },
    VEHICLE_TYPE: {
      CAR: "CAR",
      BIKE: "BIKE",
    },
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
        CONCURRENT_CONSIGNMENT: "CWO15",
      },
    },
    ORDER_LOG_MODIFIED_BY: {
      MANUAL: "Manual",
      SYSTEM: "System",
    },
    VALIDTION_ADDITIONAL_COMMENT:{
      REASON: {
        REQUIRED: {
          msg: "Please select the reason"
        }
      },
      COMMENT: {
        REQUIRED: {
          msg: "Additional information is required"
        }
      },
    }
  },
  CUSTOMER_COMM_HISTORY_CONSTANTS: {
    CONSIGNMENTS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Consignments",
        HeaderData: [
          {
            field: "id",
            headerName: "Id",
            hide: true,
          },
          {
            field: "hubCode",
            headerName: "Hub",
            width: 100,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hub",
              fieldName: "hub",
              fieldFullName: "hub",
              multiple: true,
              key: `autoComplete-hub-${generateRandomNumber(0, 1000)}`,
              label: "Hub Code",
              items: [],
            },
          },
          {
            field: "hubNames",
            headerName: "Hub Name",
            width: 200,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubName",
              fieldName: "hubName",
              fieldFullName: "hubName",
              multiple: true,
              key: `autoComplete-hubName-${generateRandomNumber(0, 1000)}`,
              label: "Hub Name",
              items: [],
            },
          },
          {
            field: "consignmentId",
            headerName: "Consignment ID",
            width: 150,
          },
          {
            field: "deliveryArea",
            headerName: "Customer Area",
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryArea",
              fieldName: "deliveryArea",
              fieldFullName: "deliveryArea",
              multiple: true,
              key: `autoComplete-deliveryArea-${generateRandomNumber(0, 1000)}`,
              label: "Area",
              items: [],
            },
          },
          {
            field: "deliveryZone", 
            headerName: "Zone",
            width: 100,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryZone",
              fieldName: "deliveryZone",
              fieldFullName: "c.metadata.deliveryZone",
              multiple: true,
              key: `autoComplete-deliveryZone-${generateRandomNumber(0, 1000)}`,
              label: "zone",
              items: []
            }
          },
          {
            field: 'orderType',
            headerName: 'Type',
            width: 100,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "type",
              fieldName: "orderType",
              fieldFullName: "c.metadata.category",
              multiple: true,
              key: `autoComplete-orderType-${generateRandomNumber(0, 1000)}`,
              label: "Order Type",
              items: [],
            }
          },
          {
            type: "date",
            field: "deliveryDate",
            headerName: "Delivery Date",
            width: 150,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "deliveryDate",
              fieldName: "dateRange",
              key: `dateRange-filter-${generateRandomNumber(0, 1000)}`,
              placeholderText: "Delivery Date",
              label: "Delivery Date",
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: {
                day: "29",
              },
              locale: {},
            },
          },
          {
            type: "date",
            field: "deliveredAt",
            headerName: "Initiated At",
            width: 150,
          },
          {
            field: "initiatedBy",
            headerName: "Initiated By",
            width: 180,
          },
          {
            field: "reasonDescription",
            headerName: "Reason For Delay",
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "reason",
              fieldName: "reason",
              fieldFullName: "c.reason",
              multiple: true,
              key: `autoComplete-orderType-${generateRandomNumber(0, 1000)}`,
              label: "reason",
              items: [],
            }
          },
          {
            field: "comment",
            headerName: "Comment",
            width: 180,
          },
        ],
        headerButtons: [
          {
            name: "colsettings",
            displayName: "Column Settings",
            disabled: false,
            childElements: [
              {
                name: "excel",
                displayName: "EXPORT DATA",
                disabled: false,
              },
            ],
          },
        ],
        loadMore: {
          name: "loadMore",
          disabled: false,
          pageSize: 400,
        },
        defaultFiltersObj: {
          hub: {
            key: "hub",
            value: [],
          },
          hubName: {
            key: "hubNames",
            value: [],
          },
          deliveryArea: {
            key: "deliveryArea",
            value: [],
          },
          addressStatus: {
            key: "addressStatus",
            value: [],
          },
          deliveryZone: {
            key: "deliveryZone",
            value: [],
          },
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
          },
           orderType: {
            key: "orderType",
            value: [],
          },
          reason: {
            key: "reason",
            value: [],
          },
          status: {
            key: "status",
            value: [],
          },
          paymentType: {
            key: "paymentType",
            value: [],
          },
          deliveryExecutionStatus: {
            key: "deliveryExecutionStatus",
            value: [],
          },
          driverId: {
            key: "driverId",
            value: [],
          },
          deliverySlotTime: {
            key: "deliverySlotTime",
            value: [],
          },
          pageNumber: 0,
          dateRange: {
            key: "dateRange",
            value: {
              startDate: new Date(),
              endDate: new Date(),
            },
          },
        },
        SEARCH_TYPES: [
          {
            name: "Consignment Id",
            value: "consignmentCode",
          },
        ],
        sortModel: {
          field: "deliveryDate",
          direction: "asc",
        },
      },
      FILTER_CHIPS: [
        {
          code: "UNASSIGNED",
          value: "Unassigned",
        },
        {
          code: "ON_HOLD",
          value: "On Hold",
        },
        {
          code: "ASSIGNED",
          value: "Assigned",
        },
        {
          code: "OUT_FOR_DELIVERY",
          value: "Out For Delivery",
        },
        {
          code: "COMPLETED",
          value: "Completed",
        },
        {
          code: "ALL",
          value: "All",
        },
      ],
      STATUSES: {
        UNASSIGNED: {
          color: "INFO_BLUE",
        },
        ON_HOLD: {
          color: "MINSK",
        },
        ASSIGNED: {
          color: "MINSK",
        },
        READY_TO_DISPATCH: {
          color: "READY_BLUE",
        },
        OUT_FOR_DELIVERY: {
          color: "PRIMARY_BLUE",
        },
        DELIVERED: {
          color: "JUNGLE_GREEN",
        },
        PARTIALLY_DELIVERED: {
          color: "PARTIAL_SUCCESS_GREEN",
        },
        DELIVERY_ATTEMPTED: {
          color: "ALERT_ORANGE",
        },
        CANCELLED_BY_SOURCE: {
          color: "PALE_GRAY",
        },
        DELIVERY_FAILED: {
          color: "ERROR_RED",
        },
        PACKING_COMPLETE: {
          color: "MINSK",
        },
      },
      DRIVER_STATUSES: {
        ACTIVE: {
          color: "GREY_SCALE",
          hintText: "",
          value: "ACTIVE",
        },
        AVAILABLE: {
          color: "SEA_GREEN",
          hintText: "",
          value: "AVAILABLE",
        },
        UNASSIGNED: {
          color: "SEA_GREEN",
          hintText: "",
          value: "UNASSIGNED",
        },
        ASSIGNED: {
          color: "GOLDEN_BELL",
          hintText: "",
          value: "ASSIGNED",
        },
        BACK_TO_STORE: {
          color: "INFO_BLUE",
          hintText: "",
          value: "BACK_TO_STORE",
        },
        AWAY: {
          color: "GREY_SCALE",
          hintText: "",
          value: "AWAY",
        },
        OUT_FOR_DELIVERY: {
          color: "GREY_SCALE",
          hintText: "",
          value: "OUT_FOR_DELIVERY",
        },
        OFFLINE: {
          color: "GREY_SCALE",
          hintText: "",
          value: "OFFLINE",
        },
      },
      ORDER_STATUSES: {
        READY: {
          color: "INFO_BLUE",
        },
        EXPORTED: {
          color: "READY_BLUE",
        },
        READY_TO_DISPATCH: {
          color: "READY_BLUE",
        },
        PAYMENT_ACCEPTED: {
          color: "MINSK_DARK",
        },
        DISPATCHED: {
          color: "SCAMPI",
        },
        DELIVERY_ATTEMPTED: {
          color: "DRIFT_WOOD",
        },
        OUT_FOR_DELIVERY: {
          color: "TAG_YEllOW",
        },
        DELIVERED: {
          color: "PERSIAN_GREEN_DARK",
        },
        PARTIALLY_DELIVERED: {
          color: "PARTIAL_SUCCESS_GREEN",
        },
        CANCELLED: {
          color: "SILVER_CHALICE2",
        },
        DELIVERY_FAILED: {
          color: "PALE_RED",
        },
        PACKING_COMPLETE: {
          color: "GREEN_BLUE",
        },
        PICKING_STARTED: {
          color: "MAROON",
        },
      },
      ORDER_STATUS: {
        READY: "READY",
        EXPORTED: "EXPORTED",
        READY_TO_DISPATCH: "READY_TO_DISPATCH",
        PAYMENT_ACCEPTED: "PAYMENT_ACCEPTED",
        DISPATCHED: "DISPATCHED",
        DELIVERY_ATTEMPTED: "DELIVERY_ATTEMPTED",
        OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
        DELIVERED: "DELIVERED",
        PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
        CANCELLED: "CANCELLED",
        DELIVERY_FAILED: "DELIVERY_FAILED",
        PACKING_COMPLETE: "PACKING_COMPLETE",
        PICKING_STARTED: "PICKING_STARTED",
      },
      DELIVERY_EXECUTION_STATUS: {
        DELAYED:{
          color: "ORANGE",
        },
        ON_TIME:{
          color: "SUCCESS_GREEN",
        },
      },
      TAB_VALUES: {
        CONSIGNMENTS: "Consignments",
        COMM_HISTORY: "Communiction History",
      },
      DEFAULT_FILTER_CHIP: "UNASSIGNED",
      ALL_FILTER_CHIP: "ALL",
      NON_EDITABLE_STATUSES: [
        "DELIVERY_FAILED",
        "CANCELLED_BY_SOURCE",
        "PARTIALLY_DELIVERED",
        "DELIVERED",
      ],
      REFRESHABLE_STATUSES:['READY','EXPORTED','PICKING_STARTED'],
      DRIVER_ASSIGNED_SUCCESS_MSG: "Driver Assigned successfully",
      DEFAULT_STATUS_SELECTION: "DELIVERED",
      PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
      REPLANNING_CONSIGNMENT_SUCCESS_MSG: "Consignment updated successfully.",
      ADDRESS_MARKED_UNVERIFIED: "Address has been marked as inaccurate",
    },
    DELIVERY_TYPE: {
      DISPATCHER: "DELIVERY_DISPATCHER",
      LINE_ITEM_REJECTION: "LINE_ITEM_REJECTION",
      ON_HOLD: "ONHOLD_DISPATCHER",
      ORDER_COMMENTS: "ORDER_COMMENTS"
    },
    DRIVERS_CATEGORY: {
      ASSIGNED: "ASSIGNED",
      UNASSIGNED: "UNASSIGNED",
      OTHERS: "OTHERS",
    },
    DRIVER_STATUS: {
      OUT_FOR_DELIVERY: "Out For Delivery",
    },
    VEHICLE_TYPE: {
      CAR: "CAR",
      BIKE: "BIKE",
    },
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
        CONCURRENT_CONSIGNMENT: "CWO15",
      },
    },
    ORDER_LOG_MODIFIED_BY: {
      MANUAL: "Manual",
      SYSTEM: "System",
    },
    VALIDTION_ADDITIONAL_COMMENT:{
      REASON: {
        REQUIRED: {
          msg: "Please select the reason"
        }
      },
      COMMENT: {
        REQUIRED: {
          msg: "Additional information is required"
        }
      },
    }
  },
};

export default CustomerCommConstants;

export enum EXPRESS_DELIVERY_TYPE {
    UNASSIGNED = "UNASSIGNED",
    ASSIGNED = "ASSIGNED",
    ON_HOLD = "ON_HOLD",
    DELIVERY_ATTEMPTED = "DELIVERY_ATTEMPTED",
    UNDELIVERED = "UNDELIVERED",
}