import React, { useEffect, useRef } from 'react';
import DateRangePicker, { Props, EventHandler } from 'react-bootstrap-daterangepicker';

interface DatePickerComponentProps extends Props {
  id: string;
  renderInput: (params: any) => React.ReactNode;
  onChange: EventHandler;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {

  const dateRangeRef = useRef<DateRangePicker>(null);

  const { id, initialSettings, renderInput, onChange } = props;

  useEffect(() => {
    if (dateRangeRef.current) {
      dateRangeRef.current.setStartDate(initialSettings.startDate);
      dateRangeRef.current.setEndDate(initialSettings.endDate);
    }
  }, [initialSettings]);

  return (
    <DateRangePicker
      initialSettings={initialSettings}
      ref={dateRangeRef}
      onApply={onChange}
    >
      {renderInput({
        id: id
      })}
    </DateRangePicker>
  )

};

export default DatePickerComponent;