const DashboardConstants = {
    DASHBOARD_CONSTANTS: {
        PERFORMANCE_CONSTANTS: {
            SLOT_VALUES: {
                Percentages: [
                    { label: "OnTime Handover Percentage", value: "handOverOnTimePercentage" },
                    { label: "OnTime Delivery Percentage", value: "onTimePercentage" }
                ],
                Counts: [
                    { label: "OnTime Handover", value: "OnTime Handover" },
                    { label: "OnTime Delivery", value: "OnTime" }
                ],
                Volume: [
                    { label: "Unplanned", value: "Unplanned" },
                    { label: "Planned/Published", value: "Planned/Published" },
                    { label: "Preparing/Prepared", value: "Preparing/Prepared" },
                    { label: "Out for Delivery", value: "Out for Delivery" },
                    { label: "Delivered", value: "Delivered" },
                    { label: "Un-Delivered", value: "Un-Delivered" },
                    { label: "Cancelled", value: "Cancelled" },
                ],
                PaymentWise: [
                    "COD", "Card on Delivery", "Prepaid"
                ],
                DeliveryTypeWise: [
                    'E-Food Standard', 'Marketplace', 'Non Food', 'Carrefour Now'
                ]
            },
        },
        ROUTES_PERFORMANCE_CONSTANTS: {
            SLOT_VALUES: {
                Percentages: [
                    { label: "OnTime Delivery Percentage", value: "onTimePercentage" },
                    { label: "OnTime Handover Percentage", value: "handOverOnTimePercentage" }
                ],
                Counts: [
                    { label: "OnTime Delivery", value: "OnTime" },
                    { label: "OnTime Handover", value: "Late" }
                ],
                Volume: [
                    { label: "Planned/Published", value: "Planned/Published" },
                    { label: "In Progress", value: "In Progress" },
                    { label: "Completed", value: "Completed" },
                ],
                PaymentWise: [
                    "COD", "Card on Delivery", "COD And Prepaid"
                ],
                DeliveryTypeWise: [
                    'E-Food Standard', 'Marketplace', 'Non Food', 'Carrefour Now'
                ]

            },
        },
        RESPONSE_CONSTANTS: {
            ERROR_CODES: {
                SESSION_TIMEOUT: "UM100",
                UNAUTHORISED: "UM101",
            }
        },
        TAB_VALUES: {
            DASHBOARD: 'Dashboard',
            HUB_WISE_DASHBOARS: 'Hubwise Dashboard',
            COD_RECON: 'COD Recon'
          },
    }
}

export default DashboardConstants;
