import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
//common components
import { AppState } from "../config/redux/reducers";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import CustomSnackbar from "../common/CustomSnackbar";
//redux
import { userLogout } from "../Login/redux/loginSlice";
import { fetchOrdersFilterChipsDisplayCount, fetchPlanningOrderListByFilter, exportToExcel, downloadOrderLogs } from './redux/expressSlice';
//Material UI Components
import { CellParams, SortModel } from "../common/GridWrapper/DataTable";
import { Grid, IconButton, SvgIcon, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
//styles
import { useStyles } from "./express.styles";
//assets
import { ReactComponent as checkIcon } from './../assets/icons/circle-checked-icon.svg';
import locationIcon from './../assets/icons/map-pin.svg';
//other components
import {TIMER_CONST} from "../constants/CommonConstants"
import {Countdown} from '../utils/countdownTimer'
import ExpressOrderDetails from "./ExpressOrderDetails";
import DriverAssignment from './DriverAssignment';
import { createChipsCountFilterPayload, createFilterPayload, expressOrdersDataTransform, getConsignmentStatusField, getOrderStatusField } from "../mocks/express/response.transforms";
interface ExpressProps {
    match?: any;
    location?: any;
}

const Express = (props: ExpressProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { ordersData, ordersFilterChipsDisplayCount, ordersFiltersDropdowns, tableLoading, loading, error, errorCode, excelLoading, exportError, driverAssigned, replanningConsignmentSuccess, isAddressUpdated, isPolled, areOrderCommentsAdded } = useSelector(
        (state: AppState) => state.expressDashboard
    );
    const { countryCode, hubCode } = useSelector(
        (state: AppState) => state.common
    );
    const { isLastMileViewer, isOnlyExpLastMileViewer } = useSelector(
        (state: AppState) => state.userLogin
    );

    const hubCountryCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;

    const [showTime, setShowTime] = useState(false);

    const onLoadRef = useRef(true);
    const [activeOrdersFilterChip, setActiveOrdersFilterChip] = useState(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);

    const [isOrderSearched, setIsOrderSearched] = useState(false);
    const [ordersSearchValue, setOrdersSearchValue] = useState("");
    const [isTabChange, setIsTabChange] = useState(false);
    const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
        ...AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA, HeaderData: AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
            let newHeader = { ...header };
            if (newHeader.field === 'consignmentId') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="idLink">{params.value}</Typography>
                )
            }
            if (newHeader.field === 'status') {
                newHeader.renderCell = (params: any) => (
                    params.value ?
                        <Grid className={classes.statusHeaderContainer} container alignItems="center">
                            <Typography className={['orderTag', getOrderStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
                        </Grid>
                        : <></>
                );
            }
            if (newHeader.field === 'deliveryStatus') {
                newHeader.renderCell = (params: any) => (
                    params.value ?
                        <Grid className={classes.statusHeaderContainer} container alignItems="center">
                            <Typography className={['tag', getConsignmentStatusField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
                        </Grid>
                        : <></>
                );
            }
            if (newHeader.field === 'actions') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="actionsLink">{params.value}</Typography>
                )
            }
            if (newHeader.field === 'deliveryArea') {
                newHeader.renderCell = (params: CellParams) => (
                    <Grid container>
                        <Grid item>{params.value}</Grid>
                        <Grid item className={classes.infoSection}>
                            <img src={locationIcon} alt="info-icon" className={classes.infoIconStyle} />
                        </Grid>
                    </Grid>
                )
            }
            return newHeader;
        }),
        defaultFiltersObj: {
            ...AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj, dateRange: {
                ...AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange, value: {
                    startDate: moment().format(AppConstants.DATE_FORMAT),
                    endDate: moment().format(AppConstants.DATE_FORMAT)
                }
            }
        }
    });

    const [ordersActiveFilters, setOrdersActiveFilters] = useState({ ...ordersTableWrapperData.defaultFiltersObj });
    const [ordersListToShow, setOrdersListToShow] = useState([]);
    const [isDisplayCount, setIsDisplayCount] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [openConsignmentDetailsPopup, setOpenConsignmentDetailsPopup] = useState(false);
    const [openDriverAssignmentPopup, setOpenDriverAssignmentPopup] = useState(false);
    const [consignmentData, setConsignmentData] = useState({});
    const [renderExcelProgress, setRenderExcelProgress] = useState(false);
    const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
    const snackbarMessage = useRef("");

    const handleDownloadLogs = useCallback(
        (consignment:any) => {
            if (ordersActiveFilters) {
                let payloadFilters:any = _.cloneDeep(ordersActiveFilters);
                payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
                const payload = createFilterPayload(payloadFilters, ordersData, false, hubCountryCode, activeOrdersFilterChip);
                payload.searchAttribute = {
                    key: 'consignmentCode',
                    value: consignment?.consignmentCode
                }
                dispatch(
                    downloadOrderLogs({
                        payload: payload
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, ordersData, hubCountryCode, activeOrdersFilterChip, ordersActiveFilters]
    )

    const handleExcelExport = useCallback(
        (filters: any) => {
            if (filters) {
                let payloadFilters = _.cloneDeep(filters);
                payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
                const payload = createFilterPayload(payloadFilters, ordersData, false, hubCountryCode, activeOrdersFilterChip);
                dispatch(
                    exportToExcel({
                        payload: payload
                    })
                );
            }
            setRenderExcelProgress(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, ordersData, hubCountryCode, activeOrdersFilterChip]
    )

    const handleOrdersHeaderButtonClick = useCallback(
        (btnObj: any) => {
            if (btnObj && btnObj.type) {
                switch (btnObj.type) {
                    case 'excel':
                        handleExcelExport(ordersActiveFilters);
                        break;
                    default:
                        break;
                }
            }
        },
        [ordersActiveFilters, handleExcelExport]
    );

    const ordersSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
        const activeFilters = _.cloneDeep(filters);
        onLoadRef.current = false;
        setOrdersSearchValue(updatedSearchValue);
        setIsOrderSearched(false);
        if (updatedSearchValue) {
            setIsOrderSearched(true);
            for (const searchKey in data) {
                if (data[searchKey]) {
                    activeFilters.searchAttribute = {
                        key: searchKey,
                        value: data[searchKey]
                    }
                    ordersFilterCallback(activeFilters);
                    return;
                }
            }
        }
    };

    const getFilterChipsCount = useCallback(
        (payload: any) => {
            setIsDisplayCount(false);
            dispatch(
                fetchOrdersFilterChipsDisplayCount({
                    payload: payload
                })
            );
            setIsDisplayCount(true);
        },
        [dispatch],
    )

    const ordersFilterCallback = useCallback(
        (filters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean, selectedChip?: any, isChipClicked?: boolean) => {
            if (filters) {
                let payloadFilters = _.cloneDeep(filters);
                payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
                if (!isChipFilterClicked) {
                    selectedChip = activeOrdersFilterChip;
                }
                const payload = createFilterPayload(payloadFilters, ordersData, isLoadMore, hubCountryCode, selectedChip);
                let payloadFilters2 = _.cloneDeep(filters);
                payloadFilters2.pageSize = ordersTableWrapperData.loadMore.pageSize;
                const payloadForCount = createChipsCountFilterPayload(payloadFilters2, ordersData, isLoadMore, hubCountryCode);
                getFilterChipsCount(payloadForCount);
                if(isLoadMore){
                    setIsTabChange(false);
                  }
                dispatch(
                    fetchPlanningOrderListByFilter({
                        filters: payload
                    })
                );
                setOrdersActiveFilters(filters);
            }
            setRenderExcelProgress(false);
        },
        [ordersData, ordersTableWrapperData, activeOrdersFilterChip, dispatch, hubCountryCode, getFilterChipsCount],
    );

    const getFilterChips = () => {
        const filterChips = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.FILTER_CHIPS;
        return filterChips.map((chip: any, index: number) => (
            <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem ${index === 0 ? 'firstItem' : ''} ${activeOrdersFilterChip === chip.code ? 'active' : ''}`} onClick={() => handleChipFilter(chip, ordersActiveFilters)}>
                <Grid container className="chipContainer">
                    <Grid item className="chipItem name">
                        <Typography>{chip.value}</Typography>
                    </Grid>
                    <Grid item className="chipItem count">
                        <Typography>{ordersFilterChipsDisplayCount.consignmentCount[chip.code]}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        ))
    };

    const handleConsignmentCellClick = useCallback(
        (params: CellParams) => {
            if (params.field === 'consignmentId') {
                const selectedRow: any = params.rowData;
                const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
                setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
                setOpenConsignmentDetailsPopup(true);
            }
            if (params.field === 'actions') {
                const selectedRow: any = params.rowData;
                if (selectedRow.actions !== null) {
                    const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
                    setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
                    setOpenDriverAssignmentPopup(true);
                }
            }
        },
        [ordersData]
    );

    const openSnackbarPopup = (msg: string, type: string) => {
        snackbarMessage.current = msg;
        snackbarType.current = type;
        setOpenSnackbar(true);
    }

    const handleSnackbarClose = useCallback(
        () => {
            setOpenSnackbar(false);
        },
        [],
    );

    const handleSnackbarExited = useCallback(
        () => {
            if (errorCode === AppConstants.EXPRESS_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
                dispatch(
                    userLogout()
                );
            }
        },
        [dispatch, errorCode]
    );

    const handleProgressClose = useCallback(
        () => {
            setRenderExcelProgress(false);
        },
        [],
    );

    const handleLoadMoreDisableState = useCallback(
        () => {
            let loadMoreState = ordersTableWrapperData.loadMore;
            loadMoreState.disabled = ordersData.totalPages ? ordersData.pageNumber + 1 >= ordersData.totalPages : true;
            setOrdersTableWrapperData({ ...ordersTableWrapperData, loadMore: loadMoreState });
        },
        [ordersTableWrapperData, ordersData]
    );

    const handleChipFilter = useCallback(
        (chip: any, activeFilters: any) => {
            let filters = { ...activeFilters };
            setIsTabChange(true);
            onLoadRef.current = false;
            setIsOrderSearched(false);
            if (chip.code !== activeOrdersFilterChip) {
                let isChipClicked = true;
                setActiveOrdersFilterChip(chip.code);
                ordersFilterCallback(filters, false, true, chip.code, isChipClicked);
            }
            setRenderExcelProgress(false);
        },
        [ordersFilterCallback, activeOrdersFilterChip]
    )

    const closeConsignmentDetailsPopup = useCallback(
        () => {
            setOpenConsignmentDetailsPopup(false);
        },
        [],
    );

    const closeDriverAssignmentPopup = useCallback(
        () => {
            setOpenDriverAssignmentPopup(false);
            setConsignmentData({});
        }, []
    );
    
    useEffect(() => {
      if (isLastMileViewer || isOnlyExpLastMileViewer) {
        let headerDataWithoutActions = ordersTableWrapperData.HeaderData.map((data: any) => {
          if (data.field === "actions") {
            data.hide = true;
          }
          return data;
        });
        setOrdersTableWrapperData({...ordersTableWrapperData, HeaderData: headerDataWithoutActions});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastMileViewer, isOnlyExpLastMileViewer]);

    useEffect(() => {
        if (error) {
            openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
        }
    }, [error])

    useEffect(() => {
        if (isOrderSearched && isDisplayCount) {
            const chips = Object.keys(ordersFilterChipsDisplayCount.consignmentCount).filter((key: string) => ordersFilterChipsDisplayCount.consignmentCount[key] > 0);
            let selectedChip;
            let selectedChipValue;
            if (chips.length === 2) {
                selectedChip = chips.filter((key: string) => key !== AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.ALL_FILTER_CHIP);
                selectedChipValue = selectedChip[0];
            } else {
                selectedChip = chips.filter((key: string) => key === AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.ALL_FILTER_CHIP);
                selectedChipValue = selectedChip[0];
            }
            handleChipFilter({ code: chips.length ? selectedChipValue : activeOrdersFilterChip }, ordersActiveFilters)
        } else {
            setOrdersListToShow(expressOrdersDataTransform(ordersData) as any);
            handleLoadMoreDisableState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersFilterChipsDisplayCount, ordersData]);

    useEffect(() => {
        if (driverAssigned) {
            openSnackbarPopup(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DRIVER_ASSIGNED_SUCCESS_MSG, AppConstants.SNACKBAR.TYPES.SUCCESS);
            setIsOrderSearched(false);
            ordersFilterCallback(ordersActiveFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverAssigned])

    useEffect(() => {
        if (isAddressUpdated) {
            openSnackbarPopup(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.ADDRESS_MARKED_UNVERIFIED, AppConstants.SNACKBAR.TYPES.SUCCESS);
            setIsOrderSearched(false);
            ordersFilterCallback(ordersActiveFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddressUpdated])

    useEffect(() => {
        if (replanningConsignmentSuccess || isPolled) {
            openSnackbarPopup(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.REPLANNING_CONSIGNMENT_SUCCESS_MSG, AppConstants.SNACKBAR.TYPES.SUCCESS);
            setIsOrderSearched(false);
            ordersFilterCallback(ordersActiveFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replanningConsignmentSuccess, isPolled])

    useEffect(() => {
        if (areOrderCommentsAdded) {
            setIsOrderSearched(false);
            ordersFilterCallback(ordersActiveFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areOrderCommentsAdded])


    useEffect(() => {
        const ordersHeaderData = ordersTableWrapperData.HeaderData.map((header: any) => {
            if (header.filterObj) {
                if (header.filterObj.fieldName === 'hub') {
                    header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
                }
                if (header.filterObj.fieldName === 'hubName') {
                    header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
                }
                if (header.filterObj.fieldName === 'deliverySlotTime') {
                    header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
                }
                if (header.filterObj.fieldName === 'deliveryStatus') {
                    header.filterObj.items = ordersFiltersDropdowns.statusList || [];
                }
                if (header.filterObj.fieldName === 'status') {
                    header.filterObj.items = ordersFiltersDropdowns.orderStatusList || [];
                }
                if (header.filterObj.fieldName === 'deliveryArea') {
                    header.filterObj.items = ordersFiltersDropdowns.deliveryArea || [];
                }
                if (header.filterObj.fieldName === "addressStatus") {
                     header.filterObj.items = ordersFiltersDropdowns.addressStatusList || [];
                }
                if (header.filterObj.fieldName === "deliveryZone") {
                    header.filterObj.items = ordersFiltersDropdowns.deliveryZonesList || [];
                 }
                if (header.filterObj.fieldName === 'paymentType') {
                    header.filterObj.items = ordersFiltersDropdowns.paymentTypeList || [];
                }
                if (header.filterObj.fieldName === 'driverId') {
                    header.filterObj.items = ordersFiltersDropdowns.driverList || [];
                }
                if (header.filterObj.fieldName === 'vehicle') {
                    header.filterObj.items = ordersFiltersDropdowns.vehicleList || [];
                }
                if (header.filterObj.fieldName === 'deliveryExecutionStatus') {
                    header.filterObj.items = ordersFiltersDropdowns.executionStatusList || [];
                }
            }
            return header;
        });
        const hubsToAdd = hubCode.map((hub: string) => ({
            name: hub,
            value: hub
        }));
        let updatedFilters: any = {};

        setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: ordersHeaderData });
        setActiveOrdersFilterChip(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);
        setIsOrderSearched(false);
        updatedFilters = { ...ordersActiveFilters, hub: { ...ordersActiveFilters.hub, value: hubsToAdd } };
        if (!onLoadRef.current) {
            ordersFilterCallback(updatedFilters);
        }
        onLoadRef.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubCode]);

    useEffect(() => {
        if (ordersTableWrapperData && ordersTableWrapperData.HeaderData) {
            let headerData = ordersTableWrapperData.HeaderData.map((header: any) => {
                if (header.filterObj) {
                    if (header.filterObj.fieldName === 'hub') {
                        header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
                    }
                    if (header.filterObj.fieldName === 'hubName') {
                        header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
                    }
                    if (header.filterObj.fieldName === 'deliverySlotTime') {
                        header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
                    }
                    if (header.filterObj.fieldName === 'deliveryStatus') {
                        header.filterObj.items = ordersFiltersDropdowns.statusList || [];
                    }
                    if (header.filterObj.fieldName === 'deliveryArea') {
                        header.filterObj.items = ordersFiltersDropdowns.deliveryArea || [];
                    }
                     if (header.filterObj.fieldName === "addressStatus") {
                       header.filterObj.items = ordersFiltersDropdowns.addressStatusList || [];
                    }
                    if (header.filterObj.fieldName === "deliveryZone") {
                        header.filterObj.items = ordersFiltersDropdowns.deliveryZonesList || [];
                     }
                    if (header.filterObj.fieldName === 'paymentType') {
                        header.filterObj.items = ordersFiltersDropdowns.paymentTypeList || [];
                    }
                    if (header.filterObj.fieldName === 'driverId') {
                        header.filterObj.items = ordersFiltersDropdowns.driverList || [];
                    }
                    if (header.filterObj.fieldName === 'vehicle') {
                        header.filterObj.items = ordersFiltersDropdowns.vehicleList || [];
                    }
                    if (header.filterObj.fieldName === 'status') {
                        header.filterObj.items = ordersFiltersDropdowns.orderStatusList || [];
                    }
                    if (header.filterObj.fieldName === 'deliveryExecutionStatus') {
                        header.filterObj.items = ordersFiltersDropdowns.executionStatusList || [];
                    }
                }
                return header;
            });
            setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: headerData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersFiltersDropdowns]);

const handleTimerInstances = (id: any) => {
  Countdown.startCountdown(id, TIMER_CONST.globalTimeForRefresh);
  setShowTime(true);
};

    return (
        <div className={classes.root}>
            {loading && <Loader></Loader>}
            <Grid className={classes.container} container>
                <Grid className="planningItem content" item>
                    <Grid container className={classes.tabPanelContainer}>
                        <Grid item className="panelItem chipFilter">
                            <Grid container className="chipsContainer">
                                {
                                    getFilterChips()
                                }
                            </Grid>
                        </Grid>
                        <Grid item className="panelItem gridWrapper">
                            <GridWrapper
                                showHeader={true}
                                checkboxSelection={false}
                                headerData={ordersTableWrapperData.HeaderData}
                                rowData={ordersListToShow}
                                isTabChange={isTabChange}
                                loading={loading ? false : tableLoading}
                                searchTypes={ordersTableWrapperData.SEARCH_TYPES}
                                defaultFilter={ordersTableWrapperData.defaultFiltersObj}
                                activeFilter={ordersActiveFilters}
                                headerButtons={ordersTableWrapperData.headerButtons}
                                title={ordersTableWrapperData.title}
                                disableSelectionOnClick={true}
                                sortModel={ordersTableWrapperData.sortModel as SortModel}
                                searchValue={ordersSearchValue}
                                loadMore={{ ...ordersTableWrapperData.loadMore, rowCount: ordersData.totalElements || 0 }}
                                headerBtnClick={handleOrdersHeaderButtonClick}
                                searchCallback={ordersSearchFilterHandler}
                                filterGrid={ordersFilterCallback}
                                onCellClick={handleConsignmentCellClick}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {!error && renderExcelProgress ? (
                    <Grid container className={classes.excelDownloadStatus}>
                        {excelLoading ? (
                            <Grid item>
                                <Typography className={classes.excelDownloadStatusText}>
                                    Your files are getting downloaded
                                </Typography>
                            </Grid>) : (
                                <Grid item>
                                    <Typography className={classes.excelDownloadStatusText}>
                                        Your files have been downloaded
                                    </Typography>
                                </Grid>
                            )}
                        {excelLoading ? <Loader></Loader> :
                            <Grid item style={{ display: "flex" }}>
                                <SvgIcon className={classes.checkedIconStyle} component={checkIcon} />
                                <IconButton aria-label="close" className={classes.close} onClick={handleProgressClose}>
                                    <CloseIcon className={classes.closeIcon} />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>) : (
                        exportError === "INTERNAL_SERVER_ERROR" && renderExcelProgress ? (
                            <Grid container className={classes.excelDownloadError}>
                                <Grid item>
                                    <Typography className={classes.excelDownloadStatusText}>
                                        Error Downloading the file. Try Again!!
                                    </Typography>
                                </Grid>
                                <Grid item style={{ display: "flex" }}>
                                    <IconButton aria-label="close" className={classes.close} onClick={handleProgressClose}>
                                        <CloseIcon className={classes.closeIcon} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) : null)}
                {openConsignmentDetailsPopup && <ExpressOrderDetails open={openConsignmentDetailsPopup} consignment={consignmentData} closePopup={closeConsignmentDetailsPopup} handleTimerInstances={handleTimerInstances} timerStarted={showTime} setShowTime={setShowTime} handleDownloadLogs={handleDownloadLogs}></ExpressOrderDetails>}
                <DriverAssignment open={openDriverAssignmentPopup} consignment={consignmentData} closePopup={closeDriverAssignmentPopup}></DriverAssignment>
                <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
            </Grid>
        </div >
    );
}

export default Express;