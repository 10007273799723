import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverFilter: {
      maxHeight: 268,
      '& .MuiAutocomplete-popper': {
        maxHeight: 176,
        overflowY: "auto"
      }
    },
    buttonsContainer: {
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center"
    },
    rootIcon: {
      width: 24,
      height: 24,
      verticalAlign: "middle",
      cursor: "pointer",
      marginLeft: theme.spacing(1),
      '&.addMargin': {
        marginLeft: theme.spacing(0.5)
      }
    },
    button: {
      width: "50%",
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
      '&:last-child': {
        marginRight: 0
      }
    },
    paperProps: {
      minWidth: 200,
      maxHeight: 300,
      padding: theme.spacing(2),
      marginTop: theme.spacing(1.5),
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: 4,
      boxShadow: "none",
      overflow: "hidden"
    },
    customBadge:{
      backgroundColor: COLORS.AMBER,
    },
    root: {
      margin: theme.spacing(1),
      '& .MuiOutlinedInput-root': {
        width: 200,
        minWidth: 180
      }
    }

  })
);