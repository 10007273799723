import axios from "axios";
import { delveBaseURL } from "../config/Api.config";

import { replaceUrlVariables } from "../utils/updateUrlParams";
import { NetworkCallPayload } from "./networkHandler.types";

const networkHandlerDelve = async (params: NetworkCallPayload) => {
  const { url, urlParams, ...restApiOptions } = params;
  let updateUrl = url;
  if (urlParams) {
    updateUrl = replaceUrlVariables(url, urlParams);
  }

  axios.defaults.baseURL = delveBaseURL;
  axios.defaults.headers.common = {
    Authorization: sessionStorage.getItem("token"),
  };
  axios.defaults.headers.common["system-id"] = "CWINGS";

  return await axios.request({ url: updateUrl, ...restApiOptions });
};

export default networkHandlerDelve;
