
export const persistState = {
    add(key:string, value: object) {
        sessionStorage.setItem(key, JSON.stringify(value));
    },
    remove(key:string) {
        sessionStorage.removeItem(key);
    },
    load(key:string ) {
        const stored = sessionStorage.getItem(key);
        return stored == null ? undefined : JSON.parse(stored);
    },
};