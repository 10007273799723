import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { CODReconState } from "./codPortalSlice.types";
import AppConstants from "../../../constants";
import { transformSelectValues, saveFile } from "../../../utils/helpers.utils";
import moment from "moment";

export const fetchDriversList = createAsyncThunk(
    "CODPortal/FetchDriversList",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/filter-drivers",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchOrdersList = createAsyncThunk(
    "CODPortal/FetchOrdersList",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/filter-orders",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const exportToExcel = createAsyncThunk(
    "CODPortal/ExportOrderToExcel",
    async (
        {
            payload,
        }: {
            payload: object;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/export",
                method: EApiMiddlewareMethods.POST,
                data: payload,
                responseType: "blob",
            } as any;
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const reconcileCollectedAmtForOrder = createAsyncThunk(
    "CODPortal/ReconcileCollectedAmtForOrder",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/collect",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createReceiptDraft = createAsyncThunk(
    "CODPortal/CreateReceiptDraft",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/receipt/draft",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const uploadReceipt = createAsyncThunk(
    "CODPortal/UploadReceipt",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/receipt",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            request.headers = {
                "content-type": "multipart/form-data",
            };
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const deletePrevUploadedReceipt = createAsyncThunk(
    "CODPortal/DeletePrevUploadedReceipt",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/receipt",
                method: EApiMiddlewareMethods.DELETE,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const submitReceipts = createAsyncThunk(
    "CODPortal/SubmitReceipts",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "reconcile/receipt/submit",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const openCollectionConfirmation = createAsyncThunk("CODPortal/OpenCollectionConfirmation", () => {
    return false;
});

export const uploadClick  = createAsyncThunk("CODPortal/UploadClick", () => {
    return false;
});

const initialState: CODReconState = {
    loading: false,
    error: "",
    errorCode: "",
    expDriversData: {
        elements: [],
        pageNumber: 0,
    },
    expOrdersData: {
        elements: [],
        pageNumber: 0,
    },
    excelLoading: false,
    expDriversFiltersDropdowns: {
        driverNameList: [],
        vendorNameList: [],
    },
    expOrdersFiltersDropdowns: {
        deliveryStatusList: [],
    },
    orderAfterCollection: {},
    isAmtCollectedForExpressOrders: false,
    draftReceiptData: {},
    receiptDataAfterUpload: {},
    isFileUploaded: false,
    uploadError: "",
    uploadErrorCode: "",
    receiptDataAfterSubmit: {},
    receiptsSubmittedFlag: false,
    expOrdersOnDriverDetails: '',
    expCodAmtOnDriverDetails: '',
    expCodPendingOnDriverDetails: '',
    expCodReceivedOnDriverDetails: '',
    isErrorOnUpload: false,
    expTotalOrdersAmtOnDriverDetails: ''
};

const codPortalSlice = createSlice({
    name: "CODPortal",
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders
            .addCase(fetchDriversList.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(fetchDriversList.fulfilled, (state, action) => {
                const {
                    payload,
                    meta: {
                        arg: { params },
                    },
                } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.expDriversData = {
                    ...payload,
                    elements:
                        params.pageNumber === 0
                            ? payload?.results?.elements
                            : [...state.expDriversData.elements, ...payload?.results?.elements],
                    pageNumber: payload?.results?.pageNumber,
                    totalElements: payload?.results?.totalElements,
                    totalPages: payload?.results?.totalPages,
                };
                if (payload?.results?.filters) {
                    state.expDriversFiltersDropdowns = {
                        driverNameList: transformSelectValues(payload.results.filters.driverNames || []),
                        vendorNameList: transformSelectValues(payload.results.filters.vendorNames || []),
                    };
                }
                state.receiptDataAfterUpload = {};
                state.isFileUploaded = false
            })
            .addCase(fetchDriversList.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(fetchOrdersList.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(fetchOrdersList.fulfilled, (state, action) => {
                const {
                    payload,
                    meta: {
                        arg: { params },
                    },
                } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.expOrdersData = {
                    ...payload,
                    elements:
                        params.pageNumber === 0
                            ? payload.results.elements
                            : [...state.expOrdersData.elements, ...payload.results.elements],
                    pageNumber: payload.results.pageNumber,
                    totalElements: payload.results.totalElements,
                    totalPages: payload.results.totalPages,
                };
                if (payload?.results?.filters) {
                    state.expOrdersFiltersDropdowns = {
                        deliveryStatusList: transformSelectValues(payload.results.filters.statuses || []),
                    };
                }
                state.expOrdersOnDriverDetails = payload?.results?.totalOrders;
                state.expCodAmtOnDriverDetails = payload?.results?.totalCODAmount;
                state.expCodPendingOnDriverDetails = payload?.results?.totalCODPending;
                state.expCodReceivedOnDriverDetails = payload?.results?.totalCODReceived;
                state.expTotalOrdersAmtOnDriverDetails = payload?.results?.totalOrdersCODAmount;
            })
            .addCase(fetchOrdersList.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(exportToExcel.pending, (state) => {
                state.excelLoading = true;
                state.error = "";
            })
            .addCase(exportToExcel.fulfilled, (state, action) => {
                const { payload } = action;
                state.excelLoading = false;
                state.errorCode = "";
                state.error = "";
                if (payload) {
                    const currentDate = moment().format("YYYY-MM-DD");
                    const currentTime = moment().format("HH-mm");
                    let filename = `COD_RECON_${currentDate}_${currentTime}.xlsx`;
                    saveFile(payload, filename);
                }
            })
            .addCase(exportToExcel.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.excelLoading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(reconcileCollectedAmtForOrder.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(reconcileCollectedAmtForOrder.fulfilled, (state, action) => {
                const { payload } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.orderAfterCollection = payload?.results;
                state.isAmtCollectedForExpressOrders = true;
            })
            .addCase(reconcileCollectedAmtForOrder.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(openCollectionConfirmation.fulfilled, (state, action) => {
                state.isAmtCollectedForExpressOrders = false;
            })
            .addCase(uploadClick.fulfilled, (state,action)=>{
                state.isFileUploaded = false;
                state.receiptsSubmittedFlag = false;
                state.isErrorOnUpload = false;
            })
            .addCase(createReceiptDraft.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(createReceiptDraft.fulfilled, (state, action) => {
                const {
                    payload
                } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.draftReceiptData = payload?.results;
            })
            .addCase(createReceiptDraft.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(deletePrevUploadedReceipt.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(deletePrevUploadedReceipt.fulfilled, (state, action) => {
                const {
                    payload
                } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.draftReceiptData = payload?.results;
            })
            .addCase(deletePrevUploadedReceipt.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(uploadReceipt.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(uploadReceipt.fulfilled, (state, action) => {
                const {
                    payload
                } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.isFileUploaded = true;
                state.receiptsSubmittedFlag =false;
                state.receiptDataAfterUpload = payload?.results;
            })
            .addCase(uploadReceipt.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.isErrorOnUpload = true;
                state.uploadErrorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.uploadError = errorPayload
                    ? errorPayload.error.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(submitReceipts.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(submitReceipts.fulfilled, (state, action) => {
                const {
                    payload
                } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.receiptsSubmittedFlag = true;
                state.isFileUploaded= false;
                state.receiptDataAfterSubmit = payload?.results;
            })
            .addCase(submitReceipts.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            });
    },
});

export default codPortalSlice.reducer;
