import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 18,
          fontWeight: 500,
          '& .content': {
            fontWeight: 600
          }
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        },
        "& .statusContainer": {
          "& .statusIcon": {
            width: 12,
            height: 12,
            borderRadius: "50%",
            '&.yellow': {
              backgroundColor: COLORS.STATUS_YELLOW
            },
            '&.black': {
              backgroundColor: COLORS.TEXT_PRIMARY
            },
            '&.green': {
              backgroundColor: COLORS.PERSIAN_GREEN
            },
            '&.red': {
              backgroundColor: COLORS.DARK_RED
            },
            '&.gray': {
              backgroundColor: COLORS.GRAY_DARK
            },
          },
          '& .statusMsg': {
            paddingLeft: theme.spacing(1),
            '& .status': {
              fontSize: 12
            }
          }
        },
        '& .editIcon': {
          paddingLeft: theme.spacing(1.5),
          '& .icon': {
            cursor: "pointer",
            verticalAlign: "middle"
          }
        }
      },
      '& .tagContainer': {
        cursor: "pointer",
        marginLeft: theme.spacing(2),
        alignSelf: "center"
      }
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .detailsHeader': {
        backgroundColor: COLORS.TRANSPARENT_BLUE,
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1),
        '& .detailsHeaderItem': {
          padding: theme.spacing(2.5, 0, 0, 6),
          '&:first-child': {
            padding: 0
          },
          '& .profileIcon': {
            width: 140,
            height: 140
          },
          '& .rightPanelContainer': {
            flexDirection: "column",
            '& .rightPanelItem': {
              paddingBottom: theme.spacing(1.5),
              '&:last-child': {
                paddingBottom: 0
              },
              '& .endSessionBtn': {
                minWidth: "245px"
              },
              '& .fullname': {
                fontSize: 24,
                fontWieght: 600
              },
              '& .logo': {
                width: 20,
                height: 20
              },
              '& .contact': {
                '& a': {
                  fontSize: 14,
                  fontWeight: 600,
                  textDecoration: "none",
                  color: theme.palette.text.primary
                },
                paddingLeft: theme.spacing(1),
                '&.mobile': {
                  paddingLeft: theme.spacing(1)
                }
              }
            }
          }
        }
      },
      '& .detailsContent': {
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: theme.spacing(3, 0, 2, 0),
        '& .contentRow': {
          paddingBottom: theme.spacing(3),
          '& .contentRowContainer': {
            justifyContent: "space-between"
          },
          '&:last-child': {
            paddingBottom: 0
          },
          '& .contentColumn': {
            flex: "2 0 0",
            '&:last-child': {
              flex: "1.5 0 0"
            },
            '& p': {
              fontSize: 14,
              lineHeight: "17px"
            },
            '& .label': {
              paddingBottom: theme.spacing(1)
            },
            '& .content': {
              fontWeight: 600
            }
          },
          '&.twoItems': {
            '& .contentColumn': {
              '&:last-child': {
                flex: "2.5 0 0"
              }
            }
          },
          '&.btnContainer': {
            '& .contentColumn': {
              flex: "1 0 auto",
              '& .resetPasswordBtn': {
                width: "100%"
              },
              '& .endSessionBtn': {
                width: "300px"
              },
            }
          }
        }
      },
      '& .hubItemsSection': {
        '& .content': {
          width: "100%",
          '& .itemsContainer': {
            flexWrap: "nowrap",
            '& .item': {
              padding: theme.spacing(2, 0),
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
              "&:first-child": {
                padding: 0,
                paddingBottom: theme.spacing(2)
              },
              '&:last-child': {
                borderBottom: "none"
              },
              '& .itemContainer': {
                justifyContent: "space-between",
                '& p': {
                  fontSize: 14,
                  lineHeight: "17px",
                  fontWeight: 600
                },
                '&.header p': {
                  color: COLORS.DOVE_GRAY,
                  fontWeight: 500,
                }
              }
            }
          }
        }
      }
    },
    accordionSummary: {
      '& .header': {
        fontSize: 14,
        lineHeight: "17px",
        fontWeight: 600
      },
      '& .content': {
        flexGrow: 1
      }
    },
    buttons: {
      width: "100%",
      '& .userBtn': {
        '&.secondary': {
          marginRight: theme.spacing(2)
        },
        flexGrow: 1
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 580
    },
    endSessionSelectionDialogTitle: {
      fontSize: 20,
      fontWeight: 600,
      padding: "6px 0px 0px 12px"
    },
    endSessionSelectionDialogPaperPropsRoot: {
      width: 428,
      height: 242,
      margin: "auto",
      borderRadius: theme.spacing(1)
    },
    content: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "15px",
      color: "#252525"
    },
    endSessionSelectionDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .contentItem': {
        '&.heading': {
          '& p': {
            fontSize: 14,
            fontWeight: 400
          }
        },
        '&.hub': {
          paddingTop: theme.spacing(3.75)
        }
      }
    },
    endSessionDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(1),
      '& .dialogBtn': {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(4.5),
        '&.secondary': {
          marginRight: theme.spacing(1)
        },
        '&.primary': {
          marginLeft: theme.spacing(1)
        }
      }
    },
  }),
);