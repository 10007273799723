import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      flexGrow: 1,
      margin: theme.spacing(2),
    },
    content: {
      fontSize: "14px",
      fontWeight: 600
    }
  })
);