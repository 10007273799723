import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
            height: "100%",
            backgroundColor: COLORS.ROUTE_CONTAINER_BACKGROUND,
            overflow: "scroll"
        },
        title: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "29px",
            color: COLORS.MINE_SHAFT
        },
        subTitle: {
            display: "flex",
            justifyContent: "center",
            fontSize: 10,
            lineHeight: "16px",
            fontWeight: 500,
            fontFamily: "Montserrat",
            color: COLORS.TEXT_PRIMARY
        },
        select: {
            borderRadius: 8,
            background: COLORS.WHITE,
            minWidth: 150
        },
        slotsSelect: {
            borderRadius: 8,
            background: COLORS.WHITE
        },
        icon: {
            "& .MuiOutlinedInput-input": {
                height: "40px",
            },
        },
        consignments: {
            background: COLORS.WHITE,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
            borderRadius: 8,
            padding: "6px 24px",
            height: "58px"
        },
        routes: {
            background: COLORS.WHITE,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 8,
            padding: "16px 24px",
        },
        deliverySection: {
            padding: "24px 24px 0px 24px"
        },
        totalText: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "21px",
            color: COLORS.BLACK,
        },
        consignmentText: {
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "21px",
            color: COLORS.BLACK,
        },
        consignmentPercent: {
            fontSize: 32,
            fontWeight: 600,
            lineHeight: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        subText: {
            fontSize: 12,
            lineHeight: "12px",
            color: COLORS.COPY,
            marginTop: theme.spacing(1)
        },
        consignmentData: {
            fontSize: 24,
            color: COLORS.TEXT_PRIMARY,
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            padding: theme.spacing(1)
        },
        statusTiles: {
            display: "flex",
        },
        tilesView: {
            flexGrow: 1,
        },
        planned: {
            height: 80,
            background: "linear-gradient(180deg, #843D77 0%, #A7689B 100%)",
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 12,
        },
        outDelivery: {
            height: 80,
            background: "linear-gradient(180deg, #1B96C3 0%, #41BCEA 100%);",
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 12,
        },
        full: {
            height: 80,
            background: "linear-gradient(180deg, #3ABA71 0%, #7FD2A3 100%);",
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 12,
        },
        unplanned: {
            height: 80,
            background: "linear-gradient(180deg, #CF9E36 0%, #F5C45D 100%)",
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 12,
            minWidth: 170
        },
        undelivered: {
            height: 80,
            background: "linear-gradient(180deg, #CE3637 0%, #F78283 100%)",
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 12,
            minWidth: 170
        },
        globalStatusTiles: {
            background: "#FFFFFF",
            borderRadius: "8px",
            height: "58px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        },
        statusTilesTitle: {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            paddingTop: "8px",
            paddingLeft: "12px",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#979797",
        },
        routeStatusTilesTitle: {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            paddingTop: "8px",
            paddingLeft: "12px",
            fontSize: "16px",
            lineHeight: "18px",
            color: "#979797",
        },
        data: {
            fontSize: 20,
            fontWeight: 600,
            paddingLeft: 18,
            color: "#4D4D4D"
        },
        tiles: {
            display: "flex",
            justifyContent: "space-between",
            background: COLORS.WHITE,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 8,
            height: "62px",
            padding: theme.spacing(2)
        },
        tileContent: {
            display: "flex",
            alignItems: "center"
        },
        deliveryText: {
            background: COLORS.WHITE,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
            borderRadius: 8,
            fontSize: 16,
            color: COLORS.BLACK,
            fontWeight: 500,
            lineHeight: "20px",
            marginTop: theme.spacing(2)
        },
        details: {
            cursor: "pointer",
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px"
        },
        subDivision: {
            marginTop: "4px",
            padding: "0px 24px"
        },
        subDivisionHandover: {
            background: COLORS.PALE_PURPLE,
            borderRadius: 8,
            padding: theme.spacing(1),
            height: "100px",
            display: "flex",
            alignItems: "center",
        },
        subDivisionOntime: {
            background: COLORS.PALE_GREEN,
            borderRadius: 8,
            padding: theme.spacing(1),
            height: "100px",
            display: "flex",
            alignItems: "center",
        },
        deliveryTiles: {
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(2),
        },
        deliveryTileContent: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "29px",
            color: COLORS.WHITE
        },
        deliveryTilesText: {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: "15px",
            color: COLORS.WHITE
        },
        subContainer: {
            marginTop: theme.spacing(2),
            flexWrap: "nowrap"
        },
        firstSubContainer: {
            width: "408px"
        },
        subDiv: {
            flexGrow: 1
        },
        chartDiv: {
            flexWrap: "nowrap",
            height: 300,
        },
        firstTileSection: {
            padding: "16px 24px"
        },
        secondTileSection: {
            padding: "0px 24px 0px 24px"
        },
        switchStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            fontSize: "14px",
            fontWeight: 600,
        },
        graphDivision: {
            background: COLORS.WHITE,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
            borderRadius: 8,
            padding: theme.spacing(3),
        },
        tabStylePerformance: {
            border: `1px solid ${COLORS.SECONDARY_DARK}`,
            background: COLORS.MADISON_LIGHT,
            marginRight: "0px",
            padding: "4px 12px",
            borderRadius: "4px 0px 0px 4px"
        },
        tabStyleVolume: {
            border: `1px solid ${COLORS.SECONDARY_DARK}`,
            background: COLORS.MADISON_LIGHT,
            marginRight: "0px",
            padding: "4px 12px",
            borderRadius: "0px 4px 4px 0px"
        },
        horizontalLine: {
            borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
            marginTop: theme.spacing(3),
            width: "100%",
        },
        statusText: {
            color: COLORS.TEXT_PRIMARY,
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 600
        },
        totalData: {
            background: COLORS.PRIMARY_MAIN,
            borderRadius: 108,
            color: COLORS.WHITE,
            fontWeight: 600,
            padding: "8px 12px",
            fontSize: 16
        },
        onTimeData: {
            background: COLORS.OCEAN_GREEN,
            borderRadius: "108px",
            color: COLORS.WHITE,
            fontWeight: 600,
            padding: "8px 12px",
            fontSize: 16
        },
        lateData: {
            background: COLORS.GOLDEN_BELL,
            borderRadius: "108px",
            color: COLORS.WHITE,
            fontWeight: 600,
            padding: "8px 12px",
            fontSize: 16
        },
        atRiskData: {
            background: COLORS.DARK_RED,
            borderRadius: "108px",
            color: COLORS.WHITE,
            fontWeight: 600,
            padding: "8px 12px",
            fontSize: 16
        },
        driversTitle: {
            fontSize: 16,
            lineHeight: "20px",
            fontWeight: 500,
            color: COLORS.BLACK
        },
        driversSubTitle: {
            fontSize: 10,
            lineHeight: "12px",
            color: COLORS.COPY,
            fontWeight: 500,
            paddingTop: theme.spacing(1)
        },
        pieDivision: {
            background: COLORS.WHITE,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
            borderRadius: 8,
            padding: theme.spacing(3),
            marginTop: theme.spacing(2)
        },
        plannedRoutes: {
            height: 80,
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 12,
            background: COLORS.WHITE,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        },
        inProgress: {
            height: 80,
            background: COLORS.WHITE,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 12,
        },
        completed: {
            height: 80,
            background: COLORS.WHITE,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 8,
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 12,
        },
        unplannedSection: {
            display: "flex",
            alignContent: "center",
            padding: theme.spacing(2),
        },
        warningUnplanned: {
            background: COLORS.BASE_RED,
            borderRadius: 8,
            marginTop: "8px"
        },
        warningIcon: {
            width: "20px",
            marginRight: "12px"
        },
        unplannedText: {
            fontSize: 16,
            fontWeight: 600,
            color: COLORS.TEXT_PRIMARY,
            display: "flex",
            alignContent: "center",
            padding: theme.spacing(2),
        },
        hubSelectionDialogPaperPropsRoot: {
            width: 500,
            height: 300,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        hubSelectionDialogTitle: {
            fontSize: 20,
            fontWeight: 600
        },
        hubSelectionDialogContentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .contentItem': {
                '&.heading': {
                    '& p': {
                        fontSize: 14,
                        fontWeight: 400
                    }
                },
                '&.hub': {
                    paddingTop: theme.spacing(3.75)
                }
            }
        },
        hubSelectionDialogButtonsContainer: {
            justifyContent: "flex-end",
            paddingBottom: theme.spacing(7),
            '& .dialogBtn': {
                paddingLeft: theme.spacing(4.5),
                paddingRight: theme.spacing(4.5),
                '&.secondary': {
                    marginRight: theme.spacing(1)
                },
                '&.primary': {
                    marginLeft: theme.spacing(1)
                }
            }
        },

    })
);