import {newDate} from "utils/dateUtils";
import {generateRandomNumber} from "../utils/helpers.utils";
const StdCodPortalConstants = {
  STD_COD_RECON_CONSTANTS: {
    DRIVERS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Drivers",
        HeaderData: [
          {
            field: "id",
            headerName: "Id",
            hide: true,
          },
          {
            field: "driverName",
            headerName: "Driver Name",
            width: 100,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "driverName",
              fieldName: "driverName",
              fieldFullName: "driverName",
              multiple: true,
              key: `autoComplete-driverName-${generateRandomNumber(0, 1000)}`,
              label: "Driver Name",
              items: [],
            },
          },
          {
            field: "totalOrders",
            headerName: "Total COD Orders",
            width: 130,
          },
          {
            field: "codCollected",
            headerName: "Total COD Collected (By Driver)",
            width: 186,
          },
          {
            field: "codReceived",
            headerName: "COD amount recieved (By Dispatcher)",
            width: 180,
          },
          {
            field: "codPending",
            headerName: "Total COD amount Due",
            width: 180,
          },
          {
            field: "vendor",
            headerName: "Vendor Name",
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "vendorName",
              fieldName: "vendorName",
              fieldFullName: "vendorName",
              multiple: true,
              key: `autoComplete-vendorName-${generateRandomNumber(0, 1000)}`,
              label: "Vendor",
              items: [],
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 100,
            hide: false,
          },
        ],
        headerButtons: [
          {
            name: "colsettings",
            displayName: "Column Settings",
            disabled: false,
            childElements: [
              {
                name: "excel",
                displayName: "EXPORT DATA",
                disabled: false,
              },
            ],
          },
        ],
        loadMore: {
          name: "loadMore",
          disabled: false,
          pageSize: 50,
        },
        defaultFiltersObj: {
          pageNumber: 0,
          driverName: {
            key: "driverNames",
            value: [],
          },
          vendorName: {
            key: "vendorNames",
            value: [],
          },
          dateRange: {
            key: "dateRange",
            value: {
              startDate: newDate(),
              endDate: newDate(),
            },
          },
        },
        SEARCH_TYPES: [
          {
            name: "Driver Name",
            value: "driverName",
          },
        ],
      },
    },

    ORDERS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Orders",
        HeaderData: [
          {
            field: "id",
            headerName: "Id",
            hide: true,
          },
          {
            field: "orderNo",
            headerName: "Order Number",
            width: 150,
          },
          {
            field: "deliveryStatus",
            headerName: "Delivery Status",
            width: 186,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryStatus",
              fieldName: "deliveryStatus",
              fieldFullName: "deliveryStatus",
              multiple: true,
              key: `autoComplete-deliveryStatus-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Status",
              items: [],
            },
          },
          {
            field: "orderDate",
            headerName: "Order Date",
            width: 140,
          },
          {
            field: "slot",
            headerName: "Slot",
            width: 140,
          },
          {
            field: "codCollected",
            headerName: "Total COD Collected (By Driver)",
            width: 130,
            hide: false,
          },
          {
            field: "codReceived",
            headerName: "COD Amount Received (By Dispatcher)",
            width: 140,
            hide: false,
          },
          {
            field: "codAmountDue",
            headerName: "Total COD Amount Due",
            width: 180,
            hide: false,
          },
          {
            field: "remarks",
            headerName: "Remarks",
            width: 180,
            hide: false,
          },
          {
            field: "collectionStatus",
            headerName: "Collection Status",
            width: 100,
            hide: false,
          },
        ],
        loadMore: {
          name: "loadMore",
          disabled: false,
          pageSize: 100,
        },
        defaultFiltersObj: {
          pageNumber: 0,
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
          },
          dateRange: {
            key: "dateRange",
            value: {
              startDate: newDate(),
              endDate: newDate(),
            },
          },
        },
        SEARCH_TYPES: [
          {
            name: "Consignment ID",
            value: "consignmentCode",
          },
        ],
      },
    },
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
        CONCURRENT_CONSIGNMENT: "CWO15",
      },
    },
  },
};
export default StdCodPortalConstants;
