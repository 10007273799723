import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: "100%"
    },
    popperStyle: {
      fontSize: "10px",
      fontWeight: 500,
      color: COLORS.WHITE,
      width: "229px",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px"
    },
    arrowStyle: {
      color: COLORS.WHITE
    },
    tooltipStyle: {
      background: COLORS.WHITE,
      color: COLORS.BLACK
    },
    tooltipTitleSection: {
      display: "flex",
      flexDirection: "column",
      padding: "12px"
    },
    tooltipHeading: {
      fontSize: "14px",
      fontWeight: 700
    },
    tooltipData: {
      fontSize: "12px",
      fontWeight: 400
    },
    customIcon:{
      padding: "unset",
    }
  })
);
