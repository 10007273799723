import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedTextFieldProps, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useStyles } from './TextBox.styles';

interface TextBoxProps extends OutlinedTextFieldProps {
  textBoxId: string;
  label?: string;
  placeholderText?: string;
  endadornment?: React.ReactNode;
}

const TextBox = (props: TextBoxProps) => {
  const classes = useStyles();
  const { variant, label, textBoxId, placeholderText, className, fullWidth, InputProps, ...rest } = props;
  let { type, endadornment } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  if (type === "password") {
    type = showPassword ? "text" : "password";
    endadornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <>
      <FormControl className={classes.root} variant={variant} fullWidth={fullWidth}>
        {label && <InputLabel htmlFor={textBoxId} shrink>{label}</InputLabel>}
        <TextField
          {...rest}
          type={type}
          fullWidth={fullWidth}
          variant={variant}
          className={`${className || ''} ${label ? 'hasLabel ' : ''}`}
          id={textBoxId}
          placeholder={placeholderText}
          InputProps={{ ...InputProps, autoComplete: "new-password", endAdornment: endadornment }}
        />
      </FormControl>
    </>
  );
}

export default TextBox;