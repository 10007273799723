import { createStyles, makeStyles, StepConnector, Theme, withStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const StepperTimelineConnector = withStyles({
  alternativeLabel: {
    top: 28,
    left: "calc(-50% + 9px)",
    right: "calc(50% + 9px)",
  },
  active: {
    "& $line": {
      borderColor: COLORS.SECONDARY_DARK,
    },
  },
  line: {
    borderColor: COLORS.SECONDARY_DARK,
    borderTopWidth: 4,
    borderRadius: 100,
  },
})(StepConnector);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0px",
      paddingTop: theme.spacing(1),
      height: "100%",
      "& .padding-2": {
        padding: theme.spacing(2),
      },
    },
    statusHeaderContainer: {
      borderRight: `1px solid ${COLORS.SILVER}`,
      marginRight: theme.spacing(1),
      "& .tag": {
        color: COLORS.WHITE,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        lineHeight: "14px",
        backgroundColor: COLORS.BLACK,
        fontWeight: 500,
        textTransform: "uppercase",
        fontSize: 12,
        marginLeft: theme.spacing(1),
        "&.PICTON_BLUE": {
          color: COLORS.PICTON_BLUE,
          backgroundColor: COLORS.PICTON_BLUE_LIGHT,
        },
        "&.CREAM_CAN": {
          color: COLORS.CREAM_CAN,
          backgroundColor: COLORS.CREAM_CAN_LIGHT,
        },
        "&.BLUE": {
          color: COLORS.PRIMARY_MAIN,
          backgroundColor: COLORS.PRIMARY_LIGHT,
        },
        "&.VISTA_BLUE": {
          color: COLORS.VISTA_BLUE,
          backgroundColor: COLORS.VISTA_BLUE_LIGHT,
        },
        "&.SEA_GREEN": {
          color: COLORS.SEA_GREEN,
          backgroundColor: COLORS.SEA_GREEN_LIGHT,
        },
        "&.GOLDEN_BELL": {
          color: COLORS.GOLDEN_BELL,
          backgroundColor: COLORS.GOLDEN_BELL_LIGHT,
        },
        "&.SCAMPI": {
          color: COLORS.SCAMPI,
          backgroundColor: COLORS.SCAMPI_LIGHT,
        },
        "&.OLD_GOLD": {
          color: COLORS.OLD_GOLD,
          backgroundColor: COLORS.OLD_GOLD_LIGHT,
        },
        "&.SEA_GREEN2": {
          color: COLORS.SEA_GREEN2,
          backgroundColor: COLORS.SEA_GREEN2_LIGHT,
        },
        "&.LUCKY": {
          color: COLORS.LUCKY,
          backgroundColor: COLORS.LUCKY_LIGHT,
        },
        "&.DUSTY_GRAY": {
          color: COLORS.DUSTY_GRAY,
          backgroundColor: COLORS.DUSTY_GRAY_NEW_LIGHT,
        },
        "&.JUNGLE_GREEN": {
          color: COLORS.JUNGLE_GREEN,
          backgroundColor: COLORS.JUNGLE_GREEN_LIGHT,
        },
        "&.BLACK": {
          color: COLORS.BLACK,
          backgroundColor: COLORS.BLACK_LIGHT,
        },
        "&.MINSK": {
          color: COLORS.SCAMPI,
          backgroundColor: COLORS.SCAMPI_LIST,
        },
        "&.BLUE_HAZE": {
          color: COLORS.BLUE_HAZE,
          backgroundColor: COLORS.BLUE_HAZE_LIGHT,
        },
        "&.OCEAN_GREEN": {
          color: COLORS.OCEAN_GREEN,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.OCEAN_GREEN,
        },
        "&.CORAL_PINK": {
          color: COLORS.CORAL_PINK,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.CORAL_PINK,
        },
        "&.ALERT_ORANGE": {
          color: COLORS.ALERT_ORANGE,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.ALERT_ORANGE,
        },
        "&.AVATAR_OCEAN": {
          color: COLORS.AVATAR_OCEAN,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.AVATAR_OCEAN,
        },
        "&.DARK_GOLD_ORANGE": {
          color: COLORS.DARK_GOLD_ORANGE,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.DARK_GOLD_ORANGE,
        },
        "&.ALTO": {
          color: COLORS.ALTO,
        },
        "&.CATSKILL_WHITE": {
          backgroundColor: COLORS.CATSKILL_WHITE,
          color: COLORS.ASTRAL,
        },
        "&.BARLEY_WHITE": {
          backgroundColor: COLORS.BARLEY_WHITE,
          color: COLORS.OLD_GOLD,
        },
      },
    },
    container: {
      flexDirection: "column",
      flexWrap: "nowrap",
      height: "calc(100vh - 176px)",
      "& .createRtItem": {
        width: "100%",
        "&.heading": {
          paddingBottom: theme.spacing(3),
          display: "flex",
          justifyContent: "space-between",
          "& .headingContainer": {
            alignItems: "center",
          },
          "& .editIcon": {
            width: 22,
            height: 22,
            marginLeft: theme.spacing(1),
            cursor: "pointer",
          },
          "& .headingTitle": {
            fontSize: 24,
            color: COLORS.BLACK,
            fontWeight: 600,
          },
        },
        "&.content": {
          height: "100%",
        },
      },
    },
    createRtContentContainer: {
      height: "100%",
      flexWrap: "nowrap",
      "& .contentItem": {
        // boxShadow: `0px 1px 4px ${COLORS.LIGHT_BLACK_SHADOW}`,
        borderRadius: theme.spacing(1),
        height: "100%",
        position: "relative",
        "&.left": {
          width: 500,
          minWidth: 500,
          marginRight: theme.spacing(3),
          "& .consignmentsContainer": {
            flexDirection: "column",
            height: "100%",
            flexWrap: "nowrap",
            overflow: "hidden",
            "& .consignmentItem": {
              "& .headerSectionContainer": {
                flexDirection: "column",

                "&.viewMode": {
                  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                },
                "& .searchTextBox": {
                  "& .MuiOutlinedInput-root": {
                    width: 210,
                  },
                },
                "& .MuiInputLabel-root": {
                  marginTop: -13,
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(5px, -2px) scale(0.75)",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  width: 143,
                  minWidth: 143,
                  height: 24,
                  borderRadius: theme.spacing(0.5),
                  border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid var(--Background-light, #F7F8F9)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  fontSize: 10,
                  padding: 0,
                  paddingLeft: theme.spacing(0.5),
                },
                "& .MuiSelect-iconOutlined": {
                  right: 0,
                },
                "& .MuiSelect-root": {
                  "& .MuiChip-root": {
                    fontSize: 10,
                    height: 15,
                  },
                },
                "& .headerContainer": {
                  alignItems: "center",
                  paddingBottom: theme.spacing(1.5),
                  "& .headerItem": {
                    "&.heading": {
                      flexGrow: 1,
                      "& .refreshIcon": {
                        width: 16,
                        height: 16,
                        cursor: "pointer",
                        marginLeft: theme.spacing(0.5),
                      },
                      "& p": {
                        fontSize: 16,
                        fontWeight: 600,
                      },
                    },
                    "&.icons": {
                      display: "flex",
                      alignItems: "center",
                      "& .MuiOutlinedInput-adornedEnd": {
                        paddingRight: 0,
                        "& .MuiSvgIcon-root": {
                          padding: theme.spacing(0.25),
                        },
                      },
                      "& .MuiInputAdornment-positionEnd": {
                        marginLeft: 0,
                      },
                      "& .MuiIconButton-sizeSmall": {
                        padding: 0,
                        "&.MuiIconButton-edgeEnd": {
                          marginRight: 0,
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        width: 19,
                        height: 19,
                      },
                      "& .icon": {
                        padding: theme.spacing(0.5),
                      },
                    },
                  },
                },
                "& .filtersContainer": {
                  padding: theme.spacing(2, 0, 2, 0),
                  "&.lastItem": {
                    justifyContent: "end",
                    padding: "unset",
                  },
                  "& .filterItem": {
                    paddingRight: theme.spacing(1),
                    "&:last-child": {
                      paddingRight: 0,
                    },
                    "& .icon-datepicker": {
                      marginLeft: 0,
                    },
                    "& .filterName": {
                      fontSize: 9,
                      fontWeight: 600,
                      marginBottom: 4,
                    },
                  },
                },
                "& .searchBoxContainer": {
                  padding: theme.spacing(0, 0, 1, 0),
                  "& .searchTextBox": {
                    "& .MuiOutlinedInput-root": {
                      width: 165,
                      height: 24,
                      borderRadius: theme.spacing(0.5),
                      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
                    },
                  },
                  "& .hubDetailsBox": {
                    flexGrow: 1,
                    "& .hubDetailsBoxContainer": {
                      justifyContent: "flex-end",
                      margin: "auto auto auto 0px",
                      padding: theme.spacing(0, 1, 0, 0),
                      height: 24,
                    },
                  },
                },
                "& .routeDetailsBoxContainer": {
                  padding: theme.spacing(0, 0.5, 0, 0.5),
                  border: "none",
                  borderRadius: theme.spacing(0),
                  margin: theme.spacing(0.5, 0, 0.5, 0),
                  justifyContent: "space-between",
                  "& .routeDetailsBox": {
                    height: theme.spacing(3),
                    width: 155,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: theme.spacing(0, 1, 0, 0),
                    margin: "auto 0px",
                    border: "none",
                    borderRight: `1px solid ${COLORS.SILVER}`,
                    borderRadius: theme.spacing(0),
                    "&:last-child": {
                      border: "none",
                      padding: theme.spacing(0, 0, 0, 0),
                    },
                  },
                  "& .routeDetailsSpan": {
                    fontSize: 13,
                    fontWeight: 500,
                    color: `${COLORS.TEXT_PRIMARY}`,
                    margin: "auto 0px",
                  },
                  "& .routeDetailsValueSpan": {
                    fontSize: 13,
                    fontWeight: 700,
                    color: `${COLORS.MINE_SHAFT}`,
                    margin: "auto 0px",
                  },
                },
              },
              "&.content": {
                height: "100%",
                "& .contentContainer": {
                  position: "fixed",
                  height: "calc(85vh)",
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: 500,
                },
                "& .hubLabel": {
                  position: "absolute",
                  top: theme.spacing(1),
                  right: theme.spacing(8.5),
                  "&.isRoute": {
                    right: theme.spacing(2),
                  },
                  "& .hubLabelContainer": {
                    alignItems: "center",
                    height: 21,
                    "& .hubLabelText": {
                      fontSize: 12,
                      fontWeight: 600,
                      maxWidth: 200,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      "& .label": {
                        fontWeight: 500,
                        paddingRight: theme.spacing(0.5),
                      },
                    },
                  },
                },
                "& .toggleAll": {
                  position: "absolute",
                  top: theme.spacing(1),
                  right: theme.spacing(2.25),
                  cursor: "pointer",
                  userSelect: "none",
                  "& .toggleAllContainer": {
                    alignItems: "center",
                    "& .toggleItem": {
                      paddingLeft: theme.spacing(0.5),
                      "&.iconItem": {
                        transform: "rotate(0deg)",
                        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "&.expanded": {
                          transform: "rotate(180deg)",
                        },
                      },
                      "& .toggleText": {
                        fontSize: 14,
                        fontWeight: 600,
                      },
                      "& .toggleIcon": {
                        width: 21,
                        height: 21,
                        verticalAlign: "bottom",
                      },
                    },
                  },
                },
                "& .slotContentContainer": {
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  "&.viewMode": {
                    overflow: "auto",
                    paddingBottom: theme.spacing(2),
                    "& .slotItemContainer": {
                      paddingLeft: theme.spacing(2),
                    },
                  },
                  "& .slotItemContainer": {
                    alignItems: "flex-start",
                    padding: theme.spacing(1, 2, 1, 6),
                    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                    flexWrap: "nowrap",
                    "&.selected": {
                      // backgroundColor: COLORS.MADISON_LIGHT,
                      //borderLeft: `3px solid ${theme.palette.primary.main}`
                    },
                    "&.drafted": {
                      color: COLORS.GRAY_DARK,
                      paddingLeft: theme.spacing(3.5),
                    },
                    "&.terminal": {
                      color: COLORS.GRAY_DARK,
                      borderColor: COLORS.GRAY_DARK,
                    },
                    "& .slotContentItem": {
                      "&.checkbox": {
                        "& .checkboxContainer": {
                          alignItems: "center",
                          flexWrap: "nowrap",
                          "& .lockIcon": {
                            width: 16,
                            height: 16,
                            marginRight: theme.spacing(1),
                          },
                        },
                        paddingRight: theme.spacing(1.5),
                      },
                      "&.consignmentDetails": {
                        flexGrow: 1,
                        height: "100%",
                        "& .consignmentDetailsContainer": {
                          justifyContent: "space-between",
                          height: "100%",
                          flexWrap: "nowrap",
                          "& .detailsItem": {
                            "& .consignmentHeader": {
                              flexDirection: "column",
                              height: "100%",
                              flexWrap: "nowrap",
                              overflow: "hidden",
                              "& .consignmentHeaderItem": {
                                "&.details": {
                                  flexGrow: 1,
                                  paddingTop: theme.spacing(0.5),
                                },
                                "& .headerDetailsContainer": {
                                  flexWrap: "nowrap",
                                  "& .itemContainer": {
                                    flexWrap: "nowrap",
                                  },
                                  "& .consignmentAddressIcon": {
                                    marginLeft: "8px",
                                    minWidth: "14px",
                                    maxWidth: "14px",
                                  },
                                },
                                "&.status": {
                                  display: "flex",
                                  alignItems: "center",
                                },
                              },
                            },
                            "& .consignmentAddressCTA": {
                              fontSize: "10px",
                              color: "#0E5AA7",
                              cursor: "pointer",
                            },
                            "& .headingContainer": {
                              alignItems: "center",
                              height: "100%",
                              flexWrap: "nowrap",
                              "& .heading": {
                                fontSize: 12,
                                fontWeight: 600,
                                cursor: "pointer",
                              },
                            },
                            "& .seqNoContainer": {
                              backgroundColor: COLORS.ALERT_ORANGE,
                              borderRadius: 2,
                              "& .seqNo": {
                                fontSize: 10,
                                fontWeight: 600,
                                color: COLORS.MINE_SHAFT,
                                lineHeight: "16px",
                                padding: theme.spacing(0, 1),
                              },
                            },
                            "& .count": {
                              fontSize: 10,
                              lineHeight: "15px",
                              fontWeight: 500,
                              padding: theme.spacing(0, 0.75),
                            },
                            "& .icon": {
                              width: 16,
                              height: 16,
                            },
                            "&.desc": {
                              paddingTop: theme.spacing(1),
                            },
                            "& .descContainer": {
                              alignItems: "center",
                              flexWrap: "nowrap",
                              "& .location": {
                                width: 356,
                              },
                              "& .itemContainer": {
                                alignItems: "center",
                                paddingRight: theme.spacing(2.5),
                                flexWrap: "nowrap",
                                "& .locDesc": {
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
                "& .MuiTabs-root": {
                  padding: theme.spacing(1),
                  minHeight: 42,
                  border: "none",
                  background: COLORS.BLACK_SQUEEZE,
                  borderRadius: theme.spacing(1),
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: "20px",
                },
                "& .MuiTab-root": {
                  padding: 0,
                  margin: 0,
                },
                "& .MuiTab-root.Mui-selected": {
                  padding: theme.spacing(1, 1.5),
                  margin: 0,
                  background: COLORS.WHITE,
                  borderRadius: theme.spacing(1),
                  color: COLORS.PRIMARY_MAIN,
                  fontSize: 14,
                  fontWeight: 700,
                  lineHeight: "20px",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                //
                "& .tabPanel": {
                  padding: 0,
                  height: "auto",
                  width: "auto",
                  overflow: "hidden",
                  "& .tabPanelContainer": {
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    "& .delSlotContainer": {
                      flexDirection: "column",
                      flexWrap: "nowrap",
                    },
                    "& .highlightText": {
                      backgroundColor: COLORS.SUCCESS_GREEN,
                    },
                    "& .accordionRoot": {
                      boxShadow: "none",
                      margin: 0,
                      marginBottom: theme.spacing(0.5),
                      "&::before": {
                        opacity: 0,
                      },
                    },
                    "& .accordianSummary": {
                      //border: "none",
                      // borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                      "&.delArea": {
                        minHeight: 46,
                        height: 46,
                        "& .areaHeaderContainer": {
                          alignItems: "center",
                          "& .areaHeaderItem": {
                            paddingRight: theme.spacing(1),
                            "& .itemContainer": {
                              alignItems: "center",
                              "& .icon": {
                                padding: theme.spacing(0.5),
                              },
                              "& .count": {
                                fontSize: 10,
                                fontWeight: 500,
                                lineHeight: "15px",
                              },
                              "& .heading": {
                                fontSize: 14,
                                fontWeight: 600,
                                color: COLORS.MINE_SHAFT,
                              },
                            },
                          },
                        },
                      },
                      "&.delSlot": {
                        minHeight: 48,
                        height: 48,
                        backgroundColor: COLORS.MINE_SHAFT_LIGHT,
                        padding: theme.spacing(1),
                        borderRadius: theme.spacing(1),
                        border: "none",
                        "& .checkBoxIconClass": {
                          marginRight: theme.spacing(1),
                        },
                        "& .slotHeaderContainer": {
                          alignItems: "center",
                          "& .Mui-disabled": {
                            pointerEvents: "auto",
                            cursor: "not-allowed",
                          },
                          "& .slotHeaderItem": {
                            paddingRight: theme.spacing(2),
                            "& .itemContainer": {
                              alignItems: "left",
                              "& .icon": {
                                height: 16,
                                width: 16,
                                margin: "auto 0px",
                                marginLeft: theme.spacing(1),
                                marginRight: theme.spacing(0.5),
                              },
                              "& .heading": {
                                fontSize: 14,
                                fontWeight: 700,
                                paddingRight: theme.spacing(0.5),
                              },
                              "& .subHeadingNote": {
                                fontSize: 12,
                                fontWeight: 500,
                                margin: "auto 8px",
                              },
                              "& .subNote": {
                                fontSize: 12,
                                fontWeight: 500,
                                margin: "auto 0px",
                              },
                              "& .normal": {
                                fontSize: 12,
                                fontWeight: 400,
                                lineHeight: "15px",
                                margin: "auto",
                              },
                              "& .disabledNormal": {
                                fontSize: 12,
                                fontWeight: 400,
                                lineHeight: "15px",
                                margin: "auto",
                                color: COLORS.DUSTY_GRAY,
                              },
                            },
                          },
                          "& .noPadding": {
                            padding: 0,
                          },
                          "& .displayFlex": {
                            display: "flex",
                          },
                          "& .borderRight": {
                            borderRight: `1px solid ${COLORS.SILVER}`,
                          },
                          "& .borderLeft": {
                            borderLeft: `1px solid ${COLORS.SILVER}`,
                          },
                          "& .marginLeft": {
                            marginLeft: theme.spacing(1),
                          },
                          "& .marginRight": {
                            marginRight: theme.spacing(1),
                          },
                          "& .paddingLeft": {
                            paddingLeft: theme.spacing(1),
                          },
                        },
                      },
                      "&.isAccordianSelected": {
                        border: `1px solid ${COLORS.PRIMARY_MAIN}`,
                        borderRadius: theme.spacing(1),
                        marginBottom: theme.spacing(0.5),
                      },
                      "&.routeOrder": {
                        minHeight: 36,
                        height: 36,
                        backgroundColor: COLORS.WHITE,
                        padding: theme.spacing(1),
                        "& .orderIconClass": {
                          marginRight: theme.spacing(1),
                        },
                        "& .slotHeaderContainer": {
                          alignItems: "center",
                          "& .Mui-disabled": {
                            pointerEvents: "auto",
                            cursor: "not-allowed",
                          },
                          "& .slotHeaderItem": {
                            paddingRight: theme.spacing(2),
                            "& .itemContainer": {
                              justifyContent: "flex-start",
                              "& .normal": {
                                fontSize: 14,
                                fontWeight: 400,
                              },
                            },
                          },
                          "& .noPadding": {
                            padding: 0,
                          },
                          "& .displayFlex": {
                            display: "flex",
                          },
                          "& .borderRight": {
                            borderRight: `1px solid ${COLORS.SILVER}`,
                          },
                          "& .marginLeft": {
                            marginLeft: theme.spacing(1),
                          },
                        },
                      },
                    },
                    "& .slotNameGrid": {
                      margin: theme.spacing(1, 0, 0, 0),
                      padding: theme.spacing(1, 2),
                      backgroundColor: COLORS.ATHENS_GRAY,
                      borderRadius: theme.spacing(1),
                      cursor: "pointer",
                      "& .displayFlex": {
                        display: "flex",
                      },
                      "& .icon": {
                        height: 16,
                        width: 16,
                        margin: "auto 0px",
                        marginRight: theme.spacing(0.5),
                      },
                      "& .normal": {
                        fontSize: 12,
                        fontWeight: 400,
                        lineHeight: "16px",
                        margin: "auto 0px",
                      },
                    },
                    "& .unplannedSlotNameGrid": {
                      margin: theme.spacing(1, 0, 0, 0),
                      padding: theme.spacing(1),
                      backgroundColor: COLORS.ATHENS_GRAY,
                      borderRadius: theme.spacing(1),
                      cursor: "pointer",
                      "& .checkBoxIconClass": {
                        marginRight: theme.spacing(1),
                      },
                      "& .displayFlex": {
                        display: "flex",
                      },
                      "& .icon": {
                        height: 16,
                        width: 16,
                        margin: "auto 0px",
                        marginRight: theme.spacing(0.5),
                      },
                      "& .normal": {
                        fontSize: 12,
                        fontWeight: 400,
                        lineHeight: "16px",
                        margin: "auto 0px",
                      },
                    },
                    "& .isGridSelected": {
                      border: `1px solid ${COLORS.PRIMARY_MAIN}`,
                      borderRadius: theme.spacing(1),
                      marginBottom: theme.spacing(0.5),
                    },
                    "& .innerAccordionRoot": {
                      boxShadow: "none",
                      margin: 0,
                      marginBottom: theme.spacing(0.5),
                      minWidth: "100%",
                      "&::before": {
                        opacity: 0,
                      },
                      "&.evenBackground": {
                        backgroundColor: COLORS.ALICE_GRAY,
                      },
                      "& .innerAccordionSummary": {
                        "&.orderBox": {
                          minHeight: 36,
                          height: 36,
                          padding: theme.spacing(1),
                          borderRadius: theme.spacing(1),
                          border: "none",
                          "& .checkBoxIconClass": {
                            marginRight: theme.spacing(1),
                          },
                          "& .slotHeaderContainer": {
                            alignItems: "center",
                            "& .Mui-disabled": {
                              pointerEvents: "auto",
                              cursor: "not-allowed",
                            },
                            "& .slotHeaderItem": {
                              "& .itemContainer": {
                                alignItems: "left",
                                "& .dotIcon": {
                                  height: 24,
                                  width: 24,
                                  margin: "auto 0px",
                                  transform: "rotate(-90deg)",
                                  cursor: "poniter",
                                },
                                "& .icon": {
                                  height: 24,
                                  width: 24,
                                  margin: "auto 0px",
                                  marginRight: theme.spacing(1),
                                },
                                "& .svgIcon": {
                                  height: 24,
                                  width: 24,
                                  margin: "auto 0px",
                                  marginRight: theme.spacing(0.5),
                                  marginLeft: theme.spacing(0.5),
                                },
                                "& .heading": {
                                  fontSize: 14,
                                  fontWeight: 700,
                                  paddingRight: theme.spacing(0.5),
                                },
                                "& .subNote": {
                                  fontSize: 14,
                                  fontWeight: 500,
                                  margin: "auto 0px",
                                },
                                "& .normal": {
                                  fontSize: 12,
                                  fontWeight: 500,
                                  lineHeight: "15px",
                                  margin: "auto 0px",
                                  marginRight: theme.spacing(1),
                                },
                                "& .orderNoCircle": {
                                  height: 24,
                                  width: 24,
                                  background: COLORS.PRIMARY_MAIN,
                                  color: COLORS.WHITE,
                                  padding: "5px",
                                  borderRadius: theme.spacing(2),
                                  margin: theme.spacing('auto', 1, 'auto', 0),
                                  fontSize: 12,
                                  fontWeight: 400,
                                  lineHeight: "15px",
                                  textAlign: "center",
                                },
                                "& .checkBoxIconClass": {
                                  margin: "auto 8px auto 0px",
                                },
                              },
                            },
                            "& .noPadding": {
                              padding: 0,
                            },
                            "& .displayFlex": {
                              display: "flex",
                            },
                            "& .borderRight": {
                              borderRight: `1px solid ${COLORS.SILVER}`,
                            },
                            "& .borderRightWithMargin": {
                              margin: 0,
                              marginRight: theme.spacing(1),
                              marginLeft: theme.spacing(1),
                              borderRight: `1px solid ${COLORS.SILVER}`,
                            },
                            "& .marginLeft": {
                              marginLeft: theme.spacing(1),
                            },
                          },
                        },
                      },
                    },
                    "& .accordianDetails": {
                      padding: 0,
                      "&.routes": {
                        "& .slotItemContainer": {
                          height: "auto",
                        },
                      },
                    },
                    "& .innerAccordionDetails": {
                      padding: "12px 0px",
                      margin: "0px",
                      "&.routes": {
                        "& .slotItemContainer": {
                          height: "auto",
                        },
                      },
                      "& .slotHeaderContainer": {
                        alignItems: "center",
                        padding: "0px",
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                        "& .Mui-disabled": {
                          pointerEvents: "auto",
                          cursor: "not-allowed",
                        },
                        "& .slotHeaderItem": {
                          "& .slotItemGridItem": {
                            margin: "auto 0px",
                          },
                          "& .itemContainer": {
                            alignItems: "left",
                            "& .icon": {
                              width: "20px",
                              height: "20px",
                              margin: "auto 0px",
                              padding: "0px",
                              marginRight: theme.spacing(1),
                            },
                            "& .svgIcon": {
                              width: 24,
                              height: 24,
                              margin: "auto 0px",
                              marginRight: theme.spacing(1),
                              marginLeft: theme.spacing(1),
                            },
                            "& .heading": {
                              fontSize: 14,
                              fontWeight: 700,
                              paddingRight: theme.spacing(0.5),
                            },
                            "& .subNote": {
                              fontSize: 12,
                              fontWeight: 500,
                            },
                            "& .normal": {
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "20px",
                              margin: "auto 0px",
                            },
                            "& .normalWithMargin": {
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "18px",
                              margin: "auto 4px",
                            },
                          },
                        },
                        "& .paddingTop": {
                          padding: "4px 0px 0px 0px",
                        },
                        "& .noPadding": {
                          padding: 0,
                        },
                        "& .displayFlex": {
                          display: "flex",
                        },
                        "& .alignItemCenter": {
                          alignItems: "center",
                        },
                        "& .borderRight": {
                          borderRight: `1px solid ${COLORS.SILVER}`,
                        },
                        "& .marginLeft": {
                          marginLeft: theme.spacing(1),
                        },
                        "& .marginTop": {
                          marginTop: theme.spacing(1),
                        },
                      },
                    },
                    "&.planned": {
                      padding: theme.spacing(1.5, 0, 2, 0),
                      height: "100%",
                      width: "auto",
                      overflowY: "hidden",
                      overflowX: "hidden",
                      position: "relative",
                      "& .noItemsGridStyle": {
                        height: "50vh",
                        textAlign: "center",
                      },
                      "& .noItemsSpanStyle": {
                        width: "500px",
                        margin: "auto",
                      },
                      "& .heading": {
                        paddingLeft: "0px !important",
                      },
                      "& .accordianDetails": {
                        "& .slotItemContainer": {
                          padding: "10px 8px 10px 0 !important",
                          cursor: "pointer",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        "&.right": {
          flexGrow: 1,
          height: "100%",
          "& .mapRightContainer": {
            height: "100%",
            width: "100%",
            "& .mapItem": {
              width: "100%",
              height: "calc(100% - 56px)",
            },
            "& .buttonItem": {
              height: "56px",
              width: "100%",
              padding: theme.spacing(1, 0, 0, 0),
            },
          },
        },
        "& .detailsContainer": {
          flexDirection: "column",
          height: "92%",
          flexWrap: "nowrap",
          "& .detailItem": {
            "&.routeDetails": {
              backgroundColor: COLORS.MINE_SHAFT_LIGHT,
              padding: theme.spacing(2),
              "&.viewMode": {
                "& .routeDetailsContainer .routeDetailsItem.basicDesc": {
                  paddingBottom: 0,
                },
              },
              "& .routeDetailsContainer": {
                flexDirection: "column",
                flexWrap: "nowrap",
                "& .routeDetailsItem": {
                  "&.header": {
                    "& .routeIdHeaderContainer": {
                      alignItems: "center",
                      justifyContent: "space-between",
                      "& .ddnsItem": {
                        "& .MuiFormControl-root": {
                          paddingRight: theme.spacing(1.5),
                          "&:last-child": {
                            paddingRight: 0,
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: COLORS.WHITE,
                        },
                      },
                    },
                    paddingBottom: theme.spacing(2),
                  },
                  "& .heading": {
                    fontSize: 16,
                    fontWeight: 600,
                    color: COLORS.BLACK,
                  },
                  "&.basicDesc": {
                    paddingBottom: theme.spacing(3.75),
                  },
                  "&.noSelectedArea": {
                    padding: theme.spacing(3),
                    backgroundColor: COLORS.WHITE,
                    border: `1px dashed ${theme.palette.secondary.dark}`,
                    borderRadius: theme.spacing(1),
                    "& p": {
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: 500,
                      color: COLORS.DOVE_GRAY,
                    },
                  },
                  "&.timeline": {
                    alignSelf: "flex-start",
                    height: 55,
                  },
                  "&.timelineWarning": {
                    paddingTop: theme.spacing(1.5),
                    "& .warningContainer": {
                      alignItems: "center",
                      "& .warningItem": {
                        paddingRight: theme.spacing(1),
                        "& p": {
                          fontSize: 11,
                          fontWeight: 500,
                          color: COLORS.DOVE_GRAY,
                        },
                      },
                      "& .warningIcon": {
                        width: 20,
                        height: 20,
                        verticalAlign: "middle",
                      },
                    },
                  },
                  "& .basicDescContainer": {
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    "& .basicItem": {
                      paddingBottom: theme.spacing(2),
                      "&:last-child": {
                        paddingBottom: 0,
                      },
                      "&.driver": {
                        paddingRight: theme.spacing(2),
                      },
                      "&.descriptions": {
                        flexGrow: 1,
                      },
                      "& .descCommonContainer": {
                        "& .commonItem": {
                          flex: "1 0 0",
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: COLORS.WHITE,
                          },
                          "&:last-child": {
                            flex: "0.5 0 0",
                          },
                          "& .descContainer": {
                            flexDirection: "column",
                            "& .capacityBreachContainer": {
                              alignItems: "center",
                              "& .infoErrorIcon": {
                                width: 16,
                                height: 16,
                                marginLeft: theme.spacing(1),
                              },
                            },
                            "& p": {
                              fontSize: 12,
                              fontWeight: 500,
                              lineHeight: "18px",
                            },
                            "& .value": {
                              alignSelf: "flex-start",
                              "& p": {
                                fontWeight: 600,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            "&.mapZone": {
              flexGrow: 1,
              backgroundColor: theme.palette.secondary.dark,
              position: "relative",
              overflow: "auto",
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
              borderRadius: theme.spacing(2),
              "& .listViewTimelineContent": {
                backgroundColor: COLORS.WHITE,
                padding: theme.spacing(2, 3),
                height: "100%",
                overflow: "auto",
              },
              "& .mapSwitchBtnContainer": {
                position: "absolute",
                alignItems: "center",
                width: 110,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: theme.spacing(1),
                backgroundColor: COLORS.WHITE,
                top: theme.spacing(2),
                right: theme.spacing(2),
                flexWrap: "nowrap",
                "& .switchBtnItem": {
                  cursor: "pointer",
                  "&.selected": {
                    backgroundColor: COLORS.MADISON_LIGHT,
                    "&.mapView": {
                      borderTopLeftRadius: theme.spacing(1),
                      borderBottomLeftRadius: theme.spacing(1),
                      "& path": {
                        stroke: theme.palette.primary.main,
                      },
                    },
                    "&.listView": {
                      borderTopRightRadius: theme.spacing(1),
                      borderBottomRightRadius: theme.spacing(1),
                      "& path": {
                        fill: theme.palette.primary.main,
                      },
                    },
                    "& .title": {
                      color: theme.palette.primary.main,
                      fontWeight: 600,
                    },
                  },
                  "& .switchBtnItemContainer": {
                    flexDirection: "column",
                    alignItems: "center",
                    "& .title": {
                      fontSize: 12,
                      fontWeight: 500,
                      paddingBottom: theme.spacing(0.5),
                    },
                  },
                  "& .switchBtnIcon": {
                    width: 54,
                    height: 40,
                    verticalAlign: "middle",
                    padding: theme.spacing(0, 2),
                    backgroundColor: "transparent",
                  },
                },
              },
            },
            "&.buttons": {
              "& .buttonsContainer": {
                padding: theme.spacing(2),
                justifyContent: "flex-end",
                "& .btn": {
                  marginRight: theme.spacing(1.5),
                  "&:last-child": {
                    marginRight: 0,
                  },
                },
                "& .refreshMapBtn": {
                  marginRight: "auto",
                },
                "& .saveBtn": {
                  padding: theme.spacing(1, 5.5),
                },
              },
            },
            "&.planned": {
              "& .routeDetailsItem": {
                "&.basicDesc": {
                  paddingBottom: "0 !important",
                },
              },
            },
          },
        },
      },
    },
    confirmDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(8.25),
      "& .dialogBtn": {
        "&.secondary": {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1),
        },
      },
    },
    confirmDialogAddressButtonsContainer: {
      justifyContent: "center",
      "& .dialogBtn": {
        "&.primary": {
          marginLeft: theme.spacing(1),
        },
      },
    },
    confirmDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      "& .contentItem": {
        "& p": {
          textAlign: "center",
          lineHeight: 1.2,
          "&.alignLeft": {
            textAlign: "left",
          },
        },
        "&.heading": {
          padding: theme.spacing(0, 15.75, 5),
          "& p": {
            fontSize: 24,
            fontWeight: 600,
          },
        },
        "&.desc": {
          padding: theme.spacing(0, 2.5),
          "& p": {
            fontSize: 18,
            fontWeight: 400,
            "& .count": {
              fontWeight: 600,
            },
          },
        },
      },
    },
    capacityBreachDialogTitle: {
      fontSize: 20,
      fontWeight: 600,
      paddingLeft: theme.spacing(2.5),
    },
    capacityBreachDialogButtonsContainer: {
      justifyContent: "flex-end",
      paddingBottom: theme.spacing(3.75),
      "& .dialogBtn": {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(4.5),
        "&.secondary": {
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          marginLeft: theme.spacing(1),
        },
      },
    },
    confirmWithTitleDialogPaperPropsRoot: {
      width: 600,
      height: 240,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    confirmDialogPaperPropsRoot: {
      width: 600,
      height: 350,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    addressConfirmDialogPaperPropsRoot: {
      width: 338,
      height: 214,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    endSessionSelectionDialogPaperPropsRoot: {
      width: 428,
      height: 242,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    endSessionSelectionDialogTitle: {
      fontSize: 20,
      fontWeight: 600,
      padding: "6px 0px 0px 12px",
    },

    endSessionSelectionDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      "& .contentItem": {
        "&.heading": {
          "& p": {
            fontSize: 14,
            fontWeight: 400,
          },
        },
        "&.hub": {
          paddingTop: theme.spacing(3.75),
        },
      },
    },
    content: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "15px",
      color: "#252525",
    },
    endSessionDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(1),
      "& .dialogBtn": {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(4.5),
        "&.secondary": {
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          marginLeft: theme.spacing(1),
        },
      },
    },
    selectMenuPropsList: {
      "& .MuiListItem-root": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
        "& .MuiListItemText-primary": {
          fontSize: 10,
        },
      },
      "& .MuiCheckbox-root": {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75),
      },
    },
    selectMenuPaperProps: {
      maxHeight: 200,
      width: 140,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      overflow: "auto",
      boxShadow: "none",
    },
    titleContainer: {
      "& .headingContainer": {
        flexDirection: "column",
        "& .titleHeading": {
          fontSize: 18,
          fontWeight: 500,
          "& .content": {
            fontWeight: 600,
          },
        },
        "& .titleContent": {
          fontSize: 12,
          fontWeight: 600,
          "&.link": {
            cursor: "pointer",
          },
        },
      },
    },
    addressContent: {
      fontSize: "14px",
      fontWeight: 500,
    },
    normalTextStyle: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "15px",
    },
    innerOrderRouteAccordian: {
      boxShadow: "none",
      margin: 0,
      backgroundColor: COLORS.WHITE,
    },
    innerOrderRouteAccordianSummary: {
      minHeight: 36,
      height: 36,
      backgroundColor: COLORS.WHITE,
      padding: theme.spacing(1),
    },
    innerOrderRouteAccordianSummaryExpanded: {
      minHeight: 36,
    },
    assignDriverHelperCardStyle: {
      position: "absolute",
      zIndex: 1,
      top: theme.spacing(6),
      width: "97%",
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      background: COLORS.WHITE,
    },
    buttonGridContainerStyle: {
      bottom: theme.spacing(3),
      position: "absolute",
      zIndex: 1,
      width: "97%",
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      background: COLORS.WHITE,
      display: "flex",
      justifyContent: "flex-end",
      "&.buttonGridContainerStyle": {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "24px",
      },
    },
    buttonStyle: {
      minWidth: "104px",
      borderRadius: theme.spacing(2),
      "&.buttonGridContainerStyle": {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "24px",
      },
    },
    "&.buttonGridContainerStyle": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "24px",
    },
    filterGridContainerStyle: {
      margin: theme.spacing(1, 0, 0, 0),
      padding: theme.spacing(0, 0, 0, 0),
    },
    publishDialogPaperPropsRoot: {
      width: 600,
      height: 350,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    deleteDialogPaperPropsRoot: {
      width: 500,
      height: 300,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    publishDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      "& .contentItem": {
        "& p": {
          textAlign: "center",
          lineHeight: 1.2,
        },
        "&.heading": {
          padding: theme.spacing(0, 15.75, 5),
          "& p": {
            fontSize: 24,
            fontWeight: 600,
          },
        },
        "&.desc": {
          padding: theme.spacing(0, 2.5),
          "& p": {
            fontSize: 18,
            fontWeight: 400,
            "& .count": {
              fontWeight: 600,
            },
          },
        },
      },
    },
    publishDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(8.25),
      "& .dialogBtn": {
        "&.secondary": {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1),
        },
      },
    },
    orderActionTooltipPopperStyle: {
      pointerEvents: "auto",
    },
    orderActionTooltipStyle: {
      width: "auto",
      height: "auto",
      background: COLORS.WHITE,
      borderRadius: theme.spacing(1),
      border: `1px solid ${COLORS.GRADIENT_BLUE}`,
    },
    orderActionTooltipArrowStyle: {
      color: COLORS.GRADIENT_BLUE,
    },
    orderActionTooltipGridContainer: {
      width: "260px",
      background: COLORS.WHITE,
      padding: 0,
    },
    orderActionTooltipGridItem: {
      padding: theme.spacing(2),
      "&:first-child": {
        borderBottom: `1px solid ${COLORS.ALICE_GRAY}`,
      },
    },
    orderActionTooltipSpanStyle: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "15px",
      color: COLORS.PRIMARY_MAIN,
      cursor: "pointer",
    },
    orderActionTooltipBoldSpanStyle: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "15px",
      color: COLORS.PRIMARY_MAIN,
      cursor: "pointer",
      margin: theme.spacing(0, 0.5, 0, 0.5),
      textDecoration: "underline",
    },
    noMapGridContainerViewStyle: {
      background: COLORS.ALICE_GRAY,
      width: "auto",
      height: "100%",
      borderRadius: theme.spacing(1),
    },
    collapseExpandGridConatinerStyle: {
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(0, 0, 0, 0),
    },
    collapseExpandGridCheckboxStyle: {
      width: "50%",
      height: "24px",
      padding: theme.spacing(0, 1.5, 0, 1),
      background: COLORS.ALICE_GRAY,
      border: "none",
      borderRadius: theme.spacing(0.5, 0, 0, 0.5),
    },
    collapseExpandGridItemStyle: {
      display: "flex",
      justifyContent: "flex-end",
      width: "50%",
      height: "24px",
      padding: theme.spacing(0, 1.5, 0, 1.5),
      background: COLORS.ALICE_GRAY,
      border: "none",
      borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    },
    collapseExpandGridItemSpanStyle: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "normal",
      margin: "auto 0px",
      color: COLORS.PRIMARY_MAIN,
      cursor: "pointer",
    },
    assignRouteToOrderCardStyle: {
      position: "absolute",
      zIndex: 1,
      width: "412px",
      bottom: "60px",
      right: "0px",
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      background: COLORS.WHITE,
      boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
    },
    assignRouteHeaderSpanStyle: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "normal",
      margin: theme.spacing(0, 0, 2, 0),
      color: COLORS.MINE_SHAFT,
    },
    assignRouteMainGridStyle: {
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(0, 0, 0, 0),
      overflow: "auto",
      maxHeight: "calc(40vh)",
    },
    assignRouteHeaderGridConatinerStyle: {
      width: "100%",
      height: "44px",
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
      border: "none",
      borderBottom: `1px solid ${COLORS.ALICE_GRAY}`,
    },
    selectedRouteStyle: {
      background: COLORS.ALICE_GRAY,
    },
    assignRouteHeaderGridItemStyle: {
      display: "flex",
      justifyContent: "center",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
      margin: "auto 0px",
    },
    assignRouteTitleSpanStyle: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
    },
    assignRouteNormalSpanStyle: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
      margin: "auto 0px",
    },
    assignRouteSubnoteSpanStyle: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
      margin: "auto 0px",
    },
    assignRouteIconStyle: {
      padding: theme.spacing(0, 0.5, 0, 0),
      margin: "auto 0px",
    },
    dialogHeaderSpanStyle: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
    },
    dialogValueSpanStyle: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
      margin: "4px 0px 0px 0px",
    },
    infoWindowCardStyle: {
      padding: theme.spacing(1),
      margin: theme.spacing(0),
    },
    infoWindowCardValueSpanStyle: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
      margin: "0px 0px 0px 4px",
    },
    mapFooterGridStyle: {
      margin: "auto 0px",
      height: "48px",
    },
    allRotePOSTitleSpanStyle: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
      margin: "auto 0px",
    },
    assignDriverHelperClosedCardStyle: {
      position: "absolute",
      zIndex: 1,
      top: theme.spacing(6),
      right: theme.spacing(0),
      width: "auto",
      margin: theme.spacing(2),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(2),
      background: COLORS.WHITE,
    },
    dialogHeaderButtonGridItemStyle: {
      padding: "0px !important",
      margin: theme.spacing(0),
    },
    dialogHeaderButtonGridStyle: {
      flexGrow: 1,
    },
    dialogHeaderValueSpanStyle: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "normal",
      color: COLORS.MINE_SHAFT,
      margin: theme.spacing(0, 0, 0, 1),
    },
    allRotePOSChangeSpanStyle: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "normal",
      color: COLORS.PRIMARY_MAIN,
      margin: "auto 0px auto 4px",
      cursor: "pointer",
    },
    //
    // Planned Route
    marginAuto: { margin: "auto" },
    marginAutoFour: { margin: theme.spacing("auto", 0.5) },
    marginAutoEight: { margin: theme.spacing("auto", 1) },
    marginAutoZero: { margin: theme.spacing("auto", 0) },
    marginRightFour: { marginRight: theme.spacing(0.5) },
    marginRightEight: { marginRight: theme.spacing(1) },
    marginLeftEight: { marginLeft: theme.spacing(1) },

    paddingTopFour: { paddingTop: theme.spacing(0.5) },
    paddingRightFour: { paddingRight: theme.spacing(0.5) },
    paddingRightEight: { paddingRight: theme.spacing(1) },
    paddingLeftFour: { paddingLeft: theme.spacing(0.5) },
    paddingLeftEight: { paddingLeft: theme.spacing(1) },

    borderRight: { borderRight: `1px solid ${COLORS.SILVER}` },
    borderLeft: { borderLeft: `1px solid ${COLORS.SILVER}` },

    fontSizeFourteen: { fontSize: 14 },
    fontSizeTweleve: { fontSize: 12 },

    lineHeightFifteen: { lineHeight: "15px" },
    lineHeightEighteen: { lineHeight: "18px" },
    lineHeightTwenty: { lineHeight: "18px" },

    fontWeightSeven: { fontWeight: 700 },
    fontWeightFive: { fontWeight: 500 },
    fontWeightSix: { fontWeight: 600 },
    fontWeightFour: { fontWeight: 400 },

    displayFlex: { display: "flex" },
    justifyContentSpaceBetween: { display: "flex", justifyContent: "space-between" },
    heightHundredPercentage: { height: "100%" },

    backgroundColorAliceGray: { backgroundColor: COLORS.ALICE_GRAY },

    subRouteAccordianCustomStyle: { borderRight: `1px solid ${COLORS.SILVER}`, paddingRight: theme.spacing(1) },

    plannedRouteMainAccordianStyle: {
      margin: theme.spacing(0, 0, 0.5, 0),
      padding: 0,
      boxShadow: "none",
      "&::before": {
        opacity: 0,
      },
      "&.MuiAccordion-root.Mui-expanded": {
        margin: theme.spacing(0, 0, 0.5, 0),
      },
    },
    plannedRouteMainAccordianSummaryStyle: {
      padding: theme.spacing(1),
      minHeight: 48,
      height: 48,
      backgroundColor: COLORS.MINE_SHAFT_LIGHT,
      borderRadius: theme.spacing(1),
      border: "none",
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 48,
        height: 48,
      },
    },
    isAccordianSelected: {
      border: `1px solid ${COLORS.PRIMARY_MAIN}`,
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
    plannedRouteMainAccordianDetailsStyle: {
      margin: theme.spacing(0.5, 0, 0, 0),
      padding: 0,
    },
    crateIconStyle: {
      height: 16,
      width: 16,
      margin: "auto 0px",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },
    userIconStyle: {
      height: 16,
      width: 16,
      margin: "auto 0px",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },
    userSpanStyle: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "15px",
      margin: "auto",
      color: COLORS.DUSTY_GRAY,
    },
    // Slot
    plannedRouteInnerSlotAccordianStyle: {
      margin: theme.spacing(0, 0, 0.5, 0),
      padding: 0,
      boxShadow: "none",
      "&::before": {
        opacity: 0,
      },
      "&.MuiAccordion-root.Mui-expanded": {
        margin: theme.spacing(0, 0, 0.5, 0),
      },
    },
    plannedRouteInnerSlotAccordianSummaryStyle: {
      padding: theme.spacing(0, 0, 0, 4),
      minHeight: 32,
      height: 32,
      backgroundColor: COLORS.ATHENS_GRAY,
      borderRadius: theme.spacing(1),
      border: "none",
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 32,
        height: 32,
      },
    },
    plannedRouteInnerSlotAccordianSummaryStyleWithoutArea: {
      padding: theme.spacing(0, 0, 0, 2),
      minHeight: 32,
      height: 32,
      backgroundColor: COLORS.ATHENS_GRAY,
      borderRadius: theme.spacing(1),
      border: "none",
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 32,
        height: 32,
      },
    },
    plannedRouteInnerSlotAccordianDetailsStyle: {
      margin: theme.spacing(0.5, 0, 0, 0),
      padding: 0,
    },
    clockIconStyle: {
      height: 16,
      width: 16,
      margin: "auto 0px",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },
    // Area
    plannedRouteInnerAreaAccordianStyle: {
      margin: theme.spacing(0, 0, 0.5, 0),
      padding: 0,
      boxShadow: "none",
      "&::before": {
        opacity: 0,
      },
      "&.MuiAccordion-root.Mui-expanded": {
        margin: theme.spacing(0, 0, 0.5, 0),
      },
    },
    plannedRouteInnerAreaAccordianSummaryStyle: {
      padding: theme.spacing(0),
      minHeight: 38,
      height: 38,
      backgroundColor: COLORS.ALICE_GRAY,
      borderRadius: theme.spacing(1),
      border: "none",
      flexDirection: "row-reverse",
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 38,
        height: 38,
      },
    },
    plannedRouteInnerSubAreaAccordianSummaryStyle: {
      padding: theme.spacing(0, 0, 0, 3),
      minHeight: 35,
      height: 35,
      backgroundColor: COLORS.ALICE_GRAY,
      borderRadius: theme.spacing(1),
      border: "none",
      flexDirection: "row-reverse",
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 35,
        height: 35,
      },
    },
    expandIconLeft: {
      marginLeft: 0,
      marginRight: "auto",
      transform: "rotate(270deg)",
      "&.MuiAccordionSummary-expandIcon.Mui-expanded": { transform: "rotate(360deg)" },
    },
    plannedRouteInnerAreaAccordianDetailsStyle: {
      margin: theme.spacing(0.5, 0, 0, 0),
      padding: 0,
    },
    // Order
    plannedRouteInnerOrderAccordianStyle: {
      margin: theme.spacing(0, 0, 0.5, 0),
      padding: 0,
      boxShadow: "none",
      "&::before": {
        opacity: 0,
      },
      "&.MuiAccordion-root.Mui-expanded": {
        margin: theme.spacing(0, 0, 0.5, 0),
      },
    },
    plannedRouteInnerOrderAccordianSummaryStyle: {
      padding: theme.spacing(0, 0, 0, 5),
      minHeight: 30,
      height: 30,
      backgroundColor: COLORS.WHITE,
      borderRadius: theme.spacing(1),
      border: "none",
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 30,
        height: 30,
      },
    },
    plannedRouteInnerOrderAccordianDetailsStyle: {
      margin: theme.spacing(0.5, 0, 0, 4.5),
      padding: theme.spacing(1, 0),
    },
    orderNumberCircle: {
      height: 24,
      width: 24,
      background: COLORS.PRIMARY_MAIN,
      color: COLORS.WHITE,
      padding: "5px",
      borderRadius: theme.spacing(2),
      margin: theme.spacing('auto', 1, 'auto', 0),
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "15px",
      textAlign: "center",
    },
    dotIconStyle: {
      height: 24,
      width: 24,
      margin: "auto 0px",
      transform: "rotate(-90deg)",
      cursor: "pointer",
    },
    locationIconStyle: {
      height: 20,
      width: 20,
      margin: "auto 0px",
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
    },
    WalletIconStyle: {
      height: 20,
      width: 20,
      margin: "auto 0px",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    orderSubRouteCheckboxStyle: {
      margin: "auto 8px auto auto",
    },
    routeBoxStyle: {
      padding: theme.spacing(0.5),
      margin: "auto 4px",
      borderRadius: "2px",
      background: COLORS.INFO_BLUE,
      fontSize: 10,
      fontWeight: 600,
      lineHeight: "12px",
      color: COLORS.WHITE,
    },
    parentRouteBoxStyle: {
      background: COLORS.OLD_GOLD,
    },
    subRouteBoxStyle: {
      background: COLORS.INFO_BLUE,
    },
    deleteIconStyle: {
      height: 16,
      width: 16,
      margin: "auto 0px",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      cursor: "pointer",
    },
    deleteButton: {
      backgroundColor: COLORS.ALIZARIN_CRIMSON,
      padding: "0px 64px",
      color: "white",
      "&:hover": {
        backgroundColor: COLORS.ALIZARIN_CRIMSON,
      },
    },
    subrouteIconStyle: {
      backgroundColor: COLORS.INFO_BLUE,
    },
    routeTitleSpanStyle: {
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0.5),
      borderRadius: "2px",
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "normal",
      color: COLORS.WHITE,
    },
  })
);
