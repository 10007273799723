export const Countdown = (function () {
  const retunObj: any = {};

  function startCountdown(id: any, count: number) {
    let timer : any;
    timer = setInterval(function () {
      if (count > 0 || count == 0) {
        retunObj[id] = count;
        count--;
      }

      if (count < 0) {
        delete retunObj[id];
        clearTimeout(timer);
      }
    }, 1000);
  }

  return {
    getInstance: function () {
      return retunObj;
    },
    startCountdown,
  };
})();
