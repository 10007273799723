import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Fade, Grid, SvgIcon, Tooltip, Typography } from "@material-ui/core";

import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow-icon.svg";
import { ReactComponent as timeSlotIcon } from "../../../assets/icons/time-slot-icon.svg";

import { isInputAnArray, isNonEmptyArray } from "../../../utils/helpers.utils";
import { getSlotName, isSubRoute } from "../utils/AllRouteUtils";
import { ALL_ROUTES_TEXT_CONTANT } from "../utils/AllRouteConstant";
import { CustomPlannedOrderActionTooltip } from "../utils/AllRouteViewUtils";

import RenderOrderDetailsAccordionSummary from "./RenderOrderDetailsAccordionSummary";
import RenderOrderAccordianDetails from "./RenderOrderAccordianDetails";

interface RenderRouteSlotAccordianConfigurationInterface {
  classes: any;
  routeObj: any;
  routeName: string;
  routeDataName: string;
  routeDataNameIndex: number;
  slotName: string;
  slotNameIndex: number;
  viewMode?: boolean;
  // Accordian
  handleRouteSlotAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string) => void;
  handleRouteOrderAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  // checkBox
  handleSlotCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string) => void;
  handleOrderCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  //
  orderActionPopUpViewChange?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  removeOrderFromRoute?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  addOrderToAnotherRoute?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
}

const RenderRouteSlotAccordianConfiguration = (props: RenderRouteSlotAccordianConfigurationInterface) => {
  const {
    classes,
    routeObj,
    routeName,
    routeDataName,
    routeDataNameIndex,
    slotName,
    slotNameIndex,
    viewMode = false,
    handleRouteSlotAccordianToggle,
    handleRouteOrderAccordianToggle,
    //
    handleSlotCheckboxToggle,
    handleOrderCheckboxToggle,
    //
    orderActionPopUpViewChange,
    removeOrderFromRoute,
    addOrderToAnotherRoute,
  } = props;

  const toolTipClasses = { popper: classes.orderActionTooltipPopperStyle, tooltip: classes.orderActionTooltipStyle, arrow: classes.orderActionTooltipArrowStyle };

  const renderNoItemsToDisplay = () => <Typography>{ALL_ROUTES_TEXT_CONTANT.NO_ITEMS_TO_DISPLAY}</Typography>;

  const isRouteSlotsContainsOrders = (obj: any) => obj && obj?.orders && isInputAnArray(obj.orders) && isNonEmptyArray(obj.orders);

  return (
    <Accordion
      key={`plannedRoute-${routeObj.routeId}-routeDataObj-${routeDataNameIndex}-slotWiseOrders-${slotNameIndex}`}
      expanded={routeObj.routeDataObj[routeDataName].slotWiseOrders[slotName].isOpened}
      TransitionProps={{ unmountOnExit: true, timeout: 0 }}
      className={classes.plannedRouteInnerSlotAccordianStyle}
    >
      <AccordionSummary
        expandIcon={<SvgIcon component={downArrowIcon} onClick={(e: any) => handleRouteSlotAccordianToggle && handleRouteSlotAccordianToggle(e, routeName, routeDataName, slotName)} />}
        onClick={(e: any) => handleRouteSlotAccordianToggle && handleRouteSlotAccordianToggle(e, routeName, routeDataName, slotName)}
        className={routeObj.subRouteCreated ? classes.plannedRouteInnerSlotAccordianSummaryStyle : classes.plannedRouteInnerSlotAccordianSummaryStyleWithoutArea}
      >
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item>
                <Grid container>
                  {!viewMode && !isSubRoute(routeObj.routeDataObj[routeDataName]) && routeObj.subRouteCreated && (
                    <Checkbox
                      color="primary"
                      checked={routeObj.routeDataObj[routeDataName].slotWiseOrders[slotName].isChecked}
                      onClick={(e: any) => handleSlotCheckboxToggle && handleSlotCheckboxToggle(e, routeName, routeDataName, slotName)}
                      className={`${classes.marginRightEight} ${classes.marginLeftEight}`}
                    />
                  )}
                  <SvgIcon viewBox="0 0 16 16" component={timeSlotIcon} className={classes.clockIconStyle} />
                  <Typography className={`${classes.fontSizeTweleve} ${classes.fontWeightFour} ${classes.lineHeightFifteen} ${classes.marginAuto} ${classes.borderRight} ${classes.paddingRightEight}`}>
                    {`${getSlotName(slotName)}`}
                  </Typography>
                  <Typography className={`${classes.fontSizeTweleve} ${classes.fontWeightFour} ${classes.marginAutoEight}`}>
                    {`${routeObj.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders?.length || ""} Orders`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.plannedRouteInnerSlotAccordianDetailsStyle}>
        {routeObj.routeDataObj[routeDataName].slotWiseOrders[slotName].isOpened && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
              {isRouteSlotsContainsOrders(routeObj.routeDataObj[routeDataName].slotWiseOrders[slotName])
                ? routeObj.routeDataObj[routeDataName].slotWiseOrders[slotName].orders.map((orderObj: any, orderObjIndex: number) => (
                    <Accordion
                      key={`plannedRoute-${routeObj.routeId}-routeDataObj-${routeDataNameIndex}-slotWiseOrders-${slotNameIndex}-orders-${orderObj?.consignmentCode}`}
                      id={`order-code-${orderObj.consignmentCode}`}
                      expanded={orderObj.isOpened}
                      TransitionProps={{ unmountOnExit: true, timeout: 0 }}
                      className={`${classes.plannedRouteInnerOrderAccordianStyle}`}
                    >
                      <Tooltip
                        open={!viewMode && orderObj.openOrderActionPopUp}
                        arrow={true}
                        classes={toolTipClasses}
                        title={
                          <CustomPlannedOrderActionTooltip
                            classes={classes}
                            consignmentCode={orderObj?.consignmentCode}
                            orderObjIndex={orderObjIndex}
                            routeName={routeName}
                            routeDataName={routeDataName}
                            slotName={slotName}
                            removeOrderFromRoute={removeOrderFromRoute}
                            addOrderToAnotherRoute={addOrderToAnotherRoute}
                            orderActionPopUpViewChange={orderActionPopUpViewChange}
                          />
                        }
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="right"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        onClose={(e: any) => orderActionPopUpViewChange && orderActionPopUpViewChange(e, routeName, routeDataName, slotName, orderObjIndex)}
                      >
                        <AccordionSummary
                          expandIcon={
                            <SvgIcon
                              component={downArrowIcon}
                              onClick={(e: any) => handleRouteOrderAccordianToggle && handleRouteOrderAccordianToggle(e, routeName, routeDataName, slotName, orderObjIndex)}
                            />
                          }
                          onClick={(e: any) => handleRouteOrderAccordianToggle && handleRouteOrderAccordianToggle(e, routeName, routeDataName, slotName, orderObjIndex)}
                          className={`${classes.plannedRouteInnerOrderAccordianSummaryStyle}`}
                        >
                          <RenderOrderDetailsAccordionSummary
                            classes={classes}
                            routeObj={routeObj}
                            routeName={routeName}
                            routeDataName={routeDataName}
                            slotName={slotName}
                            orderObj={orderObj}
                            orderObjIndex={orderObjIndex}
                            orderActionPopUpViewChange={orderObj.openOrderActionPopUp ? undefined : orderActionPopUpViewChange}
                            handleOrderCheckboxToggle={handleOrderCheckboxToggle}
                            viewMode={viewMode}
                          />
                        </AccordionSummary>
                      </Tooltip>
                      <AccordionDetails className={classes.plannedRouteInnerOrderAccordianDetailsStyle}>
                        {orderObj.isOpened && <RenderOrderAccordianDetails classes={classes} orderObj={orderObj} />}
                      </AccordionDetails>
                    </Accordion>
                  ))
                : renderNoItemsToDisplay()}
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(RenderRouteSlotAccordianConfiguration);
