import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {COLORS} from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaperPropsRoot: {
      minWidth: "45vw",
      height: "auto",
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    dialogPaperPropsConfirmRoot:{
      minWidth: "10vw",
      height: "auto",
      width:"25vw",
      margin: "auto",
      borderRadius: theme.spacing(1),
      "& .MuiDialogContent-root": {
        padding:'16px 0 0 0'
      },
    },
    uploadContainer: {
      "& .buttonsContainer": {
        "& .userBtn": {
          "&.secondary": {
            marginRight: theme.spacing(2),
          },
          flexGrow: 1,
        },
      },
    },
    uploadButtonsContainer: {
      justifyContent: "flex-start",
      paddingBottom: theme.spacing(3),
      "& .dialogBtn": {
        "&.secondary": {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1),
        },
      },
    },
    contentItem: {
        margin: 8,
        padding: "12px 12px",
        width: "auto",
        minHeight: 200,
    },
    
    contentItemConfirm: {
      margin: 8,
      padding: "18px 12px",
      width: "auto",
      textAlign:"center"
      
  },
  buttonsGroupConfirm:{
    margin: '12px auto',
    '& .MuiButton-contained': {
       borderRadius: 18,
       fontSize:12,
       margin: 4
    },
    
},
    buttonsGroup:{
      margin: "auto",
      marginTop: "2rem",
        '& .MuiButton-contained': {
           borderRadius: 18,
           fontSize:12,
           margin: 4
        },
        '& .disableButton':{
          background:'gray !important',
          color:'white !important'
        }
        
    },
    commDialogTitle: {
      fontSize: 14,
      fontWeight: 600,
      marginLeft:12,
      paddingTop: 12
    },
    container: {
        "& .commentContainer": {
          marginTop: 12
        
        },
        "& .dropDownContainer": {

            "& .MuiOutlinedInput-root": {
                   width:'40.7vw'
            }

        }
        
    },
    containerCenter:{
      textAlign: 'center'
    }
   
  })
);
