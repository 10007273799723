import React, {useCallback, useEffect, useRef, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "../../utils/loadash.utils";
import {currentDate, startOfDay} from "../../utils/dateUtils";
import {isUndefined} from "utils/helpers.utils";
//common components
import {AppState} from "../../config/redux/reducers";
import AppConstants from "../../constants";
import GridWrapper from "../../common/GridWrapper";
import Loader from "../../common/Loader";
import CustomSnackbar from "../../common/CustomSnackbar";
import SelectBox from "../../common/SelectBox";
import DateRangePickerComponent from "../../common/DateRangeComponent/index";
import {TextConstants} from "constants/TextConstants";
//Material UI Components
import {CellParams} from "../../common/GridWrapper/DataTable";
import {Grid, Typography, IconButton, SvgIcon, Button} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
//styles
import {useStyles} from "./codportal.styles";
//assets
import calendarIcon from "../../assets/icons/calendar-icon.svg";
import arrowIcon from "../../assets/icons/CaretCircleRight.svg";
import {ReactComponent as checkIcon} from "../../assets/icons/circle-checked-icon.svg";
//redux
import {fetchDriversList, exportToExcel, createCodReceiptDraft} from "./redux/stdCodPortalSlice";
import {userLogout} from "../../Login/redux/loginSlice";
import {
  createPayloadToFetchDriversList,
  codPortalDriversDataTransform,
} from "../../mocks/stdCodPortal/response.transforms";
import BucketComponent from "../../common/BucketComponent";
import CODDriverDetails from "./stdCodDriverDetails";
import CODUploadReceipt from "./stdCodUploadReceipt";
interface StandardCodPortalProps {
  match?: any;
  location?: any;
}

const StandardCodPortal = (props: StandardCodPortalProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {countryCode, userDetailsDropdowns} = useSelector((state: AppState) => state.common);
  const {
    driversData,
    loading,
    driversFiltersDropdowns,
    error,
    errorCode,
    excelLoading,
    exportError,
    isAmtCollected,
  } = useSelector((state: AppState) => state.stdCodPortal);
  const {isLastMileViewer, isOnlyStdLastMileViewer} = useSelector((state: AppState)=> state.userLogin);

  const country = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value;
  const [openDriverDetails, setOpenDriverDetails] = useState(false);
  const [dashboardHub, setDashboardHub] = useState(
    isUndefined(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode])
      ? ""
      : userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode][0].value
  );
  const [datePickerValue, setDatePickerValue] = useState({
    startDate: currentDate(AppConstants.DATE_FORMAT),
    endDate: currentDate(AppConstants.DATE_FORMAT),
  });
  const [driversTableWrapperData, setDriversTableWrapperData] = useState({
    ...AppConstants.STD_COD_RECON_CONSTANTS.DRIVERS_DATA.TABLE_WRAPPER_DATA,
    HeaderData: AppConstants.STD_COD_RECON_CONSTANTS.DRIVERS_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = header;
      if (newHeader.field === "actions") {
        newHeader.renderCell = (params: CellParams) => <img className='actionsLink' src={arrowIcon} alt='arrow' />;
      }
      return newHeader;
    }),
    defaultFiltersObj: {
      ...AppConstants.STD_COD_RECON_CONSTANTS.DRIVERS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj,
      dateRange: {
        ...AppConstants.STD_COD_RECON_CONSTANTS.DRIVERS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange,
        value: {
          datePickerValue,
        },
      },
    },
  });
  const [driversActiveFilters, setDriversActiveFilters] = useState(driversTableWrapperData.defaultFiltersObj);
  const [ordersSearchValue, setOrdersSearchValue] = useState("");
  const [isOrderSearched, setIsOrderSearched] = useState(false);

  const [driversListToShow, setDriversListToShow] = useState([]);
  const [driverDetails, setDriverDetails] = useState([]);

  const [renderExcelProgress, setRenderExcelProgress] = useState(false);
  const [openViewAndUploadPopup, setOpenViewAndUploadPopup] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const closeDriverDetailsPopup = () => {
    setOpenDriverDetails(false);
  };

  const handleViewAndUploadDialogClose = () => {
    setOpenViewAndUploadPopup(false);
  };

  const handleProgressClose = () => {
    setRenderExcelProgress(false);
  };

  const handleSnackbarExited = useCallback(() => {
    if (errorCode === AppConstants.COD_RECON_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
      dispatch(userLogout());
    }
  }, [dispatch, errorCode]);

  const driversFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean, params?: any) => {
      if (filters) {
        let payloadFilters = cloneDeep(filters);
        payloadFilters.pageSize = driversTableWrapperData.loadMore.pageSize;
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        const payload = createPayloadToFetchDriversList(
          payloadFilters,
          params,
          driversData,
          isLoadMore,
          datePickerValue,
          dashboardHub
        );
        dispatch(
          fetchDriversList({
            params: payload,
          })
        );
        setDriversActiveFilters(filters);
      }
      setRenderExcelProgress(false);
    },
    [dispatch, driversData, driversTableWrapperData, countryCode, dashboardHub, datePickerValue]
  );

  const handleExcelExport = useCallback(
    (filters: any, hub: any, dateValue: any, country: string) => {
      if (filters) {
        let payloadFilters = cloneDeep(filters);
        payloadFilters.pageSize = driversTableWrapperData.loadMore.pageSize;
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        const payload = createPayloadToFetchDriversList(
          payloadFilters,
          driversData,
          driversData,
          false,
          dateValue,
          hub
        );
        dispatch(
          exportToExcel({
            payload: payload,
          })
        );
      }
      setRenderExcelProgress(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, driversData, country, dashboardHub, datePickerValue]
  );

  const handleOrdersHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case "excel":
            handleExcelExport(driversActiveFilters, dashboardHub, datePickerValue, country);
            break;
          default:
            break;
        }
      }
    },
    [driversActiveFilters, dashboardHub, datePickerValue, country, handleExcelExport]
  );

  const handleFetchDriverParameters = useCallback(
    (params: any) => {
      driversFilterCallback(driversActiveFilters, false, params);
    },
    [driversActiveFilters, driversFilterCallback]
  );

  const handleViewAndUploadClick = useCallback(
    (hubId: any, date: any) => {
      const payload = {
        hubId: hubId,
        reconcileDate: startOfDay(date.startDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
      };
      dispatch(
        createCodReceiptDraft({
          params: payload,
        })
      );
      setOpenViewAndUploadPopup(true);
    },
    [dispatch]
  );

  const handleDateChange = (dateRange: any) => {
    setDatePickerValue(dateRange);
    handleFetchDriverParameters({
      dateRange: dateRange,
      hubCode: dashboardHub,
    });
  };

  const handleHubChange = useCallback(
    (value: any) => {
      setDashboardHub(value);
      handleFetchDriverParameters({
        dateRange: datePickerValue,
        hubCode: value,
      });
    },
    [datePickerValue, handleFetchDriverParameters]
  );

  const handleLoadMoreDisableState = useCallback(() => {
    let loadMoreState = driversTableWrapperData.loadMore;
    loadMoreState.disabled = driversData.totalPages ? driversData.pageNumber + 1 >= driversData.totalPages : true;
    setDriversTableWrapperData({...driversTableWrapperData, loadMore: loadMoreState});
  }, [driversTableWrapperData, driversData]);

  const ordersSearchFilterHandler = (data: {[key: string]: string}, updatedSearchValue: string, filters: any) => {
    const activeFilters = cloneDeep(filters);
    setOrdersSearchValue(updatedSearchValue);
    setIsOrderSearched(false);
    if (updatedSearchValue) {
      setIsOrderSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey],
          };
          driversFilterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  };

  const handleDriverCellClick = (params: CellParams) => {
    if (params.field === "actions") {
      const selectedRow: any = params.rowData;
      setDriverDetails(selectedRow);
      setOpenDriverDetails(true);
    }
  };

  useEffect(() => {
    if (driversTableWrapperData && driversTableWrapperData.HeaderData) {
      let headerData = driversTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === "driverName") {
            header.filterObj.items = driversFiltersDropdowns.driverNameList || [];
          }
          if (header.filterObj.fieldName === "vendorName") {
            header.filterObj.items = driversFiltersDropdowns.vendorNameList || [];
          }
        }
        return header;
      });
      setDriversTableWrapperData({...driversTableWrapperData, HeaderData: headerData});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversFiltersDropdowns]);

  useEffect(() => {
    setDriversListToShow(codPortalDriversDataTransform(driversData) as any);
    handleLoadMoreDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversData]);

  useEffect(() => {
    setDatePickerValue({
      startDate: currentDate(AppConstants.DATE_FORMAT),
      endDate: currentDate(AppConstants.DATE_FORMAT),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error]);

  useEffect(() => {
    if (isAmtCollected) {
      driversFilterCallback(driversActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAmtCollected]);

  const getHeaderConfig = useMemo(() => {
    const driverCodDetails = driversData;
    return [
      {
        tileHeading: TextConstants.TOTAL_COD_ORDERS,
        tileData: driverCodDetails?.totalOrders,
        subHeading: `(${country})`,
      },
      {
        tileHeading: TextConstants.TOTAL_ORDERS_AMOUNT,
        tileData: driverCodDetails?.totalOrdersCODAmount,
        subHeading: `(${country})`,
      },
      {
        tileHeading: TextConstants.TOTAL_COD_COLLECTED,
        tileData: driverCodDetails?.totalCODAmount,
        subHeading: "(By Driver)",
      },
      {
        tileHeading: TextConstants.COD_AMOUNT_RECEIVED,
        tileData: driverCodDetails?.totalCODReceived,
        subHeading: "(By Dispatcher)",
      },
      {
        tileHeading: TextConstants.TOTAL_COD_AMOUNT,
        tileData: driverCodDetails?.totalCODPending,
        subHeading: "pending",
        classes: {subHeading: classes.subHeadingBold},
      },
    ];
  }, [driversData, country, classes.subHeadingBold]);

  const HeaderComponent = () => {
    const config = getHeaderConfig;

    return (
      <Grid className={classes.bucketHeader}>
        <BucketComponent config={config} />
      </Grid>
    );
  };

  useEffect(() => {
    setDashboardHub(
      isUndefined(userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode])
        ? ""
        : userDetailsDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode][0].value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsDropdowns]);

  return (
    <>
      <div className={classes.root}>
        {loading && <Loader></Loader>}
        <Grid container justify='space-between'>
          <Grid item xs={4} className={classes.container}></Grid>
          <Grid item xs={6} className={classes.container}>
            <Grid container spacing={2}>
              <Grid item className={classes.tilesView}>
                <SelectBox
                  fullWidth
                  className={classes.select}
                  handleChange={handleHubChange}
                  value={dashboardHub}
                  enableSearch={true}
                  label='Hub'
                  id='dashboard-hub-select-box'
                  items={userDetailsDropdowns.hubListWithCodeAndName[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || []}
                ></SelectBox>
              </Grid>
              <Grid item className={classes.tilesView}>
                <DateRangePickerComponent
                  fullWidth
                  keyId='create-route-date-picker'
                  label='Date'
                  className={classes.icon}
                  value={datePickerValue}
                  isTextField={true}
                  iconSrc={calendarIcon}
                  autoUpdateInput={false}
                  autoApply={true}
                  maxSpan={{
                    day: "30",
                  }}
                  showDropdowns={true}
                  linkedCalendars={true}
                  locale={{
                    format: AppConstants.DATE_FORMAT,
                  }}
                  updateDateRange={handleDateChange}
                />
              </Grid>
              {!(isLastMileViewer || isOnlyStdLastMileViewer) && (
                <>
                  {datePickerValue.startDate === datePickerValue.endDate ? (
                    <Grid item>
                      <Button
                        className='dialogBtn primary'
                        variant='contained'
                        onClick={() => {
                          dashboardHub && handleViewAndUploadClick(dashboardHub, datePickerValue);
                        }}
                      >
                        {AppConstants.BUTTONS.VIEW_AND_UPLOAD}
                      </Button>
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <HeaderComponent />

        <Grid item className='panelItem gridWrapper'>
          <GridWrapper
            showHeader={true}
            checkboxSelection={false}
            headerData={driversTableWrapperData.HeaderData}
            rowData={driversListToShow}
            loading={loading}
            searchTypes={driversTableWrapperData.SEARCH_TYPES}
            defaultFilter={driversTableWrapperData.defaultFiltersObj}
            activeFilter={driversActiveFilters}
            headerButtons={driversTableWrapperData.headerButtons}
            title={driversTableWrapperData.title}
            disableSelectionOnClick={true}
            searchValue={ordersSearchValue}
            loadMore={{...driversTableWrapperData.loadMore, rowCount: driversData.totalElements || 0}}
            filterGrid={driversFilterCallback}
            headerBtnClick={handleOrdersHeaderButtonClick}
            searchCallback={ordersSearchFilterHandler}
            onCellClick={handleDriverCellClick}
          />
        </Grid>
        {!error && renderExcelProgress ? (
          <Grid container className={classes.excelDownloadStatus}>
            {excelLoading ? (
              <Grid item>
                <Typography className={classes.excelDownloadStatusText}>Your files are getting downloaded</Typography>
              </Grid>
            ) : (
              <Grid item>
                <Typography className={classes.excelDownloadStatusText}>Your files have been downloaded</Typography>
              </Grid>
            )}
            {excelLoading ? (
              <Loader></Loader>
            ) : (
              <Grid item className={classes.container}>
                <SvgIcon className={classes.checkedIconStyle} component={checkIcon} />
                <IconButton aria-label='close' className={classes.close} onClick={handleProgressClose}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ) : exportError === "INTERNAL_SERVER_ERROR" && renderExcelProgress ? (
          <Grid container className={classes.excelDownloadError}>
            <Grid item>
              <Typography className={classes.excelDownloadStatusText}>
                Error Downloading the file. Try Again!!
              </Typography>
            </Grid>
            <Grid item className={classes.container}>
              <IconButton aria-label='close' className={classes.close} onClick={handleProgressClose}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
        ) : null}
        <CODDriverDetails
          open={openDriverDetails}
          driverDetails={driverDetails}
          dashboardHub={dashboardHub}
          dateRange={datePickerValue}
          closePopup={closeDriverDetailsPopup}
        />
        <CODUploadReceipt
          open={openViewAndUploadPopup}
          hub={dashboardHub}
          date={datePickerValue}
          closePopup={handleViewAndUploadDialogClose}
        />
        <CustomSnackbar
          open={openSnackbar}
          handleClose={handleSnackbarClose}
          onExited={handleSnackbarExited}
          autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
          message={snackbarMessage.current}
          type={snackbarType.current}
        />
      </div>
    </>
  );
};

export default StandardCodPortal;
