import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .filterActive': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  })
);
