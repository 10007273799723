import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const hubsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "0px 12px 0px 12px",
            height: "100%",
        },
        container: {
            flexDirection: "column",
            height: "100%",
            '& .userItem': {
                width: '100%',
                '&.heading': {
                    paddingBottom: theme.spacing(2.5),
                    '& p': {
                        fontSize: 24,
                        color: COLORS.BLACK,
                        fontWeight: 600
                    }
                },
                '&.content': {
                    backgroundColor: COLORS.WHITE,
                    //boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
                    borderRadius: theme.spacing(1),
                    height: "calc(100vh - 172px)",
                    paddingTop: theme.spacing(1.5),
                    '& .hubIdLink': {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        fontSize: 12
                    },
                    '& .serviceType': {
                        color: "#2D2D2D",
                        fontWeight: 500,
                        fontSize: 12,
                        textTransform: "capitalize",
                    }
                }
            }
        },
        statusHeaderContainer: {
            '& .tag': {
                color: COLORS.WHITE,
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(0.5, 1),
                lineHeight: "14px",
                backgroundColor: COLORS.BLACK,
                fontWeight: 600,
                textTransform: "uppercase",
                fontSize: 12,
                '&.yellow': {
                    color: COLORS.GOLDEN_BELL,
                    backgroundColor: COLORS.GOLDEN_YELLOW_LIGHT
                },
                '&.green': {
                    color: COLORS.PERSIAN_GREEN_DARK,
                    backgroundColor: COLORS.PERSIAN_GREEN_LIGHT
                },
                '&.red': {
                    color: COLORS.DARK_RED,
                    backgroundColor: COLORS.LIGHT_RED
                },
                '&.gray': {
                    color: COLORS.GRAY_DARK,
                    backgroundColor: COLORS.ALTO
                },
                '&.black': {
                    backgroundColor: COLORS.BLACK,
                    color: COLORS.WHITE
                }
            }
        },
        actionsHeader: {
            alignItems: "center",
            '& .actionItem': {
                cursor: "pointer",
                '& .icon': {
                    padding: theme.spacing(0.25)
                }
            }
        }
    }),
);
