import { createStyles, makeStyles } from "@material-ui/core";
import { fontSize } from "styled-system";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      margin: "auto",
      display: "block",
    },
    icon: {
      width: "auto",
      height: "auto",
      fill: "none",
    },
    noDataContent: {
      alignItems: "center",
      gap: "12px",
      "& p": {
        fontSize: 14,
      },
    },
    actionButton: {
      padding: " 8px 16px",
      background: COLORS.PRIMARY_MAIN,
      borderRadius: "12px",
      color: COLORS.WHITE,
      "& span": {
        fontSize: 12,
      },
       '&:hover': {
          backgroundColor: COLORS.PRIMARY_MAIN
        }
    },
  })
);
