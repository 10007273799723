import React, { createRef, useState } from "react";

import { Card, Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { ALL_ROUTES_TEXT_CONTANT } from "RoutePlanning/AllRoutes/utils/AllRouteConstant";

import SelectBox from "../../../../common/SelectBox";
import CustomTooltip from "../../../../common/Tooltip";
import AppConstants from "../../../../constants/AppConstants";

import { isNonEmptyArray } from "../../../../utils/helpers.utils";
import { getAllAreaTitle, getSlotName } from "../../utils/AllRouteUtils";
import { getDateFromFormatInReqFormat } from "../../../../utils/dateUtils";

interface RenderSubrouteAssignDriverDialogInterface {
  classes: any;
  subRouteStateObj: any;
  driverValue: any;
  helperValue: any;
  vehicleValue: any;
  handleInputChange: (value: any, name: string) => void;
  getUpdatedAssigneList: (type: string, route: any) => any;
}

const RenderSubrouteAssignDriverDialog = (props: RenderSubrouteAssignDriverDialogInterface) => {
  const { classes, subRouteStateObj, driverValue, helperValue, vehicleValue, handleInputChange, getUpdatedAssigneList } = props;
  const ref = createRef() as any;
  const [assignDriverState, setAssignDriverState] = useState<{ showPopUp: boolean }>({ showPopUp: true });

  const handleHideShowPopUp = () => {
    setAssignDriverState({ ...assignDriverState, showPopUp: !assignDriverState.showPopUp });
  };

  return (
    <Card className={assignDriverState.showPopUp ? classes.assignDriverHelperCardStyle : classes.assignDriverHelperClosedCardStyle} ref={ref}>
      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} className={classes.dialogHeaderButtonGridItemStyle}>
          <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
            <Grid item>
              {assignDriverState.showPopUp && <Typography className={`${classes.routeTitleSpanStyle} ${classes.subRouteBoxStyle}`}>{`${ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE_DETAILS}`}</Typography>}
            </Grid>
            <Grid item className={classes.dialogHeaderButtonGridStyle}>
              <Grid container spacing={0} direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <IconButton aria-label="close" className="closeBtn" onClick={handleHideShowPopUp}>
                    {assignDriverState.showPopUp ? (
                      <CloseIcon />
                    ) : (
                      <CustomTooltip title={`${ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE_DETAILS_POP_UP_TOOL_TIP}`}>
                        <KeyboardArrowUpIcon />
                      </CustomTooltip>
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {assignDriverState.showPopUp && (
          <>
            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.PARENT_ROUTE_ID}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${subRouteStateObj?.parentRouteName}`}</Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE_ID}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${subRouteStateObj?.routeId}`}</Typography>
            </Grid>

            <Grid item>
              <SelectBox
                handleChange={(value: any) => handleInputChange(value, "Driver")}
                value={driverValue}
                id="driver-select-box"
                label="Driver"
                enableSearch={true}
                customWidthSearchBar={true}
                items={getUpdatedAssigneList("driver", subRouteStateObj)}
                showSelectNone={true}
              ></SelectBox>
            </Grid>
            <Grid item>
              <SelectBox
                handleChange={(value: any) => handleInputChange(value, "Helper")}
                value={helperValue}
                enableSearch={true}
                customWidthSearchBar={true}
                id="helper-select-box"
                label="Helper"
                items={getUpdatedAssigneList("helper", subRouteStateObj)}
                showSelectNone={true}
              ></SelectBox>
            </Grid>
            <Grid item>
              <SelectBox
                handleChange={(value: any) => handleInputChange(value, "Vehicle")}
                value={vehicleValue}
                id="vehicle-select-box"
                label="Vehicle"
                items={getUpdatedAssigneList("vehicle", subRouteStateObj)}
                showSelectNone={true}
              ></SelectBox>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.HUB}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${subRouteStateObj?.hubName}`}</Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.DELIVERY_AREA}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${getAllAreaTitle(subRouteStateObj?.deliveryArea)}`}</Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.CONSIGNMENTS}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${subRouteStateObj?.consignmentsIds?.length}`}</Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.CRATES}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${subRouteStateObj?.totalCrates}`}</Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.DELIVERY_SLOT}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${
                subRouteStateObj?.deliverySlot && isNonEmptyArray(subRouteStateObj?.deliverySlot) && getSlotName(subRouteStateObj?.deliverySlot[0])
              }`}</Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.dialogHeaderSpanStyle}>{`${ALL_ROUTES_TEXT_CONTANT.DELIVERY_DATE}`}</Typography>
              <Typography className={classes.dialogValueSpanStyle}>{`${
                subRouteStateObj?.plannedDate && getDateFromFormatInReqFormat(subRouteStateObj.plannedDate, AppConstants.DATE_FORMAT_BACKEND, AppConstants.DATE_FORMAT)
              }`}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default React.memo(RenderSubrouteAssignDriverDialog);
