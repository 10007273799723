import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow.svg";

import { COLORS } from "../../../config/theme/baseTheme";
import AppConstants from "../../../constants";
import DelveOrderDetailsItemsContent from "./DelveOrderDetailsItemsContent";

import { fixDecimals, isCardOnDelivery } from "../../../utils/helpers.utils";
import { TAB_VALUES_CONSTANT } from "../../../constants/DelveDashboardConstant";

interface interfaceDelveOrderDetailsItemsSummaryContent {
  classes: any;
  items: any;
  itemsVerified: any;
  metadata: any;
  deliveryInformation: any;
  showOriginalName: any;
  handleOriginalName: any;
  tabValue: any;
}

const DelveOrderDetailsItemsSummaryContent = (props: interfaceDelveOrderDetailsItemsSummaryContent) => {
  const { classes, items, itemsVerified, metadata, deliveryInformation, showOriginalName, handleOriginalName, tabValue } = props;

  return (
    <Grid className="tabularSection items" item>
      <Accordion>
        <AccordionSummary
          expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
          aria-controls="consignment-history-content"
          id="consignment-history-header"
          className={classes.accordionSummary}
        >
          <Grid container direction="column">
            <Typography className="header">{`Items Summary ${items !== undefined && Array.isArray(items) && items.length > 0 ? "(" + items.length + ")" : ""} `}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.itemSummaryAccordianDetailsStyle}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.itemSummaryHeaderContainerDivStyle}>
                <Grid item xs={4} className={classes.itemSummaryHeaderItemDivStyle}>
                  <Typography className={classes.itemSummaryHeadingValueSpanStyle}>Product Name</Typography>
                </Grid>
                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingValueSpanStyle}>{tabValue === TAB_VALUES_CONSTANT.RETURNS.value ? "Shipped Quantity" : "Ordered Quantity"}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingValueSpanStyle}>{tabValue === TAB_VALUES_CONSTANT.RETURNS.value ? "Returned Quantity" : "Shipped Quantity"}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingValueSpanStyle}>Reported Quantity</Typography>
                </Grid>
                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingValueSpanStyle}>Unit Price</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DelveOrderDetailsItemsContent items={items} classes={classes} metadata={metadata} showOriginalName={showOriginalName} handleOriginalName={handleOriginalName} tabValue={tabValue} />
            </Grid>
          </Grid>
          <Grid container className={classes.itemSummaryFooterMainContainerDivStyle}>
            <Grid className={classes.itemSummaryFooterMainItemDivStyle} item xs={12}>
              <Grid container className={classes.itemSummaryFooterGridContainerStyle}>
                <Grid item xs={6} className={classes.itemSummaryFooterLeftGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueSpanStyle}>Payment Method</Typography>
                </Grid>
                <Grid item xs={6} className={classes.itemSummaryFooterRightGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueSpanStyle}>{metadata && metadata.paymentType ? metadata.paymentType : ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.itemSummaryFooterMainItemDivStyle} item xs={12}>
              <Grid container className={classes.itemSummaryFooterGridContainerStyle}>
                <Grid item xs={6} className={classes.itemSummaryFooterLeftGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueSpanStyle}>Paid by SHARE points</Typography>
                </Grid>
                <Grid item xs={6} className={classes.itemSummaryFooterRightGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueSpanStyle}>{"-"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.itemSummaryFooterMainItemDivStyle} item xs={12}>
              <Grid container className={classes.itemSummaryFooterGridContainerStyle}>
                <Grid item xs={6} className={classes.itemSummaryFooterLeftGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueSpanStyle}>Delivery Charges</Typography>
                </Grid>
                <Grid item xs={6} className={classes.itemSummaryFooterRightGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueSpanStyle}>
                    {deliveryInformation && metadata
                      ? `${fixDecimals(2, deliveryInformation.deliveryCost ? deliveryInformation.deliveryCost.toString() : "") || ""} ${(metadata && metadata.currency) || ""}`
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.itemSummaryFooterMainItemDivLastChildStyle} item xs={12}>
              <Grid container className={classes.itemSummaryFooterGridContainerStyle}>
                <Grid item xs={6} className={classes.itemSummaryFooterLeftGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueBoldSpanStyle}>
                    Grand Total <span className={classes.itemSummaryFooterValueSpanStyle}>{` (inclusive of VAT) `}</span>
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.itemSummaryFooterRightGridItemStyle}>
                  <Typography className={classes.itemSummaryFooterValueSpanStyle}>
                    {metadata ? `${fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {metadata && (metadata.paymentType === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.COD_TEXT || metadata?.paymentType  && isCardOnDelivery(metadata.paymentType)) ? (
              <>
                <Grid className={`${classes.itemSummaryFooterMainItemDivStyle} ${classes.borderTopStyle}`} item xs={12}>
                  <Grid container className={classes.itemSummaryFooterGridContainerStyle}>
                    <Grid item xs={6} className={classes.itemSummaryFooterLeftGridItemStyle}>
                      <Typography className={classes.itemSummaryFooterValueSpanStyle}>Amount to be Collected</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.itemSummaryFooterRightGridItemStyle}>
                      <Typography className={classes.itemSummaryFooterValueSpanStyle}>
                        {metadata ? `${fixDecimals(2, metadata.amountToCollect ? metadata.amountToCollect.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.itemSummaryFooterMainItemDivStyle} item xs={12}>
                  <Grid container className={classes.itemSummaryFooterGridContainerStyle}>
                    <Grid item xs={6} className={classes.itemSummaryFooterLeftGridItemStyle}>
                      <Typography className={classes.itemSummaryFooterValueBoldSpanStyle}>Collected Amount</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.itemSummaryFooterRightGridItemStyle}>
                      <Typography className={classes.itemSummaryFooterValueBoldSpanStyle}>
                        {metadata ? `${fixDecimals(2, metadata.collectedAmount ? metadata.collectedAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default React.memo(DelveOrderDetailsItemsSummaryContent);
