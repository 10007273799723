import { debounce, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './GridWrapper.styles';
import { SelectItemProps } from '../SelectBox';
import CustomHeaderComponent from './CustomHeader';
import DataTable, { CellParams, ComponentProps, RowParams, SelectionChangeParams, SortModel } from './DataTable';
import CustomFooterComponent from './CustomFooter';
import AppConstants from "../../constants";

import { isUndefined } from '../../utils/helpers.utils';

interface GridWrapperProps {
  headerData: any;
  rowData: Array<any>;
  loading: boolean;
  showHeader?: boolean;
  searchTypes: Array<SelectItemProps>;
  defaultFilter: any;
  activeFilter?: any;
  loadMore: any;
  checkboxSelection: boolean;
  headerButtons?: Array<any>;
  title: string;
  disableSelectionOnClick?: boolean;
  sortModel?: SortModel;
  searchValue?: string;
  isTabChange?: boolean;
  onRowClick?: ((params: RowParams) => void) | undefined;
  onCellClick?: ((params: CellParams) => void) | undefined;
  onSelectionChange?: (params: SelectionChangeParams) => void | undefined;
  onSort?: ((params: SortModel) => void) | undefined;
  headerBtnClick?: (params: any) => void | undefined;
  searchCallback: (data: any, updatedSearchValue: string, filters?: any) => void;
  filterGrid: (filters: any, isLoadMore?: boolean) => void;
  fetchAllFilters?: () => void;
  resetFilter?: () => void;
  tabValue ?: string;
  exceptions?: boolean | undefined;
  filterForm?: Array<any>; 
  handleFilterChange?: (fieldName: string, value: any) => void;
  defaultFormFilter?: any;
  callOnActionOnly?: boolean;
  scrollToTopOnReset?: boolean;
  restFilterForm?:(params:boolean)=>void;
  deSelectCheckbox?: boolean;
  defaultSelectAll?: boolean;
  CustomFiltersApplied?: boolean;
  disableFilterCount?:boolean;
  reselctSelectedCheckbox?:boolean;
}

const switchCasesCallbacks: any = {};

const GridWrapper = (props: GridWrapperProps) => {
  const classes = useStyles();
  const filterRef = useRef<HTMLDivElement>(null);

  const {
    headerData,
    rowData,
    loading,
    searchTypes,
    searchValue,
    checkboxSelection,
    defaultFilter,
    activeFilter,
    loadMore,
    headerButtons,
    disableSelectionOnClick,
    exceptions,
    sortModel,
    isTabChange,
    showHeader,
    onSelectionChange,
    onSort,
    headerBtnClick,
    onRowClick,
    onCellClick,
    searchCallback,
    fetchAllFilters,
    filterGrid,
    tabValue,
    filterForm,
    handleFilterChange,
    defaultFormFilter,
    callOnActionOnly,
    scrollToTopOnReset,
    restFilterForm,
    deSelectCheckbox,
    defaultSelectAll,
    disableFilterCount,
    CustomFiltersApplied,
    reselctSelectedCheckbox,
  } = props;

  const [resetCustomFilter ,setResetCustomFilter]= useState(false);
  const [filters, setFilters] = useState(activeFilter ? activeFilter : defaultFilter);
  const [resetAllFilterFlag, setResetAllFilterFlag] = useState(false);
  const [resetSelection, setResetSelection] = useState(false);
  const [searchType, setSearchType] = useState(searchTypes.length ? searchTypes[0].value : "");
  const [placeholderText, setPlaceholderText] = useState("");

  const createPlaceHoldersSwitch = () => {
    if (searchTypes.length) {
      const addCase = (_case: any, fn: any) => {
        switchCasesCallbacks[_case] = switchCasesCallbacks[_case] || [];
        switchCasesCallbacks[_case].push(fn);
      }
      searchTypes.forEach(type => {
        addCase(type.value, () => {
          if (type.name === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.CRATE_ID) {
            setPlaceholderText(AppConstants.TODAY_DATA_TEXT);
          } else {
            setPlaceholderText(type.name || "");
          }
        });
      });
    }
  }
  const getPlaceHolderText = useCallback(
    () => {
      if (switchCasesCallbacks[searchType]) {
        switchCasesCallbacks[searchType].forEach((fn: any) => {
          fn();
        })
      }
    },
    [searchType],
  );

  const handleSearchTypeChange = (value: any) => {
    setSearchType(value);
  }

  const updateFilter = useCallback(
    (filter: any) => {
      setResetAllFilterFlag(false);
      let newFilters = { ...activeFilter, [filter.key]: filter };
      // Delve
      // LMC 2337
      // If Delve Date range is chaged then isDelve flag needs to changed
      if (!isUndefined(activeFilter) && !isUndefined(activeFilter.isDelve)) {        
        newFilters.isDelve = true;
        if(filter.key === 'dateRange' || filter.key === "deliverySlot"){
          newFilters.isDelve = false;
          newFilters.delveDateRange = {...defaultFilter.delveDateRange}
        }else{
          newFilters.dateRange = {...defaultFilter.dateRange}
        }
      }
      
      if (filter.reset) {
        if (filter.key === 'dateRange') {
          newFilters.reset = true;
        }
      } else {
        newFilters.reset = false;
      }
      searchCallback({}, "");
      setFilters(newFilters);
    },
    [activeFilter, searchCallback]
  );

  const filterGridData = useCallback((isLoadMore?: boolean) => {
    if (isLoadMore) {
      setResetSelection(false);
    } else {
      setResetSelection(true);
    }
     filterGrid(isLoadMore ? activeFilter : filters, isLoadMore);
  }, [filters, activeFilter, filterGrid]);

  const handleHeaderBtnClick = useCallback(
    (buttonObj: any) => {
      let buttonParams: any = {type: buttonObj.name}
      if (buttonObj.name === 'excel') {
        buttonParams.params = filters;
      }     
      headerBtnClick && headerBtnClick(buttonParams);
    },
    [filters, headerBtnClick]
  );

  const handleClearAllFilters = useCallback(
    () => {
      setResetAllFilterFlag(true);
      const resetFilters = { ...defaultFilter };
      // Delve
      // LMC 2337
      // If Delve Date range is chaged then isDelve flag needs to changed
      if(!isUndefined(resetFilters) && !isUndefined(resetFilters.isDelve)){
        resetFilters.isDelve = true;
      }
      if(callOnActionOnly){
        restFilterForm && restFilterForm(true)
        setResetCustomFilter(true)
      }
      searchCallback({}, "");
      setFilters(resetFilters);
    },
    [defaultFilter, searchCallback],
  )

  const loadMoreCallback = debounce((event: any) => {
    const target = event.target;
    if (target) {
      if (loading || loadMore.disabled) return;
      if (target.offsetHeight + target.scrollTop + 1 >= target.scrollHeight) {
       
        filterGridData(true);
      }
    } else {
      return;
    }
  }, 100);

  const enableLoadMore = useCallback(
    (disable?: boolean) => {
      const element = document.querySelector('.data-table-scrollable-area');
      if (element) {
        if (!disable) {
          element.addEventListener('scroll', loadMoreCallback);
        } else {
          element.removeEventListener('scroll', loadMoreCallback);
        }
      }
    },
    [loadMoreCallback]
  );

  useEffect(() => {
    fetchAllFilters && fetchAllFilters();
    createPlaceHoldersSwitch();
    getPlaceHolderText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    enableLoadMore();
    return () => {
      enableLoadMore(true);
    }
  }, [loadMore, enableLoadMore])

  useEffect(() => {
    !callOnActionOnly && filterGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, callOnActionOnly]);

  useEffect(() => {
    if(resetCustomFilter){
      filterGridData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, resetCustomFilter]);

  useEffect(() => {
    // reset select all to initial state
    if(CustomFiltersApplied){
     setResetSelection(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CustomFiltersApplied]);

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container>
        <Grid className={classes.tableSection} item>
          <DataTable
            showHeader={showHeader}
            loading={loading}
            rows={rowData}
            columns={headerData}
            rowHeight={48}
            headerHeight={48}
            isTabChange={isTabChange}
            checkboxSelection={checkboxSelection}
            defaultFilter={defaultFilter}
            activeFilters={filters}
            disableSelectionOnClick={disableSelectionOnClick}
            sortModel={sortModel}
            resetAllFilterFlag={resetAllFilterFlag}
            resetSelection={resetSelection}
            scrollToTopOnReset={scrollToTopOnReset}
            onRowClick={onRowClick}
            onCellClick={onCellClick}
            onSelectionChange={onSelectionChange}
            onSort={onSort}
            onUpdateFilter={updateFilter}
            rowCount={loadMore.rowCount}
            deSelectCheckbox={deSelectCheckbox}
            defaultSelectAll={defaultSelectAll}
            reselctSelectedCheckbox={reselctSelectedCheckbox}
            components={{
              header: (props: ComponentProps) => (<CustomHeaderComponent {...props} searchTypes={searchTypes} searchValue={searchValue || ""} searchType={searchType} placeholderText={placeholderText} getPlaceHolderText={getPlaceHolderText} setSearchTypeChange={handleSearchTypeChange} activeFilters={filters} buttons={headerButtons} searchCallback={searchCallback} btnClickHandler={handleHeaderBtnClick} clearAllFilterHandler={handleClearAllFilters} showHeader={showHeader} defaultFilter={defaultFilter} disableFilterCount={disableFilterCount} />),
              footer: (props: ComponentProps) => (<CustomFooterComponent {...props} />)
            }}
            tabValue={tabValue}
          />
        </Grid>
      </Grid>
    </div >
  );
}

export default GridWrapper;