import React, { createRef } from "react";

import { Button, Card, ClickAwayListener, Grid, SvgIcon, Typography } from "@material-ui/core";
import { isNonEmptyArray } from "../../../utils/helpers.utils";

import { ReactComponent as crateBoxIcon } from "../../../assets/icons/crate-box-icon.svg";

import AppConstants from "../../../constants/AppConstants";
import CustomTooltip from "../../../common/Tooltip";
import { getAreaTitle } from "../utils/AllRouteUtils";
import { getDeliveryAreas } from "../utils/AllRouteViewUtils";

interface AssignRouteToOrdersDialogInterface {
  classes: any;
  allRouteState: any;
  handleCloseAssignRoutePopUp: any;
  selectRouteToAssigntoOrder: any;
  handleAssignRouteClick: any;
}

const AssignRouteToOrdersDialog = (props: AssignRouteToOrdersDialogInterface) => {
  const { classes, allRouteState, handleCloseAssignRoutePopUp, selectRouteToAssigntoOrder, handleAssignRouteClick } = props;
  const ref = createRef() as any;

  return (
    <ClickAwayListener onClickAway={(event: any) => allRouteState?.openAddRouteConfirmDialog ? undefined : handleCloseAssignRoutePopUp(event)} disableReactTree={true} mouseEvent="onMouseUp">
      <Card className={classes.assignRouteToOrderCardStyle} ref={ref}>
        {allRouteState && allRouteState.availableRouteRecord && isNonEmptyArray(allRouteState.availableRouteRecord) ? (
          <>
            <Typography className={classes.assignRouteHeaderSpanStyle}>Routes</Typography>
            <Grid container spacing={1} className={classes.assignRouteMainGridStyle}>
              {allRouteState.availableRouteRecord.map((routeObj: any, routeObjIndex: any) => (
                <Grid
                  container
                  key={`${routeObj.routeId}-route-${routeObjIndex}`}
                  className={`${classes.assignRouteHeaderGridConatinerStyle} ${routeObj.isChecked ? classes.selectedRouteStyle : ""}`}
                  onClick={() => selectRouteToAssigntoOrder(routeObjIndex)}
                >
                  <Grid item xs={1} className={classes.assignRouteHeaderGridItemStyle}>
                    <Typography className={classes.assignRouteTitleSpanStyle}>{routeObj.routeId}</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.assignRouteHeaderGridItemStyle}>
                    <CustomTooltip title={getDeliveryAreas(routeObj?.deliveryArea)}>
                      <Typography className={classes.assignRouteNormalSpanStyle}>{`${getAreaTitle(routeObj)}`}</Typography>
                    </CustomTooltip>
                  </Grid>
                  <Grid item xs={2} className={classes.assignRouteHeaderGridItemStyle}>
                    <SvgIcon className={classes.assignRouteIconStyle} viewBox="0 0 24 24" component={crateBoxIcon} />
                    <Typography className={classes.assignRouteNormalSpanStyle}>{`${routeObj.totalCrates}`}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.assignRouteHeaderGridItemStyle}>{`Orders - ${routeObj.totalConsignment || 0}`}</Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button className={`${classes.buttonStyle} secondary`} variant="contained" onClick={(event: any) => handleCloseAssignRoutePopUp(event)}>
                  {AppConstants.BUTTONS.CANCEL}
                </Button>
              </Grid>
              <Grid item>
                <Button className={`${classes.buttonStyle} primary`} variant="contained" onClick={(event: any) => handleAssignRouteClick(event)}>
                  {AppConstants.BUTTONS.ADD}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography>No Routes to display</Typography>
        )}
      </Card>
    </ClickAwayListener>
  );
};

export default React.memo(AssignRouteToOrdersDialog);
