import React, { forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import _ from "lodash";
import { useFormik } from "formik";

import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { Button, Grid, Typography } from "@material-ui/core";

import CustomDialog from "../common/Dialog";
import AppConstants from "../constants";
import TextBox from "../common/TextBox";
import SelectBox, { SelectItemProps } from "../common/SelectBox";
import CustomSnackbar from "../common/CustomSnackbar";

import { useStyles } from "./UserAddOrEditDetails.styles";
import { AppState } from "../config/redux/reducers";
import { createGenerateIdPayload } from "../mocks/users/response.transforms";
import { generateUsername } from "./redux/usersSlice";
import { isNull } from "../utils/helpers.utils";

interface UserAddOrEditDetailsProps {
  open: boolean;
  user: any;
  isEdit: boolean;
  closePopup: () => void;
  handleDialogAction?: (params: any) => void;
}

const inputValidationSchema = yup.object({
  firstName: yup.string().required(AppConstants.USERS_CONSTANTS.VALIDATIONS.FIRSTNAME.REQUIRED.msg),
  lastName: yup.string().required(AppConstants.USERS_CONSTANTS.VALIDATIONS.LASTNAME.REQUIRED.msg),
  userName: yup.string().required(AppConstants.VALIDATIONS.USERNAME.REQUIRED.msg),
  email: yup
    .string()
    .when("userType", {
      is: AppConstants.USERS_CONSTANTS.USERNAME_USERTYPE,
      then: yup.string().notRequired(),
      otherwise: yup.string().required(AppConstants.VALIDATIONS.EMAIL.REQUIRED.msg),
    })
    .email(AppConstants.VALIDATIONS.EMAIL.VALID_EMAIL.msg),
  password: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg),
  confirmPassword: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg)
    .oneOf([yup.ref("password")], AppConstants.VALIDATIONS.PASSWORD.ONE_OF.msg),
  prefix: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.PREFIX.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.PREFIX.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.PREFIX.REQUIRED.msg),
  phone: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.msg),
  status: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.STATUS.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.STATUS.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.STATUS.REQUIRED.msg),
  userRole: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg),
  userType: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.msg),
  country: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg),
  hub: yup
    .array()
    .of(
      yup
        .string()
        .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.msg)
        .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.msg)
    ),
});

const editPwdValidationSchema = yup.object({
  password: yup.string().matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg),
  confirmPassword: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .oneOf([yup.ref("password")], AppConstants.VALIDATIONS.PASSWORD.ONE_OF.msg),
});

const expressDriverValidationSchema = yup.object({
  vehicleType: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.VEHICLE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.VEHICLE.REQUIRED.msg),
  vendor: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.VENDOR.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.VENDOR.REQUIRED.msg),
});

const deliveryTypeValidationSchema = yup.object({
  deliveryType: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.DELIVERY_TYPE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.DELIVERY_TYPE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.DELIVERY_TYPE.REQUIRED.msg),
});

const Transition = forwardRef(function Transition(props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UserAddOrEditDetails = (props: UserAddOrEditDetailsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogPaperProps = { classes: { root: classes.dialogPaperPropsRoot }, square: true };

  const { open, user, isEdit, closePopup, handleDialogAction } = props;

  const { addUserDropdowns, suggestedUsername } = useSelector((state: AppState) => state.users);
  const { isAdmin } = useSelector((state: AppState) => state.userLogin);
  const { countryCode } = useSelector((state: AppState) => state.common);

  const [resetFormFlag, setResetFormFlag] = useState(false);
  const [userTypeState, setUserTypeState] = useState(AppConstants.SELECT_NONE.value);
  const [emailState, setEmailState] = useState("");
  const [isExpress, setIsExpress] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [userState, setUserState] = useState({openSnackbar: false, snackbarMessage: "", snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS, rolesList : []})

  let tableRecord: any = {};
  let hubDetails: any = [];
  let checkCountry: any = {};
  let selectedRole: any = "";
  let countryCodeValue = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
  if (user) {
    tableRecord = user.tableRecord;
    if (user.countryDetails && user.countryDetails[countryCodeValue]) {
      hubDetails = user.countryDetails[countryCodeValue] ? user.countryDetails[countryCodeValue].map((item: any) => item.hubCode) : [];
      checkCountry = user.countryDetails[countryCodeValue].filter((country: any) => country.countryName === tableRecord.country);
    }
    if (tableRecord && tableRecord.role) {
      const currentRoleIndex = AppConstants.USERS_CONSTANTS.USER_ROLES.findIndex((role) => role.value === tableRecord.role);
      const accessibleRoles = AppConstants.USERS_CONSTANTS.USER_ROLES.slice(currentRoleIndex);
      selectedRole = accessibleRoles.length ? accessibleRoles[0].code : "n/a";
    }
  }

  const getInitialValues = () => {
    let phoneNumber = user?.phone ? user.phone : "";
    let prefix: any = AppConstants.SELECT_NONE.value;
    if (addUserDropdowns?.phonePrefixesList?.length > 0) {
      const codeValue = checkCountry.length ? countryCodeValue : user?.tableRecord?.country ? user.tableRecord.country : AppConstants.SELECT_NONE.value;
      if(!isNull(phoneNumber)){
        let isPrefixPresent = addUserDropdowns.phonePrefixesList.findIndex((prefix : any) => phoneNumber.includes(prefix.value));
        if(isPrefixPresent > -1){
          prefix = addUserDropdowns.phonePrefixesList[isPrefixPresent].value;
          phoneNumber = phoneNumber.replace(prefix, "");
          phoneNumber = phoneNumber.trim();
        } 
      }
      
      if(prefix === AppConstants.SELECT_NONE.value){
        prefix = addUserDropdowns.phonePrefixesList.find((prefix: any) => prefix?.countryCode === codeValue)?.value || AppConstants.SELECT_NONE.value;
        if (!isNull(phoneNumber) && !isNull(prefix) && prefix !== AppConstants.SELECT_NONE.value && phoneNumber.includes(prefix)) {
          phoneNumber = phoneNumber.replace(prefix, "");
          phoneNumber = phoneNumber.trim();
        }
      }          
    }
    
    return isEdit
      ? {
          firstName: user.firstname ? user.firstname : "",
          lastName: user.lastname ? user.lastname : "",
          userName: user.username ? user.username : "",
          email: user.email ? user.email : "",
          phone: phoneNumber,
          status: user.status ? user.status : AppConstants.SELECT_NONE.value,
          userRole: selectedRole ? selectedRole : AppConstants.SELECT_NONE.value,
          userType: user.userType ? user.userType : AppConstants.SELECT_NONE.value,
          deliveryType: user.deliveryType ? user.deliveryType : AppConstants.SELECT_NONE.value,
          serviceTypes: user.serviceType ? user.serviceType : [AppConstants.SELECT_NONE.value],
          vehicleType: user.vehicleType ? user.vehicleType : AppConstants.SELECT_NONE.value,
          vendor: user.vendor ? user.vendor : AppConstants.SELECT_NONE.value,
          country: checkCountry.length ? countryCodeValue : user.tableRecord.country ? user.tableRecord.country : AppConstants.SELECT_NONE.value,
          hub: hubDetails.length ? hubDetails : [AppConstants.SELECT_NONE.value],
          prefix: prefix,
        }
      : {
          firstName: "",
          lastName: "",
          userName: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
          status: AppConstants.SELECT_NONE.value,
          userRole: AppConstants.SELECT_NONE.value,
          userType: AppConstants.SELECT_NONE.value,
          deliveryType: AppConstants.SELECT_NONE.value,
          serviceTypes: [AppConstants.SELECT_NONE.value],
          vehicleType: AppConstants.SELECT_NONE.value,
          vendor: AppConstants.SELECT_NONE.value,
          country: AppConstants.SELECT_NONE.value,
          hub: [AppConstants.SELECT_NONE.value],
          prefix: AppConstants.SELECT_NONE.value,
        };
  };

  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: isEdit
      ? inputValidationSchema.omit(["password", "confirmPassword"]).concat(editPwdValidationSchema)
      : isDriver && isExpress
      ? inputValidationSchema.concat(expressDriverValidationSchema).concat(deliveryTypeValidationSchema)
      : isDriver
      ? inputValidationSchema.concat(deliveryTypeValidationSchema)
      : inputValidationSchema,
    validate: () => {
      setResetFormFlag(false);
    },
    onSubmit: (values) => {
      dialogActionHandler("submit", values);
    },
  });

  const disableGenerateUserIdBtn = useCallback(() => {
    let disable = false;
    if (!isEdit) {
      if (userTypeState) {
        if (userTypeState === AppConstants.USERS_CONSTANTS.USERNAME_USERTYPE) {
          if (!(inputFormik.values.firstName && inputFormik.values.lastName)) {
            disable = true;
          } else {
            disable = false;
          }
        } else {
          disable = true;
        }
      } else {
        disable = true;
      }
    } else {
      disable = true;
    }
    return disable;
  }, [userTypeState, inputFormik.values.firstName, inputFormik.values.lastName, isEdit]);

  const generateUserId = () => {
    const generateIdPayload = createGenerateIdPayload({ firstName: `${inputFormik.values.firstName}`.toLowerCase(), lastName: `${inputFormik.values.lastName}`.toLowerCase() });
    dispatch(generateUsername({ params: generateIdPayload }));
  };

  const handleDialogClose = () => {
    closePopup();
  };

  const dialogActionHandler = (type: string, values?: any) => {
    if (handleDialogAction && type !== "cancel") {
      let objectValue = _.cloneDeep(values);
      if(objectValue?.phone?.length >= 11){
        setUserState({ ...userState, openSnackbar: true, snackbarMessage: AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.lengthIssue, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
      }
      else{
        objectValue.phone = objectValue?.prefix + " " + objectValue?.phone;
        delete objectValue.prefix;
        handleDialogAction({ type: type, values: objectValue, isEdit: isEdit });
      }
    } else {
      handleDialogAction && handleDialogAction({ type: type, values: values, isEdit: isEdit });
    }
  };

  const debounceFormikValues = _.debounce((fieldName: string, value: string) => {
    inputFormik.setFieldValue(fieldName, value);
  }, 500);

  const getRoleListRecord = (userType: any) => {
    if(addUserDropdowns?.rolesList?.length === 0) return [];

    const excludedRoles = [ AppConstants.USERS_CONSTANTS.DRIVER_ROLE, AppConstants.USERS_CONSTANTS.LAST_MILE_QC_EXECUTIVE_ROLE, AppConstants.USERS_CONSTANTS.ROLE_LAST_MILE_HELPER ];

    if(userType !== AppConstants.SELECT_NONE.value){
      let rolesList = [] as any;
      if(userType === AppConstants.USERS_CONSTANTS.USERNAME_USERTYPE){
        rolesList = addUserDropdowns.rolesList.filter((role: SelectItemProps) => excludedRoles.includes(role.value));
      }else{
        rolesList = addUserDropdowns.rolesList.filter((role: SelectItemProps) => !excludedRoles.includes(role.value));
      }
      return rolesList;
    }
    return [];
  }

  const handleStateChange = useCallback(
    (event: React.ChangeEvent<any>, fieldName: string) => {
      event.persist();
      if (fieldName === "userType") {
        setUserTypeState(event.target.value);
        const rolesList : any = getRoleListRecord(event.target.value);
        setUserState({ ...userState, rolesList: rolesList});
      }
      if (fieldName === "email") {
        setEmailState(event.target.value);
      }
      if (fieldName === "userRole") {
        if (event.target.value === AppConstants.USERS_CONSTANTS.DRIVER_ROLE) {
          setIsDriver(true);
        } else {
          inputFormik.setFieldValue("vehicleType", AppConstants.SELECT_NONE.value);
          inputFormik.setFieldValue("deliveryType", AppConstants.SELECT_NONE.value);
          inputFormik.setFieldValue("vendor", AppConstants.SELECT_NONE.value);
          setIsDriver(false);
        }
      }
      if (fieldName === "deliveryType") {
        if (event.target.value === AppConstants.USERS_CONSTANTS.EXPRESS) {
          inputFormik.setFieldValue("vendor", AppConstants.SELECT_NONE.value);
          inputFormik.setFieldValue("vehicleType", AppConstants.SELECT_NONE.value);
          setIsExpress(true);
        } else {
          inputFormik.setFieldValue("vendor", AppConstants.SELECT_NONE.value);
          inputFormik.setFieldValue("vehicleType", AppConstants.SELECT_NONE.value);
          setIsExpress(false);
        }
      }
      debounceFormikValues(fieldName, event.target.value);
    },
    [debounceFormikValues, inputFormik]
  );

  const showUserIdSelectBox = () =>
    inputFormik.values.userRole === AppConstants.USERS_CONSTANTS.DRIVER_ROLE || inputFormik.values.userRole === AppConstants.USERS_CONSTANTS.LAST_MILE_QC_EXECUTIVE_ROLE || inputFormik.values.userRole === AppConstants.USERS_CONSTANTS.ROLE_LAST_MILE_HELPER;

  useEffect(() => {
    if (userTypeState === AppConstants.USERS_CONSTANTS.USERNAME_USERTYPE) {
      if (!isEdit) {
        inputFormik.setFieldValue("userName", suggestedUsername || "");
      } else {
        inputFormik.setFieldValue("userName", user.username);
      }
    } else {
      if (!isEdit) {
        inputFormik.setFieldValue("userName", emailState);
      } else {
        inputFormik.setFieldValue("userName", user.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypeState]);

  useEffect(() => {
    if (userTypeState !== AppConstants.USERS_CONSTANTS.USERNAME_USERTYPE && !isEdit) {
      inputFormik.setFieldValue("userName", emailState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailState]);

  useEffect(() => {
    if (!isEdit) {
      inputFormik.setFieldValue("userName", suggestedUsername);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedUsername]);

  useEffect(() => {
    if (open) {
      inputFormik.resetForm();
      setUserTypeState(AppConstants.SELECT_NONE.value);
      setEmailState("");
      setResetFormFlag(true);
      inputFormik.setValues(getInitialValues());    
      if(isEdit && user?.userType){
        const rolesList : any = getRoleListRecord(user?.userType);
        setUserState({ ...userState, rolesList: rolesList});
      }else{
        setUserState({ ...userState, rolesList: []});
      }
      if (isEdit) {
        setUserTypeState(user.userType ? user.userType : AppConstants.SELECT_NONE.value);
        setEmailState(user.email ? user.email : "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label">{isEdit ? "Edit User" : "Add User"}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * A Handler called on country's change
   * NOTE: Writing a separate handler since the common component Select Box was getting the All values
   * before the parent component, due to which the Formik in parent component was not able to update the values
   */
  const handleCountryChange = (event: any) => {
    let countryValue = event.target.value;
    if (countryValue === AppConstants.SELECT_NONE.value) {
      inputFormik.setFieldValue("country", countryValue);
      inputFormik.setFieldValue("hub", [AppConstants.SELECT_NONE.value]);
      inputFormik.setFieldValue("vendor", AppConstants.SELECT_NONE.value);
      inputFormik.setFieldTouched("country", false);
      inputFormik.setFieldValue("prefix", AppConstants.SELECT_NONE.value);
      inputFormik.setFieldValue("phone", "");
    } else {
      inputFormik.setFieldValue("hub", []);
      inputFormik.setFieldValue("country", countryValue);
      inputFormik.setFieldValue("vendor", AppConstants.SELECT_NONE.value);
      inputFormik.setFieldValue("hub", addUserDropdowns.hubList[countryValue] ? [...[AppConstants.SELECT_ALL.value], ...addUserDropdowns.hubList[countryValue].map((item: any) => item.value)] : []);
      inputFormik.setFieldTouched("country", false);
      let prefix =
        addUserDropdowns?.phonePrefixesList?.length > 0
          ? addUserDropdowns.phonePrefixesList.find((prefix: any) => prefix?.countryCode === countryValue)?.value || AppConstants.SELECT_NONE.value
          : AppConstants.SELECT_NONE.value;
      inputFormik.setFieldValue("prefix", prefix);
      inputFormik.setFieldValue("phone", "");
    }
  };

  const handleRoleChange = (event: any) => {
    if (event?.target?.value) {
      const value = event.target.value;
      inputFormik.setFieldValue("userRole", value);
      if (event.target.value === AppConstants.USERS_CONSTANTS.DRIVER_ROLE) {
        setIsDriver(true);
      } else {
        inputFormik.setFieldValue("vehicleType", AppConstants.SELECT_NONE.value);
        inputFormik.setFieldValue("deliveryType", AppConstants.SELECT_NONE.value);
        inputFormik.setFieldValue("vendor", AppConstants.SELECT_NONE.value);
        setIsDriver(false);
      }
      setTimeout(() => inputFormik.setFieldTouched("userRole", true));
      // if (inputFormik.values.userType === AppConstants.USERS_CONSTANTS.USERNAME_USERTYPE && !showUserIdSelectBox()) {
      //   setTimeout(() => inputFormik.setFieldError("userRole", AppConstants.USERS_CONSTANTS.USER_ROLE_TYPE_ERROR), 150);
      // }
    }
  };

  const getVendorList: any = () => {
    let vendor = "";
    if (inputFormik.values.deliveryType === AppConstants.USERS_CONSTANTS.EXPRESS) {
      vendor = addUserDropdowns.vendorList[inputFormik.values.country] || [];
    } else {
      vendor = addUserDropdowns.vendorListStd[inputFormik.values.country] || [];
    }
    return vendor;
  };

  const handleSnackbarClose = () => {
    setUserState({ ...userState, openSnackbar: false, snackbarMessage: "", snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS });
  };

  const getDetailsContent = () => {
    return (
      <form onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.contentContainer} container>
          {/* Type */}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Type</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  disabled={isEdit}
                  handleFormikChange={(e) => handleStateChange(e, "userType")}
                  value={userTypeState}
                  error={inputFormik.touched.userType && Boolean(inputFormik.errors.userType)}
                  helperText={inputFormik.touched.userType && inputFormik.errors.userType}
                  reset={resetFormFlag}
                  id="AddUserTypeSelectBox"
                  inputProps={{
                    name: "userType",
                    id: "AddUserTypeSelectBox",
                  }}
                  items={addUserDropdowns.userTypeList}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>

          {/* Role */}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Role</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  disabled={isEdit && inputFormik.values.userRole === AppConstants.USERS_CONSTANTS.DRIVER_ROLE}
                  fullWidth
                  handleFormikChange={handleRoleChange}
                  value={inputFormik.values.userRole}
                  error={inputFormik.touched.userRole && Boolean(inputFormik.errors.userRole)}
                  helperText={inputFormik.touched.userRole && inputFormik.errors.userRole}
                  reset={resetFormFlag}
                  id="AddUserRoleSelectBox"
                  inputProps={{
                    name: "userRole",
                    id: "AddUserRoleSelectBox",
                  }}
                  items={userState.rolesList || []}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>

          {/* Country */}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Country</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={handleCountryChange}
                  value={inputFormik.values.country}
                  error={inputFormik.touched.country && Boolean(inputFormik.errors.country)}
                  helperText={inputFormik.touched.country && inputFormik.errors.country}
                  reset={resetFormFlag}
                  id="AddUserCountrySelectBox"
                  inputProps={{
                    name: "country",
                    id: "AddUserCountrySelectBox",
                  }}
                  items={addUserDropdowns.countriesList[inputFormik.values.userRole] || []}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          {/* Hub */}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Hub</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  multiple
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.hub}
                  error={inputFormik.touched.hub && Boolean(inputFormik.errors.hub)}
                  helperText={inputFormik.touched.hub && inputFormik.errors.hub}
                  reset={resetFormFlag}
                  id="AddUserHubSelectBox"
                  inputProps={{
                    name: "hub",
                    id: "AddUserHubSelectBox",
                  }}
                  items={addUserDropdowns.hubList[inputFormik.values.country] || []}
                  enableSearch={true}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          {/* First Name */}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>First Name</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.firstName}
                  error={inputFormik.touched.firstName && Boolean(inputFormik.errors.firstName)}
                  helperText={inputFormik.touched.firstName && inputFormik.errors.firstName}
                  textBoxId="AddUserFirstnameTextbox"
                  name="firstName"
                  type="text"
                  placeholderText="First Name"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          {/* Last Name */}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Last Name</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.lastName}
                  error={inputFormik.touched.lastName && Boolean(inputFormik.errors.lastName)}
                  helperText={inputFormik.touched.lastName && inputFormik.errors.lastName}
                  textBoxId="AddUserLastnameTextbox"
                  name="lastName"
                  type="text"
                  placeholderText="Last Name"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          {inputFormik.values.userRole !== AppConstants.SELECT_NONE.value && (
            <>
              {inputFormik.values.userType === AppConstants.USERS_CONSTANTS.USERNAME_USERTYPE ? (
                showUserIdSelectBox() && (
                  <Grid className="contentItem" item>
                    <Grid className="itemContainer" container>
                      <Grid className="itemLabel" item>
                        <Typography>User Id</Typography>
                      </Grid>
                      <Grid className="itemInput" item>
                        <Grid className="userIdContainer" container>
                          <Grid className="userIdItem input" item>
                            <TextBox
                              fullWidth
                              variant="outlined"
                              onChange={inputFormik.handleChange}
                              value={inputFormik.values.userName}
                              error={inputFormik.touched.userName && Boolean(inputFormik.errors.userName)}
                              helperText={inputFormik.touched.userName && inputFormik.errors.userName}
                              disabled
                              textBoxId="AddUserUsernameTextbox"
                              name="userName"
                              type="text"
                              placeholderText="User Id"
                            ></TextBox>
                          </Grid>
                          <Grid className="userIdItem">
                            <Button className="userIdBtn secondary" variant="contained" disabled={disableGenerateUserIdBtn()} onClick={generateUserId}>
                              {AppConstants.BUTTONS.GENERATE}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              ) : (
                <Grid className="contentItem" item>
                  <Grid className="itemContainer" container>
                    <Grid className="itemLabel" item>
                      <Typography>Email Id</Typography>
                    </Grid>
                    <Grid className="itemInput" item>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        onChange={(e) => handleStateChange(e, "email")}
                        value={emailState}
                        error={inputFormik.touched.email && Boolean(inputFormik.errors.email)}
                        helperText={inputFormik.touched.email && inputFormik.errors.email}
                        disabled={isEdit}
                        textBoxId="AddUserEmailTextbox"
                        name="email"
                        type="text"
                        placeholderText="Email Id"
                      ></TextBox>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {isAdmin && isEdit && (
            <>
              <Grid className="contentItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Password</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.password}
                      error={inputFormik.touched.password && Boolean(inputFormik.errors.password)}
                      helperText={inputFormik.touched.password && inputFormik.errors.password}
                      textBoxId="EditUserPasswordTextbox"
                      name="password"
                      type="password"
                      placeholderText="Password"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Confirm Password</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.confirmPassword}
                      error={inputFormik.touched.confirmPassword && Boolean(inputFormik.errors.confirmPassword)}
                      helperText={inputFormik.touched.confirmPassword && inputFormik.errors.confirmPassword}
                      textBoxId="EditUserConfirmPasswordTextbox"
                      name="confirmPassword"
                      type="password"
                      placeholderText="Confirm Password"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {!isEdit && (
            <Grid className="contentItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Password</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    onChange={inputFormik.handleChange}
                    value={inputFormik.values.password}
                    error={inputFormik.touched.password && Boolean(inputFormik.errors.password)}
                    helperText={inputFormik.touched.password && inputFormik.errors.password}
                    textBoxId="AddUserPasswordTextbox"
                    name="password"
                    type="password"
                    placeholderText="Password"
                  ></TextBox>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isEdit && (
            <Grid className="contentItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Confirm Password</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    onChange={inputFormik.handleChange}
                    value={inputFormik.values.confirmPassword}
                    error={inputFormik.touched.confirmPassword && Boolean(inputFormik.errors.confirmPassword)}
                    helperText={inputFormik.touched.confirmPassword && inputFormik.errors.confirmPassword}
                    textBoxId="AddUserConfirmPasswordTextbox"
                    name="confirmPassword"
                    type="password"
                    placeholderText="Confirm Password"
                  ></TextBox>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* https://mafretail.atlassian.net/browse/MILE-3231 */}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Phone No.</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <Grid container spacing={1}>
                  <Grid className="phoneItemInput" item xs={4}>
                    <SelectBox
                      fullWidth
                      handleFormikChange={(e) => handleStateChange(e, "prefix")}
                      value={inputFormik.values.prefix}
                      error={inputFormik.touched.prefix && Boolean(inputFormik.errors.prefix)}
                      helperText={inputFormik.touched.prefix && inputFormik.errors.prefix}
                      reset={resetFormFlag}
                      id="phonePrefixesTypeSelectBox"
                      inputProps={{ name: "prefix", id: "phonePrefixesTypeSelectBox" }}
                      items={addUserDropdowns.phonePrefixesList}
                    />
                  </Grid>
                  <Grid className="phoneItemInput" item xs={8}>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.phone}
                      error={inputFormik.touched.phone && Boolean(inputFormik.errors.phone)}
                      helperText={inputFormik.touched.phone && inputFormik.errors.phone}
                      textBoxId="AddUserPhoneTextbox"
                      name="phone"
                      type="text"
                      placeholderText="Phone No."
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {inputFormik.values.userRole === AppConstants.USERS_CONSTANTS.DRIVER_ROLE ? (
            <Grid>
              <Grid className="contentItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Delivery Type</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <SelectBox
                      fullWidth
                      disabled={isEdit && inputFormik.values.deliveryType !== AppConstants.SELECT_NONE.value}
                      handleFormikChange={(e) => handleStateChange(e, "deliveryType")}
                      value={inputFormik.values.deliveryType}
                      error={inputFormik.touched.deliveryType && Boolean(inputFormik.errors.deliveryType)}
                      helperText={inputFormik.touched.deliveryType && inputFormik.errors.deliveryType}
                      reset={resetFormFlag}
                      id="addDeliveryTypeSelectBox"
                      inputProps={{ name: "deliveryType", id: "addDeliveryTypeSelectBox" }}
                      items={addUserDropdowns.deliveryTypeList}
                    ></SelectBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid className="contentItem" item>
                  <Grid className="itemContainer" container>
                    <Grid className="itemLabel" item>
                      <Typography>Vehicle Type</Typography>
                    </Grid>
                    <Grid className="itemInput" item>
                      <SelectBox
                        fullWidth
                        handleFormikChange={inputFormik.handleChange}
                        value={inputFormik.values.vehicleType}
                        error={inputFormik.touched.vehicleType && Boolean(inputFormik.errors.vehicleType)}
                        helperText={inputFormik.touched.vehicleType && inputFormik.errors.vehicleType}
                        reset={resetFormFlag}
                        id="AddVehicleSelectBox"
                        inputProps={{
                          name: "vehicleType",
                          id: "AddVehicleSelectBox",
                        }}
                        items={addUserDropdowns.vehicleTypeList}
                      ></SelectBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {inputFormik.values.userRole === AppConstants.USERS_CONSTANTS.LAST_MILE_DISPATCHER_ROLE || inputFormik.values.userRole === AppConstants.USERS_CONSTANTS.LAST_MILE_VIEWER_ROLE ? (
            <Grid className="contentItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Service Type</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <SelectBox
                    fullWidth
                    multiple
                    handleFormikChange={inputFormik.handleChange}
                    value={inputFormik.values.serviceTypes}
                    error={inputFormik.touched.serviceTypes && Boolean(inputFormik.errors.serviceTypes)}
                    helperText={inputFormik.touched.serviceTypes && inputFormik.errors.serviceTypes}
                    reset={resetFormFlag}
                    id="addServiceTypeSelectBox"
                    inputProps={{
                      name: "serviceTypes",
                      id: "addServiceTypeSelectBox",
                    }}
                    items={addUserDropdowns.serviceTypeList || []}
                  ></SelectBox>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {inputFormik.values.deliveryType && inputFormik.values.deliveryType !== AppConstants.SELECT_NONE.value && (
            <Grid className="contentItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Vendor</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <SelectBox
                    fullWidth
                    handleFormikChange={inputFormik.handleChange}
                    value={inputFormik.values.vendor}
                    error={inputFormik.touched.vendor && Boolean(inputFormik.errors.vendor)}
                    helperText={inputFormik.touched.vendor && inputFormik.errors.vendor}
                    reset={resetFormFlag}
                    id="AddVendorSelectBox"
                    inputProps={{
                      name: "vendor",
                      id: "AddVendorSelectBox",
                    }}
                    items={getVendorList()}
                  ></SelectBox>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Status</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.status}
                  error={inputFormik.touched.status && Boolean(inputFormik.errors.status)}
                  helperText={inputFormik.touched.status && inputFormik.errors.status}
                  reset={resetFormFlag}
                  id="AddUserStatusSelectBox"
                  inputProps={{
                    name: "status",
                    id: "AddUserStatusSelectBox",
                  }}
                  items={addUserDropdowns.statusList}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  const getDialogActions = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className="buttonsContainer" container>
          <Button
            className="userBtn secondary"
            variant="contained"
            onClick={() => {
              dialogActionHandler("cancel");
            }}
          >
            {AppConstants.BUTTONS.CANCEL}
          </Button>
          <Button className="userBtn primary" variant="contained" type="submit">
            {isEdit ? AppConstants.BUTTONS.SAVE : AppConstants.BUTTONS.ADD_USER}
          </Button>
        </Grid>
      </form>
    );
  };

  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={getDialogActions()}
        handleClose={handleDialogClose}
      ></CustomDialog>
        {userState.openSnackbar && (
        <CustomSnackbar
          open={userState.openSnackbar}
          handleClose={handleSnackbarClose}
          autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
          message={userState.snackbarMessage}
          type={userState.snackbarType}
        />
      )}
    </>
  );
};

export default UserAddOrEditDetails;
