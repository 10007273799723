import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

const searchableDropdownStyles = makeStyles((theme: Theme)  => ({
  fieldLabel: { marginBottom: "8px", fontSize: "12px" },
  " & .MuiFormLabel-root": {
    fontSize: "12px",
  },

  popper: {
  
    '& .MuiAutocomplete-paper': {
      margin: 0
    },
    '& .MuiAutocomplete-option': {
      minHeight: 'auto',
      padding: theme.spacing(0.75, 0),
      // '&:last-child': {
      //   paddingBottom: 0
      // },
      '&[aria-selected="true"]': {
        backgroundColor: COLORS.TRANSPARENT,
      },
      '&[data-focus="true"]': {
        backgroundColor: COLORS.TRANSPARENT,
      },
      '& .MuiCheckbox-root ': {
        paddingLeft: theme.spacing(3),
      },
      '& .optionName': {
        paddingLeft: theme.spacing(2.25),
        lineHeight: 1.4
      },
     
    },
  },

  searchableDropdown: {
    margin: 0,
    padding: 0,
    minWidth: "180px",
    "& .Mui-focused": {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: "500",
    },
    "& .MuiInputBase-input ": {
      marginLeft: "4px",
    },
    "& .MuiOutlinedInput-root ": {
      padding: 0,
      paddingLeft:4,
      width:350,
      '& .tagLimit':{
        marginTop:8
      },
      '& .MuiAutocomplete-tag ':{
          marginTop:12
      },
      '& .MuiChip-sizeSmall':{
        height:0
    }
    },
    '& .MuiAutocomplete-option': {
      minHeight: 'auto',
      padding: theme.spacing(0.75, 0),
      '&:last-child': {
        paddingBottom: 0
      },
      '&[aria-selected="true"]': {
        backgroundColor: COLORS.TRANSPARENT,
      },
      '&[data-focus="true"]': {
        backgroundColor: COLORS.TRANSPARENT,
      },
      '& .optionName': {
        paddingLeft: theme.spacing(2.25),
        lineHeight: 1.4
      }
    },
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      maxHeight: "100%",
      overflow: "hidden"
    }
      
  
   
  },
  paperStyles: {
    margin: 0,
    padding: 0,
    borderRadius: "inherit",
    fontSize: "12px",
  },
  downArrowStyle: {
    marginLeft: "6px",
    marginRight: "10px",
  },

  labelOnInputBoxFontStyle: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.54)",
    paddingLeft: "2px",
    "& .MuiFormLabel-root": {
      fontWeight: "500",
      fontSize: "12px !important",
      lineHeight: "8px",
      color: "rgba(0, 0, 0, 0.54)",
    },

    "& .MuiInputLabel-shrink": {
      fontSize: "12px !important",
    },
  },
  labelHeaderOnInputBoxFontStyle: {
    fontSize: "12px",
  },
  autocompleteInputDivStyle: {
    fontSize: "12px",
  },
  isDropdownOpen: {
    fontSize: "12px",
  },
  clearIndicatorStyle: {
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem !important",
    },
  },
  popupIndicatorOpenStyle: {
    fontSize: "1rem !important",
  },
  popupIndicatorStyle: {
    height: "28px",
    marginRight: "10px",
  },
  inputFocusedStyle: {
    paddingTop: "8px",
  },
}));

export default searchableDropdownStyles;
