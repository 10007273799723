import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const headStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: "100%",
            fontSize: "12px",
            lineHeight: "15px",
            overflow: "auto",
        },
        paper: {
            width: "100%",
            marginBottom: "24px",
        },
        container: {
            height: `calc(100vh - 310px)`,
            maxHeight: `calc(100vh - 310px)`,
            width: "100%",
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        tableHeaderRow: {
            backgroundColor: COLORS.SECONDARY_MAIN,
        },
        tableRowHeaderCell: {
            fontSize: "12px",
            lineHeight: "15px",
            height: "4em",
        },
        tableRowCell: {
            padding: 0,
            fontSize: "12px",
            lineHeight: "15px",
        },
        tableHeadRowCell: {
            padding: 0,
            paddingLeft: "10px",
            paddingRight: "6px",
            height: "30px",
            width: "70px",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#252525",
            borderTop: `1.27px solid ${COLORS.SECONDARY_DARK}`,
            borderBottom: `1.27px solid ${COLORS.SECONDARY_DARK}`,
            borderRight: `1.27px solid ${COLORS.SECONDARY_DARK}`,
            "&:last-child": {
                padding: 0,
                paddingLeft: "10px",
                paddingRight: "6px",
                borderRight: "none",
            },
        },
        tableDataRowCell: {
            padding: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            height: "55px",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "17px",
            color: "#4D4D4D",
            borderTop: `1.27px solid ${COLORS.MADISON_LIGHT}`,
            borderBottom: `1.27px solid ${COLORS.MADISON_LIGHT}`,
            "&:last-child": {
                padding: 0,
                paddingLeft: "15px",
                paddingRight: "10px",
                borderRight: "none",
            },
        },
        gridRow: {
            background: "#fff",
            "&$selected": {
                background: COLORS.MADISON_LIGHT,
                backgroundColor: COLORS.MADISON_LIGHT,
            },
            "&$selected, &$selected:hover": {
                background: COLORS.MADISON_LIGHT,
                backgroundColor: COLORS.MADISON_LIGHT,
            },
        },
        gridRowSelected: {
            backgroundColor: `${COLORS.MADISON_LIGHT} !important`,
        },
        infoIconStyle: {
            height: "20px",
            width: "14px",
            marginLeft: "12px",
            paddingTop: "6px"
        }
    }));