import React from "react";
import {  useStyles } from './camsWidgets.style';
import { AppState } from "../config/redux/reducers";
import { useSelector } from "react-redux";


const ExceptionsDeliveries = () => {
    const classes = useStyles();
     const {  hubCode } = useSelector(
    (state: AppState) => state.common
  );
  const hub= hubCode.indexOf('all') > -1 ? 'all' : hubCode

  return (
    <>
      {
          <div className={classes.CF_iframe}>
             <iframe src={"/cams/exceptions-deliveries?view=widget"}  frameBorder="0" allowFullScreen></iframe>
          </div>
      }
    </>
  );
};
export  default ExceptionsDeliveries;