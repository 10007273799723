import { Checkbox, TableCell, TableHead, TableHeadProps, TableRow, TableSortLabel } from '@material-ui/core';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { ColDef, SortDirection, RowData } from '.';
import Filter from '../Filter';
import { useStyles } from './DataTableHeader.styles';
export interface DataTableHeaderProps extends TableHeadProps {
  checkboxSelection: boolean;
  order: SortDirection;
  orderBy: keyof RowData;
  columns: ColDef[];
  rowCount: number;
  selected: number;
  headerHeight: number;
  defaultFilterValues?: any;
  resetAllFilterFlag: boolean;
  activeFilters: any;
  onSort: (event: React.MouseEvent<any>, property: string) => void;
  onUpdateFilter: ((filter: any) => void) | undefined;
  onSelectAll: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

const DataTableHeader = (props: DataTableHeaderProps) => {
  const classes = useStyles();
  const { checkboxSelection, order, orderBy, selected, rowCount, columns, headerHeight, defaultFilterValues, resetAllFilterFlag, activeFilters, onSelectAll, onSort, onUpdateFilter, ...rest } = props;

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<any>
  ) => {
    onSort(event, property);
  };

  const updateFilter = useCallback(
    (filter: any) => {
      onUpdateFilter && onUpdateFilter(filter);
    },
    [onUpdateFilter]
  );

  const getActiveFilterClass = useCallback(
    (col: ColDef) => {
      let className = '';
      if (col.filter) {
        const filterValue = activeFilters[col.filterObj.fieldName] && activeFilters[col.filterObj.fieldName].value ? activeFilters[col.filterObj.fieldName].value : activeFilters[col.filterObj.fieldName];
        if (_.isArray(filterValue)) {
          className = filterValue.length ? 'filterActive' : '';
        } else if (filterValue) {
          className = 'filterActive';
        }
      }
      return className;
    },
    [activeFilters],
  )

  return (
    <TableHead className={classes.root} {...rest}>
      <TableRow>
        {
          checkboxSelection &&
          <TableCell style={{ width: 20, height: headerHeight, minHeight: headerHeight, maxHeight: headerHeight }}>
            <Checkbox
              indeterminate={selected > 0 && selected < rowCount}
              checked={rowCount > 0 && selected === rowCount}
              size="small"
              color="primary"
              onChange={onSelectAll}
            />
          </TableCell>
        }
        {columns.map((col: ColDef, index: number) => (
          !col.hide &&
          <TableCell
            key={col.field}
            className={getActiveFilterClass(col)}
            align={col.type === 'number' ? 'right' : 'left'}
            sortDirection={orderBy === col.field && order !== 'default' ? order : false}
            style={{ height: headerHeight, minHeight: headerHeight, maxHeight: headerHeight, width: col.width, minWidth: col.width, maxWidth: col.width }}
          >
            {/* Commenting for now, To be developed properly later */}
            {/* <TableSortLabel
              active={orderBy === col.field && order !== 'default'}
              direction={orderBy === col.field && order !== 'default' ? order : 'asc'}
              hideSortIcon={orderBy === col.field && order === 'default'}
              onClick={createSortHandler(col.field)}
            > */}
            {
              col.renderHeader
                ? <col.renderHeader field={col.field} colDef={col} colIndex={index}></col.renderHeader>
                : col.headerName
            }
            {/* </TableSortLabel> */}
            {
              col.filter &&
              <Filter filter={col.filterObj} order={order} orderBy={orderBy} updateFilter={updateFilter} defaultValues={defaultFilterValues} activeFilterValues={activeFilters} resetAllFilter={resetAllFilterFlag} activeFilter={getActiveFilterClass(col)}/>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default DataTableHeader;