import React, { useCallback, forwardRef, ReactElement, Ref, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import { useStyles } from './CNCConsignmentEdit.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Button } from '@material-ui/core';
import AppConstants from "../constants";
import SelectBox from '../common/SelectBox';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../config/redux/reducers';
import TextBox from '../common/TextBox';
import { createCNCReplanningPayload } from '../mocks/clickAndCollect/response.transforms';
import { fetchCNCStatuses, fetchDeliveryReasons, cncReplanning } from './redux/clickAndCollectSlice';
import warning from "../assets/icons/warning.svg";

interface ConsignmentEditProps {
  open: boolean;
  consignment: any;
  closePopup: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConsignmentEdit = (props: ConsignmentEditProps) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, consignment, closePopup } = props;

  const { cncStatuses, deliveryReasons, reasonsDropdownList, cncReplanningSuccess, cncDataAfterReplanning } = useSelector(
    (state: AppState) => state.clickAndCollect
  )
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const [selectedReason, setSelectedReason] = useState(AppConstants.SELECT_NONE.value);
  const [selectedStatus, setSelectedStatus] = useState(AppConstants.SELECT_NONE.value);
  const [showReason, setShowReason] = useState(false);
  const [commentsValue, setCommentsValue] = useState('');
  const [errors, setErrors] = useState({
    reason: "",
  });

  let metadata: any = {};

  if (consignment) {
    metadata = consignment.metadata;
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
  }

  const validateForm = useCallback(() => {
    let newErrors = {};
    if (selectedReason === AppConstants.SELECT_NONE.value) {
      newErrors = { ...newErrors, reason: "Please select reason" };
    }
    setErrors({ ...errors, ...newErrors });

    return Object.keys(newErrors).length;
  }, [errors, selectedReason]);

  const handleStatusChange = useCallback(
    (value: any) => {
      setSelectedStatus(value);
      if (value === "COLLECTION_FAILED") {
        setShowReason(true);
      } else {
        setShowReason(false);
      }
    }, []
  );

  const handleReasonChange = useCallback(
    (value: any) => {
      setSelectedReason(value);
      if (value) {
        setErrors({ ...errors, reason: "" });
      }
    },
    [errors],
  );

  const handleCommentsTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCommentsValue(event.target.value);
    },
    []
  );

  const handleCNCReplanning = useCallback(
    () => {
      if (selectedStatus === "COLLECTION_FAILED") {
        if (validateForm() === 0) {
          const payload = createCNCReplanningPayload({
            id: consignment.consignmentCode,
            reason: selectedReason,
            commentsValue: commentsValue,
            cncStatus: selectedStatus
          });
          if (selectedReason !== AppConstants.SELECT_NONE.value) {
            const deliveryReason = deliveryReasons.filter((reason: any) => reason.reasonCode === selectedReason);
            if (deliveryReason.length) {
              payload.reason = deliveryReason[0]
            }
          }
          dispatch(
            cncReplanning(
              {
                params: payload
              }
            )
          )
        }
      } else {
        const payload = createCNCReplanningPayload({
          id: consignment.consignmentCode,
          commentsValue: commentsValue,
          cncStatus: selectedStatus
        });
        dispatch(
          cncReplanning(
            {
              params: payload
            }
          )
        )
      }
    },
    [consignment, selectedStatus, commentsValue, deliveryReasons, selectedReason, validateForm, dispatch],
  )

  const dialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'update') {
        handleCNCReplanning();
      } else {
        closePopup();
      }
    },
    [closePopup, handleCNCReplanning],
  );

  const fetchAllDeliveryReasons = useCallback(
    (countryCode: any) => {
      dispatch(
        fetchDeliveryReasons({
          params: countryCode
        })
      );
    }, [dispatch]
  );


  const handleCNCStatuses = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchCNCStatuses({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (cncReplanningSuccess) {
      closePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cncReplanningSuccess])

  useEffect(() => {
    if (consignment) {
      setSelectedStatus(AppConstants.SELECT_NONE.value);
      setShowReason(false);
      setCommentsValue("");
      setSelectedReason(AppConstants.SELECT_NONE.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignment]);


  useEffect(() => {
    if (consignment != null) {
      fetchAllDeliveryReasons(countryCode);
      handleCNCStatuses(consignment);
    }
  }, [consignment, countryCode, fetchAllDeliveryReasons, handleCNCStatuses]);


  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label"> EDIT CONSIGNMENT : </span>
                    <span className="content"> {consignment.consignmentCode} </span>
                  </Typography>
                </Grid>
                {
                  cncDataAfterReplanning && cncDataAfterReplanning.metadata ?
                    (
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', cncDataAfterReplanning && cncDataAfterReplanning.metadata && cncDataAfterReplanning.metadata.deliveryStatus)].join(" ")}>{cncDataAfterReplanning && cncDataAfterReplanning.metadata && cncDataAfterReplanning.metadata.deliveryStatus ? cncDataAfterReplanning.metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    ) : (
                      metadata && metadata.deliveryStatus &&
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', metadata && metadata.deliveryStatus)].join(" ")}>{metadata && metadata.deliveryStatus ? metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className="selectedContentArea" item>
          <Grid className="contentAreaContainer cancel" container>
            <Grid className="contentAreaItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Mark Consignment As</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <SelectBox
                    fullWidth
                    value={selectedStatus}
                    id="cncStatus"
                    inputProps={{
                      name: "status",
                      id: "cncStatus"
                    }}
                    required
                    handleChange={handleStatusChange}
                    items={cncStatuses || []}
                  ></SelectBox>
                </Grid>
              </Grid>
            </Grid>
            {showReason ? (
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Reason</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <SelectBox
                      fullWidth
                      value={selectedReason}
                      id="EditConsignmentReason"
                      inputProps={{
                        name: "reason",
                        id: "EditConsignmentReason"
                      }}
                      required
                      handleChange={handleReasonChange}
                      items={reasonsDropdownList || []}
                      error={!!errors.reason}
                      helperText={errors.reason}
                    ></SelectBox>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid className="contentAreaItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Comments</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <TextBox
                    fullWidth
                    multiline
                    variant="outlined"
                    rows={4}
                    onChange={handleCommentsTextChange}
                    value={commentsValue}
                    textBoxId="EditConsignmentComments"
                    placeholderText="Enter a comment"
                  ></TextBox>
                </Grid>
              </Grid>
            </Grid>
            {showReason ? (
              <Grid className="contentAreaItem" item style={{ height: "55px", background: "#E79832", borderRadius: "8px" }}>
                <Grid style={{ display: "flex", margin: "16px" }}>
                  <img src={warning} alt="warning" />
                  <Typography style={{ fontSize: "14px", marginLeft: "10px", lineHeight: "17px", fontWeight: 500, color: "#FFFFFF", display: "flex", alignItems: "center" }}>This consignment will no longer be collected.</Typography>
                </Grid>
              </Grid>) : null}
          </Grid>

        </Grid>
      </Grid >
    )
  }

  const getDialogActions = () => {
    return (
      <Grid className={classes.buttons} container>
        <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="userBtn primary" variant="contained" onClick={() => { dialogActionHandler('update') }}>{AppConstants.BUTTONS.SAVE}</Button>
      </Grid>
    );
  };

  const handleDialogClose = useCallback(
    () => {
      closePopup();
    },
    [closePopup],
  );

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      content={getDetailsContent()}
      actions={getDialogActions()}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default ConsignmentEdit;