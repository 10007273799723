import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { fixDecimals, isUndefined } from "../../../utils/helpers.utils";
import {
  getItemNameForDelveOrderList,
  getItemReportedQtyForDelveOrderList,
  getItemSQtyForDelveOrderList,
  getItemShippedQtyForDelveOrderList,
  getItemStatusForDelveOrderList,
} from "../../../mocks/delve/response.transforms";
import { TAB_VALUES_CONSTANT } from "../../../constants/DelveDashboardConstant";

interface interfaceDelveOrderDetailsItemsContent {
  classes: any;
  items: any;
  metadata: any;
  showOriginalName: any;
  handleOriginalName: any;
  tabValue: any;
}

const DelveOrderDetailsItemsContent = (props: interfaceDelveOrderDetailsItemsContent) => {
  const { items, classes, metadata, showOriginalName, handleOriginalName, tabValue } = props;

  const getReasonDescription = (item: any) => {
    if (!isUndefined(item)) {
      if (tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
        if (item.qcReportsReturn !== undefined && item.qcReportsReturn.reasonDescription !== undefined && item.qcReportsReturn.reasonDescription !== undefined) {
          return getRejectionReason(item.qcReportsReturn.reasonDescription);
        } else if (item.reportedReasonDescription) {
          return getRejectionReason(item.reportedReasonDescription);
        }
      } else if (
        item.associatedCrates !== undefined &&
        Array.isArray(item.associatedCrates) &&
        item.associatedCrates.length > 0 &&
        item.associatedCrates[0] !== undefined &&
        item.associatedCrates[0].qcReports !== undefined &&
        item.associatedCrates[0].qcReports.reasonDescription !== undefined
      ) {
        return getRejectionReason(item.associatedCrates[0].qcReports.reasonDescription);
      } else if (
        !isUndefined(item.substitutedProducts) &&
        Array.isArray(item.substitutedProducts) &&
        item.substitutedProducts.length > 0 &&
        !isUndefined(item.substitutedProducts[0].associatedCrates) &&
        Array.isArray(item.substitutedProducts[0].associatedCrates) &&
        item.substitutedProducts[0].associatedCrates.length > 0 &&
        !isUndefined(item.substitutedProducts[0].associatedCrates[0]) &&
        !isUndefined(item.substitutedProducts[0].associatedCrates[0].qcReports) &&
        !isUndefined(item.substitutedProducts[0].associatedCrates[0].qcReports.reasonDescription)
      ) {
        return getRejectionReason(item.substitutedProducts[0].associatedCrates[0].qcReports.reasonDescription);
      } else if (
        !isUndefined(item.qcStatusDelivery) &&
        !isUndefined(item.qcStatusDelivery.reportedQty) &&
        Number(item.qcStatusDelivery.reportedQty) > 0 &&
        !isUndefined(item.qcReportsDelivery) &&
        !isUndefined(item.qcReportsDelivery.reasonDescription)
      ) {
        return getRejectionReason(item.qcReportsDelivery.reasonDescription);
      } else if (!isUndefined(item.reason) && !isUndefined(item.reason.reasonCode) && !isUndefined(item.reason.reasonDescription)) {
        return getRejectionReason(item.reason.reasonDescription);
      } else if (!isUndefined(item.reportedReasonDescription)) {
        return getRejectionReason(item.reportedReasonDescription);
      }
    }
    return <></>;
  };

  const getItemPrice = (item: any) => {
    if (!isUndefined(item.substitutedProducts) && Array.isArray(item.substitutedProducts) && item.substitutedProducts.length > 0 && !isUndefined(item.substitutedProducts[0])) {
      if (!isUndefined(item.substitutedProducts[0].effectiveUnitPrice) && Number(item.substitutedProducts[0].effectiveUnitPrice) > 0) {
        return fixDecimals(2, item.substitutedProducts[0].effectiveUnitPrice.toString());
      } else if (!isUndefined(item.substitutedProducts[0].unitPrice)) {
        return fixDecimals(2, item.substitutedProducts[0].unitPrice.toString());
      }
    } else if (!isUndefined(item.effectiveUnitPrice) && Number(item.effectiveUnitPrice) > 0) {
      return fixDecimals(2, item.effectiveUnitPrice.toString());
    } else if (!isUndefined(item.unitPrice)) {
      return fixDecimals(2, item.unitPrice.toString());
    }
    return "";
  };

  const getRejectionReason = (reasonDescription: any) => {
    return (
      <Grid item xs={12} className={classes.itemSummaryItemDetailValueDivStyle}>
        <Typography className={classes.itemSummarySubHeadingBoldSpanStyle}>
          Issue Found - <span className={classes.itemSummarySubHeadingSpanStyle}>{reasonDescription || "Issue Found"}</span>
        </Typography>
      </Grid>
    );
  };

  return items && items.length ? (
    items.map((item: any, index: number) => (
      <Grid item xs={12} key={`${item.name.en} - ${index}`} className={classes.itemSummaryHeaderGridItemStyle}>
        <Grid container className={classes.itemSummaryHeaderContainerDivStyle}>
          <Grid item xs={4} className={classes.itemSummaryHeaderItemDivStyle}>
            <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{getItemNameForDelveOrderList(item)}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
            <Typography className={classes.itemSummaryHeadingResultSpanStyle}> {`${getItemSQtyForDelveOrderList(item, tabValue)} ${item.uom && item.uom === "Grams" ? item.uom : ""}`}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
            <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{`${getItemShippedQtyForDelveOrderList(item, tabValue)} ${item.uom && item.uom === "Grams" ? item.uom : ""}`}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
            <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{`${getItemReportedQtyForDelveOrderList(item, tabValue)} `}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
            <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{`${getItemPrice(item)} ${metadata && metadata.currency}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.itemSummaryItemDetailValueDivStyle}>
            <Typography className={item.qcStatusDelivery && item.qcStatusDelivery.verified ? classes.itemSummaryVerifiedCrate : classes.itemSummaryUnverifiedCrate}>
              {`${getItemStatusForDelveOrderList(item, tabValue)} `}
            </Typography>
          </Grid>
          {Number(getItemReportedQtyForDelveOrderList(item, tabValue)) > 0 && getReasonDescription(item)}
        </Grid>

        {!isUndefined(item.substitutedProducts) && Array.isArray(item.substitutedProducts) && item.substitutedProducts.length > 0 ? (
          <>
            <Grid item xs={12} className={classes.itemSummaryItemDetailValueDivStyle}>
              <Typography className={classes.itemSummarySubHeadingSpanStyle} style={{ margin: "4px 0px 0px 14px" }}>
                This item was substituted{" "}
                <span className={classes.itemSummarySubHeadingBoldUnderlineSpanStyle} onClick={() => handleOriginalName()}>
                  {"View Original"}
                </span>
              </Typography>
            </Grid>
            {showOriginalName && item.substitutedProducts && (
              <Grid container className={classes.itemSummarySunstitutedContainerDivStyle}>
                <Grid item xs={4} className={classes.itemSummaryHeaderItemDivStyle}>
                  <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{item.name.en.toLowerCase()}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{`${item.quantity || ""} ${item.uom && item.uom === "Grams" ? item.uom : ""}`}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{`${item.shippedQty || ""} ${item.uom && item.uom === "Grams" ? item.uom : ""}`}</Typography>
                </Grid>

                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{item.rejectedQty || "-"}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.itemSummaryHeaderItemValueDivStyle}>
                  <Typography className={classes.itemSummaryHeadingResultSpanStyle}>{`${fixDecimals(2, item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
    ))
  ) : (
    <Typography>No Items to display</Typography>
  );
};

export default React.memo(DelveOrderDetailsItemsContent);
