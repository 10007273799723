import { createStyles, makeStyles, Theme, withStyles, StepConnector } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
            height: "100%",
            backgroundColor: COLORS.ROUTE_CONTAINER_BACKGROUND,
            overflow: "scroll"
        },
        main: {
            display: "flex"
        },
        title: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "29px",
            color: COLORS.MINE_SHAFT
        },
        scrollableContainer: {
            height: "calc(100% - 117px)",
            overflow: "auto"
        },
        select: {
            borderRadius: 8,
            background: COLORS.WHITE,
            minWidth: "160px",
            maxWidth: "251px"
        },
        icon: {
            "& .MuiOutlinedInput-input": {
                height: 40,
                minWidth: "202px"
            },
        },
        container: {
            flexDirection: "column",
            height: "100%",
            '& .planningItem': {
                width: '100%',
                '& .MuiTab-root': {
                    marginRight: 44
                },
                '&.heading': {
                    paddingBottom: theme.spacing(2.5),
                    '& p': {
                        fontSize: 24,
                        color: COLORS.BLACK,
                        fontWeight: 600
                    }
                },
            }
        },
        tilesView: {
            flexGrow: 1
        },
        searchBox: {
            background: COLORS.WHITE,
        },
        searchImage: {
            marginRight: theme.spacing(1),
            cursor: "pointer"
        },
        newStatusIcon: {
            color: COLORS.PICTON_BLUE,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        readyToDispatchStatusIcon: {
            color: COLORS.OLD_GOLD,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        legendsContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3)
        },
        legend: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: "16px",
            color: COLORS.TEXT_PRIMARY,
            marginRight: theme.spacing(2)
        },
        createdStatusIcon: {
            color: COLORS.LIGHT_PERSIAN_GREEN,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        assignedStatusIcon: {
            color: COLORS.TAG_YEllOW,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        preparingStatusIcon: {
            color: COLORS.INFO_GREEN,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        outForDeliveryStatusIcon: {
            color: COLORS.GOLDEN_BELL,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        onHoldStatusIcon: {
            color: COLORS.SCAMPI,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        pendingDeliveryStatusIcon: {
            color: COLORS.PRIMARY_MAIN,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        deliveredStatusIcon: {
            color: COLORS.SEA_GREEN2,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        partiallyDeliveredStatusIcon: {
            color: COLORS.LUCKY,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        completedStatusIcon: {
            color: COLORS.PERSIAN_GREEN_DARK,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        returnedStatusIcon: {
            color: COLORS.JUNGLE_GREEN,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        preparedStatusIcon: {
            color: COLORS.SEA_GREEN,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        cancelledStatusIcon: {
            color: COLORS.DUSTY_GRAY,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        failedStatusIcon: {
            color: COLORS.DARK_RED,
            width: 10,
            height: 10,
            marginRight: theme.spacing(0.75),
            marginTop: "2px"
        },
        statusContainer: {
            border: `1px solid ${COLORS.MYSTIC_GREY}`,
            //background: COLORS.GREY_WHITE,
            marginTop: theme.spacing(2),
            backgroundColor: COLORS.WHITE,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: theme.spacing(1),
            height: "calc(100vh - 172px)",
        },
        statusTitle: {
            marginTop: theme.spacing(3),
            background: COLORS.SECONDARY_MID_DARK
        },
        statusText: {
            color: COLORS.BLACK,
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "20px",
            padding: "8px 24px"
        },
        routeDetailsContainer: {
            background: COLORS.WHITE,
            flexWrap: "nowrap",
            width: "auto",
            minWidth: "100%",
            minHeight: "calc(100vh - 290px)"
        },
        mainContainer: {
            flexWrap: "nowrap",
            display: "flex"
        },
        horizontalLine: {
            borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
            width: "100%",
            padding: "8px 0px 8px 0px"
        },
        routeDetailsSection: {
            width: 396,
            minWidth: 396,
            borderRight: `1px solid ${COLORS.SECONDARY_DARK}`,
            position: "sticky",
            left: 0,
            zIndex: 100,
            backgroundColor: COLORS.WHITE
        },
        routesSection: {
            padding: "12px 12px 0px 24px"
        },
        routeIdText: {
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "17px",
            color: COLORS.TEXT_PRIMARY,
            display: "flex",
            alignItems: "center"
        },
        draftRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.WHITE,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        createdRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.LIGHT_PERSIAN_GREEN,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        assignedRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.TAG_YEllOW,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        preparingRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.SCAMPI,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        outForDeliveryRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.AMBER,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        completedRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.PERSIAN_GREEN_DARK,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        publishedRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.SEA_GREEN,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        preparedRouteStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.JUNGLE_GREEN,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        cancelledRouteStatus: {
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.ERROR_RED,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        tooltipContainer: {
            padding: "24px 24px 4px 24px"
        },
        tooltipSection: {
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
        },
        addressInfoTooltip: {
            color: COLORS.TEXT_PRIMARY,
            fontSize: 12,
            lineHeight: "15px",
            fontWeight: "normal"
        },
        subSectionContainer: {
            paddingTop: theme.spacing(1.5),
            display: "flex",
            flexWrap: "nowrap"
        },
        subSections: {
            display: "flex",
            paddingRight: theme.spacing(1.5)
        },
        icons: {
            width: 16,
            height: 16
        },
        iconsData: {
            display: "flex",
            alignItems: "center",
            padding: "0px 12px",
            fontSize: 10,
            lineHeight: "15px",
            color: COLORS.TEXT_PRIMARY,
            fontWeight: 600
        },
        slotSection: {
            width: 396,
            minWidth: 396,
            padding: "16px",
            display: "flex",
            alignContent: "center",
            borderRight: `1px solid ${COLORS.SECONDARY_DARK}`,
            borderTop: `1px solid ${COLORS.SECONDARY_DARK}`,
            position: "sticky",
            left: 0,
            zIndex: 100,
            backgroundColor: COLORS.WHITE
        },
        slotText: {
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            display: "flex",
            alignItems: "flex-end",
            marginLeft: "60px"
        },
        slotsContainer: {
            flexWrap: "nowrap",
            display: "flex",
            width: "auto",
            minWidth: "100%",
            position: "sticky",
            bottom: 0,
            zIndex: 100,
            backgroundColor: COLORS.WHITE,
        },
        swipeIconStyle: {
            marginBottom: "auto",
            marginLeft: theme.spacing(4),
        },
        deliveredStatus: {
            color: COLORS.PERSIAN_GREEN,
            width: 14,
            height: 14,
            border: `1px solid ${COLORS.PERSIAN_GREEN}`,
            borderRadius: "10px",
        },
        undeliveredStatus: {
            color: COLORS.GOLDEN_BELL,
            width: "14px",
            height: "14px",
            border: `1px solid ${COLORS.GOLDEN_BELL}`,
            borderRadius: "10px",
        },
        consignmentDetailsSection: {
            flexGrow: 1,
            display: "flex",
        },
        consignmentsSection: {
            borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
            borderRight: `1px solid ${COLORS.SECONDARY_DARK}`,
            flexWrap: "nowrap",
            width: 404,
            backgroundColor: COLORS.WHITE
        },
        slotWiseText: {
            color: COLORS.TEXT_PRIMARY,
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            overflow: "auto",
            flexWrap: "nowrap"
        },
        slotWiseItem: {
            borderRight: `1px solid ${COLORS.SECONDARY_DARK}`,
            justifyContent: "center",
            width: 404,
            borderTop: `1px solid ${COLORS.SECONDARY_DARK}`,
        },
        generatedSlotsContainer: {
            flexGrow: 1,
            display: "flex",
            flexWrap: "nowrap"
        },
        tooltip: {
            backgroundColor: COLORS.WHITE,
            maxWidth: 360
        },
        popper: {
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            border: `1px solid ${COLORS.SECONDARY_DARK}`,
            borderRadius: "8px"
        },
        arrow: {
            color: COLORS.WHITE,
            border: `1px solid ${COLORS.SECONDARY_DARK}`,
        },
        newConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.PICTON_BLUE,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        readyConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.OLD_GOLD,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        preparingConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.VISTA_BLUE,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        preparedConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.SEA_GREEN,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        outForDeliveryConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.GOLDEN_BELL,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        onHoldConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.SCAMPI,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        pendingConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.PRIMARY_MAIN,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        deliveredConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.SEA_GREEN2,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        partialConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.LUCKY,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        cancelledConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.DUSTY_GRAY,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        returnedConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.JUNGLE_GREEN,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        failedConsignmentStatus: {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "15px",
            color: COLORS.WHITE,
            background: COLORS.DARK_RED,
            borderRadius: "4px",
            padding: "4px 8px",
            marginLeft: theme.spacing(1)
        },
        viewMoreButton: {
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
        },
        timelineStepper: {
            padding: 4,
            alignItems: "center",
            backgroundColor: 'transparent',
            '& .timelineStep': {
                paddingLeft: 10,
                paddingRight: 10,
                '&.first': {
                    paddingLeft: 4
                }
            },
            '& .MuiStepLabel-root': {
                flexDirection: 'column-reverse',
                '& .MuiStepLabel-labelContainer': {
                    '& .MuiStepLabel-alternativeLabel': {
                        whiteSpace: "nowrap",
                        width: 14,
                        height: 14,
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }
                },
                '&.MuiStepLabel-alternativeLabel': {
                    flexDirection: 'column-reverse'
                }
            },
            '& .MuiStepLabel-iconContainer': {
                paddingRight: 0
            },
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                marginTop: 0
            },
            '& .MuiStepLabel-label': {
                fontSize: 10,
                color: COLORS.TEXT_PRIMARY,
                paddingBottom: 6,
                textAlign: "center"
            }
        },
        errorContainer: {
            padding: "24px",
            minHeight: "calc(100vh - 64px)"
        },
        error: {
            fontSize: "14px"
        },
        routesCount: {
            display: "flex",
            justifyContent: "center",
        },
        countSection: {
            margin: 'auto',
            width: 100,
            height: 40,
            borderRadius: 100,
            backgroundColor: COLORS.FOOTER_COUNT_BACKGROUND,
            color: COLORS.WHITE,
            left: "calc(50 % - 88px)",
            bottom: "25px",
            position: "fixed",
            zIndex: 100,
            '& .countContainer': {
                height: "100%",
                '& .countItem': {
                    margin: "auto",
                    '& p': {
                        fontSize: 16,
                        fontWeight: 400
                    },
                    '& .current': {
                        fontWeight: 500
                    },
                    '& .separator': {
                        padding: "0 2px"
                    }
                }
            }
        },
    }),
);

export const StepperTimelineConnector = withStyles({
    alternativeLabel: {
        top: 23,
        left: 'calc(-50% + 9px)',
        right: 'calc(50% + 9px)'
    },
    active: {
        '& $line': {
            borderColor: COLORS.SECONDARY_DARK,
        },
    },
    line: {
        borderColor: COLORS.SECONDARY_DARK,
        borderTopWidth: 2,
        borderRadius: 100,
    },
})(StepConnector);