/* global google */
import React, { useState } from "react";
import MapDirectionsRenderer from "./MapDirectionRenderer";

import { withGoogleMap, GoogleMap, withScriptjs, Marker, InfoWindow, Polyline } from "react-google-maps";
import { CustomMarker, CustomLocation } from ".";
import { MAPOPTIONSTYLES_INTERFACE } from "../../RoutePlanning/AllRoutes/utils/AllRouteConstant";
import { COLORS } from "../../config/theme/baseTheme";

interface CustomGoogleMapProps {
  markers: CustomMarker[];
  showDirections?: boolean;
  roundTrip?: boolean;
  optimizeRoute?: boolean;
  updateMap?: boolean;
  defaultCenter: CustomLocation;
  defaultZoom: number;
  showInfoWindow?: boolean;
  getRouteDetailsForInfoWindow?: any;
  mapOptionStyles?: MAPOPTIONSTYLES_INTERFACE[];
  mapPolylineMarker?: CustomLocation[];
  polylineColor?: string;
}

const CustomGoogleMap = withScriptjs(
  withGoogleMap((props: CustomGoogleMapProps) => {
    const {
      markers,
      defaultCenter,
      defaultZoom,
      showDirections,
      roundTrip,
      optimizeRoute,
      updateMap,
      showInfoWindow,
      getRouteDetailsForInfoWindow,
      mapOptionStyles,
      mapPolylineMarker,
      polylineColor,
    } = props;
    const [googleMapState, setGoogleMapStated] = useState({ openInfoWindowMarkerId: "" });

    const handleToggleOpen = (markerId: string) => {
      setGoogleMapStated({ ...googleMapState, openInfoWindowMarkerId: markerId });
    };

    return (
      <GoogleMap defaultCenter={defaultCenter} defaultZoom={defaultZoom} zoom={defaultZoom} center={defaultCenter} options={{ styles: mapOptionStyles ? mapOptionStyles : [] }}>
        {markers &&
          markers.length &&
          markers.map((marker: CustomMarker) => (
            <Marker
              key={marker.name}
              title={marker.name}
              icon={marker.icon}
              label={marker.label}
              position={marker.location}
              onMouseOver={() => handleToggleOpen(marker.name)}
              onMouseOut={() => setGoogleMapStated({ ...googleMapState, openInfoWindowMarkerId: "" })}
            >
              {showInfoWindow && getRouteDetailsForInfoWindow && googleMapState.openInfoWindowMarkerId === marker.name && (
                <InfoWindow key={`infowindow-${marker.name}`} defaultOptions={{ disableAutoPan: true }}>
                  {getRouteDetailsForInfoWindow && getRouteDetailsForInfoWindow(marker)}
                </InfoWindow>
              )}
            </Marker>
          ))}
        {mapPolylineMarker && mapPolylineMarker.length > 0 && (
          <Polyline path={mapPolylineMarker} options={{ strokeColor: polylineColor || COLORS.ALIZARIN_CRIMSON, strokeOpacity: 1, strokeWeight: 2, geodesic: true }} />
        )}
        {showDirections && markers.length > 1 && (
          <MapDirectionsRenderer places={[...markers]} roundTrip={roundTrip} optimizeRoute={optimizeRoute} updateMap={updateMap} travelMode={google.maps.TravelMode.DRIVING} />
        )}
      </GoogleMap>
    );
  })
);

export default CustomGoogleMap;
