import React from 'react';
import { useStyles } from './TimePicker.styles';

const TimeRangePicker = (props:any) => {
const {handleChange, label, exception, defaultValue, value} = props
const inputVal = value ? value : defaultValue ? defaultValue == 'start' ? "00:00:00" : "23:59:00" : ""
const classes = useStyles();
  return <>
  <label className={classes.label}>{label}</label>
  <input type="time" id="timepicker" className={`${classes.timeSelector} ${exception && 'timeException'} `} onChange={(t)=>handleChange(t)} value={inputVal}/>
  </>;
}

export default TimeRangePicker;