import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 18,
          fontWeight: 500,
          '& .content': {
            fontWeight: 600
          }
        },
      }
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .MuiOutlinedInput-root': {
        height: 40
      },
      '& .contentItem': {
        paddingBottom: theme.spacing(3),
        '& .itemContainer': {
          flexDirection: "column",
          '& .itemLabel': {
            paddingBottom: theme.spacing(1),
            alignSelf: "flex-start",
            '& p': {
              fontSize: 14,
              fontWeight: 400
            }
          },
          '& .itemInput': {
            '& .MuiOutlinedInput-input': {
              fontSize: 14,
              fontWeight: 400
            }
          }
        },
      },
    },
    buttons: {
      width: "100%",
      '& .userBtn': {
        '&.secondary': {
          marginRight: theme.spacing(2)
        },
        flexGrow: 1
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 580
    }
  }),
);