import AppConstants from "constants/AppConstants";
import moment from "moment";
import { isNull, isUndefined, isInputAnArray, isNonEmptyArray, checkIfInputIsValidObjectWithKeys, getPercentageValue } from "../../../utils/helpers.utils";
import { DELVE_DASHBOARD_CONST } from "../../../constants/DelveDashboardConstant";
import { isDateValid, isEndDateBeforeStartDate } from "utils/dateUtils";

export const fetchDashboardRecordBasedOnFilterUtils = (dashboardStateObjCopy: any, countryCode: any) => {
  let requestDashboardStateObj = {} as any;
  if (!isUndefined(dashboardStateObjCopy)) {
    requestDashboardStateObj.countryIsoCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;

    if (!isUndefined(dashboardStateObjCopy.hubs)) {
      requestDashboardStateObj.hubs = dashboardStateObjCopy.hubs;
    }

    if (!isUndefined(dashboardStateObjCopy.dateSlot) && !isUndefined(dashboardStateObjCopy.dateSlot.startDate) && !isUndefined(dashboardStateObjCopy.dateSlot.endDate)) {
      requestDashboardStateObj.dateSlot = {
        startDate: moment(dashboardStateObjCopy.dateSlot.startDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_API),
        endDate: moment(dashboardStateObjCopy.dateSlot.endDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_API),
      };
    }

    if (!isUndefined(dashboardStateObjCopy.timeSlot)) {
      const { startTime, endTime } = dashboardStateObjCopy.timeSlot;
      if (!isUndefined(startTime) && !isUndefined(endTime) && isDateValid(startTime) && isDateValid(endTime) && !isEndDateBeforeStartDate(startTime, endTime)) {
        requestDashboardStateObj.timeSlot = {
          startTime: moment(startTime).format(AppConstants.SLOT_FORMAT_BACKEND),
          endTime: moment(endTime).format(AppConstants.SLOT_FORMAT_BACKEND),
        };
      }
    }

    if (dashboardStateObjCopy.tabValue !== undefined && dashboardStateObjCopy.tabValue !== "") {
      requestDashboardStateObj.deliveryFlow = dashboardStateObjCopy.tabValue;
    }
  }
  return requestDashboardStateObj;
};

export const capitalizeIssueNameString = (nameValue: any) => {
  // extraItems to Extra Items
  let str = "";
  if (!isUndefined(nameValue) && !isNull(nameValue)) {
    let arr = nameValue.split("");
    if (!isUndefined(arr) && isInputAnArray(arr) && isNonEmptyArray(arr)) {
      arr.forEach((char: any, char_index: any) => {
        if (char_index === 0) {
          str = char.toUpperCase();
        } else if (char.toUpperCase() === char.toString()) {
          str = str + " " + char;
        } else {
          str = str + char;
        }
      });
    }
  }
  return str;
};

export const resetDelveDashboardDataObjUtils = (newDelveDashboardDataObj: any) => {
  const copyNewDelveDashboardDataObj = JSON.parse(JSON.stringify(newDelveDashboardDataObj));
  if (!isUndefined(copyNewDelveDashboardDataObj.orderCount)) {
    if (checkIfInputIsValidObjectWithKeys(copyNewDelveDashboardDataObj.orderCount)) {
      Object.keys(copyNewDelveDashboardDataObj.orderCount).forEach((keyName: any) => {
        copyNewDelveDashboardDataObj.orderCount[keyName].count = 0;
        copyNewDelveDashboardDataObj.orderCount[keyName].cost = 0;
      });
    }
  }

  if (!isUndefined(copyNewDelveDashboardDataObj.hubFooterData)) {
    copyNewDelveDashboardDataObj.hubFooterData = [] as any;
  }

  if (!isUndefined(copyNewDelveDashboardDataObj.hubSeriesObj)) {
    copyNewDelveDashboardDataObj.hubSeriesObj = [] as any;
  }

  if (!isUndefined(copyNewDelveDashboardDataObj.itemsCountObj)) {
    copyNewDelveDashboardDataObj.itemsCountObj = {} as any;
  }

  if (!isUndefined(copyNewDelveDashboardDataObj.totalIssueSummaryCount)) {
    copyNewDelveDashboardDataObj.totalIssueSummaryCount = 0;
  }

  if (!isUndefined(copyNewDelveDashboardDataObj.hubLevelVerificationData)) {
    copyNewDelveDashboardDataObj.hubLevelVerificationData = {} as any;
  }

  return copyNewDelveDashboardDataObj;
};

export const updateCountDetails = (obj: any, keyName: any, countValue: any, costValue: any) => {
  return {
    ...obj,
    [keyName]: {
      count: countValue || 0,
      cost: costValue || 0,
    },
  };
};

export const designDelveDashboardRecordDataUtils = (responseData: any, returnDataObj: any) => {
  if (!isUndefined(responseData) && checkIfInputIsValidObjectWithKeys(responseData)) {
    Object.keys(responseData).forEach((keyName: any) => {
      if (!isUndefined(keyName)) {
        switch (keyName) {
          case "currency":
            returnDataObj.currency = responseData[keyName];
            break;
          case "totalOrders":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "totalOrders", responseData[keyName], returnDataObj.orderCount.totalOrders.cost);
            break;
          case "totalCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "totalOrders", returnDataObj.orderCount.totalOrders.count, responseData[keyName]);
            break;
          case "cratesVerified":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "cratesVerified", responseData[keyName], returnDataObj.orderCount.cratesVerified.cost);
            break;
          case "cratesVerifiedCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "cratesVerified", returnDataObj.orderCount.cratesVerified.count, responseData[keyName]);
            break;
          case "cratesUnverified":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "cratesUnverified", responseData[keyName], returnDataObj.orderCount.cratesUnverified.cost);
            break;
          case "cratesUnverifiedCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "cratesUnverified", returnDataObj.orderCount.cratesUnverified.count, responseData[keyName]);
            break;
          case "itemsVerified":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsVerified", responseData[keyName], returnDataObj.orderCount.itemsVerified.cost);
            break;
          case "itemsVerifiedCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsVerified", returnDataObj.orderCount.itemsVerified.count, responseData[keyName]);
            break;
          case "itemsUnverified":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsUnverified", responseData[keyName], returnDataObj.orderCount.itemsUnverified.cost);
            break;
          case "itemsUnverifiedCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsUnverified", returnDataObj.orderCount.itemsUnverified.count, responseData[keyName]);
            break;
          case "issuesFound":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "issuesFound", responseData[keyName], returnDataObj.orderCount.issuesFound.cost);
            break;
          case "issuesFoundCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "issuesFound", returnDataObj.orderCount.issuesFound.count, responseData[keyName]);
            break;
          case "statuses":
            if (!isUndefined(responseData[keyName]) && checkIfInputIsValidObjectWithKeys(responseData[keyName])) {
              Object.keys(responseData[keyName]).forEach((status: any) => {
                switch (status) {
                  case DELVE_DASHBOARD_CONST.VERIFIED:
                    returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "ordersVerified", responseData[keyName][status]?.totalOrders, responseData[keyName][status]?.totalCost);
                    break;
                  case DELVE_DASHBOARD_CONST.UNVERIFIED:
                    returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "ordersUnverified", responseData[keyName][status]?.totalOrders, responseData[keyName][status]?.totalCost);
                    break;
                  case DELVE_DASHBOARD_CONST.PARTIALLY_VERIFIED:
                    returnDataObj.orderCount = updateCountDetails(
                      returnDataObj.orderCount,
                      "ordersPartialVerified",
                      responseData[keyName][status]?.totalOrders,
                      responseData[keyName][status]?.totalCost
                    );
                    break;
                }
              });
            }
            break;
          case "hubs":
            let hubsDataObj = responseData[keyName];
            if (!isUndefined(hubsDataObj) && checkIfInputIsValidObjectWithKeys(hubsDataObj)) {
              Object.keys(hubsDataObj).forEach((hub: any) => {
                if (!isUndefined(hub) && checkIfInputIsValidObjectWithKeys(hubsDataObj[hub])) {
                  if (returnDataObj.hubFooterData.indexOf(hub) < 0) {
                    returnDataObj.hubFooterData.push(hub);
                    returnDataObj.hubLevelVerificationData[hub] = {
                      totalOrders: 0,
                      partiallyVerifiedOrders: 0,
                      verifiedOrders: 0,
                      issuesReported: 0,
                      partiallyVerifiedOrdersWithIssues: 0,
                      verifiedOrdersWithIssues: 0,
                    };
                  }

                  Object.keys(hubsDataObj[hub]).forEach((issueName: any) => {
                    if (!isUndefined(issueName)) {
                      if (issueName === "totalOrders") {
                        returnDataObj.hubLevelVerificationData[hub].totalOrders = hubsDataObj[hub][issueName];
                      } else if (issueName === "verifiedOrders") {
                        returnDataObj.hubLevelVerificationData[hub].verifiedOrders = hubsDataObj[hub][issueName];
                      } else if (issueName === "verifiedOrdersWithIssues") {
                        returnDataObj.hubLevelVerificationData[hub].verifiedOrdersWithIssues = hubsDataObj[hub][issueName];
                      } else if (issueName === "partiallyVerifiedOrders") {
                        returnDataObj.hubLevelVerificationData[hub].partiallyVerifiedOrders = hubsDataObj[hub][issueName];
                      } else if (issueName === "partiallyVerifiedOrdersWithIssues") {
                        returnDataObj.hubLevelVerificationData[hub].partiallyVerifiedOrdersWithIssues = hubsDataObj[hub][issueName];
                      } else {
                        if (issueName !== "issuesFound" && issueName.indexOf("return") === -1) {
                          if (isUndefined(returnDataObj.itemsCountObj[issueName])) {
                            returnDataObj.itemsCountObj[issueName] = {
                              name: capitalizeIssueNameString(issueName),
                              cost: 0,
                              value: issueName,
                            };
                            returnDataObj.hubSeriesObj.push({
                              data: [],
                              name: returnDataObj.itemsCountObj[issueName].name,
                              type: undefined,
                              issueName: issueName,
                            });
                          }
                          let issue_index = returnDataObj.hubSeriesObj.findIndex((x: any) => x.issueName == issueName);
                          if (issue_index > -1) {
                            returnDataObj.hubSeriesObj[issue_index].data.push(hubsDataObj[hub][issueName]);
                          }
                          returnDataObj.hubLevelVerificationData[hub].issuesReported = returnDataObj.hubLevelVerificationData[hub].issuesReported + hubsDataObj[hub][issueName];
                          returnDataObj.itemsCountObj[issueName].cost = returnDataObj.itemsCountObj[issueName].cost + hubsDataObj[hub][issueName];
                          returnDataObj.totalIssueSummaryCount = returnDataObj.totalIssueSummaryCount + hubsDataObj[hub][issueName];
                        }
                      }
                    }
                  });
                }
              });
            }
        }
      }
    });
  }
  return returnDataObj;
};

/* LMC 2314 */

export const getPercentageValueString = (sumCount: any, currentValueCount: any) => {
  return `(${getPercentageValue(sumCount, currentValueCount)}%)`;
};

export const getOrdersVerfiedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.totalOrders) &&
      !isUndefined(orderCountList.totalOrders.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.ordersVerified) &&
      !isUndefined(orderCountList.ordersVerified.count)
    ) {
      return getPercentageValueString(orderCountList.totalOrders.count, orderCountList.ordersVerified.count);
    }
  }
  return "";
};

export const getOrdersPartiallyVerfiedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.totalOrders) &&
      !isUndefined(orderCountList.totalOrders.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.ordersPartialVerified) &&
      !isUndefined(orderCountList.ordersPartialVerified.count)
    ) {
      return getPercentageValueString(orderCountList.totalOrders.count, orderCountList.ordersPartialVerified.count);
    }
  }
  return "";
};

export const getOrdersUnVerfiedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.totalOrders) &&
      !isUndefined(orderCountList.totalOrders.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.ordersUnverified) &&
      !isUndefined(orderCountList.ordersUnverified.count)
    ) {
      return getPercentageValueString(orderCountList.totalOrders.count, orderCountList.ordersUnverified.count);
    }
  }
  return "";
};

export const getCratesVerfiedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.cratesVerified) &&
      !isUndefined(orderCountList.cratesVerified.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.cratesUnverified) &&
      !isUndefined(orderCountList.cratesUnverified.count)
    ) {
      const totalCount = Number(orderCountList.cratesVerified.count) + Number(orderCountList.cratesUnverified.count);
      return getPercentageValueString(totalCount, orderCountList.cratesVerified.count);
    }
  }
  return "";
};

export const getCratesUnVerfiedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.cratesVerified) &&
      !isUndefined(orderCountList.cratesVerified.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.cratesUnverified) &&
      !isUndefined(orderCountList.cratesUnverified.count)
    ) {
      const totalCount = Number(orderCountList.cratesVerified.count) + Number(orderCountList.cratesUnverified.count);
      return getPercentageValueString(totalCount, orderCountList.cratesUnverified.count);
    }
  }
  return "";
};

export const getItemsVerfiedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.itemsVerified) &&
      !isUndefined(orderCountList.itemsVerified.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.itemsUnverified) &&
      !isUndefined(orderCountList.itemsUnverified.count)
    ) {
      const totalCount = Number(orderCountList.itemsVerified.count) + Number(orderCountList.itemsUnverified.count);
      return getPercentageValueString(totalCount, orderCountList.itemsVerified.count);
    }
  }
  return "";
};

export const getItemsUnVerfiedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.itemsVerified) &&
      !isUndefined(orderCountList.itemsVerified.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.itemsUnverified) &&
      !isUndefined(orderCountList.itemsUnverified.count)
    ) {
      const totalCount = Number(orderCountList.itemsVerified.count) + Number(orderCountList.itemsUnverified.count);
      return getPercentageValueString(totalCount, orderCountList.itemsUnverified.count);
    }
  }
  return "";
};

export const getIssuesReportedPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.itemsVerified) &&
      !isUndefined(orderCountList.itemsVerified.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.issuesFound) &&
      !isUndefined(orderCountList.issuesFound.count)
    ) {
      const totalCount = Number(orderCountList.itemsVerified.count);
      return getPercentageValueString(totalCount, orderCountList.issuesFound.count);
    }
  }
  return "";
};

export const designConsignmentsListBasedOnIssueNameUtils = (listRecord: any) => {
  let consignment_list = [] as any;
  Object.keys(listRecord).forEach((hub: any) => {
    if (!isUndefined(hub) && !isUndefined(listRecord[hub]) && checkIfInputIsValidObjectWithKeys(listRecord[hub])) {
      Object.keys(listRecord[hub]).forEach((issueName: any) => {
        if (!isUndefined(issueName) && !isUndefined(listRecord[hub][issueName]) && isInputAnArray(listRecord[hub][issueName]) && isNonEmptyArray(listRecord[hub][issueName])) {
          listRecord[hub][issueName].forEach((consignment: any) => {
            consignment_list.push(consignment);
          });
        }
      });
    }
  });
  return consignment_list;
};

export const getVerfiedConsignmentPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.totalConsignments) &&
      !isUndefined(orderCountList.totalConsignments.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.verifiedConsignments) &&
      !isUndefined(orderCountList.verifiedConsignments.count)
    ) {
      return getPercentageValueString(orderCountList.totalConsignments.count, orderCountList.verifiedConsignments.count);
    }
  }
  return "";
};

export const getUnVerfiedConsignmentPercentage = (orderCountList: any) => {
  if (checkIfInputIsValidObjectWithKeys(orderCountList)) {
    if (
      checkIfInputIsValidObjectWithKeys(orderCountList.totalConsignments) &&
      !isUndefined(orderCountList.totalConsignments.count) &&
      checkIfInputIsValidObjectWithKeys(orderCountList.unverifiedConsignments) &&
      !isUndefined(orderCountList.unverifiedConsignments.count)
    ) {
      return getPercentageValueString(orderCountList.totalConsignments.count, orderCountList.unverifiedConsignments.count);
    }
  }
  return "";
};

export const designReturnDelveDashboardRecordDataUtils = (responseData: any, returnDataObj: any) => {
  if (!isUndefined(responseData) && checkIfInputIsValidObjectWithKeys(responseData)) {
    Object.keys(responseData).forEach((keyName: any) => {
      if (!isUndefined(keyName)) {
        switch (keyName) {
          case "currency":
            returnDataObj.currency = responseData[keyName];
            break;
          case "totalOrders":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "totalConsignments", responseData[keyName], returnDataObj.orderCount.totalConsignments.cost);
            break;
          case "totalCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "totalConsignments", returnDataObj.orderCount.totalConsignments.count, responseData[keyName]);
            break;
          case "returnItemsVerified":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsVerified", responseData[keyName], returnDataObj.orderCount.itemsVerified.cost);
            break;
          case "returnItemsVerifiedCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsVerified", returnDataObj.orderCount.itemsVerified.count, responseData[keyName]);
            break;
          case "returnItemsUnverified":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsUnverified", responseData[keyName], returnDataObj.orderCount.itemsUnverified.cost);
            break;
          case "returnItemsUnverifiedCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "itemsUnverified", returnDataObj.orderCount.itemsUnverified.count, responseData[keyName]);
            break;
          case "returnIssuesFound":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "issuesFound", responseData[keyName], returnDataObj.orderCount.issuesFound.cost);
            break;
          case "returnIssuesFoundCost":
            returnDataObj.orderCount = updateCountDetails(returnDataObj.orderCount, "issuesFound", returnDataObj.orderCount.issuesFound.count, responseData[keyName]);
            break;
          case "statuses":
            if (!isUndefined(responseData[keyName]) && checkIfInputIsValidObjectWithKeys(responseData[keyName])) {
              Object.keys(responseData[keyName]).forEach((status: any) => {
                switch (status) {
                  case DELVE_DASHBOARD_CONST.VERIFIED:
                    returnDataObj.orderCount = updateCountDetails(
                      returnDataObj.orderCount,
                      "verifiedConsignments",
                      responseData[keyName][status]?.totalOrders,
                      responseData[keyName][status]?.totalCost
                    );
                    break;
                  case DELVE_DASHBOARD_CONST.UNVERIFIED:
                    returnDataObj.orderCount = updateCountDetails(
                      returnDataObj.orderCount,
                      "unverifiedConsignments",
                      responseData[keyName][status]?.totalOrders,
                      responseData[keyName][status]?.totalCost
                    );
                    break;
                }
              });
            }
            break;
          case "hubs":
            let hubsDataObj = responseData[keyName];
            if (!isUndefined(hubsDataObj) && checkIfInputIsValidObjectWithKeys(hubsDataObj)) {
              Object.keys(hubsDataObj).forEach((hub: any) => {
                if (!isUndefined(hub) && checkIfInputIsValidObjectWithKeys(hubsDataObj[hub])) {
                  if (returnDataObj.hubFooterData.indexOf(hub) < 0) {
                    returnDataObj.hubFooterData.push(hub);
                    returnDataObj.hubLevelVerificationData[hub] = {
                      totalOrders: 0,
                      partiallyVerifiedOrders: 0,
                      verifiedOrders: 0,
                      issuesReported: 0,
                      partiallyVerifiedOrdersWithIssues: 0,
                      verifiedOrdersWithIssues: 0,
                    };
                  }

                  Object.keys(hubsDataObj[hub]).forEach((issueName: any) => {
                    if (!isUndefined(issueName)) {
                      if (issueName === "totalOrders") {
                        returnDataObj.hubLevelVerificationData[hub].totalOrders = hubsDataObj[hub][issueName];
                      } else if (issueName === "returnVerifiedOrders") {
                        returnDataObj.hubLevelVerificationData[hub].verifiedOrders = hubsDataObj[hub][issueName];
                      } else if (issueName === "returnVerifiedOrdersWithIssues") {
                        returnDataObj.hubLevelVerificationData[hub].verifiedOrdersWithIssues = hubsDataObj[hub][issueName];
                      } else if (issueName === "returnPartiallyVerifiedOrders") {
                        returnDataObj.hubLevelVerificationData[hub].partiallyVerifiedOrders = hubsDataObj[hub][issueName];
                      } else if (issueName === "returnPartiallyVerifiedOrdersWithIssues") {
                        returnDataObj.hubLevelVerificationData[hub].partiallyVerifiedOrdersWithIssues = hubsDataObj[hub][issueName];
                      } else {
                        if (issueName !== "" && issueName !== "issuesFound" && issueName.indexOf("return") !== -1) {
                          let returnIssueName = issueName.replace("return", "");
                          if (isUndefined(returnDataObj.itemsCountObj[issueName])) {
                            returnDataObj.itemsCountObj[issueName] = {
                              name: capitalizeIssueNameString(returnIssueName),
                              cost: 0,
                              value: returnIssueName,
                            };
                            returnDataObj.hubSeriesObj.push({
                              data: [],
                              name: returnDataObj.itemsCountObj[issueName].name,
                              type: undefined,
                              issueName: issueName,
                            });
                          }
                          let issue_index = returnDataObj.hubSeriesObj.findIndex((x: any) => x.issueName == issueName);
                          if (issue_index > -1) {
                            returnDataObj.hubSeriesObj[issue_index].data.push(hubsDataObj[hub][issueName]);
                          }
                          returnDataObj.hubLevelVerificationData[hub].issuesReported = returnDataObj.hubLevelVerificationData[hub].issuesReported + hubsDataObj[hub][issueName];
                          returnDataObj.itemsCountObj[issueName].cost = returnDataObj.itemsCountObj[issueName].cost + hubsDataObj[hub][issueName];
                          returnDataObj.totalIssueSummaryCount = returnDataObj.totalIssueSummaryCount + hubsDataObj[hub][issueName];
                        }
                      }
                    }
                  });
                }
              });
            }
        }
      }
    });
  }
  return returnDataObj;
};
