import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, ListItemText, SvgIcon , TextField} from '@material-ui/core';
import searchableDropdownStyles from "./AutoCompleteWrapper.styles";
import AppConstants from '../../constants';
import { Autocomplete, AutocompleteCloseReason, } from '@material-ui/lab';
import Chip from "@material-ui/core/Chip";
import TextBox from '../TextBox';
import { SelectItemProps } from 'common/SelectBox';
import { ReactComponent as downArrowIcon } from "../../assets/icons/down-arrow.svg";

export interface AutoCompleteItemProps {
  name?: string;
  value: any;
}

interface AutoCompleteProps {
  handleChange?: (value: any) => void;
  handleClose?: (value: any) => void;
  value: any;
  multiple: boolean;
  id: string;
  items: Array<AutoCompleteItemProps>;
  reset?: boolean;
  resetAll?: boolean;
  label: string
}
const AutoCompleteWrapper = (props: AutoCompleteProps) => {
  const classes = searchableDropdownStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { handleChange, handleClose, value, multiple, id, items, reset, resetAll, label } = props;
  const [itemsDdn, setItemsDdn] = useState(items.slice());
    const [ddnValue, setDdnValue] = useState<any>(value || AppConstants.SELECT_NONE);

  const handleDdnChange = useCallback(
    (event: any, newValue: any) => {
      if(newValue){
      let selected ;
      if(!multiple){
        selected = [newValue]
       
      }
      else{
        selected = newValue;
      }
   
      if (multiple) {
        const selectedValues = selected.map((item: AutoCompleteItemProps) => item.value);
        const ddnValues = ddnValue.map((item: AutoCompleteItemProps) => item.value);
        const selectAllIdx = selectedValues.indexOf(AppConstants.SELECT_ALL.value);
        const selectAllPreviousIdx = ddnValues.indexOf(AppConstants.SELECT_ALL.value);
        if (selectAllIdx > -1) {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = selected.filter((item: AutoCompleteItemProps) => item.value !== AppConstants.SELECT_ALL.value);
            } else {
              selected = itemsDdn.slice();
            }
          }
        } else {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = [];
            } else if (selected.length === itemsDdn.length - 1) {
              selected = itemsDdn.slice();
            }
          }
        }
        if (!selected.length) {
          selected = [];
        }
      }
      
      setDdnValue(selected);
      handleChange && handleChange(selected);
    }
    },
    [ddnValue, itemsDdn, multiple, handleChange],
  );

  const onClose = useCallback(
    (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
        setIsOpen(false);
      if (reason === 'toggleInput') {
        return;
      }
      handleClose && handleClose(ddnValue);
    },
    [handleClose, ddnValue],
  );

  const handleSelectedValues = useCallback(
    (option: SelectItemProps, selected: SelectItemProps) => {
      let returnValue = false;
      
      if (ddnValue.findIndex((item: SelectItemProps) => item.value === selected.value) === -1) {
        //setDdnValue([...ddnValue, selected]);
      }
      if (selected.value === AppConstants.SELECT_ALL.value) {
        if (ddnValue.findIndex((item: SelectItemProps) => item.value === AppConstants.SELECT_ALL.value) > -1 && ddnValue.findIndex((item: SelectItemProps) => item.value === option.value) === -1) {
          setDdnValue([...ddnValue, option]);
        }
      }
      if (option.value === AppConstants.SELECT_ALL.value) {
        returnValue = option.value === selected.value;
        if (selected.value !== AppConstants.SELECT_ALL.value) {
          if (ddnValue.length === items.length) {
            if (ddnValue.findIndex((item: SelectItemProps) => item.value === AppConstants.SELECT_ALL.value) === -1) {
              setDdnValue([...ddnValue, AppConstants.SELECT_ALL]);
            }
            returnValue = true;
          } else {
            returnValue = false;
          }
        }
      } else {
        returnValue = option.value === selected.value;
      }
      return returnValue;
    },
    [ddnValue, items],
  );

  useEffect(() => {
    if (multiple) {
      setItemsDdn([AppConstants.SELECT_ALL, ...items]);
      setDdnValue(value || []);
    } else {
      setItemsDdn([AppConstants.SELECT_NONE, ...items]);
      setDdnValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (reset) {
      if (multiple) {
        setDdnValue([]);
      } else {
        setDdnValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (resetAll) {
      if (multiple) {
        setDdnValue([]);
      } else {
        setDdnValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAll])

  return (
    <>

      <Autocomplete
        fullWidth
        open={isOpen}
        id={id}
        onOpen={() => {
            setIsOpen(true);
          }}
        value={multiple ? ddnValue : ddnValue.value}
        onClose={onClose}
        multiple={multiple}
        key={resetAll && ddnValue?.value}
        className={classes.searchableDropdown}
        disableCloseOnSelect={multiple ? true : false}
        disablePortal
        onChange={handleDdnChange}
        noOptionsText={AppConstants.NO_OPTIONS_TEXT}
        getOptionSelected={handleSelectedValues}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {
              multiple && <Checkbox color="primary" checked={selected} />
            }
            <ListItemText className="optionName" primary={option.name} />
          </React.Fragment>
        )}
        options={itemsDdn}
        getOptionLabel={(option: SelectItemProps) => option.name || ''}
        renderInput={(params: any) => (
          <TextBox
           {...params} 
            autoFocus
            onChange={params.inputProps.onChange}
            variant="outlined"
            textBoxId="autoCompleteSearchTextBox"
            label={label}
            placeholderText="Search"
          />
        )}
        renderTags={(value, getTagProps) => {
          const numTags = value.length;
          const limitTags = 1;
          if (ddnValue.some((e: { value: string; }) => e.value === AppConstants.SELECT_ALL.value)) {
            return <Chip {...getTagProps({ index:0 })}  size="small" label={AppConstants.SELECT_ALL.name} />;
          } else {
            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    size='small'
                    label={option.name}
                  />
                ))}
    
                { numTags > limitTags && <span className='tagLimit'>{ ` +${numTags - limitTags}`}</span> }
              </>
            );
          }
        }}
     popupIcon={<SvgIcon className={classes.popupIndicatorOpenStyle} component={downArrowIcon} />}
      classes={{
        clearIndicatorDirty: classes.clearIndicatorStyle,
        popupIndicator: classes.popupIndicatorStyle,
        paper: classes.paperStyles,
        popper: classes.popper
      }}
      />
    </>
  );
}

export default AutoCompleteWrapper;