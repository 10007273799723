import React from "react";

import { Grid, LinearProgress, Typography } from "@material-ui/core";
import { getPercentageValue } from "../../../utils/helpers.utils";

interface LinearProgressWithLabelProInterface {
  classes: any;
  currentValue: any;
  sumValue: any;
}

export const LinearProgressWithLabelPro = (props: LinearProgressWithLabelProInterface) => {
  const { classes, currentValue, sumValue } = props;

  return (
    <Grid container>
      <Grid item xs={9} className={classes.linearProgressBarGridItem}>
        <LinearProgress
          classes={{
            root: classes.linearProgressRoot,
            bar: classes.linearProgressBarRoot,
            colorPrimary: classes.linearProgressColorDefault,
            barColorPrimary: classes.linearProgressBarColorInProgress,
          }}
          variant="determinate"
          value={getPercentageValue(sumValue, currentValue)}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography className={classes.issueSummaryValueSpanStyle}>{`${getPercentageValue(sumValue, currentValue)} %`}</Typography>
      </Grid>
    </Grid>
  );
};

interface ShowMessageInsideCardInterface {
  classes: any;
  message: any;
}

export const ShowMessageInsideCard = (props: ShowMessageInsideCardInterface) => {
  const { classes, message } = props;

  return (
    <Grid className={classes.issueSummaryGridNoDataAvailableCard}>
      <Typography className={classes.issueSummaryValueSpanStyle}>{`${message || "No Data Available"}`}</Typography>
    </Grid>
  );
};
