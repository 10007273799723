import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
    container: {
      flexDirection: "column",
    },
    viewContainer: {
      flexDirection: "column",
      margin: "24px 0px 0px 0px",
      padding: 0,
    },
    gritSearchContainerItem: {
      margin: 0,
      padding: 0,
    },
    gritContainerItem: {
      margin: 0,
      marginBottom: "24px",
      padding: 0,
    },
    gritContainerItemSearchHeader: {
      margin: 0,
      marginBottom: "12px",
      padding: 0,
      display: "flex",
      justifyContent: "flex-end",
    },
    gritContainerIssueSummaryBox: {
      margin: 0,
      padding: 0,
    },
    gritSearchHeaderItem: {
      margin: "0px 8px 0px 0px",
      padding: 0,
      "&:last-child": {
        margin: 0,
      },
    },
    orderCountDetailsMainContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    orderCountDetailsMainBox: {
      margin: "0px 8px 0px 0px",
      padding: 0,
      background: COLORS.WHITE,
      boxShadow: "4px 4px 24px rgba(0, 0, 0, 0.06)",
      border: "none",
      borderRadius: "8px",
      "&:last-child": {
        margin: 0,
      },
    },
    orderCountDetailsIconCountContainer: {
      margin: 0,
      padding: "8px",
    },
    orderCountDetailsCountContainer: {
      display: "flex",
      margin: "auto",
      padding: "8px",
      textAlign: "center",
    },
    orderCountDetailsTitleSpanContainer: {
      margin: 0,
      padding: "8px",
      textAlign: "center",
    },
    orderCountDetailsCostContainer: {
      margin: 0,
      padding: "8px",
      textAlign: "center",
    },
    orderCountIconContainer: {
      margin: "auto",
      padding: 0,
      minWidth: "48px",
      minHeight: "48px",
    },
    orderDetailsCountSpanStyle: {
      fontSize: 20,
      lineHeight: "28px",
      fontWeight: 500,
      color: COLORS.MINE_SHAFT_NEW,
    },
    orderCountDetailsTitleSpanStyle: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 600,
      color: COLORS.EMPEROR,
    },
    IssueSummaryMainGridContainer: {
      margin: 0,
      padding: "0px 0px 0px 12px",
    },
    orderCountDetailsCostSpanStyle: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 400,
      color: COLORS.EMPEROR,
    },
    issueGridItemStyle: {
      margin: "16px 0px 16px 0px",
      padding: 0,
      textAlign: "left",
    },
    issueGridItemSpanStyle: {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 700,
      color: COLORS.COD_GRAY,
    },
    issueSummaryGridCard: {
      minHeight: "calc(35vh)",
      maxHeight: "calc(35vh)",
      width: "100%",
      overflow: "auto",
    },
    issueSummaryGridNoDataAvailableCard: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    issueSummaryHeaderGridContainer: {},
    issueSummaryHeadingGridItem: {
      margin: 0,
      padding: "8px",
      height: "40px",
      textAlign: "left",
      "&:nth-child(2)": {
        textAlign: "center",
      },
      "&:last-child": {
        textAlign: "center",
      },
    },
    issueSummaryHeadingSpanStyle: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      color: COLORS.COD_GRAY,
    },
    issueSummaryValueSpanStyle: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 400,
      color: COLORS.READY_BLUE,
    },
    issueSummaryNameValueSpanStyle: {
      margin: "auto 0px",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 400,
      color: COLORS.EMPEROR,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    linearProgressBarGridItem: {
      margin: "auto",
      padding: 0,
    },
    clearAllFilterGridContainer: {
      margin: 0,
      padding: 0,
    },
    clearAllFilterGridItem: {
      margin: 0,
      marginBottom: "16px",
      padding: 0,
      display: "flex",
      justifyContent: "flex-end",
    },
    gritFilterHeaderItem: {
      margin: "auto 8px",
      textAlign: "right",
      padding: 0,
      "&:last-child": {
        margin: 0,
      },
    },
    clearAllFilterBtnStyle: {
      fontSize: 12,
      fontWeight: 700,
      marginRight: theme.spacing(2),
      backgroundColor: COLORS.WHITE,
      color: COLORS.PRIMARY_MAIN,
      textDecoration: "none",
      "&:hover": {
        backgroundColor: COLORS.WHITE,
        color: COLORS.PRIMARY_MAIN,
      },
    },
    storeVerificationGridCard: {
      minHeight: "calc(10vh)",
      maxHeight: "calc(10vh)",
      width: "100%",
      overflow: "auto",
    },
    storeVerificationGridItemCard: {
      margin: 0,
      padding: theme.spacing(2),
      cursor: "pointer",
    },
    storeVerificationGridContainerCard: {
      margin: 0,
      padding: theme.spacing(1),
      background: COLORS.WHITE,
      boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
      borderRadius: "12px",
    },
    storeVerificationItemRow: {
      margin: "0px 0px 8px 0px",
      padding: 0,
    },
    storeVerificationHubSpanStyle: {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 700,
      color: COLORS.EMPEROR,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    storeVerificationHeader: {
      fontSize: 12,
      fontWeight: 500,
      color: COLORS.EMPEROR,
    },
    storeVerificationValue: {
      fontSize: 14,
      fontWeight: 700,
      color: COLORS.EMPEROR,
    },
    storeVerificationVerifiedValue: {
      fontSize: 14,
      fontWeight: 700,
      color: COLORS.SEA_GREEN,
    },
    storeVerificationVerifiedWithIssueValue: {
      fontSize: 14,
      fontWeight: 700,
      color: COLORS.DARK_RED,
    },
    orderPercentageDetailsTitleSpanStyle: {
      margin: "auto",
      padding: "0px 0px 0px 4px",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 500,
      color: COLORS.EMPEROR,
    },
    issueNameSpanIconDiv: {
      display: "flex",
      justifyContent: "flex-start",
    },
    issueRedirectIconSvgStyle: {
      margin: "auto 0px auto 8px",
      padding: 0,
      cursor: "pointer",
    },
    dashboardTabRootStyle: {
      width: "100%",
      padding: "0px 24px 0px 24px",
      background: COLORS.ALICE_GRAY,
      height: "44px",
      minHeight: "44px",
      fontSize: 16,
      fontWeight: 500,
      color: COLORS.DUSTY_GRAY,
      "& .MuiTabs-indicator": {
        display: "none",
      },
      "& .MuiTabs-flexContainer": {
        height: "44px",
      },
      "& .MuiTab-root.Mui-selected": {
        fontWeight: 600,
        color: COLORS.MINE_SHAFT_NEW,
      },
    },
    dashboardTabPanelRootStyle: {
      width: "100%",
      padding: 0,
    },
  })
);
