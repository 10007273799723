import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import { useStyles } from './Loader.styles';

interface LoaderProps { }

const Loader = (props: LoaderProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <CircularProgress />
    </Grid>
  )
}

export default Loader;