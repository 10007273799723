import React from "react";

import { Checkbox, Grid, IconButton, InputAdornment, SvgIcon, Tab, Tabs, Typography } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";

import AppConstants from "../../../constants";
import DateRangePickerComponent from "../../../common/DateRangeComponent";
import calendarIcon from "../../../assets/icons/calendar-icon.svg";
import TextBox from "../../../common/TextBox";

import { ReactComponent as searchIcon } from "../../../assets/icons/search-icon-small.svg";

import CustomPlannedRouteView from "./CustomPlannedRouteView";
import CustomUnPlannedRouteView from "./CustomUnPlannedRouteView";
import Filters from "./FilterConsignmetn";

import {
  ALL_ROUTES_TEXT_CONTANT,
  ALL_ROUTE_INITIAL_STATE_INTERFACE,
  BUTTONS_TEXT,
  PLANNED_ROUTE_DETAILS,
  ROUTE_DETAILS_INTERFACE,
  SNACKBAR_MESSAGES,
  UNPLANNED_ROUTE_DETAILS,
} from "../utils/AllRouteConstant";
import { countObjectProperties, isNonEmptyArray, isNonEmptyObject, isObject, isUndefined } from "../../../utils/helpers.utils";

interface CustomAllRoutesLeftSectionInterface {
  classes: any;
  allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE;
  // Filter
  consignmentStatusList: any;
  slotsList: any;
  zoneList: any;
  handleDateChange: any;
  handleTabChange: any;
  handleStatusChange: (params: any) => void;
  handleSlotChange: (params: any) => void;
  handleZoneChange: (params: any) => void;
  handleConsignmentIdOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleConsignmentSearch: () => void;
  hubId: string | number;
  hubName: { name: string; value: string | number }[];
  handleChangeAllRoutesHub: () => void;
  // Planned Accordion Summary
  handleRouteAccordianToggle: (event: React.ChangeEvent<HTMLInputElement>, routeName: string) => void;
  handleRoutAreaAccordianToggle: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, subRouteAreaName?: string) => void;
  handleRouteSlotAccordianToggle: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string) => void;
  handleRouteOrderAccordianToggle: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  // Planned Checkbox
  handleRouteCheckboxToggle: (e: React.ChangeEvent<HTMLInputElement>, routeName: string) => void;
  handleAreaCheckboxToggle: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string) => void;
  handleSlotCheckboxToggle: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string) => void;
  handleOrderCheckboxToggle: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  // Planned Action
  orderActionPopUpViewChange: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  removeOrderFromRoute: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  addOrderToAnotherRoute: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  handleDeleteRoute: (e: React.ChangeEvent<HTMLInputElement>, value: any) => void;
  // Unplanned
  handleUnplannedAccordianToggle: any;
  handleUnplannedInnerOrderAccordianToggle: any;
  handleUnplannedOrderCheckboxToggle: any;
  handleExpandAllRecordClickMethod: any;
  handleSelectAllRoutes: any;
  handleUnplannedAreaCheckboxToggle: any;
  handleOpenCloseUnplannedRouteSlot: any;
  handleUnplannedSlotCheckboxToggle: any;
}

const CustomAllRoutesLeftSection = (props: CustomAllRoutesLeftSectionInterface) => {
  const {
    classes,
    allRouteState,
    // Filter
    consignmentStatusList,
    slotsList,
    zoneList,
    handleDateChange,
    handleTabChange,
    handleStatusChange,
    handleSlotChange,
    handleZoneChange,
    handleConsignmentIdOnChange,
    handleConsignmentSearch,
    hubId,
    hubName,
    handleChangeAllRoutesHub,
    // Planned Accordion Summary
    handleRouteAccordianToggle,
    handleRoutAreaAccordianToggle,
    handleRouteSlotAccordianToggle,
    handleRouteOrderAccordianToggle,
    // Planned Checkbox
    handleRouteCheckboxToggle,
    handleAreaCheckboxToggle,
    handleSlotCheckboxToggle,
    handleOrderCheckboxToggle,
    // Planned Action
    orderActionPopUpViewChange,
    removeOrderFromRoute,
    addOrderToAnotherRoute,
    handleDeleteRoute,
    // Unplanned
    handleUnplannedAccordianToggle,
    handleUnplannedInnerOrderAccordianToggle,
    handleUnplannedOrderCheckboxToggle,
    handleExpandAllRecordClickMethod,
    handleSelectAllRoutes,
    handleUnplannedAreaCheckboxToggle,
    handleOpenCloseUnplannedRouteSlot,
    handleUnplannedSlotCheckboxToggle,
  } = props;

  const plannedConsignmentPresent = () => {
    if (allRouteState && allRouteState.plannedConsignmentsData && isObject(allRouteState.plannedConsignmentsData) && isNonEmptyObject(allRouteState.plannedConsignmentsData)) {
      return true;
    }
    return false;
  };

  const unPlannedConsignmentPresent = () => {
    if (allRouteState && allRouteState.unplannedConsignmentsData && isObject(allRouteState.unplannedConsignmentsData) && isNonEmptyObject(allRouteState.unplannedConsignmentsData)) {
      return true;
    }
    return false;
  };

  const isAllRoutesSelected = () => {
    if (
      allRouteState &&
      allRouteState.plannedConsignmentsData &&
      isObject(allRouteState.plannedConsignmentsData) &&
      isNonEmptyObject(allRouteState.plannedConsignmentsData) &&
      !isUndefined(allRouteState.selectedConsignments) &&
      isNonEmptyArray(allRouteState.selectedConsignments) &&
      allRouteState.selectedConsignments.length === countObjectProperties(allRouteState.plannedConsignmentsData)
    ) {
      return true;
    }
    return false;
  };

  const getExpandCollapseView = () => {
    return (
      <Grid container className={classes.collapseExpandGridConatinerStyle} direction="row" justify="space-between" alignItems="center">
        <Grid item className={classes.collapseExpandGridCheckboxStyle}>
          {allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED ? (
            <Checkbox color="primary" checked={isAllRoutesSelected()} className="checkBoxIconClass" onClick={(e: any) => handleSelectAllRoutes(e)} />
          ) : (
            ""
          )}
        </Grid>

        <Grid item className={classes.collapseExpandGridItemStyle}>
          <Typography className={classes.collapseExpandGridItemSpanStyle} onClick={() => handleExpandAllRecordClickMethod()}>{`${allRouteState.expandAll ? "Collapse All" : "Expand All"}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleKeyDownChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (allRouteState.consignmentId && event.keyCode === 13) {
      handleConsignmentSearch();
    }
  };

  const isTablPlanned = (): boolean => {
    return allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED;
  };

  const renderRouteDetails = () => {
    const routeDetailsObj: ROUTE_DETAILS_INTERFACE[] = isTablPlanned() ? PLANNED_ROUTE_DETAILS : UNPLANNED_ROUTE_DETAILS;
    return (
      routeDetailsObj?.length > 0 &&
      routeDetailsObj?.map((routeDetail: ROUTE_DETAILS_INTERFACE, routeDetailIndex: number) => (
        <Grid item className="routeDetailsBox" key={`${routeDetailIndex}-routeDetailIndex-routeDetailsBox`}>
          <Typography className="routeDetailsSpan">{routeDetail.key}</Typography>
          <Typography className="routeDetailsValueSpan">{allRouteState[routeDetail?.value]}</Typography>
        </Grid>
      ))
    );
  };

  return (
    <Grid className="consignmentsContainer" container>
      <Grid className="consignmentItem content" item>
        <Grid className="contentContainer">
          <TabContext value={allRouteState.tabValue}>
            <Tabs value={allRouteState.tabValue} onChange={handleTabChange} variant="fullWidth" aria-label="Consignments for Route Creation">
              <Tab label="Planned" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED} />
              <Tab label="Unplanned" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED} />
            </Tabs>
            <Grid container className={classes.filterGridContainerStyle}>
              <Grid container className={`headerSectionContainer`}>
                <Grid className="headerSectionItem" item>
                  <Grid container className={allRouteState.tabValue !== AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? "filtersContainer lastItem" : "filtersContainer"}>
                    {allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED && (
                      <Filters
                        classes={classes}
                        selectedDate={allRouteState.selectedDate}
                        handleStatusChange={handleStatusChange}
                        handleSlotChange={handleSlotChange}
                        handleZoneChange={handleZoneChange}
                        slotValue={allRouteState.slotValue}
                        zoneValue={allRouteState.zoneValue}
                        statusValue={allRouteState.statusValue}
                        consignmentStatusList={consignmentStatusList}
                        slotsList={slotsList}
                        zoneList={zoneList}
                      />
                    )}
                    <Grid item className="filterItem">
                      <DateRangePickerComponent
                        keyId="create-route-date-picker"
                        className="icon-datepicker "
                        value={allRouteState.selectedDate}
                        isTextField={false}
                        iconSrc={calendarIcon}
                        autoUpdateInput={false}
                        autoApply={true}
                        maxSpan={{ day: "0" }}
                        showDropdowns={true}
                        linkedCalendars={true}
                        locale={{ format: AppConstants.DATE_FORMAT }}
                        updateDateRange={handleDateChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container className="searchBoxContainer">
                    <Grid item>
                      <TextBox
                        className="searchTextBox"
                        variant="outlined"
                        onChange={handleConsignmentIdOnChange}
                        value={allRouteState.consignmentId}
                        textBoxId="consignmentSearchTextbox"
                        name="consignmentSearch"
                        type="text"
                        placeholderText={SNACKBAR_MESSAGES.SEARCH_CONSIGNMENTS}
                        endadornment={
                          <InputAdornment position="end">
                            <IconButton edge="end" size="small">
                              <SvgIcon component={searchIcon} viewBox="0 0 16 16" onClick={() => handleConsignmentSearch()} />
                            </IconButton>
                          </InputAdornment>
                        }
                        onKeyDown={handleKeyDownChange}
                      />
                    </Grid>
                    <Grid item className="hubDetailsBox">
                      <Grid container spacing={1} className="hubDetailsBoxContainer">
                        <Typography className={classes.allRotePOSTitleSpanStyle}>{`Hub: ${hubId || ""} - ${hubName[0]?.name?.substring(0, 20) || ""}`}</Typography>
                        <Typography className={classes.allRotePOSChangeSpanStyle} onClick={() => handleChangeAllRoutesHub()}>{`(${AppConstants.PLANNING_CONSTANTS.CHANGE_HUB})`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className="routeDetailsBoxContainer">
                    {renderRouteDetails()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TabPanel className="tabPanel" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED}>
              <Grid className="tabPanelContainer planned" container id={`tab-panel-container-planned`}>
                {plannedConsignmentPresent() ? (
                  <>
                    {getExpandCollapseView()}
                    {Object.keys(allRouteState.plannedConsignmentsData).map((routeName: any) => (
                      <CustomPlannedRouteView
                        classes={classes}
                        routeObj={allRouteState.plannedConsignmentsData[routeName]}
                        routeName={routeName}
                        handleRouteAccordianToggle={handleRouteAccordianToggle}
                        handleRoutAreaAccordianToggle={handleRoutAreaAccordianToggle}
                        handleRouteSlotAccordianToggle={handleRouteSlotAccordianToggle}
                        handleRouteOrderAccordianToggle={handleRouteOrderAccordianToggle}
                        // Checkbox
                        handleRouteCheckboxToggle={handleRouteCheckboxToggle}
                        handleAreaCheckboxToggle={handleAreaCheckboxToggle}
                        handleSlotCheckboxToggle={handleSlotCheckboxToggle}
                        handleOrderCheckboxToggle={handleOrderCheckboxToggle}
                        orderActionPopUpViewChange={orderActionPopUpViewChange}
                        removeOrderFromRoute={removeOrderFromRoute}
                        addOrderToAnotherRoute={addOrderToAnotherRoute}
                        handleDeleteRoute={handleDeleteRoute}
                      />
                    ))}
                  </>
                ) : (
                  <Grid className="noItemsGridStyle" container>
                    <Typography className="noItemsSpanStyle padding-2">{`${ALL_ROUTES_TEXT_CONTANT.NO_PLANNED_ITEMS}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel className="tabPanel" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED}>
              <Grid className="tabPanelContainer planned" container>
                {unPlannedConsignmentPresent() ? (
                  <>
                    {getExpandCollapseView()}
                    {Object.keys(allRouteState.unplannedConsignmentsData).map((areaName: any, areaNameIndex: any) => (
                      <CustomUnPlannedRouteView
                        classes={classes}
                        areaName={areaName}
                        areaObj={allRouteState.unplannedConsignmentsData[areaName]}
                        areaNameIndex={areaNameIndex}
                        handleUnplannedAccordianToggle={handleUnplannedAccordianToggle}
                        handleUnplannedInnerOrderAccordianToggle={handleUnplannedInnerOrderAccordianToggle}
                        handleUnplannedOrderCheckboxToggle={handleUnplannedOrderCheckboxToggle}
                        handleUnplannedAreaCheckboxToggle={handleUnplannedAreaCheckboxToggle}
                        handleOpenCloseUnplannedRouteSlot={handleOpenCloseUnplannedRouteSlot}
                        handleUnplannedSlotCheckboxToggle={handleUnplannedSlotCheckboxToggle}
                      />
                    ))}
                  </>
                ) : (
                  <Grid className="noItemsGridStyle" container>
                    <Typography className="noItemsSpanStyle padding-2">{`${ALL_ROUTES_TEXT_CONTANT.NO_UNPLANNED_ITEMS}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomAllRoutesLeftSection;
