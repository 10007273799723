import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AppState } from '../../config/redux/reducers';
import { userLogout } from "../../Login/redux/loginSlice";
import { createPerformancePayload } from "../../mocks/performance/response.transforms";
import { fetchFiltersDropdownValues, fetchPerformanceParameters, fetchConsignmentsSlots, fetchRoutesSlots, fetchRouteParameters, fetchRouteGlobalParams } from "./redux/performanceSlice";
import HubWiseDashboard from "../HubwiseDashboard";
import ReconciliationDashboard from "../ReconciliationDashboard";
//Material UI Components
import { Grid, Typography, Button, Tab, Tabs, } from "@material-ui/core";
import { TabContext, TabPanel } from '@material-ui/lab';
import _ from "lodash";

//Style
import { useStyles } from "./performanceDashboard.styles";

//Common Components
import SelectBox from "../../common/SelectBox";
import DateRangePickerComponent from "../../common/DateRangeComponent/index";
import Loader from "../../common/Loader";
import CustomSnackbar from '../../common/CustomSnackbar';
import CustomDialog from "../../common/Dialog";

//Constants
import AppConstants from "../../constants";
import calendarIcon from "../../assets/icons/calendar-icon.svg";

//Components
import ConsignmentsDashboard from "./ConsignmentsDashboard";
import RoutesDashboard from "./RoutesDashboard";

interface DashboardProps {
}

const PerformanceDashboard = (props: DashboardProps) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { countryCode, userDetailsDropdowns, hubCode } = useSelector(
        (state: AppState) => state.common
    );
    const { filtersDropdowns, loading, error, errorCode } = useSelector(
        (state: AppState) => state.performance,
    );
    const { allSlots } = useSelector(
        (state: AppState) => state.performance, _.isEqual
    );
      const [tabValue, setTabValue] = useState(AppConstants.DASHBOARD_CONSTANTS.TAB_VALUES.DASHBOARD);

    const country = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value;
    const [category, setCategory] = useState(AppConstants.CATEGORY.value);
    const [orderType, setOrderType] = useState(AppConstants.DELIVERY_TYPE.value);
    const [slots, setSlots] = useState(() => [AppConstants.SELECT_ALL.value]);
    const [datePickerValue, setDatePickerValue] = useState({
        startDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
        endDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
    });
    const [isRouteView, setIsRouteView] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
    const snackbarMessage = useRef("");
    const [openDashboardHubSelectionPopup, setOpenDashboardHubSelectionPopup] = useState(true);
    const [dashboardHub, setDashboardHub] = useState(AppConstants.SELECT_NONE.value);

    const openSnackbarPopup = (msg: string, type: string) => {
        snackbarMessage.current = "No Data present for Requested Date";
        snackbarType.current = type;
        setOpenSnackbar(true);
    }

    const handleSnackbarExited = useCallback(
        () => {
            if (errorCode === AppConstants.DASHBOARD_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
                dispatch(
                    userLogout()
                );
            }
        },
        [dispatch, errorCode]
    );


    const handleConsignmentPerformanceParameters = useCallback(
        (params: any) => {
            const updatedParams = createPerformancePayload({
                ...params,
                countryCode: country,

            });
            dispatch(
                fetchPerformanceParameters({
                    params: updatedParams,
                }),
            );
        },
        [dispatch, country]
    );

    const handleCreateRoutePerformanceParameters = useCallback(
        (params: any) => {
            const updatedParams = createPerformancePayload({
                ...params,
                countryCode: country,
            });
            dispatch(
                fetchRouteParameters({
                    params: updatedParams,
                }),
            );
            dispatch(
                fetchRouteGlobalParams({
                    params: updatedParams,
                }),
            );
        },
        [dispatch, country]
    );



    const handleConsignmentsForDefaultValues = useCallback(
        () => {
            if (category === AppConstants.CATEGORY.value) {
                handleConsignmentPerformanceParameters({
                    slots: slots,
                    dateRange: datePickerValue,
                    category: category,
                    orderType: orderType,
                    hubCode: hubCode,
                });
            } else {
                handleCreateRoutePerformanceParameters({
                    slots: slots,
                    dateRange: datePickerValue,
                    category: category,
                    orderType: orderType,
                    hubCode: hubCode
                });
            }
        }, [slots, datePickerValue, category, orderType, hubCode, handleConsignmentPerformanceParameters, handleCreateRoutePerformanceParameters]
    );

    const handleCategoryValue = (() => {
      if (category === AppConstants.CATEGORY.value) {
            setIsRouteView(false);
        } else {
            setIsRouteView(true);
        }
    })

    const handleDateChange = ((dateRange: any) => {
        setDatePickerValue(dateRange);
        handleCategoryValue()
    });

    const handleOrderTypeChange = ((selectedOrderType: any) => {
        setOrderType(selectedOrderType);
        handleCategoryValue()
    });

    const handleDeliverySlotChange = ((selectedSlots: any) => {
        setSlots(selectedSlots);
        if (category === AppConstants.CATEGORY.value) {
            setIsRouteView(false);
            handleConsignmentPerformanceParameters({
                slots: selectedSlots,
                dateRange: datePickerValue,
                category: category,
                orderType: orderType,
                hubCode: hubCode
            });
        } else {
            setIsRouteView(true);
            handleCreateRoutePerformanceParameters({
                slots: selectedSlots,
                dateRange: datePickerValue,
                category: category,
                orderType: orderType,
                hubCode: hubCode
            });
        }
    });

    const handleCategoryChange = ((selectedCategory: any) => {
        setCategory(selectedCategory);
          if (selectedCategory === AppConstants.CATEGORY.value) {
            setIsRouteView(false);
        } else {
            setIsRouteView(true);
        }
    });

    const fetchAllFilters = useCallback(
        () => {
            dispatch(
                fetchFiltersDropdownValues()
            )
        },
        [dispatch]
    );

    const handleSnackbarClose = useCallback(
        () => {
            setOpenSnackbar(false);
        },
        [],
    );

    useEffect(() => {
        if (error) {
            openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
        }
    }, [error])

    useEffect(() => {
        if (category === AppConstants.CATEGORY.value) {
            handleCreateConsignmentsSlots({
                slots: slots,
                category: category,
                hubCode: hubCode,
                dateRange: datePickerValue,
                orderType: orderType,
            });
        } else {
            handleCreateRouteSlots({
                slots: slots,
                category: category,
                hubCode: hubCode,
                dateRange: datePickerValue,
                orderType: orderType,
            });
        }
       handleConsignmentsForDefaultValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datePickerValue, category, orderType, hubCode])

    const handleCreateRouteSlots = useCallback(
        (params: any) => {
            const updatedParams = createPerformancePayload({
                ...params,
                countryCode: country,
            });
            dispatch(
                fetchRoutesSlots({
                    params: updatedParams,
                }),
            );
        },
        [dispatch, country]
    );

    const handleCreateConsignmentsSlots = useCallback(
        (params: any) => {
            const updatedParams = createPerformancePayload({
                ...params,
                countryCode: country,
            });
            dispatch(
                fetchConsignmentsSlots({
                    params: updatedParams,
                }),
            );
        },
        [dispatch, country]
    );

      useEffect(() => {
        fetchAllFilters();
    }, [])

    const handleTabChange = useCallback(
        (event: React.ChangeEvent<{}>, value: string) => {
          setTabValue(value);
        },
        []
      )

    return (
        <div className={classes.root}>
            <TabContext value={tabValue}>
            <Tabs TabIndicatorProps={{ style: { backgroundColor: "#1F1F1F" } }} value={tabValue} aria-label="Consignments/Routes Navigation Tabs" style={{ paddingLeft: "20px", paddingTop: "8px", lineHeight: "24px", minHeight: 0, backgroundColor: "rgba(244, 244, 244, 0.6)", paddingBottom: "16px" }} onChange={handleTabChange} >
              <Tab label="Dashboard" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.DASHBOARD_CONSTANTS.TAB_VALUES.DASHBOARD} />
              <Tab label="Hubwise Dashboard" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.DASHBOARD_CONSTANTS.TAB_VALUES.HUB_WISE_DASHBOARS} />
              <Tab label="COD Recon" style={{ fontSize: "16px", fontWeight: 600 }} value={AppConstants.DASHBOARD_CONSTANTS.TAB_VALUES.COD_RECON} />

            </Tabs>
          
            <TabPanel className="tabValues" value={AppConstants.DASHBOARD_CONSTANTS.TAB_VALUES.DASHBOARD}>
            <Grid container justify="space-between">
                <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start", width: "50%" }}>
                    <Typography className={classes.title}>Dashboard</Typography>
                </Grid>
                <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid container spacing={2} style={{ flexWrap: "nowrap" }}>
                        <Grid item className={classes.tilesView}>
                            <SelectBox
                                fullWidth
                                className={classes.select}
                                value={category}
                                id="category"
                                label="Category"
                                items={AppConstants.CATEGORIES.category}
                                handleChange={handleCategoryChange}
                            ></SelectBox>
                        </Grid>
                        <Grid item className={classes.tilesView}>
                            <SelectBox
                                fullWidth
                                className={classes.select}
                                value={orderType}
                                id="deliveryType"
                                label="Delivery Type"
                                items={filtersDropdowns.orderTypeList[country] || []}
                                handleChange={handleOrderTypeChange}
                            ></SelectBox>
                        </Grid>
                        <Grid item className={classes.tilesView}>
                            <SelectBox
                                fullWidth
                                className={classes.select}
                                value={slots}
                                id="slot"
                                label="Slots"
                                multiple
                                items={allSlots || []}
                                handleChange={handleDeliverySlotChange}
                            ></SelectBox>
                        </Grid>
                        <Grid item className={classes.tilesView}>
                            <DateRangePickerComponent
                                fullWidth
                                keyId="create-route-date-picker"
                                label="Date"
                                className={classes.icon}
                                value={datePickerValue}
                                isTextField={true}
                                iconSrc={calendarIcon}
                                autoUpdateInput={false}
                                autoApply={true}
                                maxSpan={{
                                    day: "0",
                                }}
                                showDropdowns={true}
                                linkedCalendars={true}
                                locale={{
                                    format: AppConstants.DATE_FORMAT,
                                }}
                                updateDateRange={handleDateChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <Loader></Loader>}
            {!isRouteView ? (
                <ConsignmentsDashboard />
            ) : (
                    <RoutesDashboard />
                )}
            < CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
            </TabPanel>
            <TabPanel className="tabValues" value={AppConstants.DASHBOARD_CONSTANTS.TAB_VALUES.HUB_WISE_DASHBOARS}>
            <HubWiseDashboard />
            </TabPanel>
            <TabPanel className="tabValues" value={AppConstants.DASHBOARD_CONSTANTS.TAB_VALUES.COD_RECON}>
            <ReconciliationDashboard />
            </TabPanel>
        </TabContext>
        </div >
    );
}

export default PerformanceDashboard;