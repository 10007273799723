import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const customHeaderUseStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(1, 0),
        alignItems: "center"
      },
      newDashboard: {
        padding: theme.spacing(1, 0),
        alignItems: "center"
      },
      total: {
        alignSelf: "flex-end",
        '& span': {
          verticalAlign: "middle",
          fontSize: 14,
          fontWeight: 400,
          '&.separator': {
            padding: theme.spacing(0, 0.75)
          },
          '&.count': {
            fontWeight: 600,
            fontSize: 20,
          }
        }

      },
      buttonContainer: {
        marginLeft: "auto",
        '& .clearAllFilterBtn': {
          fontSize: 14,
          fontWeight: 600,
          marginRight: theme.spacing(2),
          fontFamily: 'Montserrat',
        }
      },
      button: {
        margin: theme.spacing(0.5),
        '&.MuiButton-root': {
          backgroundColor: "#DD2326",
          '&.Mui-disabled': {
            backgroundColor: "#DD2326",
            opacity: '0.38'
          },
          '&:hover': {
            backgroundColor: "#DD2326"
          }
        },
        '&:last-child': {
          marginRight: theme.spacing(0)
        }
      },
      customBadge:{
        backgroundColor: COLORS.AMBER,
        color:'white',
        margin:12
      },
      colSettings: {
        float: "right",
        marginTop: -3,
        '&.MuiButton-root': {
          padding: "6px 0px 6px 8px",
          minWidth: "40px",
        }
      },
      buttonGridSyle: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
      downloadIconStyle: {
        cursor: 'pointer',
        margin: theme.spacing('auto', 1),
        padding: theme.spacing(1),
        width: '40px',
        height: '40px',
        border: `1px solid ${COLORS.MYSTIC_GREY}`,
        borderRadius: theme.spacing(1.5),
        fill: COLORS.WHITE
      }
    })
);