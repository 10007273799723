const TrackingConstants = {
    TRACKING_CONSTANTS: {
        loadMore: {
            name: 'loadMore',
            disabled: false,
            pageSize: 20
        },
        defaultFiltersObj: {
            hubCode: {
                key: "hubCode",
                value: [],
                fieldName: 'c.hubCode'
            },
            pageNumber: 0,
            status: {
                key: "status",
                value: [],
                fieldName: 'c.status'
            },
            dateRange: {
                key: "dateRange",
                value: {
                    startDate: new Date(),
                    endDate: new Date()
                }
            },
        },
        searchAttribute: {
            key: "routeId",
            value: "",
            fieldName: 'c.routeId'
        },
        RESPONSE_CONSTANTS: {
            ERROR_CODES: {
                SESSION_TIMEOUT: "UM100",
                UNAUTHORISED: "UM101",
            }
        },
    }
}
export default TrackingConstants;