import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchOrdersFilterChipsDisplayCount, fetchPlanningOrderListByFilter, fetchDynamicFiltersOrdersDropdownValues, exportToExcel } from "./redux/clickAndCollectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createFilterPayload,
  getCNCConsignmentStatusesField,
  lastMilePlanningOrdersDataTransform,
  createPayloadForExcelExport,
} from "../mocks/clickAndCollect/response.transforms";
import { AppState } from "../config/redux/reducers";
import { useStyles } from "./clickandcollect.styles";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import CustomSnackbar from "../common/CustomSnackbar";
import { userLogout } from "../Login/redux/loginSlice";
import _ from "lodash";
import { CellParams, RowParams, SortModel } from "../common/GridWrapper/DataTable";
import { Grid, Typography, IconButton, SvgIcon } from "@material-ui/core";
import { useHistory } from "react-router";
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import CNCConsignmentDetails from "./CNCConsignmentDetails";
import { ReactComponent as checkIcon } from '../assets/icons/circle-checked-icon.svg';

interface ClickAndCollectProps {
  match?: any;
  location?: any;
}

const ClickAndCollect = (props: ClickAndCollectProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { location } = props;
  const componentClasses = useStyles();
  const { ordersData, tableLoading, ordersFiltersDropdowns, cncFilterChipsDisplayCount, loading, error, errorCode, cncReplanningSuccess, excelLoading, exportError } = useSelector(
    (state: AppState) => state.clickAndCollect
  );
  const { countryCode, hubCode } = useSelector(
    (state: AppState) => state.common
  );

  const onLoadRef = useRef(true);
  const [ordersListToShow, setOrdersListToShow] = useState([]);
  const [ordersSearchValue, setOrdersSearchValue] = useState("");
  const [isOrderSearched, setIsOrderSearched] = useState(false);
  const [isDisplayCount, setIsDisplayCount] = useState(false);
  const [activeOrdersFilterChip, setActiveOrdersFilterChip] = useState(AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);

  const [isTabChange, setIsTabChange] = useState(false);

  const [cncConsignmentData, setCncConsignmentData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA, HeaderData: AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'consignmentId') {
        newHeader.renderCell = (params: CellParams) => (
          <Typography className="idLink">{params.value}</Typography>
        )
      }
      if (newHeader.field === 'deliveryStatus') {
        newHeader.renderCell = (params: any) => (
          params.value ?
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={['tag', getCNCConsignmentStatusesField('color', params.value),].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
            : <></>
        );
      }
      return newHeader;
    }),
    defaultFiltersObj: {
      ...AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj, dateRange: {
        ...AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange, value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT)
        }
      }
    }
  });
  const [ordersActiveFilters, setOrdersActiveFilters] = useState({ ...ordersTableWrapperData.defaultFiltersObj });

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const [renderExcelProgress, setRenderExcelProgress] = useState(false);
  const [openCNCConsignmentDetailsPopup, setOpenCNCConsignmentDetailsPopup] = useState(false);

  const fetchDynamicFilters = useCallback(
    (filters: any, isRoute?: boolean) => {
      dispatch(
        fetchDynamicFiltersOrdersDropdownValues({
          filters: filters
        })
      )
    },
    [dispatch]
  );

  const handleLoadMoreDisableState = useCallback(
    () => {
      let loadMoreState = ordersTableWrapperData.loadMore;
      loadMoreState.disabled = ordersData.totalPages ? ordersData.pageNumber + 1 >= ordersData.totalPages : true;
      setOrdersTableWrapperData({ ...ordersTableWrapperData, loadMore: loadMoreState });
    },
    [ordersTableWrapperData, ordersData]
  );

  const getFilterChipsCount = useCallback(
    (payload: any) => {
      setIsDisplayCount(false);
      let updatedPayload = _.cloneDeep(payload);
      updatedPayload.attributes = updatedPayload.attributes.filter((attr: any) => attr.key !== 'c.metadata.displayRouteStatus');
      dispatch(
        fetchOrdersFilterChipsDisplayCount({
          filters: updatedPayload
        })
      );
      setIsDisplayCount(true);
    },
    [dispatch],
  )

  const ordersSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    const activeFilters = _.cloneDeep(filters);
    onLoadRef.current = false;
    setOrdersSearchValue(updatedSearchValue);
    setIsOrderSearched(false);
    if (updatedSearchValue) {
      setIsOrderSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey]
          }
          ordersFilterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const ordersFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
        if (!isChipFilterClicked) {
          if (activeOrdersFilterChip === AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP) {
            delete payloadFilters.displayRouteStatus;
          } else {
            payloadFilters.displayRouteStatus.value = [activeOrdersFilterChip];
          }
        }
        const payload = createFilterPayload(payloadFilters, ordersData, isLoadMore);
        if (!isLoadMore) {
          fetchDynamicFilters(payload);
        }
        if(isLoadMore){
          setIsTabChange(false);
        }
        getFilterChipsCount(payload);
        dispatch(
          fetchPlanningOrderListByFilter({
            filters: payload
          })
        );
        setOrdersActiveFilters(filters);
      }
      setRenderExcelProgress(false);
    },
    [countryCode, ordersData, ordersTableWrapperData, activeOrdersFilterChip, fetchDynamicFilters, dispatch, getFilterChipsCount],
  );

  const handleExcelExport = useCallback(
    (filters: any) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;

        if (activeOrdersFilterChip === AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP) {
          delete payloadFilters.displayRouteStatus;
        } else {
          payloadFilters.displayRouteStatus.value = [activeOrdersFilterChip];
        }
        const payload = createPayloadForExcelExport(payloadFilters, ordersData, false, false);
        dispatch(
          exportToExcel({
            payload: payload
          })
        );

      }
      setRenderExcelProgress(true);
    },
    [dispatch, ordersData, countryCode, activeOrdersFilterChip]
  )

  const handleOrdersHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case 'excel':
            handleExcelExport(ordersActiveFilters);
            break;
          default:
            break;
        }
      }
    },
    [ordersActiveFilters, handleExcelExport]
  );
  const handleConsignmentCellClick = useCallback(
    (params: CellParams) => {
      if (params.field === 'consignmentId') {
        const selectedRow: any = params.rowData;
        const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
        setCncConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
        setOpenCNCConsignmentDetailsPopup(true);
      }
    },
    [ordersData],
  );

  const handleRowClick = useCallback(
    (params: RowParams) => {
      const selectedRow: any = params.data;
      const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
      setCncConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
      setOpenCNCConsignmentDetailsPopup(true);
    },
    [ordersData],
  );

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
    },
    [],
  );

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSnackbarExited = useCallback(
    () => {
      if (errorCode === AppConstants.PLANNING_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
    },
    [dispatch, errorCode]
  );

  const handleChipFilter = useCallback(
    (chip: any, activeFilters: any) => {
      let filters = { ...activeFilters };
      setIsTabChange(true);
      onLoadRef.current = false;
      filters = chip.code === AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP ? { ...filters, displayRouteStatus: null } : { ...filters, displayRouteStatus: { ...AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.DISPLAY_ROUTE_STATUS_FILTER_OBJ, value: [chip.code] } }
      setIsOrderSearched(false);
      if (chip.code !== activeOrdersFilterChip) {
        setActiveOrdersFilterChip(chip.code);
        ordersFilterCallback(filters, false, true);
      }
      setRenderExcelProgress(false);
    },
    [ordersFilterCallback, activeOrdersFilterChip],
  )

  const getFilterChips = () => {
    const filterChips = AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.FILTER_CHIPS;
    return filterChips.map((chip: any, index: number) => (
      <Grid key={`chipFilterItem-${index}`} item className={`chipFilterItem ${index === 0 ? 'firstItem' : ''} ${activeOrdersFilterChip === chip.code ? 'active' : ''}`} onClick={() => handleChipFilter(chip, ordersActiveFilters)}>
        <Grid container className="chipContainer">
          <Grid item className="chipItem name">
            <Typography>{chip.value}</Typography>
          </Grid>
          <Grid item className="chipItem count">
            <Typography>{chip.code === AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP ? cncFilterChipsDisplayCount.consignmentCount[chip.code] : cncFilterChipsDisplayCount.consignmentCount[chip.code]}</Typography>
          </Grid>
        </Grid>
      </Grid>
    ))
  };

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    history.replace({ pathname: location.pathname, state: {} })
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    onLoadRef.current = true;
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    if (isOrderSearched && isDisplayCount) {
      const chips = Object.keys(cncFilterChipsDisplayCount.consignmentCount).filter((key: string) => cncFilterChipsDisplayCount.consignmentCount[key] > 0);
      let selectedChip;
      let selectedChipValue;
      if (chips.length === 2) {
        selectedChip = chips.filter((key: string) => key !== AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      } else {
        selectedChip = chips.filter((key: string) => key === AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      }
      handleChipFilter({ code: chips.length ? selectedChipValue : activeOrdersFilterChip }, ordersActiveFilters)
    } else {
      setOrdersListToShow(lastMilePlanningOrdersDataTransform(ordersData) as any);
      handleLoadMoreDisableState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cncFilterChipsDisplayCount, ordersData]);

  useEffect(() => {
    const ordersHeaderData = ordersTableWrapperData.HeaderData.map((header: any) => {
      if (header.filterObj) {
        if (header.filterObj.fieldName === 'hub') {
          header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
        }
        if (header.filterObj.fieldName === 'hubName') {
          header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
        }
        if (header.filterObj.fieldName === 'deliveryStatus') {
          header.filterObj.items = ordersFiltersDropdowns.statusList || [];
        }
        if (header.filterObj.fieldName === 'orderType') {
          header.filterObj.items = ordersFiltersDropdowns.typeList || [];
        }
        if (header.filterObj.fieldName === 'collectionHubCode') {
          header.filterObj.items = ordersFiltersDropdowns.collectionHubCodeList || [];
        }
        if (header.filterObj.fieldName === 'collectionHubName') {
          header.filterObj.items = ordersFiltersDropdowns.collectionHubNameList || [];
        }
        if (header.filterObj.fieldName === 'deliverySlot') {
          header.filterObj.items = ordersFiltersDropdowns.collectionSlotList || [];
        }
        if (header.filterObj.fieldName === 'cancellationReason') {
          header.filterObj.items = ordersFiltersDropdowns.cancellationReasonList || [];
        }
      }
      return header;
    });
    const hubsToAdd = hubCode.map((hub: string) => ({
      name: hub,
      value: hub
    }));
    let updatedFilters: any = {};
    setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: ordersHeaderData });
    setActiveOrdersFilterChip(AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP);
    setIsOrderSearched(false);
    updatedFilters = { ...ordersActiveFilters, hub: { ...ordersActiveFilters.hub, value: hubsToAdd } };
    if (!onLoadRef.current) {
      ordersFilterCallback(updatedFilters);
    }

    onLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubCode]);

  useEffect(() => {
    if (ordersTableWrapperData && ordersTableWrapperData.HeaderData) {
      let headerData = ordersTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === 'hub') {
            header.filterObj.items = ordersFiltersDropdowns.hubCodeList || [];
          }
          if (header.filterObj.fieldName === 'hubName') {
            header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === 'deliveryStatus') {
            header.filterObj.items = ordersFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === 'orderType') {
            header.filterObj.items = ordersFiltersDropdowns.typeList || [];
          }
          if (header.filterObj.fieldName === 'collectionHubCode') {
            header.filterObj.items = ordersFiltersDropdowns.collectionHubCodeList || [];
          }
          if (header.filterObj.fieldName === 'collectionHubName') {
            header.filterObj.items = ordersFiltersDropdowns.collectionHubNameList || [];
          }
          if (header.filterObj.fieldName === 'deliverySlot') {
            header.filterObj.items = ordersFiltersDropdowns.collectionSlotList || [];
          }
          if (header.filterObj.fieldName === 'cancellationReason') {
            header.filterObj.items = ordersFiltersDropdowns.cancellationReasonList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: headerData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersFiltersDropdowns]);

  const handleProgressClose = useCallback(
    () => {
      setRenderExcelProgress(false);
    },
    [],
  );

  const closeCNCConsignmentDetailsPopup = useCallback(
    () => {
      setOpenCNCConsignmentDetailsPopup(false);
    },
    [],
  );

  useEffect(() => {
    if (cncReplanningSuccess) {
      openSnackbarPopup(AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.REPLANNING_CONSIGNMENT_SUCCESS_MSG, AppConstants.SNACKBAR.TYPES.SUCCESS);
      setIsOrderSearched(false);
      ordersFilterCallback(ordersActiveFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cncReplanningSuccess])

  return (
    <div className={componentClasses.root}>
      {loading && <Loader></Loader>}
      <Grid className={componentClasses.container} container>
        <Grid className="planningItem content" item>
          <Grid container className={componentClasses.tabPanelContainer}>
            <Grid item className="panelItem chipFilter">
              <Grid container className="chipsContainer">
                {
                  getFilterChips()
                }
              </Grid>
            </Grid>
            <Grid item className="panelItem gridWrapper">
              <GridWrapper
                showHeader={false}
                checkboxSelection={false}
                headerData={ordersTableWrapperData.HeaderData}
                rowData={ordersListToShow}
                isTabChange={isTabChange}
                loading={loading ? false : tableLoading}
                searchTypes={ordersTableWrapperData.SEARCH_TYPES}
                defaultFilter={ordersTableWrapperData.defaultFiltersObj}
                activeFilter={ordersActiveFilters}
                headerButtons={ordersTableWrapperData.headerButtons}
                title={ordersTableWrapperData.title}
                disableSelectionOnClick={true}
                sortModel={ordersTableWrapperData.sortModel as SortModel}
                searchValue={ordersSearchValue}
                loadMore={{ ...ordersTableWrapperData.loadMore, rowCount: ordersData.totalElements || 0 }}
                headerBtnClick={handleOrdersHeaderButtonClick}
                searchCallback={ordersSearchFilterHandler}
                onCellClick={handleConsignmentCellClick}
                filterGrid={ordersFilterCallback}
                onRowClick={handleRowClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <CNCConsignmentDetails open={openCNCConsignmentDetailsPopup} consignment={cncConsignmentData} closePopup={closeCNCConsignmentDetailsPopup}></CNCConsignmentDetails>
        {!error && renderExcelProgress ? (
          <Grid container className={componentClasses.excelDownloadStatus}>
            {excelLoading ? (
              <Grid item>
                <Typography className={componentClasses.excelDownloadStatusText}>
                  Your files are getting downloaded
                </Typography>
              </Grid>) : (
                <Grid item>
                  <Typography className={componentClasses.excelDownloadStatusText}>
                    Your files have been downloaded
                  </Typography>
                </Grid>
              )}
            {excelLoading ? <Loader></Loader> :
              <Grid item style={{ display: "flex" }}>
                <SvgIcon className={componentClasses.checkedIconStyle} component={checkIcon} />
                <IconButton aria-label="close" className={componentClasses.close} onClick={handleProgressClose}>
                  <CloseIcon className={componentClasses.closeIcon} />
                </IconButton>
              </Grid>
            }
          </Grid>) : (
            exportError === "INTERNAL_SERVER_ERROR" && renderExcelProgress ? (
              <Grid container className={componentClasses.excelDownloadError}>
                <Grid item>
                  <Typography className={componentClasses.excelDownloadStatusText}>
                    Error Downloading the file. Try Again!!
              </Typography>
                </Grid>
                <Grid item style={{ display: "flex" }}>
                  <IconButton aria-label="close" className={componentClasses.close} onClick={handleProgressClose}>
                    <CloseIcon className={componentClasses.closeIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null)}
        <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
      </Grid>

    </div>
  );
};

export default ClickAndCollect;