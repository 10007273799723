import moment from "moment";
import AppConstants from "../../constants";
import { getUniqueValues, enumerateDaysBetweenDates } from "../../utils/helpers.utils";
export interface Address {
    area: string;
    landmark: string;
    building: string;
    apartment: string;
    zoneNumber: string;
    makani: string;
    streetNumber: string;
    streetName: string;
    city: string;
    postalCode: string;
    countryName: string;
    countryIsoCode: string;
    longitude: string;
    latitude: string;
}
export const createPayloadForSlots = (payload: any, data?: any, isLoadMore?: boolean) => {
    payload.attributes = [];
    if (payload.hubCode) {
        if (payload.hubCode.value) {
            let hubValue = payload.hubCode.value.map((item: any) => item.value);
            let hubNameValue = [];
            if (payload.hubName && payload.hubName.value.length) {
                hubNameValue = payload.hubName.value.map((item: any) => item.value);
            }
            hubValue = hubValue.concat(hubNameValue);
            const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
            const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
            if (selectAllIdx > -1) {
                hubValue = [];
            }
            if (selectNoneIdx > -1) {
                hubValue = [];
            }
            payload.attributes.push({
                key: payload.hubCode.fieldName,
                value: getUniqueValues(hubValue)
            })
        } else {
            payload.attributes.push({
                key: payload.hubCode.fieldName,
                value: []
            });
        }
    }
    if (isLoadMore && data) {
        payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
    }
    let dateRange = [];
    dateRange.push({
        startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
        endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
    });
    payload.dateRange = dateRange;
    return {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        attributes: payload.attributes,
        dateRange: payload.dateRange,
        isDateRange: true,
        pageSize: AppConstants.TRACKING_CONSTANTS.loadMore.pageSize,
        pageNumber: payload.pageNumber
    };
};

export const createRouteDetailsPayload = (payload: any, data?: any, isLoadMore?: boolean) => {
    payload.attributes = [];
    if (payload.hubCode) {
        if (payload.hubCode.value) {
            let hubValue = payload.hubCode.value.map((item: any) => item.value);
            let hubNameValue = [];
            if (payload.hubName && payload.hubName.value.length) {
                hubNameValue = payload.hubName.value.map((item: any) => item.value);
            }
            hubValue = hubValue.concat(hubNameValue);
            const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
            const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
            if (selectAllIdx > -1) {
                hubValue = [];
            }
            if (selectNoneIdx > -1) {
                hubValue = [];
            }
            payload.attributes.push({
                key: payload.hubCode.fieldName,
                value: getUniqueValues(hubValue)
            })
        } else {
            payload.attributes.push({
                key: payload.hubCode.fieldName,
                value: []
            });
        }
    }
    if (payload.status) {

        const selectAllIdx = payload.status.value.indexOf(AppConstants.SELECT_ALL.value);
        const selectNoneIdx = payload.status.value.indexOf(AppConstants.SELECT_NONE.value);
        if (selectAllIdx > -1) {
            payload.status.value = [];
        }
        if (selectNoneIdx > -1) {
            payload.status.value = [];
        }
        payload.attributes.push({
            key: payload.status.fieldName,
            value: payload.status.value || []
        });
    }
    let dateRange: any = [];
    const selectAllIdx = payload.slots && payload.slots.indexOf(AppConstants.SELECT_ALL.value);
    if (payload.dateRange && payload.dateRange.value) {
        if (selectAllIdx > -1 || !(payload.slots && payload.slots.length)) {
            payload.isDateRange = true;
            dateRange.push({
                startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
            });
        }
        else {
            if (payload.slots.length && !payload.slots.includes(AppConstants.SELECT_NONE.value)) {
                payload.isDateRange = false;
                const allDates = enumerateDaysBetweenDates(payload.dateRange.value.startDate, payload.dateRange.value.endDate);
                payload.slots.forEach((slot: any) => {
                    const splitSlot = slot.split('-');
                    const startSlot = splitSlot[0] && splitSlot[0].split(':');
                    const endSlot = splitSlot[1] && splitSlot[1].split(':');
                    allDates.map((item) => {
                        return dateRange.push({
                            startTime: moment(item, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                            endTime: moment(item, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                        })
                    })
                })
            }
            else {
                payload.isDateRange = false;
                dateRange.push({
                    startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
                    endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
                });
            }
        }
    }
    payload.dateRange = dateRange;
    if (isLoadMore && data) {
        payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
    }
    if (payload.searchAttribute && payload.searchAttribute.value !== "") {
        payload.searchAttribute = {
            key: payload.searchAttribute.fieldName,
            value: payload.searchAttribute.value || ""
        };
    }
    let payloadWithoutSeachAttribute: any = {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        attributes: payload.attributes,
        dateRange: payload.dateRange,
        isDateRange: payload.isDateRange,
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber
    }
    let payloadWithSeachAttribute: any = {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        attributes: payload.attributes,
        dateRange: payload.dateRange,
        isDateRange: payload.isDateRange,
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber,
        searchAttribute: payload.searchAttribute
    }
    if (data.continuationToken && payload.pageNumber) {
        payloadWithoutSeachAttribute.requestContinuationToken = data.continuationToken;
        payloadWithSeachAttribute.requestContinuationToken = data.continuationToken;
    }
    if (payload.searchAttribute && payload.searchAttribute.value !== "") {
        return payloadWithSeachAttribute
    } else {
        return payloadWithoutSeachAttribute
    }
};

export const getAddressInfo = (addressObj: Address) => {
    return addressObj ? `${addressObj.building ? addressObj.building + ', ' : ""}${addressObj.apartment ? addressObj.apartment + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber + ', ' : ""}${addressObj.makani ? addressObj.makani + ', ' : ""}${addressObj.streetNumber ? addressObj.streetNumber + ', ' : ""}${addressObj.streetName ? addressObj.streetName + ', ' : ""}${addressObj.area ? addressObj.area + ', ' : ""}${addressObj.landmark ? addressObj.landmark + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber : ""}
    ${addressObj.city ? addressObj.city + ', ' : ""}${addressObj.postalCode ? addressObj.postalCode + ', ' : ""}${addressObj.countryName ? addressObj.countryName + ', ' : ""}${addressObj.countryIsoCode ? addressObj.countryIsoCode + ', ' : ""}${addressObj.longitude ? addressObj.longitude + ', ' : ""}${addressObj.latitude ? addressObj.latitude : ""}` : "";
}