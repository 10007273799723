import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      background: COLORS.WHITE
    },
    barChart: {
      width: "100%",
      '& .mixed-chart': {
        overflowX: "auto",
        overflowY: "hidden",
        maxWidth: "1313px"
      },
      '& svg .apexcharts-datalabels': {
        transform: "translate(0px, -8px)"
      },
      '& .apexcharts-legend-series': {
        paddingRight: "12px",
        paddingTop: 8
      },
      '& .apexcharts-legend-text': {
        fontSize: 9,
        fontWeight: 500,
        lineHeight: "11px",
        color: COLORS.BLACK
      }
    },

  })
);