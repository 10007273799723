
import React, { PropsWithChildren, useEffect } from 'react'
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import {useSelector } from 'react-redux';
import { AppState } from './config/redux/reducers';
import { growthBookKey, growthBookURL } from "./config/Api.config";

export const growthbook = new GrowthBook({
  apiHost: growthBookURL,
  clientKey: growthBookKey,
  enableDevMode: true,
});



const GrowthBookProviderWrapper= ({ children }: PropsWithChildren<{}>) => {
  const { countryCode, hubCode } = useSelector(
    (state: AppState) => state.common
  );

  const { username } = useSelector(
    (state: AppState) => state.userLogin
  );

  useEffect(() => {
    growthbook.loadFeatures();
  },[])

    useEffect(() => {
      const userId = username || sessionStorage.getItem('username')
      if(userId){
         growthbook.setAttributes({
            "id": userId,
            "platform": "Web",
            "country": countryCode,
            "loggedIn": true,
            "username": userId,
            "language": "en",
            "preview": true,
            "role": JSON.parse(sessionStorage.getItem('role') || '[]')?.[0]?.code.toLowerCase(),
            "hubs":hubCode
      })
    }
    },[username])
  
    return (
      <GrowthBookProvider growthbook={growthbook}>
        {children}
      </GrowthBookProvider>
    )
  }
  export {useFeatureIsOn} from '@growthbook/growthbook-react'
  export {GrowthBookProviderWrapper}
