import React, { useCallback, forwardRef, ReactElement, Ref, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { fetchConsignmentHistory, closeDialog, exportInvoicePdf, fetchOrderLogs, getAddressStatus, fetchStatusOnClick, resetStatusRefreshButton, fetchDeliveryReasons, submitOrderComments, fetchBundleProducts, resetBundleList } from './redux/expressSlice';
import mobileLogoIcon from './../assets/icons/mobileLogo.svg';
import emailLogoIcon from './../assets/icons/emailLogo.svg';
import { AppState } from "../config/redux/reducers";
import { ReactComponent as activeTimelineDot } from './../assets/icons/active-timeline-dot.svg';
import { ReactComponent as errorTimelineDot } from './../assets/icons/timeline-dot-error.svg';
import { ReactComponent as timelineDot } from './../assets/icons/timeline-dot.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';
import { ReactComponent as zoomInIcon } from './../assets/icons/zoom-in.svg';
import { ReactComponent as zoomOutIcon } from './../assets/icons/zoom-out.svg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ReactComponent as editIconNew } from './../assets/icons/edit-icon-new.svg';
import { ReactComponent as refreshIconNew } from './../assets/icons/refresh-icon-new.svg';
import { ReactComponent as refreshIconGrey } from './../assets/icons/refresh-icon-grey.svg';
import { ReactComponent as downloadLogsIcon } from './../assets/icons/download-logs-button.svg';
import verifiedIcon from './../assets/icons/verified-icon.svg';
import unverifiedIcon from './../assets/icons/unverified-icon.svg';
import pendingIcon from './../assets/icons/pending-icon.svg';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as crateStageLocationIcon } from './../assets/icons/crate-staging-location-icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import closeButton from "./../assets/icons/close-circle-icon.svg";
import locationIcon from './../assets/icons/map-pin.svg';
import { useStyles } from './ExpressOrderDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import RcViewer from '@hanyk/rc-viewer';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, SvgIcon, Collapse, Link, Divider, Tab, Tabs, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import { COLORS } from '../config/theme/baseTheme';
import MapContainer from '../common/GoogleMapContainer';
import { Document, Page } from 'react-pdf';
import Modal from '@material-ui/core/Modal';
import Loader from "../common/Loader";
import AppConstants from "../constants";
import { getAddressInfo } from 'mocks/routePlanning/response.transforms';
import { fixDecimals, localTimeString, displayUnitGrams, isCardOnDelivery, isExisty, getObjectKeys, isArrayNonEmpty, isModifiedBySystem, isNonEmptyObject } from 'utils/helpers.utils';
import moment from 'moment';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import clsx from "clsx";
import { pdfjs } from 'react-pdf';
import { TabContext, TabPanel } from '@material-ui/lab';
import ExpressOrderEdit from './ExpressOrderEdit';
import { createPayloadToMarkAddressUnverified, getDeliveryExecutionStatusField, createPayloadToSubmitOrderComments, getCratesFromProducts } from './../mocks/express/response.transforms';
import { ADDRESS_STATUS } from '../constants/CommonConstants';
import {Countdown} from '../utils/countdownTimer'
import { EXPRESS_DELIVERY_TYPE } from 'constants/ExpressConstants';
import {TIMER_CONST} from "../constants/CommonConstants";
import {getDateOrTimeInReqFormat} from '../utils/dateUtils';
import SelectBox from "../common/SelectBox";
import { TextConstants } from "constants/TextConstants";
import TextBox from "../common/TextBox";
import CustomBarcode from 'common/CustomBarcode';
import BundleProducts from '../BundleProducts/index'
import {getCrateDetails} from '../utils/business/crate'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
interface ExpressOrderDetailsProps {
  open: boolean;
  consignment: any;
  closePopup: () => void;
  handleTimerInstances?:(id:any) => void;
  timerStarted?: boolean
  setShowTime?: (showTime: boolean) => void;
  handleDownloadLogs: (consignment:any) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ExpressOrderDetails = (props: ExpressOrderDetailsProps) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, consignmentStatuses, invoiceConsignment, invoiceData, invoiceUrl, invoiceMessage, displayInvoice, orderLogs, consignmentDataAfterReplanning, productsAfterUpdateLineItem, revisedAmountAfterCalculation, consignmentDataAfterAddressUpdate, isPolled, orderCommentsList, orderCommentsAfterSubmit, bundleProductList, crateInfo, productList, bundleProductAfterUpdateLineItem } = useSelector(
    (state: AppState) => state.expressDashboard
  )
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const {isLastMileViewer, isOnlyExpLastMileViewer } = useSelector(
    (state: AppState) => state.userLogin
  );
  const [remainingTime, setRemainingTime] = useState(TIMER_CONST.pullTimeForRefresh);
  const [timersValue, setTimersValue] = useState({});
  const { open, consignment, closePopup, handleTimerInstances,timerStarted, setShowTime, handleDownloadLogs } = props;
  const [toggleCustMap, setToggleCustMap] = useState(false);
  const [toggleSellerMap, setToggleSellerMap] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [showOriginalName, setShowOriginalName] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [scale, setScale] = useState(1);
  const [scaleNumber, setScaleNumber] = useState(100);
  const [tabValue, setTabValue] = useState(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_DETAIL);
  const [openExpressOrderEditPopup, setOpenExpressOrderEditPopup] = useState(false);
  const [addressVerified, setAddressVerified] = useState(false);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [isCratesAvailable, setIsCratesAvailable] = useState(false);
  const [items, setItems] = useState(consignment?.products)
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(0)
  const [crateDetails , setCrateDetails] = useState(getCrateDetails(consignment))
  const [bundleProducts, setBundleProducts] = useState(bundleProductList)

  let deliveryInformation: any = {};
  let reason: any = {};
  let deliveryAttemptReasons: any={}
  let metadata: any = {};
  let custInfo: any = {
    address: {}
  };
  let custGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let sellerGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let posInfo: any = {
    address: {}
  };
  //let items: any = [];
  let historyItems: any = [];
  let driver: any = [];
  let crates: any = [];
  let deliveryDate = "";
  let deliverySlot = "";
  let hubName = "";
  let isCheckboxDisabled = false;
  let deliveryInformationAfterAddressUpdate: any = {};
  let custInfoAfterAddressUpdate: any = {
    address: {}
  }
  let addressStatusAfterUpdate = ""
  let metadataAfterReplanning: any = {};

  const handleOrderStatusHistory = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchConsignmentHistory({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  if (consignment) {
    deliveryInformation = consignment.deliveryInformation;
    metadata = consignment.metadata;
    custInfo = deliveryInformation && deliveryInformation.customerContactInfo;
    reason = consignment.reason;
    const orderLevelCrateInfo = consignment?.associatedCrates
    const productsList = consignment?.products
    crates =  orderLevelCrateInfo?.length ? orderLevelCrateInfo : getCratesFromProducts(productsList);
    const deliveryAttempts = consignment.deliveryAttempts ? consignment.deliveryAttempts : 1;
    const deliveryAttemptsLength =  consignment?.deliveryAttemptReasons && Object.keys(consignment?.deliveryAttemptReasons).length-1;
    deliveryAttemptReasons =
      consignment.deliveryAttemptReasons &&
      (consignment.deliveryAttemptReasons.hasOwnProperty(deliveryAttempts)
        ? consignment.deliveryAttemptReasons[deliveryAttempts]
        : Object.values(consignment.deliveryAttemptReasons)[deliveryAttemptsLength]);
   
    if (custInfo && custInfo.address) {
      custGeoLocation.name = custInfo.name;
      custGeoLocation.location.lat = parseFloat(custInfo.address.latitude) || 0;
      custGeoLocation.location.lng = parseFloat(custInfo.address.longitude) || 0;
    }
    if (consignment.posInformation) {
      posInfo = consignment.posInformation.contactInfo;
      hubName = consignment.posInformation.storeName || consignment.posInformation.store;
    }
    if (deliveryInformation && deliveryInformation.deliverySlot) {
      const startTime = new Date(deliveryInformation.deliverySlot.startTime);
      const endTime = new Date(deliveryInformation.deliverySlot.endTime);
      deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.DATE_FORMAT);
      deliverySlot = `${localTimeString(startTime)} - ${localTimeString(endTime)}`
    }
    if (posInfo && posInfo.address) {
      sellerGeoLocation.name = hubName;
      sellerGeoLocation.location.lat = parseFloat(posInfo.address.latitude) || 0;
      sellerGeoLocation.location.lng = parseFloat(posInfo.address.longitude) || 0;
    }
    if (consignmentStatuses) {
      let statuses = consignmentStatuses.slice();
      historyItems = statuses;
    }
     // setItems(consignment?.products)
    //items = consignment.products;
    if (productsAfterUpdateLineItem && productsAfterUpdateLineItem.length > 0) {
      //setItems(productsAfterUpdateLineItem)
      //items = productsAfterUpdateLineItem; need to work on this after rejection
    }
    driver = deliveryInformation && deliveryInformation.driver ? deliveryInformation.driver : {};
    metadataAfterReplanning = consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata;
  }

  if (consignmentDataAfterAddressUpdate) {
    deliveryInformationAfterAddressUpdate = consignmentDataAfterAddressUpdate.deliveryInformation;
    custInfoAfterAddressUpdate = deliveryInformationAfterAddressUpdate && deliveryInformationAfterAddressUpdate.customerContactInfo;
    addressStatusAfterUpdate = custInfoAfterAddressUpdate && custInfoAfterAddressUpdate.address && custInfoAfterAddressUpdate.address.status;
    if (addressStatusAfterUpdate === ADDRESS_STATUS.UNVERIFIED) {
      isCheckboxDisabled = true;
    }
  }

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const confirmDialogPaperProps = {
    classes: {
      root: classes.confirmDialogPaperPropsRoot
    }
  };

  const handleTrackingLink = () => {
    const trackingUrl = `${deliveryInformation.carrierTrackingUrl}&source=portal`
    window.open(trackingUrl, '_blank');
  }
  const handleOriginalName = () => {
    if (!showOriginalName) {
      setShowOriginalName(true);
    } else {
      setShowOriginalName(false);
    }
  }

  const handleInvoiceModalClose = () => {
    setShowInvoiceModal(false);
    setScale(1);
  };

  const zoomIn = (scaleNumber: any) => {
    setScaleNumber(scaleNumber + 15);
    setScale(scale + 0.4);
  };

  const zoomOut = (scaleNumber: any) => {
    if (scale > 1) {
      setScaleNumber(scaleNumber - 15);
      setScale(scale - 0.4);
    } else {
      setScale(1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }
  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
  }

  const checkEditable = () => {
    if (isArrayNonEmpty(getObjectKeys(consignmentDataAfterReplanning))) {
      let statusAfterReplanning = metadataAfterReplanning && metadataAfterReplanning.deliveryStatus;
      return AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(statusAfterReplanning) === -1;
    } else {
      let status = metadata && metadata.deliveryStatus;
      return AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(status) === -1;
    }
  }

  const fetchExpressBundleProducts = useCallback(
    (consignmentCode: any) => {
      if (consignmentCode) {
        dispatch(
          fetchBundleProducts({
            params: consignmentCode,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const itemLength = items?.length + bundleProductList?.length
    setTotalNumberOfItems(itemLength)
   
  }, [items, bundleProductList]);

  useEffect(() => {
    if (isNonEmptyObject(consignment) && consignment?.metadata?.bundledProducts) {
      fetchExpressBundleProducts(consignment?.consignmentCode)
    }
   return ()=>{
    dispatch(resetBundleList());
   }
  }, []);

  useEffect(() => {
    setBundleProducts(bundleProductList)
  }, [bundleProductList]);
  
  useEffect(() => {
     setItems(productList)
   }, [productList]);

  useEffect(() => {
    setItems(productsAfterUpdateLineItem)
    setBundleProducts(bundleProductAfterUpdateLineItem)
  }, [productsAfterUpdateLineItem, bundleProductAfterUpdateLineItem]);

  useEffect(() => {
   if(crateInfo?.length){
    setCrateDetails(crateInfo)
   }
  }, [crateInfo]);
  
  const getBundleProduct =() =>{
    return  bundleProducts.map((item: any, index: number) => (
        <BundleProducts bundle={item} key={index} bundleCurrency={metadata?.currency}/>
    ))
   }

  const getItems = () => {
    return items && items.length && (
      items.map((item: any, index: number) => (
        <Grid key={`${item.name.en} - ${index}`} className="item" item>
          <Grid className="itemContainer" container>
            <Grid className="itemColumn item1" item xs={4}>
              <Typography>
                {item.substitutedProducts &&
                item.substitutedProducts.length &&
                item.substitutedProducts[0].name &&
                item.substitutedProducts[0].name.en
                  ? item.substitutedProducts[0].name.en.toLowerCase()
                  : item.name && item.name.en
                  ? item.name.en.toLowerCase()
                  : ""}
              </Typography>
              {isExisty(item.reason) &&
              isExisty(item.reason.reasonCode) &&
              isExisty(item.reason.reasonCode) ? (
                <Typography className="rejectedReason">{`Rejection Reason - ${item.reason.reasonDescription}`}</Typography>
              ) : isExisty(item.substitutedProducts) &&
                item.substitutedProducts.length &&
                isExisty(item.substitutedProducts[0].reason) &&
                isExisty(item.substitutedProducts[0].reason.reasonCode) ? (
                <Typography className="rejectedReason">{`Rejection Reason - ${
                  item.substitutedProducts &&
                  item.substitutedProducts.length &&
                  item.substitutedProducts[0].reason &&
                  item.substitutedProducts[0].reason.reasonDescription
                }`}</Typography>
              ) : (
                <Typography>{""}</Typography>
              )}
              {item.substitutedProducts && (
                <div style={{ display: "flex" }}>
                  <Typography className="viewOriginalLink">
                    {" "}
                    View Original
                  </Typography>
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !showOriginalName && classes.closeY,
                      showOriginalName && classes.openY
                    )}
                    onClick={() => handleOriginalName()}
                  />
                </div>
              )}
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography>
                <span>
                  {item.substitutedProducts &&
                  item.substitutedProducts.length &&
                  (item.substitutedProducts[0]?.rejectedQty !== undefined && item.substitutedProducts[0]?.rejectedQty !== '0')
                    ?  `${item.substitutedProducts[0]?.rejectedQty } ${
                        item.substitutedProducts[0].uom ?
                        item.substitutedProducts[0].uom === "Grams"
                          ? displayUnitGrams(item.substitutedProducts[0].uom)
                          : ""
                          :item.uom && item.uom === "Grams"? displayUnitGrams(item.uom): ""
                       }`
                    :  item?.rejectedQty && Number(item?.rejectedQty) ? `${item?.rejectedQty || '-'} ${
                      item.uom && item.uom === "Grams"
                        ? displayUnitGrams(item.uom)
                        : ""
                    }` : "-"}
                </span>
              </Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography>
                <span className="green">
                  {item.substitutedProducts && item.substitutedProducts.length
                    ? `${item.substitutedProducts[0].shippedQty} ${
                        item.substitutedProducts[0].uom ?
                        item.substitutedProducts[0].uom === "Grams"
                          ? displayUnitGrams(item.substitutedProducts[0].uom)
                          : ""
                          : item.uom && item.uom === "Grams"? displayUnitGrams(item.uom): ""
                      }`
                    : `${item.shippedQty || 0} ${
                        item.uom && item.uom === "Grams"
                          ? displayUnitGrams(item.uom)
                          : ""
                      }`}{" "}
                </span>
              </Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography>
                <span>{`${item.quantity || ""} ${
                  item.uom && item.uom === "Grams"
                    ? displayUnitGrams(item.uom)
                    : ""
                }`}</span>
              </Typography>
            </Grid>
            <Grid className="itemColumn item3" item xs={2}>
              <Typography>{`${fixDecimals(
                2,
                item.substitutedProducts &&
                  item.substitutedProducts.length &&
                  item.substitutedProducts[0].unitPrice
                  ? item.substitutedProducts[0]?.unitPrice
                  : item.unitPrice
                  ? item.unitPrice.toString()
                  : ""
              )} ${metadata && metadata.currency}`}</Typography>
            </Grid>
          </Grid>
          {showOriginalName && item.substitutedProducts && (
            <Grid className="itemContainer items" container>
              <Grid className="itemColumn item1" item xs={4}>
                <Typography className="originalName">
                  {item.name.en.toLowerCase()}
                </Typography>
              </Grid>
              <Grid className="itemColumn item2" item xs={2}>
                <Typography>
                  <span>{item.rejectedQty || "-"}</span>
                </Typography>
              </Grid>
              <Grid className="itemColumn item2" item xs={2}>
                <Typography>
                <span className="green">{'-'}</span>
                  {/* <span className="green"> {`${item.shippedQty || ""} ${
                    item.uom && item.uom === "Grams"
                      ? displayUnitGrams(item.uom)
                      : ""
                  }`}</span> */}
                </Typography>
              </Grid>
              <Grid className="itemColumn item2" item xs={2}>
                <Typography>
                  <span >{`${item.quantity || ""} ${
                    item.uom && item.uom === "Grams"
                      ? displayUnitGrams(item.uom)
                      : ""
                  }`}</span>
                </Typography>
              </Grid>
              <Grid className="itemColumn item3" item xs={2}>
                <Typography>{`${fixDecimals(
                  2,
                  item.unitPrice ? item.unitPrice.toString() : ""
                )} ${metadata && metadata.currency}`}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      ))
    )
  }

  const renderInvoiceModal = () => {
    return (
      <Modal open={showInvoiceModal} onClose={handleInvoiceModalClose}>
        <>
          {loading && <Loader></Loader>}
          {!loading && displayInvoice &&
            <>
              <Grid container className={classes.pdfHeader}>
                <Grid item xs={3} className={classes.pdfRoot}>
                  {numPages === 1 ? (
                    <Typography className={classes.pdfName}>{invoiceConsignment}.pdf ({numPages} Page)</Typography>
                  ) : (
                      <Typography className={classes.pdfName}>{invoiceConsignment}.pdf ({numPages} Pages)</Typography>
                    )}
                </Grid>
                <Grid item xs={6} className={classes.pdfRoot}>
                  <SvgIcon component={zoomOutIcon} className={classes.zoomOutStyle} onClick={() => zoomOut(scaleNumber)} />
                  <Typography className={classes.pdfScaleNo}>{scaleNumber}</Typography>
                  <SvgIcon component={zoomInIcon} onClick={() => zoomIn(scaleNumber)} className={classes.zoomInStyle} /></Grid>
                <Grid item xs={3} className={classes.closeModalIcon}>
                  <img src={closeButton} alt="Modal close button" onClick={handleInvoiceModalClose} />
                </Grid>

              </Grid>
              <div className={classes.invoiceModalBody}>
                <div style={{ overflow: "scroll", maxHeight: "920px", height: "100%" }}>
                  <Document
                    file={invoiceUrl ? {url:invoiceUrl} : invoiceData}
                    onLoadSuccess={onDocumentLoadSuccess}
                    noData="Please select a file"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
                    ))}
                  </Document>
                </div>
              </div></>
          }
          {!loading && !displayInvoice &&
            <Grid className={classes.noPdfModal}>
              <Grid style={{ overflow: "scroll", maxHeight: "890px", height: "100%" }}>
                <Grid className={classes.closeNoPdfModalIcon} onClick={() => setShowInvoiceModal(false)}>
                  <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowInvoiceModal(false)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid className={classes.noInvoiceMsg}>{invoiceMessage}</Grid>
              </Grid>
            </Grid>

          }
        </>
      </Modal >
    );
  };

  const closeConsignmentEditPopup = useCallback(
    () => {
      setOpenExpressOrderEditPopup(false);
    },
    [],
  );

  const handleEditPopup = useCallback(
    () => {
      setOpenExpressOrderEditPopup(true);
    },
    [dispatch],
  );

  const handleDialogClose = useCallback(
    () => {
      closePopup();
      inputFormik.resetForm()
      dispatch(
        closeDialog()
      );
      setTabValue(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_DETAIL);
    },
    [closePopup, dispatch],
  );

  const handelCancel = ()=>{
    inputFormik.resetForm();
    setTabValue(AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_DETAIL);
  }

  const handleSubmitOrderComments = useCallback(
    (consignment: any, values:any, desc:string) => {
      const payload = createPayloadToSubmitOrderComments(consignment, values?.reason, values?.comments, desc);
      dispatch(
        submitOrderComments({
          params : payload
        })
      )
      inputFormik.resetForm();
    },
    [dispatch],
  );

  const handleInvoicePdf = useCallback(
    (consignment) => {
      const paramsInvoice = {
        consignmentNo: [consignment.consignmentCode]
      };
      setShowInvoiceModal(true);
      dispatch(
        exportInvoicePdf({
          params: paramsInvoice
        })
      )
    },
    [dispatch]
  );

  const getCustomerImages = useCallback(
    () => {
      return deliveryInformation && deliveryInformation.deliveryProof && deliveryInformation.deliveryProof.imageURLs ? deliveryInformation.deliveryProof.imageURLs.map((image: any, index: number) => (
        <img key={`customer-image-${index}`} className="customer-proof-img" alt="customer-delivery-proofs" src={image} />
      )) : null;
    },
    [deliveryInformation],
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      setTabValue(value);
    },
    []
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressVerified(event.target.checked);
    setOpenAddressPopup(true)
  };

  const handleAddressDialogClose = useCallback(
    () => {
      setOpenAddressPopup(false);
    },
    [],
  );

  const handleOrderLogs = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchOrderLogs({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  const handleOrderComments = useCallback(() => {
        const payload = {
          countryISOCode: countryCode,
          type: AppConstants.EXPRESS_CONSTANTS.DELIVERY_TYPE.ORDER_COMMENTS
        }
        dispatch(
            fetchDeliveryReasons({
                params: payload
            })
        );
    },
    [dispatch]
  );

  const handleMarkAddressVerified = useCallback(
    (consignment: any, address: boolean) => {
      const payload = createPayloadToMarkAddressUnverified(consignment, address);
      dispatch(
        getAddressStatus({
          params: payload
        })
      );
      setOpenAddressPopup(false);
    },
    [dispatch]
  )

  const updateAddressDialogActionHandler = useCallback(
    (type: string, order?: string, address?: any) => {
      if (type === 'yes') {
        setAddressVerified(true);
        handleMarkAddressVerified(order, true);
      } else {
        setOpenAddressPopup(false);
        setAddressVerified(false);
      }
    },
    [handleMarkAddressVerified],
  )

  const getHistoryTimelineItems = () => {
    return historyItems && historyItems.length ? (
      historyItems.map((item: any, index: number) => (
        <TimelineItem key={`${item.statusCode} - ${index}`} className='timelineItem'>
          <TimelineSeparator>
            {index === 0 ? (
              <TimelineDot className='timelineDot'>
                <SvgIcon
                  className='dotIcon active'
                  component={item.status === "DELIVERY_FAILED" ? errorTimelineDot : activeTimelineDot}
                  viewBox='0 0 18 18'
                />
              </TimelineDot>
            ) : (
              <TimelineDot className='timelineDot'>
                <SvgIcon className='dotIcon' component={timelineDot} viewBox='0 0 8 8' />
              </TimelineDot>
            )}
            {index !== historyItems.length - 1 && <TimelineConnector className='primaryConnector' />}
          </TimelineSeparator>
          <TimelineContent className='timelineContent'>
            <Typography
              className={[
                "heading",
                index === 0 ? "active" : "",
                item.status === "DELIVERY_FAILED" ? "error" : "",
              ].join(" ")}
            >
              <span className='status'>{item.displayStatus}</span>
              <span className='dateTime'>{`(${getDateOrTimeInReqFormat(
                item.updatedAt,
                AppConstants.DATE_FORMAT
              )} at ${getDateOrTimeInReqFormat(item.updatedAt, AppConstants.SLOT_FORMAT_UI)})`}</span>
            </Typography>
            {item.reason ? (
              <Typography key={`errorMetadata-${index}`} component='div' className='metadataContainer'>
                <Typography className='title'>{item.reason ? item.reason.reasonDescription : ""}</Typography>
                <Typography className='description'>{`By System on (${new Date(
                  item.updatedAt
                ).toLocaleDateString()} at ${new Date(item.updatedAt).toLocaleTimeString("en-US", {
                  hour12: false,
                  hour: "numeric",
                  minute: "numeric",
                })})`}</Typography>
              </Typography>
            ) : consignment.reason && consignment.reason.deliveryStatus === item.displayStatus ? (
              <Typography key={`errorMetadata-${index}`} component='div' className='metadataContainer'>
                <Typography className='title'>
                  {consignment.reason ? consignment.reason.reasonDescription : ""}
                </Typography>
                <Typography className='description'>{`By System on (${new Date(
                  item.updatedAt
                ).toLocaleDateString()} at ${new Date(item.updatedAt).toLocaleTimeString("en-US", {
                  hour12: false,
                  hour: "numeric",
                  minute: "numeric",
                })})`}</Typography>
              </Typography>
            ) : (
              ""
            )}
            {/* {
            checkStatusReason(statusErrorReason, item.statusCode) && <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
              <Typography className="title">{statusErrorReason ? statusErrorReason.reasonDescription : ""}</Typography>
              <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
              })})`}</Typography>
            </Typography>
          } */}
          </TimelineContent>
        </TimelineItem>
      ))
    ) : (
      <Typography>No Items to display</Typography>
    );
  };

  const getOrderLogs = () => {
    return orderLogs && orderLogs.length ? (
      orderLogs.map((item: any, index: number) => (
        <TimelineItem key={`${item.key} - ${index}`} className='timelineItem'>
          <TimelineSeparator>
            {index === 0 ? (
              <TimelineDot className='timelineDot'>
                <SvgIcon
                  className='dotIcon active'
                  component={item.key === "DELIVERY_FAILED" ? errorTimelineDot : activeTimelineDot}
                  viewBox='0 0 18 18'
                />
              </TimelineDot>
            ) : (
              <TimelineDot className='timelineDot'>
                <SvgIcon className='dotIcon' component={timelineDot} viewBox='0 0 8 8' />
              </TimelineDot>
            )}
            {index !== orderLogs.length - 1 && <TimelineConnector className='primaryConnector' />}
          </TimelineSeparator>
          <TimelineContent className='timelineContent'>
            <Typography
              className={["heading", index === 0 ? "active" : "", item.key === "DELIVERY_FAILED" ? "error" : ""].join(
                " "
              )}
            >
              <span className='status'>{item.message}</span>
              <span className='dateTime'>{`(${getDateOrTimeInReqFormat(
                item.updatedAt,
                AppConstants.DATE_FORMAT
              )} at ${getDateOrTimeInReqFormat(item.updatedAt, AppConstants.SLOT_FORMAT_UI)})`}</span>
            </Typography>
            {!isModifiedBySystem(item.lastModifiedBy) && (
              <Typography className='lastModifiedContent'>
                <span>Updated by</span>
                <span className='name'>{item.lastModifiedBy}</span>
              </Typography>
            )}
            <Typography className='timelineContentItem'></Typography>
          </TimelineContent>
        </TimelineItem>
      ))
    ) : (
      <Typography>No Items to display</Typography>
    );
  };

  const validationSchemaComment = yup.object({
    reason: yup
      .string()
      .required(AppConstants.EXPRESS_CONSTANTS.VALIDTION_ADDITIONAL_COMMENT.REASON.REQUIRED.msg),
      comments: yup
      .string().when('reason', {
        is: (reason:any) => reason === 'OC21', 
        then: yup.string().required(AppConstants.EXPRESS_CONSTANTS.VALIDTION_ADDITIONAL_COMMENT.COMMENT.REQUIRED.msg),
    })
  });

  const inputFormik = useFormik({
    initialValues: {
      reason: '',
      comments: '',
    },
    validationSchema: validationSchemaComment,
    onSubmit: (values) => {
      let desc = ''
      orderCommentsList.filter((item: any) => {
        if (values.reason === item.value) {
          desc = item.name
        }
      });
      handleSubmitOrderComments(consignment, values, desc)
    }
  });

  const getOrderComments = () => {
    return (
      <Grid className={classes.orderCommentsContainer} container>
        <Grid container className='comments'>
          <Grid item className='item'>
            <Typography className='title'>Comments:</Typography>
            {orderCommentsAfterSubmit?.comment ? 
            <Typography className='content second'> {orderCommentsAfterSubmit?.comment?.description}</Typography>
            :
            <Typography className='content second'> {consignment?.comment?.description}</Typography>
            }
          </Grid>
          <Grid item className='item second'>
            <Typography className='title second'>Additional Information:</Typography>
            {orderCommentsAfterSubmit?.comment ? 
            <Typography className='content second'>{orderCommentsAfterSubmit?.comment?.comments}</Typography> 
            :
            <Typography className='content second'>{consignment?.comment?.comments}</Typography> 
            }
          </Grid>
        </Grid>
        <form onSubmit={inputFormik.handleSubmit}>
        <Grid className='contentAreaItem' item>
          <Grid className='itemContainer' container>
            <Grid className='itemLabel' item>
              <Typography>{TextConstants.ADD_COMMENT}</Typography>
            </Grid>
            <Grid className='itemInput' item>
            <SelectBox
                  fullWidth
                  value={inputFormik.values.reason}
                  error={inputFormik.touched.reason && Boolean(inputFormik.errors.reason)}
                  helperText={inputFormik.touched.reason && inputFormik.errors.reason}
                  id="EditConsignmentReason"
                  inputProps={{
                    name: "reason",
                    id: "EditConsignmentReason",
                  }}
                  handleFormikChange={inputFormik.handleChange}
                  items={orderCommentsList || []}
                ></SelectBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid className='contentAreaItem' item>
          <Grid className='itemContainer' container>
            <Grid className='itemLabel' item>
              <Typography>{TextConstants.ADDITIONAL_INFO}</Typography>
            </Grid>
            <Grid className='itemInput' item>
            <TextBox
                  fullWidth
                  multiline
                  variant="outlined"
                  rows={4}
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.comments}
                  error={inputFormik.touched.comments && Boolean(inputFormik.errors.comments)}
                  helperText={inputFormik.touched.comments && inputFormik.errors.comments}
                  textBoxId="additionalInfo"
                  name="comments"
                  type="text"
                  placeholderText='Enter a comment'
                ></TextBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.buttons}>
          <Grid>
            <Button className='userBtn secondary' variant='contained' onClick={handelCancel}>
              {AppConstants.BUTTONS.CANCEL}
            </Button>
            <Button
              className='userBtn primary'
              variant='contained'
              type="submit"
            >
              {AppConstants.BUTTONS.SUBMIT}
            </Button>
          </Grid>
        </Grid>
        </form>
      </Grid>
    );
  };

  const getAddressDialogTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
      </Grid >
    )
  }


    const fetchStatus = useCallback(
      (consignment: any) => {
        if (Object.keys(consignment).length) {
          let consignmentCode = consignment && consignment.consignmentCode;
          dispatch(
            fetchStatusOnClick({
              params: consignmentCode,
            })
          );
        }
      },
      [dispatch]
    );

   const checkRefreshableStatus = () => {
     let status = metadata && metadata.status;
     return (
       AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.REFRESHABLE_STATUSES.indexOf(
         status
       ) !== -1
     );
   };

   const handleClick = () => {
     fetchStatus(consignment);
     setRemainingTime(TIMER_CONST.pullTimeForRefresh);
     handleTimerInstances && handleTimerInstances(consignment.consignmentCode);
   };

   useEffect(() => {
     let timer: any = {};
     if (timerStarted) {
       timer = setInterval(() => {
         setRemainingTime((prevTime) => prevTime - 1);
         const countdownInstance = Countdown.getInstance();
         setTimersValue(countdownInstance);
       }, 1000); // update every second
     }

     return () => {
       clearTimeout(timer);
     };
   }, [timerStarted === true]);

   useEffect(() => {
     if (remainingTime === Number(0)) {
       dispatch(resetStatusRefreshButton());
       setShowTime && setShowTime(false);
     }
   }, [remainingTime]);

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className='headingContainer' container>
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography className='titleHeading'>
                    <span className='label'> CONSIGNMENT ID : </span>
                    <span className='content'> {consignment.consignmentCode} </span>
                  </Typography>
                </Grid>
                {consignmentDataAfterReplanning?.metadata ? (
                  <Grid className='tagContainer' item>
                    <Typography
                      className={[
                        "tag",
                        getStatusField("color", consignmentDataAfterReplanning?.metadata?.deliveryStatus),
                      ].join(" ")}
                    >
                      {consignmentDataAfterReplanning?.metadata?.deliveryStatus &&
                        consignmentDataAfterReplanning?.metadata?.deliveryStatus}
                    </Typography>
                  </Grid>
                ) : (
                  metadata &&
                  metadata.deliveryStatus && (
                    <Grid className='tagContainer' item>
                      <Typography
                        className={["tag", getStatusField("color", metadata && metadata.deliveryStatus)].join(" ")}
                      >
                        {metadata && metadata.deliveryStatus ? metadata.deliveryStatus : ""}
                      </Typography>
                    </Grid>
                  )
                )}
                {!(isLastMileViewer || isOnlyExpLastMileViewer) && (
                  <>
                    {consignmentDataAfterReplanning?.metadata
                      ? (consignmentDataAfterReplanning.metadata?.deliveryStatus ===
                          EXPRESS_DELIVERY_TYPE.DELIVERY_ATTEMPTED ||
                          consignmentDataAfterReplanning.metadata?.deliveryStatus ===
                            EXPRESS_DELIVERY_TYPE.ON_HOLD) && (
                          <Grid className='editIcon' item>
                            <SvgIcon className='icon' onClick={handleEditPopup} component={editIconNew} />
                          </Grid>
                        )
                      : checkEditable() && (
                          <Grid className='editIcon' item>
                            <SvgIcon className='icon' onClick={handleEditPopup} component={editIconNew} />
                          </Grid>
                        )}
                    {checkRefreshableStatus() && (
                      <Grid className='editIcon' item>
                        {!timersValue[consignment.consignmentCode as keyof typeof timersValue] && (
                          <SvgIcon className='icon' onClick={handleClick} component={refreshIconNew} />
                        )}
                        {setShowTime && timersValue[consignment.consignmentCode as keyof typeof timersValue] && (
                          <>
                            <SvgIcon className='icon refresh-block' component={refreshIconGrey} />
                            <span className='refreshText'>
                              {" "}
                              Refresh in {
                                timersValue[consignment.consignmentCode as keyof typeof timersValue]
                              } secounds{" "}
                            </span>
                          </>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            {(deliveryInformation && deliveryInformation.carrierTrackingId) || consignment.orderReference ? (
              <Grid container style={{marginTop: "12px"}}>
                <Grid item>
                  {deliveryInformation && deliveryInformation.carrierTrackingUrl ? (
                    <div style={{display: "flex"}}>
                      <Link className='titleContent link' onClick={handleTrackingLink}>{`Live Tracking (#${
                        deliveryInformation && deliveryInformation.carrierTrackingId
                          ? deliveryInformation.carrierTrackingId
                          : consignment.orderReference
                          ? consignment.orderReference
                          : ""
                      })`}</Link>
                      <div className={classes.verticalDiv} />
                      <Link className='titleContent link' onClick={() => handleInvoicePdf(consignment)}>
                        {" "}
                        View Invoice
                      </Link>
                    </div>
                  ) : (
                    <Typography className='titleContent'>{`Track Consignment (#${
                      deliveryInformation && deliveryInformation.carrierTrackingId
                        ? deliveryInformation.carrierTrackingId
                        : consignment.orderReference
                        ? consignment.orderReference
                        : ""
                    })`}</Typography>
                  )}
                </Grid>
                {deliveryInformation?.deliveryExecutionStatus && (
                  <Grid item className={classes.executionStatus}>
                    <div className={classes.verticalDiv} />
                    <Typography
                      className={[
                        "tag",
                        getDeliveryExecutionStatusField("color", deliveryInformation.deliveryExecutionStatus),
                      ].join(" ")}
                    >
                      {deliveryInformation.deliveryExecutionStatus}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const addressStatus = (status: any) => {
    if (status === ADDRESS_STATUS.VERIFIED) {
      return <img src={verifiedIcon} alt="verified-icon" />
    } else {
      if (status === ADDRESS_STATUS.UNVERIFIED) {
        return <img src={unverifiedIcon} alt="unverified-icon" />
      } else {
        return <img src={pendingIcon} alt="pending-icon" />
      }
    }
  }

  const getCrateItems = () => {
    return crateDetails && crateDetails.length ? crateDetails.map((crate: any, index: number) => (
      <Grid key={crate.crateId} className="item" item style={{ paddingTop: "16px" }}>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn item1" item xs={4}>
            <Grid container alignItems="center">
              <Typography>{crate.crateId}</Typography>
              <CustomBarcode value={crate.crateId} options={{ displayValue: false }} imgProps={{ alt: "Crate Barcode", className: "crateBarcode" }} />
            </Grid>
          </Grid>
          <Grid className="itemColumn item2" item xs={4}>
            <Typography>{crate.productsCountPerCrate !== 0 &&  crate.productsCountPerCrate}</Typography>
          </Grid>
          <Grid className="itemColumn item3" item xs={4}>
            <Grid container alignItems="center">
              <SvgIcon className="crateStageLocationIcon" component={crateStageLocationIcon} viewBox="0 0 16 16" />
              <Typography>{crate.locationCode}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )) : (<Typography>No Items to display</Typography>)
  }

  const handleCrateSummary = () => {
    setIsCratesAvailable(false);
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <TabContext value={tabValue}>
          <Tabs value={tabValue} className={classes.tabs} aria-label='Order Navigation Tabs' onChange={handleTabChange}>
            <Tab
              className='tabLabel'
              label='Order Detail'
              value={AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_DETAIL}
            />
            <Tab
              className='tabLabel'
              label='Order Log'
              value={AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_LOG}
              onClick={() => handleOrderLogs(consignment)}
            />
            {!(isLastMileViewer || isOnlyExpLastMileViewer) && (
              <Tab
                className='tabLabel'
                label='Order Comments'
                value={AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_COMMENTS}
                onClick={() => handleOrderComments()}
              />
            )}
          </Tabs>
          <TabPanel
            className='tabValues'
            value={AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_DETAIL}
          >
            <Grid className='detailsHeader' container>
              <Grid container className='deliveryDetails'>
                <Typography className='deliveryTimeDetails'>Customer Details</Typography>
              </Grid>
              <Grid container className='deliveryDetails'>
                <Grid item>
                  <Typography className='delivery'>{custInfo && custInfo.name}</Typography>
                </Grid>
                {addressStatusAfterUpdate ? (
                  <Grid item style={{marginLeft: "12px"}}>
                    {addressStatusAfterUpdate === ADDRESS_STATUS.UNVERIFIED ? (
                      <img src={unverifiedIcon} alt='info-icon' />
                    ) : (
                      ""
                    )}
                  </Grid>
                ) : (
                  <Grid item style={{marginLeft: "12px"}}>
                    {addressStatus(custInfo && custInfo.address && custInfo.address.status)}
                  </Grid>
                )}
              </Grid>
              <Grid item className='deliveryDetails' style={{display: "flex"}}>
                <img className='location' src={locationIcon} alt='location'></img>
                <Typography className='delivery'>
                  {getAddressInfo(custInfo && custInfo.address ? custInfo.address : "")}
                </Typography>
              </Grid>
              <Grid className='detailsHeaderItem' item>
                <Grid container className='headerItemContainer'>
                  <Grid className='contactMobile' item>
                    <Grid container className='contactItemContainer'>
                      <img className='logo' alt='Mobile' src={mobileLogoIcon} />
                      <span className='contact mobile'>
                        {custInfo ? <a href={`tel: ${custInfo.phone}`}>{custInfo.phone || ""}</a> : ""}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container className='contactItemContainer'>
                      <img className='logo' alt='Email' src={emailLogoIcon} />
                      <span className='contact email'>
                        {custInfo ? <a href={`mailto: ${custInfo.email}`}>{custInfo.email || ""}</a> : ""}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!(isLastMileViewer || isOnlyExpLastMileViewer) && (
                <>
                  {!(custInfo && custInfo.address && custInfo.address.status === ADDRESS_STATUS.UNVERIFIED) && (
                    <Grid container className='deliveryDetails'>
                      <FormControlLabel
                        classes={{root: classes.checkbox}}
                        label={
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#0E5AA7",
                              lineHeight: "16px",
                              fontWeight: 700,
                              marginLeft: "12px",
                            }}
                          >
                            Mark address as inaccurate
                          </Typography>
                        }
                        control={
                          <Checkbox
                            disabled={isCheckboxDisabled}
                            checked={addressVerified}
                            size='small'
                            color='primary'
                            onChange={handleChange}
                            inputProps={{"aria-label": "controlled"}}
                          />
                        }
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <Grid item>
              <Grid className='detailsContent' container>
                <Grid className='contentRow' item>
                  <Grid className='rowContainer' container>
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Order No.</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>{consignment.orderCode}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Delivery Date</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>{deliveryDate}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Delivery Slot</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>{deliverySlot}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentRow' item>
                  <Grid className='rowContainer' container>
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Delivery Proposition</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>{metadata ? metadata.deliveryType : ""}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Delivery Area</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>
                            {custInfo && custInfo.address ? custInfo.address.area : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Payment Mode</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>{metadata ? metadata.paymentType : ""}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {metadata && metadata.routeName && (
                  <Grid className='contentRow' item>
                    <Grid className='rowContainer' container>
                      <Grid className='contentColumn' item>
                        <Grid container direction='column'>
                          <Grid item>
                            <Typography className='label'>Route No.</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className='content'>{metadata.routeName}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className='contentColumn' item>
                        <Grid container direction='column'>
                          <Grid item>
                            <Typography className='label'>Driver</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className='content'>
                              {deliveryInformation && deliveryInformation.driver && deliveryInformation.driver.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className='contentColumn' item>
                        <Grid container direction='column'>
                          <Grid item>
                            <Typography className='label'>Vehicle</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className='content'>
                              {deliveryInformation && deliveryInformation.vehicle && deliveryInformation.vehicle.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid className='contentRow' item>
                  <Grid className='rowContainer' container>
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Payment Status</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>
                            {metadata && metadata.paymentReceived ? "Payment Accepted" : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                     {deliveryInformation && deliveryInformation.deliveryInstructions && (
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography className='label'>Delivery Instructions</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className='content'>
                            {deliveryInformation && deliveryInformation.deliveryInstructions}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                )}
                   
                    <Grid className='contentColumn' item>
                      <Grid container direction='column'>
                        {deliveryInformation && deliveryInformation.helper && (
                          <>
                            <Grid item>
                              <Typography className='label'>Helper</Typography>
                            </Grid>
                            <Grid item>
                              <Typography className='content'>
                                {deliveryInformation && deliveryInformation.helper && deliveryInformation.helper.name}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
               
                <Grid className='contentRow' item>
                  {metadata &&metadata?.deliveryStatus === EXPRESS_DELIVERY_TYPE.DELIVERY_ATTEMPTED  && (
                      <Grid className="contentColumn" item>
                        <Grid container direction="column">
                          {deliveryAttemptReasons && (
                            <>
                              <Grid item>
                                <Typography className="label">
                                  {TextConstants.REASON_ATTEMPTED_DELIVERY}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography className="content">
                                  {deliveryAttemptReasons?.reasonDescription}
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                  )}
               {(metadata?.deliveryStatus ===
                    AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA
                      .ORDER_STATUS.DELIVERY_FAILED ||
                    consignmentDataAfterReplanning?.metadata?.deliveryStatus ===
                      AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA
                        .ORDER_STATUS.DELIVERY_FAILED) && (
                    <Grid className="contentColumn" item>
                      <Grid container direction="column">
                        {consignmentDataAfterReplanning?.reason ? (
                          <>
                            <Grid item>
                              <Typography className="label">
                                Undelivered Reason
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className="content">
                                {consignmentDataAfterReplanning.reason &&
                                  consignmentDataAfterReplanning.reason
                                    .reasonDescription}
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          reason && (
                            <>
                              <Grid item>
                                <Typography className="label">
                                  Undelivered Reason
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography className="content">
                                  {reason && reason.reasonDescription}
                                </Typography>
                              </Grid>
                            </>
                          )
                        )}
                      </Grid>
                    </Grid>
                  )}
                  </Grid>
              </Grid>
            </Grid>
            <Grid className='detailsHeader' container>
              <Grid container className='deliveryDetails'>
                <Typography className='deliveryTimeDetails'>Hub Details</Typography>
              </Grid>
              <Grid container className='deliveryDetails'>
                <Typography className='delivery'>{hubName}</Typography>
              </Grid>
              <Grid item className='deliveryDetails' style={{display: "flex"}}>
                <img className='location' src={locationIcon} alt='location'></img>
                <Typography className='delivery'>
                  {getAddressInfo(posInfo && posInfo.address ? posInfo.address : "")}
                </Typography>
              </Grid>
            </Grid>
            {(metadata &&
              metadata.deliveryStatus === AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_STATUS_SELECTION) ||
            (metadata &&
              metadata.deliveryStatus === AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.PARTIALLY_DELIVERED) ? (
              <Grid className='deliveryProofSection' item>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                    aria-controls='delivery-proof-content'
                    id='delivery-proof-header'
                    className={classes.accordionSummary}
                  >
                    <Typography className='header'>Proof of Delivery</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component='div' className='content'>
                      <Grid className='deliveryProofContainer' container>
                        <Grid className='deliveryProofItem images' item>
                          <Grid container className='imagesContainer'>
                            <Grid item className='imagesItem addon'>
                              <Grid container className='imageItemContainer'>
                                <Grid item className='imageItem'>
                                  <Typography className='heading'>Images</Typography>
                                </Grid>
                                <Grid item className='imageItem allImages'>
                                  <Grid container className='allImagesContainer'>
                                    <RcViewer className='viewerContainer'>{getCustomerImages()}</RcViewer>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item className='imagesItem sign'>
                              <Grid container className='imageItemContainer'>
                                <Grid item className='imageItem'>
                                  <Typography className='heading'>Customer's Signature</Typography>
                                </Grid>
                                <Grid item className='imageItem allImages'>
                                  <Grid container className='allImagesContainer sign'>
                                    {deliveryInformation &&
                                      deliveryInformation.deliveryProof &&
                                      deliveryInformation.deliveryProof.signURL && (
                                        <RcViewer className='viewerContainer'>
                                          <img
                                            src={deliveryInformation.deliveryProof.signURL}
                                            className='customer-sign-img'
                                            alt='customer-signature'
                                          />
                                        </RcViewer>
                                      )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid className='deliveryProofItem comments' item>
                          <Grid container className='commentsContainer'>
                            <Grid item className='commentsItem head'>
                              <Typography className='heading'>Comments:</Typography>
                            </Grid>
                            <Grid item className='commentsItem details'>
                              <Typography className='desc'>
                                {deliveryInformation && deliveryInformation.deliveryProof
                                  ? deliveryInformation.deliveryProof.comments
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : (
              ""
            )}
            {metadata && metadata.deliveryGroupStatus !== "UNASSIGNED" ? (
              <Grid className='tabularSection items' item>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                    aria-controls='driver-details-content'
                    id='driver-details-header'
                    className={classes.accordionSummary}
                  >
                    <Typography className='header'>Driver Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='itemDiv'>
                    <Typography component='div' className='content'>
                      <Grid className='itemsContainer' container direction='column'>
                        <Grid className='item' item>
                          <Grid
                            className='itemContainer header'
                            container
                            justify='space-between'
                            style={{padding: "16px"}}
                          >
                            <Grid className='itemColumn item1' item>
                              <Typography>Driver Name</Typography>
                            </Grid>
                            <Grid className='itemColumn item1' item>
                              <Typography>Phone Number</Typography>
                            </Grid>
                            <Grid className='itemColumn item1' item>
                              <Typography>Vehicle Type</Typography>
                            </Grid>
                            <Grid className='itemColumn item1' item>
                              <Typography>Vendor</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid className='item' item>
                          <Grid className='itemContainer' container justify='space-between'>
                            <Grid className='itemColumn item1' item>
                              <Typography>{driver.driverName || "-"}</Typography>
                            </Grid>
                            <Grid className='itemColumn item1' item>
                              <Typography>{driver.phone || "-"}</Typography>
                            </Grid>
                            <Grid className='itemColumn item1' item>
                              <Typography>{driver.vehicleType || "-"}</Typography>
                            </Grid>
                            <Grid className='itemColumn item1' item>
                              <Typography>{driver.vendor || "-"}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : (
              ""
            )}
            <Grid className='tabularSection items' item>
              <Accordion>
                <AccordionSummary
                  expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                  aria-controls='consignment-history-content'
                  id='consignment-history-header'
                  className={classes.accordionSummary}
                >
                  <Typography className='header'>Items Summary ({totalNumberOfItems})</Typography>
                </AccordionSummary>
                <AccordionDetails className='itemDiv'>
                  <Typography component='div' className='content'>
                    <Grid className='itemsContainer' container direction='column'>
                      <Grid className='item' item>
                        <Grid className='itemContainer header' container style={{padding: "16px"}}>
                          <Grid className='itemColumn item1' item xs={4}>
                            <Typography>Product Name</Typography>
                          </Grid>
                          <Grid className='itemColumn item2' item xs={2}>
                            <Typography>Rejected Qty</Typography>
                          </Grid>
                          <Grid className='itemColumn item2' item xs={2}>
                            <Typography>Shipped Qty</Typography>
                          </Grid>
                          <Grid className='itemColumn item2' item xs={2}>
                            <Typography>Ordered Qty</Typography>
                          </Grid>
                          <Grid className='itemColumn item3' item xs={2}>
                            <Typography>Unit Price</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {items?.length > 0 && getItems()}
                      {bundleProductList?.length > 0 && getBundleProduct()}
                      {items?.length === 0 && bundleProductList?.length === 0 && (
                        <Typography>No Items to display</Typography>
                      )}
                    </Grid>
                    <Grid className='paymentContainer' container direction='column'>
                      <Grid className='paymentItem' item>
                        <Grid container>
                          <Grid className='paymentItemColumn' item>
                            <Typography>Payment Method</Typography>
                          </Grid>
                          <Grid className='paymentItemColumn right' item>
                            <Typography>{metadata ? metadata.paymentType : ""}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className='paymentItem' item>
                        <Grid container>
                          <Grid className='paymentItemColumn' item>
                            <Typography>Delivery Charges</Typography>
                          </Grid>
                          <Grid className='paymentItemColumn right' item>
                            <Typography>
                              {deliveryInformation && metadata
                                ? `${
                                    fixDecimals(
                                      2,
                                      deliveryInformation.deliveryCost
                                        ? deliveryInformation.deliveryCost.toString()
                                        : ""
                                    ) || ""
                                  } ${(metadata && metadata.currency) || ""}`
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className='paymentItem' item>
                        <Grid container>
                          <Grid className='paymentItemColumn' item>
                            <Typography>
                              Grand Total<span className='vat'>(inclusive of VAT)</span>
                            </Typography>
                          </Grid>
                          <Grid className='paymentItemColumn right' item>
                            <Typography>
                              {metadata
                                ? `${
                                    fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : "") || ""
                                  } ${(metadata && metadata.currency) || ""}`
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {metadata &&
                      (metadata.paymentType === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.COD_TEXT || metadata?.paymentType  && isCardOnDelivery(metadata.paymentType)) ? (
                        <>
                          <Divider className='totalDivider'></Divider>
                          <Grid className='paymentItem' item>
                            <Grid container>
                              <Grid className='paymentItemColumn' item>
                                <Typography>Amount to be Collected</Typography>
                              </Grid>
                              <Grid className='paymentItemColumn right' item>
                                <Typography>
                                  {metadata
                                    ? `${
                                        fixDecimals(
                                          2,
                                          metadata.amountToCollect ? metadata.amountToCollect.toString() : ""
                                        ) || ""
                                      } ${(metadata && metadata.currency) || ""}`
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid className='paymentItem' item>
                            <Grid container>
                              <Grid className='paymentItemColumn' item>
                                <Typography>Collected Amount</Typography>
                              </Grid>
                              {revisedAmountAfterCalculation !== "" ? (
                                <Grid className='paymentItemColumn right' item>
                                  <Typography>{`${revisedAmountAfterCalculation} ${
                                    (metadata && metadata.currency) || ""
                                  }`}</Typography>
                                </Grid>
                              ) : (
                                <Grid className='paymentItemColumn right' item>
                                  <Typography>{`${
                                    metadata.collectedAmount
                                      ? parseFloat(metadata.collectedAmount.toString()).toFixed(2)
                                      : "0.00"
                                  } ${(metadata && metadata.currency) || ""}`}</Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                      <Divider className='totalDivider'></Divider>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className='consignmentHistory' item>
              <Accordion>
                <AccordionSummary
                  expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                  aria-controls='consignment-history-content'
                  id='consignment-history-header'
                  className={classes.accordionSummary}
                >
                  <Typography className='header'>Consignment History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component='div' className='content'>
                    <Timeline className={classes.timeline} align='left'>
                      {getHistoryTimelineItems()}
                    </Timeline>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className='tabularSection crates' item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls='crate-section-content'
              id='crate-section-content'
              className={classes.accordionSummary}
              onClick={() => handleCrateSummary()}
            >
              <Grid container direction='column'>
                <Typography className='header'>Crate Summary</Typography>
                <Typography className='header'>
                  <span className='smallHeading'>Total Crates</span>
                  {` : ${crateDetails.length} (Estimated ${
                    deliveryInformation ? deliveryInformation.estimatedCrates : ""
                  } Actual ${deliveryInformation ? deliveryInformation.totalCratesCount : ""})`}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='div' className='content' style={{width: "100%"}}>
                {crateDetails && crateDetails.length ? (
                  <Grid className='itemsContainer' container direction='column'>
                    <Grid className='itemContainer header' container>
                      <Grid className='itemColumn item1' item xs={4}>
                        <Typography>Crate ID</Typography>
                      </Grid>
                      <Grid className='itemColumn item2' item xs={4}>
                        <Typography>Number of Items</Typography>
                      </Grid>
                      <Grid className='itemColumn item3' item xs={4}>
                        <Typography>Staging Location</Typography>
                      </Grid>
                    </Grid>
                    {getCrateItems()}
                  </Grid>
                ) : (
                  <>
                    <Typography>No Crates to display</Typography>
                  </>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
          </TabPanel>
          <TabPanel value={AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_LOG}>
            <Grid className={classes.iconDownlod} item >
              <SvgIcon className='icon' onClick={()=>handleDownloadLogs(consignment)} component={downloadLogsIcon} viewBox='0 0 140 32' />
            </Grid>
            <Timeline className={classes.timeline} align='left'>
              {getOrderLogs()}
            </Timeline>
          </TabPanel>
          <TabPanel value={AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ORDER_COMMENTS}>
            {getOrderComments()}
          </TabPanel>
        </TabContext>
      </Grid>
    );
  }

  const getAddressDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography style={{ fontSize: "14px", fontWeight: 500, color: "#252525" }}>
            Do you want to mark this address unverified?</Typography>
        </Grid>
      </Grid>
    )
  };

  const getAddressDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { updateAddressDialogActionHandler('no') }}>{AppConstants.BUTTONS.NO}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { updateAddressDialogActionHandler('yes', consignment, addressVerified) }}>{AppConstants.BUTTONS.YES}</Button>
      </Grid>
    )
  }

  useEffect(() => {
    if (Object.keys(consignmentDataAfterReplanning).length !== 0 || isPolled) {
      let consignmentCode = (consignmentDataAfterReplanning && consignmentDataAfterReplanning.consignmentCode) || consignment?.consignmentCode;
      dispatch(
        fetchConsignmentHistory({
          params: consignmentCode
        })
      );
    }
  }, [consignmentDataAfterReplanning, isPolled, dispatch])

  useEffect(() => {
    if (consignment != null) {
      setAddressVerified(false);
      setItems(consignment?.products)
      handleOrderStatusHistory(consignment);
    }
  }, [handleOrderStatusHistory, consignment]);

  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={null}
        handleClose={handleDialogClose}
      ></CustomDialog>
      {renderInvoiceModal()}
      <CustomDialog
        hideCloseIcon={true}
        open={openAddressPopup}
        PaperProps={confirmDialogPaperProps}
        title={getAddressDialogTitleContent()}
        content={getAddressDialogDetailsContent()}
        actions={getAddressDialogActions()}
        handleClose={handleAddressDialogClose}
      ></CustomDialog>
      {openExpressOrderEditPopup && <ExpressOrderEdit
        items={items}
        open={openExpressOrderEditPopup}
        consignment={consignment}
        bundleProduct={bundleProducts}
        closePopup={closeConsignmentEditPopup}
      ></ExpressOrderEdit>}
    </>
  )
}

export default ExpressOrderDetails;