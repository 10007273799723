import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import moment from "moment";

import networkHandler from "../../../network/networkHandler";
import networkHandlerDelve from "../../../network/networkHandlerDelve";
import AppConstants from "../../../constants";

import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { DelveDashboardState } from "./delveSlice.types";
import { saveFile, transformSelectValues } from "../../../utils/helpers.utils";

export const fetchDynamicFiltersOrdersDropdownValues = createAsyncThunk(
  "Delve/FetchDynamicFiltersOrdersDropdownValues",
  async (
    {
      filters,
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "delve/order/dynamic-filter-count",
        method: EApiMiddlewareMethods.POST,
        data: filters,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningOrderListByFilter = createAsyncThunk(
  "Delve/FetchOrdersByFilter",
  async (
    {
      filters,
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "delve/order/filter",
        method: EApiMiddlewareMethods.POST,
        data: filters,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrdersFilterChipsDisplayCount = createAsyncThunk(
  "Delve/FetchOrdersFilterChipsDisplayCount",
  async (
    {
      filters,
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "order/displayStatusCount/delve",
        method: EApiMiddlewareMethods.POST,
        data: filters,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "Delve/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "export/order",
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob",
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConsignmentHistoryAndProducts = createAsyncThunk(
  "ConsignmentDetails/FetchConsignmentHistoryAndProducts",
  async ({ params, tabValue }: { params: any; tabValue: any }, { rejectWithValue }) => {
    try {
      const request = {
        url: `order/statusHistoryProducts/${params}?deliveryFlow=${tabValue}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCratesInfo = createAsyncThunk(
  "ConsignmentDetails/getCratesInfo",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/updateCratesAlectoApi`,
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConsignmentUnOrderedProducts = createAsyncThunk("ConsignmentDetails/fetchConsignmentUnOrderedProducts", async ({ params }: { params: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: `unOrderedProducts/consignmentCode/${params}`,
      method: EApiMiddlewareMethods.GET,
    } as any;
    const { data } = await networkHandlerDelve(request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const closeDialog = createAsyncThunk("Delve/CloseDialog", () => {
  return false;
});

export const resetReplanningSuccess = createAsyncThunk("Delve/ResetReplanningSuccess", () => {
  return false;
});

const initialState: DelveDashboardState = {
  loading: false,
  tableLoading: false,
  exportError: "",
  ordersFilterChipsDisplayCount: {
    delveConsignmentCount: {
      VERIFIED: 0,
      UNVERIFIED: 0,
    },
  },
  ordersFiltersDropdowns: {
    hubNameList: [],
    deliverySlotList: [],
    statusList: [],
    driverList: [],
  },
  ordersData: {
    elements: [],
    pageNumber: 0,
  },
  error: "",
  errorCode: "",
  driversList: [],
  productsAfterUpdateLineItem: {},
  consignmentStatuses: [],
  productsList: [],
  cratesFromAlecto: "",
  productsListFromAlecto: [],
  /** exportToExcel */
  excelLoading: false,
  unOrderedProductsList: [],
};

const delveSlice = createSlice({
  name: "Delve",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchDynamicFiltersOrdersDropdownValues.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = "";
        state.error = "";
        state.ordersFiltersDropdowns = {
          hubNameList: transformSelectValues(payload.hubNames || []),
          deliverySlotList: transformSelectValues(payload.deliverySlots || []),
          statusList: transformSelectValues(payload.statuses || []),
          driverList: transformSelectValues(payload.drivers || []),
        };
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.ordersFilterChipsDisplayCount = payload;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningOrderListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningOrderListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters },
          },
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.ordersData = {
          ...payload,
          elements: filters.pageNumber === 0 ? [...payload.elements] : [...state.ordersData.elements, ...payload.elements],
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        };
      })
      .addCase(fetchPlanningOrderListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchConsignmentHistoryAndProducts.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchConsignmentHistoryAndProducts.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.productsAfterUpdateLineItem = {};
        state.consignmentStatuses = payload?.statuses?.length ? payload.statuses : [];
        state.productsList = payload?.products?.length ? payload.products : [];
      })
      .addCase(fetchConsignmentHistoryAndProducts.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(getCratesInfo.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(getCratesInfo.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.cratesFromAlecto = payload;
        state.productsListFromAlecto = payload?.crateAvailableOrders?.[0]?.products?.length > 0 ? payload.crateAvailableOrders?.[0].products : null;
      })
      .addCase(getCratesInfo.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** exportToExcel */
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const { payload } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Consignments_${currentDate} _${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** fetchConsignmentUnOrderedProducts */
      .addCase(fetchConsignmentUnOrderedProducts.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.unOrderedProductsList = [];
      })
      .addCase(fetchConsignmentUnOrderedProducts.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.unOrderedProductsList = payload;
      })
      .addCase(fetchConsignmentUnOrderedProducts.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      });
  },
});

export default delveSlice.reducer;
