import React from "react";

import { ClickAwayListener, Grid, Typography } from "@material-ui/core";
import CustomTooltip from "../../../common/Tooltip";
import { getAreaTitle, getDeliverySlotTitle } from "./AllRouteUtils";
import { isNonEmptyObject, isObject } from "../../../utils/helpers.utils";

export const customMapConainer = (color: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={color} />
    </svg>
  );
};

interface CustomPlannedOrderActionTooltipInterface {
  classes: any;
  consignmentCode?: string;
  routeName: any;
  routeDataName: string;
  slotName: any;
  orderObjIndex: number;
  removeOrderFromRoute ?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  addOrderToAnotherRoute ?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  orderActionPopUpViewChange ?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
}
export const CustomPlannedOrderActionTooltip = (props: CustomPlannedOrderActionTooltipInterface) => {
  const { classes, consignmentCode, routeName, slotName, routeDataName, orderObjIndex, removeOrderFromRoute, addOrderToAnotherRoute, orderActionPopUpViewChange } = props;

  return (
    <ClickAwayListener onClickAway={(event: any) => orderActionPopUpViewChange && orderActionPopUpViewChange(event, routeName, routeDataName, slotName, orderObjIndex)} disableReactTree={true} mouseEvent="onMouseUp">
      <Grid container className={classes.orderActionTooltipGridContainer}>
        <Grid item xs={12} className={classes.orderActionTooltipGridItem}>
          <Typography className={`${classes.displayFlex} ${classes.orderActionTooltipSpanStyle}`} onClick={() => removeOrderFromRoute && removeOrderFromRoute(routeName, routeDataName, slotName, orderObjIndex)}>
            {`Remove `}
            <Typography className={classes.orderActionTooltipBoldSpanStyle}>{`${consignmentCode || ""}`}</Typography>
            {` from Route`}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.orderActionTooltipGridItem}>
          <Typography className={`${classes.displayFlex} ${classes.orderActionTooltipSpanStyle}`} onClick={() => addOrderToAnotherRoute && addOrderToAnotherRoute(routeName, routeDataName, slotName, orderObjIndex)}>
            {`Add`}
            <Typography className={classes.orderActionTooltipBoldSpanStyle}>{`${consignmentCode || ""}`}</Typography>
            {` to Another Route`}
          </Typography>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};

export const getDeliveryAreas = (areas: any[]) => {
  return (
    areas.length &&
    areas.map((area: string, index: number) => (
      <Typography key={`tooltipArea-${area}-${index}`} className="tooltipItem">
        {area}
      </Typography>
    ))
  );
};

export const getAreaTitleView = (routeObj: any) => {
  const titleName = getAreaTitle(routeObj);
  return (
    <CustomTooltip title={getDeliveryAreas(routeObj.deliveryArea)}>
      <Typography className="normal">{`${titleName}`}</Typography>
    </CustomTooltip>
  );
};

export const getCustomClassAreaTitleView = (routeObj: any, classes: any) => {
  const titleName = getAreaTitle(routeObj);
  return (
    <CustomTooltip title={getDeliveryAreas(routeObj.deliveryArea)}>
      <Typography className={classes.dialogValueSpanStyle}>{`${titleName}`}</Typography>
    </CustomTooltip>
  );
};

export const getDeliverySlots = (obj: any) => {
  return (
    obj &&
    obj.slotWiseOrders &&
    isObject(obj.slotWiseOrders) &&
    isNonEmptyObject(obj.slotWiseOrders) &&
    Object.keys(obj.slotWiseOrders).map((slotName: string, slotNameIndex: number) => (
      <Typography key={`tooltipArea-${slotName}-${slotNameIndex}`} className="tooltipItem">
        {slotName}
      </Typography>
    ))
  );
};

export const getDeliverySlotTitleView = (obj: any) => {
  const titleName = getDeliverySlotTitle(obj);
  return (
    <CustomTooltip title={getDeliverySlots(obj)}>
      <Typography className="normal">{`${titleName}`}</Typography>
    </CustomTooltip>
  );
};

export const getAreaTitleViewWithClasses = (routeObj: any, classes: any) => {
  const titleName = getAreaTitle(routeObj);
  return (
    <CustomTooltip title={getDeliveryAreas(routeObj.deliveryArea)}>
      <Typography className={`${classes.fontSizeTweleve} ${classes.fontWeightFour} ${classes.lineHeightFifteen} ${classes.marginAuto}`}>{`${titleName}`}</Typography>
    </CustomTooltip>
  );
};

interface RenderMapMarkerTitleValueInterface {
  classes: any;
  title: string;
  value: string | undefined | null;
}

export const RenderMapMarkerTitleValue = ({ classes, title, value }: RenderMapMarkerTitleValueInterface) => {
  return (
    <Grid container>
      <Typography className={classes.dialogHeaderSpanStyle}>{`${title}`}</Typography>
      <Typography className={classes.infoWindowCardValueSpanStyle}>{`${value || "-"}`}</Typography>
    </Grid>
  );
};
