import React from 'react';
import { useStyles } from "./notification.styles";
import {ReactComponent as CircleCheck } from "../../assets/icons/success-check.svg";
import { ReactComponent as Warning } from "../../assets/icons/warning-sign.svg";
import {Grid,Box} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface NotificationProps {
  title:string,
  variant:string,
  actionCallback:()=>void
}

const icons:any = {
  success: CircleCheck,
  error: Warning,
  warning: Warning,
};

export const Notification = ({
  title,
  variant = 'success',
  actionCallback
}:NotificationProps) => {
 const classes:any = useStyles();
  const Icon = icons[variant];

  return (
    <Grid container direction="row" className={`${ classes.Container} ${classes[variant]} `} >
      <Icon />
      <Box>
        {title && <Box className={classes.title}>{title}</Box>}
      </Box>
       <Grid className={classes.closeIcon} item onClick={actionCallback}><CloseIcon /></Grid>
    </Grid>
  );


};

export default Notification;