import React from "react";

import { Checkbox, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as deleteIcon } from "../../../assets/icons/delete-icon.svg";
import { ReactComponent as UserIconGreen } from "../../../assets/icons/user_icon_green.svg";

import { isDriverAssigned, isSubRoute } from "../utils/AllRouteUtils";
import { ALL_ROUTES_TEXT_CONTANT } from "../utils/AllRouteConstant";

interface RenderRouteAreaOrSubRouteAccordionSummaryInterface {
  classes: any;
  routeObj: any;
  routeName: string;
  routeDataName: string;
  viewMode?: boolean;

  handleAreaCheckboxToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string) => void;
  handleDeleteRoute?: (event: React.ChangeEvent<HTMLInputElement>, routeObject: any) => void;
}

const RenderRouteAreaOrSubRouteAccordionSummary = (props: RenderRouteAreaOrSubRouteAccordionSummaryInterface) => {
  const { classes, routeObj, routeName, routeDataName, viewMode = false, handleAreaCheckboxToggle, handleDeleteRoute } = props;

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item className={classes.justifyContentSpaceBetween}>
        <Grid container>
          {!viewMode && !isSubRoute(routeObj.routeDataObj[routeDataName]) && routeObj.subRouteCreated && (
            <Checkbox
              color="primary"
              checked={routeObj.routeDataObj[routeDataName].isChecked}
              onClick={(e: any) => handleAreaCheckboxToggle && handleAreaCheckboxToggle(e, routeName, routeDataName)}
              className={`${classes.marginAutoFour}`}
            />
          )}
          <Typography
            className={`${classes.fontSizeFourteen} ${classes.fontWeightFive} ${classes.lineHeightFifteen} ${classes.marginAutoFour} ${classes.borderRight} ${classes.paddingRightEight}`}
          >{`${routeDataName}`}</Typography>
          <Typography
            className={`${classes.fontSizeTweleve} ${classes.fontWeightFour} ${classes.marginAutoEight} ${
              isSubRoute(routeObj.routeDataObj[routeDataName]) ? classes.subRouteAccordianCustomStyle : ""
            }`}
          >
            {`${routeObj.routeDataObj[routeDataName]?.ordersCount || ""} Orders`}
          </Typography>
          {isSubRoute(routeObj.routeDataObj[routeDataName]) && <Typography className={`${classes.routeBoxStyle} ${classes.subRouteBoxStyle}`}>{`${ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE}`}</Typography>}
        </Grid>
      </Grid>
      <Grid item className={classes.displayFlex}>
        {isSubRoute(routeObj.routeDataObj[routeDataName]) && (
          <>
            {isDriverAssigned(routeObj.routeDataObj[routeDataName]) && (
              <Grid item className={`${classes.displayFlex} ${classes.borderRight} ${classes.paddingRightEight}`}>
                <SvgIcon viewBox="0 0 24 24" component={UserIconGreen} className={classes.userIconStyle} />
                <Typography className={`${classes.fontSizeTweleve} ${classes.fontWeightFour} ${classes.lineHeightFifteen} ${classes.marginAuto}`}>
                  {`${routeObj?.routeDataObj[routeDataName]?.assignee?.name.substring(0, 12) || ""}`}
                </Typography>
              </Grid>
            )}
            {handleDeleteRoute && (
              <SvgIcon
                viewBox="0 0 24 24"
                component={deleteIcon}
                className={classes.deleteIconStyle}
                onClick={(e: any) => handleDeleteRoute && handleDeleteRoute(e, routeObj.routeDataObj[routeDataName])}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderRouteAreaOrSubRouteAccordionSummary);
