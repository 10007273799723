import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";
import { useStyles } from "./BarChartComponent.styles";
import { COLORS } from "../../config/theme/baseTheme";
interface BarChartsProps {
  options?: ApexOptions;
  seriesObj: any;
  days: any;
  color: any;
  showLegend: boolean;
}

const BarCharts = (props: BarChartsProps) => {
  const { days, color, seriesObj, showLegend } = props;
  const classes = useStyles();
  const config: any = {
    series: seriesObj,
    options: {
      colors: color,
      chart: {
        type: 'bar',
        height: 430,
        toolbar: {
          show: false
        },
        width: "100%",
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: showLegend,
        position: 'top',
        horizontalAlign: 'left',
        markers: {
          width: 10,
          height: 10,
          radius: 4,
          offsetX: 0,
          offsetY: 0,
          padding: 48,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          barHeight: '70%',
          endingShape: 'rounded',
          borderRadius: 4
        },
      },
      stroke: {
        colors: ['transparent'],
        width: 6,
      },
      xaxis: {
        categories: days,
        tickPlacement: "between",
      },
      yaxis: {
        crosshairs: {
          show: true,
          position: 'back',
          stroke: {
            color: '#b6b6b6',
            width: 1,
            dashArray: 0,
          },
        },
        tickAmount: 4,
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0)
          }
        },
      },
      grid: {
        show: true,
        borderColor: COLORS.SILVER_CHALICE,
        strokeDashArray: 3,
        padding: {
          right: 40,
          left: 40
        },
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          formatter: function (val: any) {
            return val
          }
        }
      },
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: 14,
        },
      },

    },
  };

  return (
    <div className={classes.barChart} >
      <div className="row">
        <div className="mixed-chart">
          <Chart options={config.options} series={config.series} type="bar" height="286px" width="1350px"/>
        </div>
      </div>
    </div >
  );
};

export default BarCharts;