import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { HubWiseState } from "./hubWiseSlice.types";
import AppConstants from "../../../constants";
import { transformSelectValues } from "../../../utils/helpers.utils";

export const fetchFiltersHubWise = createAsyncThunk(
    "HubWiseDashboard/FetchFiltersHubWise",
    async (params, { rejectWithValue }) => {
        try {
            const request = {
                url: `filter/all/${sessionStorage.getItem(AppConstants.COUNTRY_CODE)}`,
                method: EApiMiddlewareMethods.GET,
            } as any;
            const { data } = await networkHandler(request, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchConsignmentsSlotsHubWise = createAsyncThunk(
    "HubWiseDashboard/FetchConsignmentsSlotsHubWise",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "operations/dashboard/consignments/slots",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchHubWiseData = createAsyncThunk(
    "HubWiseDashboard/FetchHubWiseData",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "operations/dashboard/consignments/hubWise",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState: HubWiseState = {
    loading: false,
    hubWiseDataLoading: false,
    filtersDropdowns: {
        countriesList: [],
        hubCodeList: [],
        hubNameList: [],
        deliveryAreaList: [],
        deliverySlotList: [],
        orderTypeList: []
    },
    allSlots: [],
    hubWiseDataList: [],
};

const hubWiseSlice = createSlice({
    name: "HubWiseDashboard",
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders
            .addCase(fetchFiltersHubWise.pending, (state) => {
                state.loading = true;
                state.errorCode = "";
                state.error = "";
            })
            .addCase(fetchFiltersHubWise.fulfilled, (state, action) => {
                const {
                    payload
                } = action;
                let hubCodeList: any = {};
                let hubNameList: any = {};
                let deliveryAreaList: any = {};
                let deliverySlotList: any = {};
                let orderTypeList: any = {};
                if (payload.hubMap) {
                    Object.keys(payload.hubMap).filter((country: any) => {
                        hubCodeList[country] = transformSelectValues(payload.hubMap[country], 'code');
                        hubNameList[country] = transformSelectValues(payload.hubMap[country], 'value');
                        return true;
                    });
                }
                if (payload.deliveryArea) {
                    Object.keys(payload.deliveryArea).filter((country: any) => {
                        deliveryAreaList[country] = transformSelectValues(payload.deliveryArea[country]);
                        return true;
                    });
                }
                if (payload.deliverySlot) {
                    Object.keys(payload.deliverySlot).filter((country: any) => {
                        deliverySlotList[country] = transformSelectValues(payload.deliverySlot[country]);
                        return true;
                    });
                }
                if (payload.orderType) {
                    Object.keys(payload.orderType).filter((country: any) => {
                        orderTypeList[country] = transformSelectValues(payload.orderType[country]);
                        return true;
                    });
                }
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.filtersDropdowns = {
                    countriesList: transformSelectValues(payload.countries),
                    hubCodeList: hubCodeList,
                    hubNameList: hubNameList,
                    deliveryAreaList: deliveryAreaList,
                    deliverySlotList: deliverySlotList,
                    orderTypeList: orderTypeList
                };
            })
            .addCase(fetchFiltersHubWise.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(fetchConsignmentsSlotsHubWise.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.loading = true;
            })
            .addCase(fetchConsignmentsSlotsHubWise.fulfilled, (state, action) => {
                const { payload } = action;
                state.errorCode = "";
                state.error = "";
                state.loading = false;
                if (payload.allSlots.length) {
                    state.allSlots = transformSelectValues(payload.allSlots);
                }
            })
            .addCase(fetchConsignmentsSlotsHubWise.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(fetchHubWiseData.pending, (state) => {
                state.errorCode = "";
                state.error = "";
                state.hubWiseDataLoading = true;
            })
            .addCase(fetchHubWiseData.fulfilled, (state, action) => {
                const { payload } = action;
                state.errorCode = "";
                state.error = "";
                state.hubWiseDataLoading = false;
                state.hubWiseDataList = payload.hubWise;
            })
            .addCase(fetchHubWiseData.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.hubWiseDataLoading = false;
                state.errorCode = errorPayload
                    ? errorPayload.error
                    : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message || errorPayload.status
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
    },
});

export default hubWiseSlice.reducer;
