import axios from "axios";
import { NetworkCallPayload } from "./networkHandler.types";
import { reportBaseURL, userBaseURL, expressBaseURL, expressDriverBaseURL, camsServiceURL, apigeeServiceURL } from "../config/Api.config";
import { replaceUrlVariables } from "../utils/updateUrlParams";
import AppConstants from "constants/AppConstants";

const networkDefaultSetting = (isUser?: boolean, isAuth?: boolean, isUAE?: boolean, isLogin?: boolean) => {
  let countryUrl = null;
  if (sessionStorage.getItem(AppConstants.COUNTRY_URL)) {
    countryUrl = sessionStorage.getItem(AppConstants.COUNTRY_URL);
  }
  axios.defaults.baseURL = countryUrl && isUAE ? countryUrl : reportBaseURL;

  if (isAuth) {
    axios.defaults.headers.common = {
      "Authorization": sessionStorage.getItem("token")
    };
  } else {
    axios.defaults.headers.common = {};
  }
  if (isUser) {
    axios.defaults.baseURL = userBaseURL;
  }
  if (!isLogin && !isUser) {
     axios.defaults.headers.common['countryISOCode'] = sessionStorage.getItem(AppConstants.COUNTRY_CODE);
  }
  axios.defaults.headers.common['system-id'] = "CWINGS";

};

const networkSettingForExpress = (isAuth?: boolean, isExpress?: boolean, isExpressDriver?: boolean) => {
  axios.defaults.baseURL = expressBaseURL;
  if (isAuth) {
    axios.defaults.headers.common = {
      "Authorization": sessionStorage.getItem("token")
    };
  } else {
    axios.defaults.headers.common = {};
  }
  if (isExpress) {
    axios.defaults.headers.common['countryISOCode'] = sessionStorage.getItem(AppConstants.COUNTRY_CODE);
  }
  if (isExpressDriver) {
    axios.defaults.baseURL = expressDriverBaseURL;
  }
  axios.defaults.headers.common['system-id'] = "CWINGS";

};

const networkSettingForCamService = (isAuth?: boolean) => {
  axios.defaults.baseURL = camsServiceURL;
  if (isAuth) {
    axios.defaults.headers.common = {
      "Authorization": sessionStorage.getItem("token")
    };
  } else {
    axios.defaults.headers.common = {};
  }
  axios.defaults.headers.common['system-id'] = "CWINGS";

};

const networkSettingForApigeeService = (isAuth?: boolean) => {
  axios.defaults.baseURL = apigeeServiceURL;
  if (isAuth) {
    axios.defaults.headers.common = {
      "Authorization": sessionStorage.getItem("token")
    };
  } else {
    axios.defaults.headers.common = {};
  }
  axios.defaults.headers.common['system-id'] = "CWINGS";

};

const networkHandler = async (params: NetworkCallPayload, isUser?: boolean, isAuth?: boolean, isUAE?: boolean, isLogin?: boolean, isExpress?: boolean, isExpressDriver?: boolean, isCamsService?:boolean, isApigeeService?:boolean) => {
  const { url, urlParams, ...restApiOptions } = params;
  let updateUrl = url;
  if (urlParams) {
    updateUrl = replaceUrlVariables(url, urlParams);
  }
  // axios default configs
  if (isExpress) {
    networkSettingForExpress(isAuth, isExpress, isExpressDriver);
  } else if (isCamsService) {
    networkSettingForCamService(isAuth);
  } else if(isApigeeService){
    networkSettingForApigeeService(isAuth);
  }
  else {
    networkDefaultSetting(isUser, isAuth, isUAE, isLogin);
  }
  return await axios.request({ url: updateUrl, ...restApiOptions });
};

export default networkHandler;
