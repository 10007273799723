import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      flexGrow: 1,
      margin: theme.spacing(2),
      alignItems: "center",
      justifyContent: "center",
      '& h3': {
        color: COLORS.COPY
      }
    }
  })
);