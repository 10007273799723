import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiCheckbox-root': {
        '&.Mui-disabled': {
          background: 'rgba(206,217,224,.5)',
        },
      }
    },
    evenRoot: {
      '& .MuiCheckbox-root': {
        '&.Mui-disabled': {
          background: 'rgba(206,217,224,.5)',
        },
      }
      //backgroundColor: "#F4F4F4"
    }
   
  })
);
