import { Grid, Table, TableBody, TableCell, TableContainer, TableProps, TableRow, Typography } from '@material-ui/core';
import React, { ElementType, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import Loader from '../../Loader';
import { useStyles } from './DataTable.styles';
import DataTableHeader from './DataTableHeader';
import DataTableRowWrapper from './DataTableRowWrapper';

declare type NativeColTypes = 'string' | 'number';

declare type CellValue = any;

export type RowId = string | number;

export type SortDirection = 'asc' | 'desc' | 'default';

export type ColType = NativeColTypes | string;

export interface CellParams {
  field: string;
  value: CellValue;
  colDef?: ColDef;
  rowData?: RowData;
}

export interface ColParams {
  field: string;
  colDef: any;
  colIndex: number;
}

export interface ColDef {
  field: string;
  headerName: string;
  tooltip?:boolean;
  tooltipDataKey?: string;
  filter?: boolean;
  filterObj: any;
  hide?: boolean;
  width?: number;
  type?: ColType;
  showArrowIcon?: boolean;
  renderCell?: (params: CellParams) => ReactElement;
  renderSubElementCell?: (params: CellParams) => ReactElement;
  renderHeader?: (params: ColParams) => ReactElement;
}

export interface RowData extends ObjectWithId {
  [key: string]: any;
}

export interface ComponentProps {
  rows: RowData[];
  columns: ColDef[];
  options: DataTableOptions;
}

export interface SortModel {
  field: string;
  direction: SortDirection;
}

export interface RowParams {
  data: RowData;
}

export interface SelectionChangeParams {
  rows: RowData[];
}

interface ObjectWithId {
  id: RowId;
}

interface DataTableComponentOverridesProp {
  header?: ElementType<ComponentProps>;
  footer?: ElementType<ComponentProps>;
  noRowsOverlay?: ElementType<any>;
}

interface DataTableOptions {
  rowCount: number;
  selected?: number;
}

export interface DataTableProps extends TableProps {
  rows: RowData[];
  columns: ColDef[];
  ariaLabel?: string;
  loading?: boolean | undefined;
  sortModel?: SortModel;
  rowHeight?: number;
  headerHeight?: number;
  checkboxSelection: boolean | false;
  defaultFilter: any;
  activeFilters: any;
  disableSelectionOnClick?: boolean;
  resetAllFilterFlag: boolean;
  resetSelection: boolean;
  isTabChange?: boolean;
  showHeader?: boolean;
  onRowClick?: ((params: RowParams) => void) | undefined;
  onCellClick?: ((params: CellParams) => void) | undefined;
  onSelectionChange?: ((params: SelectionChangeParams) => void) | undefined;
  onSort?: ((params: SortModel) => void) | undefined;
  onUpdateFilter: ((filter: any) => void) | undefined;
  hideFooter?: boolean;
  rowCount?: number;
  components?: DataTableComponentOverridesProp;
  tabValue?: string
  scrollToTopOnReset?: boolean
  deSelectCheckbox?: boolean
  defaultSelectAll?: boolean,
  disableFilterCount?: boolean,
  reselctSelectedCheckbox?: boolean,
}

const DataTable = (props: DataTableProps) => {
  const classes = useStyles();
  const tableRef = useRef<HTMLDivElement>(null);
  const {
    rows,
    columns,
    components,
    sortModel,
    rowCount,
    rowHeight,
    defaultFilter,
    activeFilters,
    loading,
    ariaLabel,
    checkboxSelection,
    disableSelectionOnClick,
    resetAllFilterFlag,
    resetSelection,
    scrollToTopOnReset,
    headerHeight,
    isTabChange,
    showHeader,
    onRowClick,
    onCellClick,
    onSelectionChange,
    onSort,
    onUpdateFilter,
    tabValue,
    deSelectCheckbox,
    defaultSelectAll,
    disableFilterCount,
    reselctSelectedCheckbox,
    ...rest
  } = props;

  const [selected, setSelected] = useState<RowId[]>([]);
  const [order, setOrder] = useState<SortDirection>(sortModel ? sortModel.direction : 'asc');
  const [orderBy, setOrderBy] = useState<keyof RowData>(sortModel ? sortModel.field : 'id');

  const isSelected = (id: RowId) => selected.indexOf(id) !== -1;

  const handleSort = (event: React.MouseEvent<unknown>, property: keyof RowData) => {
    const isAsc = order === 'asc';
    const isDefault = order === 'default';
    const newOrder = orderBy === property ? (isAsc ? 'desc' : isDefault ? 'asc' : 'default') : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    onSort && onSort({field: property as string,direction: newOrder});
  };

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelectedRows: RowId[] = rows.map((row: RowData) => row.id);
        setSelected(newSelectedRows);
        return;
      }
      setSelected([]);
    },
    [rows]
  );

  useEffect(() => {
    if(checkboxSelection && defaultSelectAll) {
      const newSelectedRows: RowId[] = rows.map((row: RowData) => row.id);
      setSelected(newSelectedRows);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxSelection, defaultSelectAll, rows]);

  const handleRowClick = (rowData: RowData) => {
    onRowClick && onRowClick({ data: rowData });
  }

  const handleCellClick = (column: ColDef, value: any, rowData: RowData) => {
    onCellClick && onCellClick({ field: column.field, value: value, colDef: column, rowData: rowData });
  }

  const handleSelectionChange = useCallback(
    (rowId: RowId) => {
      const selectedIndex = selected.indexOf(rowId);
      let newSelected: RowId[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, rowId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    },
    [selected]
  );

  useEffect(() => {
    onSelectionChange && onSelectionChange({rows: rows.filter((row: RowData) => selected.indexOf(row.id) !== -1)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (resetSelection) {
      if (tableRef.current) {
        tableRef.current.scrollTop = 0;
      }
      setSelected([]);
    }
    if(scrollToTopOnReset){
      if (tableRef.current) {
        tableRef.current.scrollTop = 0;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, resetSelection]);

  useEffect(() => {
    if(deSelectCheckbox) {
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deSelectCheckbox]);
  

  useEffect(() => {
    if (isTabChange) {
      if (tableRef.current) {
        tableRef.current.scrollTop = 0;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isTabChange]);

  useEffect(() => {
    if(reselctSelectedCheckbox) {    
      setSelected([]);
    }
  }, [reselctSelectedCheckbox]);

  return (
    <Grid className={classes.root}>
      {
        components && components.header &&
        <Grid className={classes.headerSection}>
          <components.header rows={rows} columns={columns} options={{ rowCount: rowCount || rows.length, selected: selected.length }}></components.header>
        </Grid>
      }
      <TableContainer ref={tableRef} className={`data-table-scrollable-area ${tabValue ? classes.tableDashboardWithTab: showHeader ? classes.tableContainer : classes.newTableDashboard}`}>
        {loading && <Loader></Loader>}
        <Table
          className="DataTable"
          stickyHeader
          aria-label={ariaLabel ? ariaLabel : "Data Table"}
          {...rest}
        >
          <DataTableHeader
            checkboxSelection={checkboxSelection}
            columns={columns}
            headerHeight={headerHeight || 48}
            selected={selected.length}
            order={order}
            orderBy={orderBy}
            defaultFilterValues={defaultFilter}
            resetAllFilterFlag={resetAllFilterFlag}
            activeFilters={activeFilters}
            onSelectAll={handleSelectAll}
            onSort={handleSort}
            onUpdateFilter={onUpdateFilter}
            rowCount={rows.length}
          />
          <TableBody>
            {
              rows.length ?
                rows.map((row: RowData, index: number) => (
                  <DataTableRowWrapper
                    key={index}
                    rowData={row}
                    index={index}
                    rowHeight={rowHeight || 48}
                    columns={columns}
                    checkboxSelection={checkboxSelection}
                    disableSelectionOnClick={disableSelectionOnClick}
                    isItemSelected={isSelected(row.id)}
                    rowClickCallback={handleRowClick}
                    rowSelectionCallback={handleSelectionChange}
                    cellClickCallback={handleCellClick}
                    isSelected={isSelected}
                  />
                )) :
                <TableRow className={classes.noRowsOverlaySection}>
                  <TableCell colSpan={columns.length}>
                    <Grid className="overlayContainer" style={{ width: window.innerWidth }} container>
                      {
                        components?.noRowsOverlay ?
                          <components.noRowsOverlay />
                          :
                          <Typography>No Data Available</Typography>
                      }
                    </Grid>
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
        {
          components?.footer &&
          <Grid className={classes.footerSection}>
            <components.footer rows={rows} columns={columns} options={{ rowCount: rowCount || 0, selected: selected.length }}></components.footer>
          </Grid>
        }
      </TableContainer>
    </Grid>
  );
}

export default DataTable;