import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchOrdersFilterChipsDisplayCount, fetchPlanningOrderListByFilter, fetchDynamicFiltersOrdersDropdownValues, exportToExcel } from "./redux/delveSlice";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { useStyles } from "./delvedashboard.styles";
import { AppState } from "../../config/redux/reducers";
import { userLogout } from "../../Login/redux/loginSlice";
import {
  createDelveOrdersFilterPayload,
  delveOrdersDataTransform,
  getConsignmentStatusField,
  getVerificationStatusField,
  createPayloadForExcelExport,
  createChipsCountFilterPayload,
  getVerifiedVerificationStatusField,
} from "../../mocks/delve/response.transforms";

import Loader from "../../common/Loader";
import AppConstants from "../../constants";
import GridWrapper from "../../common/GridWrapper";
import CustomSnackbar from "../../common/CustomSnackbar";
import DelveOrderDetails from "./DelveOrderDetails";

import { CellParams, SortModel, RowParams } from "../../common/GridWrapper/DataTable";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";

import { isUndefined, isNull } from "../../utils/helpers.utils";
import { startOfDay } from "../../utils/dateUtils";
import { TabContext, TabPanel } from "@material-ui/lab";
import { DELVE_ORDER_LIST_STATE, DELVE_ORDER_LIST_STATE_INTERFACE } from "../../constants/DelveOrderListConstant";
import { TAB_VALUES_CONSTANT } from "constants/DelveDashboardConstant";

interface DelveOrderListProps {
  match?: any;
  location?: any;
}

const DelveOrderList = (props: DelveOrderListProps) => {
  const dispatch = useDispatch();
  const componentClasses = useStyles();
  const onLoadRef = useRef(true);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const { ordersData, tableLoading, ordersFiltersDropdowns, ordersFilterChipsDisplayCount, loading, error, errorCode, excelLoading } = useSelector((state: AppState) => state.delveOrderList);
  const { countryCode, hubCode } = useSelector((state: AppState) => state.common);
  const [delveOrderListState, setDelveOrderListState] = useState<DELVE_ORDER_LIST_STATE_INTERFACE>(DELVE_ORDER_LIST_STATE);
  const [ordersListToShow, setOrdersListToShow] = useState([]);
  const [isOrderSearched, setIsOrderSearched] = useState(false);
  const [isDisplayCount, setIsDisplayCount] = useState(false);
  const [isTabChange, setIsTabChange] = useState(false);
  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA,
    HeaderData: AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === "consignmentId") {
        newHeader.renderCell = (params: CellParams) => <Typography className="idLink">{params.value}</Typography>;
      }
      if (newHeader.field === "verified") {
        newHeader.renderCell = (params: any) =>
          params.value ? (
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={["tag", getVerificationStatusField("color", params.value)].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
          ) : (
            <></>
          );
      }
      if (newHeader.field === "verificationStatus") {
        newHeader.renderCell = (params: any) =>
          params.value ? (
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={["tag", getVerifiedVerificationStatusField("color", params.value)].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
          ) : (
            <></>
          );
      }
      return newHeader;
    }),
    defaultFiltersObj: {
      ...AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj,
      dateRange: {
        ...AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange,
        value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT),
        },
      },
      delveDateRange: {
        ...AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.defaultFiltersObj.delveDateRange,
        value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT),
        },
      },
    } as any,
    ReturnTabHeaderData: AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.ReturnTabHeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === "consignmentId") {
        newHeader.renderCell = (params: CellParams) => <Typography className="idLink">{params.value}</Typography>;
      }
      if (newHeader.field === "verified") {
        newHeader.renderCell = (params: any) =>
          params.value ? (
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={["tag", getVerificationStatusField("color", params.value)].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
          ) : (
            <></>
          );
      }
      if (newHeader.field === "verificationStatus") {
        newHeader.renderCell = (params: any) =>
          params.value ? (
            <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
              <Typography className={["tag", getVerifiedVerificationStatusField("color", params.value)].join(" ")}>{params.value ? params.value : ""}</Typography>
            </Grid>
          ) : (
            <></>
          );
      }
      return newHeader;
    }),
  });
  const [ordersActiveFilters, setOrdersActiveFilters] = useState({ ...ordersTableWrapperData.defaultFiltersObj });

  /** Function start */
  const handleTabChange = (event: React.ChangeEvent<{}>, value: string) => {
    const filters = _.cloneDeep(ordersActiveFilters);
    if (filters) {
      setDelveOrderListState({ ...delveOrderListState, tabValue: value, activeOrdersFilterChip: AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.ALL_FILTER_CHIP, ordersSearchValue: "" });
      if (filters.deliveryFlow) {
        filters.deliveryFlow = value;
      }
      if (filters.searchAttribute) {
        delete filters.searchAttribute;
      }
      ordersFilterCallback(filters, false, true, AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.ALL_FILTER_CHIP, false, value);
    }
  };

  const fetchDynamicFilters = (filters: any) => {
    dispatch(fetchDynamicFiltersOrdersDropdownValues({ filters: filters }));
  };

  const handleLoadMoreDisableState = useCallback(() => {
    let loadMoreState = ordersTableWrapperData.loadMore;
    loadMoreState.disabled = ordersData.totalPages ? ordersData.pageNumber + 1 >= ordersData.totalPages : true;
    setOrdersTableWrapperData({ ...ordersTableWrapperData, loadMore: loadMoreState });
  }, [ordersTableWrapperData, ordersData]);

  const getFilterChipsCount = (payload: any) => {
    setIsDisplayCount(false);
    let updatedPayload = _.cloneDeep(payload);
    dispatch(fetchOrdersFilterChipsDisplayCount({ filters: updatedPayload }));
    setIsDisplayCount(true);
  };

  const ordersSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    clearDelveSessionStorage();
    const activeFilters = _.cloneDeep(filters);
    onLoadRef.current = false;
    setDelveOrderListState({ ...delveOrderListState, ordersSearchValue: updatedSearchValue });
    setIsOrderSearched(false);
    if (updatedSearchValue) {
      setIsOrderSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey],
          };
          ordersFilterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const ordersFilterCallback = (activeFilters: any, isLoadMore?: boolean, isChipFilterClicked?: boolean, selectedChip?: any, isChipClicked?: boolean, tabValue?: any) => {
    const filters = _.cloneDeep(activeFilters);
    if (filters) {
      if (!isUndefined(sessionStorage.getItem("delveHubName")) && !isNull(sessionStorage.getItem("delveHubName"))) {
        filters.hubName.value = [];
        filters.hubName.value = [
          {
            id: JSON.parse(sessionStorage.getItem("delveHubName") || ""),
            value: JSON.parse(sessionStorage.getItem("delveHubName") || ""),
          },
        ];
        sessionStorage.removeItem("delveHubName");
      }

      if (!isUndefined(sessionStorage.getItem("delveDashboardDateRange")) && !isNull(sessionStorage.getItem("delveDashboardDateRange"))) {
        if (
          !isUndefined(sessionStorage.getItem("delveDashboardDateRange")) &&
          !isNull(sessionStorage.getItem("delveDashboardDateRange")) &&
          !isUndefined(JSON.parse(sessionStorage.getItem("delveDashboardDateRange") || "")?.startDate) &&
          !isNull(JSON.parse(sessionStorage.getItem("delveDashboardDateRange") || "")?.startDate)
        ) {
          filters.delveDateRange.value.startDate = startOfDay(JSON.parse(sessionStorage.getItem("delveDashboardDateRange") || "")?.startDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT);
        }

        if (
          !isUndefined(sessionStorage.getItem("delveDashboardDateRange")) &&
          !isNull(sessionStorage.getItem("delveDashboardDateRange")) &&
          !isUndefined(JSON.parse(sessionStorage.getItem("delveDashboardDateRange") || "")?.endDate) &&
          !isNull(JSON.parse(sessionStorage.getItem("delveDashboardDateRange") || "")?.endDate)
        ) {
          filters.delveDateRange.value.endDate = startOfDay(JSON.parse(sessionStorage.getItem("delveDashboardDateRange") || "")?.endDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT);
        }
        sessionStorage.removeItem("delveDashboardDateRange");
      }

      if (!isUndefined(sessionStorage.getItem("delveConsignmentList")) && !isNull(sessionStorage.getItem("delveConsignmentList"))) {
        filters.consignmentCode = {
          key: "c.consignmentCode",
          value: JSON.parse(sessionStorage.getItem("delveConsignmentList") || ""),
        };
        sessionStorage.removeItem("delveConsignmentList");
      }

      if (!isUndefined(sessionStorage.getItem("delveTabName")) && !isNull(sessionStorage.getItem("delveTabName"))) {
        let tabName = JSON.parse(sessionStorage.getItem("delveTabName") || "");
        if (tabName !== undefined && tabName !== "") {
          setDelveOrderListState({ ...delveOrderListState, tabValue: tabName });
          filters.deliveryFlow = tabName;
        }
        sessionStorage.removeItem("delveTabName");
      } else {
        if (tabValue === undefined) {
          if (filters.deliveryFlow !== delveOrderListState.tabValue) {
            filters.deliveryFlow = delveOrderListState.tabValue;
          }
        }
      }

      let payloadFilters = _.cloneDeep(filters);
      payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
      payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
      if (!isChipFilterClicked) {
        selectedChip = delveOrderListState.activeOrdersFilterChip;
      }
      const payload = createDelveOrdersFilterPayload(payloadFilters, ordersData, isLoadMore, selectedChip, isChipClicked, delveOrderListState.tabValue);
      if (!isLoadMore) {
        fetchDynamicFilters(payload);
      }
      let payloadFilters2 = _.cloneDeep(filters);
      payloadFilters2[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
      payloadFilters2.pageSize = ordersTableWrapperData.loadMore.pageSize;
      const payloadForCount = createChipsCountFilterPayload(payloadFilters2, ordersData, isLoadMore, selectedChip);
      getFilterChipsCount(payloadForCount);
      dispatch(fetchPlanningOrderListByFilter({ filters: payload }));
      setOrdersActiveFilters(filters);
    }
  };

  const handleConsignmentCellClick = useCallback(
    (params: CellParams) => {
      if (params.field === "consignmentId") {
        const selectedRow: any = params.rowData;
        const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
        setDelveOrderListState({ ...delveOrderListState, consignmentData: selectedConsignment.length ? selectedConsignment[0] : {}, openConsignmentDetailsPopup: true });
      }
    },
    [ordersData]
  );

  const handleRowClick = useCallback(
    (params: RowParams) => {
      const selectedRow: any = params.data;
      const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
      setDelveOrderListState({ ...delveOrderListState, consignmentData: selectedConsignment.length ? selectedConsignment[0] : {}, openConsignmentDetailsPopup: true });
    },
    [ordersData]
  );

  const handleExcelExport = (filters: any) => {
    if (filters) {
      let payloadFilters = _.cloneDeep(filters);
      payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
      const payload = createPayloadForExcelExport(payloadFilters, ordersData, false, delveOrderListState.activeOrdersFilterChip, delveOrderListState.tabValue);
      dispatch(exportToExcel({ payload: payload }));
    }
  };

  const handleOrdersHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case "excel":
            handleExcelExport(ordersActiveFilters);
            break;
          default:
            break;
        }
      }
    },
    [ordersActiveFilters, handleExcelExport]
  );

  const handleSnackbarClose = () => {
    setDelveOrderListState({ ...delveOrderListState, openSnackbar: false });
  };

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setDelveOrderListState({ ...delveOrderListState, openSnackbar: true });
  };

  const handleSnackbarExited = useCallback(() => {
    if (errorCode === AppConstants.DELVE_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
      dispatch(userLogout());
    }
  }, [dispatch, errorCode]);

  const handleChipFilter = useCallback(
    (chip: any, activeFilters: any) => {
      let filters = { ...activeFilters };
      setIsTabChange(true);
      onLoadRef.current = false;
      setIsOrderSearched(false);
      if (chip && chip.code !== delveOrderListState.activeOrdersFilterChip) {
        let isChipClicked = true;
        setDelveOrderListState({ ...delveOrderListState, activeOrdersFilterChip: chip.code });
        ordersFilterCallback(filters, false, true, chip.code, isChipClicked);
      }
    },
    [ordersFilterCallback, delveOrderListState.activeOrdersFilterChip]
  );

  const combine_partially_verfied__and_verified_issues = () => {
    let issue_count = 0;
    if (
      ordersFilterChipsDisplayCount !== undefined &&
      ordersFilterChipsDisplayCount.delveConsignmentCount !== undefined &&
      typeof ordersFilterChipsDisplayCount.delveConsignmentCount === "object" &&
      Object.keys(ordersFilterChipsDisplayCount.delveConsignmentCount).length > 0
    ) {
      Object.keys(ordersFilterChipsDisplayCount.delveConsignmentCount).forEach((status_name: any) => {
        if (status_name === AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.PARTIALLY_VERIFIED_ISSUES_CHIP || status_name === AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.VERIFIED_ISSUES_CHIP) {
          issue_count = issue_count + ordersFilterChipsDisplayCount.delveConsignmentCount[status_name];
        }
      });
    }
    return issue_count;
  };

  const getFilterChips = () => {
    let filterChips = [] as any;
    if (delveOrderListState.tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value) {
      filterChips = AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.FILTER_CHIPS;
    } else if (delveOrderListState.tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
      filterChips = AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.RETURN_TYPE_FILTER_CHIPS;
    }
    return filterChips.map((chip: any, index: number) => (
      <Grid
        key={`chipFilterItem-${index}`}
        item
        className={`chipFilterItem ${index === 0 ? "firstItem" : ""} ${delveOrderListState.activeOrdersFilterChip === chip.code ? "active" : ""} ${
          delveOrderListState.tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value ? "fiveItemsWidth" : "fourItemsWidth"
        }`}
        onClick={() => handleChipFilter(chip, ordersActiveFilters)}
      >
        <Grid container className="chipContainer">
          <Grid item className="chipItem name">
            <Typography>{chip.value}</Typography>
          </Grid>
          <Grid item className="chipItem count">
            <Typography>{`${
              chip.code === AppConstants.DELVE_CONSTANTS.CONSIGNMENTS_DATA.ISSUES_REPORTED_CHIP
                ? combine_partially_verfied__and_verified_issues()
                : ordersFilterChipsDisplayCount.delveConsignmentCount[chip.code] || "0"
            }`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    ));
  };

  const closeConsignmentDetailsPopup = () => {
    setDelveOrderListState({ ...delveOrderListState, consignmentData: {}, openConsignmentDetailsPopup: false });
  };

  const clearDelveSessionStorage = () => {
    sessionStorage.removeItem("delveDashboardDateRange");
    sessionStorage.removeItem("delveHubName");
    sessionStorage.removeItem("delveConsignmentList");
    sessionStorage.removeItem("delveTabName");
  };

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error]);

  useEffect(() => {
    if (isOrderSearched && isDisplayCount) {
      const chips = Object.keys(ordersFilterChipsDisplayCount.delveConsignmentCount).filter((key: string) => ordersFilterChipsDisplayCount.delveConsignmentCount[key] > 0);
      let selectedChip;
      let selectedChipValue;
      if (chips.length === 2) {
        selectedChip = chips.filter((key: string) => key !== AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      } else {
        selectedChip = chips.filter((key: string) => key === AppConstants.CNC_CONSTANTS.ALL_FILTER_CHIP);
        selectedChipValue = selectedChip[0];
      }
      handleChipFilter({ code: chips.length ? selectedChipValue : delveOrderListState.activeOrdersFilterChip }, ordersActiveFilters);
    } else {
      setOrdersListToShow(delveOrdersDataTransform(ordersData, delveOrderListState.tabValue) as any);
      handleLoadMoreDisableState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersFilterChipsDisplayCount, ordersData]);

  useEffect(() => {
    if (delveOrderListState.tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value) {
      const ordersHeaderData = ordersTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === "hubName") {
            header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === "deliverySlot") {
            header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === "deliveryStatus") {
            header.filterObj.items = ordersFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === "driver") {
            header.filterObj.items = ordersFiltersDropdowns.driverList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: ordersHeaderData });
    } else if (delveOrderListState.tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
      const returnTabHeaderData = ordersTableWrapperData.ReturnTabHeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === "hubName") {
            header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === "deliverySlot") {
            header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === "deliveryStatus") {
            header.filterObj.items = ordersFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === "driver") {
            header.filterObj.items = ordersFiltersDropdowns.driverList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, ReturnTabHeaderData: returnTabHeaderData });
    }
    setIsOrderSearched(false);
    if (!onLoadRef.current) {
      ordersFilterCallback(ordersActiveFilters);
    }
    onLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubCode]);

  useEffect(() => {
    if (delveOrderListState.tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value) {
      let headerData = ordersTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === "hubName") {
            header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === "deliverySlot") {
            header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === "deliveryStatus") {
            header.filterObj.items = ordersFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === "driver") {
            header.filterObj.items = ordersFiltersDropdowns.driverList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, HeaderData: headerData });
    } else if (delveOrderListState.tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
      let returnTabHeaderData = ordersTableWrapperData.ReturnTabHeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === "hubName") {
            header.filterObj.items = ordersFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === "deliverySlot") {
            header.filterObj.items = ordersFiltersDropdowns.deliverySlotList || [];
          }
          if (header.filterObj.fieldName === "deliveryStatus") {
            header.filterObj.items = ordersFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === "driver") {
            header.filterObj.items = ordersFiltersDropdowns.driverList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({ ...ordersTableWrapperData, ReturnTabHeaderData: returnTabHeaderData });
    }
  }, [ordersFiltersDropdowns]);

  useEffect(() => {
    return () => {
      clearDelveSessionStorage();
    };
  }, []);

  return (
    <div className={componentClasses.root}>
      {(loading || excelLoading) && <Loader></Loader>}
      <Grid className={componentClasses.container} container>
        <TabContext value={delveOrderListState.tabValue}>
          <Tabs value={delveOrderListState.tabValue} onChange={handleTabChange} className={componentClasses.dashboardTabRootStyle}>
            <Tab label={TAB_VALUES_CONSTANT.DELIVERIES.name} value={TAB_VALUES_CONSTANT.DELIVERIES.value} />
            <Tab label={TAB_VALUES_CONSTANT.RETURNS.name} value={TAB_VALUES_CONSTANT.RETURNS.value}  />
          </Tabs>
          <Grid className="planningItem content" item>
            <Grid container className={componentClasses.tabPanelContainer}>
              <Grid item className="panelItem chipFilter">
                <Grid container className="chipsContainer">
                  {getFilterChips()}
                </Grid>
              </Grid>
              <Grid item className="panelItem gridWrapper">
                <GridWrapper
                  showHeader={true}
                  checkboxSelection={false}
                  headerData={delveOrderListState.tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value ? ordersTableWrapperData.HeaderData : ordersTableWrapperData.ReturnTabHeaderData}
                  rowData={ordersListToShow}
                  isTabChange={isTabChange}
                  loading={loading ? false : tableLoading}
                  searchTypes={ordersTableWrapperData.SEARCH_TYPES}
                  defaultFilter={ordersTableWrapperData.defaultFiltersObj}
                  activeFilter={ordersActiveFilters}
                  headerButtons={ordersTableWrapperData.headerButtons}
                  title={ordersTableWrapperData.title}
                  disableSelectionOnClick={true}
                  sortModel={ordersTableWrapperData.sortModel as SortModel}
                  searchValue={delveOrderListState.ordersSearchValue}
                  loadMore={{ ...ordersTableWrapperData.loadMore, rowCount: ordersData.totalElements || 0 }}
                  headerBtnClick={handleOrdersHeaderButtonClick}
                  searchCallback={ordersSearchFilterHandler}
                  filterGrid={ordersFilterCallback}
                  onCellClick={handleConsignmentCellClick}
                  onRowClick={handleRowClick}
                  tabValue={delveOrderListState.tabValue}
                  disableFilterCount={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </TabContext>
        <CustomSnackbar
          open={delveOrderListState.openSnackbar}
          handleClose={handleSnackbarClose}
          onExited={handleSnackbarExited}
          autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
          message={snackbarMessage.current}
          type={snackbarType.current}
        />
      </Grid>
      {delveOrderListState.openConsignmentDetailsPopup && (
        <DelveOrderDetails
          open={delveOrderListState.openConsignmentDetailsPopup}
          consignment={delveOrderListState.consignmentData}
          closePopup={closeConsignmentDetailsPopup}
          tabValue={delveOrderListState.tabValue}
        />
      )}
    </div>
  );
};
export default DelveOrderList;
