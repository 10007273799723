import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
//redux
import { AppState } from "../config/redux/reducers";
import { fetchHubDetails } from "./redux/posSlice";
import { userLogout } from "../Login/redux/loginSlice";
//constants
import AppConstants from "../constants";
//common components
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import { CellParams, RowData } from "../common/GridWrapper/DataTable";
import CustomSnackbar from "../common/CustomSnackbar";
//material-ui components
import { Grid, Typography } from "@material-ui/core";
//assests
import viewIcon from './../assets/icons/Eye.svg';
//styles
import { hubsStyles } from "./pos.styles";
import { lastMileHubDataTransform, createHubDetailsPayload } from "../mocks/pos/response.transforms";
import PosDetails from "./PosDetails";

const POSManagement = () => {
    const componentClasses = hubsStyles();
    const dispatch = useDispatch();
    const { countryCode } = useSelector(
        (state: AppState) => state.common
    );
    const { hubsData, loading, tableLoading, error, errorCode, closedDetails, isQRRegenerated } = useSelector(
        (state: AppState) => state.pos
    );
    const onLoadRef = useRef(true);
    const [posListToShow, setPosListToShow] = useState([]);
    const [userSearchValue, setUserSearchValue] = useState("");
    const [tableWrapperData, setTableWrapperData] = useState({
        ...AppConstants.POS_CONSTANTS.TABLE_WRAPPER_DATA, HeaderData: AppConstants.POS_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
            let newHeader = { ...header };
            if (newHeader.field === 'hubId') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="hubIdLink">{params.value}</Typography>
                )
            }
            if (newHeader.field === 'actions') {
                newHeader.renderCell = (params: CellParams) => (
                    <Grid container className={componentClasses.actionsHeader}>
                        <Grid className="actionItem" item>
                            <Typography className="actionsLink"><img src={viewIcon} alt="view" /></Typography>
                        </Grid>
                    </Grid>
                );
            }
            if (newHeader.field === 'serviceType') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="serviceType">{params && params.value && params.value.join(" , ")}</Typography>
                );
            }
            if (newHeader.field === 'category') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="serviceType">{params && params.value && params.value.join(" , ")}</Typography>
                );
            }
            return newHeader;
        })
    });
    const [activeFilters, setActiveFilters] = useState({ ...tableWrapperData.defaultFiltersObj });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
    const snackbarMessage = useRef("");

    const [hubData, setHubData] = useState({});
    const [openHubDetailsPopup, setOpenHubDetailsPopup] = useState(false);

    const searchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
        const activeFilters = cloneDeep(filters);
        onLoadRef.current = false;
        setUserSearchValue(updatedSearchValue);
        if (updatedSearchValue) {
            for (const searchKey in data) {
                if (data[searchKey]) {
                    activeFilters.searchAttribute = {
                        key: searchKey,
                        value: data[searchKey]
                    }
                    filterCallback(activeFilters);
                    return;
                }
            }
        }
    };

    const filterCallback = useCallback(
        (filters: any, isLoadMore?: boolean) => {
            if (filters) {
                let payloadFilters = cloneDeep(filters);
                payloadFilters.pageSize = tableWrapperData.loadMore.pageSize;
                const payload = createHubDetailsPayload(payloadFilters, hubsData, isLoadMore, countryCode);
                dispatch(
                    fetchHubDetails({
                        params: payload
                    })
                );
                setActiveFilters(filters);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, hubsData, countryCode],
    );

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleSessionExpired = useCallback(
        () => {
            if (errorCode === AppConstants.USERS_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
                dispatch(
                    userLogout()
                );
            }
        },
        [dispatch, errorCode],
    );

    const handleSnackbarOnExited = useCallback(
        () => {
            handleSessionExpired();
        },
        [handleSessionExpired],
    );

    const closeHubDetailsPopup = () => {
        setOpenHubDetailsPopup(false);
    }

    const setHubDataOnClick = useCallback(
        (rowData: RowData) => {
            let selectedRow: RowData = { ...rowData };
            const selectedHub: any = { ...rowData, tableRecord: selectedRow }
            setHubData(selectedHub);
        },
        []
    )

    const handleHubCellClick = useCallback(
        (params: CellParams) => {
            if (params.field === 'hubId' || params.field === "actions") {
                setHubDataOnClick(params.rowData ? params.rowData : {} as RowData);
                setOpenHubDetailsPopup(true);
            }
        },
        [setHubDataOnClick],
    );

    const handleLoadMoreDisableState = useCallback(
        () => {
            let loadMoreState = tableWrapperData.loadMore;
            loadMoreState.disabled = hubsData.totalPages ? hubsData.pageNumber + 1 >= hubsData.totalPages : true;
            setTableWrapperData({ ...tableWrapperData, loadMore: loadMoreState });
        },
        [tableWrapperData, hubsData]
    );

    const openSnackbarPopup = (msg: string, type: string) => {
        snackbarMessage.current = msg;
        snackbarType.current = type;
        setOpenSnackbar(true);
    }

    useEffect(() => {
        if (error) {
            openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
        }
    }, [error])

    useEffect(() => {
        if (closedDetails && isQRRegenerated) {
            filterCallback(activeFilters);
        }
    }, [closedDetails, isQRRegenerated, activeFilters, filterCallback]);


    useEffect(() => {
        setPosListToShow(lastMileHubDataTransform(hubsData) as any);
        handleLoadMoreDisableState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubsData, countryCode]);

    useEffect(() => {
        if (countryCode) {
            filterCallback(tableWrapperData.defaultFiltersObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode])

    return (
        <div className={componentClasses.root}>
            {loading && <Loader></Loader>}
            <Grid className={componentClasses.container} container>
                <Grid className="userItem content" item>
                    <GridWrapper
                        showHeader={true}
                        checkboxSelection={false}
                        headerData={tableWrapperData.HeaderData}
                        rowData={posListToShow}
                        activeFilter={activeFilters}
                        loading={loading ? false : tableLoading}
                        searchTypes={tableWrapperData.SEARCH_TYPES}
                        defaultFilter={tableWrapperData.defaultFiltersObj}
                        title={tableWrapperData.title}
                        disableSelectionOnClick={true}
                        searchValue={userSearchValue}
                        loadMore={{ ...tableWrapperData.loadMore, rowCount: hubsData.totalElements || 0 }}
                        searchCallback={searchFilterHandler}
                        filterGrid={filterCallback}
                        onCellClick={handleHubCellClick}
                    />
                </Grid>
                <PosDetails open={openHubDetailsPopup} hub={hubData} closePopup={closeHubDetailsPopup}></PosDetails>
                <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarOnExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />

            </Grid>
        </div >
    );
};

export default POSManagement;

