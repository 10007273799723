import moment from "moment";

import AppConstants from "../../constants/AppConstants";
import { ALL_ROUTES_TEXT_CONTANT, ALL_ROUTE_INITIAL_STATE_INTERFACE, ROUTE_TYPE } from "../../RoutePlanning/AllRoutes/utils/AllRouteConstant";
import { enumerateDaysBetweenDates, isArrayNonEmpty, isNonEmptyArray, isNonEmptyObject, isNull, isObject, isUndefined } from "../../utils/helpers.utils";
import { designMapIcon, designMapLabel, designSubRouteMapIcon, designSubrouteMapLabel, getColorBasedOnRouteIndex, getSlotName } from "../../RoutePlanning/AllRoutes/utils/AllRouteUtils";

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
  let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
  const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
  const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
  if (selectAllIdx > -1) {
    currentValue = [];
  }
  if (selectNoneIdx > -1) {
    currentValue = [];
  }
  return currentValue;
};
export const createGetAllPlannedRoutesPayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE, hubId: any) => {
  return {
    timeSlot: {
      startTime: moment(stateObj.selectedDate.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(stateObj.selectedDate.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
    },
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    hubCode: hubId,
  };
};

export const createGetAllDropdownsPayload = (stateObj: any, hubId: any) => {
  return {
    timeSlot: {
      startTime: moment(stateObj.selectedDate.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(stateObj.selectedDate.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
    },
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    hubCode: hubId,
  };
};

export const createGetAllUnPlannedRoutesPayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE, hubId: any, hubName: any) => {
  let dateRange = [];
  let isDateRange = true;
  if (stateObj.slotValue) {
    const selectAllIdx = stateObj.slotValue.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = stateObj.slotValue.indexOf(AppConstants.SELECT_NONE.value);
    if (stateObj.selectedDate) {
      const allDates = enumerateDaysBetweenDates(stateObj.selectedDate.startDate, stateObj.selectedDate.endDate);
      if (selectAllIdx > -1 || selectNoneIdx > -1 || !stateObj.slotValue.length) {
        isDateRange = true;
        dateRange.push({
          startTime: moment(stateObj.selectedDate.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(stateObj.selectedDate.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
        });
      } else {
        isDateRange = false;
        stateObj.slotValue.forEach((slot: any) => {
          const splitSlot = slot.split("-");
          const startSlot = splitSlot[0].split(":");
          const endSlot = splitSlot[1].split(":");
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              });
            });
          }
        });
      }
    }
  }
  return {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    hubCode: hubId,
    hubName: hubName.length ? hubName[0].name : "",
    isDateRange: isDateRange,
    deliveryZones: getFilterAttributeValue(stateObj.zoneValue, true),
    deliveryStatuses: getFilterAttributeValue(stateObj.statusValue, true),
    timeSlot: dateRange,
  };
};

export const createPublishRoutesPayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  return stateObj && stateObj.selectedConsignments && stateObj.selectedConsignments.length > 0
    ? {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        ids: stateObj.selectedConsignments.map((route: any) => route.id),
      }
    : null;
};

export const createUpdateRoutesPayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  const routeData = stateObj.selectedRoute;
  if (routeData && routeData.id && routeData.routeId) {
    let routePayload: any = {
      countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      route: {
        id: routeData.id,
        hubCode: routeData.hubCode,
        hubName: routeData.hubName,
        consignmentsIds: routeData.consignmentsIds,
        totalConsignment: routeData.consignmentsIds.length,
        totalCrates: routeData.totalCrates,
        plannedDate: routeData.plannedDate,
        deliveryArea: routeData.deliveryArea,
        deliverySlot: routeData.deliverySlot,
      },
    };

    if (stateObj.driverValue !== AppConstants.SELECT_NONE.value) {
      routePayload.route.assignee = {
        id: stateObj.driverValue,
      };
    }
    if (stateObj.helperValue !== AppConstants.SELECT_NONE.value) {
      routePayload.route.helper = {
        id: stateObj.helperValue,
      };
    }
    if (stateObj.vehicleValue !== AppConstants.SELECT_NONE.value) {
      routePayload.route.assignedVehicle = {
        id: stateObj.vehicleValue,
      };
    }
    return routePayload;
  }
  return {};
};

export const getStatusWithoutUnderScore = (status: any) => {
  if (status && typeof status === "string") {
    return status.replaceAll("_", " ");
  }
  return "";
};

export const createFetchRemoveOrderFromRoutesPayload = (routeData: any, orderData: any, displayRouteStatus: any) => {
  return {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    consignmentIds: [orderData.consignmentCode],
    displayRouteStatus: displayRouteStatus,
    routeId: routeData.id,
  };
};

export const createAddOrderToAnotherRoutePayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE, hubId: any, consignmentCode: any) => {
  return {
    timeSlot: {
      startTime: moment(stateObj.selectedDate.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(stateObj.selectedDate.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND),
    },
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    hubCode: hubId,
    consignmentCodes: Array.isArray(consignmentCode) ? [...consignmentCode] : [consignmentCode],
  };
};

export const createAddOrdersToRoutesPayload = (selectedConsignments: any, selectedConsignmentToChangeRoute: any, routeObj: any, displayRouteStatus: string) => {
  const request_payload = {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    consignmentIds: [] as Array<any>,
    displayRouteStatus: displayRouteStatus,
    routeId: routeObj.id,
  };
  if (!isUndefined(selectedConsignments) && isArrayNonEmpty(selectedConsignments)) {
    selectedConsignments.forEach((item: any) => {
      if (isObject(item) && !isUndefined(item.consignmentCode)) {
        request_payload.consignmentIds.push(item.consignmentCode);
      } else {
        request_payload.consignmentIds.push(item);
      }
    });
  } else if (!isUndefined(selectedConsignmentToChangeRoute) && isArrayNonEmpty(selectedConsignmentToChangeRoute)) {
    selectedConsignmentToChangeRoute.forEach((item: any) => {
      if (isObject(item) && !isUndefined(item.consignmentCode)) {
        request_payload.consignmentIds.push(item.consignmentCode);
      } else {
        request_payload.consignmentIds.push(item);
      }
    });
  }
  return request_payload;
};

export const createDraftUnplannedRoutePayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE, hubId: any, hubName: any) => {
  const deliverySlot = getUniqueValues(stateObj?.selectedConsignments, "slotValue");
  const request_payload = {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    hubCode: hubId,
    hubName: hubName.length && hubName[0]?.name ? hubName[0].name : "",
    deliveryStatuses: [],
    deliveryZones: [],
    timeSlot: [],
    isDateRange: false,
  } as any;
  if (!isUndefined(deliverySlot) && isNonEmptyArray(deliverySlot)) {
    deliverySlot.forEach((slot: any) => {
      if (!isUndefined(slot)) {
        const splitSlot = slot.split("-");
        if (!isUndefined(splitSlot) && isNonEmptyArray(splitSlot)) {
          const startSlot = splitSlot[0].split(":");
          const endSlot = splitSlot[1].split(":");
          request_payload.timeSlot.push({
            startTime: moment(stateObj.selectedDate.startDate, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
            endTime: moment(stateObj.selectedDate.endDate, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
          });
        }
      }
    });
  }
  return request_payload;
};

const getUniqueAddressValues = (array: any[], key?: string) => {
  return Array.from(new Set(key ? array.map((item: any) => item?.address && item?.address[key]).filter((item: any) => item !== undefined) : array));
};

const getUniqueValues = (array: any[], key?: string) => {
  return Array.from(new Set(key ? array.map((item: any) => item[key]).filter((item: any) => item !== undefined) : array));
};

export const createNewRouteProceedPayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  return {
    consignmentTransitTime: {},
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    route: {
      id: stateObj?.draftedRouteDetails?.id,
      creationTime: stateObj?.draftedRouteDetails?.creationTime,
      createdBy: stateObj?.draftedRouteDetails?.createdBy,
      countryDetails: stateObj?.draftedRouteDetails?.countryDetails,
      hubCode: stateObj?.draftedRouteDetails?.hubCode,
      hubName: stateObj?.draftedRouteDetails?.hubName,
      plannedDate: stateObj?.draftedRouteDetails?.plannedDate,
      deliveryArea: getUniqueAddressValues(stateObj?.selectedConsignments, "area"),
      deliverySlot: getUniqueValues(stateObj?.selectedConsignments, "slotValue"),
      totalCrates: 0,
      deliveryDate: moment(stateObj.selectedDate.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      transitTime: 0,
      totalConsignment: stateObj?.selectedConsignments?.length,
      consignmentsIds: stateObj?.selectedConsignments?.map((item: any) => item.consignmentCode),
    },
  };
};

export const mapUnplannedOrderToDraftedRoutePayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE, route: any, status: string) => {
  return {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    consignmentIds: stateObj?.selectedConsignments?.map((item: any) => item.consignmentCode),
    displayRouteStatus: status,
    routeId: route?.id,
  };
};

export const createNewSubRouteRequestPayload = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  return {
    routeId: stateObj?.selectedRoute?.id,
    consignments: [...stateObj.selectedConsignmentsForSubRoute],
  };
};

export const createSubRoutesUpdatePayload = (selectedSubRoute: any, subDriverValue: any, subHelperValue: any, subVehicleValue: any) => {
  if (selectedSubRoute?.id) {
    let routePayload: any = {
      countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      route: {
        id: selectedSubRoute.id,
        hubCode: selectedSubRoute.hubCode,
        hubName: selectedSubRoute.hubName,
        consignmentsIds: selectedSubRoute.consignmentsIds,
        totalConsignment: selectedSubRoute.consignmentsIds.length,
        totalCrates: selectedSubRoute.totalCrates,
        plannedDate: selectedSubRoute.plannedDate,
        deliveryArea: selectedSubRoute.deliveryArea,
        deliverySlot: selectedSubRoute.deliverySlot,
      },
    };

    if (subDriverValue && subDriverValue !== AppConstants.SELECT_NONE.value) {
      routePayload.route.assignee = { id: subDriverValue };
    }
    if (subHelperValue && subHelperValue !== AppConstants.SELECT_NONE.value) {
      routePayload.route.helper = { id: subHelperValue };
    }
    if (subVehicleValue && subVehicleValue !== AppConstants.SELECT_NONE.value) {
      routePayload.route.assignedVehicle = { id: subVehicleValue };
    }
    return routePayload;
  }
  return {};
};

export const transformPlannedRoute = (plannedRoutes: any) => {
  let routeDetailsObj = {} as any;

  let colorIndex = -1;
  let totalRecords: number = 0;
  let totalCrates: number = 0;
  let totalOrders: number = 0;

  let consignmentMarkers = [] as any;
  let mapCenter = { lat: 0, lng: 0 };
  let firstMapCordinates = { lat: 0, lng: 0 };

  if (plannedRoutes && plannedRoutes.allRoutes && plannedRoutes.allRoutes.PLANNED && isObject(plannedRoutes.allRoutes.PLANNED) && isNonEmptyObject(plannedRoutes.allRoutes.PLANNED)) {
    Object.keys(plannedRoutes.allRoutes.PLANNED).forEach((slotValue: string) => {
      if (slotValue && plannedRoutes.allRoutes.PLANNED[slotValue] && plannedRoutes.allRoutes.PLANNED[slotValue].elements) {
        const { elements } = plannedRoutes.allRoutes.PLANNED[slotValue];
        if (elements && Array.isArray(elements) && elements.length > 0) {
          elements.forEach((route: any) => {
            if (route && route.routeId) {
              if (!routeDetailsObj.hasOwnProperty(route.routeId)) {
                totalRecords++;
                totalCrates = totalCrates + route?.totalCrates;
                totalOrders = totalOrders + route?.totalConsignment;
                colorIndex++;
                let color = getColorBasedOnRouteIndex(colorIndex);
                routeDetailsObj[route.routeId] = {
                  ...route,
                  mapCenter: { lat: 0, lng: 0 },
                  routeDataObj: {}, // Area or Sub Route
                  isChecked: false,
                  isOpened: false,
                  openOrderActionPopUp: false,
                  color: color,
                  isSubRoutePresent: false,
                  subRouteCreated: false,
                  ordersCount: 0,
                };
                delete routeDetailsObj[route.routeId].orders;
                delete routeDetailsObj[route.routeId].sourceDetails;
                routeDetailsObj[route.routeId].routeDataObj[ALL_ROUTES_TEXT_CONTANT.AREA_DATA] = {
                  routeType: ROUTE_TYPE.ROUTE_AREA,
                  slotWiseOrders: {},
                  ordersCount: 0,
                  isChecked: false,
                  isOpened: true,
                };
              }

              // Orders based on Slot and Areas
              if (route && route.orders && isNonEmptyArray(route.orders)) {
                route.orders.forEach((order: any) => {
                  if (!isUndefined(order?.address?.area) && !isNull(order?.address?.area)) {
                    const { area } = order?.address;
                    if (!routeDetailsObj[route.routeId].routeDataObj.hasOwnProperty(area)) {
                      routeDetailsObj[route.routeId].routeDataObj[area] = { routeType: ROUTE_TYPE.ROUTE_AREA, slotWiseOrders: {}, ordersCount: 0, isChecked: false, isOpened: true };
                    }

                    if (order.slotValue) {
                      if (!routeDetailsObj[route.routeId].routeDataObj[area].slotWiseOrders.hasOwnProperty(order.slotValue)) {
                        routeDetailsObj[route.routeId].routeDataObj[area].slotWiseOrders[order.slotValue] = { orders: [], isChecked: false, isOpened: false };
                      }

                      if (!routeDetailsObj[route.routeId].routeDataObj[ALL_ROUTES_TEXT_CONTANT.AREA_DATA].slotWiseOrders.hasOwnProperty(order.slotValue)) {
                        routeDetailsObj[route.routeId].routeDataObj[ALL_ROUTES_TEXT_CONTANT.AREA_DATA].slotWiseOrders[order.slotValue] = { orders: [], isChecked: false, isOpened: false };
                      }

                      if (order?.address?.longitude) {
                        let location = { lat: parseFloat(order.address.latitude || 0), lng: parseFloat(order.address.longitude || 0) };

                        if (routeDetailsObj[route.routeId].mapCenter.lat === 0) {
                          routeDetailsObj[route.routeId].mapCenter = location;
                        }

                        if (mapCenter.lat === 0) {
                          firstMapCordinates = location;
                          mapCenter = location;
                        }
                        const hubMapDetails = {
                          name: `${route?.routeId} - ${order.consignmentCode}`,
                          routeId: `${route?.routeId}`,
                          deliveryArea: `${order?.address && order?.address?.area}`,
                          deliverySlot: `${getSlotName(order?.slotValue)}`,
                          consignmentCode: `${order?.consignmentCode}`,
                          location: location,
                          color: routeDetailsObj[route.routeId].color,
                          icon: designMapIcon(routeDetailsObj[route.routeId].color),
                          label: designMapLabel(routeDetailsObj[route.routeId].ordersCount),
                        };
                        consignmentMarkers.push(hubMapDetails);
                        routeDetailsObj[route.routeId].ordersCount++;
                        routeDetailsObj[route.routeId].routeDataObj[area].ordersCount++;
                        routeDetailsObj[route.routeId].routeDataObj[ALL_ROUTES_TEXT_CONTANT.AREA_DATA].ordersCount++;
                        const orderObj = { isSubRoute: false, isOpened: false, isChecked: false, openOrderActionPopUp: false, mapCenter: location };
                        routeDetailsObj[route.routeId].routeDataObj[area].slotWiseOrders[order.slotValue].orders.push({
                          ...order,
                          ...orderObj,
                          orderIndex: routeDetailsObj[route.routeId].ordersCount,
                        });
                        routeDetailsObj[route.routeId].routeDataObj[ALL_ROUTES_TEXT_CONTANT.AREA_DATA].slotWiseOrders[order.slotValue].orders.push({
                          ...order,
                          ...orderObj,
                          orderIndex: routeDetailsObj[route.routeId].ordersCount,
                        });
                      }
                    }
                  }
                });
              }

              if (!isUndefined(route?.subRoutes) && isNonEmptyArray(route?.subRoutes)) {
                routeDetailsObj[route.routeId].isSubRoutePresent = true;
                routeDetailsObj[route.routeId].subRouteCreated = true;
                route.subRoutes.forEach((subRoute: any) => {
                  if (subRoute && subRoute.routeId && isNonEmptyArray(subRoute?.orders)) {
                    if (!routeDetailsObj[route.routeId].routeDataObj.hasOwnProperty(subRoute.routeId)) {
                      routeDetailsObj[route.routeId].routeDataObj = {
                        [subRoute.routeId]: {
                          ...subRoute,
                          routeType: ROUTE_TYPE.SUB_ROUTE,
                          parentRouteName: `${route?.routeId}`,
                          slotWiseOrders: {},
                          ordersCount: 0,
                          isChecked: false,
                          isOpened: false,
                          id: subRoute?.id,
                        },
                        ...routeDetailsObj[route.routeId].routeDataObj,
                      };
                      delete routeDetailsObj[route.routeId].routeDataObj[subRoute.routeId].orders;
                    }
                    subRoute?.orders.forEach((order: any) => {
                      if (!isUndefined(order?.address?.area) && !isNull(order?.address?.area)) {
                        if (order?.slotValue) {
                          if (!routeDetailsObj[route.routeId].routeDataObj[subRoute.routeId].slotWiseOrders.hasOwnProperty(order.slotValue)) {
                            routeDetailsObj[route.routeId].routeDataObj[subRoute.routeId].slotWiseOrders[order.slotValue] = { orders: [], isChecked: false, isOpened: false };
                          }
                          if (order?.address?.longitude) {
                            let location = { lat: parseFloat(order.address.latitude || 0), lng: parseFloat(order.address.longitude || 0) };

                            const hubMapDetails = {
                              name: `${subRoute?.routeId} - ${order.consignmentCode}`,
                              routeId: `${subRoute?.routeId}`,
                              parentRouteId: `${subRoute?.parentRouteId}`,
                              parentRouteName: `${route?.routeId}`,
                              deliveryArea: `${order?.address && order?.address?.area}`,
                              deliverySlot: `${getSlotName(order?.slotValue)}`,
                              consignmentCode: `${order?.consignmentCode}`,
                              location: location,
                              color: routeDetailsObj[route.routeId].color,
                              icon: designSubRouteMapIcon(routeDetailsObj[route.routeId].color),
                              label: designSubrouteMapLabel(routeDetailsObj[route.routeId].ordersCount, subRoute?.routeId),
                            };
                            consignmentMarkers.push(hubMapDetails);
                            routeDetailsObj[route.routeId].ordersCount++;
                            routeDetailsObj[route.routeId].routeDataObj[subRoute.routeId].ordersCount++;
                            routeDetailsObj[route.routeId].routeDataObj[subRoute.routeId].slotWiseOrders[order.slotValue].orders.push({
                              ...order,
                              orderIndex: routeDetailsObj[route.routeId].ordersCount,
                              routeType: ROUTE_TYPE.SUB_ROUTE,
                              isSubRoute: true,
                              isOpened: false,
                              isChecked: false,
                              mapCenter: location,
                            });
                          }
                        }
                      }
                    });
                  }
                });
              }
            }
          });
        }
      }
    });
  }

  return { routeDetailsObj, totalRecords, totalCrates, totalOrders, firstMapCordinates, mapCenter, consignmentMarkers };
};
