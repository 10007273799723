import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { Grid, Typography, SvgIcon, Button } from "@material-ui/core";

import { useStyles } from "./Users.styles";
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import { ReactComponent as deleteIcon } from './../assets/icons/delete-icon.svg';

import {
  createAddUserPayload,
  createEditUserPayload,
  createFilterPayload,
  createUserResetPwdPayload,
  createUserUAEPayload,
  getStatusField,
  lastMileUserDataTransform
} from "../mocks/users/response.transforms";
import { AppState } from "../config/redux/reducers";
import { userLogout } from "../Login/redux/loginSlice";
import { fetchUsersByFilter, fetchAddUserDropdownValues, updateUser, createUserViaSA, resetUserCreationStatus, resetUserUpdationStatus, usersResetPwd, updateUserInUaeService, createUserInUaeService, fetchCountryUrl, exportUserInformationToExcel, bulkDeleteUser, fetchUsersBuildVersion } from "./redux/usersSlice";
import { CellParams, RowData, SortModel } from "../common/GridWrapper/DataTable";
import { ACTION_BUTTON } from "../constants/UsersConstants";
import { combineTwoArrayAndRemoveDuplicate } from "../utils/helpers.utils";

import UserDetails from "./UserDetails";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import CustomSnackbar from "../common/CustomSnackbar";
import UserAddOrEditDetails from "./UserAddOrEditDetails";
import ResetPwd from "./ResetPwd";
import CustomDialog from "../common/Dialog";

const Users = () => {
  const componentClasses = useStyles();
  const publishDialogPaperProps = { classes: { root: componentClasses.customDialogPaperPropsRoot } };
  const dispatch = useDispatch();
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const onLoadRef = useRef(true);

  const { data, tableLoading, loading, userCreationStatus, userRegisteredStatus, userCreationMsg, userUpdateStatus, addUserDropdowns, userUpdateMsg, resetPwdSuccess, error, errorCode, resetPwdMsg } = useSelector((state: AppState) => state.users);
  const { role, isAdmin, isDispatcher, isOnlyExpressDispatcher, isOnlyStandardDispatcher, isLastMileViewer, isOnlyStdLastMileViewer, isOnlyExpLastMileViewer } = useSelector((state: AppState) => state.userLogin);
  const { countryCode, hubCode, commonDropdowns } = useSelector((state: AppState) => state.common);
  const { isCollectionPointManager } = useSelector((state: AppState) => state.userLogin);

  const [listToShow, setListToShow] = useState([]);
  const [userData, setUserData] = useState({});
  const [userSearchValue, setUserSearchValue] = useState("");
  const [openUserDetailsPopup, setOpenUserDetailsPopup] = useState(false);
  const [openUserAddPopup, setOpenUserAddPopup] = useState(false);
  const [openUserResetPwdPopup, setOpenUserResetPwdPopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [tableWrapperData, setTableWrapperData] = useState({
    ...AppConstants.USERS_CONSTANTS.TABLE_WRAPPER_DATA, HeaderData: AppConstants.USERS_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'status') {
        newHeader.renderCell = (params: any) => (
          <Grid className={componentClasses.statusHeaderContainer} container alignItems="center">
            <Typography className={['tag', getStatusField('color', params.value)].join(" ")}>{params.value ? params.value : ""}</Typography>
          </Grid>
        );
      }
      if (newHeader.field === 'userId') {
        newHeader.renderCell = (params: CellParams) => (
          <Typography className="userIdLink">{params.value}</Typography>
        )
      }
      if (newHeader.field === 'actions') {
        newHeader.renderCell = (params: CellParams) => (
          <Grid container className={componentClasses.actionsHeader}>
            <Grid className="actionItem" item>
              <SvgIcon className="icon editIcon" onClick={handleEditUserIconClick(params)} component={editIcon} />
              {isAdmin && <SvgIcon className="icon deleteIcon" onClick={handleUserDeleteIconClick(params)} component={deleteIcon} />}
            </Grid>
          </Grid>
        );
      }
      return newHeader;
    })
  });
  const [activeFilters, setActiveFilters] = useState({ ...tableWrapperData.defaultFiltersObj });
  const [userManagementState, setUserManagementState] = useState({ openDownloadUserDataPopUp : false, openUserDeletePopUp : false, userRowData : {} as any, reselctSelectedCheckbox: false })
  const [selectedRoutes, setSelectedRoutes] = useState([] as any[]);

  const fetchAllFilters = () => {
    dispatch(fetchAddUserDropdownValues());
  }

  const isBtnHidden = useCallback(() => {
    return (
      isDispatcher ||
      isCollectionPointManager ||
      isOnlyExpressDispatcher ||
      isOnlyStandardDispatcher ||
      isLastMileViewer ||
      isOnlyStdLastMileViewer ||
      isOnlyExpLastMileViewer
    );
  }, [
    isDispatcher,
    isCollectionPointManager,
    isOnlyStandardDispatcher,
    isOnlyExpressDispatcher,
    isLastMileViewer,
    isOnlyStdLastMileViewer,
    isOnlyExpLastMileViewer,
  ]);

  const handleHeaderButtonsDisableState = () => {
    let headerButtons = tableWrapperData.headerButtons.map((btn: any) => {
      switch (btn.name) {
        case 'addUser':
          btn.hide = false;
          if (isBtnHidden()) {
            btn.hide = true;
          }
          break;
        case ACTION_BUTTON.BULK_DELETE: 
          btn.disabled = !selectedRoutes.length;
          btn.displayName=  selectedRoutes.length ? ` Delete ${selectedRoutes.length} User`: 'Delete Users'
          break;
        default:
          break;
      }
      return btn;
    });
    setTableWrapperData({ ...tableWrapperData, headerButtons: headerButtons });
  };

  const handleLoadMoreDisableState = useCallback(
    () => {
      let loadMoreState = tableWrapperData.loadMore;
      loadMoreState.disabled = data.totalPages ? data.pageable.pageNumber + 1 >= data.totalPages : true;
      setTableWrapperData({ ...tableWrapperData, loadMore: loadMoreState });
    },
    [tableWrapperData, data]
  );

  const handleEditUserIconClick = (params: CellParams) => () => {
    setUserDataOnClick(params.rowData ? params.rowData : {} as RowData);
    setIsEdit(true);
    setOpenUserAddPopup(true);
  }

  const filterCallback = (filters: any, isLoadMore?: boolean) => {
    if (filters) {
      const requestContinuationToken = data.pageable.requestContinuation;
      let payloadFilters = _.cloneDeep(filters);
      payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
      const payload = createFilterPayload(payloadFilters, data, isLoadMore, role);
      dispatch(fetchUsersByFilter({filters: payload, requestContinuationToken: requestContinuationToken }));
      if (!isLoadMore) {
        dispatch(fetchUsersBuildVersion({filters: payload}));
      }
      setActiveFilters(filters);
    }
  }

  const searchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    onLoadRef.current = false;
    const searchKeys = Object.keys(data);
    setUserSearchValue(updatedSearchValue);
    if (updatedSearchValue) {
      for (const searchKey of searchKeys) {
        data[searchKey] = _.trim(data[searchKey]);
        if (data[searchKey] && data[searchKey].length >= AppConstants.SEARCH_BOX_LIMIT) {
          let searchPayload = _.cloneDeep(filters);
          searchPayload.search = data;
          filterCallback(searchPayload);
          return;
        } else {
          openSnackbarPopup(AppConstants.SEARCH_TEXT_LIMIT, AppConstants.SNACKBAR.TYPES.ERROR);
          return;
        }
      }
    }
  };

  const setUserDataOnClick = (rowData: RowData) => {
    let selectedRow: RowData = { ...rowData };
    delete selectedRow.actions;
    const selectedUser: any = { ...rowData.actions, tableRecord: selectedRow }
    setUserData(selectedUser);
  }

  const handleUserCellClick = (params: CellParams) => {
    if (params.field === 'userId') {
      setUserDataOnClick(params.rowData ? params.rowData : {} as RowData);
      setOpenUserDetailsPopup(true);
    }
  }

  const handleUserDeleteIconClick = (params: any) => () => {
   let rowData = {} as any; 
   if(params?.rowData){
    rowData = params.rowData
    setUserManagementState({...userManagementState, userRowData : rowData, openUserDeletePopUp: true})
   }
  }

  const handleDeleteBulkUserButtonClick = () => {
    setUserManagementState({...userManagementState, openUserDeletePopUp: true, userRowData: {}})
  }

  const handleDeleteBulkUserDialogClose = () => {
    setUserManagementState({...userManagementState, openUserDeletePopUp: false, userRowData: {}})
  }

  const handleDeleteUserAction = async () => {
    const bulkDeletePayload = {usernames : []} as any;
    if(userManagementState?.userRowData?.actions?.username){
      bulkDeletePayload.usernames.push(userManagementState?.userRowData?.actions?.username)
    }else if(selectedRoutes?.length > 0){
      selectedRoutes.forEach((user: any) => {
        bulkDeletePayload.usernames.push(user?.actions?.username);
      })
    }

    if(bulkDeletePayload?.usernames?.length > 0){
      const response : any = await dispatch(bulkDeleteUser({ payload: bulkDeletePayload })); 
      setUserManagementState({...userManagementState, openUserDeletePopUp: false, userRowData: {}})
      if(response?.payload?.successfullyDeletedUsers?.length > 0 && response?.payload?.message){
        openSnackbarPopup(response?.payload?.message, AppConstants.SNACKBAR.TYPES.SUCCESS);
        onLoadRef.current = false;
        filterCallback(activeFilters);
      }else {
        let message: string = AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        if(response?.payload?.message){
          message = response?.payload?.message;
        }
        openSnackbarPopup(message, AppConstants.SNACKBAR.TYPES.ERROR);
      }
    }
  }

  const handleHeaderButtonClick = (btnObj: any) => {  
    if (btnObj && btnObj.type) {
      switch (btnObj.type) {
        case 'addUser':
          openUserUpdatePopup(false);
          setUserManagementState({...userManagementState, reselctSelectedCheckbox: true});
          break;
        case AppConstants.BUTTONS.DOWNLOAD_ICON:          
          handleDownloadUserDataDialogOpen();
          break;
        case ACTION_BUTTON.BULK_DELETE:
          if(selectedRoutes?.length > 0){
            handleDeleteBulkUserButtonClick();
          }
          else{
            openSnackbarPopup('No user record found to delete.', AppConstants.SNACKBAR.TYPES.ERROR);
          }
          break;
        default:
          break;
      }
    }
  }

  const closeUserDetailsPopup = () => {
    setOpenUserDetailsPopup(false);
  }

  const closeUserAddPopup = () => {
    setOpenUserAddPopup(false);
    setUserManagementState({...userManagementState, reselctSelectedCheckbox: false});
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  }

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSessionExpired = () => {
    if (errorCode === AppConstants.USERS_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
      dispatch(userLogout());
    }
  }

  const isDriverOrHelper = (userRole: string) => {
    return AppConstants.USERS_CONSTANTS.DRIVER_OR_VEHICLE_ROLES.filter((role: any) => role.code === userRole).length;
  }

  const updateUserdata = useCallback(
    (params: any) => {
      if (params.deliveryType === AppConstants.USERS_CONSTANTS.EXPRESS) {
        const payload = createEditUserPayload(_.cloneDeep(params), addUserDropdowns);
        if (payload) {
          dispatch(updateUser({ payload: payload }));
        }
      } else {
        const payload = createEditUserPayload(_.cloneDeep(params), addUserDropdowns);
        if (payload) {
          dispatch(updateUser({ payload: payload }));
        }
        if (isDriverOrHelper(params.userRole)) {
          const payloadUAE = createUserUAEPayload(_.cloneDeep(params), addUserDropdowns);
          if (payloadUAE) {
            dispatch(updateUserInUaeService({ payload: payloadUAE }));
          }
        }
      }
    },
    [addUserDropdowns, isDriverOrHelper, dispatch],
  )

  const openUserUpdatePopup = (isEdit: boolean) => {
    setIsEdit(isEdit);
    setOpenUserAddPopup(true);
  }

  const handleUserDetailsActions = (type: string) => {
    if (type === 'update') {
      closeUserDetailsPopup();
      openUserUpdatePopup(true);
    } else {
      setOpenUserResetPwdPopup(true);
    }
  }

  const handleAddUser = useCallback(
    (params: any) => {
      if (params.deliveryType === AppConstants.USERS_CONSTANTS.EXPRESS) {
        const payload = createAddUserPayload(_.cloneDeep(params), addUserDropdowns);
        dispatch(
          createUserViaSA({
            payload: payload
          })
        );
      } else {
        const payload = createAddUserPayload(_.cloneDeep(params), addUserDropdowns);
        dispatch(
          createUserViaSA({
            payload: payload
          })
        );
        if (isDriverOrHelper(params.userRole)) {
          const payloadUAE = createUserUAEPayload(_.cloneDeep(params), addUserDropdowns);
          if (payloadUAE) {
            dispatch(
              createUserInUaeService({
                payload: payloadUAE
              })
            );
          }
        }
      }
    },
    [addUserDropdowns, dispatch]
  )

  const fetchCurrentCountry = () => {
    dispatch(fetchCountryUrl({payload: countryCode}))
  }

  const handleUserUpdateActions = useCallback(
    (params: any) => {
      if (params.type === 'submit') {
        if (params.isEdit) {
          updateUserdata(params.values);
        } else {
          handleAddUser(params.values);
        }
      } else {
        closeUserAddPopup();
      }
    },
    [handleAddUser, updateUserdata],
  )

  const closeUserResetPwdPopup = () => {
    setOpenUserResetPwdPopup(false);
  }

  const handleUserResetPwd = (values: any) => {
    const payload = createUserResetPwdPayload(_.cloneDeep(values));
    dispatch(usersResetPwd({userdata: payload}))
  }

  const handleUserResetPwdActions = useCallback(
    (params: any) => {
      if (params.type === 'submit') {
        handleUserResetPwd(params.values);
      } else {
        closeUserResetPwdPopup();
      }
    },
    [handleUserResetPwd, closeUserResetPwdPopup],
  )

  const handleSnackbarOnExited = useCallback(
    () => {
      handleSessionExpired();
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.SUCCESS) {
        if (userCreationStatus) {
          dispatch(
            resetUserCreationStatus()
          )
        }
        if (userUpdateStatus) {
          dispatch(
            resetUserUpdationStatus()
          )
        }
      }
    },
    [dispatch, handleSessionExpired, userCreationStatus, userUpdateStatus],
  );

  const handleDownloadUserDataDialogOpen = () => {
    setUserManagementState({...userManagementState, openDownloadUserDataPopUp: true})
  }

  const handleDownloadUserDataDialogClose = () => {
    setUserManagementState({...userManagementState, openDownloadUserDataPopUp: false})
  }

  const handleDownloadUserDataAction = () => {  
    let payloadFilters : any = _.cloneDeep(activeFilters);
    payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
    const payload = createFilterPayload(payloadFilters, data, false, role);
    dispatch(exportUserInformationToExcel({payload: payload}));
    handleDownloadUserDataDialogClose();
  }

  const renderDialogDetailsContent = (heading: string, subHeading ?: string) => {
    return (
      <Grid className={componentClasses.dialogDetailsContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>{`${heading}`}</Typography>
        </Grid>
        {subHeading && <Grid className="contentItem desc" item>
          <Typography>{`${subHeading}`}</Typography>
        </Grid>}
      </Grid>
    );
  };

  const handleDownloadUserDataDialogContent = () => {
    const heading: string = `${AppConstants.POP_UP_MESSAGE_CONSTANT.DOWNLOAD_USER_INFORMATION}`;
    const subHeading: string = `${AppConstants.POP_UP_MESSAGE_CONSTANT.DOWNLOAD_USER_INFORMATION_CONFIRMATION}`;
    return renderDialogDetailsContent(heading, subHeading);
  }

  const handleDeleteBulkUserDialogContent = () => {
    const heading: string = `${AppConstants.USERS_CONSTANTS.POP_UP_MESSAGE_CONSTANT.DELETE_USER_RECORD}`;
    const subHeading: string = `${AppConstants.USERS_CONSTANTS.POP_UP_MESSAGE_CONSTANT.DOWNLOAD_USER_INFORMATION_CONFIRMATION}`;
    return renderDialogDetailsContent(heading, subHeading);
  }

  const handleDownloadUserDataDialogActions = () => {
    return (
      <Grid className={componentClasses.dialogDetailsActionContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleDownloadUserDataDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => handleDownloadUserDataAction()}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const handleDeleteBulkUserDialogActions = () => {
    return (
      <Grid className={componentClasses.dialogDetailsActionContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleDeleteBulkUserDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => handleDeleteUserAction()}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  }

  const updateSelectedRoutes = (params: any) => {  
    setUserManagementState({...userManagementState, reselctSelectedCheckbox: false});
    setSelectedRoutes(params?.rows);
  }

  useEffect(() => {
    if (userRegisteredStatus) {
      fetchCurrentCountry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRegisteredStatus])

  useEffect(() => {
    onLoadRef.current = true;
  }, [])

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    if (userCreationStatus) {
      closeUserAddPopup();
      openSnackbarPopup(userCreationMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      filterCallback(tableWrapperData.defaultFiltersObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreationStatus, userCreationMsg])

  useEffect(() => {   
    if (userUpdateStatus) {
      closeUserDetailsPopup();
      closeUserAddPopup();
      openSnackbarPopup(userUpdateMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      filterCallback(tableWrapperData.defaultFiltersObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdateStatus])

  useEffect(() => {
    if (resetPwdSuccess) {
      openSnackbarPopup(resetPwdMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      closeUserResetPwdPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPwdSuccess]);

  useEffect(() => {   
    setListToShow(lastMileUserDataTransform(listToShow, data, countryCode) as any);
    handleLoadMoreDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    handleHeaderButtonsDisableState();
  }, [listToShow, selectedRoutes]);

  useEffect(() => {
    const headerData = tableWrapperData.HeaderData.map((header: any) => {
      if (header.filterObj) {
        if (header.filterObj.fieldName === 'hub') {
          header.filterObj.items = commonDropdowns.hubCodeList[countryCode] || [];
        }
        if (header.filterObj.fieldName === 'role') {
          header.filterObj.items = addUserDropdowns.rolesList || [];
        }
        if (header.filterObj.fieldName === 'status') {
          header.filterObj.items = addUserDropdowns.statusList || [];
        }
        if (header.filterObj.fieldName === 'type') {
          header.filterObj.items = addUserDropdowns.userTypeList || [];
        }
      }
      return header;
    });
    setTableWrapperData({ ...tableWrapperData, HeaderData: headerData });
    if (!onLoadRef.current) {
      const hubsToAdd = hubCode.map((hub: string) => ({ name: hub, value: hub }));
      const updatedFilters = { ...activeFilters, hub: { ...activeFilters.hub, value: hubsToAdd } };
      filterCallback(updatedFilters);
    }
    onLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubCode]);

  useEffect(() => {
    if (tableWrapperData && tableWrapperData.HeaderData) {
      let headerData = tableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === 'hub') {
            header.filterObj.items = commonDropdowns.hubCodeList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
          }
          if (header.filterObj.fieldName === 'role') {
            header.filterObj.items = addUserDropdowns.rolesList || [];
          }
          if (header.filterObj.fieldName === 'status') {
            header.filterObj.items = addUserDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === 'type') {
            header.filterObj.items = addUserDropdowns.userTypeList || [];
          }
          if (header.filterObj.fieldName === 'buildVersion') {
            header.filterObj.items = addUserDropdowns.buildVersionList || [];
          }
          if (header.filterObj.fieldName === 'vehicleType') { 
            header.filterObj.items = addUserDropdowns.vehicleTypeList || [];
          }
          if (header.filterObj.fieldName === 'vendor') { 
            const venderExpress = addUserDropdowns.vendorList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
            const vendorStandard = addUserDropdowns.vendorListStd[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
            header.filterObj.items = combineTwoArrayAndRemoveDuplicate(venderExpress, vendorStandard, 'value')
          }
          if (header.filterObj.fieldName === 'deliveryType') {
            header.filterObj.items = addUserDropdowns.deliveryTypeList || [];
          }
          if (header.filterObj.fieldName === 'serviceType') {
            header.filterObj.items = addUserDropdowns.serviceTypeList || [];
          }
        }
        return header;
      });
      setTableWrapperData({ ...tableWrapperData, HeaderData: headerData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonDropdowns, addUserDropdowns])

  useEffect(() => {
    if (isBtnHidden()) {
      let headerDataWithoutActions = tableWrapperData.HeaderData.map((data: any) => {
        if (data.field === "actions") {
          data.hide = true;
        }
        return data;
      });
      setTableWrapperData({ ...tableWrapperData, HeaderData: headerDataWithoutActions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBtnHidden])

  return (
    <div className={componentClasses.root}>
      {loading && <Loader></Loader>}
      <Grid className={componentClasses.container} container>
        <Grid className="userItem content" item>
          <GridWrapper
            showHeader={true}
            checkboxSelection={isAdmin ? true : false}
            headerData={tableWrapperData.HeaderData}
            rowData={listToShow}
            loading={loading ? false : tableLoading}
            searchTypes={tableWrapperData.SEARCH_TYPES}
            defaultFilter={tableWrapperData.defaultFiltersObj}
            activeFilter={activeFilters}
            headerButtons={tableWrapperData.headerButtons}
            title={tableWrapperData.title}
            disableSelectionOnClick={true}
            searchValue={userSearchValue}
            sortModel={tableWrapperData.sortModel as SortModel}
            loadMore={{ ...tableWrapperData.loadMore, rowCount: data.totalElements || 0 }}
            headerBtnClick={handleHeaderButtonClick}
            searchCallback={searchFilterHandler}
            onCellClick={handleUserCellClick}
            filterGrid={filterCallback}
            fetchAllFilters={fetchAllFilters}
            onSelectionChange={updateSelectedRoutes}
            reselctSelectedCheckbox={userManagementState.reselctSelectedCheckbox}
          />
        </Grid>
        {openUserDetailsPopup && <UserDetails open={openUserDetailsPopup} user={userData} handleDialogAction={handleUserDetailsActions} closePopup={closeUserDetailsPopup}></UserDetails>}
        {openUserAddPopup && <UserAddOrEditDetails open={openUserAddPopup} user={userData} isEdit={isEdit} handleDialogAction={handleUserUpdateActions} closePopup={closeUserAddPopup}></UserAddOrEditDetails>}
        {openUserResetPwdPopup && <ResetPwd open={openUserResetPwdPopup} user={userData} handleDialogAction={handleUserResetPwdActions} closePopup={closeUserResetPwdPopup}></ResetPwd>}
        {openSnackbar && <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarOnExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />}
        {userManagementState.openDownloadUserDataPopUp && <CustomDialog open={userManagementState.openDownloadUserDataPopUp} PaperProps={publishDialogPaperProps} title={<></>} content={handleDownloadUserDataDialogContent()} actions={handleDownloadUserDataDialogActions()} handleClose={handleDownloadUserDataDialogClose} />}
        {userManagementState.openUserDeletePopUp && <CustomDialog open={userManagementState.openUserDeletePopUp} PaperProps={publishDialogPaperProps} title={<></>} content={handleDeleteBulkUserDialogContent()} actions={handleDeleteBulkUserDialogActions()} handleClose={handleDeleteBulkUserDialogClose} />}
      </Grid>
    </div >
  );
};

export default Users;
