import React, { useRef, useCallback, useState, useEffect } from "react";

const useTimer = () => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const fn = useCallback((callback, timeout = 0) => {
    clearTimeout(timer.current as NodeJS.Timeout);
    timer.current = setTimeout(() => {
      callback();
    }, timeout);
  }, []);

  const clearTimeoutHandler = () => {
    return clearTimeout(timer.current as NodeJS.Timeout);
  };

  useEffect(() => {
    return () => {
      clearTimeoutHandler();
    };
  }, []);

  return { fn, clearTimeoutHandler };
};

export default useTimer