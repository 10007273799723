import React,{ReactChild} from 'react';
import {Chip} from "@material-ui/core";

interface ChipComponentProps {
  editable?: boolean;
  item?: Array<string>;
  onDeleteCallback: (value: string) => void;
  DeleteIcon:any
}

 const ChipComponent=(props:ChipComponentProps)=> {
 const {editable, item, onDeleteCallback, DeleteIcon} =props

  const GetDelatableChips = () => {
    return (
      <>
        {item && item.length >0 && item.map((element: any, index: number) => {
          return  <Chip
          key={element+index}
          label={element}
          onDelete={()=>onDeleteCallback(element)}
          deleteIcon={<DeleteIcon />}
        />
        })}
      </>
    );
  
  };
  const GetUnDelatableChips = () => {
    return (
      <>
        {item && item.length >0 && item.map((element: any, index: number) => {
          return  <Chip label={element} key={element+index}/>
        })}
      </>
    );
    
  };

  return (
  <>
  {editable ? <GetDelatableChips /> :<GetUnDelatableChips />}
 
  </>
  );
}
export default ChipComponent