import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    searchSelect: {
      '& .MuiOutlinedInput-root': {
        minWidth: 160,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "8px 0px 0px 8px",
        color: COLORS.DOVE_GRAY
      },
      '& .MuiOutlinedInput-input': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: "none"
      },
      '& .open .MuiOutlinedInput-notchedOutline': {
        borderBottom: `1px solid ${COLORS.BLACK_INPUT_BORDER}`
      }
    },
    searchTextbox: {
      '& .MuiOutlinedInput-root': {
        minWidth: 300
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderLeft: "none",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      }
    },
    searchSelectPaperProps: {
      maxHeight: 200,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderTop: "none",
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: "none"
    }
  })
);