import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { CNCOrderListState } from "./clickAndCollectSlice.types";
import AppConstants from "../../constants";
import { transformSelectValues, saveFile } from "../../utils/helpers.utils";
import moment from "moment";

export const fetchDynamicFiltersOrdersDropdownValues = createAsyncThunk(
  "ClickAndCollect/FetchDynamicFiltersOrdersDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeliveryReasons = createAsyncThunk(
  "ClickAndCollect/FetchDeliveryReasons",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/get-delivery-reasons/${params}?type=CLICK_AND_COLLECT`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningOrderListByFilter = createAsyncThunk(
  "ClickAndCollect/FetchOrdersByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrdersFilterChipsDisplayCount = createAsyncThunk(
  "ClickAndCollect/FetchOrdersFilterChipsDisplayCount",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/displayStatusCount',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConsignmentHistoryAndProducts = createAsyncThunk(
  "CNCConsignmentDetails/FetchConsignmentHistoryAndProducts",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/statusHistoryProducts/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCNCStatuses = createAsyncThunk(
  "CNCConsignmentDetails/FetchCNCStatuses",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/cncStatuses/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "ClickAndCollect/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/order',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportInvoicePdf = createAsyncThunk(
  "ClickAndCollect/ExportInvoicePdf",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/view-invoice',
        method: EApiMiddlewareMethods.POST,
        data: params,
        responseType: "bytes"
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cncReplanning = createAsyncThunk(
  "CNCConsignmentDetails/cncReplanning",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/replanning',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAllConsignmentsState = createAsyncThunk("CNCConsignmentDetails/UpdateAllConsignmentsState", ({
  consignments
}: {
  consignments: any
}) => {
  return consignments;
});

export const closeDialog = createAsyncThunk("CNCConsignmentDetails/CloseDialog", () => {
  return false;
});

export const resetReplanningSuccess = createAsyncThunk("CNCConsignmentDetails/ResetReplanningSuccess", () => {
  return false;
});

const initialState: CNCOrderListState = {
  loading: false,
  tableLoading: false,
  excelLoading: false,
  cncFilterChipsDisplayCount: {
    consignmentCount: {
      NEW: 0,
      PACKED: 0,
      READY_TO_COLLECT: 0,
      COLLECTED: 0,
      UNCOLLECTED: 0,
      CANCELLED: 0,
    }
  },
  ordersFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    collectionHubCodeList: [],
    collectionHubNameList: [],
    typeList: [],
    statusList: [],
    collectionSlotList: [],
    cancellationReasonList: [],
  },
  reasonsDropdownList: [],
  deliveryReasons: {},
  ordersData: {
    elements: [],
    pageNumber: 0
  },
  consignmentStatuses: [],
  cncStatuses: [],
  productsList: [],
  error: "",
  errorCode: "",
  invoiceData: "",
  invoiceUrl: "",
  displayInvoice: false,
  invoiceMessage: "",
  invoiceConsignment: "",
  exportError: "",
  cncReplanningSuccess: false,
  cncDataAfterReplanning: {}
};

const clickAndCollectSlice = createSlice({
  name: "ClickAndCollect",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchDynamicFiltersOrdersDropdownValues.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.cncReplanningSuccess = false;
        state.ordersFiltersDropdowns = {
          hubCodeList: transformSelectValues(payload.hubCodes || []),
          hubNameList: transformSelectValues(payload.hubNames || []),
          collectionHubCodeList: transformSelectValues(payload.collectionHubCodes || []),
          collectionHubNameList: transformSelectValues(payload.collectionHubNames || []),
          typeList: transformSelectValues(payload.types || []),
          collectionSlotList: transformSelectValues(payload.pickUpSlots || []),
          statusList: transformSelectValues(payload.statuses || []),
          cancellationReasonList: transformSelectValues(payload.undeliveredReasons || [])
        };
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDeliveryReasons.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDeliveryReasons.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let deliveryReasonsList: any = [];
        if (payload.reasons) {
          deliveryReasonsList = payload.reasons.map((reason: any) => {
            return {
              code: reason.reasonCode,
              value: reason.reasonDescription
            };
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
        state.deliveryReasons = payload.reasons;
      })
      .addCase(fetchDeliveryReasons.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.cncReplanningSuccess = false;
        state.cncFilterChipsDisplayCount = payload;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningOrderListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningOrderListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.cncReplanningSuccess = false;
        state.ordersData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.elements]
              : [...state.ordersData.elements, ...payload.elements],
          totalElements: payload.totalElements,
          totalPages: payload.totalPages
        };
      })
      .addCase(fetchPlanningOrderListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchConsignmentHistoryAndProducts.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchConsignmentHistoryAndProducts.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.consignmentStatuses = payload.statuses && payload.statuses.length ? payload.statuses : [];
        state.productsList = payload.products && payload.products.length ? payload.products : [];
      })
      .addCase(fetchConsignmentHistoryAndProducts.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchCNCStatuses.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchCNCStatuses.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.cncStatuses = transformSelectValues(payload.statuses && payload.statuses.length ? payload.statuses : []);
      })
      .addCase(fetchCNCStatuses.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportInvoicePdf.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(exportInvoicePdf.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        const data = payload.Invoices && payload.Invoices.length ? payload.Invoices[0] : [];
        state.invoiceConsignment = payload.Invoices && payload.Invoices.length ? payload.Invoices[0].ConsignmentNo : "";
        state.displayInvoice = payload.Invoices && payload.Invoices.length;
        if(data?.FileUrl){
            state.invoiceUrl = data?.FileUrl
        }
        else if(data?.FileDataBytes){
            const pdfData = `data:application/pdf;base64,${data.FileDataBytes}`;
            state.invoiceData = pdfData ;
          }
        state.invoiceMessage = payload.Message;
      })
      .addCase(exportInvoicePdf.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `CNC_Consignments_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })

      .addCase(cncReplanning.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(cncReplanning.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.cncReplanningSuccess = true;
        state.cncDataAfterReplanning = payload;
      })
      .addCase(cncReplanning.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.cncReplanningSuccess = false;
      })
      .addCase(closeDialog.fulfilled, (state, action) => {
        state.cncDataAfterReplanning = {};
      })
      .addCase(resetReplanningSuccess.fulfilled, (state, action) => {
        state.cncReplanningSuccess = false
      })
  },
});

export default clickAndCollectSlice.reducer;
