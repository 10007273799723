import React, {CSSProperties} from "react";
//Material UI Components
import { Grid } from "@material-ui/core";
//styles
import { useStyles } from "./bucket.styles";

interface Classes {
  subHeading?: string;
  tileHeading?: string;
  tileData?: string;
}
interface StyleType {
  tile?: CSSProperties;
  content?: CSSProperties;
}

interface IBucketComponent{
    tileHeading: string
    tileData?: string;
    subHeading?: string;
    classes?: Classes;
    style?: StyleType;
}
 interface CODPortalHeaderProps {
    config : IBucketComponent[];
}

const BucketComponent = (props: CODPortalHeaderProps) => {
    const classes = useStyles();
    const {config } = props;
    
   const HeaderContent = () => {
     return (
       <>
         {config.map(function (conf: any, index: number) {
           return (
             <Grid
               item
               className={classes.tilesView}
               key={index}
               style={conf?.style?.tile && { ...conf.style.tile }}
             >
               <Grid
                 container
                 className={classes.globalStatusTiles}
                 style={conf?.style?.content && { ...conf.style.content }}
               >
                 <Grid item>
                   <Grid item className={`${conf?.classes?.tileHeading} ${classes.tileHeading} `}>
                     {conf.tileHeading}
                   </Grid>
                   {conf.subHeading && (
                     <Grid
                       item
                       className={`${classes.tileSubHeading} ${conf?.classes?.subHeading} `}
                     >
                       {conf.subHeading}
                     </Grid>
                   )}
                 </Grid>
                 {conf.tileData !== null && (
                   <Grid
                     item
                     className={`${classes.tileData} ${conf?.classes?.tileData} `}
                   >
                     {conf.tileData}
                   </Grid>
                 )}
               </Grid>
             </Grid>
           );
         })}
       </>
     );
   };

    return (
        <>
            <Grid container spacing={3} >
               <HeaderContent />
            </Grid>
        </>
    );
};

export default BucketComponent;
