import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { PlanningOrderListState, BulkDeletedRouteInterface } from "./planningSlice.types";
import AppConstants from "../../constants";
import { transformSelectValues, saveFile } from "../../utils/helpers.utils";
import { getDropdownsData, getPlannedRoutesData, transformBundleData, transformedDataViewRoute } from "../../mocks/routePlanning/response.transforms";
import {getCrateDetails} from '../../utils/business/crate'
import { API_RESPONSE_STATUS } from "../../constants/CommonConstants";

export const fetchPlanningList = createAsyncThunk(
  "RoutePlanning/FetchByCountry",
  async (
    {
      requestContinuationToken,
      searchBy,
      searchValue,
    }: {
      requestContinuationToken?: string;
      searchBy: string;
      searchValue: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/${searchBy}/${searchValue}`,
        method: EApiMiddlewareMethods.GET,
      } as any;

      if (requestContinuationToken) {
        request.headers = {
          "x-requestcontinuation-token": requestContinuationToken,
        };
      }
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDynamicFiltersOrdersDropdownValues = createAsyncThunk(
  "RoutePlanning/FetchDynamicFiltersOrdersDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDynamicFiltersRoutesDropdownValues = createAsyncThunk(
  "RoutePlanning/FetchDynamicFiltersRoutesDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeliveryReasons = createAsyncThunk(
  "RoutePlanning/FetchDeliveryReasons",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `order/get-delivery-reasons/Default`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeliveryReasonsCountryBased = createAsyncThunk(
  "RoutePlanning/FetchDeliveryReasonsCountryBased",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `order/get-delivery-reasons/Default_Dispatcher`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningOrderListByFilter = createAsyncThunk(
  "RoutePlanning/FetchOrdersByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchExpressSlotDropdownValues = createAsyncThunk(
  "Common/FetchExpressSlotDropdownValues",
  async (
    {
      countryCode
    }: {
      countryCode: any;
    },
    { rejectWithValue }
  ) => {
    let params = countryCode ? countryCode : sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    try {
      const request = {
        url: `filter/all/${params}/EXP`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrderLogs = createAsyncThunk(
  "RoutePlanning/FetchOrderLogs",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `logs/getOrderLogs?consignmentCode=${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true)
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSlots= createAsyncThunk(
  "RoutePlanning/FetchSlots",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `/slots/availableSlots`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, false , true , true,false , false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningRouteListByFilter = createAsyncThunk(
  "RoutePlanning/FetchRoutesByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllDrivers = createAsyncThunk(
  "RoutePlanning/FetchAllDrivers",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'assignee/drivers',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllHelpers = createAsyncThunk(
  "RoutePlanning/FetchAllHelpers",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'assignee/helpers',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllVehicles = createAsyncThunk(
  "RoutePlanning/FetchAllVehicles",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'vehicle/load',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createDraftRoute = createAsyncThunk(
  "RoutePlanning/CreateDraftRoute",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/draft/create',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadAllSlots = createAsyncThunk(
  "RoutePlanning/LoadAllSlots",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/draft/load/slots',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const consignmentReplanning = createAsyncThunk(
  "RoutePlanning/ConsignmentReplanning",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/replanning',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const replanDuringPartialRejection = createAsyncThunk(
  "RoutePlanning/ReplanAndPartialRejection",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'v2/order/replan-order-line-items',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const fetchAllPlannedRoutes = createAsyncThunk(
  "RoutePlanning/FetchAllPlannedRoutes",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/view',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAndEditRoute = createAsyncThunk(
  "RoutePlanning/CreateAndEditRoute",
  async (
    {
      payload,
      editMode
    }: {
      payload: any,
      editMode: boolean
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/edit',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const discardRouteChanges = createAsyncThunk(
  "RoutePlanning/DiscardRouteChanges",
  async (
    {
      params,
      redirect
    }: {
      params: any,
      redirect?: boolean
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/discard',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const publishRoutes = createAsyncThunk(
  "RoutePlanning/PublishRoutes",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/publish',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoute = createAsyncThunk(
  "RoutePlanning/DeleteRoute",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/delete',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const bulkDeleteRoute = createAsyncThunk(
  "RoutePlanning/BulkDeleteRoute",
  async (
    {
      payload
    }: {
      payload: BulkDeletedRouteInterface
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'v2/route/delete',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateConsignmentStatus = createAsyncThunk(
  "RoutePlanning/UpdateOrderStatus",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/updateRouteStatus',
        method: EApiMiddlewareMethods.PUT,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrdersFilterChipsDisplayCount = createAsyncThunk(
  "RoutePlanning/FetchOrdersFilterChipsDisplayCount",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/displayStatusCount',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoutesFilterChipsDisplayCount = createAsyncThunk(
  "RoutePlanning/FetchRoutesFilterChipsDisplayCount",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/displayStatusCount',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRouteConsignments = createAsyncThunk(
  "RoutePlanning/FetchRouteConsignments",
  async ({
    id
  }: {
    id: string
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `route/get-consignments-by-route/${id}`,
        method: EApiMiddlewareMethods.POST,
        data: {}
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "RoutePlanning/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/order',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportRoutesToExcel = createAsyncThunk(
  "RoutePlanning/ExportRoutesToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/route',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportInvoicePdf = createAsyncThunk(
  "RoutePlanning/ExportInvoicePdf",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/view-invoice',
        method: EApiMiddlewareMethods.POST,
        data: params,
        responseType: "bytes"
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSubRouteById = createAsyncThunk(
  "RoutePlanning/FetchSubRouteById",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
     const {routeId, fetchOrders} = params
    try {
      const request = {
        url: `/route/sub-routes?parentRouteId=${routeId}&fetchOrders=${fetchOrders}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrderById = createAsyncThunk(
  "RoutePlanning/FetchOrderById",
  async (
    {
      id
    }: {
      id: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `/order/${id}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllPlannedRoutesNew = createAsyncThunk("RoutePlanning/FetchAllPlannedRoutesNew", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "route/planned-route",
      method: EApiMiddlewareMethods.POST,
      data: payload,
    } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
// export const clearPlanningStateValue = createAsyncThunk("RoutePlanning/ClearPlanningState", (
//   {
//     params
//   }:
//     {
//       params: any
//     }
// ) => {
//   return params.value;
// });

export const updateAllConsignmentsState = createAsyncThunk("RoutePlanning/UpdateAllConsignmentsState", ({
  consignments
}: {
  consignments: any
}) => {
  return consignments;
});

export const clearPublishedRoutesErrors = createAsyncThunk("RoutePlanning/ClearPublishedRoutesErrors", () => {
  return [];
});

export const clearPublishedRoutes = createAsyncThunk("RoutePlanning/ClearPublishedRoutes", () => {
  return [];
});

export const resetPlanningError = createAsyncThunk("RoutePlanning/ResetPlanningError", () => {
  return "";
});

export const clearUpdatedRoute = createAsyncThunk("RoutePlanning/ClearUpdatedRoute", () => {
  return null;
});

export const clearDeletedRoute = createAsyncThunk("RoutePlanning/ClearDeletedRoute", () => {
  return null;
});

export const clearBulkDeletedRoute = createAsyncThunk("RoutePlanning/clearBulkDeletedRoute", () => {
  return null;
});

export const resetCreateRouteSuccess = createAsyncThunk("RoutePlanning/ResetCreateRouteSuccess", () => {
  return false;
});

export const resetDraftRoute = createAsyncThunk("RoutePlanning/ResetDraftRoute", () => {
  return {};
});

export const resetConsignmentReplanningSuccess = createAsyncThunk("RoutePlanning/ResetConsignmentReplanningSuccess", () => {
  return false;
});

export const clearCurrentSlot = createAsyncThunk("RoutePlanning/ClearCurrentSlot", () => {
  return null;
});
export const resetAddressupdateStatus = createAsyncThunk("Planning/resetAddressupdateStatus", () => {
   return false;
});

export const resetPlanningData = createAsyncThunk("Planning/resetPlanningData", () => {
  return false;
});

export const resetViewRouteState = createAsyncThunk("Planning/resetViewRouteState", () => {
  return [];
});

export const resetRouteConsignmentsState = createAsyncThunk("Planning/resetRouteConsignmentsState", () => {
  return [];
});

export const resetSubrouteState = createAsyncThunk("Planning/resetSubrouteState", () => {
  return [];
});

export const fetchConsignmentHistoryAndProducts = createAsyncThunk(
  "ConsignmentDetails/FetchConsignmentHistoryAndProducts",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `v2/order/statusHistoryProducts/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStatusOnClick = createAsyncThunk(
  "ConsignmentDetails/FetchStatusOnClick",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `delayedUpdates/fetch/snapshot/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, false, false, false, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCratesInfo = createAsyncThunk(
  "ConsignmentDetails/getCratesInfo",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/updateCratesAlectoApi`,
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddressStatus = createAsyncThunk(
  "ConsignmentDetails/getAddressStatus",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/update-delivery-address',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeDialog = createAsyncThunk("Planning/CloseDialog", () => {
  return false;
});

export const resetStatusRefreshButton = createAsyncThunk("Planning/resetStatusRefreshButton", () => {
  return false;
});

export const resetReplanningSuccess = createAsyncThunk("Planning/ResetReplanningSuccess", () => {
  return false;
});

export const fetchOrderDeliveryStatuses = createAsyncThunk(
  "RoutePlanning/fetchOrderDeliveryStatuses",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/statuses/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRejectionReasons = createAsyncThunk(
  "CarrefourDelivery/FetchRejectionReasons",
  async (
    {
      countryCode
    }: {
      countryCode: any;
    },
    { rejectWithValue }
  ) => {
    let params = countryCode ? countryCode : sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    try {
      const request = {
        url: `order/get-lineitem-rejection-reasons/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRefreshedDataForPCNotReceived = createAsyncThunk(
  "RoutePlanning/FetchRefreshedDataForPCNotReceived",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/snapshot/consignmentCode/${params}`,
        method: EApiMiddlewareMethods.PUT,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const releaseAssignee = createAsyncThunk(
  "RoutePlanning/ReleaseAssignee",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'assignee/release',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDriverMarkerApi = createAsyncThunk("RoutePlanning/getDriverMarkerApi", async ({ params }: { params: any }, { rejectWithValue }) => {
  try {
    const request = { url: "assignee/locations", method: EApiMiddlewareMethods.POST, data: params } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data?.results;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const initialState: PlanningOrderListState = {
  loading: false,
  tableLoading: false,
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  invoiceData: "",
  invoiceUrl: "",
  displayInvoice: false,
  invoiceMessage: "",
  invoiceConsignment: "",
  exportError: "",
  cratesFromAlecto: "",
  productsListFromAlecto: [],
  orderLogs: [],
  bundleProductList:[],
  ordersFilterChipsDisplayCount: {
    consignmentCount: {
      UNPLANNED: 0,
      PLANNED: 0,
      PUBLISHED: 0,
      PREPARING: 0,
      PREPARED: 0,
      OUT_FOR_DELIVERY: 0,
      COMPLETED: 0,
    }
  },
  routesFilterChipsDisplayCount: {
    routCount: {
      UNPLANNED: 0,
      PLANNED: 0,
      PUBLISHED: 0,
      PREPARING: 0,
      PREPARED: 0,
      OUT_FOR_DELIVERY: 0,
      COMPLETED: 0,
    }
  },
  ordersFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    deliveryAreaList: [],
    addressStatusList: [],
    deliveryZonesList: [],
    vendorList: [],
    typeList: [],
    deliverySlotList: [],
    statusList: [],
    paymentTypeList: [],
    carrierList: [],
    deliveryPropositionList: [],
    routeIdList: [],
    routeStatusList: [],
    driverList: [],
    helperList: [],
    vehicleList: [],
    undeliveredReasonList: [],
    executionStatusList: []
  },
  routesFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    routeIdList: [],
    deliveryAreaList: [],
    deliverySlotList: [],
    carrierList: [],
    deliveryPropositionList: [],
    routeStatusList: [],
    driverList: [],
    helperList: [],
    vehicleList: [],
    createdByList: []
  },
  reasonsDropdownList: [],
  deliveryReasons: {},
  ordersData: {
    elements: [],
    pageNumber: 0
  },
  routesData: {
    elements: [],
    pageNumber: 0
  },
  subRoutes:[],
  error: "",
  errorCode: "",
  draftRoute: {},
  plannedRoutes: {},
  plannedRoutesNew: {},
  slotwisePlannedRoutes: [],
  areawiseConsignments: [],
  slotwiseConsignments: {},
  allConsignments: [],
  routeConsignments: [],
  driversList: [],
  helpersList: [],
  vehiclesList: [],
  slotsList: [],
  zoneList:[],
  consignmentStatusList: [],
  currentSlot: null,
  routeCreateSuccess: false,
  updatedRoute: null,
  routeDiscardSuccess: false,
  publishedRoutesErrors: [],
  publishedRoutes: [],
  replanningConsignmentSuccess: false,
  deletedRoute: null,
  bulkDeletedRoute: null,
  excelLoading: false,
  consignmentStatuses: [],
  productsList: [],
  orderLevelCrateInfo: [],
  consignmentDataAfterReplanning: {},
  deliverySlotListExpress: [],
  orderDeliveryStatuses: [],
  rejectionReasonList: [],
  rejectionReasons: {},
  refreshedConsFromSnapshotService: {},
  productsAfterUpdateLineItem: [],
  isPolled: false,
  consignmentIdForPolledStatus: '',
  polledConsignmentOmsData: {},
  revisedAmountAfterCalculation: "",
  calculateConsignmentSuccess: false,
  isReleaseAssignee: false,
  releaseMessage: "",
  consignmentDataAfterAddressUpdate: {},
  isAddressUpdated: false,
  availableSlots: [],
  crateInfo:[],
  driverLocationData: {},
  bundleProductAfterUpdateLineItem: [],
  order: {}
};

const timerStart = () => {
  let counter = 60
  setTimeout(() => counter - 1, 1000);
  return counter
}

const routePlanningSlice = createSlice({
  name: "RoutePlanning",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchPlanningList.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningList.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.ordersData = payload;
      })
      .addCase(fetchPlanningList.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.ordersFiltersDropdowns = {
          hubCodeList: transformSelectValues(payload.hubCodes || []),
          hubNameList: transformSelectValues(payload.hubNames || []),
          deliveryAreaList: transformSelectValues(payload.areas || []),
          addressStatusList: transformSelectValues(payload.addressStatuses || []),
          deliveryZonesList: transformSelectValues(payload.deliveryZones || []),
          vendorList:  transformSelectValues(payload.vendors || []),
          typeList: transformSelectValues(payload.types || []),
          deliverySlotList: transformSelectValues(payload.deliverySlots || []),
          statusList: transformSelectValues(payload.statuses || []),
          paymentTypeList: transformSelectValues(payload.paymentTypes || []),
          carrierList: transformSelectValues(payload.carriers || []),
          deliveryPropositionList: transformSelectValues(payload.deliveryPropositions || []),
          routeIdList: transformSelectValues(payload.routeIds || []),
          routeStatusList: transformSelectValues(payload.routeStatuses || []),
          driverList: transformSelectValues(payload.drivers || []),
          helperList: transformSelectValues(payload.helpers || []),
          vehicleList: transformSelectValues(payload.vehicles || []),
          undeliveredReasonList: transformSelectValues(payload.undeliveredReasons || []),
          executionStatusList: transformSelectValues(payload.executionStatus || []),
        };
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDynamicFiltersRoutesDropdownValues.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })

      .addCase(fetchDynamicFiltersRoutesDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.routesFiltersDropdowns = {
          hubCodeList: transformSelectValues(payload.hubCodes || []),
          hubNameList: transformSelectValues(payload.hubNames || []),
          routeIdList: transformSelectValues(payload.routeIds || []),
          deliveryAreaList: transformSelectValues(payload.areas || []),
          deliverySlotList: transformSelectValues(payload.deliverySlots || []),
          carrierList: transformSelectValues(payload.carriers || []),
          deliveryPropositionList: transformSelectValues(payload.deliveryPropositions || []),
          routeStatusList: transformSelectValues(payload.routeStatuses || []),
          driverList: transformSelectValues(payload.drivers || []),
          helperList: transformSelectValues(payload.helpers || []),
          vehicleList: transformSelectValues(payload.vehicles || []),
          createdByList: transformSelectValues(payload.createdBy || [])
        };
      })
      .addCase(fetchDynamicFiltersRoutesDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDeliveryReasons.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDeliveryReasons.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let deliveryReasonsList: any = [];
        if (payload.reasons) {
          deliveryReasonsList = payload.reasons.map((reason: any) => {
            return {
              code: reason.reasonCode,
              value: reason.reasonDescription
            };
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
        state.deliveryReasons = payload.reasons;
      })
      .addCase(fetchDeliveryReasons.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetPlanningData.fulfilled , (state) => {
        state.reasonsDropdownList = []
        state.deliveryReasons={}
      })
      .addCase(fetchDeliveryReasonsCountryBased.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDeliveryReasonsCountryBased.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let deliveryReasonsList: any = [];
        if (payload.reasons) {
          deliveryReasonsList = payload.reasons.map((reason: any) => {
            return {
              code: reason.reasonCode,
              value: reason.reasonDescription
            };
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
        state.deliveryReasons = payload.reasons;
      })
      .addCase(fetchDeliveryReasonsCountryBased.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.isAddressUpdated = false;
        state.ordersFilterChipsDisplayCount = payload;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRoutesFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchRoutesFilterChipsDisplayCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.routesFilterChipsDisplayCount = payload;
      })
      .addCase(fetchRoutesFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllDrivers.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllDrivers.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driversData = payload;
        const drivers = getDropdownsData(payload);
        state.driversList = transformSelectValues(drivers);
      })
      .addCase(fetchAllDrivers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllHelpers.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllHelpers.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.helpersData = payload;
        const helpers = getDropdownsData(payload);
        state.helpersList = transformSelectValues(helpers);
      })
      .addCase(fetchAllHelpers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllVehicles.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllVehicles.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.vehiclesData = payload;
        const vehicles = getDropdownsData(payload);
        state.vehiclesList = transformSelectValues(vehicles);
      })
      .addCase(fetchAllVehicles.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(loadAllSlots.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(loadAllSlots.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.slotwisePlannedRoutes = [];
        state.slotsData = payload;
        state.currentSlot = payload.currentSlot && payload.currentSlot.length ? payload.currentSlot[0] : {};
        state.slotsList = transformSelectValues(payload.allSlots);
        state.zoneList = transformSelectValues(payload.deliveryZones);
      })
      .addCase(loadAllSlots.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.currentSlot = null;
        state.slotsList = [];
        state.draftRoute = {};
        state.slotwisePlannedRoutes = [];
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllPlannedRoutes.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllPlannedRoutes.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.plannedRoutes = payload;
        state.slotwisePlannedRoutes = getPlannedRoutesData(payload.allRoutes);
      })
      .addCase(fetchAllPlannedRoutes.rejected, (state, action) => {
        state.loading = false;
        state.plannedRoutes = {};
        state.slotwisePlannedRoutes = [];
      })
      .addCase(fetchOrderLogs.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderLogs.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.orderLogs = payload.results.logs || [];
      })
      .addCase(fetchOrderLogs.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchSlots.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchSlots.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.availableSlots = payload?.data
      })
      .addCase(fetchSlots.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })

      .addCase(createDraftRoute.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.draftRoute = {};
      })
      .addCase(createDraftRoute.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.routeCreateSuccess = false;
        state.updatedRoute = null;
        state.routeDiscardSuccess = false;
        state.currentSlot = null;
        state.slotwisePlannedRoutes = [];
        state.draftRoute = payload;
        state.consignmentStatusList = transformSelectValues(payload.deliveryStatuses || []);
        sessionStorage.setItem('routeId', payload.route && payload.route.id);
        sessionStorage.setItem('extraConsignments', '[]');
      })
      .addCase(createDraftRoute.rejected, (state, action) => {
        const {
          payload,
          meta: {
            arg: { params }
          }
        } = action;
        const errorPayload: any = payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.routeCreateSuccess = false;
        state.updatedRoute = null;
        state.routeDiscardSuccess = false;
        state.currentSlot = null;
        state.draftRoute = {};
        state.slotwisePlannedRoutes = [];
        if (!params.editMode) {
          sessionStorage.setItem('routeId', '');
          sessionStorage.setItem('extraConsignments', '[]');
        }
      })
      .addCase(consignmentReplanning.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(consignmentReplanning.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = true;
     
        state.consignmentDataAfterReplanning = payload;
      })
      .addCase(consignmentReplanning.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.replanningConsignmentSuccess = false;
      })
      .addCase(createAndEditRoute.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(createAndEditRoute.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { editMode }
          }
        } = action;
        state.loading = false;
        if (editMode) {
          state.updatedRoute = payload;
        } else {
          state.routeCreateSuccess = true;
        }
        state.currentSlot = null;
        sessionStorage.setItem('routeId', '');
        sessionStorage.setItem('extraConsignments', '[]');
      })
      .addCase(createAndEditRoute.rejected, (state, action) => {
        const {
          payload,
          meta: {
            arg: { editMode }
          }
        } = action;
        const errorPayload: any = payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        if (editMode) {
          state.updatedRoute = null;
        } else {
          state.routeCreateSuccess = false;
        }
      })
      .addCase(discardRouteChanges.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(discardRouteChanges.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { redirect }
          }
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.routeDiscardSuccess = true;
        state.draftRoute = {};
        state.currentSlot = null;
        if (!redirect) {
          sessionStorage.setItem('routeId', '');
        }
        sessionStorage.setItem('extraConsignments', '[]');
      })
      .addCase(discardRouteChanges.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.routeDiscardSuccess = false;
      })
      .addCase(publishRoutes.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.publishedRoutesErrors = [];
      })
      .addCase(publishRoutes.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.publishErrorDto && payload.publishErrorDto.length) {
          state.publishedRoutesErrors = payload.publishErrorDto;
          state.publishedRoutes = payload.publishedRoutes && payload.publishedRoutes.length ? payload.publishedRoutes : [];
        } else {
          state.publishedRoutes = payload.publishedRoutes && payload.publishedRoutes.length ? payload.publishedRoutes : [];
        }
      })
      .addCase(publishRoutes.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.publishedRoutes = [];
        state.publishedRoutesErrors = [];
      })
      .addCase(deleteRoute.pending, (state) => {
        state.bulkDeletedRoute = null;
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(deleteRoute.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.deletedRoute = payload;
      })
      .addCase(deleteRoute.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.deletedRoute = null;
      })
      .addCase(bulkDeleteRoute.pending, (state) => {
        state.deletedRoute = null;
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(bulkDeleteRoute.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.bulkDeletedRoute = payload;
      })
      .addCase(bulkDeleteRoute.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ?  AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.BULK_DELETE_ERROR_MSG
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.bulkDeletedRoute = null;
      })
      .addCase(updateConsignmentStatus.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateConsignmentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateConsignmentStatus.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningOrderListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(resetAddressupdateStatus.fulfilled , (state) => {
        state.isAddressUpdated = false
      })
      .addCase(fetchPlanningOrderListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.isAddressUpdated = false;
        if(state.isPolled && state.consignmentIdForPolledStatus){
           state.polledConsignmentOmsData = payload.elements.find((x:any) => x.id === state.consignmentIdForPolledStatus);
        }
        state.ordersData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.elements]
              : [...state.ordersData.elements, ...payload.elements],
          totalElements: payload.totalElements,
          totalPages: payload.totalPages
        };
      })
      .addCase(fetchPlanningOrderListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningRouteListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningRouteListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.routesData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.elements]
              : [...state.routesData.elements, ...payload.elements],
          totalElements: payload.totalElements,
          totalPages: payload.totalPages
        };
      })
      .addCase(fetchPlanningRouteListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRouteConsignments.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchRouteConsignments.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.routeConsignments = payload;
      })
      .addCase(fetchRouteConsignments.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      // .addCase(clearPlanningStateValue.fulfilled, (state, action) => {
      //   const {
      //     payload,
      //     meta: {
      //       arg: { params }
      //     }
      //   } = action;
      //   const stateKey: string = params.stateKey || "";
      //   state = { ...state, [stateKey]: payload };
      // })
      .addCase(updateAllConsignmentsState.fulfilled, (state, action) => {
        const { payload } = action;
        state.areawiseConsignments = payload.areaWiseConsignments;
        state.slotwiseConsignments = payload.slotwiseConsignments;
        state.allConsignments = payload.allConsignments;
        state.loading = payload.loading;
        state.error = "";
      })
      .addCase(clearPublishedRoutesErrors.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.publishedRoutesErrors = payload;
      })
      .addCase(clearPublishedRoutes.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.publishedRoutes = payload;
      })
      .addCase(resetPlanningError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(clearUpdatedRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.updatedRoute = payload;
        state.routeDiscardSuccess = false;
      })
      .addCase(clearDeletedRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.deletedRoute = payload;
      })
      .addCase(clearBulkDeletedRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.bulkDeletedRoute = payload;
      })
      .addCase(clearCurrentSlot.fulfilled, (state, action) => {
        const { payload } = action;
        state.currentSlot = payload;
      })
      .addCase(resetViewRouteState.fulfilled, (state, action) => {
        const { payload } = action;
        state.subRoutes = payload;
      })
      .addCase(resetCreateRouteSuccess.fulfilled, (state, action) => {
        const { payload } = action;
        state.routeCreateSuccess = payload;
      })
      .addCase(resetDraftRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.draftRoute = payload;
      })
      .addCase(resetConsignmentReplanningSuccess.fulfilled, (state, action) => {
        const { payload } = action;
        state.replanningConsignmentSuccess = payload;
      })
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Consignments_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportRoutesToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportRoutesToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Routes_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportRoutesToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.exportError = errorPayload.status;
      })
      .addCase(exportInvoicePdf.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(exportInvoicePdf.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        const data = payload.Invoices && payload.Invoices.length ? payload.Invoices[0] : [];
        state.invoiceConsignment = payload.Invoices && payload.Invoices.length ? payload.Invoices[0].ConsignmentNo : "";
        state.displayInvoice = payload.Invoices && payload.Invoices.length;
        if(data?.FileUrl){
          state.invoiceUrl = data?.FileUrl
        }
        else if(data?.FileDataBytes){
         const pdfData = `data:application/pdf;base64,${data.FileDataBytes}`;
          state.invoiceData = pdfData ;
        }
        state.invoiceMessage = payload.Message;
      })
      .addCase(exportInvoicePdf.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchConsignmentHistoryAndProducts.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchConsignmentHistoryAndProducts.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.productsAfterUpdateLineItem = [];
        state.bundleProductAfterUpdateLineItem = [];
        state.consignmentStatuses = payload.statuses && payload.statuses.length ? payload.statuses : [];
        state.crateInfo = getCrateDetails(payload)
        state.productsList = payload.products && payload.products.length ? payload.products : [];
        //state.bundleProductList = transformBundleData(Demo);
        state.bundleProductList = payload?.bundleProducts?.length ? transformBundleData(payload?.bundleProducts) : [];
        state.orderLevelCrateInfo = payload.associatedCrates && payload.associatedCrates.length ? payload.associatedCrates : [];
        state.orderLogs = [];
      })
      .addCase(fetchConsignmentHistoryAndProducts.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
       .addCase(fetchStatusOnClick.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchStatusOnClick.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.isPolled = payload.polled;
        state.consignmentIdForPolledStatus = payload.consignmentCode
      })
      .addCase(fetchStatusOnClick.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(getCratesInfo.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(getCratesInfo.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.cratesFromAlecto = payload;
        state.productsListFromAlecto = payload && payload.crateAvailableOrders && payload.crateAvailableOrders.length > 0 && payload.crateAvailableOrders[0].products && payload.crateAvailableOrders[0].products.length > 0 ? payload.crateAvailableOrders[0].products : null;
      })
      .addCase(getCratesInfo.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(closeDialog.fulfilled, (state, action) => {
        state.consignmentDataAfterReplanning = {};
        state.consignmentDataAfterAddressUpdate = {};
        state.isPolled = false
        state.consignmentIdForPolledStatus = ""
        state.polledConsignmentOmsData = {}
      })
      .addCase(resetStatusRefreshButton.fulfilled, (state, action) => {
        state.isPolled = false
      })
      .addCase(resetReplanningSuccess.fulfilled, (state, action) => {
        state.replanningConsignmentSuccess = false
      })
      .addCase(fetchExpressSlotDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchExpressSlotDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        let deliverySlotListExp: any = {};
        if (payload.deliverySlot) {
          Object.keys(payload.deliverySlot).filter((country: any) => {
            deliverySlotListExp[country] = transformSelectValues(payload.deliverySlot[country]);
            return true;
          });
        }
        state.deliverySlotListExpress = deliverySlotListExp;
      })
      .addCase(fetchExpressSlotDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrderDeliveryStatuses.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderDeliveryStatuses.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.orderDeliveryStatuses = transformSelectValues(payload.statuses && payload.statuses.length ? payload.statuses : []);
      })
      .addCase(fetchOrderDeliveryStatuses.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRejectionReasons.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchRejectionReasons.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let rejectionReasonsList: any = [];
        if (payload.reasons) {
          rejectionReasonsList = payload.reasons.map((reason: any) => {
            return {
              code: reason.reasonCode,
              value: reason.reasonDescription
            };
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.rejectionReasonList = transformSelectValues(rejectionReasonsList);
        state.rejectionReasons = payload.reasons;
      })
      .addCase(fetchRejectionReasons.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(replanDuringPartialRejection.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(replanDuringPartialRejection.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.revisedAmountAfterCalculation = payload && payload.metadata && payload.metadata.collectedAmount ? payload.metadata.collectedAmount : "";
        state.calculateConsignmentSuccess = true;
        state.productsAfterUpdateLineItem = payload && payload.products;
        state.bundleProductAfterUpdateLineItem = payload?.bundleProducts?.length && transformBundleData(payload?.bundleProducts)
        state.consignmentDataAfterReplanning = payload;
        state.replanningConsignmentSuccess = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(replanDuringPartialRejection.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.replanningConsignmentSuccess = false;
      })
      .addCase(fetchRefreshedDataForPCNotReceived.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchRefreshedDataForPCNotReceived.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.refreshedConsFromSnapshotService = payload;
      })
      .addCase(fetchRefreshedDataForPCNotReceived.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.replanningConsignmentSuccess = false;
      })
      .addCase(releaseAssignee.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(releaseAssignee.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload) {
          state.isReleaseAssignee = true;
          state.releaseMessage = payload && payload.message;
        }
      })
      .addCase(releaseAssignee.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.isReleaseAssignee = false;
        state.releaseMessage = "";
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(getAddressStatus.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(getAddressStatus.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.consignmentDataAfterAddressUpdate = payload;
        state.isAddressUpdated = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(getAddressStatus.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(getDriverMarkerApi.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.driverLocationData = {};
      })
      .addCase(getDriverMarkerApi.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driverLocationData = payload;
      })
      .addCase(getDriverMarkerApi.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchSubRouteById.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.subRoutes = [];
      })
      .addCase(fetchSubRouteById.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.subRoutes = transformedDataViewRoute(payload);
      })
      .addCase(fetchSubRouteById.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrderById.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.order = {};
      })
      .addCase(fetchOrderById.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.order = payload;
      })
      .addCase(fetchOrderById.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllPlannedRoutesNew.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.plannedRoutesNew = {};
      })
      .addCase(fetchAllPlannedRoutesNew.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.plannedRoutesNew = payload;
      })
      .addCase(fetchAllPlannedRoutesNew.rejected, (state, action) => {
        const { payload } : any = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload?.message;
      }) 
      .addCase(resetRouteConsignmentsState.fulfilled, (state, action) => {
        const { payload } = action;
        state.routeConsignments = payload;
      })      
      .addCase(resetSubrouteState.fulfilled, (state, action) => {
        const { payload } = action;
        state.subRoutes = payload;
      })      
  },
});

export default routePlanningSlice.reducer;
