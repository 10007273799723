import React, { useCallback, useEffect, useRef, useState } from 'react';
import SelectBox from '../SelectBox';
import DateRangePickerComponent from '../DateRangeComponent';
import { Button, Grid, Popover, SvgIcon, Badge } from '@material-ui/core';
import AppConstants from '../../constants';
import { useStyles } from './Filter.styles';
import AutoComplete from '../AutoComplete';
import { RowData, SortDirection } from './DataTable';
import { ReactComponent as filterIcon } from './../../assets/icons/filter-icon.svg';
import { useClickHandler } from 'utils/helpers.utils';

interface FilterProps {
  filter: any;
  order: SortDirection;
  orderBy: keyof RowData;
  resetAllFilter: boolean;
  defaultValues: any;
  activeFilterValues: any;
  updateFilter: (filter: any) => void;
  activeFilter?: string
}

const Filter = (props: FilterProps) => {
  const classes = useStyles();
  const { filter, order, orderBy, defaultValues, resetAllFilter, activeFilterValues, updateFilter, activeFilter } = props;
  const [filterValue, setFilterValue] = useState(defaultValues[filter.fieldName].value ? defaultValues[filter.fieldName].value : defaultValues[filter.fieldName]);
  const [pendingValue, setPendingValue] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const filterPopoverRef = useRef<any>(null);
  const filterRef = useRef<any>(null);

  const openFilterPopup = Boolean(anchorEl);

  const handlePopoverContentClick = useCallback(
    (event: any) => {
      event.preventDefault();
    },
    [],
  );

  const handleFilterPopupOpen = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      setPendingValue(filterValue);
      setAnchorEl(event.currentTarget);
    },
    [filterValue],
  )

  const handleFilterPopupClose = useCallback(
    (value: any) => {
      setFilterValue(value);
      setAnchorEl(null);
    },
    [],
  );

  const onApplyFilter = useCallback(
    (value: any) => {
      setResetFilter(false);
      updateFilter({
        key: filter.fieldName,
        value: value,
        reset: false,
        fieldName: filter.fieldFullName || ''
      });
    },
    [filter, updateFilter],
  );

  const handleFilterChange = useCallback(
    (value: any) => {
      setFilterValue(value);
    },
    []
  );

  const handleDateFilterChange = useCallback(
    (value: any) => {
      handleFilterChange(value);
      onApplyFilter(value);
    },
    [handleFilterChange, onApplyFilter],
  )

  const onResetFilter = useCallback(
    () => {
      setFilterValue(defaultValues[filter.fieldName].value);
      setResetFilter(true);
      updateFilter({
        key: filter.fieldName,
        value: defaultValues[filter.fieldName].value,
        reset: true,
        fieldName: filter.fieldFullName || ''
      });
    },
    [defaultValues, filter, updateFilter],
  );

  const updatePopoverRef = useCallback(
    (element: Element) => {
      filterPopoverRef.current = element;
      return filterRef.current ? filterRef.current : element;
    },
    [],
  );

  useEffect(() => {
    if (resetAllFilter && filter.type === 'dateRange') {
      setFilterValue(defaultValues[filter.fieldName].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAllFilter]);

  useEffect(() => {
    setFilterValue(activeFilterValues[filter.fieldName].value ? activeFilterValues[filter.fieldName].value : activeFilterValues[filter.fieldName]);
    setPendingValue(activeFilterValues[filter.fieldName] && activeFilterValues[filter.fieldName].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilterValues]);

  useClickHandler(filterPopoverRef, handlePopoverContentClick);

  const getFilter = () => {
    switch (filter.type) {
      case 'selectBox':
        return (
          <SelectBox
            handleChange={handleFilterChange}
            value={filterValue}
            reset={resetFilter}
            resetAll={resetAllFilter}
            id={filter.key}
            multiple={filter.multiple}
            label={filter.label}
            items={filter.items || []}
          ></SelectBox>
        );
      case 'autocomplete':
        return (
          <AutoComplete
            handleChange={handleFilterChange}
            handleClose={handleFilterPopupClose}
            value={pendingValue}
            reset={resetFilter}
            resetAll={resetAllFilter}
            id={filter.key}
            multiple={filter.multiple}
            items={filter.items || []}
          />
        );
      case 'dateRange':
        return (
          <DateRangePickerComponent
            keyId={filter.key}
            label={filter.label}
            placeholder={filter.placeholderText}
            className={orderBy === filter.fieldKey && order === 'default' ? 'addMargin' : ''}
            value={filterValue}
            isTextField={false}
            autoUpdateInput={filter.autoUpdateInput}
            autoApply={filter.autoApply}
            maxSpan={filter.maxSpan}
            showDropdowns={filter.showDropdowns}
            linkedCalendars={filter.linkedCalendars}
            locale={{ ...filter.locale, format: AppConstants.DATE_FORMAT }}
            updateDateRange={handleDateFilterChange}
            defaultValue= {defaultValues[filter.fieldName].value}
          />
        )
      default: return null;
    }
  }
  return (
    <>
      {
        filter.type === 'dateRange' ?
          getFilter()
          :
          <>
          <Badge classes={{ badge: classes.customBadge }} variant="dot" invisible={activeFilter ? false : true}>
            <SvgIcon ref={filterRef} key={filter.key} className={`${classes.rootIcon} ${orderBy === filter.fieldKey && order === 'default' ? 'addMargin' : ''}`} viewBox="0 0 24 24" onClick={handleFilterPopupOpen} component={filterIcon} />
          </Badge> 
            <Popover
              id="filter-popover-area"
              classes={{
                paper: classes.paperProps,
              }}
              open={openFilterPopup}
              anchorEl={anchorEl}
              getContentAnchorEl={updatePopoverRef}
              transformOrigin={{
                vertical: -20,
                horizontal: 'center',
              }}
            >
              <Grid className={classes.popoverFilter} item>
                {
                  getFilter()
                }
                <Grid container className={classes.buttonsContainer}>
                  <Button variant="contained" className={[classes.button, 'primary'].join(' ')} onClick={() => onApplyFilter(filterValue)}>{AppConstants.BUTTONS.APPLY}</Button>
                  <Button variant="contained" className={[classes.button, 'secondary'].join(' ')} onClick={onResetFilter}>{AppConstants.BUTTONS.CLEAR}</Button>
                </Grid>
              </Grid>
            </Popover>
          </>
      }
    </>
  );
}

export default Filter;