import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
 
    bundleProduct:{
      "& .icon": {
        fill: "none",
      },
      '& .itemContainer':{
        borderBottom: "none",
        
    },
      "&.item": {
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        "&:first-child": {
          padding: 0,
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
      '& .bundleRows':{
        marginTop: 24,
        '& .itemContainer':{
            marginTop: 12,
        },
      }
    },
    bundleItemsContainer: {
        marginTop: 12,
        flexWrap: "nowrap",
        padding: '0px 16px',
        '&.rowItem':{
            "& p": {
              fontWeight: '400 !important',
            },
          },
          "& .itemColumn": {
            "& .itemsContent":{
                fontSize:'14px'
            },
            "&.item1": {
              "& p": {
                fontWeight: 400,
                textTransform: "capitalize",
              },
              "& .rejectedReason": {
                fontSize: 10,
                color: COLORS.ALIZARIN_CRIMSON,
              },
            },
            "&.item2": {
              "& p": {
                textAlign: "center",
                "& .green": {
                  color: COLORS.PERSIAN_GREEN,
                },
              },
            },
            "&.item3": {
              "& p": {
                textAlign: "right",
              },
            },
          },
     
      },
      selectRoot: {
        color: COLORS.MINE_SHAFT,
        fontSize: "10px",
        fontWeight: 500
    },
    showRejectionReason:{
      display: "flex",
      alignItems: "center",
      padding:'0px 16px',
      "& p": {
        fontSize: "10px",
        color: COLORS.MINE_SHAFT,
        fontWeight: 600,
      },

    },
    icon: {
        color: "#000000"
    }, 
    itemHeader:{
      display: 'flex',
      backgroundColor: COLORS.LIGHT_BLUE,
      width: 'fit-content',
      padding: '4px 8px 2px 8px',
      borderRadius: 12
    },
    rejectedReason:{
      textTransform: "capitalize",
      color: COLORS.ALIZARIN_CRIMSON,
      paddingLeft: 16,
      marginTop: 8,
      "& p": {
        fontWeight: 400,
        fontSize:10
      },
    },
    showError:{
      paddingLeft: 16,
    }
  })
);