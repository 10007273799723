import React, { useState } from "react";
import { useSelector } from "react-redux";


//Material UI Components
import { Grid, Typography } from "@material-ui/core";

//Style
import { useStyles } from "./performanceDashboard.styles";

//Common Components
import BarCharts from "../../common/BarCharts";
import AreaChart from "../../common/AreaChart";
import DonutChart from "../../common/DonutChart";
import PieChart from "common/PieChart";
import SwitchButton from "../../common/SwitchButton/SwitchButton";

//Constants
import { COLORS } from "../../config/theme/baseTheme";

//Assets
import WarningIcon from "../../assets/icons/warning-icon.svg";

import { AppState } from '../../config/redux/reducers';

interface RoutesDashboardProps { }

const BARCOLORS_PERFORMANCE = [
    COLORS.PALE_PURPLE,
    COLORS.OCEAN_GREEN
]

const BARCOLORS_VOLUME = [
    COLORS.AQUA_BLUE,
    COLORS.VENETIAN_RED,
    COLORS.FOUNTAIN_BLUE,
]

const DONUT_COLORS = [
    COLORS.PRIMARY_MAIN,
    COLORS.CORAL_PINK,
    COLORS.PICTON_BLUE,
    COLORS.ALERT_ORANGE
]

const PIE_COLORS = [
    COLORS.PRIMARY_MAIN,
    COLORS.PRIMARY_LIGHT,
    COLORS.PRIMARY_LIGHT_BLUE,
]

const DONUT_LABELS = [
    'E-Food', 'Marketplace', 'Non-Food', 'Carrefour Now'
]

const PIE_LABELS = [
    'COD', 'Prepaid', 'COD And Prepaid']

const RoutesDashboard = (props: RoutesDashboardProps) => {
    const classes = useStyles();

    const { routes, routesGlobal } = useSelector(
        (state: AppState) => state.performance,
    );
    const [isChecked, setIsChecked] = useState(false);

    const handleSwitch = (e: any) => {
        if (isChecked) {
            setIsChecked(false);
        } else {
            setIsChecked(true);
        }
    };

    const renderGraphsSection = () => {
        return (
            <>
                {isChecked ?
                    <Grid item>
                        <Grid item className={classes.subDiv}>
                            <AreaChart
                                days={routes.formattedSlots}
                                color={BARCOLORS_PERFORMANCE}
                                seriesObj={routes.countsData && routes.countsData.length ? routes.countsData : []}
                                showLegend={true}
                                stacked={false}
                            />
                        </Grid>
                    </Grid> : <Grid>
                        <Grid item className={classes.subDiv}>
                            <AreaChart
                                days={routes.formattedSlots}
                                color={BARCOLORS_PERFORMANCE}
                                seriesObj={routes.percentageData && routes.percentageData.length ? routes.percentageData : []}
                                showLegend={true}
                                stacked={false}
                            />
                        </Grid>
                    </Grid>}
                <Grid container style={{ paddingTop: "24px" }}>
                    <Grid item>
                        <Typography className={classes.consignmentText}>Volume by Slots</Typography>
                    </Grid>
                    <Grid container className={classes.chartDiv}>
                        <Grid item className={classes.subDiv}>
                            <BarCharts
                                days={routes.formattedSlots}
                                color={BARCOLORS_VOLUME}
                                seriesObj={routes.volumeData && routes.volumeData.length ? routes.volumeData : []}
                                showLegend={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

    const renderPieChartsSection = () => {
        return (
            <>
                <Grid item xs={6}>
                    <Grid item className={classes.pieDivision}>
                        <PieChart pieColors={PIE_COLORS} pieLabel={PIE_LABELS} pieData={routes.paymentWise || []} title={"Routes by Payment Mode"} />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item className={classes.pieDivision}>
                        <DonutChart pieColors={DONUT_COLORS} pieLabel={DONUT_LABELS} pieData={routesGlobal.deliveryTypeWise || []} title={"Routes by Delivery Type"} donutLabel={"Routes"} />
                    </Grid>
                </Grid>
            </>

        )
    }

    return (
        <Grid container className={classes.subContainer} spacing={2} justify="space-between">
            <Grid item xs={3}>
                <Grid container className={classes.routes} justify="space-between">
                    <Grid item className={classes.tileContent}>
                        <Typography className={classes.totalText}>Today's Routes</Typography>
                    </Grid>
                    <Grid item className={classes.consignmentData}>{routesGlobal.totalRoutes || 0}</Grid>
                    <Grid container className={classes.warningUnplanned} justify="space-between">
                        <Grid item className={classes.unplannedSection}>
                            <img src={WarningIcon} alt="warning" className={classes.warningIcon} />
                            <Typography className={classes.consignmentText}>Unplanned Consignments</Typography></Grid>
                        <Grid item className={classes.unplannedText}>{routes.unPlannedConsignments || 0}</Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className={classes.deliveryText}>

                        <Grid item className={classes.deliverySection}>
                            <Typography className={classes.consignmentText}>Performance by Delivery</Typography>
                        </Grid>
                        <Grid container spacing={2} className={classes.subDivision}>
                            <Grid item xs={6}>
                                <Grid item className={classes.subDivisionHandover}>
                                    <Grid container justify="space-between">
                                        <Grid item xs={6} className={classes.deliveryTiles}><Typography className={classes.deliveryTilesText}>Ontime Handover</Typography></Grid>
                                        <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                            <Typography className={classes.deliveryTileContent}>
                                                {routes.handOverOnTimePercentage + "%"}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item className={classes.subDivisionOntime}>
                                    <Grid container justify="space-between">
                                        <Grid item xs={6} className={classes.deliveryTiles}><Typography className={classes.deliveryTilesText}>Ontime Delivery</Typography></Grid>
                                        <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                                            <Typography className={classes.deliveryTileContent}>{routes.onTimePercentage + "%"}</Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.firstTileSection} spacing={2}>
                            <Grid item xs={6}>
                                <Grid container className={classes.tiles}>
                                    <Grid item className={classes.tileContent}><Typography className={classes.statusText}>Total</Typography></Grid>
                                    <Grid item className={classes.tileContent}><Typography className={classes.totalData}>{routes.globalStateCount.Total || 0}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container className={classes.tiles}>
                                    <Grid item className={classes.tileContent}><Typography className={classes.statusText}>Ontime</Typography></Grid>
                                    <Grid item className={classes.tileContent}><Typography className={classes.onTimeData}>{routes.globalStateCount.OnTime || 0}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.secondTileSection} spacing={2}>
                            <Grid item xs={6}>
                                <Grid container className={classes.tiles}>
                                    <Grid item className={classes.tileContent}><Typography className={classes.statusText}>Late</Typography></Grid>
                                    <Grid item className={classes.tileContent}><Typography className={classes.lateData}>{routes.globalStateCount.Late || 0}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container className={classes.tiles}>
                                    <Grid item className={classes.tileContent}><Typography className={classes.statusText}>At Risk</Typography></Grid>
                                    <Grid item className={classes.tileContent}><Typography className={classes.atRiskData}>{routes.globalStateCount['At Risk'] || 0}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.horizontalLine} />
                        <Grid container style={{ padding: "24px" }}>
                            <Typography>Top 5 Drivers</Typography>
                        </Grid>
                        <Grid item style={{ padding: "24px" }}>Coming Soon..!!</Grid>

                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={9} className={classes.subDiv}>
                <Grid container spacing={2} style={{ flexWrap: "nowrap" }}>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.plannedRoutes}>
                            <Grid item className={classes.routeStatusTilesTitle}>Planned / Published</Grid>
                            <Grid item className={classes.data}>{routesGlobal.statusWise['Planned/Published'] || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.inProgress}>
                            <Grid item className={classes.routeStatusTilesTitle}>In Progress</Grid>
                            <Grid item className={classes.data}>{routesGlobal.statusWise['In Progress'] || 0}</Grid>
                        </div>
                    </Grid>
                    <Grid item className={classes.tilesView}>
                        <div className={classes.completed}>
                            <Grid item className={classes.routeStatusTilesTitle}>Completed</Grid>
                            <Grid item className={classes.data}>{routesGlobal.statusWise['Completed'] || 0}</Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "16px" }}>
                    <Grid container direction="column" className={classes.graphDivision}>
                        <Grid item>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Typography className={classes.consignmentText}>Performance by Slots</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        item
                                        xs={7} className={classes.switchStyle}
                                    >
                                        Percentage
                                    <SwitchButton
                                            size="large"
                                            checked={isChecked}
                                            handleChange={handleSwitch}
                                            onClick={(e: any) => e.stopPropagation()}
                                        />
                                    Count
                                 </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        {renderGraphsSection()}

                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {renderPieChartsSection()}

                </Grid>
            </Grid>
        </Grid >
    )
}

export default RoutesDashboard;