import React from "react";

import { ReactComponent as crateBoxIcon } from "../../../assets/icons/crate-box-icon.svg";
import { ReactComponent as SixDotsIcon } from "../../../assets/icons/six-dots-icon.svg";

import { Checkbox, Grid, SvgIcon, Typography } from "@material-ui/core";

import { getVerificationStatusField, isSubRoute } from "../utils/AllRouteUtils";
import { getStatusWithoutUnderScore } from "../../../mocks/allRoutes/response.transforms";
import { COLORS } from "../../../config/theme/baseTheme";

import RenderRescheduleOrders from "../../../common/RescheduleOrders/RenderRescheduleOrders";

interface RenderOrderDetailsAccordionSummaryInterface {
  classes: any;
  routeObj: any;
  routeName: string;
  routeDataName: string;
  slotName: string;
  orderObj: any;
  orderObjIndex: number;
  viewMode?: boolean;
  orderActionPopUpViewChange?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: any, orderObjIndex: number) => void;
  handleOrderCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
}

const RenderOrderDetailsAccordionSummary = (props: RenderOrderDetailsAccordionSummaryInterface) => {
  const { classes, routeObj, routeName, routeDataName, slotName, orderObj, orderObjIndex, viewMode, orderActionPopUpViewChange, handleOrderCheckboxToggle } = props;

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Grid container justify="flex-start">
          {!isSubRoute(routeObj.routeDataObj[routeDataName]) && routeObj.subRouteCreated && (
            <Checkbox
              color="primary"
              checked={routeObj.routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].isChecked}
              onClick={(e: any) => handleOrderCheckboxToggle && handleOrderCheckboxToggle(e, routeName, routeDataName, slotName, orderObjIndex)}
              className={`${classes.orderSubRouteCheckboxStyle}`}
            />
          )}
          <Typography className={classes.orderNumberCircle} style={{ background: routeObj.color || COLORS.TEXT_PRIMARY }}>{`${orderObj.orderIndex}`}</Typography>
          <Typography className={`${classes.fontSizeFourteen} ${classes.fontWeightFive} ${classes.lineHeightFifteen} ${classes.marginAutoZero}`}>{`${orderObj.consignmentCode || "-"}`}</Typography>
          {orderObj?.rescheduled && <RenderRescheduleOrders />} 
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justify="flex-end">
          <Grid item>
            <Grid container>
              <Grid item>
                <Grid container justify="flex-start" className={`${classes.borderRight} ${classes.heightHundredPercentage}`}>
                  <SvgIcon viewBox="0 0 24 24" component={crateBoxIcon} className={classes.crateIconStyle} />
                  <Typography
                    className={`${classes.fontSizeTweleve} ${classes.fontWeightFive} ${classes.lineHeightFifteen} ${classes.marginAutoZero} ${classes.marginRightEight}`}
                  >{`${orderObj.totalCratesCount}`}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid className={classes.statusHeaderContainer} container alignItems="flex-start">
                  <Typography className={["tag", getVerificationStatusField("color", orderObj.deliveryStatus)].join(" ")}>{getStatusWithoutUnderScore(orderObj.deliveryStatus)}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="flex-start">
                  {!viewMode && !isSubRoute(routeObj.routeDataObj[routeDataName]) && (
                    <SvgIcon
                      viewBox="0 0 24 24"
                      component={SixDotsIcon}
                      onClick={(e: any) => orderActionPopUpViewChange && orderActionPopUpViewChange(e, routeName, routeDataName, slotName, orderObjIndex)}
                      className={classes.dotIconStyle}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderOrderDetailsAccordionSummary);
