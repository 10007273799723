import { TableRowProps } from "@material-ui/core";
import React from "react";
import { ColDef, RowData, RowId } from ".";
import DataTableRowElement from "./DataTableRowElement";

export interface DataTableRowElementProps extends TableRowProps {
  rowData: RowData;
  columns: ColDef[];
  checkboxSelection: boolean;
  rowHeight: number;
  index: number;
  disableSelectionOnClick?: boolean;
  isItemSelected?: boolean;
  rowClickCallback?: (rowData: RowData) => void;
  rowSelectionCallback?: (rowId: RowId) => void;
  cellClickCallback?: (column: ColDef, value: any, rowData: RowData) => void;
  isSelected?: (rowId: RowId) => boolean;
}

const DataTableRowWrapper = (props: DataTableRowElementProps) => {
  const { rowData } = props;
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <DataTableRowElement
        {...props}
        rowData={rowData}
        setOpen={setOpen}
        open={open}
      />
      {open &&
        rowData?.subRoutes &&
        rowData?.subRoutes.map((data: RowData, index: number) => (
          <DataTableRowElement {...props} rowData={data} subElement={true} />
        ))}
    </>
  );
};

export default DataTableRowWrapper;
