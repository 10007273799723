import moment from "moment";
import { RowData } from "../../common/GridWrapper/DataTable";
import AppConstants from "../../constants";
import { enumerateDaysBetweenDates, fixDecimals, getUniqueValues, localTimeString } from "../../utils/helpers.utils";

export interface MetaData {
  paymentType: string;
  totalAmount: string;
  transactionDate: Date;
  amountToCollect: string;
  collectedAmount: string;
  currency: string;
  category: string;
  deliveryType: string;
  modeOfCollection: string;
  invoiceNumber: string;
  internalExternal: string;
  origin: string;
  status: string;
  deliveryStatus: string;
  routeId: string;
  routeName: string;
  routeStatus: string;
  numberOfItems: number;
  lastStatusUpdatedTime: string;
}

export interface Name {
  ar: string;
  en: string;
}

export interface Product {
  sku: string;
  entryNumber: string;
  hasRejectedProducts: boolean;
  name: Name;
  quantity: string;
  acceptedQty: string
  unitPrice: string;
  imgUrl: string;
  vatPercentage: string;
  uom: string;
  orderedBarcode: string;
  associatedBarcodes: string[];
  substitution: boolean;
}

export interface Address {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  makani: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
}

export interface ContactInfo {
  name: string;
  phone: string;
  altphone: string;
  email: string;
  address: Address;
}

export interface PosInformation {
  store: string;
  storeName: string;
  contactInfo: ContactInfo;
}

export interface DeliverySlot {
  startTime: Date;
  endTime: Date;
}

export interface CustomerContactInfo {
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface DeliveryInformation {
  deliveryInstructions: string;
  deliveryCost: string;
  carrier: string;
  carrierTrackingId: string;
  shipmentSize: string;
  deliverySlot: DeliverySlot;
  customerContactInfo: CustomerContactInfo;
  driver: Driver;
  helper: Driver;
  vehicle: Vehicle;
  transitTime: string;
  actualTransitTime: string;
  dynamicTransitTime: string;
  estimatedTransitTime: string;
}

export interface PickUpContactInfo {
  companyName: string;
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface PickupSlot {
  startTime: Date;
  endTime: Date;
}

export interface PickUpInformation {
  pickUpContactInfo: PickUpContactInfo;
  pickupSlot: PickupSlot;
}

export interface Reason {
  deliveryStatus: string;
  reasonCode: string;
  reasonDescription: string;
}

export interface Order {
  id: string;
  orderCode: string,
  consignmentCode: string,
  hasRejectedProducts: boolean,
  retryCount: number,
  metadata: MetaData;
  products: Product[];
  reason: Reason;
  posInformation: PosInformation;
  deliveryInformation: DeliveryInformation;
  pickUpInformation: PickUpInformation;
  statuses: Statuses[];
}

export interface CountryDetails {
  countryCode: string;
  countryName: string;
}

export interface AssignedStatus {
  vehicleAssigned: boolean;
  helperAssigned: boolean;
  driverAssigned: boolean;
  consignmentsAvailable: boolean;
}

export interface Vehicle {
  brand: string;
  country: string;
  crateCapacity: number;
  fleetId: string;
  id: string;
  orderCapacity: number;
  type: string;
  name: string;
}

export interface Driver {
  countryIsoCode: string;
  countryName: string;
  licenseNumber: number;
  id: string;
  name: string;
  type: string;
}

export interface Route {
  id: string;
  routeId: string;
  creationTime: string;
  plannedDate: string;
  createdBy: string;
  carrier: string;
  deliveryProposition: string;
  deliveryArea: string[];
  deliverySlot: string[];
  countryDetails: CountryDetails;
  totalConsignment: number;
  transitTime: number;
  displayRouteStatus: string;
  hubCode: string;
  hubName: string;
  status: string;
  assignedStatus: AssignedStatus;
  assignedVehicle: Vehicle;
  assignee: Driver;
  helper: Driver;
  actualTransitTime: string;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface PlanningOrdersDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: Order[];
}

export interface PlanningRoutesDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: Route[];
}
export interface Statuses {
  statusCode: string;
  statusName: string;
  statusDate: string;
  reason: Reason;
}

export const lastMileVTOrdersDataTransform = (response: PlanningOrdersDataResponse): any[] => {
  const transformedArray: any[] = [];
  response.elements.forEach((order: Order) => {
    const {
      id,
      metadata,
      posInformation: { store, storeName },
      deliveryInformation,
      orderCode,
      products,
      reason,
      consignmentCode,
      statuses,
    } = order;
    // TODO: map/add actual objects too for drilling down content or store in state
    const deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND);
    const startTime = new Date(deliveryInformation.deliverySlot.startTime);
    const endTime = new Date(deliveryInformation.deliverySlot.endTime);
    const customerContactInfo = deliveryInformation.customerContactInfo || {
      address: {}
    };
    const transactionDate = moment(metadata.transactionDate, AppConstants.DATE_FORMAT_BACKEND);
    const statusDate = metadata.lastStatusUpdatedTime ? moment(metadata.lastStatusUpdatedTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.TIMESTAMP) : statuses && statuses.length ? moment(statuses[0].statusDate, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.TIMESTAMP) : "";

    transformedArray.push({
      id: id,
      hubCode: store,
      hubName: storeName,
      deliveryArea: customerContactInfo.address ? customerContactInfo.address.area : "",
      consignmentId: consignmentCode,
      type: metadata.category,
      deliveryDate: deliveryDate.format(AppConstants.DATE_FORMAT),
      startEndTime: `${localTimeString(startTime)} - ${localTimeString(endTime)}`,
      deliveryStatus: metadata.deliveryStatus,
      statusDate: statusDate,
      products: products && products.length ? products.length : 0,
      numberOfItems: metadata.numberOfItems && metadata.numberOfItems > 0 ? metadata.numberOfItems : products && products.length ? products.length : 0,
      orderId: orderCode,
      transactionDate: transactionDate.format(AppConstants.DATE_FORMAT),
      customerName: customerContactInfo.name,
      customerEmail: customerContactInfo.email,
      customerMobileNumber: customerContactInfo.phone,
      deliveryStreetAddress: getAddressInfo(customerContactInfo.address),
      landmark: customerContactInfo.address.landmark,
      paymentType: metadata.paymentType,
      totalAmount: fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : ""),
      codAmount: fixDecimals(2, metadata.amountToCollect ? metadata.amountToCollect.toString() : ""),
      collectedAmount: fixDecimals(2, metadata.collectedAmount ? metadata.collectedAmount.toString() : ""),
      carrier: deliveryInformation.carrier,
      deliveryProposition: metadata.deliveryType,
      routeId: metadata.routeName,
      routeStatus: metadata.routeStatus,
      driver: deliveryInformation.driver ? deliveryInformation.driver.name : "",
      helper: deliveryInformation.helper ? deliveryInformation.helper.name : "",
      vehicle: deliveryInformation.vehicle ? deliveryInformation.vehicle.name : "",
      plannedDuration: deliveryInformation.dynamicTransitTime ? deliveryInformation.dynamicTransitTime : deliveryInformation.estimatedTransitTime,
      actualDuration: deliveryInformation.actualTransitTime ? deliveryInformation.actualTransitTime : "",
      deliveryFailedReason: reason ? reason.reasonDescription : statuses && statuses.length && statuses[0].reason ? statuses[0].reason.reasonDescription : "",
    });
  });
  return [...transformedArray];
};

export const lastMileVTRoutesDataTransform = (response: PlanningRoutesDataResponse): any[] => {
  const transformedArray: any[] = [];
  response.elements.forEach((order: Route) => {
    const {
      id,
      routeId,
      hubCode,
      hubName,
      carrier,
      deliveryProposition,
      transitTime,
      deliveryArea,
      deliverySlot,
      plannedDate,
      status,
      totalConsignment,
      assignedVehicle,
      assignee,
      helper,
      createdBy,
      actualTransitTime
    } = order;
    // TODO: map/add actual objects too for drilling down content or store in state
    const deliveryDate = moment(plannedDate, AppConstants.DATE_FORMAT_BACKEND);
    transformedArray.push({
      id: id,
      hubCode: hubCode,
      hubName: hubName,
      deliveryArea: deliveryArea && deliveryArea.length ? deliveryArea : [],
      deliveryDate: deliveryDate.format(AppConstants.DATE_FORMAT),
      deliverySlot: deliverySlot && deliverySlot.length ? deliverySlot : [],
      consignments: totalConsignment,
      carrier: carrier,
      deliveryProposition: deliveryProposition,
      routeId: routeId,
      routeStatus: status,
      driver: assignee ? assignee.name : "",
      vehicle: assignedVehicle ? assignedVehicle.name : "",
      helper: helper ? helper.name : "",
      createdBy: createdBy,
      plannedDuration: transitTime,
      actualDuration: actualTransitTime,
      actions: order
    });
  });
  return [...transformedArray];
};

export const getConsignmentStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

export const getRouteStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.VALET_TROLLEY_CONSTANTS.ROUTES_DATA.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

export const sortedCountryData = (
  columnName: string,
  isDescending: boolean
) => (a: any, b: any) => {
  const aVal = isDescending ? a : b;
  const bVal = isDescending ? b : a;
  if (
    aVal[columnName].match(/^[0-9]+$/) &&
    bVal[columnName].match(/^[0-9]+$/)
  ) {
    return parseFloat(aVal[columnName]) - parseFloat(bVal[columnName]);
  } else {
    if (aVal[columnName] < bVal[columnName]) {
      return -1;
    } else if (aVal[columnName] > bVal[columnName]) {
      return 1;
    } else {
      return 0;
    }
  }
};

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
  let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
  const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
  const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
  if (selectAllIdx > -1) {
    currentValue = [];
  }
  if (selectNoneIdx > -1) {
    currentValue = [];
  }
  return currentValue;
}

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, isRoute?: boolean) => {
  payload.attributes = [];
  if (payload.hub) {
    if (payload.hub.value) {
      let hubValue = payload.hub.value.map((item: any) => item.value);
      let hubNameValue = [];
      if (payload.hubName && payload.hubName.value.length) {
        hubNameValue = payload.hubName.value.map((item: any) => item.value);
      }
      hubValue = hubValue.concat(hubNameValue);
      const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        hubValue = [];
      }
      if (selectNoneIdx > -1) {
        hubValue = [];
      }
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: getUniqueValues(hubValue)
      })
      delete payload.hub;
      delete payload.hubName;
    } else {
      payload.attributes.push({
        key: payload.hub.fieldName,
        value: []
      });
      delete payload.hub;
      delete payload.hubName;
    }
  }
  if (payload.deliveryArea) {
    let attrValue = [];
    if (payload.deliveryArea.value) {
      attrValue = getFilterAttributeValue(payload.deliveryArea.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryArea.fieldName,
      value: attrValue
    })
    delete payload.deliveryArea;
  }
  if (payload.orderType) {
    let attrValue = [];
    if (payload.orderType.value) {
      attrValue = getFilterAttributeValue(payload.orderType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.orderType.fieldName,
      value: attrValue
    })
    delete payload.orderType;
  }
  if (payload.deliveryStatus) {
    let attrValue = [];
    if (payload.deliveryStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryStatus.fieldName,
      value: attrValue
    })
    delete payload.deliveryStatus;
  }
  if (payload.paymentType) {
    let attrValue = [];
    if (payload.paymentType.value) {
      attrValue = getFilterAttributeValue(payload.paymentType.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.paymentType.fieldName,
      value: attrValue
    })
    delete payload.paymentType;
  }
  if (payload.carrier) {
    let attrValue = [];
    if (payload.carrier.value) {
      attrValue = getFilterAttributeValue(payload.carrier.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.carrier.fieldName,
      value: attrValue
    })
    delete payload.carrier;
  }
  if (payload.deliveryProposition) {
    payload.attributes.push({
      key: payload.deliveryProposition.fieldName,
      value: ["SVT"]
    })
    delete payload.deliveryProposition;
  }
  if (payload.routeId) {
    let attrValue = [];
    if (payload.routeId.value) {
      attrValue = getFilterAttributeValue(payload.routeId.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeId.fieldName,
      value: attrValue
    })
    delete payload.routeId;
  }
  if (payload.routeStatus) {
    let attrValue = [];
    if (payload.routeStatus.value) {
      attrValue = getFilterAttributeValue(payload.routeStatus.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.routeStatus.fieldName,
      value: attrValue
    })
    delete payload.routeStatus;
  }
  if (payload.driver) {
    let attrValue = [];
    if (payload.driver.value) {
      attrValue = getFilterAttributeValue(payload.driver.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.driver.fieldName,
      value: attrValue
    })
    delete payload.driver;
  }
  if (payload.vehicle) {
    let attrValue = [];
    if (payload.vehicle.value) {
      attrValue = getFilterAttributeValue(payload.vehicle.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.vehicle.fieldName,
      value: attrValue
    })
    delete payload.vehicle;
  }
  if (payload.helper) {
    let attrValue = [];
    if (payload.helper.value) {
      attrValue = getFilterAttributeValue(payload.helper.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.helper.fieldName,
      value: attrValue
    })
    delete payload.helper;
  }
  if (payload.deliveryFailedReason) {
    let attrValue = [];
    if (payload.deliveryFailedReason.value) {
      attrValue = getFilterAttributeValue(payload.deliveryFailedReason.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.deliveryFailedReason.fieldName,
      value: attrValue
    })
    delete payload.deliveryFailedReason;
  }
  if (payload.createdBy) {
    let attrValue = [];
    if (payload.createdBy.value) {
      attrValue = getFilterAttributeValue(payload.createdBy.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.createdBy.fieldName,
      value: attrValue
    })
    delete payload.createdBy;
  }
  if (payload.displayRouteStatus) {
    payload.attributes.push({
      key: payload.displayRouteStatus.fieldName,
      value: payload.displayRouteStatus.value || []
    })
    delete payload.displayRouteStatus;
  }
  if (payload.deliverySlot && payload.deliverySlot.value) {
    let delSlotValue = payload.deliverySlot.value.map((item: any) => item.value);
    let dateRange = [];
    const selectAllIdx = delSlotValue.indexOf(AppConstants.SELECT_ALL.value);
    if (payload.dateRange && payload.dateRange.value) {
      const allDates = enumerateDaysBetweenDates(payload.dateRange.value.startDate, payload.dateRange.value.endDate);
      if (selectAllIdx > -1 || !delSlotValue.length) {
        payload.isDateRange = true;
        dateRange.push({
          startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
        });
      } else {
        payload.isDateRange = false;
        delSlotValue.forEach((slot: any) => {
          const splitSlot = slot.split('-');
          const startSlot = splitSlot[0].split(':');
          const endSlot = splitSlot[1].split(':');
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              })
            })
          }
        })
      }
      if (payload.reset) {
        delete payload.isDateRange;
      }
    }
    payload.dateRange = dateRange;
    delete payload.displayRouteStatus;
    delete payload.deliverySlot;
    delete payload.reset;
  }
  if (isLoadMore && data) {
    payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
  }
  if (data.continuationToken && payload.pageNumber) {
    payload.requestContinuationToken = data.continuationToken
  }
  payload.isSVT = true;
  return payload;
};

export const createPayloadForExcelExport = (payload: any, data?: any, isLoadMore?: boolean, isRoute?: boolean) => {
  let newPayload = createFilterPayload(payload, data, isLoadMore, isRoute);
  var orderFilterRequestDto = { orderFilterRequestDto: newPayload };
  return orderFilterRequestDto;
};

export const createRoutesPayloadForExcelExport = (payload: any, data?: any, isLoadMore?: boolean, isRoute?: boolean) => {
  let newPayload = createFilterPayload(payload, data, isLoadMore, isRoute);
  var routeFilterRequestDto = { routeFilterRequestDto: newPayload };
  return routeFilterRequestDto;
};

/* export const createCratesInfoPayload = (payload: any) => {
  if (payload) {
    let consignmentCode;
    let storeCode;
    if (payload.consignmentCode) {
      consignmentCode = payload.consignmentCode;
    }
    if (payload.posInformation) {
      storeCode = payload.posInformation.store
    };
    var consignmentCodePosCodeMap = {
      consignmentCodePosCodeMap: {
        [consignmentCode]: storeCode
      }
    }
    return consignmentCodePosCodeMap;
  }
} */

export const createDraftRoutePayload = (payload: any,) => {
  const routeId = sessionStorage.getItem('routeId');
  const consignments = JSON.parse(sessionStorage.getItem('extraConsignments') || '[]');
  let responsePayload: any;
  responsePayload = {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    hubCode: payload.hubCode,
    hubName: payload.hubName,
    isSVT: true
  }
  if (payload.slotValue) {
    let dateRange = [];
    const selectAllIdx = payload.slotValue.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.slotValue.indexOf(AppConstants.SELECT_NONE.value);
    if (payload.date) {
      const allDates = enumerateDaysBetweenDates(payload.date.startDate, payload.date.endDate);
      if (selectAllIdx > -1 || selectNoneIdx > -1 || !payload.slotValue.length) {
        responsePayload.isDateRange = true;
        dateRange.push({
          startTime: moment(payload.date.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
          endTime: moment(payload.date.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
        });
      } else {
        responsePayload.isDateRange = false;
        payload.slotValue.forEach((slot: any) => {
          const splitSlot = slot.split('-');
          const startSlot = splitSlot[0].split(':');
          const endSlot = splitSlot[1].split(':');
          if (allDates.length) {
            allDates.forEach((date: string) => {
              dateRange.push({
                startTime: moment(date, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                endTime: moment(date, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
              })
            })
          }
        })
      }
    }
    responsePayload.timeSlot = dateRange;
  }
  if (payload.editMode) {
    responsePayload.editMode = true;
  }
  if (payload.statusValue) {
    responsePayload.deliveryStatuses = getFilterAttributeValue(payload.statusValue, true);
  }
  if (routeId) {
    if (!payload.editMode) {
      if (consignments.length) {
        responsePayload.route = {
          id: routeId
        }
      }
    } else {
      responsePayload.route = {
        id: routeId
      }
    }
    if (consignments.length) {
      responsePayload.consignmentIds = consignments;
    }
  }
  return responsePayload;
}

export const getDiscardRoutePayload = (params: any) => {
  const routeId = sessionStorage.getItem('routeId');
  const consignments = JSON.parse(sessionStorage.getItem('extraConsignments') || '[]');
  const previousConsignments = params.previousCons;
  let responsePayload: any = {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    routeId: routeId || "",
    previousConsignments: previousConsignments
  }
  if (consignments.length) {
    responsePayload.consignmentsAdded = consignments
  }
  return responsePayload;
}

export const createPublishRoutesPayload = (routes: RowData[]) => {
  return routes.length ? {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    ids: routes.map((route: RowData) => route.id)
  } : null;
}

export const createConsignmentReplanningPayload = (params: any) => {
  let payload: any = {
    consignmentId: params.id
  };
  if (params.selection === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION) {
    payload.reScheduled = true;
    const splitSlot = params.slot.split('-');
    const startSlot = splitSlot[0].split(':');
    const endSlot = splitSlot[1].split(':');
    payload.timeSlot = {
      startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT_UI).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT_UI).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
    }
  } else {
    payload.reScheduled = false;
    payload.comments = params.commentsValue;
    payload.reason = params.reason ? {
      reasonCode: params.reason.reasonCode,
      reasonDescription: params.reason.reasonDescription,
      deliveryStatus: params.reason.deliveryStatus
    } : {}
  }
  return payload;
}

export const createDeleteRoutePayload = (id: string) => {
  return id ? {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    id: id
  } : null;
}

export const createRouteCreationPayload = (payload: any) => {
  let transitTime: any = calculateTransitTimeForConsignments(payload.mapRouteDirections, payload.consignments);
  let routePayload: any = {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    route: {
      id: payload.route.id,
      creationTime: payload.route.creationTime,
      createdBy: payload.route.createdBy,
      countryDetails: payload.route.countryDetails,
      hubCode: payload.route.hubCode,
      hubName: payload.route.hubName,
      deliveryArea: getUniqueValues(payload.consignments, 'area'),
      deliverySlot: getUniqueValues(payload.consignments, 'slot'),
      totalConsignment: payload.consignments.length,
      deliveryDate: moment(payload.date, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      plannedDate: payload.route.plannedDate,
      transitTime: payload.transitTime,
      consignmentsIds: payload.consignments.map((item: any) => item.code)
    },
    consignmentTransitTime: transitTime
  }
  if (payload.selectedDriver !== AppConstants.SELECT_NONE.value) {
    routePayload.route.assignee = {
      id: payload.selectedDriver
    }
  }
  if (payload.selectedHelper !== AppConstants.SELECT_NONE.value) {
    routePayload.route.helper = {
      id: payload.selectedHelper
    }
  }
  if (payload.selectedVehicle !== AppConstants.SELECT_NONE.value) {
    routePayload.route.assignedVehicle = {
      id: payload.selectedVehicle
    }
  }
  return routePayload;
}

export const createGetAllDropdownsPayload = (params: any) => {
  return {
    timeSlot: {
      startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
    },
    hubCode: params.hubCode,
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE)
  }
}

export const createGetAllPlannedRoutesPayload = (params: any) => {
  return {
    timeSlot: {
      startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
    },
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    hubCode: params.hubCode,
    isSVT: true
  }
}

export const createUpdateOrderStatusPayload = (params: any) => {
  return {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    consignmentIds: params.consignments,
    displayRouteStatus: params.status,
    routeId: params.routeId
  }
}

export const getPlannedRoutesData = (routeData: any) => {
  let plannedSlots = [];
  if (routeData.PLANNED) {
    const plannedValue: any = routeData.PLANNED;
    for (let slotKey in plannedValue) {
      const slotValue: any = plannedValue[slotKey];
      let slot: any = {
        code: slotKey,
        value: slotValue.slotValue,
        data: []
      };
      let totalConsignments = 0;
      const elements: any = slotValue.elements.map((route: any) => {
        totalConsignments += route.totalConsignment;
        return {
          code: route.id,
          value: route.routeId,
          data: route,
          slot: slotKey,
          slotValue: slotValue.slotValue
        };
      });
      slot.data = elements;
      slot.totalConsignments = totalConsignments;
      plannedSlots.push(slot);
    }
  }
  return plannedSlots;
}


export const createVTOrderReplanningPayload = (params: any) => {
  let payload: any = {
    consignmentId: params.id
  };
  if (params.status === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION) {
    payload.reScheduled = true;
    const splitSlot = params.slot.split('-');
    const startSlot = splitSlot[0].split(':');
    const endSlot = splitSlot[1].split(':');
    payload.timeSlot = {
      startTime: moment(params.date.startDate, AppConstants.DATE_FORMAT_UI).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
      endTime: moment(params.date.endDate, AppConstants.DATE_FORMAT_UI).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
    }
  } else {
    payload.reScheduled = false;
    payload.status = params.selection;
    payload.comments = params.commentsValue;
    payload.reason = params.reason ? {
      reasonCode: params.reason.reasonCode,
      reasonDescription: params.reason.reasonDescription,
      deliveryStatus: params.reason.deliveryStatus
    } : {}
  }
  return payload;
}

export const getConsignmentData = async (draftRouteData: any) => {
  let areas: any[] = [];
  let consignments: any[] = [];
  let slots: any = {};
  if (draftRouteData) {
    Object.keys(draftRouteData).forEach((statusKey: string) => {
      const statusValue: any = draftRouteData[statusKey]
      Object.keys(statusValue).forEach((areaKey: string) => {
        const areaIndex: number = areas.findIndex((area: any) => area.code === areaKey);
        let area: any = areaIndex === -1 ? {
          code: areaKey,
          value: areaKey,
          data: []
        } : { ...areas[areaIndex] };
        const areaValue: any = statusValue[areaKey];
        Object.keys(areaValue).forEach((slotKey: string) => {
          const slotValue: any = areaValue[slotKey];
          const slotIndex: number = areaIndex !== -1 ? area.data.findIndex((slot: any) => slot.code === slotKey) : -1;
          let slot: any = slotIndex === -1 ? {
            code: slotKey,
            value: slotValue.slotValue,
            area: areaKey,
            data: []
          } : { ...area.data[slotIndex] };
          const elements: any = slotValue.elements.map((consignment: any) => {
            return {
              code: consignment.id,
              value: consignment.id,
              data: consignment,
              slot: slotKey,
              slotValue: slotValue.slotValue,
              area: areaKey,
              status: statusKey
            };
          });
          if (slotIndex !== -1) {
            slot.data = [...slot.data, ...elements];
            area.data[slotIndex] = slot;
          } else {
            slot.data = elements;
            area.data.push(slot);
          }
          if (slots[area.code]) {
            slots[area.code][slot.code] = slot.data;
          } else {
            slots[area.code] = {
              [slot.code]: slot.data
            }
          }
          consignments = [...consignments, ...elements];
        })
        if (areaIndex !== -1) {
          areas[areaIndex] = area;
        } else {
          areas.push(area);
        }
      })
    })
  }
  return {
    areaWiseConsignments: areas,
    allConsignments: consignments,
    slotwiseConsignments: slots
  };
}

export const createPayloadForUpdatingCustAddress = (params: any, consignmentCode: any) => {
  return {
    consignmentCode: consignmentCode,
    address: params
  }
}

export const transformRouteConsignments = (consignments: any[]) => {
  let routeConsignments: any = {};
  let seqConsignments: any = [];
  consignments.forEach((consignment: any) => {
    let newConsignment: any = {
      code: consignment.id,
      value: consignment.id,
      data: consignment,
      area: consignment.deliveryInformation && consignment.deliveryInformation.customerContactInfo && consignment.deliveryInformation.customerContactInfo.address.area,
      slot: consignment.deliveryInformation && consignment.deliveryInformation.deliverySlot.deliverySlotTime
    };
    seqConsignments.push(consignment.id);
    if (routeConsignments[newConsignment.area]) {
      if (routeConsignments[newConsignment.area][newConsignment.slot]) {
        routeConsignments[newConsignment.area][newConsignment.slot].push(newConsignment);
      } else {
        routeConsignments[newConsignment.area][newConsignment.slot] = [newConsignment];
      }
    } else {
      routeConsignments[newConsignment.area] = {
        [newConsignment.slot]: [newConsignment]
      };
    }
  });
  return {
    routeConsignments: routeConsignments,
    seqConsignments: seqConsignments
  };
}

export const getAllAreasAndSlots = (areas: any[]) => {
  let allAreas: string[] = [];
  areas.forEach((area: any) => {
    const slots = area.data;
    slots.forEach((slot: any) => {
      allAreas.push(`${area.code}-${slot.code}`);
    });
    allAreas.push(area.code);
  });
  return allAreas;
}

export const getDropdownsData = (payload: any, codeKey?: string, valueKey?: string) => {
  return payload.length ? payload.map((item: any) => {
    return {
      code: codeKey ? item[codeKey] : item.id,
      value: valueKey ? item[valueKey] : item.name
    }
  }) : [];
}

export const getTotalCrates = (consignments: any[]) => {
  let totalCrates = 0;
  if (consignments.length) {
    consignments.forEach((item: any) => {
      if (item.data && item.data.deliveryInformation) {
        totalCrates += parseInt(item.data.deliveryInformation.totalCratesCount);
      }
    });
  }
  return totalCrates;
}

export const calculateTransitTime = (directions: any) => {
  let transitTime = 0;
  if (directions.legs && directions.legs.length) {
    directions.legs.forEach((leg: any) => {
      transitTime += leg.duration ? leg.duration.value : 0;
    });
    transitTime += (AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.DRIVER_SERVICE_TIME * directions.legs.length);
  }
  return transitTime;
}

export const calculateTransitTimeForConsignments = (directions: any, consignments: any) => {
  let consignmentIdsTransitTime: any = {};
  if (directions.legs && directions.legs.length) {
    for (var i = 0; i < consignments.length; i++) {
      if (directions.legs[i]) {
        consignmentIdsTransitTime[consignments[i].code] = directions.legs[i].duration.value
      }
      else {
        consignmentIdsTransitTime[consignments[i].code] = 0
      }
    }
  }
  return consignmentIdsTransitTime;
}

export const getAddressInfo = (addressObj: Address) => {
  return addressObj ? `${addressObj.building ? addressObj.building + ', ' : ""}${addressObj.apartment ? addressObj.apartment + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber + ', ' : ""}${addressObj.makani ? addressObj.makani + ', ' : ""}${addressObj.streetNumber ? addressObj.streetNumber + ', ' : ""}${addressObj.streetName ? addressObj.streetName + ', ' : ""}${addressObj.area ? addressObj.area + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber : ""}
  ${addressObj.city ? addressObj.city + ', ' : ""}${addressObj.postalCode ? addressObj.postalCode + ', ' : ""}${addressObj.countryIsoCode ? addressObj.countryIsoCode : ""}` : "";
}

export const getLatLong = (addressObj: Address) => {
  return addressObj ? `${addressObj.latitude ? parseFloat(addressObj.latitude).toFixed(5) + ', ' : ""}${addressObj.longitude ? parseFloat(addressObj.longitude).toFixed(5) : ""}` : "";
}

export const checkTerminalStatus = (deliveryStatus: string) => {
  return AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.TERMINAL_STATUSES.indexOf(deliveryStatus) > -1;
}
