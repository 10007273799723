import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow-icon.svg";
import { ReactComponent as crateBoxIcon } from "../../../assets/icons/crate-box-icon.svg";
import { ReactComponent as timeSlotIcon } from "../../../assets/icons/time-slot-icon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location-icon.svg";
import { ReactComponent as PayWalletIcon } from "../../../assets/icons/pay-wallet-icon.svg";

import { getPaymentModeString, getSlotName, getVerificationStatusField } from "../utils/AllRouteUtils";
import { getStatusWithoutUnderScore } from "../../../mocks/allRoutes/response.transforms";
import { isInputAnArray, isNonEmptyArray, isNonEmptyObject, isObject, isUndefined } from "../../../utils/helpers.utils";

import { COLORS } from "../../../config/theme/baseTheme";
import { getDeliverySlotTitleView } from "../utils/AllRouteViewUtils";
import CustomTooltip from "../../../common/Tooltip";
import RenderRescheduleOrders from "../../../common/RescheduleOrders/RenderRescheduleOrders";

interface CustomUnPlannedRouteViewInterface {
  classes: any;
  areaName: any;
  areaObj: any;
  areaNameIndex: number;
  handleUnplannedAccordianToggle: any;
  handleUnplannedInnerOrderAccordianToggle: any;
  handleUnplannedOrderCheckboxToggle: any;
  handleUnplannedAreaCheckboxToggle: any;
  handleOpenCloseUnplannedRouteSlot: any;
  handleUnplannedSlotCheckboxToggle: any;
}

const CustomUnPlannedRouteView = (props: CustomUnPlannedRouteViewInterface) => {
  const {
    classes,
    areaName,
    areaObj,
    areaNameIndex,
    handleUnplannedAccordianToggle,
    handleUnplannedInnerOrderAccordianToggle,
    handleUnplannedOrderCheckboxToggle,
    handleUnplannedAreaCheckboxToggle,
    handleOpenCloseUnplannedRouteSlot,
    handleUnplannedSlotCheckboxToggle,
  } = props;

  const areaContainsSlots = (obj: any) => {
    if (obj && obj.slotWiseOrders && isObject(obj.slotWiseOrders) && isNonEmptyObject(obj.slotWiseOrders)) {
      return true;
    }
    return false;
  };

  const areaSlotWiseOrdersPresent = (obj: any) => {
    if (obj && isInputAnArray(obj) && isNonEmptyArray(obj)) {
      return true;
    }
    return false;
  };

  const isAlOrdersCheckedForSlot = (slotName: any) => {
    if (
      !isUndefined(areaObj) &&
      !isUndefined(areaObj.slotWiseOrders) &&
      !isUndefined(areaObj.slotWiseOrders[slotName]) &&
      isInputAnArray(areaObj.slotWiseOrders[slotName]) &&
      isNonEmptyArray(areaObj.slotWiseOrders[slotName]) &&
      areaObj.slotWiseOrders[slotName].findIndex((order: any) => order.isChecked === false) === -1
    ) {
      return true;
    }
    return false;
  };

  return (
    <Accordion key={`un-plannedRoute-${areaName}`} expanded={areaObj.isOpened} className="accordionRoot delSlot" TransitionProps={{ unmountOnExit: true, timeout: 0 }}>
      <AccordionSummary
        expandIcon={<SvgIcon component={downArrowIcon} onClick={(e: any) => handleUnplannedAccordianToggle(e, areaName)} />}
        className="accordianSummary delSlot"
        onClick={(e: any) => handleUnplannedAccordianToggle(e, areaName)}
      >
        <Grid className="slotHeaderContainer" container direction="row" justify="space-between" alignItems="center">
          <Grid className="slotHeaderItem noPadding" item>
            <Grid className="itemContainer" container>
              <Checkbox color="primary" checked={areaObj.isChecked} className="checkBoxIconClass" onClick={(e: any) => handleUnplannedAreaCheckboxToggle(e, areaName)} />
              {areaName && areaName.length > 20 ? (
                <CustomTooltip title={`${areaName || ""}`}>
                  <Typography className="heading" style={{ color: areaObj.color || COLORS.TEXT_PRIMARY }}>{`${areaName.substring(0, 17)}...`}</Typography>
                </CustomTooltip>
              ) : (
                <Typography className="heading" style={{ color: areaObj.color || COLORS.TEXT_PRIMARY }}>{`${areaName || ""}`}</Typography>
              )}
              <Typography className="subNote">{`(${areaObj.orderCount || 0} Orders)`}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container className="itemContainer" justify="flex-end">
              <Grid className="slotHeaderItem noPadding" item>
                <Grid className="itemContainer" container>
                  <Grid className="slotHeaderItem displayFlex borderRight" item>
                    <SvgIcon className="icon" viewBox="0 0 24 24" component={crateBoxIcon} />
                    <Typography className="normal">{`${areaObj.totalCrates || 0}`}</Typography>
                  </Grid>
                  <Grid className="slotHeaderItem noPadding displayFlex" item>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={timeSlotIcon} />
                    <Typography className="normal">{getDeliverySlotTitleView(areaObj)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="accordianDetails delSlot routes">
        {areaObj.isOpened && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
              {areaContainsSlots(areaObj) ? (
                <Grid container>
                  {Object.keys(areaObj.slotWiseOrders).map((slotName: any, slotNameIndex: any) => (
                    <>
                      <Grid item xs={12} key={`plannedRoute-route-heading-${slotName}`} className="unplannedSlotNameGrid" onClick={() => handleOpenCloseUnplannedRouteSlot(areaName, slotName)}>
                        <Grid className="displayFlex" item>
                          <Checkbox
                            color="primary"
                            checked={isAlOrdersCheckedForSlot(slotName)}
                            className="checkBoxIconClass"
                            onClick={(e: any) => handleUnplannedSlotCheckboxToggle(e, areaName, slotName)}
                          />
                          <SvgIcon className="icon" viewBox="0 0 16 16" component={timeSlotIcon} />
                          <Typography className="normal">{`${getSlotName(slotName)}`}</Typography>
                        </Grid>
                      </Grid>
                      {areaSlotWiseOrdersPresent(areaObj.slotWiseOrders[slotName]) ? (
                        areaObj.slotWiseOrders[slotName].map((orderObj: any, orderObjIndex: number) => (
                          <Grid item xs={12} key={`un-plannedRoute-inner-order-${orderObjIndex}-${slotNameIndex}`}>
                            <Accordion
                              expanded={orderObj.isOpened}
                              className={`innerAccordionRoot orderBox ${orderObjIndex % 2 !== 0 ? "evenBackground" : ""}`}
                              TransitionProps={{ unmountOnExit: true, timeout: 0 }}
                              id={`order-code-${orderObj.consignmentCode}`}
                            >
                              <AccordionSummary
                                expandIcon={<SvgIcon component={downArrowIcon} onClick={(e: any) => handleUnplannedInnerOrderAccordianToggle(e, areaName, slotName, orderObjIndex)} />}
                                className="innerAccordionSummary orderBox"
                                onClick={(e: any) => handleUnplannedInnerOrderAccordianToggle(e, areaName, slotName, orderObjIndex)}
                              >
                                <Grid className="slotHeaderContainer" container direction="row" justify="space-between" alignItems="center">
                                  <Grid className="slotHeaderItem" item>
                                    <Grid container className="itemContainer" justify="flex-start">
                                      <Checkbox
                                        color="primary"
                                        className="checkBoxIconClass"
                                        checked={orderObj.isChecked}
                                        onClick={(e: any) => handleUnplannedOrderCheckboxToggle(e, areaName, slotName, orderObjIndex)}
                                      />
                                      <Typography className="orderNoCircle" style={{ background: areaObj.color || COLORS.TEXT_PRIMARY }}>{`${
                                        Number(orderObj?.orderIndex) >= 0 ? orderObj?.orderIndex : orderObjIndex + 1
                                      }`}</Typography>
                                      <Typography className="normal">{`${orderObj.consignmentCode || ""}`}</Typography>
                                      {orderObj?.rescheduled && <RenderRescheduleOrders />}  
                                    </Grid>
                                  </Grid>
                                  <Grid className="slotHeaderItem" item>
                                    <Grid container className="itemContainer" justify="flex-end">
                                      <Grid className="slotHeaderItem noPadding" item>
                                        <Grid className="itemContainer" container>
                                          <Grid className="slotHeaderItem" item>
                                            <Grid container className="itemContainer borderRight" justify="flex-start">
                                              <SvgIcon className="svgIcon" viewBox="0 0 24 24" component={crateBoxIcon} />
                                              <Typography className="normal">{`${orderObj.totalCratesCount}`}</Typography>
                                            </Grid>
                                          </Grid>
                                          <Grid className="slotHeaderItem" item>
                                            <Grid className={classes.statusHeaderContainer} container alignItems="center">
                                              <Typography className={["tag", getVerificationStatusField("color", orderObj && orderObj.deliveryStatus)].join(" ")}>
                                                {orderObj && orderObj.deliveryStatus ? getStatusWithoutUnderScore(orderObj.deliveryStatus) : ""}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails className="innerAccordionDetails slotHeaderItem routes">
                                {orderObj.isOpened && (
                                  <Grid className="slotHeaderContainer" container>
                                    <Grid className="slotHeaderItem" item>
                                      <Grid container className="itemContainer" justify="flex-start">
                                        <Grid item>
                                          <Grid container className="itemContainer" justify="flex-start">
                                            <Grid className="slotHeaderItem noPadding" item>
                                              <Grid className="itemContainer" container>
                                                <Grid className="slotHeaderItem displayFlex noPadding" item>
                                                  <SvgIcon className="icon" viewBox="0 0 16 16" fontSize="large" component={LocationIcon} />
                                                  <Typography className="normalWithMargin">{`${(orderObj.address && orderObj.address.fullAddress) || ""}`}</Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid container className="itemContainer" justify="flex-start">
                                            <Grid className="slotHeaderItem noPadding" item>
                                              <Grid className="itemContainer" container>
                                                <Grid className="slotHeaderItem paddingTop borderRight" item>
                                                  <Typography className="heading displayFlex alignItemCenter">
                                                    {`Area : `} <Typography className="normalWithMargin">{`${(orderObj.address && orderObj.address.area) || ""}`}</Typography>
                                                  </Typography>
                                                </Grid>
                                                <Grid className="slotHeaderItem paddingTop displayFlex" item>
                                                  <SvgIcon className="svgIcon" viewBox="0 0 24 24" component={PayWalletIcon} />
                                                  <Typography className="normalWithMargin">{getPaymentModeString(orderObj.paymentMethod)}</Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        ))
                      ) : (
                        <Typography className="padding-2">No Items to display</Typography>
                      )}
                    </>
                  ))}
                </Grid>
              ) : (
                <Typography className="padding-2">No Items to display</Typography>
              )}
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(CustomUnPlannedRouteView);
