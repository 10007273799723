import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {COLORS} from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaperPropsRoot: {
      minWidth: "95vw",
      height: "95vh",
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    uploadContainer: {
      "& .buttonsContainer": {
        "& .userBtn": {
          "&.secondary": {
            marginRight: theme.spacing(2),
          },
          flexGrow: 1,
        },
      },
    },
    uploadButtonsContainer: {
      justifyContent: "flex-start",
      paddingBottom: theme.spacing(3),
      "& .dialogBtn": {
        "&.secondary": {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1),
        },
      },
    },
    contentItem: {
      paddingTop: theme.spacing(3),
      "& .itemContainer": {
        flexDirection: "column",
        "& .itemLabel": {
          paddingBottom: theme.spacing(1),
          alignSelf: "flex-start",
          "& p": {
            fontSize: 14,
            fontWeight: 400,
          },
        },
        "& .itemInput": {
          "& .MuiOutlinedInput-input": {
            fontSize: 14,
            fontWeight: 400,
          },
          "& .userIdContainer .input": {
            flexGrow: 1,
            paddingRight: theme.spacing(2),
          },
        },
      },
    },
    uploadDialogTitle: {
      fontSize: "24px",
      fontWeight: 600,
    },
    container: {
      padding: "4px 0px 0px 8px",   
      "& .prevContainer": {
        padding: theme.spacing(3),
        "& .receiptsPanel": {
          background: "rgba(250, 250, 251, 0.89)",
          padding: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          height: "678px",
          overflow: "auto",
        },
        "& .panelTitle": {
          color: "#505050",
          fontSize: "20px",
          fontWeight: 600,
        },
        "& .defaultPanel": {
          height: "405px",
          "& .title": {
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
          },
          "& .content": {
            color: "#505050",
            fontSize: "20px",
            fontWeight: 600,
          },
        },
        "& .uploadedFilePanel": {
          "& .container": {
            height: "52px",
            border: `1px solid ${COLORS.SEA_GREEN}`,
            borderRadius: "12px",
            marginTop: theme.spacing(2),
            "& .item": {
              padding: "12px 20px",
            },
            "& .content": {
              display: "flex",
              justifyContent: "space-between",
              "& .fileName": {
                fontSize: "14px",
                fontWeight: 500,
                color: "#141414",
              },
              "& .fileTimestamp": {
                fontSize: "10px",
                fontWeight: 400,
                color: "#505050",
                paddingTop: theme.spacing(1),
              },
              "& .icon": {
                height: "24px",
                width: "24px",
                cursor: "pointer",
                "&.delete": {
                  marginLeft: theme.spacing(1.5),
                },
              },
            },
          },
        },
      },
    },
    tempContainer: {
      "& .content": {
        paddingTop: theme.spacing(5),
        display: "flex",
        alignItems: "center",
      },
      "& .imgItem": {
        display: "flex",
        paddingLeft: theme.spacing(3),
        "& .name": {
          color: COLORS.MINE_SHAFT,
          fontSize: "14px",
          fontWeight: 600,
        },
        "& .icon": {
          width: "18px",
          height: "18px",
          marginLeft: theme.spacing(3),
          cursor: "pointer",
        },
      },
    },
    attachItem: {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(2.75),
      display: "flex",
      alignItems: "center",
      "&.secondary": {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        marginRight: theme.spacing(1),
      },
      "& .input": {
        display: "none",
      },
      "& .btnDiv": {
        display: "flex",
        borderRadius: "12px",
        cursor: "pointer",
      },
      "& .btnText": {
        color: COLORS.PRIMARY_MAIN,
        fontSize: "14px",
        fontWeight: 700,
        padding: "4px 0px 0px 16px",
      },
    },
  })
);
