import { TAB_VALUES_CONSTANT } from "./DelveDashboardConstant";

const DelveConstants = {
  DELVE_CONSTANTS: {
    CONSIGNMENTS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Consignments",
        HeaderData: [
          { field: "id", headerName: "Id", hide: true },
          {
            field: "hubName",
            headerName: "Hub Name",
            width: 130,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubName",
              fieldName: "hubName",
              fieldFullName: "c.posInformation.store",
              multiple: true,
              key: `autoComplete-hubName-${Math.floor(Math.random() * 1000)}`,
              label: "Hub Name",
              items: [],
            },
          },
          { field: "consignmentId", headerName: "Consignment Id", width: 160 },
          { field: "verified", headerName: "Consignment Status at Time of Verification", width: 170 },
          {
            type: "date",
            field: "readyToDispatchDate",
            headerName: "Ready to Dispatch Date [Default]",
            width: 160,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "readyToDispatchDate",
              fieldName: "delveDateRange",
              key: `delveDateRange-filter-${Math.floor(Math.random() * 1000)}`,
              placeholderText: "Ready To Dispatch Date",
              label: "Ready To Dispatch Date",
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: { month: "1" },
              locale: {},
            },
          },
          {
            type: "date",
            field: "deliveryDate",
            headerName: "Delivery Date",
            width: 160,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "deliveryDate",
              fieldName: "dateRange",
              key: `dateRange-filter-${Math.floor(Math.random() * 1000)}`,
              placeholderText: "Delivery Date",
              label: "Delivery Date",
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: { month: "1" },
              locale: {},
            },
          },
          {
            field: "deliverySlot",
            headerName: "Delivery Slot",
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldName: "deliverySlot",
              multiple: true,
              key: `autoComplete-deliverySlot-${Math.floor(Math.random() * 1000)}`,
              label: "Delivery Slot",
              items: [],
              fieldKey: "deliverySlot",
            },
          },
          { field: "crates", headerName: "Crates", width: 100 },
          { field: "totalItems", headerName: "Total Items", width: 100 },
          { field: "verificationStatus", headerName: "Verification Status", width: 220 },
          { field: "itemsVerified", headerName: "Items Verified", width: 120 },
          { field: "issuesFound", headerName: "Issues Found", width: 120 },
          { field: "verificationDate", headerName: "Verification Date", width: 160, hide: false },
          { field: "verificationTime", headerName: "Verification Time", width: 160, hide: false },
          { field: "verifiedBy", headerName: "Verified By", width: 160 },
          {
            field: "driverName",
            headerName: "Driver Name",
            width: 150,
            hide: false,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "driver",
              fieldName: "driver",
              fieldFullName: "c.deliveryInformation.driver.id",
              multiple: true,
              key: `autoComplete-driver-${Math.floor(Math.random() * 1000)}`,
              label: "Driver",
              items: [],
            },
          },
        ],
        ReturnTabHeaderData: [
          { field: "id", headerName: "Id", hide: true },
          {
            field: "hubName",
            headerName: "Hub Name",
            width: 130,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubName",
              fieldName: "hubName",
              fieldFullName: "c.posInformation.store",
              multiple: true,
              key: `autoComplete-hubName-${Math.floor(Math.random() * 1000)}`,
              label: "Hub Name",
              items: [],
            },
          },
          { field: "consignmentId", headerName: "Consignment Id", width: 160 },
          { field: "verified", headerName: "Consignment Status at Time of Verification", width: 170 },
          {
            type: "date",
            field: "readyToDispatchDate",
            headerName: "Ready to Dispatch Date [Default]",
            width: 160,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "readyToDispatchDate",
              fieldName: "delveDateRange",
              key: `delveDateRange-filter-${Math.floor(Math.random() * 1000)}`,
              placeholderText: "Ready To Dispatch Date",
              label: "Ready To Dispatch Date",
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: { month: "1" },
              locale: {},
            },
          },
          {
            type: "date",
            field: "deliveryDate",
            headerName: "Delivery Date",
            width: 160,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "deliveryDate",
              fieldName: "dateRange",
              key: `dateRange-filter-${Math.floor(Math.random() * 1000)}`,
              placeholderText: "Delivery Date",
              label: "Delivery Date",
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: { month: "1" },
              locale: {},
            },
          },
          {
            field: "deliverySlot",
            headerName: "Delivery Slot",
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldName: "deliverySlot",
              multiple: true,
              key: `autoComplete-deliverySlot-${Math.floor(Math.random() * 1000)}`,
              label: "Delivery Slot",
              items: [],
              fieldKey: "deliverySlot",
            },
          },
          { field: "totalItems", headerName: "Total Items", width: 100 },
          { field: "verificationStatus", headerName: "Verification Status", width: 220 },
          { field: "itemsVerified", headerName: "Items Verified", width: 120 },
          { field: "issuesFound", headerName: "Issues Found", width: 120 },
          { field: "verificationDate", headerName: "Verification Date", width: 160, hide: false },
          { field: "verificationTime", headerName: "Verification Time", width: 160, hide: false },
          { field: "verifiedBy", headerName: "Verified By", width: 160 },
          {
            field: "driverName",
            headerName: "Driver Name",
            width: 150,
            hide: false,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "driver",
              fieldName: "driver",
              fieldFullName: "c.deliveryInformation.driver.id",
              multiple: true,
              key: `autoComplete-driver-${Math.floor(Math.random() * 1000)}`,
              label: "Driver",
              items: [],
            },
          },
        ],
        FilterFormData: [
          {
            type: "dateRange",
            fieldName: "verificationDateRange",
            key: `verificationDateRange-filter-${Math.floor(Math.random() * 1000)}`,
            placeholderText: "Verification Date",
            label: "Verification Date",
            autoUpdateInput: false,
            autoApply: true,
            showDropdowns: true,
            linkedCalendars: true,
            maxSpan: {
              day: "6",
            },
            locale: {},
          },
          {
            type: "selectBox",
            fieldName: "verificationStatus",
            multiple: true,
            key: `selectBox-verificationStatus-${Math.floor(Math.random() * 1000)}`,
            label: "verification Status",
            items: [],
          },
        ],
        headerButtons: [
          {
            name: "colsettings",
            displayName: "Column Settings",
            disabled: false,
            childElements: [{ name: "excel", displayName: "EXPORT DATA", disabled: false }],
          },
        ],
        loadMore: {
          name: "loadMore",
          disabled: false,
          pageSize: 20,
        },
        defaultFiltersObj: {
          hub: { key: "hub", value: [], fieldName: "c.posInformation.store" },
          hubName: { key: "hubName", value: [], fieldName: "c.posInformation.store" },
          verified: { key: "verified", value: [], fieldName: "c.metadata.qcStatusDelivery.verified" },
          deliveryArea: { key: "deliveryArea", value: [], fieldName: "c.deliveryInformation.customerContactInfo.address.area" },
          orderType: { key: "orderType", value: [], fieldName: "c.metadata.category" },
          paymentType: { key: "paymentType", value: [], fieldName: "c.metadata.paymentType" },
          carrier: { key: "carrier", value: [], fieldName: "c.deliveryInformation.carrier" },
          deliveryProposition: { key: "deliveryProposition", value: ["STD"], fieldName: "c.metadata.deliveryType" },
          routeId: { key: "routeId", value: [], fieldName: "c.metadata.routeId" },
          routeStatus: { key: "routeStatus", value: [], fieldName: "c.metadata.routeStatus" },
          driver: { key: "driver", value: [], fieldName: "c.deliveryInformation.driver.id" },
          vehicle: { key: "vehicle", value: [], fieldName: "c.deliveryInformation.vehicle.id" },
          helper: { key: "helper", value: [], fieldName: "c.deliveryInformation.helper.id" },
          deliveryFailedReason: { key: "deliveryFailedReason", value: [], fieldName: "c.reason.reasonCode" },
          dateRange: { key: "dateRange", value: { startDate: new Date(), endDate: new Date() } },
          deliverySlot: { key: "deliverySlot", value: [] },
          delveDateRange: { key: "delveDateRange", value: { startDate: new Date(), endDate: new Date() } },
          consignmentCode: { key: "c.consignmentCode", value: [] },
          isDelve: true,
          pageNumber: 0,
          deliveryFlow: TAB_VALUES_CONSTANT.DELIVERIES.value /** https://mafretail.atlassian.net/browse/LMC-2428 */,
          returns: { key: "verified", value: [], fieldName: "c.metadata.qcStatusReturn.verified" },
        },
        SEARCH_TYPES: [{ name: "Consignment Id", value: "c.consignmentCode" }],
        sortModel: { field: "deliveryDate", direction: "asc" },
      },
      FILTER_CHIPS: [
        { code: "ALL", value: "All" },
        { code: "VERIFIED", value: "Verified" },
        { code: "PARTIALLY_VERIFIED", value: "Partially Verified" },
        { code: "UNVERIFIED", value: "Unverified" },
        { code: "ISSUES_REPORTED", value: "Issues Reported" }, // Combination of PARTIALLY_VERIFIED_ISSUES and VERIFIED_ISSUES
      ],
      RETURN_TYPE_FILTER_CHIPS: [
        { code: "ALL", value: "All" },
        { code: "VERIFIED", value: "Verified" },
        { code: "UNVERIFIED", value: "Unverified" },
        { code: "ISSUES_REPORTED", value: "Issues Reported" }, // Combination of PARTIALLY_VERIFIED_ISSUES and VERIFIED_ISSUES
      ],
      STATUSES: {
        NEW: { color: "PICTON_BLUE", hintText: "" },
        READY_TO_PREPARE: { color: "CREAM_CAN", hintText: "" },
        READY_TO_DISPATCH: { color: "BLUE", hintText: "" },
        PREPARING: { color: "VISTA_BLUE", hintText: "" },
        PREPARED: { color: "SEA_GREEN", hintText: "" },
        OUT_FOR_DELIVERY: { color: "GOLDEN_BELL", hintText: "" },
        ON_HOLD: { color: "SCAMPI", hintText: "" },
        PENDING_DELIVERY: { color: "TROPICAL_BLUE", hintText: "" },
        DELIVERED: { color: "SEA_GREEN2", hintText: "" },
        PARTIALLY_DELIVERED: { color: "LUCKY", hintText: "" },
        CANCELLED: { color: "DUSTY_GRAY", hintText: "" },
        "RETURNED_TO_STORE/HUB": { color: "JUNGLE_GREEN", hintText: "" },
        DELIVERY_FAILED: { color: "BLACK", hintText: "" },
        PACKING_COMPLETE: { color: "MINSK", hintText: "" },
        ASSIGNED: { color: "BLUE_HAZE", hintText: "" },
      },
      VERIFICATION_STATUS: {
        VERIFIED: { color: "OCEAN_GREEN", hintText: "" },
        UNVERIFIED: { color: "CORAL_PINK", hintText: "" },
        VERIFIED_ISSUES: { color: "ALERT_ORANGE", hintText: "" },
        PARTIALLY_VERIFIED: { color: "AVATAR_OCEAN", hintText: "" },
        PARTIALLY_VERIFIED_ISSUES: { color: "DARK_GOLD_ORANGE", hintText: "" },
      },
      ALL_FILTER_CHIP: "ALL",
      DEFAULT_FILTER_CHIP: "UNVERIFIED",
      VERIFIED_FILTER_CHIP: "VERIFIED",
      ISSUES_REPORTED_CHIP: "ISSUES_REPORTED",
      PARTIALLY_VERIFIED_ISSUES_CHIP: "PARTIALLY_VERIFIED_ISSUES",
      VERIFIED_ISSUES_CHIP: "VERIFIED_ISSUES",
      VERIFIED: { key: "verified", value: [], fieldName: "c.metadata.qcStatusDelivery.verified" },
    },
    RESPONSE_CONSTANTS: { ERROR_CODES: { SESSION_TIMEOUT: "UM100", UNAUTHORISED: "UM101", CONCURRENT_CONSIGNMENT: "CWO15" } },
  },
};

export default DelveConstants;