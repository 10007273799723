import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "100%",
    },
    container: {
      flexDirection: "column",
      "& .planningItem": {
        width: "100%",
        "& .MuiTab-root": {
          marginRight: 44,
        },
        "&.heading": {
          "& p": {
            fontSize: 20,
            lineHeight: "30px",
            color: COLORS.PRIMARY_MAIN,
            fontFamily: "Montserrat",
            fontWeight: 600,
          },
        },
        "&.content": {
          backgroundColor: COLORS.WHITE,
          borderRadius: theme.spacing(1),
          height: "calc(100vh - 172px)",
          "& .tabPanel": {
            height: "calc(100% - 56px)",
            padding: 0,
            "&.routes": {
              position: "relative",
            },
          },
        },
      },
    },
    tabPanelContainer: {
      flexDirection: "row",
      height: "100%",
      "& .panelItem": {
        width: "100%",
        "&.chipFilter": {
          padding: "16px 16px 16px 0px",
          "& .chipsContainer": {
            "& .chipFilterItem": {
              height: 36,
              marginRight: "24px",
              borderRadius: theme.spacing(1),
              border: "1px solid rgba(10, 67, 124, 0.2)",
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
              "&:last-child": {
                marginRight: 0,
              },
              "&.firstItem": {
                boxShadow: `0px 0px 10px 4px ${COLORS.BOX_SHADOW_PRIMARY}`,
                border: `2px solid ${theme.palette.primary.main}`,
              },
              "&.active": {
                background: `linear-gradient(274.11deg, ${COLORS.GRADIENT_BLUE} 2.78%, ${theme.palette.primary.main} 94.36%)`,
                border: "none",
                boxShadow: "none",
                "& .chipItem p": {
                  color: COLORS.WHITE,
                },
              },
              "&.fiveItemsWidth": { minWidth: "18%" },
              "&.fourItemsWidth": { minWidth: "23%" },
              "& .chipContainer": {
                alignItems: "center",
                height: "100%",
                "& .chipItem": {
                  padding: theme.spacing(0, 1.5),
                  "& p": {
                    fontWeight: 500,
                    color: COLORS.MINE_SHAFT,
                  },
                  "&.name": {
                    "& p": {
                      fontSize: 14,
                    },
                  },
                  "&.count": {
                    marginLeft: "auto",
                    "& p": {
                      fontSize: 24,
                    },
                  },
                },
              },
            },
          },
        },
        "&.gridWrapper": {
          height: "calc(100% - 70px)",
          "& .idLink": {
            cursor: "pointer",
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: 12,
          },
        },
      },
    },
    statusHeaderContainer: {
      "& .tag": {
        color: COLORS.WHITE,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        lineHeight: "14px",
        backgroundColor: COLORS.BLACK,
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: 12,
        "&.PICTON_BLUE": {
          color: COLORS.PICTON_BLUE,
          backgroundColor: COLORS.PICTON_BLUE_LIGHT,
        },
        "&.CREAM_CAN": {
          color: COLORS.CREAM_CAN,
          backgroundColor: COLORS.CREAM_CAN_LIGHT,
        },
        "&.BLUE": {
          color: COLORS.PRIMARY_MAIN,
          backgroundColor: COLORS.PRIMARY_LIGHT,
        },
        "&.VISTA_BLUE": {
          color: COLORS.VISTA_BLUE,
          backgroundColor: COLORS.VISTA_BLUE_LIGHT,
        },
        "&.SEA_GREEN": {
          color: COLORS.SEA_GREEN,
          backgroundColor: COLORS.SEA_GREEN_LIGHT,
        },
        "&.GOLDEN_BELL": {
          color: COLORS.GOLDEN_BELL,
          backgroundColor: COLORS.GOLDEN_BELL_LIGHT,
        },
        "&.SCAMPI": {
          color: COLORS.SCAMPI,
          backgroundColor: COLORS.SCAMPI_LIGHT,
        },
        "&.OLD_GOLD": {
          color: COLORS.OLD_GOLD,
          backgroundColor: COLORS.OLD_GOLD_LIGHT,
        },
        "&.SEA_GREEN2": {
          color: COLORS.SEA_GREEN2,
          backgroundColor: COLORS.SEA_GREEN2_LIGHT,
        },
        "&.LUCKY": {
          color: COLORS.LUCKY,
          backgroundColor: COLORS.LUCKY_LIGHT,
        },
        "&.DUSTY_GRAY": {
          color: COLORS.DUSTY_GRAY,
          backgroundColor: COLORS.DUSTY_GRAY_NEW_LIGHT,
        },
        "&.JUNGLE_GREEN": {
          color: COLORS.JUNGLE_GREEN,
          backgroundColor: COLORS.JUNGLE_GREEN_LIGHT,
        },
        "&.BLACK": {
          color: COLORS.BLACK,
          backgroundColor: COLORS.BLACK_LIGHT,
        },
        "&.MINSK": {
          color: COLORS.SCAMPI,
          backgroundColor: COLORS.SCAMPI_LIST,
        },
        "&.BLUE_HAZE": {
          color: COLORS.BLUE_HAZE,
          backgroundColor: COLORS.BLUE_HAZE_LIGHT,
        },
        "&.OCEAN_GREEN": {
          color: COLORS.OCEAN_GREEN,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.OCEAN_GREEN,
        },
        "&.CORAL_PINK": {
          color: COLORS.CORAL_PINK,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.CORAL_PINK,
        },
        "&.ALERT_ORANGE": {
          color: COLORS.ALERT_ORANGE,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.ALERT_ORANGE,
        },
        "&.AVATAR_OCEAN": {
          color: COLORS.AVATAR_OCEAN,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.AVATAR_OCEAN,
        },
        "&.DARK_GOLD_ORANGE": {
          color: COLORS.DARK_GOLD_ORANGE,
          backgroundColor: COLORS.WHITE,
          border: "solid",
          borderWidth: "1px",
          borderColor: COLORS.DARK_GOLD_ORANGE,
        },
        "&.ALTO": {
          color: COLORS.ALTO,
        },

        "&.CATSKILL_WHITE": {
          backgroundColor: COLORS.CATSKILL_WHITE,
          color: COLORS.ASTRAL,
        },

        "&.BARLEY_WHITE": {
          backgroundColor: COLORS.BARLEY_WHITE,
          color: COLORS.OLD_GOLD,
        },
      },
    },
    button: {
      margin: theme.spacing(0.5),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
    },
    colSettings: {
      float: "right",
      marginTop: -3,
    },
    buttonContainer: {
      marginLeft: "auto",
      "& .clearAllFilterBtn": {
        fontSize: 14,
        fontWeight: 600,
        marginRight: theme.spacing(2),
      },
    },
    dashboardTabRootStyle: {
      width: "100%",
      padding: "0px 24px 0px 24px",
      background: COLORS.ALICE_GRAY,
      height: "44px",
      minHeight: "44px",
      fontSize: 16,
      fontWeight: 500,
      color: COLORS.DUSTY_GRAY,
      "& .MuiTabs-indicator": {
        display: "none",
      },
      "& .MuiTabs-flexContainer": {
        height: "44px",
      },
      "& .MuiTab-root.Mui-selected": {
        fontWeight: 600,
        color: COLORS.MINE_SHAFT_NEW,
      },
    },
    dashboardTabPanelRootStyle: {
      width: "100%",
      padding: 0,
    },
  })
);
