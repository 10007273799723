import React, { useMemo, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Bundleproduct.styles";

interface BundleProductsProps {
  bundleItem: {
    bundleProductName: string;
    bundleProductQuantity: number;
    bundleProductShippedQty: number;
    bundleProductRejectedQty: number;
  };
  bundleShippedQty: number;
  rejectedQty?: number | false | undefined;
}
const BundleProductRow = (props: BundleProductsProps) => {
  const classes = useStyles();
  const {
    bundleItem: {
      bundleProductName,
      bundleProductShippedQty,
      bundleProductRejectedQty,
      bundleProductQuantity,
    },
    bundleShippedQty,
    rejectedQty,
  } = props;

  const [rejectedQtyAfterSelection, setRejectedQtyAfterSelection] = useState(
    bundleProductRejectedQty
  );

  useEffect(() => {
    if (rejectedQty) {
      const itemQtyAfterSelection =
        (bundleProductShippedQty / bundleShippedQty) * rejectedQty;
      setRejectedQtyAfterSelection(itemQtyAfterSelection);
    }
  }, [rejectedQty]);

  useEffect(() => {
    if (bundleProductRejectedQty) {
      setRejectedQtyAfterSelection(bundleProductRejectedQty);
    }
  }, [bundleProductRejectedQty]);

  const displayedOptions = useMemo(() => {
    return [
      { key: "name", value: bundleProductName, width: 4, class: "item1" },
      {
        key: "rejectedQty",
        value: bundleProductRejectedQty,
        width: 2,
        class: "item2",
      },
      {
        key: "shippedQty",
        value: bundleProductShippedQty,
        width: 2,
        class: "item2",
      },
      {
        key: "quantity",
        value: bundleProductQuantity,
        width: 2,
        class: "item2",
      },
    ];
  }, [
    bundleProductName,
    bundleProductShippedQty,
    bundleProductRejectedQty,
    bundleProductQuantity,
  ]);

  //{`${classes.itemsContainer} rowItem`}
  const getItemsDetail = () => {
    return (
      <Grid className={`${classes.bundleItemsContainer} rowItem`} container>
        {displayedOptions?.length &&
          displayedOptions.map((item: any, index: any) => (
            <Grid
              className={`itemColumn ${item.class} ${item.key}`}
              item
              key={index}
              xs={item.width}
            >
              <Typography className="itemsContent">
                <span>
                  {item.key === "rejectedQty"
                    ? rejectedQtyAfterSelection
                    : item.value}
                </span>
              </Typography>
            </Grid>
          ))}
      </Grid>
    );
  };

  return <>{getItemsDetail()}</>;
};

export default BundleProductRow;
