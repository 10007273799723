import React, { useCallback, forwardRef, ReactElement, Ref, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { fetchConsignmentHistoryAndProducts, exportInvoicePdf, closeDialog, resetReplanningSuccess } from "./redux/clickAndCollectSlice";
import mobileLogoIcon from './../assets/icons/mobileLogo.svg';
import emailLogoIcon from './../assets/icons/emailLogo.svg';
import { AppState } from "../config/redux/reducers";
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import { ReactComponent as activeTimelineDot } from './../assets/icons/active-timeline-dot.svg';
import { ReactComponent as errorTimelineDot } from './../assets/icons/timeline-dot-error.svg';
import { ReactComponent as timelineDot } from './../assets/icons/timeline-dot.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';

import { ReactComponent as zoomInIcon } from './../assets/icons/zoom-in.svg';

import { ReactComponent as zoomOutIcon } from './../assets/icons/zoom-out.svg';
import { useStyles } from './CNCConsignmentDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, SvgIcon, Link, Divider } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import { COLORS } from '../config/theme/baseTheme';

import Loader from "../common/Loader";
import AppConstants from "../constants";
import { fixDecimals, localTimeString, isCardOnDelivery } from 'utils/helpers.utils';
import moment from 'moment';
import CNCConsignmentEdit from './CNCConsignmentEdit';
import { Document, Page } from 'react-pdf';
import Modal from '@material-ui/core/Modal';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import clsx from "clsx";
import { pdfjs } from 'react-pdf';
import closeButton from "./../assets/icons/close-circle-icon.svg";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface ConsignmentDetailsProps {
  open: boolean;
  consignment: any;
  closePopup: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConsignmentDetails = (props: ConsignmentDetailsProps) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, consignmentStatuses, productsList, invoiceData, invoiceUrl, displayInvoice, invoiceMessage, invoiceConsignment, cncDataAfterReplanning } = useSelector(
    (state: AppState) => state.clickAndCollect
  );
  const { isLastMileViewer, isOnlyStdLastMileViewer } = useSelector(
    (state: AppState) => state.userLogin
  );
  const { open, consignment, closePopup } = props;
  const [openConsignmentEditPopup, setOpenConsignmentEditPopup] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [showOriginalName, setShowOriginalName] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [scale, setScale] = useState(1);
  const [scaleNumber, setScaleNumber] = useState(100);

  let deliveryInformation: any = {};
  let metadata: any = {};
  let custInfo: any = {
    address: {}
  };
  let pickupInformation: any = {};
  let pickUpContactInfo: any = {};
  let posInformation: any = {};
  //let statusErrorReason: any = {};
  let custGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let sellerGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let posInfo: any = {
    address: {}
  };
  let items: any = [];
  let historyItems: any = [];
  let deliveryDate = "";
  let deliverySlot = "";
  let hubName = "";

  const handleStatusHistoryAndProducts = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchConsignmentHistoryAndProducts({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  if (consignment) {
    deliveryInformation = consignment.deliveryInformation;
    metadata = consignment.metadata;
    custInfo = deliveryInformation && deliveryInformation.customerContactInfo;
    pickupInformation = consignment && consignment.pickUpInformation;
    posInformation = consignment.posInformation;
    if (pickupInformation && pickupInformation.pickUpContactInfo) {
      pickUpContactInfo = pickupInformation.pickUpContactInfo;
    }
    if (custInfo && custInfo.address) {
      custGeoLocation.name = custInfo.name;
      custGeoLocation.location.lat = parseFloat(custInfo.address.latitude) || 0;
      custGeoLocation.location.lng = parseFloat(custInfo.address.longitude) || 0;
    }
    if (consignment.posInformation) {
      posInfo = consignment.posInformation.contactInfo;
      hubName = consignment.posInformation.storeName || consignment.posInformation.store;
    }
    if (pickupInformation && pickupInformation.pickupSlot && pickupInformation.pickupSlot.deliverySlotTime) {
      const startTime = new Date(pickupInformation.pickupSlot.startTime);
      const endTime = new Date(pickupInformation.pickupSlot.endTime);
      deliveryDate = moment(pickupInformation.pickupSlot.startTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.DATE_FORMAT);
      deliverySlot = `${localTimeString(startTime)} - ${localTimeString(endTime)}`;
    }
    if (posInfo && posInfo.address) {
      sellerGeoLocation.name = hubName;
      sellerGeoLocation.location.lat = parseFloat(posInfo.address.latitude) || 0;
      sellerGeoLocation.location.lng = parseFloat(posInfo.address.longitude) || 0;
    }
    items = productsList;
    if (consignmentStatuses) {
      let statuses = consignmentStatuses.slice();
      historyItems = statuses;
    }
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }

  const handleOriginalName = () => {
    if (!showOriginalName) {
      setShowOriginalName(true);
    } else {
      setShowOriginalName(false);
    }
  }

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
  }

  const checkEditable = () => {
    if (Object.keys(cncDataAfterReplanning).length !== 0) {
      return AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(cncDataAfterReplanning && cncDataAfterReplanning.metadata && cncDataAfterReplanning.metadata.deliveryStatus) === -1;
    } else {
      return AppConstants.CNC_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(metadata && metadata.deliveryStatus) === -1;
    }
  }

  const getItems = () => {
    return items && items.length ? items.map((item: any, index: number) => (
      <Grid key={`${item.name.en} - ${index}`} className="item" item>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn item1" item xs={5}>
            <Typography>{item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].name && item.substitutedProducts[0].name.en ? item.substitutedProducts[0].name.en.toLowerCase() : item.name && item.name.en ? item.name.en.toLowerCase() : ""}</Typography>
            {
              item.reason ? (
                <Typography className="rejectedReason">{`Rejection Reason - ${item.reason.reasonDescription}`}</Typography>) :
                item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].reason ? (
                  <Typography className="rejectedReason">{`Rejection Reason - ${item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].reason && item.substitutedProducts[0].reason.reasonDescription}`}</Typography>
                ) : (
                    <Typography>{""}</Typography>
                  )
            }
            {
              item.substitutedProducts &&
              <div style={{ display: "flex" }}>
                <Typography className="viewOriginalLink"> View Original</Typography>
                <KeyboardArrowDownRoundedIcon className={clsx(!showOriginalName && classes.closeY, showOriginalName && classes.openY)} onClick={() => handleOriginalName()} />
              </div>
            }
          </Grid>
          <Grid className="itemColumn item2" item xs={2}>
            <Typography><span>{item.substitutedProducts && item.substitutedProducts.length ? item.substitutedProducts[0].rejectedQty : item.rejectedQty || "-"}</span></Typography>

          </Grid>

          <Grid className="itemColumn item2" item xs={3}>
            <Typography><span className="green">{item.substitutedProducts && item.substitutedProducts.length ? item.substitutedProducts[0].shippedQty : item.shippedQty || ""}</span>/{`${item.quantity || ""} ${item.uom && item.uom === 'Grams' ? item.uom : ""}`}</Typography>
          </Grid>
          <Grid className="itemColumn item3" item xs={2}>
            <Typography>{`${fixDecimals(2, item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
          </Grid>
        </Grid>

        {
          showOriginalName && item.substitutedProducts &&
          <Grid className="itemContainer items" container>
            <Grid className="itemColumn item1" item xs={5}>
              <Typography className="originalName">{item.name.en.toLowerCase()}</Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography><span>{item.rejectedQty || "-"}</span></Typography>

            </Grid>

            <Grid className="itemColumn item2" item xs={3}>
              <Typography><span className="green">{item.shippedQty || ""}</span>/{`${item.quantity || ""} ${item.uom && item.uom === 'Grams' ? item.uom : ""}`}</Typography>
            </Grid>
            <Grid className="itemColumn item3" item xs={2}>
              <Typography>{`${fixDecimals(2, item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    )) : (<Typography>No Items to display</Typography>)
  }

  const handleDialogClose = useCallback(
    () => {
      dispatch(
        closeDialog()
      );
      closePopup();
    },
    [closePopup, dispatch],
  );
  /* const checkStatusReason = useCallback(
    (reason: any, statusCode: string) => {
      return reason && reason.deliveryStatus ? reason.deliveryStatus === statusCode ? true : false : false;
    },
    [],
  ); */

  const closeConsignmentEditPopup = useCallback(
    () => {
      setOpenConsignmentEditPopup(false);
    },
    [],
  );

  const handleEditPopup = useCallback(
    () => {
      dispatch(
        resetReplanningSuccess()
      )
      setOpenConsignmentEditPopup(true);
    },
    [dispatch],
  );

  const handleInvoiceModalClose = () => {
    setShowInvoiceModal(false);
    setScale(1);
  };

  const zoomIn = (scaleNumber: any) => {
    setScaleNumber(scaleNumber + 15);
    setScale(scale + 0.4);
  };

  const zoomOut = (scaleNumber: any) => {
    if (scale > 1) {
      setScaleNumber(scaleNumber - 15);
      setScale(scale - 0.4);
    } else {
      setScale(1);
    }
  };

  const handleInvoicePdf = useCallback(
    (consignment) => {
      const paramsInvoice = {
        consignmentNo: [consignment.consignmentCode]
      };
      setShowInvoiceModal(true);
      dispatch(
        exportInvoicePdf({
          params: paramsInvoice
        })
      )
    },
    [dispatch]
  );

  const renderInvoiceModal = () => {
    return (
      <Modal open={showInvoiceModal} onClose={handleInvoiceModalClose}>
        <>
          {loading && <Loader></Loader>}
          {!loading && displayInvoice &&
            <>
              <Grid container className={classes.pdfHeader}>
                <Grid item xs={3} className={classes.pdfRoot}>
                  <Typography className={classes.pdfName}>{invoiceConsignment}.pdf ({numPages} Pages)</Typography>
                </Grid>
                <Grid item xs={6} className={classes.pdfRoot}>
                  <SvgIcon component={zoomOutIcon} className={classes.zoomOutStyle} onClick={() => zoomOut(scaleNumber)} />
                  <Typography className={classes.pdfScaleNo}>{scaleNumber}</Typography>
                  <SvgIcon component={zoomInIcon} onClick={() => zoomIn(scaleNumber)} className={classes.zoomInStyle} /></Grid>
                <Grid item xs={3} className={classes.closeModalIcon}>
                  <img src={closeButton} alt="Modal close button" onClick={handleInvoiceModalClose} />
                </Grid>

              </Grid>
              <div className={classes.invoiceModalBody}>
                <div style={{ overflow: "scroll", maxHeight: "920px", height: "100%" }}>
                  <Document
                    file={invoiceUrl ? {url:invoiceUrl} : invoiceData}
                    onLoadSuccess={onDocumentLoadSuccess}
                    noData="Please select a file"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
                    ))}
                  </Document>
                </div>
              </div></>
          }
          {!loading && !displayInvoice &&
            <div className={classes.noPdfModal}>
              <div style={{ overflow: "scroll", maxHeight: "890px", height: "100%" }}>
                <div className={classes.closeNoPdfModalIcon} onClick={() => setShowInvoiceModal(false)}>
                  <img src={closeButton} alt="Modal close button" />
                </div>
                <p className={classes.noInvoiceMsg}>{invoiceMessage}</p>
              </div>
            </div>
          }
        </>
      </Modal >
    );
  };

  const getHistoryTimelineItems = () => {
    return historyItems && historyItems.length ? historyItems.map((item: any, index: number) => (
      < TimelineItem key={`${item.statusCode} - ${index}`} className="timelineItem" >
        <TimelineSeparator>
          {index === 0 ?
            <TimelineDot className="timelineDot">
              <SvgIcon className="dotIcon active" component={item.statusCode === "DELIVERY_FAILED" ? errorTimelineDot : activeTimelineDot} viewBox="0 0 18 18" />
            </TimelineDot>
            :
            <TimelineDot className="timelineDot">
              <SvgIcon className="dotIcon" component={timelineDot} viewBox="0 0 8 8" />
            </TimelineDot>
          }
          {index !== historyItems.length - 1 && <TimelineConnector className="primaryConnector" />}
        </TimelineSeparator>
        <TimelineContent className="timelineContent">
          <Typography className={["heading", index === 0 ? "active" : "", (item.statusCode === "COLLECTION_FAILED") ? "error" : ""].join(" ")}><span className="status">{item.statusName}</span><span className="dateTime">{`(${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
          })})`}</span></Typography>
          {
            item.reason ? (
              <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                <Typography className="title">{item.reason ? item.reason.reasonDescription : ""}</Typography>
                <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                  hour12: false,
                  hour: 'numeric',
                  minute: 'numeric',
                })})`}</Typography>
              </Typography>) :
              consignment.reason && consignment.reason.deliveryStatus === item.statusCode ? (
                <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                  <Typography className="title">{consignment.reason ? consignment.reason.reasonDescription : ""}</Typography>
                  <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                  })})`}</Typography>
                </Typography>
              ) : ""
          }
          {/* {
            checkStatusReason(statusErrorReason, item.statusCode) && <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
              <Typography className="title">{statusErrorReason ? statusErrorReason.reasonDescription : ""}</Typography>
              <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
              })})`}</Typography>
            </Typography>
          } */}
        </TimelineContent>
      </TimelineItem >
    )) : (<Typography>No Items to display</Typography>)
  }
  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label"> CONSIGNMENT ID : </span>
                    <span className="content"> {consignment.consignmentCode} </span>
                  </Typography>
                </Grid>
                {
                  cncDataAfterReplanning && cncDataAfterReplanning.metadata ?
                    (
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', cncDataAfterReplanning && cncDataAfterReplanning.metadata && cncDataAfterReplanning.metadata.deliveryStatus)].join(" ")}>{cncDataAfterReplanning && cncDataAfterReplanning.metadata && cncDataAfterReplanning.metadata.deliveryStatus ? cncDataAfterReplanning.metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    ) : (
                      metadata && metadata.deliveryStatus &&
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', metadata && metadata.deliveryStatus)].join(" ")}>{metadata && metadata.deliveryStatus ? metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    )
                }
                {!(isLastMileViewer || isOnlyStdLastMileViewer) && (
                  <>
                    {checkEditable() && (
                      <Grid className='editIcon' item>
                        <SvgIcon className='icon' onClick={handleEditPopup} component={editIcon} />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            {
              (deliveryInformation && deliveryInformation.carrierTrackingId) || consignment.orderReference ? (
                <Grid container>
                  <Grid item>
                    {
                      deliveryInformation && deliveryInformation.carrierTrackingUrl ?
                        <div style={{ display: "flex" }}>
                          <Link className="titleContent link" onClick={() => handleInvoicePdf(consignment)}> View Invoice</Link>
                        </div>
                        :
                        <Typography className="titleContent">{`Track Consignment (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Typography>
                    }
                  </Grid>

                </Grid>
              ) : ""
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className="customerDetails" item>
          <Grid className="detailsHeader" container>
            <Grid className="detailsHeaderItem" item>
              <Typography>Customer Details</Typography>
            </Grid>
            <Grid className="detailsHeaderItem" item>
              <Typography className="name">{custInfo && custInfo.name}</Typography>
            </Grid>

            <Grid className="detailsHeaderItem" item>
              <Grid container className="headerItemContainer">
                <Grid className="contactMobile" item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                    <span className="contact mobile">{custInfo ? <a href={`tel: ${custInfo.phone}`}>{custInfo.phone || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Email" src={emailLogoIcon} />
                    <span className="contact email">{custInfo ? <a href={`mailto: ${custInfo.email}`}>{custInfo.email || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="collectionsHeader" style={{ marginTop: "8px", display: "flex" }}>
            <Grid className="detailsHeaderItem" xs={6} item >
              <Typography>Collection point code</Typography>
              <Typography className="name">{(pickUpContactInfo && pickUpContactInfo.companyName) || "-"}</Typography>
            </Grid>
            <Grid className="detailsHeaderItem" xs={6} item >
              <Typography>Collection point name</Typography>
              <Typography className="name">{(pickUpContactInfo && pickUpContactInfo.name) || "-"}</Typography>
            </Grid>
          </Grid>

        </Grid>

        <Grid item>
          <Grid className="detailsContent" container>
            <Grid className="contentRow" item>
              <Grid className="rowContainer" container style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Order No.</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{consignment.orderCode}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Collection Date</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{deliveryDate}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Collection Slot</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{deliverySlot}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentRow" item>
              <Grid className="rowContainer" container style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Fulfilment Store Code</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{posInformation && posInformation.store}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Fulfilment Store Name</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{posInformation && posInformation.storeName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label"></Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content"></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="consignmentHistory" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="consignment-history-content"
              id="consignment-history-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Consignment History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className="content">
                <Timeline className={classes.timeline} align="left">
                  {getHistoryTimelineItems()}
                </Timeline>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className="tabularSection items" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="consignment-history-content"
              id="consignment-history-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Items Summary ({items && items.length})</Typography>
            </AccordionSummary>
            <AccordionDetails className="itemDiv">
              <Typography component="div" className="content">
                <Grid className="itemsContainer" container direction="column">
                  <Grid className="item" item>
                    <Grid className="itemContainer header" container style={{ padding: "16px" }}>
                      <Grid className="itemColumn item1" item xs={5}>
                        <Typography>Product Name</Typography>
                      </Grid>
                      <Grid className="itemColumn item2" item xs={2}>
                        <Typography>Rejected Qty</Typography>
                      </Grid>
                      <Grid className="itemColumn item2" item xs={3}>
                        <Typography>Shipped/ Ordered Qty</Typography>
                      </Grid>
                      <Grid className="itemColumn item3" item xs={2}>
                        <Typography>Unit Price</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {getItems()}
                </Grid>
                <Grid className="paymentContainer" container direction="column">
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Payment Method</Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{metadata ? metadata.paymentType : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Delivery Charges</Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{deliveryInformation && metadata ? `${fixDecimals(2, deliveryInformation.deliveryCost ? deliveryInformation.deliveryCost.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Grand Total<span className="vat">(inclusive of VAT)</span></Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{metadata ? `${fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    metadata && (metadata.paymentType === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.COD_TEXT || metadata?.paymentType  && isCardOnDelivery(metadata.paymentType)) ?
                      <>
                        <Divider className="totalDivider"></Divider>
                        <Grid className="paymentItem" item>
                          <Grid container>
                            <Grid className="paymentItemColumn" item>
                              <Typography>Amount to be Collected</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                              <Typography>{metadata ? `${fixDecimals(2, metadata.amountToCollect ? metadata.amountToCollect.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid className="paymentItem" item>
                          <Grid container>
                            <Grid className="paymentItemColumn" item>
                              <Typography>Collected Amount</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                              <Typography>{metadata ? `${fixDecimals(2, metadata.collectedAmount ? metadata.collectedAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                      : null
                  }
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>

      </Grid>
    )
  }

  useEffect(() => {
    if (Object.keys(cncDataAfterReplanning).length !== 0) {
      let consignmentCode = cncDataAfterReplanning && cncDataAfterReplanning.consignmentCode;
      dispatch(
        fetchConsignmentHistoryAndProducts({
          params: consignmentCode
        })
      );
    }
  }, [cncDataAfterReplanning, dispatch])


  useEffect(() => {
    if (consignment != null) {
      handleStatusHistoryAndProducts(consignment);
    }
  }, [handleStatusHistoryAndProducts, consignment]);


  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={null}
        handleClose={handleDialogClose}
      ></CustomDialog>
      <CNCConsignmentEdit open={openConsignmentEditPopup} consignment={consignment} closePopup={closeConsignmentEditPopup}></CNCConsignmentEdit>

      {renderInvoiceModal()}
    </>
  )
}

export default ConsignmentDetails;