import React, { useCallback, useEffect, useRef, useState } from 'react';
import SelectBox from '../SelectBox';
import DateRangePickerComponent from '../DateRangeComponent';
import TimeRangePicker  from 'common/TimeRangePicker';
import { Button, Grid, Popover, SvgIcon } from '@material-ui/core';
import AppConstants from '../../constants';
import { useStyles } from './FilterForm.styles';
import AutoComplete from '../AutoComplete';
import { useClickHandler } from 'utils/helpers.utils';
import AutoCompleteWrapper from "../AutoCompleteWrapper"
import moment from "moment";
//import 
interface FilterProps {
  filter: any,
  resetAllFilter?: boolean;
  activeFilterValues?: any;
  reset?: boolean,
  defaultValues: any,
  initialValues?: any;
  updateFilter: (filter: any) => void,
  fetchDataCallback?: any
}

const Filter = (props: FilterProps) => {
 
  const { filter, defaultValues, resetAllFilter, reset, fetchDataCallback, updateFilter } = props;
  const [filterValue, setFilterValue] = useState(defaultValues[filter?.fieldName]?.value ? defaultValues[filter?.fieldName]?.value : defaultValues[filter?.fieldName]);
  const [pendingValue, setPendingValue] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const  [exception, setException]= useState(false);
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const filterPopoverRef = useRef<any>(null);
  const filterRef = useRef<any>(null);
  const propsStyle = {
    width: filter?.width,
  }
  
  const classes = useStyles(propsStyle);
  const openFilterPopup = Boolean(anchorEl);

  const handlePopoverContentClick = useCallback(
    (event: any) => {
      event.preventDefault();
    },
    [],
  );

  useEffect(() => {
    if (reset) {
      setFilterValue(defaultValues[filter?.fieldName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const onApplyFilter = useCallback(
    (value: any) => {
      setResetFilter(false);
      
    },
    [filter],
  );

  const handleFilterChange = useCallback(
    (value: any) => {
      setFilterValue(value);
      fetchDataCallback && fetchDataCallback(filter.fieldName, value)
    },
    [fetchDataCallback]
  );

  useEffect(() => {
    updateFilter({
      key: filter.fieldName,
      value: filterValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  const handleDateFilterChange = useCallback(
    (value: any) => {
      handleFilterChange(value);
      onApplyFilter(value);
    },
    [handleFilterChange, onApplyFilter],
  )

  const  handleTimeFilterChange =(e:any)=> {  
    const time = e.target.value  
    if (time.match(/\d+:\d+/)){
      setException(false)
      handleFilterChange(time)
    }
    else{
      setException(true)
    }
      
   //todo: get the hours and minutes from the input value and store them in separate variables
 }

  const updatePopoverRef = useCallback(
    (element: Element) => {
      filterPopoverRef.current = element;
      return filterRef.current ? filterRef.current : element;
    },
    [],
  );

  useEffect(() => {
    if (resetAllFilter && filter.type !== 'dateRange' && filter.type !== 'selectBox') {
         setFilterValue(defaultValues[filter?.fieldName]?.value);
    }
    if(resetAllFilter && filter.type === 'dateRange'){
      const value = {
        startDate: moment(defaultValues[filter?.fieldName]?.startDate).format(AppConstants.DATE_FORMAT),
        endDate: moment(defaultValues[filter?.fieldName]?.endDate).format(AppConstants.DATE_FORMAT),
      }
      handleDateFilterChange(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAllFilter]);

  useClickHandler(filterPopoverRef, handlePopoverContentClick);

  const getFilter = () => {
    switch (filter.type) {
      case 'selectBox':
        return (
          <SelectBox
            handleChange={handleFilterChange}
            value={filterValue}
            id={filter.key}
            multiple={filter.multiple}
            label={filter.label}
            items={filter.items || []}
          ></SelectBox>
        );
      case 'autocomplete':
        return (
          <AutoCompleteWrapper
           handleChange={handleFilterChange}
            value={pendingValue}
            reset={resetFilter}
            resetAll={resetAllFilter}
            id={filter.key}
            multiple={filter.multiple}
            items={filter.items || []}
            label={filter.label}
         />
          
        );
        case 'timeRange':
          return (
            <TimeRangePicker
            handleChange={handleTimeFilterChange}
            value={filterValue}
            label={filter.label}
            exception={exception}
            defaultValue={filter.default}
           />
            
          );
      case 'dateRange':
        return (
          <DateRangePickerComponent
            keyId={filter.key}
            label={filter.label}
            placeholder={filter.placeholderText}
            value={filterValue}
            isTextField={filter?.isTextField ? filter.isTextField : false}
            autoUpdateInput={filter.autoUpdateInput}
            autoApply={filter.autoApply}
            maxSpan={filter.maxSpan}
            showDropdowns={filter.showDropdowns}
            linkedCalendars={filter.linkedCalendars}
            locale={{ ...filter.locale, format: AppConstants.DATE_FORMAT }}
            updateDateRange={handleDateFilterChange}
          />
        )
      default: return null;
    }
  }
  return (
    <>
      {
        filter.type === 'dateRange' ?
          getFilter()
          :
          <>
            <Grid className={classes.root} item>
      { filter && filter.type ? getFilter() : null}
    </Grid>
          </>
      }
    </>
  );
}

export default Filter;