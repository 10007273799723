import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow-icon.svg";

import { isNonEmptyObject, isObject } from "../../../utils/helpers.utils";
import { ALL_ROUTES_TEXT_CONTANT } from "../utils/AllRouteConstant";

import RenderRouteAreaOrSubRouteAccordionSummary from "./RenderRouteAreaOrSubRouteAccordionSummary";
import RenderRouteSlotAccordianConfiguration from "./RenderRouteSlotAccordianConfiguration";

interface RenderPlannedRouteAreaAccordionInterface {
  classes: any;
  routeObj: any;
  routeName: string;
  routeDataName: string;
  routeDataNameIndex: number;
  viewMode?: boolean;
  //
  handleRoutAreaAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, subRouteAreaName?: string) => void;
  handleRouteSlotAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, subRouteAreaName?: string) => void;
  handleRouteOrderAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number, subRouteAreaName?: string) => void;
  //
  handleAreaCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string) => void;
  handleSlotCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string) => void;
  handleOrderCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  //
  orderActionPopUpViewChange?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  removeOrderFromRoute?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  addOrderToAnotherRoute?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  handleDeleteRoute?: (e: React.ChangeEvent<HTMLInputElement>, value: any) => void;
}

const RenderPlannedRouteAreaAccordion = (props: RenderPlannedRouteAreaAccordionInterface) => {
  const {
    classes,
    routeObj,
    routeName,
    routeDataName,
    routeDataNameIndex,
    viewMode = false,
    handleRoutAreaAccordianToggle,
    handleRouteSlotAccordianToggle,
    handleRouteOrderAccordianToggle,
    //
    handleSlotCheckboxToggle,
    handleAreaCheckboxToggle,
    handleOrderCheckboxToggle,
    //
    orderActionPopUpViewChange,
    removeOrderFromRoute,
    addOrderToAnotherRoute,
    handleDeleteRoute,
  } = props;

  const renderNoItemsToDisplay = () => <Typography>{ALL_ROUTES_TEXT_CONTANT.NO_ITEMS_TO_DISPLAY}</Typography>;

  const isRouteAreaContainsSlots = (obj: any) => obj && obj?.slotWiseOrders && isObject(obj.slotWiseOrders) && isNonEmptyObject(obj.slotWiseOrders);

  return (
    <Accordion
      key={`plannedRoute-${routeObj.routeId}-routeDataObj-${routeDataNameIndex}`}
      expanded={routeObj.routeDataObj[routeDataName].isOpened}
      TransitionProps={{ unmountOnExit: true, timeout: 0 }}
      className={classes.plannedRouteInnerAreaAccordianStyle}
    >
      {routeObj.subRouteCreated && (
        <AccordionSummary
          expandIcon={<SvgIcon component={downArrowIcon} onClick={(e: any) => handleRoutAreaAccordianToggle && handleRoutAreaAccordianToggle(e, routeName, routeDataName)} />}
          onClick={(e: any) => handleRoutAreaAccordianToggle && handleRoutAreaAccordianToggle(e, routeName, routeDataName)}
          className={classes.plannedRouteInnerAreaAccordianSummaryStyle}
        >
          <RenderRouteAreaOrSubRouteAccordionSummary
            classes={classes}
            routeObj={routeObj}
            routeName={routeName}
            routeDataName={routeDataName}
            handleAreaCheckboxToggle={handleAreaCheckboxToggle}
            handleDeleteRoute={handleDeleteRoute}
            viewMode={viewMode}
          />
        </AccordionSummary>
      )}
      <AccordionDetails className={classes.plannedRouteInnerAreaAccordianDetailsStyle}>
        {routeObj.routeDataObj[routeDataName].isOpened && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
              {isRouteAreaContainsSlots(routeObj.routeDataObj[routeDataName])
                ? Object.keys(routeObj.routeDataObj[routeDataName].slotWiseOrders).map((slotName: string, slotNameIndex: number) => (
                    <RenderRouteSlotAccordianConfiguration
                      classes={classes}
                      routeObj={routeObj}
                      routeName={routeName}
                      routeDataName={routeDataName}
                      routeDataNameIndex={routeDataNameIndex}
                      slotName={slotName}
                      slotNameIndex={slotNameIndex}
                      handleRouteSlotAccordianToggle={handleRouteSlotAccordianToggle}
                      handleRouteOrderAccordianToggle={handleRouteOrderAccordianToggle}
                      handleSlotCheckboxToggle={handleSlotCheckboxToggle}
                      handleOrderCheckboxToggle={handleOrderCheckboxToggle}
                      orderActionPopUpViewChange={orderActionPopUpViewChange}
                      removeOrderFromRoute={removeOrderFromRoute}
                      addOrderToAnotherRoute={addOrderToAnotherRoute}
                      viewMode={viewMode}
                    />
                  ))
                : renderNoItemsToDisplay()}
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(RenderPlannedRouteAreaAccordion);
