import { currentDate, startOfDay, endOfDay } from "../utils/dateUtils";
import AppConstants from "./AppConstants";
import { API_RESPONSE_STATUS } from "./CommonConstants";

export const DELVE_DASHBOARD_CONST = {
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
  PARTIALLY_VERIFIED: "PARTIALLY_VERIFIED",
};

export interface DATE_PICKER_VALUE_INTERFACE {
  startDate: any;
  endDate: any;
}
export const DATE_PICKER_VALUE: DATE_PICKER_VALUE_INTERFACE = {
  startDate: currentDate(AppConstants.DATE_FORMAT),
  endDate: currentDate(AppConstants.DATE_FORMAT),
};

export interface TIME_PICKER_VALUE_INTERFACE {
  startTime: any;
  endTime: any;
}
export const TIME_PICKER_VALUE: TIME_PICKER_VALUE_INTERFACE = {
  startTime: startOfDay(currentDate(AppConstants.DATE_FORMAT), AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
  endTime: endOfDay(currentDate(AppConstants.DATE_FORMAT), AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
};

export interface DELVE_ORDER_DETAILS_INTERFACE {
  count: any;
  cost: any;
}
export const DELVE_ORDER_DETAILS: DELVE_ORDER_DETAILS_INTERFACE = {
  count: 0,
  cost: 0,
};

export interface DELVE_ORDER_COUNT_OBJ_INTERFACE {
  totalOrders: DELVE_ORDER_DETAILS_INTERFACE;
  ordersVerified: DELVE_ORDER_DETAILS_INTERFACE;
  ordersPartialVerified: DELVE_ORDER_DETAILS_INTERFACE;
  ordersUnverified: DELVE_ORDER_DETAILS_INTERFACE;
  cratesVerified: DELVE_ORDER_DETAILS_INTERFACE;
  cratesUnverified: DELVE_ORDER_DETAILS_INTERFACE;
  itemsVerified: DELVE_ORDER_DETAILS_INTERFACE;
  itemsUnverified: DELVE_ORDER_DETAILS_INTERFACE;
  issuesFound: DELVE_ORDER_DETAILS_INTERFACE;
}
export const DELVE_ORDER_COUNT_OBJ: DELVE_ORDER_COUNT_OBJ_INTERFACE = {
  totalOrders: DELVE_ORDER_DETAILS,
  ordersVerified: DELVE_ORDER_DETAILS,
  ordersPartialVerified: DELVE_ORDER_DETAILS,
  ordersUnverified: DELVE_ORDER_DETAILS,
  cratesVerified: DELVE_ORDER_DETAILS,
  cratesUnverified: DELVE_ORDER_DETAILS,
  itemsVerified: DELVE_ORDER_DETAILS,
  itemsUnverified: DELVE_ORDER_DETAILS,
  issuesFound: DELVE_ORDER_DETAILS,
};

export interface DELVE_DASHBOARD_DATA_OBJ_INTERFCAE {
  orderCount: DELVE_ORDER_COUNT_OBJ_INTERFACE;
  currency: any;
  totalIssueSummaryCount: any;
  itemsCountObj: any;
  hubSeriesObj: any;
  hubFooterData: any;
  hubLevelVerificationData: any;
}
export const DELVE_DASHBOARD_DATA_OBJ = {
  orderCount: DELVE_ORDER_COUNT_OBJ,
  currency: "",
  totalIssueSummaryCount: 0,
  itemsCountObj: {} as any,
  hubSeriesObj: [] as any,
  hubFooterData: [] as any,
  hubLevelVerificationData: {} as any,
};

export const TAB_VALUES_CONSTANT = {
  DELIVERIES: { value: "DELIVERY", name: "Deliveries" },
  RETURNS: { value: "RETURNS", name: "Returns" },
};

export interface DELVE_RETURN_COUNT_OBJ_INTERFACE {
  totalConsignments: DELVE_ORDER_DETAILS_INTERFACE;
  verifiedConsignments: DELVE_ORDER_DETAILS_INTERFACE;
  unverifiedConsignments: DELVE_ORDER_DETAILS_INTERFACE;
  itemsVerified: DELVE_ORDER_DETAILS_INTERFACE;
  itemsUnverified: DELVE_ORDER_DETAILS_INTERFACE;
  issuesFound: DELVE_ORDER_DETAILS_INTERFACE;
}
export const DELVE_RETURN_COUNT_OBJ: DELVE_RETURN_COUNT_OBJ_INTERFACE = {
  totalConsignments: DELVE_ORDER_DETAILS,
  verifiedConsignments: DELVE_ORDER_DETAILS,
  unverifiedConsignments: DELVE_ORDER_DETAILS,
  itemsVerified: DELVE_ORDER_DETAILS,
  itemsUnverified: DELVE_ORDER_DETAILS,
  issuesFound: DELVE_ORDER_DETAILS,
};

export interface DELVE_RETURN_DASHBOARD_DATA_OBJ_INTERFCAE {
  orderCount: DELVE_RETURN_COUNT_OBJ_INTERFACE;
  currency: any;
  totalIssueSummaryCount: any;
  itemsCountObj: any;
  hubSeriesObj: any;
  hubFooterData: any;
  hubLevelVerificationData: any;
}
export const DELVE_RETURN_DASHBOARD_DATA_OBJ = {
  orderCount: DELVE_RETURN_COUNT_OBJ,
  currency: "",
  totalIssueSummaryCount: 0,
  itemsCountObj: {} as any,
  hubSeriesObj: [] as any,
  hubFooterData: [] as any,
  hubLevelVerificationData: {} as any,
};
export interface DELVE_DASHBOARD_STATE_INTERFACE {
  loading: boolean;
  countryIsoCode: any;
  hubs: any;
  dateSlot: DATE_PICKER_VALUE_INTERFACE;
  istimeSlotEnabled: boolean;
  timeSlot: TIME_PICKER_VALUE_INTERFACE;
  delveDashboardRecord: DELVE_DASHBOARD_DATA_OBJ_INTERFCAE;
  returnDashboardRecord: DELVE_RETURN_DASHBOARD_DATA_OBJ_INTERFCAE;
  tabValue: any;
}

export const DELVE_DASHBOARD_STATE: DELVE_DASHBOARD_STATE_INTERFACE = {
  loading: false,
  countryIsoCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE) || AppConstants.COUNTRY_OBJ.value,
  hubs: [AppConstants.SELECT_ALL.value],
  dateSlot: DATE_PICKER_VALUE,
  istimeSlotEnabled: false,
  timeSlot: TIME_PICKER_VALUE,
  delveDashboardRecord: DELVE_DASHBOARD_DATA_OBJ,
  returnDashboardRecord: DELVE_RETURN_DASHBOARD_DATA_OBJ,
  tabValue: TAB_VALUES_CONSTANT.DELIVERIES.value,
};

export interface commonFetchInitialStateInterface {
  fetchStatus: any;
  error: any;
  data: any;
  loading: boolean;
}

export const commonFetchInitialState: commonFetchInitialStateInterface = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: {},
  loading: false,
};

export type DelveDashboardSliceState = {
  delveDashboardRecod: commonFetchInitialStateInterface;
  delveConsignmentsIssueName: commonFetchInitialStateInterface;
};

export const NO_ISSUE_NAME_FOUND = "No issue name found";
export const ERROR_WHILE_FETCHING_CONSIGNMENT_LIST_BASED_ON_ISSUE = "Error while fetching consignment list based on issue name";
export const NO_CONSIGNMENT_LIST_FOUND = "No Consignment list found based on issue name";