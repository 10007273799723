import React, { forwardRef, ReactElement, Ref, useCallback, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../config/redux/reducers";
import { useStyles } from './ExpUpdatePartialRejection.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography } from '@material-ui/core';
import AppConstants from '../constants';
import { fixDecimals } from 'utils/helpers.utils';
import SelectBox from '../common/SelectBox';
import { fetchDeliveryReasons } from './redux/expressSlice';
import { rejectionDataTransform } from '../mocks/express/response.transforms';
import { createPayloadForReasons } from '../mocks/express/response.transforms';
import { bundleRejectionDataTransform } from './../mocks/routePlanning/response.transforms';
import {displayUnitGrams }  from "../utils/helpers.utils"
import BundleProducts from '../BundleProducts/index'

interface ExpUpdatePartialRejectionProps {
    open: boolean;
    consignment: any;
    items: any;
    closePopup: () => void;
    bundleProduct?: any;
    handleDialogAction?: (params: any) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ExpUpdatePartialRejection = (props: ExpUpdatePartialRejectionProps) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { rejectionReasonList, revisedAmountAfterCalculation } = useSelector(
        (state: AppState) => state.expressDashboard
    );
    const { countryCode } = useSelector(
        (state: AppState) => state.common
    );
    const { open, consignment, items, closePopup, handleDialogAction, bundleProduct } = props;
    const generalReason = AppConstants.SELECT_NONE.value;
    const [rejections, setRejections] = useState([] as any);
    const [products, setProducts] = useState([] as any);
    const [bundleProducts, setBundleProducts] = useState([] as any);
    let metadata: any = {};

    if (consignment) {
        metadata = consignment.metadata;
    }

    const dialogPaperProps = {
        classes: {
            root: classes.dialogPaperPropsRoot
        },
        square: true
    }

    const handleDialogClose = useCallback(
        () => {
            closePopup();
        },
        [closePopup],
    );

    const validateForm = ((products: any, bundleItems:any) => {
        let newProducts = [...products];
        let newBundleItems = [...bundleItems];
        let rejectionReason = false;
        newProducts.forEach((element: any) => {
            if ((element?.rejectedQty !== "0" && element?.showReason === true && element?.itemDeliveryReason && element?.itemDeliveryReason?.length === 0) ||
                (element?.rejectedQty !== "0" && element?.itemDeliveryReason && element?.itemDeliveryReason?.length > 0 && element?.itemDeliveryReason[0]?.name === '')) {
                element.error = "Please select reason";
                rejectionReason = true;
            }
        })
        newBundleItems.forEach((element: any) => {
            if ((element.rejectedQty !== "0" && element.showReason === true && element.itemDeliveryReason && element.itemDeliveryReason.length === 0) ||
                (element.rejectedQty !== "0" && element.itemDeliveryReason && element.itemDeliveryReason.length > 0 && element.itemDeliveryReason[0].name === '')) {
                element.error = "Please select reason";
                rejectionReason = true;
            }
        })
        setBundleProducts([...newBundleItems]);
        setProducts([...newProducts]);
        return rejectionReason;
    });

  

    const dialogActionHandler = useCallback(
        (type: string, values?: any,bundleItems?:any, consignment?: any) => {
            if (type === 'save') {
                if (!validateForm(values, bundleItems)) {
                    handleDialogAction && handleDialogAction({
                        type: type,
                        values: values,
                        bundleItems: bundleItems,
                        consignment: consignment
                    });
                }
            } else {
                closePopup();
            }
        },
        [closePopup, handleDialogAction],
    );

    const handleCommonReasonChange = ((event: any) => {
        let commonReason: any = [];
        rejectionReasonList.map((reason: any) => {
            let productsCopy: any = [...products];
            let bundleProductCopy: any =[...bundleProducts]
            if (reason.value === event) {
                commonReason.push(reason);
                productsCopy.forEach((prod: any) => {
                    if (prod.showReason) {
                        prod.itemDeliveryReason = commonReason
                    }
                });
                bundleProductCopy?.forEach((prod: any) => {
                    if (prod.showReason) {
                        prod.itemDeliveryReason = commonReason
                    }
                });
            }
            setBundleProducts([...bundleProductCopy])
            setProducts([...productsCopy]);
            return productsCopy;
        })
        setRejections(commonReason);
    });

    const handleBundleReasonChange = ((event: any, fieldName: any) => {
        let itemReason: any = [];
        rejectionReasonList.map((reason: any) => {
            let bundleProductCopy: any = [...bundleProducts];
            if (reason.value === event) {
                itemReason.push(reason);
                bundleProductCopy.forEach((prod: any) => {
                    if (prod.id === fieldName) {
                        prod.itemDeliveryReason = itemReason;
                        prod.error = ""
                    }
                });
            }
            setBundleProducts([...bundleProductCopy]);
        })
    });

    const handleItemReasonChange = ((event: any, fieldName: any) => {
        let itemReason: any = [];
        rejectionReasonList.map((reason: any) => {
            let productsCopyNew: any = [...products];
            if (reason.value === event) {
                itemReason.push(reason);
                productsCopyNew.forEach((prod: any) => {
                    if (prod.id === fieldName) {
                        prod.itemDeliveryReason = itemReason;
                        prod.error = ""
                    }
                });
            }
            setProducts([...productsCopyNew]);
            return productsCopyNew;
        })
        setRejections(itemReason);
    });

    const handleUpdatedRejectedQty = ((event: any, fieldName: any, isBundleProduct?:boolean) => {
        let updateProducts: any;
        if(isBundleProduct){
            updateProducts  = [...bundleProducts]; 
        }
        else{
            updateProducts  = [...products]; 
        }
        updateProducts.forEach((prod: any) => {
            if (prod.id === fieldName) {
                prod.rejectedQty = event;
                //conditionally rendering the rejection reason if rejected qty > 0
                if (event === "0") {
                    prod.showReason = false;
                    prod.itemDeliveryReason.pop();
                } else {
                    prod.showReason = true;
                }
            }
            if(isBundleProduct){
                setBundleProducts([...updateProducts])
            }
            else{
                setProducts([...updateProducts]);
            }
        });
    });

    const checkIfDisabled = () => {
        let counter = 0;
        let isDisabled = false;
        if (products && products.length) {
            products.forEach((item: any) => {
                if (item.rejectedQty === 0) {
                    counter = counter + 1;
                }
            })
        }
        if (bundleProducts && bundleProducts.length) {
            bundleProducts.forEach((item: any) => {
                if (item.rejectedQty === 0) {
                    counter = counter + 1;
                }
            })
        }
        if (counter === products?.length + bundleProducts?.length) {
            isDisabled = true;
        } else {
            isDisabled = false;
        }
        return isDisabled;
    }

    const getBundleProduct = () => {
        return bundleProducts.map((item: any, index: number) => (
          <BundleProducts
            bundle={item}
            key={index}
            editMode={true}
            rejectionReasonList={rejectionReasonList}
            handleUpdatedRejectedQty={handleUpdatedRejectedQty}
            bundleCurrency={metadata?.currency}
            handleBundleReasonChange={handleBundleReasonChange}
          />
        ));
      };

    const getItems = () => {
        return products.map((item: any, index: number) => (
            <Grid key={`${item.name.en} - ${index}`} className="item" item>
                <Grid className="itemContainer" container>
                    <Grid className="itemColumn item1" item xs={4}>
                        <Typography className="itemsContent">{item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].name && item.substitutedProducts[0].name.en ? item.substitutedProducts[0].name.en.toLowerCase() : item.name && item.name.en ? item.name.en.toLowerCase() : ""}</Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                        <Typography className="itemsContent">
                            <SelectBox
                                className="select"
                                value={item.selectedRejectedQty[0].value}
                                id="rejectedQty"
                                inputProps={{
                                    name: "rejectedQty",
                                    id: "rejectedQty"
                                }}
                                items={item.rejectedQtyList || []}
                                handleChange={(e) => handleUpdatedRejectedQty(e, item.id)}
                            ></SelectBox>
                        </Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                        <Typography className="itemsContent">
                        <span className="green"> {` ${item.shippedQty || 0} ${item.uom}`} </span> 
                        </Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                        <Typography className="itemsContent">
                            {`${item.totalQty || ""}`}</Typography>
                    </Grid>
                    <Grid className="itemColumn item3" item xs={2}>
                        <Typography className="itemsContent">{`${fixDecimals(2, item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].unitPrice ?  item.substitutedProducts[0]?.unitPrice : item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
                    </Grid>
                    {item.showReason ?
                        (
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={{ fontSize: "10px", color: "#2D2D2D", fontWeight: 600 }}>Rejection Reason : </Typography>
                                    </Grid>
                                    <Grid item style={{ paddingLeft: "8px" }}>
                                        <SelectBox
                                            variant="standard"
                                            disableUnderline
                                            value={item.itemDeliveryReason && item.itemDeliveryReason[0] && item.itemDeliveryReason[0].value}
                                            id="reason"
                                            inputProps={{
                                                name: "reason",
                                                id: "reason"
                                            }}
                                            menuPaperProps={classes.menuPropsPaper}
                                            menuPropsList={classes.menuPropsList}
                                            classes={{
                                                root: classes.selectRoot,
                                                icon: classes.icon
                                            }}
                                            items={rejectionReasonList || []}
                                            handleChange={(e) => handleItemReasonChange(e, item.id)}
                                        ></SelectBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                    {item.error ? (
                        <Grid>
                            <Typography style={{ color: "red", fontSize: "10px" }}>Please select reason</Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        )) 
    }

    const getTitleContent = () => {
        return (
            <Grid className={classes.titleContainer} container>
                <Grid item>
                    <Grid className="headingContainer" container>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography className="titleHeading">
                                        <span className="label">Update Rejected Items</span>
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container>
                                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                                            <Typography style={{ fontSize: "12px", color: "#0E5AA7", fontWeight: 600 }}>Common Rejection Reason : </Typography>
                                        </Grid>
                                        <Grid item style={{ paddingLeft: "8px" }}>
                                            <SelectBox
                                                fullWidth
                                                showSelectNone={false}
                                                variant="standard"
                                                value={generalReason}
                                                id="reason"
                                                menuPaperProps={classes.menuPropsPaper}
                                                menuPropsList={classes.menuPropsList}
                                                classes={{
                                                    root: classes.generalReasonRoot,
                                                    icon: classes.generalReasonIcon
                                                }}
                                                items={rejectionReasonList || []}
                                                disableUnderline
                                                handleChange={(e) => handleCommonReasonChange(e)}
                                            ></SelectBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }

    const getDetailsContent = () => {
        return (
            <Grid className={classes.contentContainer} container>
                <Grid container className="content">
                    <Grid container className="itemContainer">
                        <Grid className="itemColumn item1" item xs={4}>
                            <Typography className={classes.title}>Product Name</Typography>
                        </Grid>
                        <Grid className="itemColumn item2" item xs={2} style={{ display: "flex", flexDirection: "column" }}>
                            <Typography className={classes.title}>Rejected</Typography>
                            <Typography className={classes.title} style={{ paddingTop: "4px" }}>Qty</Typography>
                        </Grid>
                        <Grid className="itemColumn item2" item xs={2}>
                            <Typography className={classes.title}>Shipped</Typography>
                        </Grid>
                        <Grid className="itemColumn item2" item xs={2}>
                            <Typography className={classes.title} >Ordered Qty</Typography>
                        </Grid>
                        <Grid className="itemColumn item3" item xs={2}>
                            <Typography className={classes.title}>Unit Price</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {products?.length > 0 && getItems()}
                {bundleProduct?.length > 0 && getBundleProduct()}
                {products?.length === 0 && bundleProduct?.length === 0 && (
                    <Grid style={{ padding: "12px" }}>
                      <Typography style={{ fontSize: "14px", fontWeight: 500 }}>
                         No Items to display
                      </Typography>
                     </Grid>
                )}
                <Grid className="paymentContainer" container direction="column">
                    <Grid className="paymentItem" item>
                        <Grid container>
                            <Grid className="paymentItemColumn" item>
                                <Typography>Payment Method</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                                <Typography>{metadata ? metadata.paymentType : ""}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="paymentItem" item>
                        <Grid container>
                            <Grid className="paymentItemColumn" item>
                                <Typography>Grand Total<span className="vat">(Inclusive of VAT)</span></Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                                <Typography>{metadata ? `${fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {metadata && metadata.paymentType === "COD" ? (
                        <Grid className="paymentItem" item>
                            <Grid container>
                                <Grid item>
                                    <Typography>Revised Total</Typography>
                                </Grid>
                                {(revisedAmountAfterCalculation !== "") ?
                                    <Grid className="paymentItemColumn right" item>
                                        <Typography>{`${revisedAmountAfterCalculation} ${(metadata && metadata.currency) || ""}`}</Typography>
                                    </Grid>
                                    :
                                    <Grid className="paymentItemColumn right" item>
                                        <Typography>{`${(metadata.collectedAmount ? parseFloat(metadata.collectedAmount.toString()).toFixed(2) : "0.00")} ${(metadata && metadata.currency) || ""}`}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    ) : null}

                </Grid>
            </Grid>
        )
    }

    const getDialogActions = () => {
        return (
            <Grid className={classes.buttons} container>
                <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
                <Button className="userBtn primary" variant="contained" type="submit" disabled={checkIfDisabled()} onClick={() => { dialogActionHandler('save', products, bundleProducts, consignment) }}>{AppConstants.BUTTONS.SAVE}</Button>
            </Grid>
        );
    };

    const fetchAllRejectionReasons = useCallback(
        (consignment: any) => {
            if (Object.keys(consignment).length) {
                const payload = createPayloadForReasons(countryCode, AppConstants.EXPRESS_CONSTANTS.DELIVERY_TYPE.LINE_ITEM_REJECTION);
                dispatch(
                    fetchDeliveryReasons({
                        params: payload
                    })
                );
            }
        },
        [dispatch, countryCode]
    );

    useEffect(() => {
        if (open && consignment != null) {
            fetchAllRejectionReasons(countryCode);
        }
    }, [open, consignment, countryCode, fetchAllRejectionReasons]);

    useEffect(() => {
        setBundleProducts(bundleRejectionDataTransform(bundleProduct) as any)
        setProducts(rejectionDataTransform(items) as any);
    }, [items, bundleProduct])

    return (
        <CustomDialog
            open={open}
            TransitionComponent={Transition}
            PaperProps={dialogPaperProps}
            title={getTitleContent()}
            actions={getDialogActions()}
            content={getDetailsContent()}
            handleClose={handleDialogClose}
        ></CustomDialog>
    )
}

export default ExpUpdatePartialRejection;