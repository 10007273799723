import React from "react";

import { Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface DelveOrderDetailsItemReportedTitleContentInterface {
  classes: any;
  crateId: any;
  handleClose: any;
}

const DelveOrderDetailsItemReportedTitleContent = (props: DelveOrderDetailsItemReportedTitleContentInterface) => {
  const { classes, crateId, handleClose } = props;

  return (
    <Grid container spacing={0} className={classes.detailsItemContent}>
      <Grid item xs={6}>
        <Typography className={classes.detailsItemContentValueStyle}>{`Issues reported - ${crateId || "Crate"}`}</Typography>
      </Grid>
      <Grid item xs={6}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(DelveOrderDetailsItemReportedTitleContent);
