import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Collapse, Grid, SvgIcon, Typography } from "@material-ui/core";

import mobileLogoIcon from "../../../assets/icons/mobileLogo.svg";
import emailLogoIcon from "../../../assets/icons/emailLogo.svg";
import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow.svg";

import MapContainer from "../../../common/GoogleMapContainer";

import { COLORS } from "../../../config/theme/baseTheme";
import { getAddressInfo, getLatLong } from "../../../mocks/delve/response.transforms";

interface interfaceDelveOrderDetailsHubAddressContent {
  classes: any;
  hubName: any;
  posInfo: any;
  toggleSellerMap: any;
  custGeoLocation: any;
  sellerGeoLocation: any;
  updateToggleSellerMap: any;
}

const DelveOrderDetailsHubAddressContent = (props: interfaceDelveOrderDetailsHubAddressContent) => {
  const { classes, hubName, posInfo, toggleSellerMap, custGeoLocation, sellerGeoLocation, updateToggleSellerMap } = props;

  return (
    <Grid className="customerDetails" item style={{ paddingBottom: "8px" }}>
      <Accordion>
        <AccordionSummary expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />} aria-controls="hub-address-header" id="hub-address-header" className={classes.accordionSummary}>
          <Typography className="header">Hub Address</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container className="contentHubAddress">
            <Grid item className="main">
              <Typography className="title">{hubName}</Typography>
              <Typography className="content">{getAddressInfo(posInfo && posInfo.address ? posInfo.address : "")}</Typography>
            </Grid>
            <Grid item className="subSection">
              <Typography className="subSectionTitle">Lat/Long:</Typography>
              <Typography className="subSectionContent">{getLatLong(posInfo && posInfo.address ? posInfo.address : "")}</Typography>
            </Grid>
            <Grid className="contentRow geocodedAddress" item style={{ paddingTop: "16px" }}>
              <Grid container direction="column">
                <Grid className="addressColItem" item>
                  <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => updateToggleSellerMap(!toggleSellerMap)}>
                    <span className="address">View Geocoded Address</span>
                    <SvgIcon className={["addressLogo", toggleSellerMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                  </Grid>
                </Grid>
                <Collapse in={toggleSellerMap}>
                  <Grid className="addressMapContainer" item>
                    {custGeoLocation ? <MapContainer center={sellerGeoLocation.location} zoom={12} markers={[sellerGeoLocation]}></MapContainer> : ""}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <Grid className="detailsHeaderItem" item>
              <Grid container className="headerItemContainer">
                <Grid className="contactMobile" item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                    <span className="contact mobile">{posInfo ? <a href={`tel: ${posInfo.phone}`}>{posInfo.phone || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Email" src={emailLogoIcon} />
                    <span className="contact email">{posInfo ? <a href={`mailto: ${posInfo.email}`}>{posInfo.email || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default React.memo(DelveOrderDetailsHubAddressContent);
