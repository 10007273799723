import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Collapse, Grid, SvgIcon, Typography } from "@material-ui/core";

import mobileLogoIcon from "../../../assets/icons/mobileLogo.svg";
import emailLogoIcon from "../../../assets/icons/emailLogo.svg";
import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow.svg";

import MapContainer from "../../../common/GoogleMapContainer";

import { getAddressInfo, getLatLong } from "../../../mocks/delve/response.transforms";
import { COLORS } from "../../../config/theme/baseTheme";

interface interfaceDelveOrderDetailsCustomerDetailsContent {
  classes: any;
  custInfo: any;
  deliveryInformation: any;
  toggleCustMap: any;
  custGeoLocation: any;
  updateToggleCustMap: any;
}

const DelveOrderDetailsCustomerDetailsContent = (props: interfaceDelveOrderDetailsCustomerDetailsContent) => {
  const { classes, custInfo, deliveryInformation, toggleCustMap, custGeoLocation, updateToggleCustMap } = props;

  return (
    <Grid className="customerDetails" item style={{ paddingBottom: "8px" }}>
      <Accordion>
        <AccordionSummary expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />} aria-controls="hub-address-header" id="hub-address-header" className={classes.accordionSummary}>
          <Typography className="header">Customer Details</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container className="contentHubAddress">
            <Grid className="detailsHeaderItem" item>
              <Typography className="name">{custInfo && custInfo.name}</Typography>
              <Typography style={{ paddingTop: "12px" }}>{getAddressInfo(custInfo && custInfo.address ? custInfo.address : "")} </Typography>
              <div className="subSection">
                <Typography className="subSectionTitle">Lat/Long :</Typography>
                <Typography className="subSectionContent">{getLatLong(custInfo && custInfo.address ? custInfo.address : "")}</Typography>
              </div>
              <div className="subSection">
                <Typography className="subSectionTitle">Landmark :</Typography>
                <Typography className="subSectionContent">{custInfo && custInfo.address ? custInfo.address.landmark : ""}</Typography>
              </div>
              <div className="subSection">
                <Typography className="subSectionTitle">Delivery Instructions :</Typography>
                <Typography className="subSectionContent">{deliveryInformation ? deliveryInformation.deliveryInstructions : ""}</Typography>
              </div>
            </Grid>
            <Grid className="contentRow geocodedAddress" item>
              <Grid container direction="column">
                <Grid className="addressColItem" item>
                  <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => updateToggleCustMap(!toggleCustMap)}>
                    <span className="address">View Geocoded Address</span>
                    <SvgIcon className={["addressLogo", toggleCustMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                  </Grid>
                </Grid>
                <Collapse in={toggleCustMap}>
                  <Grid className="addressMapContainer" item>
                    {custGeoLocation ? <MapContainer center={custGeoLocation.location} zoom={12} markers={[custGeoLocation]}></MapContainer> : ""}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <Grid className="detailsHeaderItem" item>
              <Grid container className="headerItemContainer">
                <Grid className="contactMobile" item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                    <span className="contact mobile">{custInfo ? <a href={`tel: ${custInfo.phone}`}>{custInfo.phone || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Email" src={emailLogoIcon} />
                    <span className="contact email">{custInfo ? <a href={`mailto: ${custInfo.email}`}>{custInfo.email || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default React.memo(DelveOrderDetailsCustomerDetailsContent);
