import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { customFooterUseStyles } from './CustomFooter.styles';
import { ComponentProps } from './DataTable';

interface CustomFooterComponentProps extends ComponentProps { }

const CustomFooterComponent = (props: CustomFooterComponentProps) => {
  const classes = customFooterUseStyles();
  const { rows, options } = props;

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.countSection} item>
        <Grid className="countContainer" container>
          <Grid className="countItem" item>
            <Typography><span className="current">{rows.length}</span><span className="separator">/</span><span className="count">{options.rowCount}</span></Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomFooterComponent;