import React from "react";

import { Card, Grid, Typography } from "@material-ui/core";
import { LinearProgressWithLabelPro, ShowMessageInsideCard } from "../Utils/DelveDashboardViewUtils";
import { checkIfInputIsValidObjectWithKeys, isUndefined } from "../../../utils/helpers.utils";
import RedirectIcon from "../../../assets/icons/RedirectIcon.svg";

interface DelveDashboardIssueSummaryDetailsInterface {
  classes: any;
  recordObj: any;
  fetchConsignmentsListBasedOnIssueName: any;
}

const DelveDashboardIssueSummaryDetails = (props: DelveDashboardIssueSummaryDetailsInterface) => {
  const { classes, recordObj, fetchConsignmentsListBasedOnIssueName } = props;

  const isDashboardRecordContainsItemCountObj = () => {
    return !isUndefined(recordObj) && !isUndefined(recordObj.itemsCountObj) && checkIfInputIsValidObjectWithKeys(recordObj.itemsCountObj);
  };

  return (
    <Grid container spacing={0} className={classes.IssueSummaryMainGridContainer}>
      <Grid item xs={12} className={classes.issueGridItemStyle}>
        <Typography className={classes.issueGridItemSpanStyle}>{`Issue Summary `}</Typography>
      </Grid>

      <Card className={classes.issueSummaryGridCard}>
        {isDashboardRecordContainsItemCountObj() ? (
          <Grid container spacing={0} className={classes.orderCountDetailsMainContainer}>
            <Grid item xs={12}>
              <Grid container spacing={0} className={classes.orderCountDetailsMainContainer}>
                <Grid item xs={5} className={classes.issueSummaryHeadingGridItem}>
                  <Typography className={classes.issueSummaryHeadingSpanStyle}>{`Issue`}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.issueSummaryHeadingGridItem}>
                  <Typography className={classes.issueSummaryHeadingSpanStyle}>{`Qty`}</Typography>
                </Grid>
                <Grid item xs={5} className={classes.issueSummaryHeadingGridItem}>
                  <Typography className={classes.issueSummaryHeadingSpanStyle}>{`Percentage`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {Object.keys(recordObj.itemsCountObj).map((issue_item: any, item_index: any) => (
              <Grid item key={`${item_index}-item_index`} xs={12}>
                <Grid container spacing={0} className={classes.orderCountDetailsMainContainer}>
                  <Grid
                    item
                    xs={5}
                    className={`${classes.issueSummaryHeadingGridItem} ${classes.issueNameSpanIconDiv}`}
                    onClick={() => fetchConsignmentsListBasedOnIssueName(recordObj.itemsCountObj[issue_item]?.value || issue_item || "Issue")}
                  >
                    <Typography className={classes.issueSummaryNameValueSpanStyle}>{`${recordObj?.itemsCountObj[issue_item]?.name || issue_item || "Issue"}`}</Typography>
                    <img src={RedirectIcon} alt="RedirectIcon" className={classes.issueRedirectIconSvgStyle} />
                  </Grid>
                  <Grid item xs={2} className={classes.issueSummaryHeadingGridItem}>
                    <Typography className={classes.issueSummaryValueSpanStyle}>{`${recordObj.itemsCountObj[issue_item].cost || 0}`}</Typography>
                  </Grid>
                  <Grid item xs={5} className={classes.issueSummaryHeadingGridItem}>
                    <LinearProgressWithLabelPro classes={classes} currentValue={recordObj.itemsCountObj[issue_item]?.cost || 0} sumValue={recordObj.totalIssueSummaryCount || 0} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <ShowMessageInsideCard classes={classes} message={`No Data Available`} />
        )}
      </Card>
    </Grid>
  );
};

export default React.memo(DelveDashboardIssueSummaryDetails);
