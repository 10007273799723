import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { Grid, Link, Tab, Tabs } from "@material-ui/core";

import Loader from "../../common/Loader";
import CustomSnackbar from "../../common/CustomSnackbar";
import DelveDashboardSearchHeader from "./Common/DelveDashboardSearchHeader";
import DelveDashboardOrderStatusDetails from "./Common/DelveDashboardOrderStatusDetails";
import DelveDashboardIssueMappingDetails from "./Common/DelveDashboardIssueMappingDetails";
import DelveDashboardIssueSummaryDetails from "./Common/DelveDashboardIssueSummaryDetails";
import DelveDashboardHubVerificationDetails from "./Common/DelveDashboardHubVerificationDetails";

import { useStyles } from "./Utils/DelveDashboardStyles";

import AppConstants from "../../constants/AppConstants";
import { AppState } from "../../config/redux/reducers";
import {
  DELVE_DASHBOARD_DATA_OBJ,
  DELVE_DASHBOARD_STATE,
  DELVE_DASHBOARD_STATE_INTERFACE,
  DELVE_RETURN_DASHBOARD_DATA_OBJ,
  ERROR_WHILE_FETCHING_CONSIGNMENT_LIST_BASED_ON_ISSUE,
  NO_CONSIGNMENT_LIST_FOUND,
  NO_ISSUE_NAME_FOUND,
  TAB_VALUES_CONSTANT,
} from "../../constants/DelveDashboardConstant";
import { getConsignmentsDataBasedOnIssueName, getDelveDashboardRecord, selectAllDelveDashboardRecordListServices } from "./Redux/DelveDashboardSlice";
import {
  designConsignmentsListBasedOnIssueNameUtils,
  designDelveDashboardRecordDataUtils,
  designReturnDelveDashboardRecordDataUtils,
  fetchDashboardRecordBasedOnFilterUtils,
  resetDelveDashboardDataObjUtils,
} from "./Utils/DelveDashboardUtils";
import { API_RESPONSE_STATUS } from "../../constants/CommonConstants";
import { checkIfInputIsValidObjectWithKeys, isInputAnArray, isUndefined, isNull, isAString, isNonEmptyArray } from "../../utils/helpers.utils";
import { currentDate, endOfDay, isDateValid, isEndDateBeforeStartDate, startOfDay } from "../../utils/dateUtils";
import { TextConstants } from "../../constants/TextConstants";
import RouteConstants from "../../constants/RouteConstants";
import { TabContext, TabPanel } from "@material-ui/lab";

interface DelveDashboardInterface {}

const DelveDashboard = (props: DelveDashboardInterface) => {
  const {} = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const snackbarMessage = useRef("");
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);

  const { countryCode, userDetailsDropdowns } = useSelector((state: AppState) => state.common);
  const delveDashboardRecordListServices = useSelector(selectAllDelveDashboardRecordListServices);

  const [dashboardState, setDashboardState] = useState<DELVE_DASHBOARD_STATE_INTERFACE>(DELVE_DASHBOARD_STATE);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const fetchDashboardRecordBasedOnFilter = (dashboardStateObjCopy: any) => {
    let requestDashboardStateObj: any = fetchDashboardRecordBasedOnFilterUtils(dashboardStateObjCopy, countryCode);
    if (!isUndefined(requestDashboardStateObj) && checkIfInputIsValidObjectWithKeys(requestDashboardStateObj)) {
      dispatch(getDelveDashboardRecord(requestDashboardStateObj));
    } else {
      setDashboardState({ ...dashboardState, loading: false });
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, value: string) => {
    setDashboardState({ ...dashboardState, tabValue: value, loading: true });
    let dashboardStateObjCopy = JSON.parse(JSON.stringify(dashboardState));
    dashboardStateObjCopy.tabValue = value;
    fetchDashboardRecordBasedOnFilter(dashboardStateObjCopy);
  };

  const handleDateChange = (dateRange: any) => {
    if (!isUndefined(dateRange)) {
      let dashboardStateObjCopy = JSON.parse(JSON.stringify(dashboardState));
      dashboardStateObjCopy.dateSlot = { ...dateRange };
      setDashboardState({ ...dashboardState, loading: true, dateSlot: { ...dateRange } });
      fetchDashboardRecordBasedOnFilter(dashboardStateObjCopy);
    }
  };

  const handleDeliverySlotChange = (values: any) => {
    if (!isUndefined(values)) {
      let dashboardStateObjCopy = JSON.parse(JSON.stringify(dashboardState));
      setDashboardState({
        ...dashboardState,
        loading: isInputAnArray(values) && values.length === 1 && values[0] !== AppConstants.SELECT_NONE.value ? true : false,
        hubs: [...values],
      });

      if (Array.isArray(values) && values.length > 0) {
        if ((values.length === 1 && values[0] !== AppConstants.SELECT_NONE.value) || values.length > 1) {
          dashboardStateObjCopy.hubs = [...values];
          fetchDashboardRecordBasedOnFilter(dashboardStateObjCopy);
        }
      }
    }
  };

  const handleSlotTimeChange = (dateValue: any, KeyValue: any) => {
    if (!isUndefined(dateValue) && !isNull(dateValue)) {
      let dashboardStateObjCopy = JSON.parse(JSON.stringify(dashboardState));
      if (KeyValue === "startTime") {
        dashboardStateObjCopy.timeSlot.startTime = dateValue;
      } else {
        dashboardStateObjCopy.timeSlot.endTime = dateValue;
      }
      const isDateValidFlag = isDateValid(dateValue);
      const isEndDateBeforeStartDateFlag = isEndDateBeforeStartDate(dashboardStateObjCopy.timeSlot.startTime, dashboardStateObjCopy.timeSlot.endTime);
      setDashboardState({
        ...dashboardState,
        loading: isDateValidFlag ? (!isEndDateBeforeStartDateFlag ? true : false) : false,
        timeSlot: { ...dashboardState.timeSlot, [KeyValue]: dateValue },
      });

      if (isDateValidFlag) {
        if (!isEndDateBeforeStartDateFlag) {
          fetchDashboardRecordBasedOnFilter(dashboardStateObjCopy);
        } else {
          openSnackbarPopup(TextConstants.VALID_END_TIME, AppConstants.SNACKBAR.TYPES.ERROR);
        }
      }
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  };

  const designDeliveryDelveDashboard = (responseDataObj: any) => {
    let newDelveDashboardDataObj = JSON.parse(JSON.stringify(DELVE_DASHBOARD_DATA_OBJ));
    newDelveDashboardDataObj = resetDelveDashboardDataObjUtils(newDelveDashboardDataObj);
    if (!isUndefined(responseDataObj) && !isUndefined(responseDataObj.error) && !isNull(responseDataObj.error)) {
      openSnackbarPopup(responseDataObj.error, AppConstants.SNACKBAR.TYPES.ERROR);
    } else if (!isUndefined(responseDataObj) && !isUndefined(responseDataObj.data)) {
      if (!isUndefined(responseDataObj.data.results) && checkIfInputIsValidObjectWithKeys(responseDataObj.data.results)) {
        newDelveDashboardDataObj = designDelveDashboardRecordDataUtils(responseDataObj.data.results, newDelveDashboardDataObj);
      }
    }
    setDashboardState({ ...dashboardState, delveDashboardRecord: { ...newDelveDashboardDataObj }, loading: false });
  };

  const designReturnDelveDashboard = (responseDataObj: any) => {
    let newDelveDashboardDataObj = JSON.parse(JSON.stringify(DELVE_RETURN_DASHBOARD_DATA_OBJ));
    newDelveDashboardDataObj = resetDelveDashboardDataObjUtils(newDelveDashboardDataObj);
    if (!isUndefined(responseDataObj) && !isUndefined(responseDataObj.error) && !isNull(responseDataObj.error)) {
      openSnackbarPopup(responseDataObj.error, AppConstants.SNACKBAR.TYPES.ERROR);
    } else if (!isUndefined(responseDataObj) && !isUndefined(responseDataObj.data)) {
      if (!isUndefined(responseDataObj.data.results) && checkIfInputIsValidObjectWithKeys(responseDataObj.data.results)) {
        newDelveDashboardDataObj = designReturnDelveDashboardRecordDataUtils(responseDataObj.data.results, newDelveDashboardDataObj);
      }
    }
    setDashboardState({ ...dashboardState, returnDashboardRecord: { ...newDelveDashboardDataObj }, loading: false });
  };

  const designDelveDashboardRecordData = (responseDataObj: any) => {
    if (dashboardState) {
      if (dashboardState.tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value) {
        designDeliveryDelveDashboard(responseDataObj);
      }
      if (dashboardState.tabValue === TAB_VALUES_CONSTANT.RETURNS.value) {
        designReturnDelveDashboard(responseDataObj);
      }
    }
  };

  const IsDelveServiceFetchStatusValid = () => {
    if (
      !isUndefined(delveDashboardRecordListServices) &&
      !isUndefined(delveDashboardRecordListServices.fetchStatus) &&
      delveDashboardRecordListServices.fetchStatus !== API_RESPONSE_STATUS.LOADING &&
      delveDashboardRecordListServices.fetchStatus !== API_RESPONSE_STATUS.IDLE &&
      (delveDashboardRecordListServices.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED || delveDashboardRecordListServices.fetchStatus === API_RESPONSE_STATUS.FAILED)
    ) {
      designDelveDashboardRecordData(delveDashboardRecordListServices);
    }
  };

  const clearAllFilterHandler = () => {
    let dashboardStateObjCopy = JSON.parse(JSON.stringify(dashboardState));
    const dateData = currentDate(AppConstants.DATE_FORMAT);
    const startTime = startOfDay(dateData, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND);
    const endTime = endOfDay(dateData, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND);
    dashboardStateObjCopy.hubs = [AppConstants.SELECT_ALL.value];
    dashboardStateObjCopy.dateSlot.startDate = dateData;
    dashboardStateObjCopy.dateSlot.endDate = dateData;
    dashboardStateObjCopy.timeSlot.startTime = startTime;
    dashboardStateObjCopy.timeSlot.endTime = endTime;
    let requestDashboardStateObj: any = fetchDashboardRecordBasedOnFilterUtils(dashboardStateObjCopy, countryCode);
    if (!isUndefined(requestDashboardStateObj) && checkIfInputIsValidObjectWithKeys(requestDashboardStateObj)) {
      setDashboardState({
        ...dashboardState,
        loading: true,
        hubs: [AppConstants.SELECT_ALL.value],
        dateSlot: { startDate: dateData, endDate: dateData },
        timeSlot: { startTime: startTime, endTime: endTime },
      });
      dispatch(getDelveDashboardRecord(requestDashboardStateObj));
    }
  };

  const handleHubLevelVerificationCardClick = (hubName: any) => {
    if (!isUndefined(hubName) && !isNull(hubName)) {
      sessionStorage.setItem("delveDashboardDateRange", JSON.stringify(dashboardState.dateSlot));
      sessionStorage.setItem("delveHubName", JSON.stringify(hubName));
      sessionStorage.setItem("delveTabName", JSON.stringify(dashboardState.tabValue));
      history.push(RouteConstants.ROUTES.DELVE_ORDER_LIST);
    }
  };

  const getConsignmentsListBasedOnIssueName = async (payloadObject: any) => {
    const consignmentsObject = await dispatch(getConsignmentsDataBasedOnIssueName(payloadObject));
    if (
      !isUndefined(consignmentsObject) &&
      !isUndefined(consignmentsObject.payload) &&
      !isUndefined(consignmentsObject.payload.results) &&
      !isUndefined(consignmentsObject.payload.results.hubWiseConsignments)
    ) {
      const { hubWiseConsignments } = consignmentsObject.payload.results;
      if (checkIfInputIsValidObjectWithKeys(hubWiseConsignments)) {
        let consignment_list = designConsignmentsListBasedOnIssueNameUtils(hubWiseConsignments);
        if (!isUndefined(consignment_list) && isInputAnArray(consignment_list) && isNonEmptyArray(consignment_list)) {
          sessionStorage.setItem("delveDashboardDateRange", JSON.stringify(dashboardState.dateSlot));
          sessionStorage.setItem("delveConsignmentList", JSON.stringify(consignment_list));
          sessionStorage.setItem("delveTabName", JSON.stringify(dashboardState.tabValue));
          history.push(RouteConstants.ROUTES.DELVE_ORDER_LIST);
          return;
        }
      }
      openSnackbarPopup(NO_CONSIGNMENT_LIST_FOUND, AppConstants.SNACKBAR.TYPES.ERROR);
      setDashboardState({ ...dashboardState, loading: false });
    } else {
      openSnackbarPopup(ERROR_WHILE_FETCHING_CONSIGNMENT_LIST_BASED_ON_ISSUE, AppConstants.SNACKBAR.TYPES.ERROR);
      setDashboardState({ ...dashboardState, loading: false });
    }
  };

  const converIssueNameToEnum = (issueName: any) => {
    let name = "" as any;
    let i = 0;
    let character = "";
    while (i <= issueName.length) {
      character = issueName.charAt(i);
      if (i === 0 && character == character.toUpperCase()) {
        name = character;
      } else if (character == character.toLowerCase()) {
        name = name + character.toUpperCase();
      } else if (character == character.toUpperCase()) {
        name = name + "_" + character;
      }
      i++;
    }
    return name;
  };

  const fetchConsignmentsListBasedOnIssueName = (issueName: any) => {
    if (!isUndefined(issueName) && isAString(issueName) && issueName !== "" && issueName !== "Issue") {
      setDashboardState({ ...dashboardState, loading: true });
      let requestDashboardStateObj: any = fetchDashboardRecordBasedOnFilterUtils(dashboardState, countryCode);
      if (!isUndefined(requestDashboardStateObj) && checkIfInputIsValidObjectWithKeys(requestDashboardStateObj)) {
        requestDashboardStateObj.rejectionReasons = [] as any;
        requestDashboardStateObj.rejectionReasons.push(converIssueNameToEnum(issueName));
        getConsignmentsListBasedOnIssueName(requestDashboardStateObj);
      } else {
        setDashboardState({ ...dashboardState, loading: false });
      }
    } else {
      openSnackbarPopup(NO_ISSUE_NAME_FOUND, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  };

  useEffect(() => {
    IsDelveServiceFetchStatusValid();
  }, [delveDashboardRecordListServices, delveDashboardRecordListServices.fetchStatus]);

  useEffect(() => {
    setDashboardState({ ...dashboardState, loading: true });
    fetchDashboardRecordBasedOnFilter(dashboardState);
  }, [countryCode]);

  return (
    <div className={classes.root}>
      {dashboardState.loading && <Loader></Loader>}
      <Grid className={classes.container} container>
        <TabContext value={dashboardState.tabValue}>
          <Tabs value={dashboardState.tabValue} onChange={handleTabChange} className={classes.dashboardTabRootStyle}>
            <Tab label={TAB_VALUES_CONSTANT.DELIVERIES.name} value={TAB_VALUES_CONSTANT.DELIVERIES.value} />
            <Tab label={TAB_VALUES_CONSTANT.RETURNS.name} value={TAB_VALUES_CONSTANT.RETURNS.value} />
          </Tabs>
          <Grid className={classes.viewContainer} container>
            <Grid item xs={12} className={classes.gritSearchContainerItem}>
              <DelveDashboardSearchHeader
                classes={classes}
                dashboardState={dashboardState}
                countryCode={countryCode}
                userDetailsDropdowns={userDetailsDropdowns}
                handleDateChange={handleDateChange}
                handleDeliverySlotChange={handleDeliverySlotChange}
                handleSlotTimeChange={handleSlotTimeChange}
                clearAllFilterHandler={clearAllFilterHandler}
              />
            </Grid>
            <TabPanel value={TAB_VALUES_CONSTANT.DELIVERIES.value} className={classes.dashboardTabPanelRootStyle}>
              <DelveDashboardOrderStatusDetails classes={classes} recordObj={dashboardState.delveDashboardRecord} />
              <Grid item xs={12} className={classes.gritContainerItem}>
                <Grid container spacing={1}>
                  <Grid item xs={8} className={classes.gritContainerIssueSummaryBox}>
                    <DelveDashboardIssueMappingDetails classes={classes} recordObj={dashboardState.delveDashboardRecord} />
                  </Grid>
                  <Grid item xs={4} className={classes.gritContainerIssueSummaryBox}>
                    <DelveDashboardIssueSummaryDetails
                      classes={classes}
                      recordObj={dashboardState.delveDashboardRecord}
                      fetchConsignmentsListBasedOnIssueName={fetchConsignmentsListBasedOnIssueName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.gritContainerItem}>
                <DelveDashboardHubVerificationDetails
                  classes={classes}
                  recordObj={dashboardState.delveDashboardRecord}
                  handleHubLevelVerificationCardClick={handleHubLevelVerificationCardClick}
                  userDetailsDropdowns={userDetailsDropdowns}
                  countryCode={countryCode}
                  tabValue={dashboardState.tabValue}
                />
              </Grid>
            </TabPanel>
            <TabPanel value={TAB_VALUES_CONSTANT.RETURNS.value} className={classes.dashboardTabPanelRootStyle}>
              <DelveDashboardOrderStatusDetails classes={classes} recordObj={dashboardState.returnDashboardRecord} />
              <Grid item xs={12} className={classes.gritContainerItem}>
                <Grid container spacing={1}>
                  <Grid item xs={8} className={classes.gritContainerIssueSummaryBox}>
                    <DelveDashboardIssueMappingDetails classes={classes} recordObj={dashboardState.returnDashboardRecord} />
                  </Grid>
                  <Grid item xs={4} className={classes.gritContainerIssueSummaryBox}>
                    <DelveDashboardIssueSummaryDetails
                      classes={classes}
                      recordObj={dashboardState.returnDashboardRecord}
                      fetchConsignmentsListBasedOnIssueName={fetchConsignmentsListBasedOnIssueName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.gritContainerItem}>
                <DelveDashboardHubVerificationDetails
                  classes={classes}
                  recordObj={dashboardState.returnDashboardRecord}
                  handleHubLevelVerificationCardClick={handleHubLevelVerificationCardClick}
                  userDetailsDropdowns={userDetailsDropdowns}
                  countryCode={countryCode}
                  tabValue={dashboardState.tabValue}
                />
              </Grid>
            </TabPanel>
          </Grid>
        </TabContext>
      </Grid>

      {/* CustomSnackbar */}
      <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
    </div>
  );
};

export default React.memo(DelveDashboard);
