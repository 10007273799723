import React from "react";

import { ReactComponent as RescheduleOrdersIcon } from "../../assets/icons/reschedule-orders-icon.svg";
import { Grid, SvgIcon, Tooltip, Typography } from "@material-ui/core";

import { useStyles } from "./RescheduleOrdersStyles";

interface RenderRescheduleOrdersInterface {}

const RenderRescheduleOrders = (props: RenderRescheduleOrdersInterface) => {
  const {} = props;
  const classes = useStyles();

  const renderTooltip = () => {
    return (
      <Grid container className={classes.tooltipTitleSection}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.tooltipHeading}>Rescheduled Order</Typography>
          </Grid>
          <Grid item className={classes.tooltipData}></Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Tooltip
        title={renderTooltip()}
        arrow
        classes={{
          popper: classes.popperStyle,
          arrow: classes.arrowStyle,
          tooltip: classes.tooltipStyle,
        }}
      >
        <SvgIcon component={RescheduleOrdersIcon} viewBox="0 0 20 21" className={classes.rescheduleOrdersIconStyle} />
      </Tooltip>
    </div>
  );
};

export default React.memo(RenderRescheduleOrders);
