import React, { useCallback, forwardRef, ReactElement, Ref, useRef, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchConsignmentHistoryAndProducts, closeDialog, resetReplanningSuccess, updateAddressSuccess, fetchCHDSlotDropdownValues, updateCustomerAddress } from "./redux/carrefourDeliverySlice";
import mobileLogoIcon from './../assets/icons/mobileLogo.svg';
import emailLogoIcon from './../assets/icons/emailLogo.svg';
import RcViewer from '@hanyk/rc-viewer';
import { AppState } from "../config/redux/reducers";
import { ReactComponent as editIcon } from './../assets/icons/editIcon.svg';
import { ReactComponent as activeTimelineDot } from './../assets/icons/active-timeline-dot.svg';
import { ReactComponent as errorTimelineDot } from './../assets/icons/timeline-dot-error.svg';
import { ReactComponent as timelineDot } from './../assets/icons/timeline-dot.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';
import { useStyles } from './CarrefourDeliveryOrderDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, SvgIcon, Collapse, Link, Divider } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import { COLORS } from '../config/theme/baseTheme';
import MapContainer from '../common/GoogleMapContainer';
import AppConstants from "../constants";
import { getAddressInfo, getLatLong, createPayloadForUpdatingCustAddress } from 'mocks/carrefourHomeDelivery/response.transforms';
import { fixDecimals, localTimeString, transformSlotValue, isDisplayStatusEditable, isCardOnDelivery } from 'utils/helpers.utils';
import moment from 'moment';
import UpdateCustomerAddress from './UpdateCustomerAddress';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import clsx from "clsx";
import { pdfjs } from 'react-pdf';
import CarrefourDeliveryOrderDetailsEdit from "./CarrefourDeliveryOrderDetailsEdit";
import CustomSnackbar from "../common/CustomSnackbar";
import UpdatePartialRejection from './UpdatePartialRejection';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface CarrefourDeliveryOrderDetailsProps {
  open: boolean;
  consignment: any;
  isFromViewRoute?: any;
  isEditIcon?: any
  closePopup: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CarrefourDeliveryOrderDetails = (props: CarrefourDeliveryOrderDetailsProps) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { consignmentStatuses, productsList, consignmentDataAfterReplanning, isUpdateAddress, updatedCustomerAddress } = useSelector(
    ((state: AppState) => state.carrefourDelivery), shallowEqual
  );
  const { countryCode } = useSelector(
    ((state: AppState) => state.common), shallowEqual
  );
  const { isLastMileViewer, isOnlyStdLastMileViewer } = useSelector(
    ((state: AppState) => state.userLogin)
  );
  const { open, consignment, isFromViewRoute, isEditIcon, closePopup } = props;
  const [toggleCustMap, setToggleCustMap] = useState(false);
  const [toggleSellerMap, setToggleSellerMap] = useState(false);
  const [openConsignmentEditPopup, setOpenConsignmentEditPopup] = useState(false);
  const [openCustomerAddressPopup, setOpenCustomerAddressPopup] = useState(false);
  const [openPartialRejectionPopup, setOpenPartailRejectionPopup] = useState(false);
  const [openInfoSnackbar, setOpenInfoSnackbar] = useState(false);
  const [showOriginalName, setShowOriginalName] = useState(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const infoSnackbarType = useRef(AppConstants.SNACKBAR.TYPES.ERROR);
  const snackbarMessage = useRef(AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.ADDRESS_UPDATE_SUCCESS_MSG);
  const infoSnackbarMessage = useRef(AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.UNPLAN_CONSIGNMENT_WARNING_MSG);

  let deliveryInformation: any = {};
  let metadata: any = {};
  let custInfo: any = {
    address: {}
  };
  let updatedAddress: any = {
    address: {}
  };
  let custGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let sellerGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let posInfo: any = {
    address: {}
  };
  let items: any = [];
  let historyItems: any = [];

  let deliveryDate = "";
  let deliverySlot = "";
  let hubName = "";


  const handleStatusHistoryAndProducts = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchConsignmentHistoryAndProducts({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  if (consignment) {
    deliveryInformation = consignment.deliveryInformation;
    metadata = consignment.metadata;
    custInfo = deliveryInformation && deliveryInformation.customerContactInfo;
    if (custInfo && custInfo.address) {
      custGeoLocation.name = custInfo.name;
      custGeoLocation.location.lat = parseFloat(custInfo.address.latitude) || 0;
      custGeoLocation.location.lng = parseFloat(custInfo.address.longitude) || 0;
    }
    if (consignment.posInformation) {
      posInfo = consignment.posInformation.contactInfo;
      hubName = consignment.posInformation.storeName || consignment.posInformation.store;
    }
    if (deliveryInformation && deliveryInformation.deliverySlot) {
      const startTime = new Date(deliveryInformation.deliverySlot.startTime);
      const endTime = new Date(deliveryInformation.deliverySlot.endTime);
      deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.DATE_FORMAT);
      deliverySlot = `${localTimeString(startTime)} - ${localTimeString(endTime)}`
    }
    if (posInfo && posInfo.address) {
      sellerGeoLocation.name = hubName;
      sellerGeoLocation.location.lat = parseFloat(posInfo.address.latitude) || 0;
      sellerGeoLocation.location.lng = parseFloat(posInfo.address.longitude) || 0;
    }
    items = productsList;
    if (consignmentStatuses) {
      let statuses = consignmentStatuses.slice();
      historyItems = statuses;
    }
  }
  if (updatedCustomerAddress) {
    if (updatedCustomerAddress && updatedCustomerAddress.deliveryInformation && updatedCustomerAddress.deliveryInformation.customerContactInfo && updatedCustomerAddress.deliveryInformation.customerContactInfo.address) {
      updatedAddress = updatedCustomerAddress.deliveryInformation.customerContactInfo
    }
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const handleTrackingLink = () => {
    window.open(deliveryInformation.carrierTrackingUrl, '_blank');
  }

  const handleOriginalName = () => {
    if (!showOriginalName) {
      setShowOriginalName(true);
    } else {
      setShowOriginalName(false);
    }
  }

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
  }

  const checkEditable = () => {
    if (Object.keys(consignmentDataAfterReplanning).length !== 0) {
      return AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus) === -1;
    } else {
      return AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(metadata && metadata.deliveryStatus) === -1;
    }
  }

  const getItems = () => {
    return items && items.length ? items.map((item: any, index: number) => (
      <Grid key={`${item.name.en} - ${index}`} className="item" item>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn item1" item xs={5}>
            <Typography>{item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].name && item.substitutedProducts[0].name.en ? item.substitutedProducts[0].name.en.toLowerCase() : item.name && item.name.en ? item.name.en.toLowerCase() : ""}</Typography>
            {
              item.reason ? (
                <Typography className="rejectedReason">{`Rejection Reason - ${item.reason.reasonDescription}`}</Typography>) :
                item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].reason ? (
                  <Typography className="rejectedReason">{`Rejection Reason - ${item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].reason && item.substitutedProducts[0].reason.reasonDescription}`}</Typography>
                ) : (
                    <Typography>{""}</Typography>
                  )
            }
            {
              item.substitutedProducts &&
              <div style={{ display: "flex" }}>
                <Typography className="viewOriginalLink"> View Original</Typography>
                <KeyboardArrowDownRoundedIcon className={clsx(!showOriginalName && classes.closeY, showOriginalName && classes.openY)} onClick={() => handleOriginalName()} />
              </div>
            }
          </Grid>
          <Grid className="itemColumn item2" item xs={2}>
            <Typography><span>{item.substitutedProducts && item.substitutedProducts.length ? item.substitutedProducts[0].rejectedQty : item.rejectedQty || "-"}</span></Typography>

          </Grid>

          <Grid className="itemColumn item2" item xs={3}>
            <Typography><span className="green">{item.substitutedProducts && item.substitutedProducts.length ? item.substitutedProducts[0].shippedQty : item.shippedQty || ""}</span>/{`${item.quantity || ""} ${item.uom && item.uom === 'Grams' ? item.uom : ""}`}</Typography>
          </Grid>
          <Grid className="itemColumn item3" item xs={2}>
            <Typography>{`${fixDecimals(2, item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
          </Grid>
        </Grid>

        {
          showOriginalName && item.substitutedProducts &&
          <Grid className="itemContainer items" container>
            <Grid className="itemColumn item1" item xs={5}>
              <Typography className="originalName">{item.name.en.toLowerCase()}</Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography><span>{item.rejectedQty || "-"}</span></Typography>

            </Grid>

            <Grid className="itemColumn item2" item xs={3}>
              <Typography><span className="green">{item.shippedQty || ""}</span>/{`${item.quantity || ""} ${item.uom && item.uom === 'Grams' ? item.uom : ""}`}</Typography>
            </Grid>
            <Grid className="itemColumn item3" item xs={2}>
              <Typography>{`${fixDecimals(2, item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    )) : (<Typography>No Items to display</Typography>)
  }

  const handleDialogClose = useCallback(
    () => {
      closePopup();
      dispatch(
        closeDialog()
      );
    },
    [closePopup, dispatch],
  );

  const getCustomerImages = useCallback(
    () => {
      return deliveryInformation && deliveryInformation.deliveryProof && deliveryInformation.deliveryProof.imageURLs ? deliveryInformation.deliveryProof.imageURLs.map((image: any, index: number) => (
        <img key={`customer-image-${index}`} className="customer-proof-img" alt="customer-delivery-proofs" src={image} />
      )) : null;
    },
    [deliveryInformation],
  );

  const closeUpdateAddressPopup = useCallback(
    () => {
      setOpenCustomerAddressPopup(false);
    },
    [],
  );

  const closeConsignmentEditPopup = useCallback(
    () => {
      setOpenConsignmentEditPopup(false);
    },
    [],
  );

  const closePartialRejectionPopup = useCallback(
    () => {
      setOpenPartailRejectionPopup(false);
    },
    [],
  );

  const handleEditAddress = (params: any) => {
    if (params === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP) {
      setOpenCustomerAddressPopup(true);
    } else {
      setOpenInfoSnackbar(true);
    }
  }

  const handleEditPopup = useCallback(
    () => {
      dispatch(
        resetReplanningSuccess()
      )
      setOpenConsignmentEditPopup(true);
    },
    [dispatch],
  );

  const updateNewAddress = useCallback(
    (params: any, consignmentId) => {
      const payload = createPayloadForUpdatingCustAddress(params, consignmentId.id);
      dispatch(
        updateCustomerAddress({
          params: payload
        })
      );
      closeUpdateAddressPopup();
    },
    [dispatch, closeUpdateAddressPopup]
  )

  const handleUpdateActions = useCallback(
    (params: any) => {
      if (params.type === 'submit') {
        updateNewAddress(params.values, params.consignmentId);
      } else {
        if (params.type === 'cancel') {
          closeUpdateAddressPopup();
        }
      }
    },
    [updateNewAddress, closeUpdateAddressPopup]
  )

  const handlePartialRejectionUpdateActions = useCallback(
    (params: any) => {
      if (params.type === 'sumbit') {
        console.log("sumit");
      }
    }, []
  )

  const handleSnackbarClose = useCallback(
    () => {
      dispatch(
        updateAddressSuccess()
      );
    },
    [dispatch],
  );

  const handleInfoSnackbarClose = () => {
    setOpenInfoSnackbar(false);
  }

  const handleDeliverySlotsForCHD = useCallback(
    (countryCode: any) => {
      dispatch(
        fetchCHDSlotDropdownValues({
          countryCode: countryCode
        })
      );
    },
    [dispatch]
  );

  const getHistoryTimelineItems = () => {
    return historyItems && historyItems.length ? historyItems.map((item: any, index: number) => (
      < TimelineItem key={`${item.statusCode} - ${index}`} className="timelineItem" >
        <TimelineSeparator>
          {index === 0 ?
            <TimelineDot className="timelineDot">
              <SvgIcon className="dotIcon active" component={item.statusCode === "DELIVERY_FAILED" ? errorTimelineDot : activeTimelineDot} viewBox="0 0 18 18" />
            </TimelineDot>
            :
            <TimelineDot className="timelineDot">
              <SvgIcon className="dotIcon" component={timelineDot} viewBox="0 0 8 8" />
            </TimelineDot>
          }
          {index !== historyItems.length - 1 && <TimelineConnector className="primaryConnector" />}
        </TimelineSeparator>
        <TimelineContent className="timelineContent">
          <Typography className={["heading", index === 0 ? "active" : "", (item.statusCode === "DELIVERY_FAILED") ? "error" : ""].join(" ")}><span className="status">{item.statusName}</span><span className="dateTime">{`(${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
          })})`}</span></Typography>
          {
            item.reason ? (
              <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                <Typography className="title">{item.reason ? item.reason.reasonDescription : ""}</Typography>
                <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                  hour12: false,
                  hour: 'numeric',
                  minute: 'numeric',
                })})`}</Typography>
              </Typography>) :
              consignment.reason && consignment.reason.deliveryStatus === item.statusCode ? (
                <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                  <Typography className="title">{consignment.reason ? consignment.reason.reasonDescription : ""}</Typography>
                  <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                  })})`}</Typography>
                </Typography>
              ) : ""
          }
        </TimelineContent>
      </TimelineItem >
    )) : (<Typography>No Items to display</Typography>)
  }
  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label"> CONSIGNMENT ID : </span>
                    <span className="content"> {consignment.consignmentCode} </span>
                  </Typography>
                </Grid>
                {
                  consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata ?
                    (
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus)].join(" ")}>{consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus ? consignmentDataAfterReplanning.metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    ) : (
                      metadata && metadata.deliveryStatus &&
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', metadata && metadata.deliveryStatus)].join(" ")}>{metadata && metadata.deliveryStatus ? metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    )
                }
                {!(isLastMileViewer || isOnlyStdLastMileViewer) && (
                  <>
                    {isFromViewRoute
                      ? checkEditable() &&
                        !isEditIcon && (
                          <Grid className='editIcon' item>
                            <SvgIcon className='icon' onClick={handleEditPopup} component={editIcon} />
                          </Grid>
                        )
                      : checkEditable() &&
                        isDisplayStatusEditable(metadata) && (
                          <Grid className='editIcon' item>
                            <SvgIcon className='icon' onClick={handleEditPopup} component={editIcon} />
                          </Grid>
                        )}
                  </>
                )}
              </Grid>
            </Grid>
            {
              (deliveryInformation && deliveryInformation.carrierTrackingId) || consignment.orderReference ? (
                <Grid container>
                  <Grid item>
                    {
                      deliveryInformation && deliveryInformation.carrierTrackingUrl ?
                        <div style={{ display: "flex" }}>
                          <Link className="titleContent link" onClick={handleTrackingLink}>{`Live Tracking (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Link>
                        </div>
                        :
                        <Typography className="titleContent">{`Track Consignment (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Typography>
                    }
                  </Grid>

                </Grid>
              ) : ""
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className="customerDetails" item style={{ paddingBottom: "16px" }}>
          <Grid className="detailsHeader" container>
            <Grid className="detailsHeaderItem" container>
              <Grid item xs={6}>
                <Typography className="title">Customer Details</Typography></Grid>
                {!(isLastMileViewer || isOnlyStdLastMileViewer) && (
                <>
                  {checkEditable() && (
                    <Grid item xs={6} className='editIcon'>
                      <SvgIcon
                        className='editAddress'
                        onClick={() => handleEditAddress(metadata && metadata.displayRouteStatus)}
                        component={editIcon}
                      />
                    </Grid>
                  )}
                </>
                )}
            </Grid>
            <div className={classes.horizontalDiv} />
            <Grid className="detailsHeaderItem" item>
              <Typography className="name">{custInfo && custInfo.name}</Typography>
              {Object.keys(updatedAddress.address).length !== 0 ? (
                <Typography style={{ paddingTop: "12px" }}>{getAddressInfo(updatedAddress.address || "")} </Typography>
              ) : (
                  <Typography style={{ paddingTop: "12px" }}>{getAddressInfo(custInfo && custInfo.address ? custInfo.address : "")} </Typography>)}
              <div className="subSection">
                <Typography className="subSectionTitle" >Lat/Long :</Typography>
                <Typography className="subSectionContent">{getLatLong(custInfo && custInfo.address ? custInfo.address : "")}</Typography>
              </div>
              <div className="subSection">
                <Typography className="subSectionTitle" >Landmark :</Typography>
                {Object.keys(updatedAddress.address).length !== 0 ? (
                  <Typography className="subSectionContent">{updatedAddress.address ? updatedAddress.address.landmark : ""}</Typography>
                ) : (
                    <Typography className="subSectionContent">{custInfo && custInfo.address ? custInfo.address.landmark : ""}</Typography>
                  )}
              </div>
              <div className="subSection">
                <Typography className="subSectionTitle" >Delivery Instructions :</Typography>
                <Typography className="subSectionContent">{deliveryInformation ? deliveryInformation.deliveryInstructions : ""}</Typography>
              </div>
            </Grid>
            <Grid className="contentRow geocodedAddress" item>
              <Grid container direction="column">
                <Grid className="addressColItem" item>
                  <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => setToggleCustMap(!toggleCustMap)}>
                    <span className="address">View Geocoded Address</span>
                    <SvgIcon className={["addressLogo", toggleCustMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                  </Grid>
                </Grid>
                <Collapse in={toggleCustMap}>
                  <Grid className="addressMapContainer" item>
                    {
                      custGeoLocation ? (
                        <MapContainer center={custGeoLocation.location} zoom={12} markers={[custGeoLocation]}></MapContainer>
                      ) : ""
                    }
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <Grid className="detailsHeaderItem" item>
              <Grid container className="headerItemContainer">
                <Grid className="contactMobile" item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                    <span className="contact mobile">{custInfo ? <a href={`tel: ${custInfo.phone}`}>{custInfo.phone || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container className="contactItemContainer">
                    <img className="logo" alt="Email" src={emailLogoIcon} />
                    <span className="contact email">{custInfo ? <a href={`mailto: ${custInfo.email}`}>{custInfo.email || ""}</a> : ""}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="customerDetails" item style={{ paddingBottom: "8px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="hub-address-header"
              id="hub-address-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Hub Address</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container className="contentHubAddress">
                <Grid item className="main">
                  <Typography className="title">{hubName}</Typography>
                  <Typography className="content">{getAddressInfo(posInfo && posInfo.address ? posInfo.address : "")}</Typography>
                </Grid>
                <Grid item className="subSection">
                  <Typography className="subSectionTitle">Lat/Long:</Typography>
                  <Typography className="subSectionContent">{getLatLong(posInfo && posInfo.address ? posInfo.address : "")}</Typography>
                </Grid>
                <Grid className="contentRow geocodedAddress" item style={{ paddingTop: "16px" }}>
                  <Grid container direction="column">
                    <Grid className="addressColItem" item>
                      <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => setToggleSellerMap(!toggleSellerMap)}>
                        <span className="address">View Geocoded Address</span>
                        <SvgIcon className={["addressLogo", toggleSellerMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                      </Grid>
                    </Grid>
                    <Collapse in={toggleSellerMap}>
                      <Grid className="addressMapContainer" item>
                        {
                          custGeoLocation ? (
                            <MapContainer center={sellerGeoLocation.location} zoom={12} markers={[sellerGeoLocation]}></MapContainer>
                          ) : ""
                        }
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
                <Grid className="detailsHeaderItem" item>
                  <Grid container className="headerItemContainer">
                    <Grid className="contactMobile" item>
                      <Grid container className="contactItemContainer">
                        <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                        <span className="contact mobile">{posInfo ? <a href={`tel: ${posInfo.phone}`}>{posInfo.phone || ""}</a> : ""}</span>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container className="contactItemContainer">
                        <img className="logo" alt="Email" src={emailLogoIcon} />
                        <span className="contact email">{posInfo ? <a href={`mailto: ${posInfo.email}`}>{posInfo.email || ""}</a> : ""}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item>
          <Grid className="detailsContent" container>
            <Grid className="contentRow" item>
              <Grid className="rowContainer" container>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Order No.</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{consignment.orderCode}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Delivery Date</Typography>
                    </Grid>
                    <Grid item>
                      {consignmentDataAfterReplanning && consignmentDataAfterReplanning.deliveryInformation ?
                        <Typography className="content">{moment(consignmentDataAfterReplanning.deliveryInformation.deliverySlot.deliveryDate, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.DATE_FORMAT)}</Typography>
                        :
                        <Typography className="content">{deliveryDate}</Typography>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Delivery Slot</Typography>
                    </Grid>
                    <Grid item>
                      {consignmentDataAfterReplanning && consignmentDataAfterReplanning.deliveryInformation ?
                        <Typography className="content">{transformSlotValue(consignmentDataAfterReplanning.deliveryInformation.deliverySlot.deliverySlotTime)}</Typography>
                        :
                        <Typography className="content">{deliverySlot}</Typography>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentRow" item>
              <Grid className="rowContainer" container>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Delivery Proposition</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{metadata ? metadata.deliveryType : ""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Payment Mode</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{metadata ? metadata.paymentType : ""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Delivery Area</Typography>
                    </Grid>
                    <Grid item>
                      {Object.keys(updatedAddress.address).length !== 0 ? (
                        <Typography className="subSectionContent">{updatedAddress.address ? updatedAddress.address.area : ""}</Typography>
                      ) : (
                          <Typography className="subSectionContent">{custInfo && custInfo.address ? custInfo.address.area : ""}</Typography>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              metadata && metadata.routeName &&
              <Grid className="contentRow" item>
                <Grid className="rowContainer" container>
                  <Grid className="contentColumn" item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className="label">Route No.</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="content">{metadata.routeName}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="contentColumn" item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className="label">Driver</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="content">{deliveryInformation && deliveryInformation.driver && deliveryInformation.driver.name}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="contentColumn" item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className="label">Vehicle Type</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="content">{deliveryInformation && deliveryInformation.vehicle && deliveryInformation.vehicle.name}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid className="deliveryProofSection" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="delivery-proof-content"
              id="delivery-proof-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Proof of Delivery</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className="content">
                <Grid className="deliveryProofContainer" container>
                  <Grid className="deliveryProofItem images" item>
                    <Grid container className="imagesContainer">
                      <Grid item className="imagesItem addon">
                        <Grid container className="imageItemContainer">
                          <Grid item className="imageItem">
                            <Typography className="heading">Images</Typography>
                          </Grid>
                          <Grid item className="imageItem allImages">
                            <Grid container className="allImagesContainer">
                              <RcViewer className="viewerContainer">
                                {getCustomerImages()}
                              </RcViewer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="imagesItem sign">
                        <Grid container className="imageItemContainer">
                          <Grid item className="imageItem">
                            <Typography className="heading">Customer's Signature</Typography>
                          </Grid>
                          <Grid item className="imageItem allImages">
                            <Grid container className="allImagesContainer sign">
                              {
                                deliveryInformation && deliveryInformation.deliveryProof && deliveryInformation.deliveryProof.signURL &&
                                <RcViewer className="viewerContainer">
                                  <img src={deliveryInformation.deliveryProof.signURL} className="customer-sign-img" alt="customer-signature" />
                                </RcViewer>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="deliveryProofItem comments" item>
                    <Grid container className="commentsContainer">
                      <Grid item className="commentsItem head">
                        <Typography className="heading">Comments:</Typography>
                      </Grid>
                      <Grid item className="commentsItem details">
                        <Typography className="desc">{deliveryInformation && deliveryInformation.deliveryProof ? deliveryInformation.deliveryProof.comments : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className="consignmentHistory" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="consignment-history-content"
              id="consignment-history-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Consignment History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className="content">
                <Timeline className={classes.timeline} align="left">
                  {getHistoryTimelineItems()}
                </Timeline>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className="tabularSection items" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="consignment-history-content"
              id="consignment-history-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Items Summary ({items && items.length})</Typography>
            </AccordionSummary>
            <AccordionDetails className="itemDiv">
              <Typography component="div" className="content">
                <Grid className="itemsContainer" container direction="column">
                  <Grid className="item" item>
                    <Grid className="itemContainer header" container style={{ padding: "16px" }}>
                      <Grid className="itemColumn item1" item xs={5}>
                        <Typography>Product Name</Typography>
                      </Grid>
                      <Grid className="itemColumn item2" item xs={2}>
                        <Typography>Rejected Qty</Typography>
                      </Grid>
                      <Grid className="itemColumn item2" item xs={3}>
                        <Typography>Shipped/ Ordered Qty</Typography>
                      </Grid>
                      <Grid className="itemColumn item3" item xs={2}>
                        <Typography>Unit Price</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {getItems()}
                </Grid>
                <Grid className="paymentContainer" container direction="column">
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Payment Method</Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{metadata ? metadata.paymentType : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Delivery Charges</Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{deliveryInformation && metadata ? `${fixDecimals(2, deliveryInformation.deliveryCost ? deliveryInformation.deliveryCost.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Grand Total<span className="vat">(inclusive of VAT)</span></Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{metadata ? `${fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    metadata && (metadata.paymentType === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.COD_TEXT || metadata?.paymentType  && isCardOnDelivery(metadata.paymentType))  ?
                      <>
                        <Divider className="totalDivider"></Divider>
                        <Grid className="paymentItem" item>
                          <Grid container>
                            <Grid className="paymentItemColumn" item>
                              <Typography>Amount to be Collected</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                              <Typography>{metadata ? `${fixDecimals(2, metadata.amountToCollect ? metadata.amountToCollect.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid className="paymentItem" item>
                          <Grid container>
                            <Grid className="paymentItemColumn" item>
                              <Typography>Collected Amount</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                              <Typography>{metadata ? `${fixDecimals(2, metadata.collectedAmount ? metadata.collectedAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                      : null
                  }
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid >
    )
  }

  useEffect(() => {
    if (Object.keys(consignmentDataAfterReplanning).length !== 0) {
      let consignmentCode = consignmentDataAfterReplanning && consignmentDataAfterReplanning.consignmentCode;
      dispatch(
        fetchConsignmentHistoryAndProducts({
          params: consignmentCode
        })
      );
    }
  }, [consignmentDataAfterReplanning, dispatch])

  useEffect(() => {
    if (consignment != null) {
      handleStatusHistoryAndProducts(consignment);
      handleDeliverySlotsForCHD(countryCode);
    }
  }, [handleStatusHistoryAndProducts, handleDeliverySlotsForCHD, consignment, countryCode]);


  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={null}
        handleClose={handleDialogClose}
      ></CustomDialog>
      <UpdateCustomerAddress open={openCustomerAddressPopup} consignment={consignment} custInfo={Object.keys(updatedAddress.address).length !== 0 ? updatedAddress : custInfo} handleDialogAction={handleUpdateActions} closePopup={closeUpdateAddressPopup}></UpdateCustomerAddress>
      <CarrefourDeliveryOrderDetailsEdit open={openConsignmentEditPopup} consignment={consignment} closePopup={closeConsignmentEditPopup}></CarrefourDeliveryOrderDetailsEdit>
      <UpdatePartialRejection open={openPartialRejectionPopup} consignment={consignment} closePopup={closePartialRejectionPopup} handleDialogAction={handlePartialRejectionUpdateActions}></UpdatePartialRejection>
      <CustomSnackbar open={isUpdateAddress} handleClose={handleSnackbarClose} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
      <CustomSnackbar open={openInfoSnackbar} handleClose={handleInfoSnackbarClose} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={infoSnackbarMessage.current} type={infoSnackbarType.current} />
    </>
  )
}

export default CarrefourDeliveryOrderDetails;