import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexCharts from "react-apexcharts";
import { useStyles } from "./DonutChartComponent.styles";
import { COLORS } from "../../config/theme/baseTheme";

interface DonutChartProps {
    options?: ApexOptions;
    pieData: any;
    pieLabel?: any;
    pieColors: any;
    title: any;
    donutLabel: any;
}

const DonutChart = (props: DonutChartProps) => {
    const { pieData, pieLabel, pieColors, title, donutLabel } = props;
    const classes = useStyles();
    const config: any = {
        series: pieData,
        options: {
            chart: {
                fontFamily: "Montserrat",
                type: "pie"
            },
            chartOptions: {
                labels: pieLabel
            },
            title: {
                text: title,
                style: {
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    color: COLORS.BLACK,
                    lineHeight: "20px"
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: "60%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                offsetY: 20
                            },
                            value: {
                                show: true,
                                fontSize: 24,
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                                color: COLORS.BLACK,
                                offsetY: -20,
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: donutLabel,
                                fontSize: 12,
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: COLORS.BLACK,
                                formatter: function (w: any) {
                                    return w.globals.seriesTotals.reduce((a: any, b: any) => {
                                        return a + b;
                                    }, 0);
                                },
                            },
                        },
                    },
                },
            },
            colors: pieColors,
            labels: pieLabel,
            dataLabels: {
                dropShadow: {
                    enabled: false
                },
            },
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                fontFamily: "Montserrat",
                fontSize: 12,
                fontWeight: 500,
            },
            stroke: {
                width: 1
            },
            noData: {
                text: "No Data Available",
                style: {
                    fontFamily: "Montserrat",
                    fontSize: 14,
                    colors: ["#000000"],
                    fontWeight: 500,
                },
                verticalAlign: "middle"
            },
            tooltip: {
                onDatasetHover: {
                    highlightDataSeries: true
                }
            },
        }
    };


    return (
        <>
            <div id="chart" className={classes.dashboardChartStyle}>
                <ReactApexCharts options={config.options} series={config.series} type="donut" height="350px" />
            </div>
        </>
    );
};


export default DonutChart;
