import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const customFooterUseStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        alignItems: "center"
      },
      countSection: {
        margin: 'auto',
        width: 200,
        height: 40,
        borderRadius: 100,
        backgroundColor: COLORS.FOOTER_COUNT_BACKGROUND,
        color: COLORS.WHITE,
        '& .countContainer': {
          height: "100%",
          '& .countItem': {
            margin: "auto",
            '& p': {
              fontSize: 16,
              fontWeight: 400
            },
            '& .current': {
              fontWeight: 500
            },
            '& .separator': {
              padding: "0 2px"
            }
          }
        }
      }
    })
);