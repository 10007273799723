import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { RouteTrackingState } from "./routeTrackingSlice.types";
import AppConstants from "../../constants";
import { transformSelectValues, transformIntoArray } from "../../utils/helpers.utils";


export const fetchFiltersDropdownValues = createAsyncThunk(
    "RouteTracking/FetchFiltersDropdownValues",
    async (params, { rejectWithValue }) => {
        try {
            const request = {
                url: `filter/all/${sessionStorage.getItem(AppConstants.COUNTRY_CODE)}`,
                method: EApiMiddlewareMethods.GET,
            } as any;
            const { data } = await networkHandler(request, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchStatusForTracking = createAsyncThunk(
    "RouteTracking/FetchStatusForTracking",
    async (params, { rejectWithValue }) => {
        try {
            const request = {
                url: `route/statuses`,
                method: EApiMiddlewareMethods.GET,
            } as any;
            const { data } = await networkHandler(request, false, true);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const fetchSlotForTracking = createAsyncThunk(
    "RouteTracking/FetchSlotForTracking",
    async (
        {
            params,
        }: {
            params: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "route/loadWithConsignment/slots",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchRouteDetails = createAsyncThunk(
    "RouteTracking/FetchRouteDetails",
    async (
        {
            params
        }: {
            params: any
        },
        { rejectWithValue }
    ) => {
        try {
            const request = {
                url: "route/loadWithConsignment",
                method: EApiMiddlewareMethods.POST,
                data: params,
            } as any;
            const { data } = await networkHandler(request, false, true, false);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

const initialState: RouteTrackingState = {
    loading: false,
    routeStatus: [],
    allSlots: [],
    routesData: {
        elements: [],
        pageNumber: 0
    },
    allRouteStatus: [],
    routes: [],
    slotsList: [],
    formattedSlots: [],
};

const routeTrackingSlice = createSlice({
    name: "Tracking",
    initialState,
    reducers: {},
    extraReducers: (builders) => {
        builders
            .addCase(fetchFiltersDropdownValues.pending, (state) => {
                state.loading = true;
                state.errorCode = "";
                state.error = "";
            })
            .addCase(fetchFiltersDropdownValues.fulfilled, (state, action) => {
                const {
                    payload
                } = action;
                let hubCodeList: any = {};
                let hubNameList: any = {};
                let deliveryAreaList: any = {};
                let deliverySlotList: any = {};
                let orderTypeList: any = {};
                if (payload.hubMap) {
                    Object.keys(payload.hubMap).filter((country: any) => {
                        hubCodeList[country] = transformSelectValues(payload.hubMap[country], 'code');
                        hubNameList[country] = transformSelectValues(payload.hubMap[country], 'value');
                        return true;
                    });
                }
                if (payload.deliveryArea) {
                    Object.keys(payload.deliveryArea).filter((country: any) => {
                        deliveryAreaList[country] = transformSelectValues(payload.deliveryArea[country]);
                        return true;
                    });
                }
                if (payload.deliverySlot) {
                    Object.keys(payload.deliverySlot).filter((country: any) => {
                        deliverySlotList[country] = transformSelectValues(payload.deliverySlot[country]);
                        return true;
                    });
                }
                if (payload.orderType) {
                    Object.keys(payload.orderType).filter((country: any) => {
                        orderTypeList[country] = transformSelectValues(payload.orderType[country]);
                        return true;
                    });
                }
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                /* state.filtersDropdowns = {
                    countriesList: transformSelectValues(payload.countries),
                    hubCodeList: hubCodeList,
                    hubNameList: hubNameList,
                    deliveryAreaList: deliveryAreaList,
                    deliverySlotList: deliverySlotList,
                    orderTypeList: orderTypeList
                }; */
            })
            .addCase(fetchFiltersDropdownValues.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(fetchStatusForTracking.pending, (state) => {
                state.loading = true;
                state.errorCode = "";
                state.error = "";
            })
            .addCase(fetchStatusForTracking.fulfilled, (state, action) => {
                const { payload } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                state.routeStatus = transformSelectValues(payload.statuses);
                state.allRouteStatus = transformIntoArray(transformSelectValues(payload.statuses));
            })
            .addCase(fetchStatusForTracking.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(fetchSlotForTracking.pending, (state) => {
                state.loading = true;
                state.errorCode = "";
                state.error = "";
            })
            .addCase(fetchSlotForTracking.fulfilled, (state, action) => {
                const { payload } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                if (payload.allSlots && payload.allSlots.length) {
                    state.allSlots = transformSelectValues(payload.allSlots);
                }
            })
            .addCase(fetchSlotForTracking.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
            .addCase(fetchRouteDetails.pending, (state) => {
                state.loading = true;
                state.errorCode = "";
                state.error = "";
            })
            .addCase(fetchRouteDetails.fulfilled, (state, action) => {
                const {
                    payload,
                    meta: {
                        arg: { params }
                    }
                } = action;
                state.loading = false;
                state.errorCode = "";
                state.error = "";
                let result: any = [];
                let slotResult: any = [];
                let formattedSlotsList: any = [];
                Object.keys(payload.elements).forEach((routeKey: string) => {
                    let newResponse = {
                        ...payload.elements[routeKey].route, slotsWiseData: { ...payload.elements[routeKey].slotWiseConsignments }
                    };
                    result.push(newResponse);

                });
                payload.slotsInfo.allSlots.map((key: any) => {
                    slotResult.push(Object.values(key)[0]);
                    formattedSlotsList.push(Object.values(key)[1]);
                    return "";
                })

                state.routes = result;
                state.formattedSlots = formattedSlotsList;
                state.slotsList = slotResult;
                state.routesData = {
                    ...payload,
                    elements:
                        params.pageNumber === 0
                            ? result
                            : [...state.routesData.elements, ...result],
                    totalElements: payload.totalElements,
                    totalPages: payload.totalPages
                };
            })
            .addCase(fetchRouteDetails.rejected, (state, action) => {
                const errorPayload: any = action.payload;
                state.loading = false;
                state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
                state.error = errorPayload
                    ? errorPayload.message
                    : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
            })
    }
});


export default routeTrackingSlice.reducer;