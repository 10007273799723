import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";


//common components
import { AppState } from "../config/redux/reducers";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
//redux

//Material UI Components
import { CellParams, SortModel } from "../common/GridWrapper/DataTable";
import { Grid} from "@material-ui/core";
//styles
import { useStyles } from "./orderDataWrapper.styles";
import {deepCopy} from '../utils/helpers.utils'


//import { createChipsCountFilterPayload, createFilterPayload, expressOrdersDataTransform, getConsignmentStatusField, getOrderStatusField } from "../mocks/express/response.transforms";
interface ExpressProps {
    ordersTableWrapperData: any;
    loadTableData: (payload:any) => void;
    exportToExcelAction?:  (payload:any) => void  ;
    fetchOrderFilterChips?: (payload:any) => void ;
    ordersData?: any;
    trasformData:any;
    createFilterPayload?: any;
    fetchDynamicFormFilters?: (payload:any) => void ;
    loading?: any;
    tableLoading?: any
    customFilter?: any
    defaultFilterChip? : string
    callOnActionOnly?: boolean
    onRowSelection?: (param:any) => void 
    checkboxSelection?: boolean
    restFilterForm?:(param:boolean)=>void
    customClass?:string
    deSelectCheckbox?: boolean
    defaultSelectAll?:boolean
    handleDeselect?: ()=>void
}

const OrderDataWrapper = (props: ExpressProps) => {
const classes = useStyles();
const onLoadRef = useRef(true);
//setResetSelection to scroll on top if table reload
const [scrollToTopOnReset, setScrollToTopOnReset] = useState(false);
const {
  ordersTableWrapperData: ordersTableWrapperDatatest,
  loadTableData,
  exportToExcelAction,
  ordersData,
  trasformData,
  tableLoading,
  loading,
  customFilter,
  createFilterPayload,
  defaultFilterChip,
  onRowSelection,
  callOnActionOnly,
  checkboxSelection,
  restFilterForm,
  customClass,
  deSelectCheckbox,
  defaultSelectAll,
  handleDeselect,
} = props;
const [ordersTableWrapperData, setOrdersTableWrapperData] = useState(ordersTableWrapperDatatest)
const [ordersActiveFilters, setOrdersActiveFilters] = useState({ ...ordersTableWrapperData.defaultFiltersObj });
const [ordersListToShow, setOrdersListToShow] = useState([]);
const [ordersSearchValue, setOrdersSearchValue] = useState("");
const [isOrderSearched, setIsOrderSearched] = useState(false);
const [consignmentData, setConsignmentData] = useState({});
const [CustomFiltersApplied, setCustomFiltersApplied] = useState(false)

const { countryCode, hubCode } = useSelector(
    (state: AppState) => state.common
);

const [isTabChange, setIsTabChange] = useState(false);
const [isDisplayCount, setIsDisplayCount] = useState(false);
const [renderExcelProgress, setRenderExcelProgress] = useState(false);
const [activeOrdersFilterChip, setActiveOrdersFilterChip] = useState(defaultFilterChip);
const hubCountryCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
const handleExcelExport = useCallback(
    (filters: any) => {
        if (filters) {
            let payloadFilters = deepCopy(filters);
            payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
            const payload = createFilterPayload(payloadFilters, ordersData, false, hubCountryCode, activeOrdersFilterChip);
            exportToExcelAction && exportToExcelAction(payload)
        }
        setRenderExcelProgress(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ordersData, hubCountryCode, activeOrdersFilterChip]
)

useEffect(() => {
    const hubsToAdd = hubCode.map((hub: string) => ({
        name: hub,
        value: hub
    }));
    let updatedFilters: any = {};
   
    setActiveOrdersFilterChip(defaultFilterChip);
    setIsOrderSearched(false);
    updatedFilters = { ...ordersActiveFilters, hub: { ...ordersActiveFilters.hub, value: hubsToAdd } };
    if (!onLoadRef.current) {
        ordersFilterCallback(updatedFilters);
    }
    onLoadRef.current = false;
}, [hubCode])

useEffect(() => {
    if(customFilter && Object.keys(customFilter).length !== 0){
        let updatedFilters: any = {};
        setIsOrderSearched(false);
        updatedFilters = { ...ordersActiveFilters, ...customFilter}
        setOrdersActiveFilters(updatedFilters)
        ordersFilterCallback(updatedFilters, false, true);
        setCustomFiltersApplied(true)
    }
    onLoadRef.current = false;
}, [customFilter])

useEffect(() => {

    if(ordersData){
        setOrdersListToShow(trasformData(ordersData) as any);
        handleLoadMoreDisableState(); 
        setCustomFiltersApplied(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [ordersData]);

const handleOrdersHeaderButtonClick = useCallback(
    (btnObj: any) => {
        if (btnObj && btnObj.type) {
            switch (btnObj.type) {
                case 'excel':
                    handleExcelExport(ordersActiveFilters);
                    break;
                case 'selectedRow':
                    handleDeselect && handleDeselect();
                    break;  
                default:
                    break;
            }
        }
    },
    [ordersActiveFilters, handleExcelExport]
);

const handleLoadMoreDisableState = useCallback(
    () => {
        let loadMoreState = ordersTableWrapperData.loadMore;
        loadMoreState.disabled = ordersData.totalPages ? ordersData.pageNumber + 1 >= ordersData.totalPages : true;
        setOrdersTableWrapperData({ ...ordersTableWrapperData, loadMore: loadMoreState });
    },
    [ordersTableWrapperData, ordersData]
);


const ordersFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean, isCalledFromTest?: boolean) => {
        if (filters) {
            if (isLoadMore) {
                setScrollToTopOnReset(false);
              } else {
                setScrollToTopOnReset(true);
              }
            let payloadFilters = deepCopy(filters);
            payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
               const payload = createFilterPayload(payloadFilters, ordersData, isLoadMore, hubCountryCode);
                        let payloadFilters2 = deepCopy(filters);
            payloadFilters2.pageSize = ordersTableWrapperData.loadMore.pageSize;
           loadTableData(payload)
            setOrdersActiveFilters(filters);
        }
        setRenderExcelProgress(false);
    },
    [ordersData, ordersTableWrapperData, activeOrdersFilterChip, hubCountryCode],
);

const ordersSearchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    const activeFilters = deepCopy(filters);
    onLoadRef.current = false;
    setOrdersSearchValue(updatedSearchValue);
    setIsOrderSearched(false);
    if (updatedSearchValue) {
        setIsOrderSearched(true);
        for (const searchKey in data) {
            if (data[searchKey]) {
                activeFilters.searchAttribute = {
                    key: searchKey,
                    value: data[searchKey]
                }
                ordersFilterCallback(activeFilters, true);
                return;
            }
        }
    }
};
const handleConsignmentCellClick = useCallback(
    (params: CellParams) => {
        if (params.field === 'consignmentId') {
            const selectedRow: any = params.rowData;
            const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
            setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
        }
        if (params.field === 'actions') {
            const selectedRow: any = params.rowData;
            if (selectedRow.actions !== null) {
                const selectedConsignment = ordersData.elements.filter((item: any) => item.consignmentCode === selectedRow.consignmentId);
                setConsignmentData(selectedConsignment.length ? selectedConsignment[0] : {});
            }
        }
    },
    [ordersData]
);

  const updateSelectedRoutes = useCallback(
    (params: any) => {
        onRowSelection && onRowSelection(params.rows)
    },
    []
  );
    return (
        <div className={classes.root}>
            {loading && <Loader></Loader>}
                        <Grid className={classes.container} container>
                <Grid className={`${customClass && customClass} ${"planningItem content"}`} item>
                    <Grid container className={classes.tabPanelContainer}>
                        <Grid item className="panelItem gridWrapper">
                            <GridWrapper
                                filterForm={ordersTableWrapperData?.FilterFormData}
                                defaultFormFilter={ordersTableWrapperData?.defaultFiltersObjForm}
                                showHeader={true}
                                checkboxSelection={checkboxSelection ? checkboxSelection : false}
                                headerData={ordersTableWrapperData.HeaderData}
                                rowData={ordersListToShow}
                                isTabChange={isTabChange}
                                loading={loading ? false : tableLoading}
                                searchTypes={ordersTableWrapperData.SEARCH_TYPES}
                                defaultFilter={ordersTableWrapperData.defaultFiltersObj}
                                activeFilter={ordersActiveFilters}
                                headerButtons={ordersTableWrapperData.headerButtons}
                                title={ordersTableWrapperData.title}
                                disableSelectionOnClick={true}
                                sortModel={ordersTableWrapperData.sortModel as SortModel}
                                searchValue={ordersSearchValue}
                                loadMore={{ ...ordersTableWrapperData.loadMore, rowCount: ordersData?.totalElements || 0 }}
                                headerBtnClick={handleOrdersHeaderButtonClick}
                                searchCallback={ordersSearchFilterHandler}
                                filterGrid={ordersFilterCallback}
                                callOnActionOnly ={callOnActionOnly}
                                onCellClick={handleConsignmentCellClick}
                                scrollToTopOnReset={scrollToTopOnReset}
                                onSelectionChange={updateSelectedRoutes}
                                restFilterForm={restFilterForm}
                                deSelectCheckbox={deSelectCheckbox}
                                defaultSelectAll={defaultSelectAll}
                                CustomFiltersApplied={CustomFiltersApplied}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </div >
    );
}

export default OrderDataWrapper;