import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { isExisty } from "utils/helpers.utils";
import { useStyles } from "./Bundleproduct.styles";
import BundleProductsHeader from "./BundleProducHeader";
import BundleProductRow from "./BundleProductRow";
import BundleProductsHeaderEdit from "./BundleProductHeaderEdit";
import SelectBox from "../common/SelectBox";

export interface ReasonProps {
  reasonCode: string;
  reasonDescription: string;
}
interface BundleConstituent {
  bundleProductName: string;
  bundleProductQuantity: number;
  bundleProductShippedQty: number;
  bundleProductRejectedQty: number;
}
interface BundleProductsProps {
  bundle: {
    campaignId: string;
    bundleName: string;
    bundleShippedQty: number;
    bundleProductQuantity: number;
    bundlePrice: number;
    bundleCurrency: string;
    bundleRejectedQty: number;
    bundleConstituents: BundleConstituent[];
    reason?: ReasonProps;
    showReason?: boolean;
    error?: boolean;
    itemDeliveryReason?: any;
  };
  bundleCurrency: string;
  editMode?: boolean;
  rejectionReasonList?: any;
  handleUpdatedRejectedQty?: (
    params: any,
    param1: any,
    param3?: boolean
  ) => void;
  handleBundleReasonChange?:(param1:any, param2:any)=> void
}

const BundleProducts = (props: BundleProductsProps) => {
  const classes = useStyles();
  const {
    bundle,
    bundle: {
      campaignId,
      bundleConstituents,
      reason,
      showReason,
      error,
      itemDeliveryReason,
      bundleShippedQty,
    },
    bundleCurrency,
    editMode,
    rejectionReasonList,
    handleUpdatedRejectedQty,
    handleBundleReasonChange,
  } = props;
  const [rejectedQty, setRejectQty] = useState(0);

  const rejectBundle = (e: any, campaignId: string, isBundleProduct: any) => {
    setRejectQty(e);
    handleUpdatedRejectedQty &&
      handleUpdatedRejectedQty(e, campaignId, isBundleProduct);
  };

  return (
    <>
      {/* {getItemsDetail(bundleProduct)} */}
      <Grid className={`item ${classes.bundleProduct}`} item>
        {!editMode && (
          <BundleProductsHeader
            bundle={bundle}
            bundleCurrency={bundleCurrency}
          />
        )}
        {editMode && (
          <BundleProductsHeaderEdit
            bundle={bundle}
            rejectionReasonList={rejectionReasonList}
            rejectBundle={rejectBundle}
            bundleCurrency={bundleCurrency}
          />
        )}
        <Grid className="bundleRows">
          {bundleConstituents.map((item, index) => (
            <BundleProductRow
              bundleItem={item}
              bundleShippedQty={bundleShippedQty}
              key={index}
              rejectedQty={editMode && rejectedQty}
            />
          ))}
        </Grid>

        {!editMode && isExisty(reason?.reasonCode) && (
          <Grid className={`${classes.rejectedReason}`} xs={4}>
            <Typography>{`Rejection Reason - ${reason?.reasonDescription}`}</Typography>
          </Grid>
        )}
        <>
          {showReason ? (
            <Grid item xs={12}>
              <Grid container>
                <Grid item className={classes.showRejectionReason}>
                  <Typography>Rejection Reason : </Typography>
                </Grid>
                <Grid item>
                  <SelectBox
                    variant="standard"
                    disableUnderline
                    value={
                      itemDeliveryReason.length && itemDeliveryReason?.[0].value
                    }
                    id="reason"
                    inputProps={{
                      name: "reason",
                      id: "reason",
                    }}
                    classes={{
                      root: classes.selectRoot,
                      icon: classes.icon,
                    }}
                    items={rejectionReasonList || []}
                    handleChange={(e) => handleBundleReasonChange && handleBundleReasonChange(e, campaignId)}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {showReason && error ? (
            <Grid className={classes.showError}>
              <Typography style={{ color: "red", fontSize: "10px" }}>
                Please select reason
              </Typography>
            </Grid>
          ) : null}
        </>
      </Grid>
    </>
  );
};

export default BundleProducts;
