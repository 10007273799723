import { makeStyles } from "@material-ui/core";

const searchableDropdownStyles = makeStyles(() => ({
  fieldLabel: { marginBottom: "8px", fontSize: "12px" },
  " & .MuiFormLabel-root": {
    fontSize: "12px",
  },

  searchableDropdown: {
    margin: 0,
    padding: 0,
    minWidth: "180px",
    "& .Mui-focused": {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: "500",
    },
    "& .MuiInputBase-input ": {
      marginLeft: "4px",
    },
  },
  paperStyles: {
    margin: 0,
    padding: 0,
    borderRadius: "inherit",
    fontSize: "12px",
  },
  downArrowStyle: {
    marginLeft: "6px",
    marginRight: "10px",
  },

  labelOnInputBoxFontStyle: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.54)",
    paddingLeft: "2px",
    "& .MuiFormLabel-root": {
      fontWeight: "500",
      fontSize: "12px !important",
      lineHeight: "8px",
      color: "rgba(0, 0, 0, 0.54)",
    },

    "& .MuiInputLabel-shrink": {
      fontSize: "12px !important",
    },
  },
  labelHeaderOnInputBoxFontStyle: {
    fontSize: "12px",
  },
  autocompleteInputDivStyle: {
    fontSize: "12px",
  },
  isDropdownOpen: {
    fontSize: "12px",
  },
  clearIndicatorStyle: {
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem !important",
    },
  },
  popupIndicatorOpenStyle: {
    fontSize: "1rem !important",
  },
  popupIndicatorStyle: {
    height: "28px",
    marginRight: "10px",
  },
  inputFocusedStyle: {
    paddingTop: "8px",
  },
}));

export default searchableDropdownStyles;
