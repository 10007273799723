import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../config/redux/reducers";
import { fetchHubDetails, createNewHub, fetchAddHubCountryDropdownValues, updateHubDetails, resetHubCreationStatus, resetHubUpdationStatus } from "./redux/hubSlice";
import { hubsStyles } from "./hubs.styles";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import _ from "lodash";
import { Grid, Typography, SvgIcon } from "@material-ui/core";
import { CellParams, RowData } from "../common/GridWrapper/DataTable";
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import {
    lastMileHubDataTransform,
    createHubDetailsPayload,
    createAddHubPayload,
    createEditHubPayload
} from "../mocks/hubs/response.transforms";
import HubAddOrEditDetails from "./HubAddOrEditDetails";
import CustomSnackbar from "../common/CustomSnackbar";
import { userLogout } from "../Login/redux/loginSlice";
import HubDetails from "./HubDetails";

const Hubs = () => {
    const componentClasses = hubsStyles();
    const dispatch = useDispatch();
    const { countryCode } = useSelector(
        (state: AppState) => state.common
    );
    const { hubsData, loading, tableLoading, error, errorCode, hubCreationMsg, hubCreationStatus, hubUpdateStatus, hubUpdateMsg } = useSelector(
        (state: AppState) => state.hub
    );
    const {
      isCollectionPointManager,
      isVTManager,
      isCHDManager,
      isDispatcher,
      isOnlyStandardDispatcher,
      isLastMileViewer,
      isOnlyStdLastMileViewer,
    } = useSelector((state: AppState) => state.userLogin);

    const [hubsListToShow, setHubsListToShow] = useState([]);
    const [userSearchValue, setUserSearchValue] = useState("");
    const [tableWrapperData, setTableWrapperData] = useState({
        ...AppConstants.HUB_CONSTANTS.TABLE_WRAPPER_DATA, HeaderData: AppConstants.HUB_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
            let newHeader = { ...header };
            if (newHeader.field === 'hubId') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="hubIdLink">{params.value}</Typography>
                )
            }
            if (newHeader.field === 'actions') {
                newHeader.renderCell = (params: CellParams) => (
                    <Grid container className={componentClasses.actionsHeader}>
                        <Grid className="actionItem" item>
                            <SvgIcon className="icon editIcon" onClick={handleEditHubDetailsIconClick(params)} component={editIcon} />
                        </Grid>
                    </Grid>
                );
            }
            if (newHeader.field === 'serviceType') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="serviceType">{params && params.value && params.value.join(" , ")}</Typography>
                );
            }
            if (newHeader.field === 'category') {
                newHeader.renderCell = (params: CellParams) => (
                    <Typography className="serviceType">{params && params.value && params.value.join(" , ")}</Typography>
                );
            }
            return newHeader;
        })
    });
    const [activeFilters, setActiveFilters] = useState({ ...tableWrapperData.defaultFiltersObj });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
    const snackbarMessage = useRef("");

    const [isEdit, setIsEdit] = useState(false);
    const [openHubAddPopup, setOpenHubAddPopup] = useState(false);

    const [hubData, setHubData] = useState({});
    const [openHubDetailsPopup, setOpenHubDetailsPopup] = useState(false);
    const handleSnackbarClose = useCallback(
        () => {
            setOpenSnackbar(false);
        },
        [],
    );

    const openSnackbarPopup = (msg: string, type: string) => {
        snackbarMessage.current = msg;
        snackbarType.current = type;
        setOpenSnackbar(true);
    }

    const setHubDataOnClick = useCallback(
        (rowData: RowData) => {
            let selectedRow: RowData = { ...rowData };
            const selectedHub: any = { ...rowData, tableRecord: selectedRow }
            setHubData(selectedHub);
        },
        []
    )

    const handleHubCellClick = useCallback(
        (params: CellParams) => {
            if (params.field === 'hubId') {
                setHubDataOnClick(params.rowData ? params.rowData : {} as RowData);
                setOpenHubDetailsPopup(true);
            }
        },
        [setHubDataOnClick],
    );

    const handleEditHubDetailsIconClick = (params: CellParams) => () => {
        setHubDataOnClick(params.rowData ? params.rowData : {} as RowData);
        setIsEdit(true);
        setOpenHubAddPopup(true);
    }
    const closeHubDetailsPopup = () => {
        setOpenHubDetailsPopup(false);
    }
    const handleHubDetailsActions = useCallback(
        (type: string) => {
            if (type === 'update') {
                closeHubDetailsPopup();
                openHubUpdatePopup(true);
            }
        },
        [],
    )

    const handleLoadMoreDisableState = useCallback(
        () => {
            let loadMoreState = tableWrapperData.loadMore;
            loadMoreState.disabled = hubsData.totalPages ? hubsData.pageNumber + 1 >= hubsData.totalPages : true;
            setTableWrapperData({ ...tableWrapperData, loadMore: loadMoreState });
        },
        [tableWrapperData, hubsData]
    );

    const filterCallback = (filters: any, isLoadMore?: boolean) => {
        if (filters) {
            let payloadFilters = _.cloneDeep(filters);
            payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
            payloadFilters.pageSize = tableWrapperData.loadMore.pageSize;
            payloadFilters.pageNumber = 0;
            const payload = createHubDetailsPayload(payloadFilters, hubsData, isLoadMore);
            dispatch(
                fetchHubDetails({
                    params: payload
                })
            );
            setActiveFilters(filters);
        }
    }

    const fetchAllFilters = useCallback(
        () => {
            dispatch(
                fetchAddHubCountryDropdownValues()
            );
        },
        [dispatch]
    );

    const handleAddHub = useCallback(
        (params: any) => {
            const payload = createAddHubPayload(params);
            dispatch(
                createNewHub({
                    params: payload
                })
            );
        },
        [dispatch]
    )

    const updateHubdata = useCallback(
        (params: any) => {
            const payload = createEditHubPayload(params);
            dispatch(
                updateHubDetails({
                    params: payload
                })
            );
        },
        [dispatch],
    )

    const handleHubUpdateActions = useCallback(
        (params: any) => {
            if (params.type === 'submit') {
                if (params.isEdit) {
                    updateHubdata(params.values);
                } else {
                    handleAddHub(params.values);
                }
            } else {
                closeHubAddPopup();
            }
        },
        [handleAddHub, updateHubdata],
    )

    const closeHubAddPopup = () => {
        setOpenHubAddPopup(false);
    }

    const searchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
        const searchKeys = Object.keys(data);
        setUserSearchValue(updatedSearchValue);
        if (updatedSearchValue) {
            for (const searchKey of searchKeys) {
                data[searchKey] = _.trim(data[searchKey]);
                let newDataValue = data[searchKey];
                filters[searchKey].value = [newDataValue];
                filters.pageNumber = 0;
                if (data[searchKey] && data[searchKey].length >= AppConstants.SEARCH_BOX_LIMIT) {
                    let searchPayload = _.cloneDeep(filters);
                    filterCallback(searchPayload[searchKey]);
                    return;
                } else {
                    openSnackbarPopup(AppConstants.SEARCH_TEXT_LIMIT, AppConstants.SNACKBAR.TYPES.ERROR);
                    return;
                }
            }
        }
    };

    const openHubUpdatePopup = (isEdit: boolean) => {
        setIsEdit(isEdit);
        setOpenHubAddPopup(true);
    }

    const handleHeaderButtonClick = useCallback(
        (btnObj: any) => {
            if (btnObj && btnObj.type) {
                switch (btnObj.type) {
                    case 'addHub':
                        openHubUpdatePopup(false);
                        break;
                    default:
                        break;
                }
            }
        },
        []
    );

    const handleSessionExpired = useCallback(
        () => {
            if (errorCode === AppConstants.USERS_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
                dispatch(
                    userLogout()
                );
            }
        },
        [dispatch, errorCode],
    );

    const handleSnackbarOnExited = useCallback(
        () => {
            handleSessionExpired();
            if (snackbarType.current === AppConstants.SNACKBAR.TYPES.SUCCESS) {
                if (hubCreationStatus) {
                    dispatch(
                        resetHubCreationStatus()
                    )
                }
                if (hubUpdateStatus) {
                    dispatch(
                        resetHubUpdationStatus()
                    )
                }
            }
        },
        [dispatch, handleSessionExpired, hubCreationStatus, hubUpdateStatus],
    );

    const isBtnHidden = useCallback(() => {
      return (
        isDispatcher ||
        isCHDManager ||
        isVTManager ||
        isCollectionPointManager ||
        isOnlyStandardDispatcher ||
        isLastMileViewer ||
        isOnlyStdLastMileViewer
      );
    }, [
      isDispatcher,
      isCHDManager,
      isVTManager,
      isCollectionPointManager,
      isOnlyStandardDispatcher,
      isLastMileViewer,
      isOnlyStdLastMileViewer,
    ]);

    const handleHeaderButtonsDisableState = useCallback(
        () => {
            let headerButtons = tableWrapperData.headerButtons.map((btn: any) => {
                switch (btn.name) {
                    case 'addHub':
                        if (isBtnHidden()) {
                            btn.hide = true;
                        } else {
                            btn.hide = false;
                        }
                        break;
                    default:
                        break;
                }
                return btn;
            });
            setTableWrapperData({ ...tableWrapperData, headerButtons: headerButtons });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tableWrapperData]
    );

    useEffect(() => {
        if (error) {
            openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
        }
    }, [error])

    useEffect(() => {
        handleHeaderButtonsDisableState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubsListToShow]);

    useEffect(() => {
        if (hubCreationStatus) {
            closeHubAddPopup();
            openSnackbarPopup(hubCreationMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
            filterCallback(tableWrapperData.defaultFiltersObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubCreationStatus, hubCreationMsg])

    useEffect(() => {
        if (hubUpdateStatus) {
            closeHubDetailsPopup();
            closeHubAddPopup();
            openSnackbarPopup(hubUpdateMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
            filterCallback(tableWrapperData.defaultFiltersObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubUpdateStatus])

    useEffect(() => {
        setHubsListToShow(lastMileHubDataTransform(hubsData, countryCode) as any);
        handleLoadMoreDisableState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubsData]);

    useEffect(() => {
        if (isBtnHidden()) {
            let headerDataWithoutActions = tableWrapperData.HeaderData.map((data: any) => {
                if (data.field === "actions") {
                    data.hide = true;
                }
                return data;
            });
            setTableWrapperData({ ...tableWrapperData, HeaderData: headerDataWithoutActions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBtnHidden])

    useEffect(() => {
        if (countryCode) {
            filterCallback(tableWrapperData.defaultFiltersObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode])


    return (
        <div className={componentClasses.root}>
            {loading && <Loader></Loader>}
            <Grid className={componentClasses.container} container>
                <Grid className="userItem content" item>
                    <GridWrapper
                        showHeader={false}
                        checkboxSelection={false}
                        headerData={tableWrapperData.HeaderData}
                        rowData={hubsListToShow}
                        activeFilter={activeFilters}
                        loading={loading ? false : tableLoading}
                        searchTypes={tableWrapperData.SEARCH_TYPES}
                        defaultFilter={tableWrapperData.defaultFiltersObj}
                        headerButtons={tableWrapperData.headerButtons}
                        title={tableWrapperData.title}
                        disableSelectionOnClick={true}
                        searchValue={userSearchValue}
                        loadMore={{ ...tableWrapperData.loadMore, rowCount: hubsData.totalElements || 0 }}
                        searchCallback={searchFilterHandler}
                        filterGrid={filterCallback}
                        headerBtnClick={handleHeaderButtonClick}
                        onCellClick={handleHubCellClick}
                        fetchAllFilters={fetchAllFilters}
                    />
                </Grid>
                <HubDetails open={openHubDetailsPopup} hub={hubData} handleDialogAction={handleHubDetailsActions} closePopup={closeHubDetailsPopup}></HubDetails>
                <HubAddOrEditDetails open={openHubAddPopup} user={hubData} isEdit={isEdit} handleDialogAction={handleHubUpdateActions} closePopup={closeHubAddPopup}></HubAddOrEditDetails>
                <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarOnExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
            </Grid>
        </div >
    );
};

export default Hubs;
