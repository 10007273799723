import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: "100%",
      overflow: "scroll",
      marginLeft: 12,
    },
    success: {
      background: "rgba(0,150,136,0.05)",
      border: 1,
      borderColor: "#96B5D4",
      color: "#009688",
    },
    error: {
      background: "rgba(238,37,39,0.05)",
      border: 1,
      borderColor: "#F5C7C8",
      color: "#EE2527",
    },
    warning: {
      background: "rgba(167,95,1,.05)",
      color: "#a75f01",
    },
    Container: {
      alignItems: "center",
      padding: "4px 8px",
      gap: "16px",
    },
    closeIcon: {
      marginLeft: "auto",
    },
  })
);
