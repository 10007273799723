import produce from "immer";

import AppConstants from "../../../constants";

import {
  ALL_ROUTES_TEXT_CONTANT,
  ALL_ROUTE_INITIAL_STATE_INTERFACE,
  OrderInterface,
  PlannedConsignmentsDataInterface,
  ROUTE_TYPE,
  SNACKBAR_MESSAGES,
  STATUSES,
  UnplannedConsignmentsDataInterface,
} from "./AllRouteConstant";
import {
  countObjectProperties,
  getSlotUIValue,
  isAString,
  isNonEmptyArray,
  isNonEmptyObject,
  isNull,
  isObject,
  isUndefined,
  isInputAnArray,
  isArrayNonEmpty,
  loadDashIsArrayAndNotEmpty,
} from "../../../utils/helpers.utils";
import { getStatusWithUnderScore } from "../../../mocks/delve/response.transforms";
import { COLORS } from "../../../config/theme/baseTheme";
import { transformPlannedRoute } from "../../../mocks/allRoutes/response.transforms";

export const isSubRoute = (areaObj: any) => areaObj?.routeType === ROUTE_TYPE.SUB_ROUTE;
export const isDriverAssigned = (routeObj: any) => routeObj?.assignee?.id !== undefined;

export const getSlotName = (slotName: any) => {
  const firstDelSlot = slotName.split("-");
  const slotValue = getSlotUIValue(firstDelSlot);
  if (slotValue && slotValue.startTime && slotValue.endTime) {
    return `${slotValue.startTime} - ${slotValue.endTime}`;
  }
  return slotName;
};

export const getAreaTitle = (routeObj: any) => {
  let title = "";
  if (routeObj.deliveryArea && Array.isArray(routeObj.deliveryArea) && routeObj.deliveryArea.length > 0) {
    title = routeObj.deliveryArea[0];
    if (title.length > 13) {
      title = `${routeObj.deliveryArea[0].substring(0, 10)}...`;
    }
    if (routeObj.deliveryArea.length > 1) {
      title = `${title}, +${routeObj.deliveryArea.length - 1}`;
    }
  }
  return title;
};

export const getDeliverySlotTitle = (obj: any) => {
  let title = "";
  if (obj && obj.slotWiseOrders && isObject(obj.slotWiseOrders) && isNonEmptyObject(obj.slotWiseOrders)) {
    if (countObjectProperties(obj.slotWiseOrders) === 1) {
      title = getSlotName(Object.keys(obj.slotWiseOrders)[0]);
    } else {
      title = `${getSlotName(Object.keys(obj.slotWiseOrders)[0])}, +${countObjectProperties(obj.slotWiseOrders) - 1}`;
    }
  }
  return title;
};

export const getAllAreaTitle = (deliveryArea: any) => {
  let title = "";
  if (!isUndefined(deliveryArea) && Array.isArray(deliveryArea) && deliveryArea.length > 0) {
    title = deliveryArea.join(", ");
  }
  return title;
};

export const getVerificationStatusField = (fieldName: string, statusCode: string) => {
  let statusCodeCopy = "" as any;
  if (!isUndefined(statusCode) && isAString(statusCode)) {
    statusCodeCopy = getStatusWithUnderScore(statusCode);
    const statuses: any = STATUSES;
    const newStatus = statusCodeCopy.toUpperCase();
    return statusCode && newStatus && fieldName && statuses[newStatus] && statuses[newStatus][fieldName] ? statuses[newStatus][fieldName] : "";
  }
  return "";
};

export const getColorBasedOnRouteIndex = (colorIndex: number) => {
  if (COLORS && colorIndex >= 0 && Object.keys(COLORS).length >= colorIndex) {
    const name: keyof typeof COLORS = Object.keys(COLORS)[colorIndex] as any;
    return COLORS[name];
  }
  return "";
};

export const designMapIcon = (color: any) => {
  var template = [
    '<?xml version="1.0"?>',
    '<svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">',
    '<rect width="24" height="24" rx="4" fill="{{ color }}"/>',
    '<path d="M12.3077 28L8 24C10.0923 24 12.6769 24 16 24L12.3077 28Z" fill="{{ color }}"/>',
    "</svg>",
  ].join("\n");
  var svg = template.replaceAll("{{ color }}", color);
  var blob = new Blob([svg], { type: "image/svg+xml" });
  var blobUrl = URL.createObjectURL(blob);

  return {
    url: blobUrl,
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: color,
    scale: 1,
    labelOrigin: { x: 11, y: 12 },
  };
};

export const designSubRouteMapIcon = (color: any) => {
  var template = [
    '<?xml version="1.0"?>',
    '<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">',
    '<rect width="24" height="24" rx="12" fill="{{ color }}"/>',
    '<path d="M12.3077 26L8 22C10.0923 22 12.6769 22 16 22L12.3077 26Z" fill="{{ color }}"/>',
    "</svg>",
  ].join("\n");
  var svg = template.replaceAll("{{ color }}", color);
  var blob = new Blob([svg], { type: "image/svg+xml" });
  var blobUrl = URL.createObjectURL(blob);

  return {
    url: blobUrl,
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: color,
    scale: 1,
    labelOrigin: { x: 12, y: 12 },
  };
};

export const designMapLabel = (orderIndex: number) => {
  return { text: JSON.stringify(orderIndex + 1), fontSize: "12px", fontWeight: "400", color: COLORS.WHITE, lineHeight: "0px" };
};

export const designSubrouteMapLabel = (orderIndex: number, routeId: string) => {
  return { text: `${JSON.stringify(orderIndex + 1)}`, fontSize: "12px", fontWeight: "400", color: COLORS.WHITE, lineHeight: "0px" };
};

export const resetRouteDetailsCount = (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  draft.totalRecords = 0;
  draft.totalOrders = 0;
  draft.totalCrates = 0;
  return draft;
};

export const resetPlannedRouteDraft = (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  draft.consignmentMarkers = [];
  draft.consignmentMarkersForMap = [];
  draft.firstMapCordinates = { lat: 0, lng: 0 };
  draft.mapCenter = { lat: 0, lng: 0 };
  draft.mapZoom = 12;
  draft.mapPolylineMarker = [];
  draft.polylineColor = "";

  draft.plannedConsignmentsData = {};
  draft.selectedRoute = {};
  draft.selectedConsignments = [];
  draft.totalRecords = 0;
  draft.totalOrders = 0;
  draft.totalCrates = 0;

  draft.selectedRouteObj = {}; // Add Remove Order from Route
  draft.orderDataObj = {};
  draft.openAddRouteConfirmDialog = false;
  draft.openRemoveRouteConfirmDialog = false;

  draft.consignmentId = "";

  draft.selectedConsignmentsForSubRoute = [];
  draft.openCreateSubRouteDialog = false;

  draft.selectedRouteDeletion = {};
  draft.openDeleteSubRouteDialog = false;

  draft.selectedSubRoute = {};
  draft.openSubRouteOrderActionPopUp = false;

  draft.openAssignRoutePopUp = false;
  return draft;
};

export const designAllRoutesStateObjectUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, plannedRoutes: any, hubId?: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.loading = false;
    resetPlannedRouteDraft(draft);

    let { routeDetailsObj, totalRecords, totalCrates, totalOrders, firstMapCordinates, mapCenter, consignmentMarkers } = transformPlannedRoute(plannedRoutes);

    draft.plannedConsignmentsData = routeDetailsObj;
    draft.totalRecords = totalRecords;
    draft.totalOrders = totalOrders;
    draft.totalCrates = totalCrates;
    draft.firstMapCordinates = firstMapCordinates;
    draft.mapCenter = mapCenter;
    draft.consignmentMarkers = [...consignmentMarkers];
    draft.consignmentMarkersForMap = [...consignmentMarkers];
  });
  return newAllRouteState;
};

export const handleRouteAccordianClickedUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: any, vehiclesList: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (!isUndefined(draft) && !isUndefined(draft.plannedConsignmentsData) && !isUndefined(draft.plannedConsignmentsData[routeName])) {
      draft.expandAll = false;

      draft.driverValue = AppConstants.SELECT_NONE.value;
      draft.helperValue = AppConstants.SELECT_NONE.value;
      draft.vehicleValue = AppConstants.SELECT_NONE.value;
      draft.subDriverValue = AppConstants.SELECT_NONE.value;
      draft.subHelperValue = AppConstants.SELECT_NONE.value;
      draft.subVehicleValue = AppConstants.SELECT_NONE.value;

      draft.selectedRoute = {};
      draft.selectedSubRoute = {};
      draft.selectedConsignments = [];

      draft.consignmentMarkersForMap = [...draft.consignmentMarkers];
      draft.mapZoom = 12;
      draft.mapCenter = draft.firstMapCordinates;
      draft.mapPolylineMarker = [];
      draft.polylineColor = "";

      Object.keys(draft.plannedConsignmentsData).forEach((route: any) => {
        draft.plannedConsignmentsData[route].openOrderActionPopUp = false;
        if (route === routeName) {
          draft.plannedConsignmentsData[route].isOpened = !draft.plannedConsignmentsData[route].isOpened;
          if (draft.plannedConsignmentsData[route].isOpened) {
            draft.expandAll = true;
            draft.plannedConsignmentsData[route].openOrderActionPopUp = true;
            Object.keys(draft.plannedConsignmentsData[route]?.routeDataObj).forEach((routeName: string) => {
              Object.keys(draft.plannedConsignmentsData[route]?.routeDataObj[routeName]?.slotWiseOrders).forEach((slotName: string) => {
                draft.plannedConsignmentsData[route].routeDataObj[routeName].slotWiseOrders[slotName].isOpened = true;
                draft.plannedConsignmentsData[route].routeDataObj[routeName].slotWiseOrders[slotName].orders = draft.plannedConsignmentsData[route].routeDataObj[routeName].slotWiseOrders[
                  slotName
                ].orders.map((order: any) => ({ ...order, isOpened: false }));
              });
            });

            if (!draft.openUpdateConfirmDialog) {
              if (draft.plannedConsignmentsData[route].openOrderActionPopUp) {
                Object.keys(draft.plannedConsignmentsData).forEach((route: any) => {
                  draft.plannedConsignmentsData[route].isChecked = false;
                });

                if (draft.plannedConsignmentsData[route]?.mapCenter && draft.plannedConsignmentsData[route]?.mapCenter?.lat && draft.plannedConsignmentsData[route]?.mapCenter?.lat !== 0) {
                  draft.mapCenter = draft.plannedConsignmentsData[route].mapCenter;
                }

                draft.selectedRoute = JSON.parse(JSON.stringify(draft.plannedConsignmentsData[route]));
                delete draft.selectedRoute.subRoutes;

                if (draft?.selectedRoute?.assignedVehicle?.id !== undefined) {
                  draft.vehicleValue = draft.selectedRoute.assignedVehicle.id;
                } else if (vehiclesList?.length && vehiclesList.length === 1) {
                  draft.vehicleValue = vehiclesList[0].value;
                }
                if (draft?.selectedRoute?.assignee?.id !== undefined) {
                  draft.driverValue = draft.selectedRoute.assignee.id;
                }
                if (draft?.selectedRoute?.helper?.id !== undefined) {
                  draft.helperValue = draft.selectedRoute.helper.id;
                }
              }
            }
            // Make a Polynine
            if (!isUndefined(draft.consignmentMarkers) && isArrayNonEmpty(draft.consignmentMarkers)) {
              const markerWithCurrentRoute = draft.consignmentMarkers.filter((x: any) => x?.routeId === route || x?.parentRouteName === route);
              if (!isUndefined(markerWithCurrentRoute) && isArrayNonEmpty(markerWithCurrentRoute)) {
                draft.consignmentMarkersForMap = [...markerWithCurrentRoute];
                draft.mapPolylineMarker = markerWithCurrentRoute.map((marker: any) => marker?.location);
                draft.polylineColor = markerWithCurrentRoute[0].color;
              }
            }
          }
        } else {
          draft.plannedConsignmentsData[route].isOpened = false;
          Object.keys(draft.plannedConsignmentsData[route]?.routeDataObj).forEach((routeName: string) => {
            Object.keys(draft.plannedConsignmentsData[route]?.routeDataObj[routeName]?.slotWiseOrders).forEach((slotName: string) => {
              draft.plannedConsignmentsData[route].routeDataObj[routeName].slotWiseOrders[slotName].isOpened = false;
              draft.plannedConsignmentsData[route].routeDataObj[routeName].slotWiseOrders[slotName].orders = draft.plannedConsignmentsData[route].routeDataObj[routeName].slotWiseOrders[
                slotName
              ].orders.map((order: any) => ({ ...order, isOpened: false }));
            });
          });
        }
      });
    }
    draft.loading = false;
  });
  return newAllRouteState;
};

export const handleRouteSlotAccordianToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: string, routeDataName: string, slotName: string) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (
      !isUndefined(draft) &&
      !isUndefined(draft.plannedConsignmentsData) &&
      !isUndefined(draft.plannedConsignmentsData[routeName]) &&
      !isUndefined(draft.plannedConsignmentsData[routeName].routeDataObj) &&
      !isUndefined(draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName]) &&
      !isUndefined(draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders) &&
      !isUndefined(draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName])
    ) {
      const value: boolean = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isOpened;
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isOpened = !value;
    }
    draft.loading = false;
  });
  return newAllRouteState;
};

export const handleRoutAreaAccordianToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: string, routeDataName: string, vehiclesList: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (
      !isUndefined(draft) &&
      !isUndefined(draft.plannedConsignmentsData) &&
      !isUndefined(draft.plannedConsignmentsData[routeName]) &&
      !isUndefined(draft.plannedConsignmentsData[routeName].routeDataObj) &&
      !isUndefined(draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName])
    ) {
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isOpened = !draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isOpened;

      draft.subDriverValue = AppConstants.SELECT_NONE.value;
      draft.subHelperValue = AppConstants.SELECT_NONE.value;
      draft.subVehicleValue = AppConstants.SELECT_NONE.value;

      draft.selectedSubRoute = {};
      if (draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isOpened && draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].routeType === ROUTE_TYPE.SUB_ROUTE) {
        draft.selectedSubRoute = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName];

        if (draft?.selectedSubRoute?.assignedVehicle?.id !== undefined) {
          draft.subVehicleValue = draft.selectedSubRoute.assignedVehicle.id;
        } else if (vehiclesList && vehiclesList?.length && vehiclesList.length === 1) {
          draft.subVehicleValue = vehiclesList[0].value;
        }
        if (draft?.selectedSubRoute?.assignee?.id !== undefined) {
          draft.subDriverValue = draft.selectedSubRoute.assignee.id;
        }
        if (draft?.selectedSubRoute?.helper?.id !== undefined) {
          draft.subHelperValue = draft.selectedSubRoute.helper.id;
        }
      }

      if (!isUndefined(draft.consignmentMarkers) && isArrayNonEmpty(draft.consignmentMarkers)) {
        let markerWithCurrentRoute = [];
        if (draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isOpened) {
          if (draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName]?.routeType === ROUTE_TYPE.SUB_ROUTE) {
            markerWithCurrentRoute = draft.consignmentMarkers.filter((x: any) => x?.routeId === routeDataName);
          } else {
            markerWithCurrentRoute = draft.consignmentMarkers.filter((x: any) => x?.routeId === routeName && x?.deliveryArea === routeDataName);
          }
        } else {
          markerWithCurrentRoute = draft.consignmentMarkers.filter((x: any) => x?.routeId === routeName || x?.parentRouteName === routeName);
        }

        if (!isUndefined(markerWithCurrentRoute) && isArrayNonEmpty(markerWithCurrentRoute)) {
          draft.consignmentMarkersForMap = [...markerWithCurrentRoute];
          draft.mapPolylineMarker = markerWithCurrentRoute.map((marker: any) => marker?.location);
          draft.polylineColor = markerWithCurrentRoute[0].color;
        }
      }
    }
    draft.loading = false;
  });
  return newAllRouteState;
};

export const handleToggleCreateSubrouteUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: string) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (!isUndefined(draft) && !isUndefined(draft.plannedConsignmentsData) && !isUndefined(draft.plannedConsignmentsData[routeName])) {
      draft.plannedConsignmentsData[routeName].subRouteCreated = !draft.plannedConsignmentsData[routeName].subRouteCreated;
      draft.selectedRoute.subRouteCreated = draft.plannedConsignmentsData[routeName].subRouteCreated;
    }
    draft.loading = false;
  });
  return newAllRouteState;
};

export const handleRouteCheckboxToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: any, checked: any, vehiclesList?: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (!isUndefined(draft) && !isUndefined(draft.plannedConsignmentsData) && !isUndefined(draft.plannedConsignmentsData[routeName])) {
      draft.plannedConsignmentsData[routeName].isChecked = !draft.plannedConsignmentsData[routeName].isChecked;
      if (checked) {
        draft.selectedConsignments.push(draft.plannedConsignmentsData[routeName]);
      } else {
        var removeIndex = draft.selectedConsignments.findIndex((item: any) => item.routeId === draft.plannedConsignmentsData[routeName].routeId);
        if (removeIndex !== -1) {
          draft.selectedConsignments.splice(removeIndex, 1);
        }
      }
    }
  });
  return newAllRouteState;
};

export const handleSlotCheckboxToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, checked: boolean, routeName: string, routeDataName: string, slotName: string) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]) {
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isChecked = checked;
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName]?.orders.forEach((order: any, orderIndex: number) => {
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderIndex].isChecked = checked;
        if (checked) {
          if (!draft.selectedConsignmentsForSubRoute.includes(order.consignmentCode)) {
            draft.selectedConsignmentsForSubRoute.push(order.consignmentCode);
          }
        } else {
          const removeIndex = draft.selectedConsignmentsForSubRoute.findIndex((item: any) => item === order.consignmentCode);
          if (removeIndex !== -1) {
            draft.selectedConsignmentsForSubRoute.splice(removeIndex, 1);
          }
        }
      });

      // Update Area Checkbox if all Slots checked
      if (checked) {
        let isAllSelected: boolean = true;
        Object.keys(draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders).forEach((slotName: string) => {
          if (draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isChecked === false) {
            isAllSelected = false;
          }
          if (isAllSelected) {
            draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isChecked = checked;
          }
        });
      } else {
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isChecked = checked;
      }
    }
  });
  return newAllRouteState;
};

export const handleAreaCheckboxToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, checked: boolean, routeName: string, routeDataName: string) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]) {
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isChecked = checked;
      Object.keys(draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders).forEach((slotName: string) => {
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isChecked = checked;
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName]?.orders.forEach((order: any, orderIndex: number) => {
          draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderIndex].isChecked = checked;
          if (checked) {
            if (!draft.selectedConsignmentsForSubRoute.includes(order.consignmentCode)) {
              draft.selectedConsignmentsForSubRoute.push(order.consignmentCode);
            }
          } else {
            const removeIndex = draft.selectedConsignmentsForSubRoute.findIndex((item: any) => item === order.consignmentCode);
            if (removeIndex !== -1) {
              draft.selectedConsignmentsForSubRoute.splice(removeIndex, 1);
            }
          }
        });
      });
    }
  });
  return newAllRouteState;
};

let checkIsAllConsignmentCodePresent = (arr: string[], target: any) => target.every((v: any) => arr.includes(v.consignmentCode));

export const handleOrderCheckboxToggleUtils = (
  allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE,
  checked: boolean,
  routeName: string,
  routeDataName: string,
  slotName: string,
  orderObjIndex: number
) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex]) {
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].isChecked = checked;
      const order = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex];
      if (checked) {
        if (!draft.selectedConsignmentsForSubRoute.includes(order.consignmentCode)) {
          draft.selectedConsignmentsForSubRoute.push(order.consignmentCode);
        }
      } else {
        const removeIndex = draft.selectedConsignmentsForSubRoute.findIndex((item: any) => item === order.consignmentCode);
        if (removeIndex !== -1) {
          draft.selectedConsignmentsForSubRoute.splice(removeIndex, 1);
        }
      }

      // Is all consignmentCode of area present in selectedConsignmentsForSubRoute
      const orderRecord = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders;
      const isAllPresent: boolean = checkIsAllConsignmentCodePresent(draft.selectedConsignmentsForSubRoute, orderRecord);
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isChecked = false;
      if (isAllPresent) {
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isChecked = checked;
      }

      // Update Area Checkbox if all Slots checked
      if (checked) {
        let isAllSelected: boolean = true;
        Object.keys(draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders).forEach((slotName: string) => {
          if (draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].isChecked === false) {
            isAllSelected = false;
          }
        });
        if (isAllSelected) {
          draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isChecked = checked;
        }
      } else {
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].isChecked = checked;
      }
    }
  });
  return newAllRouteState;
};

export const handleOrderAccordianToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: string, routeDataName: string, slotName: string, orderObjIndex: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex]) {
      const order = draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex];
      draft.selectedRouteObj = {};
      draft.orderDataObj = {};
      draft.openAddRouteConfirmDialog = false;
      draft.openRemoveRouteConfirmDialog = false;
      draft.availableRouteRecord = [];
      draft.selectedConsignmentToChangeRoute = [];
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].isOpened = !order.isOpened;
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].openOrderActionPopUp = false;
      if (draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].isOpened && !isSubRoute(order)) {
        if (order?.mapCenter?.lat && order?.mapCenter?.lat !== 0) {
          draft.mapCenter = order.mapCenter;
        }
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].openOrderActionPopUp = true;
      }
    }
  });
  return newAllRouteState;
};

export const orderActionPopUpViewChangeUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: any, routeDataName: string, slotName: any, orderObjIndex: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    const order = draft.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex];
    if (order) {
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].openOrderActionPopUp = !order.openOrderActionPopUp;
    }
  });
  return newAllRouteState;
};

export const isAllRoutesAssignedWithDriverUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  if (loadDashIsArrayAndNotEmpty(allRouteState.selectedConsignments)) {
    let count: number = 0;
    allRouteState.selectedConsignments.forEach((order: any) => {
      if (order && !isUndefined(order?.assignedVehicle?.id) && !isUndefined(order?.assignee?.id)) {
        count++;
      }
    });
    if (count === allRouteState?.selectedConsignments?.length) {
      return false;
    }
  }
  return true;
};

export const isActionButtonVisibleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  if (allRouteState !== undefined && allRouteState.selectedConsignments !== undefined && Array.isArray(allRouteState.selectedConsignments) && allRouteState.selectedConsignments.length > 0) {
    return true;
  }
  return false;
};

export const validatePlannedRouteInfoUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  let routePayload = {
    errorObj: {},
    payloadObject: {
      countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      route: {
        assignee: {},
        helper: {},
        assignedVehicle: {},
      },
    },
  } as any;

  if (allRouteState !== undefined && allRouteState.selectedRoute !== undefined && isObject(allRouteState.selectedRoute) && isNonEmptyObject(allRouteState.selectedRoute)) {
    if (allRouteState.vehicleValue !== AppConstants.SELECT_NONE.value) {
      routePayload.payloadObject.route.assignee = {
        id: allRouteState.vehicleValue,
      };
    }
    if (allRouteState.driverValue !== AppConstants.SELECT_NONE.value) {
      routePayload.payloadObject.route.helper = {
        id: allRouteState.driverValue,
      };
    }
    if (allRouteState.helperValue !== AppConstants.SELECT_NONE.value) {
      routePayload.payloadObject.route.assignedVehicle = {
        id: allRouteState.helperValue,
      };
    }
  }

  return routePayload;
};

const designUnplannedMapLabel = (orderIndex: number) => {
  return { text: JSON.stringify(orderIndex), fontSize: "12px", fontWeight: "500", color: COLORS.WHITE, lineHeight: "0px" };
};

const resetUnPlannedRouteDraft = (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  draft.consignmentMarkers = [];
  draft.consignmentMarkersForMap = [];
  draft.firstMapCordinates = { lat: 0, lng: 0 };
  draft.mapCenter = { lat: 0, lng: 0 };
  draft.mapZoom = 12;
  draft.mapPolylineMarker = [];
  draft.polylineColor = "";

  draft.unplannedConsignmentsData = {};
  draft.plannedConsignmentsData = {};
  draft.selectedRoute = {};
  draft.totalRecords = 0;
  draft.totalOrders = 0;
  draft.totalCrates = 0;

  draft.selectedRouteObj = {}; // Add Remove Order from Route
  draft.orderDataObj = {};
  draft.openAddRouteConfirmDialog = false;
  draft.openRemoveRouteConfirmDialog = false;

  draft.consignmentId = "";

  draft.selectedConsignmentsForSubRoute = [];
  draft.openCreateSubRouteDialog = false;

  draft.selectedRouteDeletion = {};
  draft.openDeleteSubRouteDialog = false;

  draft.selectedSubRoute = {};
  draft.openSubRouteOrderActionPopUp = false;

  return draft;
};

export const designAllUnPlannedRoutesStateObjectUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, unPlannedRoutes: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.loading = false;
    resetUnPlannedRouteDraft(draft);

    let colorIndex = -1;
    let areaDetailsObj = {} as any;

    if (isObject(unPlannedRoutes?.statusOrders?.UNPLANNED)) {
      Object.keys(unPlannedRoutes.statusOrders.UNPLANNED).forEach((areaName: any) => {
        if (!areaDetailsObj.hasOwnProperty(areaName)) {
          draft.totalRecords++;
          colorIndex++;
          let color = getColorBasedOnRouteIndex(colorIndex);
          areaDetailsObj[areaName] = {
            orderCount: 0,
            totalCrates: 0,
            orderIndex: 1,
            slotWiseOrders: {},
            isOpened: false,
            isChecked: false,
            openOrderActionPopUp: false,
            color: color,
            mapCenter: { lat: 0, lng: 0 },
          };
        }
        if (unPlannedRoutes.statusOrders.UNPLANNED[areaName] && isObject(unPlannedRoutes.statusOrders.UNPLANNED[areaName]) && isNonEmptyObject(unPlannedRoutes.statusOrders.UNPLANNED[areaName])) {
          Object.keys(unPlannedRoutes.statusOrders.UNPLANNED[areaName]).forEach((slotName: any, slotNameIndex: any) => {
            const slotData = unPlannedRoutes.statusOrders.UNPLANNED[areaName][slotName];
            if (!areaDetailsObj[areaName].slotWiseOrders.hasOwnProperty(slotName)) {
              areaDetailsObj[areaName].slotWiseOrders[slotName] = [];
            }
            if (slotData?.elements && isNonEmptyArray(slotData?.elements)) {
              slotData.elements.forEach((order: any) => {                
                draft.totalOrders++;
                areaDetailsObj[areaName].totalCrates = areaDetailsObj[areaName].totalCrates + order?.totalCratesCount;
                draft.totalCrates = draft.totalCrates + order?.totalCratesCount;
                let location = { lat: parseFloat(order?.address?.latitude || 0), lng: parseFloat(order?.address?.longitude || 0) };
                const hubMapDetails = {
                  name: `${areaName} - ${order.consignmentCode}`,
                  location: location,
                  color: areaDetailsObj[areaName].color,
                  icon: designMapIcon(areaDetailsObj[areaName].color),
                  label: designUnplannedMapLabel(areaDetailsObj[areaName].orderIndex),
                  deliveryArea: `${areaName}`,
                  deliverySlot: `${getSlotName(slotName)}`,
                  consignmentCode: `${order?.consignmentCode}`,
                  orderIndex: areaDetailsObj[areaName].orderIndex,
                };
                if (!isUndefined(areaDetailsObj[areaName].mapCenter) && !isUndefined(areaDetailsObj[areaName].mapCenter.lat) && areaDetailsObj[areaName].mapCenter.lat === 0) {
                  areaDetailsObj[areaName].mapCenter = location;
                  if (!isUndefined(draft.mapCenter) && !isUndefined(draft.mapCenter.lat) && draft.mapCenter.lat === 0) {
                    draft.firstMapCordinates = location;
                    draft.mapCenter = location;
                  }
                }
                areaDetailsObj[areaName].orderCount++;
                areaDetailsObj[areaName].slotWiseOrders[slotName].push({ ...order, isChecked: false, isOpened: false, mapCenter: location, orderIndex: areaDetailsObj[areaName].orderIndex });
                areaDetailsObj[areaName].orderIndex = areaDetailsObj[areaName].orderIndex + 1;
                draft.consignmentMarkers.push(hubMapDetails);
              });
            }
          });
        }
      });
    }
    draft.unplannedConsignmentsData = areaDetailsObj;
    draft.consignmentMarkersForMap = [...draft.consignmentMarkers];
  });
  return newAllRouteState;
};

export const handleUnPlannedAccordianToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, areaName: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (!isUndefined(draft) && !isUndefined(draft.unplannedConsignmentsData) && !isUndefined(draft.unplannedConsignmentsData[areaName])) {
      draft.expandAll = false;
      draft.mapZoom = 12;
      draft.mapCenter = draft.firstMapCordinates;
      draft.mapPolylineMarker = [];
      draft.polylineColor = "";
      Object.keys(draft.unplannedConsignmentsData).forEach((area: any) => {
        if (area === areaName) {
          draft.unplannedConsignmentsData[area].isOpened = !draft.unplannedConsignmentsData[area].isOpened;
          if (draft.unplannedConsignmentsData[area].hasOwnProperty("slotWiseOrders") && isNonEmptyObject(draft.unplannedConsignmentsData[area].slotWiseOrders)) {
            Object.keys(draft.unplannedConsignmentsData[area].slotWiseOrders).forEach((slot: any) => {
              draft.unplannedConsignmentsData[area].slotWiseOrders[slot] = draft.unplannedConsignmentsData[area].slotWiseOrders[slot].map((order: any) => ({
                ...order,
                isOpened: draft.unplannedConsignmentsData[area].isOpened,
              }));
            });
          }
          if (draft.unplannedConsignmentsData[area].isOpened) {
            draft.expandAll = true;
            draft.mapZoom = 15;
            if (draft.unplannedConsignmentsData[area]?.mapCenter && draft.unplannedConsignmentsData[area]?.mapCenter?.lat && draft.unplannedConsignmentsData[area]?.mapCenter?.lat !== 0) {
              draft.mapCenter = draft.unplannedConsignmentsData[area].mapCenter;
            }
          }
        } else if (draft.unplannedConsignmentsData[area].isOpened === true) {
          draft.expandAll = true;
        }
      });
    }
  });
  return newAllRouteState;
};

export const handleUnplannedInnerOrderAccordianToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, areaName: any, slotName: any, orderObjIndex: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (
      draft &&
      draft.unplannedConsignmentsData &&
      draft.unplannedConsignmentsData[areaName] &&
      draft.unplannedConsignmentsData[areaName].slotWiseOrders &&
      draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName] &&
      draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName][orderObjIndex]
    ) {
      draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName][orderObjIndex].isOpened = !draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName][orderObjIndex].isOpened;
    }
  });
  return newAllRouteState;
};

export const handleUnplannedOrderCheckboxToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, areaName: any, slotName: any, orderObjIndex: any, checked: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (
      draft &&
      draft.unplannedConsignmentsData &&
      draft.unplannedConsignmentsData[areaName] &&
      draft.unplannedConsignmentsData[areaName].slotWiseOrders &&
      draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName] &&
      draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName][orderObjIndex]
    ) {
      draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName][orderObjIndex].isChecked = checked;
      if (checked) {
        draft.selectedConsignments.push(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName][orderObjIndex]);
        const find_index = draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName].findIndex((order: any) => order.isChecked === false);
        if (find_index === -1) {
          draft.unplannedConsignmentsData[areaName].isChecked = checked;
        } else {
          draft.unplannedConsignmentsData[areaName].isChecked = false;
        }
      } else {
        draft.unplannedConsignmentsData[areaName].isChecked = checked;
        var removeIndex = draft.selectedConsignments.findIndex((item: any) => item.orderCode === draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName][orderObjIndex].orderCode);
        if (removeIndex !== -1) {
          draft.selectedConsignments.splice(removeIndex, 1);
        }
      }
    }
  });
  return newAllRouteState;
};

export const updateRouteObjectAfterUpdateUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeData: any, successMessage: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.loading = false;
    draft.openUpdateConfirmDialog = false;
    draft.showDriverAssignedPopup = false;
    draft.openSnackbar = true;
    draft.snackbarMessage = successMessage;
    draft.snackbarType = AppConstants.SNACKBAR.TYPES.SUCCESS;
    draft.driverValue = AppConstants.SELECT_NONE.value;
    draft.helperValue = AppConstants.SELECT_NONE.value;
    draft.vehicleValue = AppConstants.SELECT_NONE.value;
    if (
      routeData &&
      routeData.routeId &&
      draft.plannedConsignmentsData &&
      isObject(draft.plannedConsignmentsData) &&
      isNonEmptyObject(draft.plannedConsignmentsData) &&
      draft.plannedConsignmentsData[routeData.routeId]
    )
      draft.plannedConsignmentsData[routeData.routeId].assignedVehicle = {};
    if (routeData.assignedVehicle !== undefined && routeData.assignedVehicle.id !== undefined) {
      draft.vehicleValue = routeData.assignedVehicle.id;
      draft.plannedConsignmentsData[routeData.routeId].assignedVehicle = { ...routeData.assignedVehicle };
    }
    draft.plannedConsignmentsData[routeData.routeId].assignee = {};
    if (routeData.assignee !== undefined && routeData.assignee.id !== undefined) {
      draft.driverValue = routeData.assignee.id;
      draft.plannedConsignmentsData[routeData.routeId].assignee = { ...routeData.assignee };
    }
    draft.plannedConsignmentsData[routeData.routeId].helper = {};
    if (routeData.helper !== undefined && routeData.helper.id !== undefined) {
      draft.helperValue = routeData.helper.id;
      draft.plannedConsignmentsData[routeData.routeId].helper = { ...routeData.helper };
    }
    draft.selectedRoute = draft.plannedConsignmentsData[routeData.routeId];

    var removeIndex = draft.selectedConsignments.findIndex((item: any) => item.routeId === draft.plannedConsignmentsData[routeData.routeId].routeId);
    if (removeIndex !== -1) {
      draft.selectedConsignments.splice(removeIndex, 1);
      draft.selectedConsignments.push(draft.plannedConsignmentsData[routeData.routeId]);
    }
  });
  return newAllRouteState;
};

export const handleExpandAllRecordClickMethodUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.expandAll = !draft.expandAll;
    if (draft.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED) {
      if (!isUndefined(draft) && !isUndefined(draft.plannedConsignmentsData)) {
        Object.keys(draft.plannedConsignmentsData).forEach((route: any) => {
          draft.plannedConsignmentsData[route].isOpened = draft.expandAll;
          if (draft.plannedConsignmentsData[route].hasOwnProperty("slotWiseOrders") && isNonEmptyObject(draft.plannedConsignmentsData[route].slotWiseOrders)) {
            Object.keys(draft.plannedConsignmentsData[route].slotWiseOrders).forEach((slot: any) => {
              draft.plannedConsignmentsData[route].slotWiseOrders[slot] = draft.plannedConsignmentsData[route].slotWiseOrders[slot].map((order: any) => ({
                ...order,
                isOpened: draft.expandAll,
              }));
            });
          }
        });
      }
    } else if (draft.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED) {
      if (!isUndefined(draft) && !isUndefined(draft.unplannedConsignmentsData)) {
        Object.keys(draft.unplannedConsignmentsData).forEach((route: any) => {
          draft.unplannedConsignmentsData[route].isOpened = draft.expandAll;
          if (draft.unplannedConsignmentsData[route].hasOwnProperty("slotWiseOrders") && isNonEmptyObject(draft.unplannedConsignmentsData[route].slotWiseOrders)) {
            Object.keys(draft.unplannedConsignmentsData[route].slotWiseOrders).forEach((slot: any) => {
              draft.unplannedConsignmentsData[route].slotWiseOrders[slot] = draft.unplannedConsignmentsData[route].slotWiseOrders[slot].map((order: any) => ({
                ...order,
                isOpened: draft.expandAll,
              }));
            });
          }
        });
      }
    }
  });
  return newAllRouteState;
};

export const updatAavailableRouteRecordUtils = (
  allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE,
  routeName?: string,
  routeDataName?: string,
  slotName?: string,
  orderObjIndex?: number,
  payload?: any
) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.loading = false;
    draft.openAssignRoutePopUp = !draft.openAssignRoutePopUp;
    draft.availableRouteRecord = [];
    draft.selectedConsignmentToChangeRoute = [];
    draft.selectedRouteObj = {};
    draft.orderDataObj = {};
    draft.openAddRouteConfirmDialog = false;
    draft.openRemoveRouteConfirmDialog = false;
    if (payload && isNonEmptyArray(payload)) {
      draft.availableRouteRecord = payload.map((routeData: any) => ({ ...routeData, isChecked: false }));
      if (
        routeName &&
        routeDataName &&
        slotName &&
        orderObjIndex !== undefined &&
        Number(orderObjIndex) >= 0 &&
        draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex]
      ) {
        const order = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex];
        draft.selectedConsignmentToChangeRoute.push(order.consignmentCode);
        draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].openOrderActionPopUp = !order.openOrderActionPopUp;
      }
    }
  });
  return newAllRouteState;
};

export const selectRouteToAssigntoOrderUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeIndex: number) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft.availableRouteRecord && isNonEmptyArray(draft.availableRouteRecord) && draft.availableRouteRecord[routeIndex]) {
      draft.availableRouteRecord.forEach((route: any, index: any) => {
        if (index === routeIndex) {
          draft.availableRouteRecord[index].isChecked = !draft.availableRouteRecord[index].isChecked;
        } else {
          draft.availableRouteRecord[index].isChecked = false;
        }
      });
    }
  });
  return newAllRouteState;
};

export const getConsignmentRecordUtils = (selectedConsignments: any) => {
  if (selectedConsignments && isNonEmptyArray(selectedConsignments)) {
    return selectedConsignments.map((order: any) => order.consignmentCode);
  }
  return [];
};

export const updatAvailableRouteRecordForUnplannedOrdersUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, consignmentRecord: any, payload: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.loading = false;
    draft.openAssignRoutePopUp = !draft.openAssignRoutePopUp;
    draft.availableRouteRecord = [];
    draft.selectedConsignmentToChangeRoute = [];
    if (payload && isNonEmptyArray(payload)) {
      draft.availableRouteRecord = payload.map((routeData: any) => ({ ...routeData, isChecked: false }));
      draft.selectedConsignmentToChangeRoute = [...consignmentRecord];
    }
  });
  return newAllRouteState;
};

export const updateLoadingStateUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.loading = true;
  });
  return newAllRouteState;
};

export const handleSelectAllRoutesUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, checked: boolean) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (!isUndefined(draft) && !isUndefined(draft.plannedConsignmentsData)) {
      draft.selectedConsignments = [];
      Object.keys(draft.plannedConsignmentsData).forEach((route: any) => {
        draft.plannedConsignmentsData[route].isChecked = checked;
        if (checked) {
          draft.selectedConsignments.push(draft.plannedConsignmentsData[route]);
        }
      });
    }
  });
  return newAllRouteState;
};

export const removeOrderFromRouteUtils = (
  allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE,
  routeName: string,
  routeDataName: string,
  slotName: string,
  orderObjIndex: number,
  routeData: any,
  orderData: string
) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.selectedRouteObj = routeData;
    draft.orderDataObj = orderData;
    draft.openAddRouteConfirmDialog = false;
    draft.openRemoveRouteConfirmDialog = true;
    const order = draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex];
    if (order) {
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].openOrderActionPopUp = !order.openOrderActionPopUp;
    }
  });
  return newAllRouteState;
};

export const handleUnplannedAreaCheckboxToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, areaName: any, checked: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft && draft.unplannedConsignmentsData && draft.unplannedConsignmentsData[areaName]) {
      draft.unplannedConsignmentsData[areaName].isChecked = checked;
      if (
        !isUndefined(draft.unplannedConsignmentsData[areaName].slotWiseOrders) &&
        isObject(draft.unplannedConsignmentsData[areaName].slotWiseOrders) &&
        countObjectProperties(draft.unplannedConsignmentsData[areaName].slotWiseOrders) > 0
      ) {
        Object.keys(draft.unplannedConsignmentsData[areaName].slotWiseOrders).forEach((slotName: any) => {
          if (
            !isUndefined(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName]) &&
            isInputAnArray(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName]) &&
            isNonEmptyArray(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName])
          ) {
            draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName] = draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName].map((order: any) => ({
              ...order,
              isChecked: checked,
            }));
            draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName].forEach((order: any) => {
              var removeIndex = draft.selectedConsignments.findIndex((item: any) => item.orderCode === order.orderCode);
              if (checked && removeIndex === -1) {
                draft.selectedConsignments.push(order);
              } else if (!checked && removeIndex !== -1) {
                draft.selectedConsignments.splice(removeIndex, 1);
              }
            });
          }
        });
      }
    }
  });
  return newAllRouteState;
};

export const getPaymentModeString = (paymentMethod: any) => {
  if (paymentMethod.toLowerCase() === "cash on delivery") {
    return "COD";
  }
  return paymentMethod;
};

export const handleOpenCloseUnplannedRouteSlotUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, areaName: any, slotName: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (
      draft &&
      draft.unplannedConsignmentsData &&
      draft.unplannedConsignmentsData[areaName] &&
      !isUndefined(draft.unplannedConsignmentsData[areaName].slotWiseOrders) &&
      !isUndefined(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName]) &&
      isInputAnArray(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName]) &&
      isNonEmptyArray(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName])
    ) {
      draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName] = draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName].map((order: any) => ({
        ...order,
        isOpened: !order.isOpened,
      }));
    }
  });
  return newAllRouteState;
};

export const handleUnplannedSlotCheckboxToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, checked: boolean, areaName: any, slotName: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft && draft.unplannedConsignmentsData && draft.unplannedConsignmentsData[areaName]) {
      if (
        draft &&
        draft.unplannedConsignmentsData &&
        draft.unplannedConsignmentsData[areaName] &&
        !isUndefined(draft.unplannedConsignmentsData[areaName].slotWiseOrders) &&
        !isUndefined(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName]) &&
        isInputAnArray(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName]) &&
        isNonEmptyArray(draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName])
      ) {
        draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName] = draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName].map((order: any) => ({
          ...order,
          isChecked: checked,
        }));
        draft.unplannedConsignmentsData[areaName].slotWiseOrders[slotName].forEach((order: any) => {
          var removeIndex = draft.selectedConsignments.findIndex((item: any) => item.orderCode === order.orderCode);
          if (checked && removeIndex === -1) {
            draft.selectedConsignments.push(order);
          } else if (!checked && removeIndex !== -1) {
            draft.selectedConsignments.splice(removeIndex, 1);
          }
        });
      }
    }
  });
  return newAllRouteState;
};

function findUnplannedConsignmentsData(data: UnplannedConsignmentsDataInterface, consignmentCode: string | number) {
  for (let key in data) {
    if (data.hasOwnProperty(key) && !isUndefined(data[key].slotWiseOrders)) {
      const slotWiseOrders = data[key].slotWiseOrders;
      for (const timeSlot in slotWiseOrders) {
        const orders = slotWiseOrders[timeSlot];
        const order = orders.find((order: OrderInterface) => order.consignmentCode === consignmentCode);
        if (order) {
          const obj = { key, timeSlot, order };
          return obj;
        }
      }
    }
  }
  return null;
}

function findPlannedConsignmentsData(data: PlannedConsignmentsDataInterface, consignmentCode: string | number) {
  for (let key in data) {
    if (!isUndefined(data[key].consignmentsIds) && isNonEmptyArray(data[key].consignmentsIds) && data[key].consignmentsIds.includes(String(consignmentCode))) {
      for (const areaName in data[key].routeDataObj) {
        if (data[key].routeDataObj[areaName].routeType === ROUTE_TYPE.SUB_ROUTE) {
          if (data[key]?.routeDataObj[areaName] && data[key]?.routeDataObj[areaName]?.slotWiseOrders) {
            for (const timeSlot in data[key].routeDataObj[areaName].slotWiseOrders) {
              if (
                data[key].routeDataObj[areaName].slotWiseOrders[timeSlot] &&
                data[key].routeDataObj[areaName].slotWiseOrders[timeSlot].orders &&
                isNonEmptyArray(data[key].routeDataObj[areaName].slotWiseOrders[timeSlot].orders)
              ) {
                const orders = data[key].routeDataObj[areaName].slotWiseOrders[timeSlot].orders;
                const order = orders?.find((order: OrderInterface) => order.consignmentCode === consignmentCode);
                if (order) {
                  const obj = { key, areaName, timeSlot, order };
                  return obj;
                }
              }
            }
          }
        } else {
          for (const timeSlot in data[key].routeDataObj[areaName].slotWiseOrders) {
            const orders = data[key].routeDataObj[areaName].slotWiseOrders[timeSlot].orders;
            if (
              data[key].routeDataObj[areaName].slotWiseOrders &&
              data[key].routeDataObj[areaName].slotWiseOrders[timeSlot] &&
              data[key].routeDataObj[areaName].slotWiseOrders[timeSlot].orders &&
              isNonEmptyArray(data[key].routeDataObj[areaName].slotWiseOrders[timeSlot].orders)
            ) {
              const order = orders?.find((order: OrderInterface) => order.consignmentCode === consignmentCode);
              if (order) {
                const obj = { key, areaName, timeSlot, order };
                return obj;
              }
            }
          }
        }
      }
    }
  }
  return null;
}

export const handleConsignmentSearchUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    let noOrderFound: boolean = false;
    draft.expandAll = false;
    draft.mapZoom = 12;
    draft.mapCenter = draft.firstMapCordinates;
    draft.mapPolylineMarker = [];
    draft.polylineColor = "";
    draft.consignmentMarkersForMap = [...draft.consignmentMarkers];
    if (allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED && isNonEmptyObject(draft.plannedConsignmentsData)) {
      const obj: any = findPlannedConsignmentsData(allRouteState.plannedConsignmentsData, allRouteState.consignmentId);
      if (obj && isNonEmptyObject(obj) && !isUndefined(obj?.key) && draft.plannedConsignmentsData.hasOwnProperty(obj?.key)) {
        draft.plannedConsignmentsData[obj?.key].isOpened = true;
        draft.mapZoom = 15;
        draft.expandAll = true;

        const objectKeysLength: number = Object.keys(obj).length;
        if (objectKeysLength === 4) {
          if (
            draft.plannedConsignmentsData[obj?.key].hasOwnProperty("routeDataObj") &&
            isNonEmptyObject(draft.plannedConsignmentsData[obj?.key].routeDataObj) &&
            draft.plannedConsignmentsData[obj?.key]?.routeDataObj.hasOwnProperty(obj?.areaName)
          ) {
            draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].isOpened = true;
            if (
              draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].hasOwnProperty("slotWiseOrders") &&
              isNonEmptyObject(draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].slotWiseOrders) &&
              draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].slotWiseOrders.hasOwnProperty(obj?.timeSlot)
            ) {
              draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].slotWiseOrders[obj.timeSlot].isOpened = true;
              if (isNonEmptyArray(draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].slotWiseOrders[obj.timeSlot].orders)) {
                const orders = draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].slotWiseOrders[obj.timeSlot].orders;
                draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].slotWiseOrders[obj.timeSlot].orders = orders.map((order: OrderInterface) =>
                  order.consignmentCode === allRouteState.consignmentId ? { ...order, isOpened: draft.plannedConsignmentsData[obj?.key].isOpened } : order
                );
              }
            }
          }
        } else if (objectKeysLength === 5) {
          if (
            draft.plannedConsignmentsData[obj?.key].hasOwnProperty("routeDataObj") &&
            isNonEmptyObject(draft.plannedConsignmentsData[obj?.key].routeDataObj) &&
            draft.plannedConsignmentsData[obj?.key]?.routeDataObj.hasOwnProperty(obj?.areaName)
          ) {
            draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].isOpened = true;
            if (
              draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].hasOwnProperty("areaDataObj") &&
              isNonEmptyObject(draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj) &&
              draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj.hasOwnProperty(obj.subAreaName)
            ) {
              draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].isOpened = true;
              if (
                draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].hasOwnProperty("slotWiseOrders") &&
                isNonEmptyObject(draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].slotWiseOrders) &&
                draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].slotWiseOrders.hasOwnProperty(obj?.timeSlot)
              ) {
                draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].slotWiseOrders[obj.timeSlot].isOpened = true;
                if (isNonEmptyArray(draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].slotWiseOrders[obj.timeSlot].orders)) {
                  const orders = draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].slotWiseOrders[obj.timeSlot].orders;
                  draft.plannedConsignmentsData[obj?.key].routeDataObj[obj.areaName].areaDataObj[obj.subAreaName].slotWiseOrders[obj.timeSlot].orders = orders.map((order: OrderInterface) =>
                    order.consignmentCode === allRouteState.consignmentId ? { ...order, isOpened: draft.plannedConsignmentsData[obj?.key].isOpened } : order
                  );
                }
              }
            }
          }
        }

        if (draft.plannedConsignmentsData[obj?.key].isOpened) {
          if (obj?.order?.mapCenter && obj?.order?.mapCenter?.lat && obj?.order?.mapCenter?.lat !== 0) {
            draft.mapCenter = obj?.order?.mapCenter;
          } else if (draft.plannedConsignmentsData[obj?.key]?.mapCenter && draft.plannedConsignmentsData[obj?.key]?.mapCenter?.lat && draft.plannedConsignmentsData[obj?.key]?.mapCenter?.lat !== 0) {
            draft.mapCenter = draft.plannedConsignmentsData[obj?.key].mapCenter;
          }
          // Make a Polynine
          if (!isUndefined(draft.consignmentMarkers) && isArrayNonEmpty(draft.consignmentMarkers)) {
            let markerWithCurrentRoute = [];
            if (objectKeysLength === 5) {
              markerWithCurrentRoute = draft.consignmentMarkers.filter((x: any) => x?.routeId === obj?.areaName);
            } else if (objectKeysLength === 4) {
              markerWithCurrentRoute = draft.consignmentMarkers.filter((x: any) => x?.routeId === obj?.key || x?.parentRouteName === obj?.key);
            }
            if (!isUndefined(markerWithCurrentRoute) && isArrayNonEmpty(markerWithCurrentRoute)) {
              draft.consignmentMarkersForMap = [...markerWithCurrentRoute];
              draft.mapPolylineMarker = markerWithCurrentRoute.map((marker: any) => marker?.location);
              draft.polylineColor = markerWithCurrentRoute[0].color;
            }
          }
        }
      } else {
        noOrderFound = true;
      }
    } else if (allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED && isNonEmptyObject(draft.unplannedConsignmentsData)) {
      const obj = findUnplannedConsignmentsData(allRouteState.unplannedConsignmentsData, allRouteState.consignmentId);
      if (obj && isNonEmptyObject(obj) && !isUndefined(obj?.key) && draft.unplannedConsignmentsData.hasOwnProperty(obj?.key)) {
        draft.unplannedConsignmentsData[obj?.key].isOpened = !draft.unplannedConsignmentsData[obj?.key].isOpened;
        if (
          draft.unplannedConsignmentsData[obj?.key]?.hasOwnProperty("slotWiseOrders") &&
          isNonEmptyObject(draft.unplannedConsignmentsData[obj?.key]?.slotWiseOrders) &&
          draft.unplannedConsignmentsData[obj?.key]?.slotWiseOrders.hasOwnProperty(obj?.timeSlot)
        ) {
          draft.unplannedConsignmentsData[obj?.key].slotWiseOrders[obj?.timeSlot] = draft.unplannedConsignmentsData[obj?.key].slotWiseOrders[obj?.timeSlot].map((order: OrderInterface) =>
            order.consignmentCode === allRouteState.consignmentId ? { ...order, isOpened: draft.unplannedConsignmentsData[obj?.key].isOpened } : order
          );
        }
        if (draft.unplannedConsignmentsData[obj?.key].isOpened) {
          draft.expandAll = true;
          draft.mapZoom = 15;
          if (obj?.order?.mapCenter && obj?.order?.mapCenter?.lat && obj?.order?.mapCenter?.lat !== 0) {
            draft.mapCenter = obj?.order?.mapCenter;
          } else if (draft.unplannedConsignmentsData[obj?.key]?.mapCenter?.lat && draft.unplannedConsignmentsData[obj?.key]?.mapCenter?.lat !== 0) {
            draft.mapCenter = draft.unplannedConsignmentsData[obj?.key].mapCenter;
          }
        }
      } else {
        noOrderFound = true;
      }
    }

    if (noOrderFound) {
      draft.openSnackbar = true;
      draft.snackbarMessage = `${SNACKBAR_MESSAGES.CONSIGNMENT_ID} - ${allRouteState.consignmentId} not found`;
      draft.snackbarType = AppConstants.SNACKBAR.TYPES.ERROR;
    }
  });
  return newAllRouteState;
};

export const handleRoutAreaSubareaAccordianToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: string, routeDataName: string, subRouteAreaName: string) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName]?.areaDataObj[subRouteAreaName]) {
      const subRouteArea = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].areaDataObj[subRouteAreaName];
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].areaDataObj[subRouteAreaName].isOpened = !subRouteArea.isOpened;
    }
    draft.loading = false;
  });
  return newAllRouteState;
};

export const handleSubRouteSlotAccordianToggleUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeName: string, routeDataName: string, slotName: string, subRouteAreaName: string) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.areaDataObj[subRouteAreaName]?.slotWiseOrders[slotName]) {
      const subRouteSlot = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].areaDataObj[subRouteAreaName].slotWiseOrders[slotName];
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].areaDataObj[subRouteAreaName].slotWiseOrders[slotName].isOpened = !subRouteSlot.isOpened;
    }
    draft.loading = false;
  });
  return newAllRouteState;
};

export const handleSubrouteOrderAccordianToggleUtils = (
  allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE,
  routeName: string,
  routeDataName: string,
  slotName: string,
  orderObjIndex: any,
  subRouteAreaName: string
) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    if (draft?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.areaDataObj[subRouteAreaName]?.slotWiseOrders[slotName]?.orders[orderObjIndex]) {
      const subRouteOrder = draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].areaDataObj[subRouteAreaName].slotWiseOrders[slotName].orders[orderObjIndex];
      draft.plannedConsignmentsData[routeName].routeDataObj[routeDataName].areaDataObj[subRouteAreaName].slotWiseOrders[slotName].orders[orderObjIndex].isOpened = !subRouteOrder.isOpened;
    }
    draft.loading = false;
  });
  return newAllRouteState;
};

export const validatePlannedSubRouteInfoUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
  let routePayload = {
    errorObj: {},
    payloadObject: { countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE), route: { assignee: {}, helper: {}, assignedVehicle: {} } },
  } as any;

  if (allRouteState !== undefined && allRouteState.selectedSubRoute !== undefined && isObject(allRouteState.selectedSubRoute) && isNonEmptyObject(allRouteState.selectedSubRoute)) {
    if (allRouteState.subVehicleValue !== AppConstants.SELECT_NONE.value) {
      routePayload.payloadObject.route.assignee = { id: allRouteState.subVehicleValue };
    }
    if (allRouteState.subDriverValue !== AppConstants.SELECT_NONE.value) {
      routePayload.payloadObject.route.helper = { id: allRouteState.subDriverValue };
    }
    if (allRouteState.subHelperValue !== AppConstants.SELECT_NONE.value) {
      routePayload.payloadObject.route.assignedVehicle = { id: allRouteState.subHelperValue };
    }
  }

  return routePayload;
};

export const updateSubRouteObjectAfterUpdateUtils = (allRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE, routeData: any, successMessage: any) => {
  let newAllRouteState: ALL_ROUTE_INITIAL_STATE_INTERFACE = produce(allRouteState, (draft: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    draft.loading = false;
    draft.openSubRouteOrderActionPopUp = false;

    draft.openSnackbar = true;
    draft.snackbarMessage = successMessage;
    draft.snackbarType = AppConstants.SNACKBAR.TYPES.SUCCESS;

    draft.subDriverValue = AppConstants.SELECT_NONE.value;
    draft.subHelperValue = AppConstants.SELECT_NONE.value;
    draft.subVehicleValue = AppConstants.SELECT_NONE.value;
    if (
      allRouteState?.selectedSubRoute?.parentRouteName &&
      routeData.routeId &&
      draft.plannedConsignmentsData[allRouteState?.selectedSubRoute?.parentRouteName] &&
      draft.plannedConsignmentsData[allRouteState?.selectedSubRoute?.parentRouteName]?.routeDataObj &&
      draft.plannedConsignmentsData[allRouteState?.selectedSubRoute?.parentRouteName]?.routeDataObj[routeData.routeId]
    )
      draft.plannedConsignmentsData[allRouteState.selectedSubRoute.parentRouteName].routeDataObj[routeData.routeId].assignedVehicle = {};
    if (routeData?.assignedVehicle?.id !== undefined) {
      draft.subVehicleValue = routeData.assignedVehicle.id;
      draft.plannedConsignmentsData[allRouteState.selectedSubRoute.parentRouteName].routeDataObj[routeData.routeId].assignedVehicle = { ...routeData.assignedVehicle };
    }

    draft.plannedConsignmentsData[allRouteState.selectedSubRoute.parentRouteName].routeDataObj[routeData.routeId].assignee = {};
    if (routeData?.assignee?.id !== undefined) {
      draft.subDriverValue = routeData.assignee.id;
      draft.plannedConsignmentsData[allRouteState.selectedSubRoute.parentRouteName].routeDataObj[routeData.routeId].assignee = { ...routeData.assignee };
    }

    draft.plannedConsignmentsData[allRouteState.selectedSubRoute.parentRouteName].routeDataObj[routeData.routeId].helper = {};
    if (routeData?.helper?.id !== undefined) {
      draft.subHelperValue = routeData.helper.id;
      draft.plannedConsignmentsData[allRouteState.selectedSubRoute.parentRouteName].routeDataObj[routeData.routeId].helper = { ...routeData.helper };
    }
    draft.selectedSubRoute = draft.plannedConsignmentsData[allRouteState.selectedSubRoute.parentRouteName].routeDataObj[routeData.routeId];
  });
  return newAllRouteState;
};
