const HubConstants = {
    HUB_CONSTANTS: {
        TABLE_WRAPPER_DATA: {
            title: "Hubs",
            HeaderData: [
                {
                    field: 'id',
                    headerName: 'Id',
                    hide: true
                },
                {
                    field: 'hubId',
                    headerName: 'Hub Id',
                    width: 150,
                },
                {
                    field: 'hubName',
                    headerName: 'Hub',
                    width: 150
                },
                {
                    field: 'latLong',
                    headerName: 'Latitude/Longitude',
                    width: 150
                },
                {
                    field: 'country',
                    headerName: 'Country',
                    width: 150
                },
                {
                    field: 'category',
                    headerName: 'Category',
                    width: 150
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    width: 150
                },
                {
                    field: 'serviceType',
                    headerName: 'Service Type',
                    width: 150
                },
                {
                    field: 'area',
                    headerName: 'Address',
                    width: 150
                },
                {
                    field: 'actions',
                    headerName: 'Actions',
                    width: 100
                }
            ],
            headerButtons: [
                {
                    name: "addHub",
                    displayName: "Add New Hub",
                    disabled: false,
                    classes: 'primary'
                }
            ],
            loadMore: {
                name: 'loadMore',
                disabled: false,
                pageSize: 20
            },
            defaultFiltersObj: {
                hubId: {
                    key: "hubId",
                    value: [],
                    fieldName: 'c.id'
                },
                hubName: {
                    key: "hubName",
                    value: [],
                    fieldName: 'c.name'
                },
                pageNumber: 0
            },
            SEARCH_TYPES: [
                {
                    name: "Hub Id",
                    value: "hubId"
                },
                {
                    name: "Hub Name",
                    value: "hubName"
                }
            ],
        },
        VALIDATIONS: {
            HUBID: {
                REQUIRED: {
                    msg: "Hub Id is required"
                }
            },
            HUBNAME: {
                REQUIRED: {
                    msg: "Hub Name is required"
                }
            },
            LATITUDE: {
                REQUIRED: {
                    msg: "Latitude is required",
                },
                VALIDITY: {
                    msg: "Please enter a valid latitude value",
                    value: "/^[a-zA-Z0-9]+$/",
                }
            },
            LONGITUDE: {
                REQUIRED: {
                    msg: "Longitude is required"
                },
                VALIDITY: {
                    msg: "Please enter a valid longitude value",
                    value: "/^[a-zA-Z0-9]+$/",
                }
            },
            HUB_STATUS: {
                REQUIRED: {
                    msg: "Status is required",
                    value: "\\b(?![n\\/a]\\b)\\w+"
                }
            },
            SERVICE_TYPE: {
                REQUIRED: {
                    msg: "Service Type is required",
                    value: "\\b(?![n\\/a]\\b)\\w+"
                }
            },
            CATEGORY: {
                REQUIRED: {
                    msg: "Service Type is required",
                    value: "\\b(?![n\\/a]\\b)\\w+"
                }
            },
            COUNTRY: {
                REQUIRED: {
                    msg: "Country is required",
                    value: "\\b(?![n\\/a]\\b)\\w+"
                }
            },
            ADDRESS: {
                REQUIRED: {
                    msg: "Address is required"
                }
            },
        },
        RESPONSE_CONSTANTS: {
            ERROR_CODES: {
                SESSION_TIMEOUT: "UM100",
                UNAUTHORISED: "UM101",
            }
        },
        HUB_STATUS: [
            { name: "ACTIVE", value: "ACTIVE" },
            { name: "INACTIVE", value: "INACTIVE" },
        ],
        HUB_SERVICE_TYPE: [
            { name: "Standard", value: "Standard" },
            { name: "Express", value: "Express" },
            { name: "Click & Collect", value: "Click&Collect" },
        ],
        CATEGORY: [
            { name: "Food", value: "Food" },
            { name: "Non Food", value: "NonFood" },
        ]
    }
}
export default HubConstants;