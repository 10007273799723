import AppConstants from "../../constants";

export interface Elements {
    store: string;
    storeName: string;
    hubStatus: string;
    displayName: string;
    deliveryType: any;
    category: any;
    contactInfo: Contact;
    serviceTime: any;
    active: any;
    hubQRImagePath: string;
}

export interface Contact {
    name: string;
    phone: string;
    email: string;
    address: Address;
}

export interface Address {
    area: string;
    region: string;
    countryName: string;
    countryIsoCode: string;
    longitude: string;
    latitude: string;
}

export interface HubDataResponse {
    pageNumber: number;
    totalElements: number;
    totalPages: number;
    elements: Elements[];
}

const lastMileHubDataTransform = (response: HubDataResponse): any[] => {
    const transformedArray: any[] = [];
    response.elements.forEach((elements: Elements) => {
        const {
            store,
            storeName,
            hubStatus,
            displayName,
            deliveryType,
            category,
            contactInfo,
            active,
            hubQRImagePath
        } = elements;
        transformedArray.push({
            hubId: store,
            hubName: storeName,
            latLong: `${Number(contactInfo.address.latitude).toFixed(2)}, ${Number(contactInfo.address.longitude).toFixed(2)}`,
            serviceType: deliveryType,
            area: contactInfo.address.area,
            hubStatus: hubStatus,
            category: category,
            displayName: displayName,
            latitude: contactInfo.address.latitude,
            longitude: contactInfo.address.longitude,
            status: active ? "ACTIVE" : "INACTIVE",
            country: contactInfo.address.countryName,
            hubQRImage: hubQRImagePath
        });

    });
    return [...transformedArray];
};

export { lastMileHubDataTransform };

export const createHubDetailsPayload = (payload: any, data?: any, isLoadMore?: boolean, countryCode?: any) => {
    payload.attributes = [];
    payload.countryISOCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
    if (isLoadMore) {
        payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
    }
    delete payload.hubId;
    delete payload.hubName;
    return payload;
};

export const createAddHubPayload = (payload: any) => {
    if (payload.hubId) {
        payload.id = payload.hubId
    }
    payload.contactInfo = {};
    payload.contactInfo.address = {};
    if (payload.latitude) {
        payload.contactInfo.address.latitude = payload.latitude;
    }
    if (payload.longitude) {
        payload.contactInfo.address.longitude = payload.longitude;
    }
    if (payload.country) {
        payload.contactInfo.address.countryIsoCode = payload.country;
    }
    if (payload.address) {
        payload.contactInfo.address.area = payload.address;
    }
    return {
        id: payload.hubId,
        name: payload.hubName,
        hubStatus: payload.status,
        deliveryType: [payload.serviceType],
        category: [payload.category],
        contactInfo: payload.contactInfo,
        pageSize: AppConstants.HUB_CONSTANTS.TABLE_WRAPPER_DATA.loadMore.pageSize,
        pageNumber: payload.pageNumber
    };
}

export const createEditHubPayload = (payload: any) => {
    if (payload.hubId) {
        payload.id = payload.hubId
    }
    payload.contactInfo = {};
    payload.contactInfo.address = {};
    if (payload.latitude) {
        payload.contactInfo.address.latitude = payload.latitude;
    }
    if (payload.longitude) {
        payload.contactInfo.address.longitude = payload.longitude;
    }
    if (payload.country) {
        payload.contactInfo.address.countryIsoCode = payload.country;
    }
    if (payload.address) {
        payload.contactInfo.address.area = payload.address;
    }
    return {
        id: payload.hubId,
        name: payload.hubName,
        hubStatus: payload.status,
        deliveryType: [payload.serviceType],
        category: [payload.category],
        contactInfo: payload.contactInfo,
        pageSize: AppConstants.HUB_CONSTANTS.TABLE_WRAPPER_DATA.loadMore.pageSize,
        pageNumber: payload.pageNumber
    };
}