import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      marginTop: 12,
      "&.MuiGrid-container": {
        flexWrap: "unset",
      },
      "& .inputBox .MuiInputBase-root": {
        borderRadius: "4px 0px 0px 4px",
        height: 48,
        width: 290,
      },
      "& .MuiInputBase-root": {
        minWidth: "auto",
      },
    },
    checkbox: {
      marginRight: 8,
    },
    icon: {
      width: "auto",
      height: "auto",
      fill: "none",
    },
  })
);
