import React from "react";

import { Checkbox, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as crateBoxIcon } from "../../../assets/icons/crate-box-icon.svg";
import { ReactComponent as UserIconGreen } from "../../../assets/icons/user_icon_green.svg";
import { ReactComponent as UserIconGrey } from "../../../assets/icons/user_icon_grey.svg";

import { getAreaTitleViewWithClasses } from "../utils/AllRouteViewUtils";
import { COLORS } from "../../../config/theme/baseTheme";
import { ALL_ROUTES_TEXT_CONTANT } from "../utils/AllRouteConstant";

import CustomTooltip from "../../../common/Tooltip";

interface RenderPlannedRouteAccordionSummaryInterface {
  classes: any;
  routeObj: any;
  routeName: string;
  viewMode?: boolean;
  handleRouteCheckboxToggle ?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string) => void;
}

const RenderPlannedRouteAccordionSummary = (props: RenderPlannedRouteAccordionSummaryInterface) => {
  const { classes, routeObj, routeName, viewMode, handleRouteCheckboxToggle } = props;

  const isDriverAssigned = (routeObj: any) => {
    if (routeObj.assignee !== undefined && routeObj.assignee.id !== undefined) {
      return true;
    }
    return false;
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Grid container>
          {!viewMode && <Checkbox color="primary" checked={routeObj.isChecked} onClick={(e: any) => handleRouteCheckboxToggle && handleRouteCheckboxToggle(e, routeName)} className={`${classes.marginAuto} ${classes.marginRightFour}`} />}
          <Typography
            style={{ color: routeObj.color || COLORS.TEXT_PRIMARY }}
            className={`${classes.fontSizeFourteen} ${classes.fontWeightSeven} ${classes.marginAuto} ${classes.paddingRightFour} ${classes.borderRight}`}
          >{`${routeObj.routeId || "-"}`}</Typography>
          <Typography
            className={`${classes.fontSizeTweleve} ${classes.fontWeightFive} ${classes.marginAuto} ${classes.paddingLeftFour} ${classes.paddingRightFour}`}
          >{`${routeObj.totalConsignment} Orders`}</Typography>
          <Grid item className={`${classes.displayFlex} ${classes.marginAuto} ${classes.paddingLeftFour} ${classes.paddingRightFour} ${classes.borderLeft}`}>
            {getAreaTitleViewWithClasses(routeObj, classes)}
          </Grid>
          {routeObj.isSubRoutePresent && (
            <Grid item className={`${classes.displayFlex} ${classes.marginAuto} ${classes.paddingLeftFour} ${classes.paddingRightFour} ${classes.borderLeft}`}>
              <CustomTooltip title={`${ALL_ROUTES_TEXT_CONTANT.PARENT_ROUTE}`}>
                <Typography className={`${classes.routeBoxStyle} ${classes.parentRouteBoxStyle}`}>{`P...`}</Typography>
              </CustomTooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justify="flex-end">
          <Grid item>
            <Grid container>
              <Grid item className={`${classes.displayFlex} ${classes.paddingRightEight} ${classes.borderRight}`}>
                <SvgIcon viewBox="0 0 24 24" component={crateBoxIcon} className={classes.crateIconStyle} />
                <Typography className={`${classes.fontSizeTweleve} ${classes.fontWeightFour} ${classes.lineHeightFifteen} ${classes.marginAuto}`}>{`${routeObj.totalCrates}`}</Typography>
              </Grid>
              <Grid item className={`${classes.displayFlex}`}>
                {isDriverAssigned(routeObj) ? (
                  <>
                    <SvgIcon viewBox="0 0 24 24" component={UserIconGreen} className={classes.userIconStyle} />
                    <Typography className={`${classes.fontSizeTweleve} ${classes.fontWeightFour} ${classes.lineHeightFifteen} ${classes.marginAuto}`}>{`${
                      routeObj.assignee && routeObj.assignee.name && routeObj.assignee.name.substring(0, 12)
                    }`}</Typography>
                  </>
                ) : (
                  <>
                    <SvgIcon viewBox="0 0 24 24" component={UserIconGrey} className={classes.userIconStyle} />
                    <Typography className={classes.userSpanStyle}>{`Unassigned`}</Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderPlannedRouteAccordionSummary);
