export enum ADDRESS_STATUS {
  VERIFIED = "VERIFIED",
  UNVERIFIED = "UNVERIFIED",
}

export enum API_RESPONSE_STATUS {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export enum TIMER_CONST {
  globalTimeForRefresh = 60,
  pullTimeForRefresh = 64,
}

export enum UnitOfMeasure {
  PIECES = "Pieces",
  GRAMS = "Grams",
}

export enum QcVerificationStatus {
  VERIFIED = "VERIFIED",
  VERIFIED_ISSUES = "VERIFIED_ISSUES",
  PARTIALLY_VERIFIED = "PARTIALLY_VERIFIED",
  PARTIALLY_VERIFIED_ISSUES = "PARTIALLY_VERIFIED_ISSUES",
  UNVERIFIED = "UNVERIFIED",
  ALL = "ALL",
}
