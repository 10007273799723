import { createStyles, makeStyles } from "@material-ui/core";
import { marginRight } from "styled-system";
import { COLORS } from "../config/theme/baseTheme";
import { marginLeft } from "styled-system";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: "100%",
      //overflow: "scroll",
    },
    formik: {
      margin: "auto",
    },
    notificatin:{
      display: "flex", 
      justifyContent: "flex-end"
    },
    hubSearch:{},
    graphDivision: {
      background: COLORS.WHITE,
      boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
      borderRadius: 12,
      padding: theme.spacing(3),
    },
    stagingAreaContent:{
      overflowY: "scroll",
      maxHeight: "72vh"
    },
    list:{
      overflowY: "scroll",
      maxHeight: "61vh"
    },
    titel: {
      borderRadius: 8,
      padding: theme.spacing(1),
      background: COLORS.ALICE_GRAY,
      textAlign: "center",
    },
    subHeading: {
      padding: theme.spacing(1),
    },
    heading:{
      fontWeight: 600,
      fontSize: 16
    },
    searchContainer:{
      flexGrow:1
    },
    searchHub:{
      "& .MuiFormControl-root": {
        flexGrow:1,
        marginRight: 10,
      },
    },
    icon: {
      width: "auto",
      height: "auto",
      fill: "none",
       "&.done":{
        marginBottom: 4,
        marginRight: 4
       },
       "&.plusIcon":{
        marginLeft: 4
       }
    },
    addButton:{
      border: '1px solid #0E5AA7',
      color: COLORS.PRIMARY_MAIN,
      background: COLORS.WHITE,
      marginLeft: 'auto',
      borderRadius: 16,
    },
    stagingLoc: {
      textAlign: "unset",
      marginTop: 12,
      minHeight: 185,
      "&.edited": {
        border: "1px dashed #C3C3C3",
      },

      "& p": {
        paddingLeft: 12,
      },
       "& .editedHeader": {
        paddingRight: 12,
        margin:'auto'
      },
      "& .heading": {
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
        "& .MuiInputBase-root": {
          width: '30%'
     },
      },
      "& .subHeading": {
        fontSize: 14,
        paddingTop: 12,
        color: COLORS.GREY_SCALE,
      },
      "& .MuiFormHelperText-root": {
        fontSize: 12,
        textAlign: "start",
        color: "red",
        paddingLeft: "unset",
      },
    },
    buttons: {
      width: "100%",
      "& .userBtn": {
        "&.secondary": {
          marginRight: theme.spacing(2),
        },
        flexGrow: 1,
      },
    },
    tiles: {
      padding: theme.spacing(1),
      
    },
    areaTile: {
      height: "calc(100vh - 172px)",
    },
    pilles: {
      gap: 12,
      margin: "16px 12px",
      "& .MuiChip-root": {
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        color: COLORS.GREY_SCALE,
        borderRadius: 48,
        height: 48,
        padding: 8,
      },
    },
    confirmDialogPaperPropsRoot: {
      width: 550,
      height: 250,
      margin: "auto",
      borderRadius: theme.spacing(1),
      "& .MuiDialogContent-root": {
        padding:'16px 0 0 0'
      },
    },
    confirmDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
     
      "& .contentItem": {
        "& p": {
          textAlign: "center",
          lineHeight: 1.2,
          "&.alignLeft": {
            textAlign: "left",
          },
        },
        "& .MuiGrid-item": {
          paddingTop: theme.spacing(2),
        },
        "&.heading": {
          padding: theme.spacing(0, 9, 3),
          "& p": {
            fontSize: 18,
            fontWeight: 600,
          },
         
          "& .MuiFormHelperText-root": {
            fontSize: 12,
            textAlign: "start",
            color: "red",
          },
        },
      },
    },
    confirmDialogButtonsContainer: {
      justifyContent: "center",
      paddingBottom: theme.spacing(5),
      "& .dialogBtn": {
        "&.secondary": {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
          marginRight: theme.spacing(1),
        },
        "&.primary": {
          paddingLeft: theme.spacing(7.25),
          paddingRight: theme.spacing(7.25),
          marginLeft: theme.spacing(1),
        },
      },
    },
  })
);
