import { combineReducers } from "@reduxjs/toolkit";
import { UserLoginState } from "../../Login/redux/loginSlice.types";
import routePlanningSlice from "../../RoutePlanning/redux/planningSlice";
import usersSlice from "../../Users/redux/usersSlice";
import userLoginSlice from "../../Login/redux/loginSlice";
import commonSlice from "./common/commonSlice";
import codReconciliationSlice from "../../Dashboard/ReconciliationDashboard/redux/reconciliationSlice";
import { PlanningOrderListState } from "../../RoutePlanning/redux/planningSlice.types";
import { CodReconciliationState } from "../../Dashboard/ReconciliationDashboard/redux/reconciliationSlice.types";
import { CommonState } from "./common/commonSlice.types";
import { UsersState } from "../../Users/redux/usersSlice.types";
import { PerformanceState } from "../../Dashboard/PerformanceDashboard/redux/performanceSlice.types";
import performanceSlice from "../../Dashboard/PerformanceDashboard/redux/performanceSlice";
import { RouteTrackingState } from "RouteTracking/redux/routeTrackingSlice.types";
import routeTrackingSlice from "../../RouteTracking/redux/routeTrackingSlice";
import hubSlice from "../../Hubs/redux/hubSlice";
import hubWiseDashboardSlice from "../../Dashboard/HubwiseDashboard/redux/hubWiseSlice";
import { HubState } from "Hubs/redux/hubSlice.types";
import { HubWiseState } from "Dashboard/HubwiseDashboard/redux/hubWiseSlice.types";
import { CNCOrderListState } from "../../ClickAndCollect/redux/clickAndCollectSlice.types";
import clickAndCollectSlice from "../../ClickAndCollect/redux/clickAndCollectSlice";
import valetTrolleySlice from "ValetTrolley/redux/valetTrolleySlice";
import { ValetTrolleyState } from "../../ValetTrolley/redux/valetTrolleySlice.types";
import carrefourDeliverySlice from "CarrefourDelivery/redux/carrefourDeliverySlice";
import { CarrefourDeliveryState } from "../../CarrefourDelivery/redux/carrefourDeliverySlice.types";
import { DelveDashboardState } from "../../Delve/OrderList/redux/delveSlice.types";
import delveSlice from "../../Delve/OrderList/redux/delveSlice";
import { ExpressDashboardState } from "Express/redux/expressSlice.types";
import expressSlice from "Express/redux/expressSlice";
import customerCommSlice from "CustomerCommunication/redux/customerCommSlice";
import { CustomerCommState } from "CustomerCommunication/redux/customerCommSlice.types";
import posSlice from "POSManagement/redux/posSlice";
import { PosState } from "POSManagement/redux/posSlice.types";
import { DriversDashboardState } from "DriverManagement/redux/driversSlice.types";
import driversSlice from "DriverManagement/redux/driversSlice";
import { CODReconState } from "CODPortal/express/redux/codPortalSlice.types";
import codPortalSlice from "CODPortal/express/redux/codPortalSlice";
import DelveDashboardSlice from "../../Delve/Dashboard/Redux/DelveDashboardSlice";
import { DelveDashboardSliceState } from "../../constants/DelveDashboardConstant";
import { StdCodReconState } from "CODPortal/standard/redux/stdCodPortalSlice.types";
import stdCodPortalSlice from "CODPortal/standard/redux/stdCodPortalSlice";
import stagingAreaSlice from "../../StagingArea/redux/stagingAreaSlice";
// All Routes
import { AllRoutesSliceInitialState } from "../../RoutePlanning/AllRoutes/utils/AllRouteConstant";
import allRoutesSlice from '../../RoutePlanning/AllRoutes/redux/allRoutesSlice'

const reducers = {
  userLogin: userLoginSlice,
  common: commonSlice,
  users: usersSlice,
  routePlanning: routePlanningSlice,
  codReconciliation: codReconciliationSlice,
  performance: performanceSlice,
  routeTracking: routeTrackingSlice,
  hub: hubSlice,
  hubWise: hubWiseDashboardSlice,
  clickAndCollect: clickAndCollectSlice,
  valetTrolley: valetTrolleySlice,
  carrefourDelivery: carrefourDeliverySlice,
  delveOrderList: delveSlice, // Delve Order List
  expressDashboard: expressSlice,
  drivers: driversSlice,
  pos: posSlice,
  codPortal: codPortalSlice,
  delveDashboard: DelveDashboardSlice,
  stdCodPortal: stdCodPortalSlice,
  stagingArea: stagingAreaSlice,
  allRoutes: allRoutesSlice,
  customerCommunication: customerCommSlice
};

export interface AppState {
  userLogin: UserLoginState;
  routePlanning: PlanningOrderListState;
  users: UsersState;
  common: CommonState;
  codReconciliation: CodReconciliationState;
  performance: PerformanceState;
  routeTracking: RouteTrackingState;
  hub: HubState;
  hubWise: HubWiseState;
  clickAndCollect: CNCOrderListState;
  valetTrolley: ValetTrolleyState;
  carrefourDelivery: CarrefourDeliveryState;
  delveOrderList: DelveDashboardState;
  expressDashboard: ExpressDashboardState;
  pos: PosState;
  drivers: DriversDashboardState;
  codPortal: CODReconState;
  delveDashboard: DelveDashboardSliceState;
  stdCodPortal: StdCodReconState;
  stagingArea: any;
  allRoutes: AllRoutesSliceInitialState
  customerCommunication: CustomerCommState
}

export default combineReducers(reducers);
