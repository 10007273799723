import React, { useCallback, forwardRef, ReactElement, Ref, useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../config/redux/reducers';
import { fetchCarrefourDeliveryStatuses, consignmentReplanning, fetchDeliveryReasons } from './redux/carrefourDeliverySlice';
import { createVTOrderReplanningPayload } from '../mocks/valetTrolley/response.transforms';

//common components
import CustomDialog from '../common/Dialog';
import SelectBox from '../common/SelectBox';
import TextBox from '../common/TextBox';
import DateRangePickerComponent from '../common/DateRangeComponent';

//Material UI Components
import { useStyles } from './CarrefourDeliveryOrderDetailsEdit.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Button } from '@material-ui/core';

//constants
import AppConstants from "../constants";

//Assets
import warning from "../assets/icons/warning.svg";
import { transformSlotValue } from "../utils/helpers.utils";
//Commenting Partial rejection functionality for now,To be included later
//import UpdatePartialRejection from './UpdatePartialRejection';
//import warningIcon from "./../assets/icons/warningOctagon.svg";


interface CarrefourDeliveryOrderDetailsEditProps {
  open: boolean;
  consignment: any;
  isExpress?: any;
  closePopup: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CarrefourDeliveryOrderDetailsEdit = (props: CarrefourDeliveryOrderDetailsEditProps) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, consignment, closePopup } = props;

  const { carrefourDeliveryStatuses, deliveryReasons, reasonsDropdownList, chdReplanningConsignmentSuccess, consignmentDataAfterReplanning, deliverySlotListCHD } = useSelector(
    (state: AppState) => state.carrefourDelivery
  )
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const [selectedStatus, setSelectedStatus] = useState(AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION);
  const [selectedSlot, setSelectedSlot] = useState(AppConstants.SELECT_NONE.value);
  const [selectedReason, setSelectedReason] = useState(AppConstants.SELECT_NONE.value);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_UI),
    endDate: moment().hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_UI)
  });
  const [showReason, setShowReason] = useState(false);
  const [commentsValue, setCommentsValue] = useState('');
  const [errors, setErrors] = useState({
    reason: "",
  });
  //Commenting Partial rejection functionality for now,To be included later
  /* const [isStatusDelivered, setIsStatusDelivered] = useState(false);  
  const [openUpdateRejectedDialog, setOpenUpdateRejectedDialog] = useState(false);
  const [openPartialRejectionPopup, setOpenPartailRejectionPopup] = useState(false); */

  let deliveryInformation: any = {};
  let metadata: any = {};
  let deliveryDate = "";

  if (consignment) {
    deliveryInformation = consignment.deliveryInformation;
    metadata = consignment.metadata;
    if (deliveryInformation && deliveryInformation.deliverySlot) {
      deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.DATE_FORMAT);
    }
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }
  //Commenting Partial rejection functionality for now, To be included later
  /* const confirmDialogPaperProps = {
    classes: {
      root: classes.confirmDialogPaperPropsRoot
    }
  }; */

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
  }

  const validateForm = useCallback(() => {
    let newErrors = {};
    if (selectedReason === AppConstants.SELECT_NONE.value) {
      newErrors = { ...newErrors, reason: "Please select reason" };
    }
    setErrors({ ...errors, ...newErrors });

    return Object.keys(newErrors).length;
  }, [errors, selectedReason]);

  const handleSlotChange = useCallback(
    (value: any) => {
      setSelectedSlot(value);
    },
    [],
  );

  const handleReasonChange = useCallback(
    (value: any) => {
      setSelectedReason(value);
      if (value) {
        setErrors({ ...errors, reason: "" });
      }
    },
    [errors],
  );

  const handleCommentsTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCommentsValue(event.target.value);
    },
    []
  );

  const handleDateChange = useCallback(
    (value: any) => {
      setSelectedDate(value);
    },
    [],
  );
  //Commenting Partial rejection functionality for now, To be included later
  /* const handlePartialRejectionUpdateActions = useCallback(
    (params: any) => {
      if (params.type === 'sumbit') {
        console.log("sumit");
      }
    }, []
  ) 

  const closePartialRejectionPopup = useCallback(
    () => {
      setOpenPartailRejectionPopup(false);
    },
    [],
  );

  /* const handleReplanningForDelivered = useCallback(
    () => {
      const payload = createVTOrderReplanningPayload({
        id: consignment.consignmentCode,
        commentsValue: commentsValue,
        selection: selectedStatus
      });
      dispatch(
        consignmentReplanning(
          {
            params: payload
          }
        )
      )
      closePartialRejectionPopup();
    }, [consignment, selectedStatus, commentsValue, closePartialRejectionPopup, dispatch]
  )
  
   const handleCHDOrderReplanning = useCallback(
    () => {
      if (selectedStatus === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.UNDELIVERED) {
        if (validateForm() === 0) {
          const payload = createVTOrderReplanningPayload({
            id: consignment.consignmentCode,
            selection: selectedStatus,
            reason: selectedReason,
            commentsValue: commentsValue
          });
          if (selectedReason !== AppConstants.SELECT_NONE.value) {
            const deliveryReason = deliveryReasons.filter((reason: any) => reason.reasonCode === selectedReason);
            if (deliveryReason.length) {
              payload.reason = deliveryReason[0]
            }
          }
          dispatch(
            consignmentReplanning({
              params: payload
            })
          )
        }
      } else {
        const payload = createVTOrderReplanningPayload({
          id: consignment.consignmentCode,
          status: selectedStatus,
          slot: selectedSlot,
          date: selectedDate
        });
        dispatch(
          consignmentReplanning({
            params: payload
          })
        )
      }
    },
    [consignment, selectedStatus, selectedDate, selectedSlot, commentsValue, deliveryReasons, selectedReason, validateForm, dispatch],
  ) */


  const handleCHDOrderReplanning = useCallback(
    () => {
      if (selectedStatus !== AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION) {
        if (selectedStatus === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.UNDELIVERED) {
          if (validateForm() === 0) {
            const payload = createVTOrderReplanningPayload({
              id: consignment.consignmentCode,
              selection: selectedStatus,
              reason: selectedReason,
              commentsValue: commentsValue
            });
            if (selectedReason !== AppConstants.SELECT_NONE.value) {
              const deliveryReason = deliveryReasons.filter((reason: any) => reason.reasonCode === selectedReason);
              if (deliveryReason.length) {
                payload.reason = deliveryReason[0]
              }
            }
            dispatch(
              consignmentReplanning(
                {
                  params: payload
                }
              )
            )
          }
        } else {
          const payload = createVTOrderReplanningPayload({
            id: consignment.consignmentCode,
            commentsValue: commentsValue,
            selection: selectedStatus
          });
          dispatch(
            consignmentReplanning(
              {
                params: payload
              }
            )
          )
        }
      } else {
        const payload = createVTOrderReplanningPayload({
          id: consignment.consignmentCode,
          status: selectedStatus,
          slot: selectedSlot,
          date: selectedDate
        });
        dispatch(
          consignmentReplanning(
            {
              params: payload
            }
          )
        )
      }
    },
    [consignment, selectedStatus, selectedDate, selectedSlot, commentsValue, deliveryReasons, selectedReason, validateForm, dispatch],
  )

  const dialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'update') {
        //Commenting Partial rejection functionality for now, To be included later
        /* 
        if (isStatusDelivered) {
          setOpenUpdateRejectedDialog(true);
        } else { */
        handleCHDOrderReplanning();

      } else {
        closePopup();
      }
    },
    [closePopup, handleCHDOrderReplanning],
  );

  //Commenting Partial rejection functionality for now, to be included later
  /* const handleRejectedDialogClose = useCallback(
    () => {
      setOpenUpdateRejectedDialog(false);
    },
    [],
  );

  const updateRejectedDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'save') {
        handleReplanningForDelivered();
      } else {
        setOpenPartailRejectionPopup(true);
      }
      handleRejectedDialogClose();
    },
    [handleRejectedDialogClose, handleReplanningForDelivered],
  ) */

  const handleStatusChange = useCallback(
    (value: any) => {
      setSelectedStatus(value);
      if (value === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.UNDELIVERED) {
        setShowReason(true);
        setSelectedReason(AppConstants.SELECT_NONE.value);
        //Commenting Partial rejection functionality for now,To be included later
        //setIsStatusDelivered(false);
      } else {
        if (value === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DELIVERED) {
          //Commenting Partial rejection functionality for now,To be included later
          //setIsStatusDelivered(true);
          setShowReason(false);
          setSelectedReason(AppConstants.SELECT_NONE.value);
        } else {
          setShowReason(false);
          setSelectedReason(AppConstants.SELECT_NONE.value);
          //Commenting Partial rejection functionality for now,To be included later
          //setIsStatusDelivered(false);
        }
      }
    }, []
  );



  const fetchAllDeliveryReasons = useCallback(
    () => {
      dispatch(
        fetchDeliveryReasons()
      );
    }, [dispatch]
  );


  const handleCHDStatuses = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchCarrefourDeliveryStatuses({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (chdReplanningConsignmentSuccess) {
      closePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chdReplanningConsignmentSuccess])

  /*
   * Called on page load, to set the value in the delivery slot from the constants which is amongst the delivery slot list.
   * If the order contains a delivery slot value which matches the value from the deliverySlotListCHD, sets that as a preselected 
   * value
   */
  useEffect(() => {
    if (deliveryInformation && deliverySlotListCHD) {
      setSelectedSlot(AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CONSIGNMENTS_DATA.DELIVERY_SLOT[0].value);
      deliverySlotListCHD[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode].forEach((slot: any) => {
        if (transformSlotValue(deliveryInformation.deliverySlot.deliverySlotTime) === transformSlotValue(slot.value)) {
          setSelectedSlot(deliveryInformation && deliveryInformation.deliverySlot.deliverySlotTime);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignment, countryCode])

  useEffect(() => {
    if (consignment) {
      setSelectedDate({
        startDate: moment(deliveryDate ? deliveryDate : new Date(), AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_UI),
        endDate: moment(deliveryDate ? deliveryDate : new Date(), AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_UI)
      });
      setCommentsValue("");
      setShowReason(false);
      setSelectedReason(AppConstants.SELECT_NONE.value);
      setSelectedStatus(AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignment]);

  useEffect(() => {
    if (consignment != null) {
      fetchAllDeliveryReasons();
      handleCHDStatuses(consignment);
    }
  }, [consignment, fetchAllDeliveryReasons, handleCHDStatuses]);

  //Commenting Partial rejection functionality for now, To be included later
  /* const getRejectedDialogTitleContent = () => {
    return (
      <Grid container style={{ paddingTop: "8px" }}>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <img src={warningIcon} alt="warning" style={{ width: "18px", height: "18px" }} /></Grid>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontSize: "14px", fontWeight: 700, textTransform: "uppercase", color: "#252525", paddingLeft: "12px" }}>Edit Rejected Items </Typography>
        </Grid>
      </Grid>
    )
  };

  const getRejectedDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography style={{ fontSize: "12px", fontWeight: 500, color: "#252525" }}>
            You will not be able to edit the order details after this status change.</Typography>
          <Typography style={{ fontSize: "12px", fontWeight: 500, color: "#252525", paddingTop: "12px" }}>
            Please go ahead and make changes to the item summary if you have any</Typography>
        </Grid>
      </Grid>
    )
  };

  const getRejectedDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { updateRejectedDialogActionHandler('edit') }}>{AppConstants.BUTTONS.EDIT_ITEMS}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { updateRejectedDialogActionHandler('save') }}>{AppConstants.BUTTONS.SAVE}</Button>
      </Grid>
    )
  } */

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label"> EDIT CONSIGNMENT : </span>
                    <span className="content"> {consignment.consignmentCode} </span>
                  </Typography>
                </Grid>
                {
                  consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata ?
                    (
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus)].join(" ")}>{consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus ? consignmentDataAfterReplanning.metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    ) : (
                      metadata && metadata.deliveryStatus &&
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', metadata && metadata.deliveryStatus)].join(" ")}>{metadata && metadata.deliveryStatus ? metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className="selectedContentArea" item>
          <Grid className="contentAreaContainer cancel" container>
            <Grid className="contentAreaItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Update Status</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <SelectBox
                    fullWidth
                    value={selectedStatus}
                    id="status"
                    inputProps={{
                      name: "status",
                      id: "sStatus"
                    }}
                    required
                    handleChange={handleStatusChange}
                    items={carrefourDeliveryStatuses || []}
                  ></SelectBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="selectedContentArea" item>
          {
            selectedStatus === AppConstants.VALET_TROLLEY_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_REPLANNING_SELECTION ?
              <Grid className="contentAreaContainer reschedule" container>
                <Grid className="contentAreaItem" item>
                  <Grid className="itemContainer" container>
                    <Grid className="itemLabel" item>
                      <Typography>Delivery Date</Typography>
                    </Grid>
                    <Grid className="itemInput" item>
                      <DateRangePickerComponent
                        fullWidth
                        keyId="EditConsignmentDeliveryDate"
                        placeholder="Delivery Date"
                        value={selectedDate}
                        isTextField={true}
                        autoUpdateInput={true}
                        autoApply={true}
                        maxSpan={{
                          day: "0"
                        }}
                        showDropdowns={true}
                        linkedCalendars={true}
                        locale={{ format: AppConstants.DATE_FORMAT_UI }}
                        updateDateRange={handleDateChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentAreaItem" item>
                  <Grid className="itemContainer" container>
                    <Grid className="itemLabel" item>
                      <Typography>Slot</Typography>
                    </Grid>
                    <Grid className="itemInput" item>
                      <SelectBox
                        fullWidth
                        value={selectedSlot}
                        id="EditConsignmentSlot"
                        inputProps={{
                          name: "slot",
                          id: "EditConsignmentSlot"
                        }}
                        handleChange={handleSlotChange}
                        items={deliverySlotListCHD[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode]}
                      ></SelectBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid className="contentAreaContainer cancel" container>
                {showReason ? (
                  <Grid className="contentAreaItem" item>
                    <Grid className="itemContainer" container>
                      <Grid className="itemLabel" item>
                        <Typography>Reason</Typography>
                      </Grid>
                      <Grid className="itemInput" item>
                        <SelectBox
                          fullWidth
                          value={selectedReason}
                          id="EditConsignmentReason"
                          inputProps={{
                            name: "reason",
                            id: "EditConsignmentReason"
                          }}
                          required
                          handleChange={handleReasonChange}
                          items={reasonsDropdownList || []}
                          error={!!errors.reason}
                          helperText={errors.reason}
                        ></SelectBox>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid className="contentAreaItem" item>
                  <Grid className="itemContainer" container>
                    <Grid className="itemLabel" item>
                      <Typography>Comments</Typography>
                    </Grid>
                    <Grid className="itemInput" item>
                      <TextBox
                        fullWidth
                        multiline
                        variant="outlined"
                        rows={4}
                        onChange={handleCommentsTextChange}
                        value={commentsValue}
                        textBoxId="EditConsignmentComments"
                        placeholderText="Enter a comment"
                      ></TextBox>
                    </Grid>
                  </Grid>
                </Grid>
                {showReason ? (
                  <Grid className="contentAreaItem" item style={{ height: "55px", background: "#E79832", borderRadius: "8px" }}>
                    <Grid style={{ display: "flex", margin: "16px" }}>
                      <img src={warning} alt="warning" />
                      <Typography style={{ fontSize: "14px", marginLeft: "10px", lineHeight: "17px", fontWeight: 500, color: "#FFFFFF", display: "flex", alignItems: "center" }}>This consignment will no longer be delivered.</Typography>
                    </Grid>
                  </Grid>) : null}
              </Grid>
          }
        </Grid>
      </Grid>
    )
  }
  const getDialogActions = () => {
    return (
      <Grid className={classes.buttons} container>
        <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="userBtn primary" variant="contained" onClick={() => { dialogActionHandler('update') }}>{AppConstants.BUTTONS.SAVE}</Button>
      </Grid>
    );
  };

  const handleDialogClose = useCallback(
    () => {
      closePopup();
    },
    [closePopup],
  );

  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={getDialogActions()}
        handleClose={handleDialogClose}
      ></CustomDialog>
      {/*Commenting Partial rejection functionality for now, To be included later*/}
      {/* <CustomDialog
        hideCloseIcon={true}
        open={openUpdateRejectedDialog}
        PaperProps={confirmDialogPaperProps}
        title={getRejectedDialogTitleContent()}
        content={getRejectedDialogDetailsContent()}
        actions={getRejectedDialogActions()}
        handleClose={handleRejectedDialogClose}
      ></CustomDialog> 
      <UpdatePartialRejection open={openPartialRejectionPopup} consignment={consignment} closePopup={closePartialRejectionPopup} handleDialogAction={handlePartialRejectionUpdateActions}></UpdatePartialRejection> */}
    </>
  )
}

export default CarrefourDeliveryOrderDetailsEdit;