import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
//common components
import { AppState } from "../../config/redux/reducers";
import AppConstants from "../../constants";
import {
  fetchOrdersFilterChipsDisplayCount,
  fetchPlanningOrderListByFilter,
  exportToExcel,
} from "../redux/customerCommSlice";
//redux
//Material UI Components
import { CellParams } from "../../common/GridWrapper/DataTable";
import { Grid, Typography } from "@material-ui/core";
//styles
import { useStyles } from "../customerComm.styles";
//assets

import locationIcon from "../../assets/icons/map-pin.svg";
import OrderDataWrapper from "../../orderDataWrapper";

import {createFilterPayloadExpress, expressOrdersDataTransform} from "../../mocks/customerCommunications/response.transforms";
import {
  getConsignmentStatusField,
  getOrderStatusField,
} from "../../mocks/express/response.transforms";


const CustomerCommunExpress = (props:any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {filter, onRowSelection, restFilterForm, deSelectCheckbox, selectedRoutes,handleCancel} = props
 
  const {ordersData,  loading, tableLoading } = useSelector(
    (state: AppState) => state.customerCommunication
  );

  const [customFilter, setCustomFilter] =useState({})

  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
      .TABLE_WRAPPER_DATA,
    HeaderData: AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.HeaderData.map(
      (header: any) => {
        let newHeader = { ...header };
        if (newHeader.field === "consignmentId") {
          newHeader.renderCell = (params: CellParams) => (
            <Typography className="idLink">{params.value}</Typography>
          );
        }
        if (newHeader.field === "status") {
          newHeader.renderCell = (params: any) =>
            params.value ? (
              <Grid
                className={classes.statusHeaderContainer}
                container
                alignItems="center"
              >
                <Typography
                  className={[
                    "orderTag",
                    getOrderStatusField("color", params.value),
                  ].join(" ")}
                >
                  {params.value ? params.value : ""}
                </Typography>
              </Grid>
            ) : (
              <></>
            );
        }
       
        if (newHeader.field === "actions") {
          newHeader.renderCell = (params: CellParams) => (
            <Typography className="actionsLink">{params.value}</Typography>
          );
        }
        if (newHeader.field === "deliveryArea") {
          newHeader.renderCell = (params: CellParams) => (
            <Grid container>
              <Grid item>{params.value}</Grid>
              <Grid item className={classes.infoSection}>
                <img
                  src={locationIcon}
                  alt="info-icon"
                  className={classes.infoIconStyle}
                />
              </Grid>
            </Grid>
          );
        }
        return newHeader;
      }
    ),
    defaultFiltersObj: {
      ...AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
        .TABLE_WRAPPER_DATA.defaultFiltersObj,
      dateRange: {
        ...AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
          .TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange,
        value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT),
        },
      },
    },
  });

  useEffect(() => {
    if(filter && Object.keys(filter).length !== 0){
        let updatedFilters: any = {};
        updatedFilters = { ...ordersTableWrapperData.defaultFiltersObj, status: { ...ordersTableWrapperData.defaultFiltersObj.status, value: filter.deliveryStatus },
        deliveryZone: { ...ordersTableWrapperData.defaultFiltersObj.deliveryZone, value: filter.zones },
         deliveryArea: { ...ordersTableWrapperData.defaultFiltersObj.deliveryArea, value: filter.deliveryAreas }, 
         deliveryEndTime: filter.deliveryEndTime,
         deliveryStartTime: filter.deliveryStartTime,
         dateRange:{...ordersTableWrapperData.defaultFiltersObj.dateRange, value: filter.dateRange }, hub:{ ...ordersTableWrapperData.defaultFiltersObj.hubName, value: filter.hub }
         }
         setCustomFilter(updatedFilters)
    }

}, [filter])

useEffect(()=>{
  if(selectedRoutes){
    let headerButtons = ordersTableWrapperData.headerButtons.map((btn: any) => {
      switch (btn.name) {
        case "selectedRow":
          if(selectedRoutes.length){
            btn.hide = false;
            btn.displayName=`${selectedRoutes.length} Rows Selected`
          }
          else{
            btn.hide = true;
          }  
          break;
        default:
          break;
      }
      return btn;
    });
    setOrdersTableWrapperData({ ...ordersTableWrapperData, headerButtons: headerButtons });
  }
},[selectedRoutes])

  const loadTableData = (payload: any) => {
    dispatch(
      fetchPlanningOrderListByFilter({
        filters: payload,
      })
    );
  };

  const exportToExcelAction = (payload: any) => {
    dispatch(
      exportToExcel({
        payload: payload,
      })
    );
  };

  const fetchOrderFilterChips = (payload: any) => {
    dispatch(
      fetchOrdersFilterChipsDisplayCount({
        payload: payload,
      })
    );
  };

  const handleDeselect = () =>{
    handleCancel()
  }


  return (
    <>
      <Grid container >
        <Grid
          item
          xs={12}
         
        >
          <OrderDataWrapper
            trasformData ={expressOrdersDataTransform}
            ordersData={ordersData}
            ordersTableWrapperData={ordersTableWrapperData}
            loadTableData={loadTableData}
            exportToExcelAction={exportToExcelAction}
            fetchOrderFilterChips={fetchOrderFilterChips}
            customFilter={customFilter}
            createFilterPayload ={createFilterPayloadExpress}
            defaultFilterChip={AppConstants.CUSTOMER_COMM_CONSTANTSـSTD.CONSIGNMENTS_DATA.DEFAULT_FILTER_CHIP}
            loading={loading}
            tableLoading={tableLoading}
            callOnActionOnly={true}
            onRowSelection={onRowSelection}
            checkboxSelection={true}
            restFilterForm={restFilterForm}
            deSelectCheckbox={deSelectCheckbox}
            defaultSelectAll={true}
            handleDeselect={handleDeselect}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerCommunExpress;
