import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) => ({
  timeSelector: {
    cursor: "pointer",
    fontWeight: 600,
    paddingTop: 12,
    borderWidth: 1,
    borderRadius: 8,
    paddingBottom: 4,
    borderColor: COLORS.BLACK_INPUT_BORDER,
    paddingLeft:8,
    width: 170,
    "&.timeException":{
      outline: '#F45C5D  auto 1px'
    }
  },
  label:{
    position: "absolute",
    transform: "translate(0px, 0px) scale(0.75)",
    color:COLORS.BLACK_INPUT_LABEL,
    fontSize:12,
  }

}));