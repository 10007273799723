import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles(() =>
    createStyles({
        tooltip: {
            background: COLORS.BLACK
        },
        dashboardChartStyle: {
            margin: 0,
            padding: 0,
        },
    })
);