import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
            height: "100%",
            backgroundColor: COLORS.ROUTE_CONTAINER_BACKGROUND,
            overflow: "scroll"
        },
        container: {
            margin: "24px 0px 0px 0px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
            backgroundColor: COLORS.WHITE
        },
        title: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "29px",
            color: COLORS.MINE_SHAFT,
            display: "flex",
            alignItems: "center"
        },
        subTitle: {
            display: "flex",
            justifyContent: "center",
            fontSize: 10,
            lineHeight: "16px",
            fontWeight: 500,
            fontFamily: "Montserrat",
            color: COLORS.TEXT_PRIMARY
        },
        select: {
            borderRadius: 8,
            background: COLORS.WHITE,
            minWidth: 150
        },
        slotsSelect: {
            borderRadius: 8,
            background: COLORS.WHITE
        },
        icon: {
            "& .MuiOutlinedInput-input": {
                height: "40px",
            },
        },
        tilesView: {
            flexGrow: 1,
        },
        cratesMainDivStyle: {
            background: "#FFFFFF",
            border: "1px solid #D2D2D2",
            borderRadius: "8px",
        },
        mainDivSearchColumn: {
            display: "grid",
            float: "left",
            marginTop: "auto",
            marginBottom: "auto",
            height: "40px",
            width: "427px",
        },
        inputBoxFontStyle: {
            fontFamily: "Montserrat",
            fontSize: "12px",
            lineHeight: "1px",
            color: "#252525 !important",
            paddingLeft: "2px",
            "& .MuiFormLabel-root": {
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "1px",
                color: "#252525",
                paddingLeft: "2px",
            },
            "& .MuiFormLabel-root.Mui-focused": {
                color: "#252525 !important",
            },
            "& .Mui-focused": {
                color: "#252525",
                fontWeight: "500",
            },
            "&$focused": {
                color: "#252525 !important",
            },
        },
        searchStyle: {
            fontFamily: "Montserrat",
            fontSize: "12px",
            lineHeight: "8px",
            textAlign: "left",
            height: "40px",
            background: "#FFFFFF",
            border: "1px solid #B2B2B2",
            borderColor: "#f44336",
            borderRadius: "8px",
            outline: "0 !important",
            color: "#252525",
            backgroundColor: "#FFFFFF !important",
            boxShadow: "0px 4px 4px rgba(205, 205, 205, 0.23)",
            "&:hover": { backgroundColor: "#FFFFFF" },
            "& .MuiSelect-select:focus": { background: "rgba(255,255,255,0.1)" },
        },
        inputRounded: {
            fontFamily: "Montserrat",
            fontSize: "12px",
            lineHeight: "8px",
            textAlign: "left",
            height: "40px",
            background: "#FFFFFF",
            border: "1px solid #B2B2B2",
            borderRadius: "8px",
            outline: "0 !important",
            backgroundColor: "#FFFFFF !important",
            boxShadow: "0px 4px 4px rgba(205, 205, 205, 0.23)",
            "&:hover": { backgroundColor: "#FFFFFF" },
            "& .MuiSelect-select:focus": { background: "rgba(255,255,255,0.1)" },
        },
        tableDataRow: {
            height: "56px",
            background: "#FFFFFF",
        },
        tableHeadStyle: {
            height: "28px",
            backgroundColor: "rgba(230, 230, 230, 0.24)"
        },
        tableDataRowActive: {
            height: "56px",
            backgroundColor: COLORS.MADISON_LIGHT,
        },
        tableDataFirstRow: {
            padding: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            minWidth: "160px",
            maxWidth: "160px",
            fontWeight: 600,
            height: "56px",
            fontFamily: "Montserrat",
            fontSize: "14px",
            lineHeight: "17px",
            color: COLORS.COPY,
            "&:last-child": {
                padding: 0,
                paddingLeft: "10px",
                borderRight: "none",
            },
        },
        tableDataRowCell: {
            padding: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            minWidth: "160px",
            maxWidth: "160px",
            fontWeight: 500,
            height: "56px",
            fontFamily: "Montserrat",
            fontSize: "14px",
            lineHeight: "17px",
            color: COLORS.COPY,
            "&:last-child": {
                padding: 0,
                paddingLeft: "10px",
                borderRight: "none",
            },
        },
        tableDataRowCellActive: {
            padding: 0,
            paddingLeft: "20px",
            height: "56px",
            fontFamily: "Montserrat",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#0E5AA7",
            borderTop: `1px solid ${COLORS.SECONDARY_DARK}`,
            borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
            borderRight: `1px solid ${COLORS.SECONDARY_DARK}`,
        },
        gridRow: {
            background: "#fff",
            height: "64px",
            "&$selected": {
                background: COLORS.SUB_HEADER_SELECTED,
                backgroundColor: COLORS.SUB_HEADER_SELECTED,
                height: "64px",
            },
            "&$selected, &$selected:hover": {
                background: COLORS.SUB_HEADER_SELECTED,
                backgroundColor: COLORS.SUB_HEADER_SELECTED,
                height: "64px",
            },

        },
        SlotDropDownButtonStyle: {
            cursor: "pointer",
            outline: "0 !important",
            "&:hover": { backgroundColor: "transparent" },
        },
        SlotOpenDropDownStyle: {
            marginLeft: "6px",
            cursor: "pointer",
            outline: "0 !important",
        },
        collapseTableCell: {
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "none",
            borderTop: "none",
            paddingLeft: "0px",
            "&.MuiTableCell-sizeSmall": {
                "&:last-child": {
                    paddingRight: "0px",
                },
            },
        },
        collapseTableCellDiv: {
            maxHeight: "200px",
            overflow: "auto",
        },
        collapseTableHeaderCellFirst: {
            padding: 0,
            paddingLeft: "15px",
            paddingRight: "10px",
            height: "36px",
            borderTop: "none",
        },
        collapseTableHeaderCell: {
            padding: 0,
            paddingLeft: "15px",
            paddingRight: "10px",
            height: "36px",
            fontSize: "12px",
            lineHeight: "15px",
            color: COLORS.COPY,
            borderTop: `1px solid ${COLORS.SECONDARY_DARK}`,
            "&:last-child": {
                padding: 0,
                paddingLeft: "15px",
                paddingRight: "10px",
                borderRight: "none",
            },
        },
        collapseTableDataRow: {
            height: "36px",
            background: COLORS.WHITE,
        },
        collapseTableDataRowCellEmpty: {
            padding: 0,
            paddingLeft: "15px",
            paddingRight: "10px",
            height: "36px",
            fontSize: "12px",
            lineHeight: "15px",
            color: COLORS.BLACK,
            background: COLORS.WHITE,
            borderBottom: "none",
        },
        collapseTableDataRowCell: {
            padding: 0,
            paddingLeft: "15px",
            paddingRight: "10px",
            fontSize: "12px",
            lineHeight: "15px",
            height: "36px",
            color: COLORS.BLACK,
            background: COLORS.WHITE,
            borderRight: `1px solid ${COLORS.SECONDARY_DARK}`,
            borderTop: `1px solid ${COLORS.SECONDARY_DARK}`,
            "&:last-child": {
                padding: 0,
                paddingLeft: "15px",
                paddingRight: "10px",
                borderRight: "none",
            },
        },
        noRowsSection: {
            borderLeft: `1px solid ${COLORS.SECONDARY_DARK}`,
            marginLeft: "215px",
        },
        noRowsText: {
            marginLeft: "445px",
            padding: "24px",
            fontSize: "12px",
        },
        loadingTable: {
            position: "absolute",
            top: "50%",
            left: "50%",
            translate: "transform(-50%, -50%)",
        },
        popperStyle: {
            fontSize: "10px",
            fontWeight: 500,
            color: COLORS.WHITE,
        },
        arrowStyle: {
            color: COLORS.BLACK,
        },
        tooltipStyle: {
            background: COLORS.BLACK,
        },
        statusTitle: {
            color: "#979797",
            fontSize: "10px",
            fontWeight: 500,
            fontFamily: "Montserrat",
            lineHeight: "18px",
        },
        statusData: {
            color: "#4D4D4D",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "20px",
        },
        progressText: {
            color: "#686868",
            fontWeight: 500,
            fontSize: "10px",
            lineHeight: "12px",
            display: "flex",
            flexWrap: "wrap",
            fontFamily: "Montserrat",
            paddingTop: "8px"
        },
        linearProgressRoot: {
            height: "8px",
            borderRadius: "4px",
        },
        linearProgress: {
            marginTop: "8px",
        },
        linearProgressBarRoot: {
            borderRadius: 4,
            backgroundColor: COLORS.SECONDARY_DARK,
        },
        linearProgressColorDefault: {
            backgroundColor: COLORS.SECONDARY_DARK,
        },
        linearProgressBarColor: {
            backgroundColor: COLORS.LIGHT_PERSIAN_GREEN,
        },
        linearProgressBarColorInProgress: {
            backgroundColor: COLORS.GOLDEN_BELL,
        },
        loadingDiv: {
            display: "table",
            height: "89%",
            width: "100%",
            textAlign: "center",
        },
        loadingDivInner: {
            height: "100%",
            width: "100%",
            verticalAlign: "middle",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        spanStyle: {
            marginTop: "auto",
            marginBottom: "auto",
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: 500,
            lineHeight: "21px",
            color: COLORS.COPY,
            padding: "24px"
        },
        handoverPercent: {
            fontSize: "12px",
            lineHeight: "15px",
            fontWeight: 600,
            color: COLORS.PERSIAN_GREEN,
            paddingLeft: "14px"
        },
        ontimePercent: {
            fontSize: "12px",
            lineHeight: "15px",
            fontWeight: 600,
            color: COLORS.PALE_PURPLE,
            paddingLeft: "14px"
        }
    })
);