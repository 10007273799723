import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow.svg";
import { COLORS } from "../../../config/theme/baseTheme";
import { isUndefined, isInputAnArray, isNonEmptyArray, checkIfInputIsObject, checkIfInputIsValidObjectWithKeys, countObjectProperties } from "../../../utils/helpers.utils";
import { TAB_VALUES_CONSTANT } from "../../../constants/DelveDashboardConstant";

interface DelveOrderDetailsByCratesDetailsContentInterface {
  classes: any;
  metadata: any;
  crates_details_object: any;
  show_reported_item_view: any;
  tabValue: any;
}

const DelveOrderDetailsByCratesDetailsContent = (props: DelveOrderDetailsByCratesDetailsContentInterface) => {
  const { classes, metadata, crates_details_object, show_reported_item_view, tabValue } = props;

  const getClassNameForStatus = (verified: number) => {
    if (!isUndefined(verified)) {
      if (verified === 0) {
        return classes.cratesOrderDetailsUnVerifiedSpanStyle;
      }
      return classes.cratesOrderDetailsVerifiedSpanStyle;
      // return classes.cratesOrderDetailsPartialVerifiedSpanStyle;
    }
    return classes.cratesOrderDetailsUnVerifiedSpanStyle;
  };

  const getStatusText = (verified: number) => {
    let status: string = "Unverified";
    if (!isUndefined(verified)) {
      if (Number(verified) > 0) {
        status = "Verified";
      }
    }
    return status;
  };

  return (
    <Grid className="tabularSection crates" item>
      {tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value && (
        <Accordion>
          <AccordionSummary
            expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
            aria-controls="crate-section-content"
            id="crate-section-content"
            className={classes.accordionSummary}
          >
            <Grid container direction="column">
              <Typography className="header">Crate Summary</Typography>
              <Typography className="header">
                <span className="smallHeading">Total Crates {` : ${Object.keys(crates_details_object?.crates_data_obj).length} `} </span>
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div" className="content" style={{ width: "100%" }}>
              {crates_details_object !== undefined &&
              crates_details_object?.crates_data_obj !== undefined &&
              typeof crates_details_object?.crates_data_obj === "object" &&
              Object.keys(crates_details_object?.crates_data_obj).length > 0 ? (
                <Grid className="itemsContainer" container direction="column">
                  <Grid className="itemContainer header" container>
                    <Grid className="itemColumn item1" item xs={3}>
                      <Typography className={classes.cratesOrderDetailsValueSpanStyle}>Crate ID</Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                      <Typography className={classes.cratesOrderDetailsValueSpanStyle}>Item Qty</Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                      <Typography className={classes.cratesOrderDetailsValueSpanStyle}>Reported Qty</Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                      <Typography className={classes.cratesOrderDetailsValueSpanStyle}>Status</Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                      <Typography className={classes.cratesOrderDetailsValueSpanStyle}>Issue Cost</Typography>
                    </Grid>
                  </Grid>
                  {Object.keys(crates_details_object?.crates_data_obj).map((key_name: any, key_name_index: any) => (
                    <Grid key={`${key_name_index}-key_name_index-crates_details_object`} className="item" item style={{ paddingTop: "16px" }}>
                      <Grid className="itemContainer" container>
                        <Grid className="itemColumn" item xs={3}>
                          <Grid container alignItems="center">
                            <Typography className={classes.cratesOrderDetailsHeadingSpanStyle}>{key_name || ""}</Typography>
                          </Grid>
                        </Grid>
                        <Grid className="itemColumn" item xs={2}>
                          <Grid container alignItems="center" className={classes.paddingLeftStyle}>
                            <Typography className={classes.cratesOrderDetailsHeadingSpanStyle}>{crates_details_object?.crates_data_obj[key_name].productQty || "0"}</Typography>
                          </Grid>
                        </Grid>
                        <Grid className="itemColumn" item xs={2}>
                          <Grid container alignItems="center">
                            <Typography className={classes.cratesOrderDetailsHeadingSpanStyle}>{crates_details_object?.crates_data_obj[key_name].reportedQty || "0"}</Typography>
                          </Grid>
                        </Grid>
                        <Grid className="itemColumn" item xs={2}>
                          <Grid container alignItems="center">
                            <div className={getClassNameForStatus(crates_details_object?.crates_data_obj[key_name].verified)}>
                              {getStatusText(crates_details_object?.crates_data_obj[key_name].verified)}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="itemColumn" item xs={2}>
                          <Grid container alignItems="center">
                            <Typography className={classes.cratesOrderDetailsHeadingSpanStyle}>{`${Number(crates_details_object?.crates_data_obj[key_name].issueCost).toFixed(2) || "0"} ${
                              (metadata && metadata.currency) || ""
                            }`}</Typography>
                          </Grid>
                        </Grid>
                        {crates_details_object?.crates_data_obj[key_name].issue_found !== undefined && crates_details_object?.crates_data_obj[key_name].issue_found && (
                          <Grid className="itemColumn" item xs={1}>
                            <SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} className={classes.cratesOrderDetailsIconStyle} onClick={(e: any) => show_reported_item_view(true, key_name)} />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No Crates to display</Typography>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      <Grid container spacing={1} className={classes.cratesOrderDetailsSummaryDiv}>
        <Grid item xs={4} className={classes.detailsItemContentDivStyle}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography className={classes.cratesOrderDetailsHeadingSpanStyle}>Consignment Value</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.cratesOrderDetailsValueSpanStyle}>{`${
                tabValue === TAB_VALUES_CONSTANT.RETURNS.value
                  ? metadata !== undefined && metadata?.returnedCost !== undefined
                    ? Number(metadata?.returnedCost).toFixed(2)
                    : 0
                  : metadata !== undefined && metadata?.amountToCollect !== undefined
                  ? Number(metadata?.amountToCollect).toFixed(2)
                  : 0
              } ${metadata.currency}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.detailsItemContentDivStyle}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography className={classes.cratesOrderDetailsHeadingSpanStyle}>Total issue cost</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.cratesOrderDetailsValueSpanStyle}>{`${
                crates_details_object !== undefined && crates_details_object.issues_reported_cost !== undefined ? Number(crates_details_object.issues_reported_cost).toFixed(2) : 0
              } ${(metadata && metadata.currency) || ""}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {crates_details_object !== undefined &&
        crates_details_object.item_issues_object !== undefined &&
        typeof crates_details_object.item_issues_object === "object" &&
        Object.keys(crates_details_object.item_issues_object).length > 0 ? (
          Object.keys(crates_details_object.item_issues_object).map((issue_key: any, issue_key_index: any) => (
            <Grid item xs={4} key={`${issue_key_index}-issue_key_index-item_issues_object`} className={classes.detailsItemContentDivStyle}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography className={classes.cratesOrderDetailsHeadingSpanStyle}>{`${
                    crates_details_object.item_issues_object[issue_key] !== undefined && crates_details_object.item_issues_object[issue_key].reasonDescription !== undefined
                      ? crates_details_object.item_issues_object[issue_key].reasonDescription
                      : "Issue Item"
                  }`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.cratesOrderDetailsValueSpanStyle}>{`${
                    crates_details_object.item_issues_object[issue_key] !== undefined && crates_details_object.item_issues_object[issue_key].issues_cost !== undefined
                      ? Number(crates_details_object.item_issues_object[issue_key].issues_cost).toFixed(2)
                      : "0"
                  } ${(metadata && metadata.currency) || ""}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(DelveOrderDetailsByCratesDetailsContent);
