import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
//common components
import { AppState } from "../../config/redux/reducers";
import AppConstants from "../../constants";
import {
  fetchOrdersFilterChipsDisplayCount,
  fetchPlanningOrderListByFilterHistory,
  exportToExcel,
} from "../redux/customerCommSlice";
//redux
//Material UI Components
import { CellParams } from "../../common/GridWrapper/DataTable";
import { Grid, Typography } from "@material-ui/core";
//styles
import { useStyles } from "../customerComm.styles";
//assets

import locationIcon from "../../assets/icons/map-pin.svg";
import OrderDataWrapper from "../../orderDataWrapper";
import { historyOrdersDataTransform, createFilterPayload} from "../../mocks/customerCommunications/response.transforms";

import {
  getConsignmentStatusField,
  getOrderStatusField,
} from "../../mocks/express/response.transforms";
interface ExpressProps {
  match?: any;
  location?: any;
}

const History = (props: ExpressProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ordersHistoryFiltersDropdowns,  ordersHistory, tableLoading, loading } = useSelector(
    (state: AppState) => state.customerCommunication
  );
  const { hubCode } = useSelector(
    (state: AppState) => state.common
  );



  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.CUSTOMER_COMM_HISTORY_CONSTANTS.CONSIGNMENTS_DATA
      .TABLE_WRAPPER_DATA,
    HeaderData: AppConstants.CUSTOMER_COMM_HISTORY_CONSTANTS.CONSIGNMENTS_DATA.TABLE_WRAPPER_DATA.HeaderData.map(
      (header: any) => {
        let newHeader = { ...header };
        if (newHeader.field === "consignmentId") {
          newHeader.renderCell = (params: CellParams) => (
            <Typography className="idLink">{params.value}</Typography>
          );
        }
        if (newHeader.field === "status") {
          newHeader.renderCell = (params: any) =>
            params.value ? (
              <Grid
                className={classes.statusHeaderContainer}
                container
                alignItems="center"
              >
                <Typography
                  className={[
                    "orderTag",
                    getOrderStatusField("color", params.value),
                  ].join(" ")}
                >
                  {params.value ? params.value : ""}
                </Typography>
              </Grid>
            ) : (
              <></>
            );
        }
        if (newHeader.field === "deliveryStatus") {
          newHeader.renderCell = (params: any) =>
            params.value ? (
              <Grid
                className={classes.statusHeaderContainer}
                container
                alignItems="center"
              >
                <Typography
                  className={[
                    "tag",
                    getConsignmentStatusField("color", params.value),
                  ].join(" ")}
                >
                  {params.value ? params.value : ""}
                </Typography>
              </Grid>
            ) : (
              <></>
            );
        }
        if (newHeader.field === "actions") {
          newHeader.renderCell = (params: CellParams) => (
            <Typography className="actionsLink">{params.value}</Typography>
          );
        }
        if (newHeader.field === "deliveryArea") {
          newHeader.renderCell = (params: CellParams) => (
            <Grid container>
              <Grid item>{params.value}</Grid>
              <Grid item className={classes.infoSection}>
                <img
                  src={locationIcon}
                  alt="info-icon"
                  className={classes.infoIconStyle}
                />
              </Grid>
            </Grid>
          );
        }
        return newHeader;
      }
    ),
    defaultFiltersObj: {
      ...AppConstants.CUSTOMER_COMM_HISTORY_CONSTANTS.CONSIGNMENTS_DATA
        .TABLE_WRAPPER_DATA.defaultFiltersObj,
      dateRange: {
        ...AppConstants.CUSTOMER_COMM_HISTORY_CONSTANTS.CONSIGNMENTS_DATA
          .TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange,
        value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT),
        },
      },
    },
  });

  const loadTableData = (payload: any) => {
    dispatch(
      fetchPlanningOrderListByFilterHistory({
        filters: payload,
      })
    );
  };

  const exportToExcelAction = (payload: any) => {
    dispatch(
      exportToExcel({
        payload: payload,
      })
    );
  };

  const fetchOrderFilterChips = (payload: any) => {
    dispatch(
      fetchOrdersFilterChipsDisplayCount({
        payload: payload,
      })
    );
  };


  useEffect(() => {
    if(hubCode ||ordersTableWrapperData && ordersTableWrapperData.HeaderData ){
    if (ordersTableWrapperData && ordersTableWrapperData.HeaderData) {
      let headerData = ordersTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === "hub") {
            header.filterObj.items = ordersHistoryFiltersDropdowns.hubCodeList || [];
          }
          if (header.filterObj.fieldName === "hubName") {
            header.filterObj.items = ordersHistoryFiltersDropdowns.hubNameList || [];
          }
          if (header.filterObj.fieldName === "deliveryStatus") {
            header.filterObj.items = ordersHistoryFiltersDropdowns.statusList || [];
          }
          if (header.filterObj.fieldName === "deliveryArea") {
            header.filterObj.items = ordersHistoryFiltersDropdowns.customerAreaList || [];
          }
          if (header.filterObj.fieldName === "deliveryZone") {
            header.filterObj.items = ordersHistoryFiltersDropdowns.deliveryZonesList || [];
          }
          if (header.filterObj.fieldName === "orderType") {
            header.filterObj.items = ordersHistoryFiltersDropdowns.orderTypeList || [];
          }
          if (header.filterObj.fieldName === "reason") {
            header.filterObj.items = ordersHistoryFiltersDropdowns.reasonList || [];
          }
        
        }
        return header;
      });
      setOrdersTableWrapperData({
        ...ordersTableWrapperData,
        HeaderData: headerData,
      });
}

    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersHistoryFiltersDropdowns, hubCode]);


  return (
    <>

        <Grid
          item
          xs={12}

        >
          <OrderDataWrapper
            ordersTableWrapperData={ordersTableWrapperData}
            trasformData ={historyOrdersDataTransform}
            ordersData={ordersHistory}
            loadTableData={loadTableData}
            fetchOrderFilterChips={fetchOrderFilterChips}
            loading={loading}
            tableLoading={tableLoading}
            createFilterPayload ={createFilterPayload}
            exportToExcelAction={exportToExcelAction}
            customClass={classes.historyContent}
          />
        </Grid>
   
    </>
  );
};

export default History;
