import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow-icon.svg";

import { isNonEmptyObject, isObject } from "../../../utils/helpers.utils";
import { ALL_ROUTES_TEXT_CONTANT } from "../utils/AllRouteConstant";

import RenderPlannedRouteAccordionSummary from "./RenderPlannedRouteAccordionSummary";
import RenderPlannedRouteAreaAccordion from "./RenderPlannedRouteAreaAccordion";

interface CustomPlannedRouteViewInterface {
  classes: any;
  routeObj: any;
  routeName: string;
  // Accordion Summary
  handleRouteAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string) => void;
  handleRoutAreaAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, subRouteAreaName?: string) => void;
  handleRouteSlotAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, subRouteAreaName?: string) => void;
  handleRouteOrderAccordianToggle?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number, subRouteAreaName?: string) => void;
  // CheckBox
  handleRouteCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string) => void;
  handleAreaCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string) => void;
  handleSlotCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string) => void;
  handleOrderCheckboxToggle?: (e: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  //
  orderActionPopUpViewChange?: (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  removeOrderFromRoute?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  addOrderToAnotherRoute?: (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => void;
  handleDeleteRoute?: (e: React.ChangeEvent<HTMLInputElement>, value: any) => void;
  viewMode?: boolean;
}

const CustomPlannedRouteView = (props: CustomPlannedRouteViewInterface) => {
  const {
    classes,
    routeObj,
    routeName,
    viewMode = false,
    //
    handleRouteAccordianToggle,
    handleRoutAreaAccordianToggle,
    handleRouteSlotAccordianToggle,
    handleRouteOrderAccordianToggle,
    //
    handleRouteCheckboxToggle,
    handleSlotCheckboxToggle,
    handleAreaCheckboxToggle,
    handleOrderCheckboxToggle,
    //
    orderActionPopUpViewChange,
    removeOrderFromRoute,
    addOrderToAnotherRoute,
    handleDeleteRoute,
  } = props;

  const renderNoItemsToDisplay = () => <Typography>{ALL_ROUTES_TEXT_CONTANT.NO_ITEMS_TO_DISPLAY}</Typography>;

  const routeContainsSubrouteOrArea = (obj: any) => obj && obj?.routeDataObj && isObject(obj.routeDataObj) && isNonEmptyObject(obj.routeDataObj);

  const isSubRouteView = (obj: any) => obj.isSubRoutePresent || obj.subRouteCreated;

  const showAreaViewBasedOnRouteDataName = (routeObj: any, routeDataName: string) => {
    if (isSubRouteView(routeObj)) {
      if (routeDataName !== ALL_ROUTES_TEXT_CONTANT.AREA_DATA) {
        return true;
      }
    } else {
      if (routeDataName === ALL_ROUTES_TEXT_CONTANT.AREA_DATA) {
        return true;
      }
    }
    return false;
  };

  return (
    <Accordion key={`plannedRoute-${routeObj.routeId}`} expanded={routeObj.isOpened} TransitionProps={{ unmountOnExit: true, timeout: 0 }} className={classes.plannedRouteMainAccordianStyle}>
      <AccordionSummary
        expandIcon={<SvgIcon component={downArrowIcon} onClick={(e: any) => handleRouteAccordianToggle && handleRouteAccordianToggle(e, routeName)} />}
        onClick={(e: any) => handleRouteAccordianToggle && handleRouteAccordianToggle(e, routeName)}
        className={`${classes.plannedRouteMainAccordianSummaryStyle} ${routeObj.openOrderActionPopUp ? classes.isAccordianSelected : ""}`}
      >
        <RenderPlannedRouteAccordionSummary classes={classes} routeObj={routeObj} routeName={routeName} handleRouteCheckboxToggle={handleRouteCheckboxToggle} viewMode={viewMode} />
      </AccordionSummary>
      <AccordionDetails className={classes.plannedRouteMainAccordianDetailsStyle}>
        {routeObj.isOpened && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
              {routeContainsSubrouteOrArea(routeObj)
                ? Object.keys(routeObj.routeDataObj).map(
                    (routeDataName: string, routeDataNameIndex: number) =>
                      showAreaViewBasedOnRouteDataName(routeObj, routeDataName) && (
                        <RenderPlannedRouteAreaAccordion
                          classes={classes}
                          routeObj={routeObj}
                          routeName={routeName}
                          routeDataName={routeDataName}
                          routeDataNameIndex={routeDataNameIndex}
                          handleRoutAreaAccordianToggle={handleRoutAreaAccordianToggle}
                          handleRouteSlotAccordianToggle={handleRouteSlotAccordianToggle}
                          handleRouteOrderAccordianToggle={handleRouteOrderAccordianToggle}
                          handleAreaCheckboxToggle={handleAreaCheckboxToggle}
                          handleSlotCheckboxToggle={handleSlotCheckboxToggle}
                          handleOrderCheckboxToggle={handleOrderCheckboxToggle}
                          orderActionPopUpViewChange={orderActionPopUpViewChange}
                          removeOrderFromRoute={removeOrderFromRoute}
                          addOrderToAnotherRoute={addOrderToAnotherRoute}
                          handleDeleteRoute={handleDeleteRoute}
                          viewMode={viewMode}
                        />
                      )
                  )
                : renderNoItemsToDisplay()}
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomPlannedRouteView;
