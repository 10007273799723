import React from 'react';
import { Radio, RadioProps, SvgIcon } from '@material-ui/core';
import { useStyles } from './CustomRadio.styles';
import { ReactComponent as radioCheckedIcon } from './../../assets/icons/radio-checked-icon.svg';
import { ReactComponent as radioUncheckedIcon } from './../../assets/icons/radio-unchecked-icon.svg';

interface CustomRadioProps extends RadioProps {

}

const CustomRadio = (props: CustomRadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<SvgIcon className={classes.icon} component={radioCheckedIcon} viewBox="0 0 18 18" />}
      icon={<SvgIcon className={classes.icon} component={radioUncheckedIcon} viewBox="0 0 18 18" />}
      {...props}
    />
  );
}

export default CustomRadio;