import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./noData.style";
import { ReactComponent as searchIcon } from "./../assets/icons/search-icon.svg";
//Material UI Components
import { Grid, Typography, Button, SvgIcon } from "@material-ui/core";


const NoDataComponent = (props: any) => {
    const {icon,title,actionText, actionCallback} = props
    const classes = useStyles();

  return (
              <Grid item className={classes.content}>
                <Grid
                  container
                  direction="column"
                  className={classes.noDataContent}
                >
                 {icon && <SvgIcon
                    className={classes.icon}
                    viewBox="0 0 48 49"
                    component={icon}
                  />}
                  <Typography>{title}</Typography>
                  {actionText && <Button
                    className={classes.actionButton}
                    onClick={actionCallback ? actionCallback : null}
                  >
                    {actionText}
                  </Button>}
                </Grid>
              </Grid>
 
  );
};

export default NoDataComponent;
