import React, { useCallback, useEffect, useState } from 'react';
import './config/theme/globalStyles.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Header from './common/Header';
import Routes from './routes';
import { AppState } from './config/redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetUserError } from './Login/redux/loginSlice';
import { clearDeletedRoute,clearBulkDeletedRoute, clearUpdatedRoute, resetCreateRouteSuccess, resetPlanningError, clearPublishedRoutesErrors, clearPublishedRoutes, resetDraftRoute } from './RoutePlanning/redux/planningSlice';
import AppConstants from './constants';
import { resetUsersError, resetUsersResetPwd, setLoggedInUser } from './Users/redux/usersSlice';

interface AppProps {
  location?: any;
  history: any;
}

const App = (props: AppProps) => {

  const { history } = props;
  const {
    isLoggedIn,
    error: userLoginError,
    role,
    isAdmin,
    isCollectionPointManager,
    isCHDManager,
    isVTManager,
    isQCManager,
    isDispatcher,
    isOnlyExpressDispatcher,
    isOnlyStandardDispatcher,
    isLastMileViewer,
    isOnlyStdLastMileViewer,
    isOnlyExpLastMileViewer
  } = useSelector((state: AppState) => state.userLogin);

  const { error: planningOrderListError, routeCreateSuccess, updatedRoute, deletedRoute, bulkDeletedRoute, draftRoute, publishedRoutes, publishedRoutesErrors } = useSelector(
    (state: AppState) => state.routePlanning
  );
  const { error: usersError, resetPwdSuccess } = useSelector(
    (state: AppState) => state.users
  );
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const dispatch = useDispatch();
  
  const hasHeader = isLoggedIn;

  const [openSideMenuOnClick, setOpenSideMenuOnClick] = useState(false);

  const handleSideMenuOpen = useCallback(
    (openState: boolean) => {
      setOpenSideMenuOnClick(openState);
    },
    [],
  )

  useEffect(() => {
    const unlisten = history.listen((route: any) => {
      if (AppConstants.ROUTES.HOME === route.pathname) {
        dispatch(
          setLoggedInUser()
        )
      }
      if (userLoginError) {
        dispatch(
          resetUserError()
        );
      }
      if (planningOrderListError) {
        dispatch(
          resetPlanningError()
        );
      }
      if (usersError) {
        dispatch(
          resetUsersError()
        );
      }
      if (routeCreateSuccess) {
        dispatch(
          resetCreateRouteSuccess()
        );
      }
      if (draftRoute.route) {
        dispatch(
          resetDraftRoute()
        );
      }
      if (resetPwdSuccess) {
        dispatch(
          resetUsersResetPwd()
        );
      }
      if (publishedRoutes.length) {
        dispatch(
          clearPublishedRoutes()
        )
      }
      if (publishedRoutesErrors.length) {
        dispatch(
          clearPublishedRoutesErrors()
        )
      }
      if (route.pathname !== AppConstants.ROUTES.PLANNING) {
        if (updatedRoute) {
          dispatch(
            clearUpdatedRoute()
          );
        }
        if (deletedRoute) {
          dispatch(
            clearDeletedRoute()
          );
        }
        if (bulkDeletedRoute) {
          dispatch(
            clearBulkDeletedRoute()
          );
        }
      }
    });
    return () => {
      unlisten();
    }
  }, [history, planningOrderListError, userLoginError, usersError, updatedRoute, deletedRoute, bulkDeletedRoute, routeCreateSuccess, resetPwdSuccess, publishedRoutes, draftRoute, publishedRoutesErrors, dispatch]);
  
  return (
    <>
      {hasHeader && <Header openSideMenuOnClick={openSideMenuOnClick} handleDrawerOnClick={handleSideMenuOpen} />}
      <main className={`routesContainer ${hasHeader ? "hasHeader" : ""} ${openSideMenuOnClick ? "open" : ""}`}>
        <Routes
          role={role}
          isLoggedIn={isLoggedIn}
          isAdmin={isAdmin}
          isDispatcher={isDispatcher}
          isCHDManager={isCHDManager}
          isCollectionPointManager={isCollectionPointManager}
          isVTManager={isVTManager}
          isQCManager={isQCManager}
          countryCode={countryCode}
          isOnlyExpressDispatcher={isOnlyExpressDispatcher}
          isOnlyStandardDispatcher={isOnlyStandardDispatcher}
          isLastMileViewer={isLastMileViewer}
          isOnlyStdLastMileViewer={isOnlyStdLastMileViewer}
          isOnlyExpLastMileViewer={isOnlyExpLastMileViewer}
        />
      </main>
    </>
  );
};

export default withRouter(App);

