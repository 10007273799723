import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(0.75)
    },
    popper: {
      '&.MuiAutocomplete-popperDisablePortal': {
        position: "relative"
      },
      '& .MuiAutocomplete-paper': {
        boxShadow: "none",
        margin: 0
      },
      '& .MuiAutocomplete-option': {
        minHeight: 'auto',
        padding: theme.spacing(0.75, 0),
        '&:last-child': {
          paddingBottom: 0
        },
        '&[aria-selected="true"]': {
          backgroundColor: COLORS.TRANSPARENT,
        },
        '&[data-focus="true"]': {
          backgroundColor: COLORS.TRANSPARENT,
        },
        '& .optionName': {
          paddingLeft: theme.spacing(2.25),
          lineHeight: 1.4
        }
      },
      '& .MuiAutocomplete-listbox': {
        padding: 0,
        maxHeight: "100%",
        overflow: "hidden"
      }
    }
  })
)