import { createStyles, makeStyles, Theme } from "@material-ui/core";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tilesView: {
            flexGrow: 1,
        },
        globalStatusTiles: {
            background: "#FFFFFF",
            borderRadius: "8px",
            height: "58px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
            padding: "8px 20px",
            display: "flex",
            justifyContent: "space-between"
        },
        tileHeading: {
            fontSize: "16px",
            lineHeight: "21px",
            fontWeight: 600
        },
        tileSubHeading: {
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 400
        },
        tileData: {
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px"
        },
        title: {
            fontSize: "24px",
            fontWeight: 600
        },
      
    }),
);