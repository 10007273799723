/* eslint-disable no-use-before-define */
import React,{useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox, IconButton, InputAdornment,SvgIcon } from "@material-ui/core";
import searchableDropdownStyles from "../SearchableDropdownStyles";
import { ReactComponent as downArrowIcon } from "../../../assets/icons/down-arrow.svg";
import Chip from "@material-ui/core/Chip";
import AppConstants from "../../../constants";

export interface SelectItemProps {
  name?: string;
  value: any;
}
interface AutoCompleteProps {
  onSelectAll: (isSelected: any) => void;
  onClearOptions:() => void;
  onToggleOption:(selectedOptions: any) => void;
  getOptionLabel:(option: any) => string;
  selectedVal: any;
  id: string;
  items:  Array<SelectItemProps>;
  selectedValues: any;
  label: string
  limitTags?: any
  multiple?: boolean
}

const MultiSelect = ({
  items,
  selectedValues,
  label,
  limitTags,
  id,
  onToggleOption,
  onClearOptions,
  selectedVal,
  onSelectAll,
  getOptionLabel,
  multiple,
}:AutoCompleteProps) => {
  const allSelected = items.length === selectedValues.length;
    const [isOpen, setIsOpen] = useState(false);
  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected);
  };

  const handleChange = (event:any, selectedOptions:any, reason:any) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (multiple && selectedOptions.find((option:any) => option.value === "all")) {
        handleToggleSelectAll();
      } else {
        onToggleOption && onToggleOption(selectedOptions);
      }
    } else if (reason === "clear") {
      onToggleOption(selectedOptions)
      //onClearOptions && onClearOptions();
    }
  };
  const optionRenderer = (option:any, { selected }:any) => {
    const selectAllProps =
      option.value === "all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};

    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = (params:any) => (
    <TextField
      {...params}
      label={label}
      placeholder="search hub"
      InputLabelProps={{ classes: { root: classes.labelOnInputBoxFontStyle },shrink: true }}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        variant:"standard",
        className: isOpen ? classes.isDropdownOpen : classes.autocompleteInputDivStyle,
        classes: { root: classes.labelHeaderOnInputBoxFontStyle },
      }}
     
    />
  );
  const getOptionSelected = (option:any, anotherOption:any) =>
  option.value === anotherOption.value;
  const filter = createFilterOptions();
  const classes = searchableDropdownStyles();
  return (
    <Autocomplete
      multiple
      size="small"
      id={id}
      className={classes.searchableDropdown}
      limitTags={limitTags}
      options={items}
      openOnFocus={true}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      value={selectedValues}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue) {
          return filtered;
        }
        return [{ name: "Select all", value: "all" }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      renderTags={(tagValue, getTagProps) => {
        if (selectedVal.indexOf(AppConstants.SELECT_ALL.value) > -1) {
          return <Chip size="small" label={AppConstants.SELECT_ALL.name} />;
        } else {
          return tagValue.map((option, index) => (
            <Chip
              size="small"
              {...getTagProps({ index })}
              label={option.name}
            />
          ));
        }
      }}
      popupIcon={<SvgIcon className={classes.popupIndicatorOpenStyle} component={downArrowIcon} />}
      classes={{
        clearIndicatorDirty: classes.clearIndicatorStyle,
        popupIndicator: classes.popupIndicatorStyle,
        paper: classes.paperStyles
      }}
    />
  );
};

MultiSelect.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: (value:any) => value,
};

export default MultiSelect;
