import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { CustomerCommState } from "./customerCommSlice.types";
import AppConstants from "../../constants";
import moment from "moment";
import { transformSelectValues, saveFile, transformCountryAndZones } from "../../utils/helpers.utils";
import { stat } from "fs";


export const fetchFormFilterDropdown = createAsyncThunk(
  "Communications/FetchFormFilterDropdown",
  async ( {
    params
  }: {
    params: any;
  },
    { rejectWithValue }
  ) => {
    try {
      const {orderType, countryCode} = params
      
      let returnData;
      if(orderType ==='EXPRESS'){
        const request = {
          url:  `communication/dashboard/filters/${params.filter}`,
          method: EApiMiddlewareMethods.GET,
        } as any;
        const {data} = await networkHandler(request, false, true, false, false, true);
        returnData = data
      }
      else{
        const request = {
          url:  `communication/filters/${params.filter}`,
          method: EApiMiddlewareMethods.GET,
        } as any;
        const {data} = await networkHandler(request, false, true, true);
        returnData = data
      }
      return returnData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCommunicationReasonsExpress = createAsyncThunk(
  "Communications/FetchCommunicationReasonsExpress",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'reasons/get',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCommunicationReason= createAsyncThunk(
  "Communications/FetchCommunicationReason",
  async (params, { rejectWithValue })  => {
    try {
      const request = {
        url:  "communication/get-communication-reasons/Default",
        method: EApiMiddlewareMethods.GET,
      } as any;
        const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendCommunication= createAsyncThunk(
  "Communications/SendCommunication",
  async ( {
    params
  }: {
    params: any;
  },
    { rejectWithValue }
  )  => {
    try {
      const request = {
        url:  "communication/send-communication",
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
        const {data} = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendCommunicationExpress = createAsyncThunk(
  "Communications/SendCommunicationExpress",
  async ( {
    params
  }: {
    params: any;
  },
    { rejectWithValue }
  )  => {
    try {
      const request = {
        url:  "communication/orders-delayed-reason",
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCountryAndArea = createAsyncThunk(
  "Communications/FetchCountryAndArea",
  async ( {
    params
  }: {
    params: any;
  },
    { rejectWithValue }
  ) => {
   
    const Country :any= AppConstants.COUNTRY_MAP
    const {countryCode} = params
    try {
      const request = {
        url: 'pos/country',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      request.headers = {
        "site-id": Country[countryCode],
      };
      const { data } = await networkHandler(request, false, false, true, true, false, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const fetchDynamicFiltersOrdersDropdownValues = createAsyncThunk(
  "Communications/FetchDynamicFiltersOrdersDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFilterFormsOrdersDropdownValues = createAsyncThunk(
  "RoutePlanning/FetchFilterFormsOrdersDropdownValues",
  async ({
    filters,
    hubCode
  }: {
    filters: any
    hubCode: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `communication/filters/${hubCode}`,
        method: EApiMiddlewareMethods.POST,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningOrderListByFilter = createAsyncThunk(
  "Communications/FetchOrdersByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrdersFilterChipsDisplayCount = createAsyncThunk(
  "Communications/FetchOrdersFilterChipsDisplayCount",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/displayFilterCount',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningOrderListByFilterHistory = createAsyncThunk(
  "Communications/FetchPlanningOrderListByFilterHistory",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'communication/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchExpressOrderListByFilterHistory = createAsyncThunk(
  "Communications/FetchExpressOrderListByFilterHistory",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'communication/history/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchConsignmentHistory = createAsyncThunk(
  "ExpressOrderDetails/FetchConsignmentHistory",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `status/getOrderStatuses?type=ORDER&sort=DESC&orderBy=updatedAt&consignmentCode=${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStatusOnClick = createAsyncThunk(
  "ConsignmentDetails/FetchStatusOnClick",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `delayedUpdates/fetch/snapshot/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, false, false, false, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddressStatus = createAsyncThunk(
  "ExpressOrderDetails/GetAddressStatus",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/address-status`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrderLogs = createAsyncThunk(
  "ExpressOrderDetails/FetchOrderLogs",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `log/getOrderLogs?type=ORDER&consignmentCode=${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchDriversList = createAsyncThunk(
  "DriversAssignment/FetchDriversList",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `driver/getAllDrivers`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignDriverManually = createAsyncThunk(
  "DriverAssignment/AssignDriverManually",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/assign-driver`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "Communications/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/communication',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcelExpress = createAsyncThunk(
  "Communications/ExportOrderToExcelExpress",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'communication/export',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const downloadOrderLogs = createAsyncThunk(
  "Communications/ExportOrderLogs",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/export-logs',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportInvoicePdf = createAsyncThunk(
  "Communications/ExportInvoicePdf",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'v2/order/view-invoice',
        method: EApiMiddlewareMethods.POST,
        data: params,
        responseType: "bytes"
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchExpressOrderDeliveryStatuses = createAsyncThunk(
  "Communications/FetchExpressOrderDeliveryStatuses",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/statuses/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const expressOrderReplanning = createAsyncThunk(
  "Communications/ExpressOrderReplanning",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/replanning',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const replanDuringPartialRejection = createAsyncThunk(
  "Communications/ReplanAndPartialRejection",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/replan-order-line-items',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const submitOrderComments = createAsyncThunk(
  "Communications/SubmitOrderComments",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/add-comment',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeDialog = createAsyncThunk("Communications/CloseDialog", () => {
  return false;
});
export const resetData= createAsyncThunk("Communications/resetData", () => {
  return false;
});



export const userLogout = createAsyncThunk("UserLogin/Logout", () => {
  return false;
});

export const updateFormFilters = createAsyncThunk("Communications/UpdateFormFilters", ({
  filters
}: {
  filters: any
}) => {
  return filters;
});


const initialState: CustomerCommState = {
  loading: false,
  tableLoading: false,
  exportError: "",
  ordersFilterChipsDisplayCount: {
    consignmentCount: {
      UNASSIGNED: 0,
      ON_HOLD: 0,
      ASSIGNED: 0,
      OUT_FOR_DELIVERY: 0,
      COMPLETED: 0,
    }
  },
  ordersFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    deliverySlotList: [],
    deliveryZonesList: [],
    addressStatusList: [],
    statusList: [],
    driverList: [],
    vehicleList: [],
    paymentTypeList: [],
    deliveryArea: [],
    orderStatusList: [],
    executionStatusList: []
  },
  ordersHistoryFiltersDropdowns: {
    customerArea:[],
    hubId: [],
    hubName: [],
    initiatedBy: [],
    orderType: [],
    reason: [],
    zone: []
  },
  ordersFilterFormDropdowns:{
    statusList: [],
    deliveryArealist:[]
  },
  ordersData: {
    elements: [],
    pageNumber: 0
  },
  ordersHistory: {
    elements: [],
    pageNumber: 0
  },
  hubDetail: {
    hubs: [],
    zones: []
  },
  error: "",
  errorCode: "",
  isPolled: false,
  consignmentStatuses: [],
  orderLogs: [],
  driversList: [],
  excelLoading: false,
  invoiceData: "",
  invoiceUrl:"",
  displayInvoice: false,
  invoiceMessage: "",
  invoiceConsignment: "",
  driverAssigned: false,
  orderDeliveryStatuses: [],
  reasonsDropdownList: [],
  orderCommentsList: [],
  deliveryReasons: {},
  rejectionReasons: {},
  orderCommentsReasons: {},
  consignmentDataAfterReplanning: {},
  replanningConsignmentSuccess: false,
  rejectionReasonList: [],
  productsAfterUpdateLineItem: {},
  revisedAmountAfterCalculation: "",
  consignmentDataAfterAddressUpdate: {},
  isAddressUpdated: false,
  orderCommentsAfterSubmit: {},
  areOrderCommentsAdded: false,
  filterFormDropDown:{},
  formFilter:{},
  success:""
};

const ADDRESS_STATUS_TEMP =[{code: "PENDING", value: "PENDING"},{code: "UNVERIFIED", value: "UNVERIFIED"},{code: "VERIFIED", value: "VERIFIED"}]
const expressSlice = createSlice({
  name: "Express",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchFormFilterDropdown.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFormFilterDropdown.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        if(payload.results){
          state.filterFormDropDown = payload.results
        }
        else{
          state.filterFormDropDown = payload
        }
      })
      .addCase(fetchFormFilterDropdown.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
       // state.filterFormDropDown = testRes
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningOrderListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningOrderListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.isAddressUpdated = false;
        state.ordersData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.results.elements]
              : [...state.ordersData.elements, ...payload.results.elements],
          pageNumber: payload?.results?.pageNumber,
          totalElements: payload?.results?.totalElements,
          totalPages: payload?.results?.totalPages
        };
        if (payload && payload.results && payload.results.filters) {
          state.ordersFiltersDropdowns = {
            hubCodeList: transformSelectValues(payload.results.filters.hubCodes || []),
            hubNameList: transformSelectValues(payload.results.filters.hubNames || []),
            deliverySlotList: transformSelectValues(payload.results.filters.deliverySlots || []),
            deliveryZonesList: transformSelectValues(payload.results.filters.deliveryZones || []),
            statusList: transformSelectValues(payload.results.filters.statuses || []),
            orderStatusList: transformSelectValues(payload.results.filters.orderStatuses || []),
            driverList: transformSelectValues(payload.results.filters.drivers || []),
            vehicleList: transformSelectValues(payload.results.filters.vehicles || []),
            paymentTypeList: transformSelectValues(payload.results.filters.paymentTypes || []),
            deliveryArea: transformSelectValues(payload.results.filters.areas || []),
            addressStatusList: transformSelectValues(ADDRESS_STATUS_TEMP),
            executionStatusList: transformSelectValues(payload.results.filters.deliveryExecutionStatus || [])
          };
        }
      })
      .addCase(fetchPlanningOrderListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
    
      .addCase(fetchPlanningOrderListByFilterHistory.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
        state.ordersHistory= {
          elements: [],
          pageNumber: 0
        }
      })
      .addCase(fetchPlanningOrderListByFilterHistory.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.isAddressUpdated = false;
        state.ordersHistory = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.elements]
              : [...state.ordersHistory.elements, ...payload.elements],
          pageNumber: payload?.pageNumber,
          totalElements: payload?.totalElements,
          totalPages: payload?.totalPages
        };

        if (payload &&  payload?.filters) {
          state.ordersHistoryFiltersDropdowns = {
            hubCodeList: transformSelectValues(payload?.filters?.hubCode || []),
            hubNameList: transformSelectValues(payload?.filters?.hubName || []),
            initiatedByList: transformSelectValues(payload?.filters?.initiatedBy || []),
            deliveryZonesList: transformSelectValues(payload?.filters?.zone || []),
            orderTypeList: transformSelectValues(payload?.filters?.orderType || []),
            reasonList: transformSelectValues(payload?.filters?.reason || []),
            customerAreaList: transformSelectValues(payload?.filters?.customerArea || []),
          };
        }
      })
      .addCase(fetchPlanningOrderListByFilterHistory.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchExpressOrderListByFilterHistory.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
        state.ordersHistory= {
          elements: [],
          pageNumber: 0
        }
      })
      .addCase(fetchExpressOrderListByFilterHistory.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.isAddressUpdated = false;
        state.ordersHistory = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.results.elements]
              : [...state.ordersData.elements, ...payload.results.elements],
          pageNumber: payload.results.pageNumber,
          totalElements: payload.results.totalElements,
          totalPages: payload.results.totalPages
        };
        if (payload && payload.results && payload.results.filters) {
          state.ordersHistoryFiltersDropdowns = {
            hubCodeList: transformSelectValues(payload?.results?.filters?.hubCode || []),
            hubNameList: transformSelectValues(payload?.results?.filters?.hubName || []),
            initiatedByList: transformSelectValues(payload?.results?.filters?.initiatedBy || []),
            deliveryZonesList: transformSelectValues(payload?.results?.filters?.zone || []),
            orderTypeList: transformSelectValues(payload?.results?.filters?.orderType || []),
            reasonList: transformSelectValues(payload?.results?.filters?.reason || []),
            customerAreaList: transformSelectValues(payload?.results?.filters?.customerArea || []),
          };
        }
      })
      .addCase(fetchExpressOrderListByFilterHistory.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchConsignmentHistory.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchConsignmentHistory.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.consignmentStatuses = payload.results.statuses || [];
        state.orderLogs = [];
      })
      .addCase(fetchConsignmentHistory.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
     
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Sent_Communication_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportToExcelExpress.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcelExpress.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Sent_Communication_Express_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcelExpress.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(downloadOrderLogs.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(downloadOrderLogs.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Order_Logs_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(downloadOrderLogs.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportInvoicePdf.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(exportInvoicePdf.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        const data = payload.results.Invoices && payload.results.Invoices.length ? payload.results.Invoices[0] : [];
        state.invoiceConsignment = payload.results.Invoices && payload.results.Invoices.length ? payload.results.Invoices[0].ConsignmentNo : "";
        state.displayInvoice = payload.results.Invoices && payload.results.Invoices.length;
        if(data?.FileUrl){
          state.invoiceUrl = data?.FileUrl
        }
        else if(data?.FileDataBytes){
         const pdfData = `data:application/pdf;base64,${data.FileDataBytes}`;
          state.invoiceData = pdfData ;
        }
        state.invoiceMessage = payload.results.Message;
      })
      .addCase(exportInvoicePdf.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchFilterFormsOrdersDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFilterFormsOrdersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
       
      })
      .addCase(fetchFilterFormsOrdersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.error.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.replanningConsignmentSuccess = false;
      })
      .addCase(closeDialog.fulfilled, (state, action) => {
        state.consignmentDataAfterReplanning = {};
        state.productsAfterUpdateLineItem = {};
        state.consignmentDataAfterAddressUpdate = {};
        state.isPolled = false;
        state.orderCommentsAfterSubmit = {};
        state.areOrderCommentsAdded = false;
      })
      .addCase(resetData.fulfilled, (state, action) => {
                state.success=""
                state.hubDetail = {
                  hubs: [],
                  zones: []
                }
                state.filterFormDropDown = {}
      })
      .addCase(fetchCountryAndArea.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
    .addCase(fetchCountryAndArea.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        const test = transformCountryAndZones(payload.data)
        state.hubDetail.hubs = test?.hubs
        state.hubDetail.zones = test?.zoneList
       
      })
    .addCase(fetchCountryAndArea.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      }) 
      .addCase(sendCommunication.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.success= ""
      })
      .addCase(sendCommunication.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.success = payload
      })
      .addCase(sendCommunication.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.success= ""
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(sendCommunicationExpress.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.success= ""
      })
      .addCase(sendCommunicationExpress.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.success = payload.results
      })
      .addCase(sendCommunicationExpress.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.success= ""
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      
      .addCase(fetchCommunicationReason.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchCommunicationReason.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        let deliveryReasonsList: any = [];
        if (payload.reasons) {
          deliveryReasonsList = payload.reasons.map((reason: any) => {
            return {
              code: reason.reasonCode,
              value: reason.reasonDescription
            };
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
       
      })
      .addCase(fetchCommunicationReason.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })

      .addCase(fetchCommunicationReasonsExpress.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchCommunicationReasonsExpress.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let deliveryReasonsList: any = [];
          if (payload.results && payload.results.reasons) {
            deliveryReasonsList = payload.results.reasons.map((reason: any) => {
              return {
                code: reason.reasonCode,
                value: reason.reasonDescription
              };
            });
          }
          state.loading = false;
          state.errorCode = "";
          state.error = "";
          state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
      })
      .addCase(fetchCommunicationReasonsExpress.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
   
  },
});

export default expressSlice.reducer;