import moment from "moment";

import AppConstants from "../../../constants";
import { API_RESPONSE_STATUS } from "../../../constants/CommonConstants";

export const STATUSES = {
  VERIFIED: { color: "OCEAN_GREEN", hintText: "" },
  UNVERIFIED: { color: "CORAL_PINK", hintText: "" },
  VERIFIED_ISSUES: { color: "ALERT_ORANGE", hintText: "" },
  PARTIALLY_VERIFIED: { color: "AVATAR_OCEAN", hintText: "" },
  PARTIALLY_VERIFIED_ISSUES: { color: "DARK_GOLD_ORANGE", hintText: "" },
  NEW: { color: "PICTON_BLUE", hintText: "" },
  READY_TO_PREPARE: { color: "CREAM_CAN", hintText: "" },
  READY_TO_DISPATCH: { color: "BLUE", hintText: "" },
  PREPARING: { color: "VISTA_BLUE", hintText: "" },
  PREPARED: { color: "SEA_GREEN", hintText: "" },
  OUT_FOR_DELIVERY: { color: "GOLDEN_BELL", hintText: "" },
  ON_HOLD: { color: "SCAMPI", hintText: "" },
  PENDING_DELIVERY: { color: "TROPICAL_BLUE", hintText: "" },
  DELIVERED: { color: "SEA_GREEN2", hintText: "" },
  PARTIALLY_DELIVERED: { color: "LUCKY", hintText: "" },
  CANCELLED: { color: "DUSTY_GRAY", hintText: "" },
  "RETURNED_TO_STORE/HUB": { color: "JUNGLE_GREEN", hintText: "" },
  DELIVERY_FAILED: { color: "BLACK", hintText: "" },
  PACKING_COMPLETE: { color: "MINSK", hintText: "" },
  ASSIGNED: { color: "BLUE_HAZE", hintText: "" },
};

export interface DATE_TYPE_INITIAL_STATE_INTERFACE {
  startDate: any;
  endDate: any;
}

export const DATE_TYPE_INITIAL_STATE: DATE_TYPE_INITIAL_STATE_INTERFACE = {
  startDate: moment(new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
  endDate: moment(new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT),
};
export interface ALL_ROUTE_INITIAL_STATE_INTERFACE {
  loading: boolean;
  tabValue: any;
  selectedDate: DATE_TYPE_INITIAL_STATE_INTERFACE;
  // Snack Bar
  openSnackbar: boolean;
  openPublishRouteSnackBar: boolean;
  snackbarMessage: any;
  snackbarPublishMessage: string;
  snackbarType: any;
  // Consignment
  expandAll: boolean;
  plannedConsignmentsData: any;
  unplannedConsignmentsData: any;
  selectedConsignments: any;
  selectedRoute: any;
  // Map
  isListView: boolean;
  consignmentMarkersForMap: any;
  consignmentMarkers: any;
  firstMapCordinates: any;
  mapCenter: any;
  mapZoom: number;
  mapPolylineMarker: CustomLocation[];
  polylineColor: string;
  // Assign Driver
  showDriverAssignedPopup: boolean;
  openUpdateConfirmDialog: boolean;
  driverValue: any;
  helperValue: any;
  vehicleValue: any;
  //
  subDriverValue: any;
  subHelperValue: any;
  subVehicleValue: any;
  // Publish
  openPublishDialog: any;
  //
  openOrderActionPopUp: boolean;
  statusValue: Array<string>;
  slotValue: Array<string>;
  zoneValue: Array<string>;
  consignmentId: string;
  // Remove-Add Order to new Route
  openAssignRoutePopUp: boolean;
  selectedConsignmentToChangeRoute: Array<any>;
  availableRouteRecord: Array<any>;
  // Add Remove Order from Route
  selectedRouteObj: any;
  orderDataObj: any;
  openAddRouteConfirmDialog: boolean;
  openRemoveRouteConfirmDialog: boolean;
  // Create New Route
  openMoveOrderToNewRoute: boolean;
  draftedRouteDetails: any;
  // Route Details
  totalRecords: number;
  totalOrders: number;
  totalCrates: number;
  // Create Sub route
  selectedConsignmentsForSubRoute: string[];
  openCreateSubRouteDialog: boolean;
  openDeleteSubRouteDialog: boolean;
  selectedRouteDeletion: any;
  // Sub Route Save
  selectedSubRoute: any;
  openSubRouteOrderActionPopUp: boolean;
}

export const ALL_ROUTE_INITIAL_STATE: ALL_ROUTE_INITIAL_STATE_INTERFACE = {
  loading: false,
  tabValue: AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED,
  selectedDate: DATE_TYPE_INITIAL_STATE,
  // Snack Bar
  openSnackbar: false,
  openPublishRouteSnackBar: false,
  snackbarMessage: "",
  snackbarPublishMessage: "",
  snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
  // Consignment
  expandAll: false,
  plannedConsignmentsData: {},
  unplannedConsignmentsData: {},
  selectedConsignments: [],
  selectedRoute: {},
  // Map
  isListView: true,
  consignmentMarkersForMap: [],
  consignmentMarkers: [],
  firstMapCordinates: { lat: 0, lng: 0 },
  mapCenter: { lat: 0, lng: 0 },
  mapZoom: 12, // max is 22
  mapPolylineMarker: [],
  polylineColor: "",
  // Assign Driver
  showDriverAssignedPopup: false,
  openUpdateConfirmDialog: false,
  driverValue: AppConstants.SELECT_NONE.value,
  helperValue: AppConstants.SELECT_NONE.value,
  vehicleValue: AppConstants.SELECT_NONE.value,
  //
  subDriverValue: AppConstants.SELECT_NONE.value,
  subHelperValue: AppConstants.SELECT_NONE.value,
  subVehicleValue: AppConstants.SELECT_NONE.value,
  // Publish
  openPublishDialog: false,
  //
  openOrderActionPopUp: false,
  statusValue: [],
  slotValue: [],
  zoneValue: [],
  consignmentId: "",
  // Remove-Add Order to new Route
  openAssignRoutePopUp: false,
  selectedConsignmentToChangeRoute: [],
  availableRouteRecord: [],
  // Add Remove Order from Route
  selectedRouteObj: {},
  orderDataObj: {},
  openAddRouteConfirmDialog: false,
  openRemoveRouteConfirmDialog: false,
  // Create New Route
  openMoveOrderToNewRoute: false,
  draftedRouteDetails: {},
  // Route Details
  totalRecords: 0,
  totalOrders: 0,
  totalCrates: 0,
  // Create Sub route
  selectedConsignmentsForSubRoute: [],
  openCreateSubRouteDialog: false,
  openDeleteSubRouteDialog: false,
  selectedRouteDeletion: {},
  // Sub Route Save
  selectedSubRoute: {},
  openSubRouteOrderActionPopUp: false,
};

export interface commonFetchInitialStateInterface {
  fetchStatus: any;
  error: any;
  data: any;
  loading: boolean;
}

export const commonFetchInitialState: commonFetchInitialStateInterface = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: {} as any,
  loading: false,
};

export interface AllRoutesSliceInitialState {
  loading: boolean;
  fetchStatus: any;
  error: any;
  plannedRoutes: any;
  unPlannedRoutes: any;
  removedRouteData: any;
  existingRoutesList: any;
  orderStatuses: SelectItemProps[];
}

export const SNACKBAR_MESSAGES = {
  ORDER_REMOVED_FROM_ROUTE: "Order successfully removed from route",
  ORDER_ADDED_TO_ROUTE: "Order successfully added to route",
  VIEW: "View",
  CONFIRM_CHANGES_TO_ROUTE: "The changes made here will affect existing routes.",
  CONFIRM_PUBLISH_ROUTE: "Are you sure you want to publish these routes?",
  DRIVER_NOTIFIED_MESSAGE: "being published. Once published, the drivers will be notified of the assigned routes.",
  ASSIGN_DRIVER_TO_PUBLISH: "To publish route, please provide Driver and Vehicle for all selected routes.",
  CONSIGNMENT_ID_REQUIRED: "Consignment ID is required",
  CONSIGNMENT_ID: "Consignment ID",
  NO_ORDERS_FOUND: "Consignment ID not found",
  SEARCH_CONSIGNMENTS: "Search Consignments",
  ERROR_WHILE_FETCHING_DRAFTED_ROUTE: "Error while fetching drafted route",
  CONFIRM_CHANGES_TO_NEW_ROUTE: "The changes made here will create a new route.",
  CREATE_ROUTE_SUCCESS_MSG: "has been created successfully",
  CONFIRM_CHANGES_TO_NEW_SUB_ROUTE: "The changes made here will create a new sub route.",
  NO_ORDERS_PRESENT_FOR_SUB_ROUTE: "Please provide orders for sub route.",
  DELETE_ROUTE_SUCCESS_MSG: "Sub Route has been deleted successfully",
  CONFIRM_DELETE_SUB_ROUTE: "The changes made here will delete the sub route.",
  CONFIRM_CHANGES_TO_SUB_ROUTE: "The changes made here will affect existing routes.",
  ASSIGN_PARENT_ROUTE_DRIVER_TO_PUBLISH: "To publish route, please assign all orders to sub-routes and provide Driver, Vehicle for all selected routes.",
  NO_ROUTES_TO_DISPLAY: "No Routes to display",
};

export interface SelectItemProps {
  name?: string;
  value: any;
}

export interface MAPOPTIONSTYLES_INTERFACE {
  elementType: string;
  featureType?: string;
  stylers: { visibility: string }[];
}
export const mapOptionStyles: MAPOPTIONSTYLES_INTERFACE[] = [
  { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  { featureType: "administrative.land_parcel", elementType: "labels", stylers: [{ visibility: "off" }] },
  { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
  { featureType: "road.local", elementType: "labels", stylers: [{ visibility: "off" }] },
];

export interface MAP_LOCATION_INTERFACE {
  lat: string | number;
  lng: string | number;
}

export interface MAP_MARKER_ICON_INTERFACE {
  url: string;
  fillColor?: string;
  fillOpacity?: number;
  strokeWeight?: number;
  strokeColor?: string;
  scale?: number;
  labelOrigin?: { x: number; y: number };
}
export interface MAP_MARKER_INTERFACE {
  name: string;
  routeId: string;
  deliveryArea: string;
  deliverySlot: string;
  consignmentCode: string;
  location: MAP_LOCATION_INTERFACE;
  icon: MAP_MARKER_ICON_INTERFACE;
  label: {
    text: string;
    fontSize: string;
    fontWeight: string;
    color: string;
    lineHeight: string;
  };
  parentRouteName?: string;
}

export interface CustomLocation {
  lat: number;
  lng: number;
}

export interface OrderInterface {
  orderCode: string | number;
  orderIndex?: string | number;
  consignmentCode: string | number;
  deliveryStatus: string | number;
  displayDeliveryStatus: string | number;
  isChecked: boolean;
  isOpened: boolean;
  mapCenter: MAP_LOCATION_INTERFACE;
  paymentMethod: string;
  paymentType: string;
  slotValue: string;
  totalCratesCount: string | number;
  address: {
    area: string;
    countryName: string;
    fullAddress: string;
    latitude: string;
    longitude: string;
    status: string;
    zoneNumber?: string;
  };
  openOrderActionPopUp?: boolean;
}

export interface UnplannedSlotWiseOrdersInterface {
  [timeSlot: string]: OrderInterface[];
}
export interface SlotWiseOrdersInterface {
  [timeSlot: string]: {
    isChecked: boolean;
    isOpened: boolean;
    orders: OrderInterface[];
  };
}

export interface UnplannedConsignmentsDataInterface {
  [key: string]: {
    color: string;
    isChecked: boolean;
    isOpened: boolean;
    mapCenter: MAP_LOCATION_INTERFACE;
    slotWiseOrders: UnplannedSlotWiseOrdersInterface;
    openOrderActionPopUp: boolean;
    orderCount: string | number;
    orderIndex: string | number;
  };
}

export interface AssignedVehicleInterface {
  active?: boolean;
  crateCapacity?: string | number;
  id?: string | number;
  name?: string | number;
  orderCapacity?: number;
  temperatureControlled?: boolean;
}

export interface RouteDataObjInterface {
  [key: string]: {
    isChecked: boolean;
    isOpened: boolean;
    ordersCount: number;
    routeType: string;
    slotWiseOrders: SlotWiseOrdersInterface;
  };
}

export interface SubRouteDataObjInterface {
  [key: string]: {
    isChecked: boolean;
    isOpened: boolean;
    ordersCount: number;
    routeType: string;
    slotWiseOrders: SlotWiseOrdersInterface;
  };
}
export interface PlannedConsignmentsDataInterface {
  [key: string]: {
    assignedVehicle: AssignedVehicleInterface;
    color: string;
    consignmentsIds: string[];
    deliveryArea: string[];
    deliveryProposition: string;
    deliverySlot: string[];
    displayRouteStatus: string;
    displayStatus: string;
    hubCode: string;
    hubName: string;
    id: string;
    isChecked: boolean;
    isOpened: boolean;
    mapCenter: MAP_LOCATION_INTERFACE;
    openOrderActionPopUp: boolean;
    plannedDate: string;
    routeId: string;
    routeDataObj: RouteDataObjInterface | any;
    sourceDetails: { latitude: string; longitude: string };
    status?: string;
    totalConsignment?: string | number;
    totalCrates?: string | number;
    type?: string;
  };
}

export const BUTTONS_TEXT = {
  CREATE_NEW_ROUTE: "Create Route",
  PLANNED: "PLANNED",
  UNPLANNED: "UNPLANNED",
  TOTAL_ROUTES: "Total Routes",
  TOTAL_AREAS: "Total Areas",
  TOTAL_ORDERS: "Total Orders",
  TOTAL_CRATES: "Total Crates",
};

export interface ROUTE_DETAILS_INTERFACE {
  key: string;
  value: keyof ALL_ROUTE_INITIAL_STATE_INTERFACE;
}

export const PLANNED_ROUTE_DETAILS: ROUTE_DETAILS_INTERFACE[] = [
  { key: BUTTONS_TEXT.TOTAL_ROUTES, value: "totalRecords" },
  { key: BUTTONS_TEXT.TOTAL_ORDERS, value: "totalOrders" },
  { key: BUTTONS_TEXT.TOTAL_CRATES, value: "totalCrates" },
];

export const UNPLANNED_ROUTE_DETAILS: ROUTE_DETAILS_INTERFACE[] = [
  { key: BUTTONS_TEXT.TOTAL_AREAS, value: "totalRecords" },
  { key: BUTTONS_TEXT.TOTAL_ORDERS, value: "totalOrders" },
  { key: BUTTONS_TEXT.TOTAL_CRATES, value: "totalCrates" },
];

export const MARKER_URL = {
  RED_DOT_MARKER: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
  GREEN_DOT_MARKER: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
};

export enum ROUTE_TYPE {
  NORMAL_ROUTE = "NORMAL_ROUTE",
  PARENT_ROUTE = "PARENT_ROUTE",
  SUB_ROUTE = "SUB_ROUTE",
  ROUTE_AREA = "ROUTE_AREA",
}

export const ALL_ROUTES_TEXT_CONTANT = {
  NO_PLANNED_ITEMS: "No Planned Items to display",
  NO_UNPLANNED_ITEMS: "No Unplanned Items to display",
  NO_ITEMS_TO_DISPLAY: "No Items to display",
  SUB_ROUTE_ENABLING: "Sub Route Enabling",
  ROUTE_DETAILS_POP_UP_TOOL_TIP: "View Route Details/Assign Driver",
  SUB_ROUTE_DETAILS_POP_UP_TOOL_TIP: "View Sub Route Details/Assign Driver",
  CREATE_SUB_ROUTE: "CREATE SUB ROUTE",
  SUB_ROUTE: "Sub Route",
  SUB_ROUTE_DETAILS: "Sub Route Details",
  PARENT_ROUTE: "Parent Route",
  PARENT_ROUTE_DETAILS: "Parent Route Details",
  ROUTE_DETAILS: "Route Details",
  DISABLE_SUB_ROUTE_SWITCH: "To turn off sub route switch, please delete all sub route(s) for the route.",
  PARENT_ROUTE_ID: "Parent Route ID",
  SUB_ROUTE_ID: "Sub Route ID",
  HUB: "Hub",
  DELIVERY_AREA: "Delivery Area",
  CONSIGNMENTS: "Consignments",
  CRATES: "Crates",
  DELIVERY_SLOT: "Delivery Slot",
  AREA_DATA: "AREA_DATA",
  ACTUAL_DISTANCE: "Actual Distance",
  DELIVERY_DATE: "Delivery Date",
};
