import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Grid, IconButton, InputAdornment, Step, StepLabel, Stepper, SvgIcon, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { AppState } from '../config/redux/reducers';
import { StepperTimelineConnector, useStyles } from './CarrefourDeliveryCreateRoute.styles';
import { ReactComponent as searchIcon } from './../assets/icons/search-icon-small.svg';
import { ReactComponent as refreshIcon } from './../assets/icons/refresh-icon.svg';
import { ReactComponent as consignmentsIcon } from './../assets/icons/consignments-icon.svg';
import { ReactComponent as activeTimelineDot } from './../assets/icons/active-timeline-dot.svg';
import { ReactComponent as mapViewIcon } from './../assets/icons/map-view-icon.svg';
import { ReactComponent as listViewIcon } from './../assets/icons/list-view-icon.svg';
import { ReactComponent as areaTimelineIcon } from './../assets/icons/timeline-area-icon.svg';
import { ReactComponent as infoWarningIcon } from './../assets/icons/info-warning-icon.svg';
import mapHubIcon from './../assets/icons/timeline-area-icon.svg';
import mapItemIcon from './../assets/icons/map-item-icon.svg';
//import { ReactComponent as cratesIcon } from './../assets/icons/crates-icon.svg';
import { ReactComponent as lockIcon } from './../assets/icons/lock-icon.svg';
import { ReactComponent as walletIcon } from './../assets/icons/wallet-icon.svg';
import { ReactComponent as mobileIcon } from './../assets/icons/mobile-icon.svg';
import { ReactComponent as locationIcon } from './../assets/icons/location-icon.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow-icon.svg';
import { ReactComponent as infoErrorIcon } from './../assets/icons/info-error-icon.svg';
import { ReactComponent as timeSlotIcon } from './../assets/icons/time-slot-icon.svg';
import { ReactComponent as userIcon } from './../assets/icons/user-icon.svg';
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import calendarIcon from './../assets/icons/calendar-icon.svg';
import { TabContext, TabPanel, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import AppConstants from '../constants';
import SelectBox, { SelectItemProps } from '../common/SelectBox';
import { calculateTransitTime, createDraftRoutePayload, createGetAllDropdownsPayload, createGetAllPlannedRoutesPayload, createRouteCreationPayload, createUpdateOrderStatusPayload, getAllAreasAndSlots, getConsignmentData, getDiscardRoutePayload, getTotalCrates, transformRouteConsignments, getAddressInfo, getRouteStatusField, getConsignmentStatusField, checkTerminalStatus } from '../mocks/carrefourHomeDelivery/response.transforms';
import { createDraftRoute, createAndEditRoute, fetchAllDrivers, fetchAllHelpers, fetchAllVehicles, fetchAllPlannedRoutes, updateConsignmentStatus, discardRouteChanges, resetPlanningError, updateAllConsignmentsState, loadAllSlots, clearCurrentSlot, fetchRouteConsignments, clearUpdatedRoute } from './redux/carrefourDeliverySlice';
import DateRangePickerComponent from '../common/DateRangeComponent';
import moment from 'moment';
import CustomSnackbar from '../common/CustomSnackbar';
import { userLogout } from '../Login/redux/loginSlice';
import { getMatchingValues, getSlotUIValue, getUniqueValues, highlightTextWithClass, isEqualArrays, convertTime, getDifferenceValues } from '../utils/helpers.utils';
import { Prompt, useHistory } from 'react-router';
import TextBox from '../common/TextBox';
import _ from 'lodash';
import CustomDialog from '../common/Dialog';
import MapContainer from 'common/GoogleMapContainer';
import CustomTooltip from 'common/Tooltip';
import { COLORS } from 'config/theme/baseTheme';
import CarrefourDeliveryOrderDetails from "./CarrefourDeliveryOrderDetails";

interface CreateRouteProps {
  location?: any;
  match?: any;
  editMode?: boolean;
  viewMode?: boolean;
}

const CreateRoute = (props: CreateRouteProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { editMode, viewMode, match, location } = props;

  const history = useHistory();
  const { loading, draftRoute, areawiseConsignments, slotwiseConsignments, allConsignments, routeConsignments, slotwisePlannedRoutes, driversList, helpersList, vehiclesList, vehiclesData, routeCreateSuccess, updatedRoute, routeDiscardSuccess, currentSlot, slotsList, consignmentStatusList, error, errorCode } = useSelector(
    (state: AppState) => state.carrefourDelivery
  );

  const { mapRouteDirections, countryCode, hubData } = useSelector(
    (state: AppState) => state.common
  );
  const { isLastMileViewer, isOnlyStdLastMileViewer } = useSelector(
    (state: AppState) => state.userLogin
  );
  const [tabValue, setTabValue] = useState<any>(location.state && location.state.tabValue ? location.state.tabValue : AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED);
  const shouldBlockNavigationRef = useRef<boolean>(false);
  const autoRefreshMapRef = useRef<boolean>(false);
  const hubNameRef = useRef<string>(location.state && location.state.hubName);
  const onLoadRef = useRef<boolean>(false);
  const filterChangeRef = useRef<boolean>(false);
  const [autoRefreshMap, setAutoRefreshMap] = useState(false);
  const [optimizeMapRoute, setOptimizeMapRoute] = useState(false);
  const [updateMap, setUpdateMap] = useState(false);
  const [editRoute, setEditRoute] = useState<any>(null);
  const [noRedirect, setNoRedirect] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [draggingItem, setDraggingItem] = useState(false);
  const [toggleAllDropdownExpanded, setToggleAllDropdownExpanded] = useState(false);
  const [mapMarkers, setMapMarkers] = useState<any>([]);
  const [consignmentsSequence, setConsignmentsSequence] = useState<any>({});
  const [lastSequenceNumber, setLastSequenceNumber] = useState(0);
  const [openDiscardConfirmDialog, setOpenDiscardConfirmDialog] = useState(false);
  const [openDateChangeConfirmDialog, setOpenDateChangeConfirmDialog] = useState(false);
  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false);
  const [openCapacityBreachConfirmDialog, setOpenCapacityBreachConfirmDialog] = useState(false);
  const [capacityBreachPopupMsg, setCapacityBreachPopupMsg] = useState('');
  const [openConsignmentDetailsPopup, setOpenConsignmentDetailsPopup] = useState(false);
  const [consignmentData, setConsignmentData] = useState({});
  const [consignmentExpanded, setConsignmentExpanded] = useState<string[]>([]);
  const [searchedConsignmentExpanded, setSearchedConsignmentExpanded] = useState<string[]>([]);
  const [consignmentSearchValue, setConsignmentSearchValue] = useState('');
  const [statusValue, setStatusValue] = useState<string[]>([]);
  const [slotValue, setSlotValue] = useState<string[]>([]);
  const [driverValue, setDriverValue] = useState(AppConstants.SELECT_NONE.value);
  const [helperValue, setHelperValue] = useState(AppConstants.SELECT_NONE.value);
  const [vehicleValue, setVehicleValue] = useState(AppConstants.SELECT_NONE.value);
  const [routeDriverValue, setRouteDriverValue] = useState(AppConstants.SELECT_NONE.value);
  const [routeHelperValue, setRouteHelperValue] = useState(AppConstants.SELECT_NONE.value);
  const [routeVehicleValue, setRouteVehicleValue] = useState(AppConstants.SELECT_NONE.value);
  const [isPlannedRoute, setIsPlannedRoute] = useState(false);
  const [selectableConsignments, setSelectableConsignments] = useState({ ...slotwiseConsignments });
  const [checkedConsignments, setCheckedConsignments] = useState({} as any);
  const [selectedRoute, setSelectedRoute] = useState({} as any);
  const [selectedConsignments, setSelectedConsignments] = useState({} as any);
  const [unplannedConsignmentsData, setUnplannedConsignmentsData] = useState({
    selectedConsignments: {},
    consignmentsSequence: {}
  } as any);
  const [plannedConsignmentsData, setPlannedConsignmentsData] = useState({
    selectedConsignments: {},
    consignmentsSequence: {}
  } as any);
  const [currentDate, setCurrentDate] = useState({
    startDate: moment(location.state && location.state.plannedDate ? location.state.plannedDate : new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
    endDate: moment(location.state && location.state.plannedDate ? location.state.plannedDate : new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT)
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const isEditIcon = (location.state && location.state.route && location.state.route.displayRouteStatus && location.state.route.displayRouteStatus !== AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.ROUTES_DATA.UNEDITABLE_CHIP);

  const confirmDialogPaperProps = {
    classes: {
      root: classes.confirmDialogPaperPropsRoot
    }
  };

  const confirmWithTitleDialogProps = {
    classes: {
      root: classes.confirmWithTitleDialogPaperPropsRoot
    }
  };

  const getAllPlannedRoutes = useCallback(
    (params: any) => {
      const payload = createGetAllPlannedRoutesPayload(params);
      dispatch(
        fetchAllPlannedRoutes({
          payload: payload
        })
      );
    },
    [dispatch],
  );

  const handleRefreshPlannedRoutes = useCallback(
    () => {
      getAllPlannedRoutes({
        date: currentDate,
        hubCode: location.state.hubCode,
      });
    },
    [currentDate, location.state.hubCode, getAllPlannedRoutes],
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      setTabValue(value);
      setAutoRefreshMap(true);
      if (value === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED) {
        setPlannedConsignmentsData({
          selectedConsignments: { ...selectedConsignments },
          consignmentsSequence: { ...consignmentsSequence }
        });
        setSelectedConsignments(unplannedConsignmentsData.selectedConsignments);
        setConsignmentsSequence(unplannedConsignmentsData.consignmentsSequence);
      } else {
        setUnplannedConsignmentsData({
          selectedConsignments: { ...selectedConsignments },
          consignmentsSequence: { ...consignmentsSequence }
        });
        setSelectedConsignments(plannedConsignmentsData.selectedConsignments);
        setConsignmentsSequence(plannedConsignmentsData.consignmentsSequence);
        handleRefreshPlannedRoutes();
      }
    },
    [consignmentsSequence, selectedConsignments, unplannedConsignmentsData, plannedConsignmentsData, handleRefreshPlannedRoutes],
  );

  const toggleListView = useCallback(
    (type: string) => {
      setIsListView(type === 'map' ? false : true);
      if (type === 'map') {
        setAutoRefreshMap(true);
      }
    },
    [],
  );

  const setExpandedConsignments = useCallback(
    (consignments: string[], isSearch?: boolean) => {
      const expandedConsignments = getUniqueValues(consignments);
      isSearch ? setSearchedConsignmentExpanded(expandedConsignments) : setConsignmentExpanded(expandedConsignments);
    },
    [],
  )

  const handleSearchedAccordionChange = useCallback(
    (searchValue: string) => {
      if (searchValue) {
        let expandedConsignments: string[] = [];
        let searchedConsignments = allConsignments.filter((item: any) => {
          const consignmentValue = highlightTextWithClass(searchValue, item.code);
          const address = getAddressInfo(item.data && item.data.deliveryInformation ? item.data.deliveryInformation.customerContactInfo.address : "");
          const addressValue = highlightTextWithClass(searchValue, address);
          const areaValue = highlightTextWithClass(searchValue, item.area);
          return consignmentValue.indexOf('highlightText') > -1 || addressValue.indexOf('highlightText') > -1 || areaValue.indexOf('highlightText') > -1;
        });
        if (searchedConsignments.length) {
          searchedConsignments.forEach((consignment: any) => {
            const slotIndex = expandedConsignments.indexOf(consignment.slot);
            const areaIndex = expandedConsignments.indexOf(consignment.area);
            if (slotIndex === -1) {
              expandedConsignments.push(`${consignment.area}-${consignment.slot}`);
            }
            if (areaIndex === -1) {
              expandedConsignments.push(consignment.area);
            }
          });
        } else {
          expandedConsignments = [];
        }
        setExpandedConsignments(expandedConsignments, true);
      }
    },
    [allConsignments, setExpandedConsignments],
  );

  const debounceConsignmentSearch = _.debounce((searchValue: string) => {
    handleSearchedAccordionChange(searchValue);
  }, 500);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const searchValue = event.target.value;
      setConsignmentSearchValue(searchValue);
      debounceConsignmentSearch(searchValue);
    },
    [debounceConsignmentSearch],
  );

  const handleConsignmentAccordionChange = useCallback(
    (itemObj: any, isSlot?: boolean) => (event: any, isExpanded: boolean) => {
      let expandedConsignments = consignmentSearchValue ? [...searchedConsignmentExpanded] : [...consignmentExpanded];
      const id = isSlot ? `${itemObj.area}-${itemObj.code}` : itemObj.code;
      const idIndex = expandedConsignments.indexOf(id);
      const allSlotItems = !isSlot ? Object.keys(slotwiseConsignments[itemObj.code]) : [];
      if (isExpanded) {
        expandedConsignments.push(id);
        if (allSlotItems.length) {
          expandedConsignments = [...expandedConsignments, ...allSlotItems.map(item => `${itemObj.code}-${item}`)];
        }
      } else {
        if (idIndex > -1) {
          expandedConsignments.splice(idIndex, 1);
        }
      }

      consignmentSearchValue ? setExpandedConsignments(expandedConsignments, true) : setExpandedConsignments(expandedConsignments);
    },
    [consignmentExpanded, consignmentSearchValue, searchedConsignmentExpanded, slotwiseConsignments, setExpandedConsignments],
  );

  const checkExpanded = useCallback(
    (item: any, isSlot?: boolean) => {
      const idsToCheck = consignmentSearchValue ? [...searchedConsignmentExpanded] : [...consignmentExpanded];
      let expanded = false;
      const itemId = isSlot ? `${item.area}-${item.code}` : item.code;
      if (item) {
        if (idsToCheck.length) {
          expanded = idsToCheck.indexOf(itemId) > -1;
        } else {
          expanded = false;
        }
      }
      return expanded;
    },
    [consignmentExpanded, consignmentSearchValue, searchedConsignmentExpanded],
  )

  const getAllSelectedConsignments = useCallback(
    () => {
      let consignments: any[] = [];
      if (selectedConsignments) {
        Object.keys(selectedConsignments).forEach((areaKey: string) => {
          const slots = selectedConsignments[areaKey];
          Object.keys(slots).forEach((slotKey: string) => {
            consignments = [...consignments, ...slots[slotKey].filter((item: any) => item.value !== AppConstants.SELECT_ALL.value)];
          });
        });
      }
      return consignments;
    },
    [selectedConsignments],
  );

  const calculateCapacityBreach = useCallback(
    (type: string) => {
      let capacityBreach = false;
      if (vehiclesData && vehicleValue !== AppConstants.SELECT_NONE.value) {
        let selectedVehicle = vehiclesData.filter((vehicle: any) => vehicle.id === vehicleValue);
        if (selectedVehicle.length) {
          selectedVehicle = selectedVehicle[0];
          const allSelectedConsignments = getAllSelectedConsignments();
          switch (type) {
            case 'consignments':
              capacityBreach = allSelectedConsignments.length > selectedVehicle.orderCapacity;
              break;
            case 'crates':
              capacityBreach = getTotalCrates(allSelectedConsignments) > selectedVehicle.crateCapacity;
              break;
            case 'transitTime':
              capacityBreach = false;
              break
            default:
              break;
          }
        }
      }
      return capacityBreach;
    },
    [getAllSelectedConsignments, vehicleValue, vehiclesData],
  )

  const updateHighlightSearch = useCallback(
    (item: any) => {
      let returnValue = item.code;
      if (consignmentSearchValue) {
        returnValue = highlightTextWithClass(consignmentSearchValue, item.code);
      }
      return returnValue;
    },
    [consignmentSearchValue],
  );

  const handleConSelectChange = useCallback(
    (newValues: any, slot: string, area: string) => {
      let selected: any[] = [];
      const selectedValues = newValues[area][slot];
      const previous = selectedConsignments[area][slot].map((item: any) => item.value);
      const selectable = selectableConsignments[area][slot].filter((item: any) => item.status !== 'DRAFTED');
      if (selectedValues.length) {
        selectable.forEach((selItem: any) => {
          selectedValues.forEach((item: any) => {
            if (selItem.value === item) {
              selected.push(selItem);
            }
          })
        })
      }
      const selectAllIdx = selectedValues.indexOf(AppConstants.SELECT_ALL.value);
      const selectAllPreviousIdx = previous.indexOf(AppConstants.SELECT_ALL.value);
      if (selectAllIdx > -1) {
        if (selected.length < selectable.length) {
          if (selectAllPreviousIdx > -1) {
            selected = selected.filter((item: any) => (item.value !== AppConstants.SELECT_ALL.value && item.status !== 'DRAFTED'));
          } else {
            selected = selectable.slice().filter((item: any) => item.status !== 'DRAFTED');
          }
        }
      } else {
        if (selected.length < selectable.length) {
          if (selectAllPreviousIdx > -1) {
            selected = [];
          } else if (selected.length === selectable.length - 1) {
            selected = selectable.slice().filter((item: any) => item.status !== 'DRAFTED');
          }
        }
      }
      if (!selected.length) {
        selected = [];
      }
      setSelectedConsignments({ ...selectedConsignments, [area]: { ...selectedConsignments[area], [slot]: selected } });
      setCheckedConsignments({ ...checkedConsignments, [area]: { ...checkedConsignments[area], [slot]: selected.map((item: any) => item.value) } })
      let defaultConsignments = [AppConstants.SELECT_ALL];
      if (editMode) {
        defaultConsignments = draftRoute.route && draftRoute.route.consignmentsIds ? [AppConstants.SELECT_ALL, ...draftRoute.route.consignmentsIds.map((item: string) => {
          return {
            value: item
          }
        })] : [AppConstants.SELECT_ALL]
      }
      let allPreviousSelectedConsignments = getAllSelectedConsignments();
      const currentSlotPreviousConsignments = allPreviousSelectedConsignments.filter((con: any) => con.area === area && con.slot === slot);
      allPreviousSelectedConsignments = _.difference(allPreviousSelectedConsignments, currentSlotPreviousConsignments);
      const allSelectedConsignments = [...allPreviousSelectedConsignments, ...selected];
      sessionStorage.setItem('extraConsignments', JSON.stringify(getDifferenceValues(allSelectedConsignments, defaultConsignments).map((item: any) => item.value)));
    },
    [selectableConsignments, selectedConsignments, checkedConsignments, draftRoute, editMode, getAllSelectedConsignments],
  );

  const updateOrderStatus = useCallback(
    (params: any) => {
      if (params.consignments.length) {
        const payload = createUpdateOrderStatusPayload(params);
        dispatch(
          updateConsignmentStatus({
            payload: payload
          })
        );
      }
    },
    [dispatch],
  );

  const handleConsignmentSequense = useCallback(
    (consignment: any, isNew: boolean, seqParams: any) => {
      if (consignment) {
        let lastSeqNo: number = seqParams.lastSeqNo;
        let newSequencedCons = { ...seqParams.allSequencedCons };
        if (isNew) {
          newSequencedCons[consignment.id] = {
            id: consignment.id,
            seq: ++lastSeqNo
          }
        } else {
          delete newSequencedCons[consignment.id];
          --lastSeqNo;
          Object.keys(newSequencedCons).forEach((id: string) => {
            const cons = newSequencedCons[id];
            if (id !== consignment.id && cons.seq >= consignment.seq) {
              newSequencedCons[id].seq -= 1;
            }
          });
        }
        seqParams.lastSeqNo = lastSeqNo;
        seqParams.allSequencedCons = newSequencedCons;
      }
      return seqParams;
    },
    [],
  );

  const addSequence = useCallback(
    (consignments: any[], status: string, params: any) => {
      let seqParams = _.cloneDeep(params);
      if (consignments.length) {
        let sequencedCons = seqParams.allSequencedCons;
        consignments.forEach((id: any) => {
          let isNew = status === 'PLANNED';
          let consignment = isNew ? {
            id: id
          } : sequencedCons[id];
          seqParams = handleConsignmentSequense(consignment, isNew, seqParams);
        });
      }
      return seqParams;
    },
    [handleConsignmentSequense],
  )

  const handleCheckboxToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | MouseEvent>, value: any, isSlot: boolean) => {
      event.stopPropagation();
      const currentSlot = isSlot ? value.code : value.slot;
      const currentArea = value.area;
      const currentValue = isSlot ? AppConstants.SELECT_ALL.value : value.code;
      const currentIndex = checkedConsignments[currentArea][currentSlot].indexOf(currentValue);
      const newCheckedConsignments = [...checkedConsignments[currentArea][currentSlot]];
      let consignments = [];
      let status = '';
      if (currentIndex === -1) {
        status = 'PLANNED';
        if (isSlot) {
          consignments = slotwiseConsignments[currentArea][currentSlot].slice().filter((item: any) => item.status !== 'DRAFTED').map((item: any) => item.code);
        } else {
          consignments = [currentValue];
        }
        newCheckedConsignments.push(currentValue);
      } else {
        status = 'UNPLANNED';
        if (isSlot) {
          consignments = slotwiseConsignments[currentArea][currentSlot].slice().filter((item: any) => item.status !== 'DRAFTED').map((item: any) => item.code);
        } else {
          consignments = [currentValue];
        }
        newCheckedConsignments.splice(currentIndex, 1);
      }
      updateOrderStatus({
        consignments: consignments,
        status: status,
        routeId: draftRoute.route.id
      });
      let seqParams: any = {
        allSequencedCons: _.cloneDeep(consignmentsSequence),
        lastSeqNo: lastSequenceNumber
      };
      if (isSlot && status === 'PLANNED') {
        const alreadySelectedCons = getMatchingValues(checkedConsignments[currentArea][currentSlot], consignments);
        seqParams = addSequence(alreadySelectedCons, 'UNPLANNED', seqParams);
      }
      seqParams = addSequence(consignments, status, seqParams);
      autoRefreshMapRef.current = false;
      setOptimizeMapRoute(false);
      setAutoRefreshMap(false);
      setUpdateMap(false);
      setConsignmentsSequence(seqParams.allSequencedCons);
      setLastSequenceNumber(seqParams.lastSeqNo);
      setCheckedConsignments({ ...checkedConsignments, [currentArea]: { ...checkedConsignments[currentArea], [currentSlot]: newCheckedConsignments } });
      handleConSelectChange({ ...checkedConsignments, [currentArea]: { ...checkedConsignments[currentArea], [currentSlot]: newCheckedConsignments } }, currentSlot, currentArea);
    },
    [checkedConsignments, draftRoute, slotwiseConsignments, consignmentsSequence, lastSequenceNumber, addSequence, handleConSelectChange, updateOrderStatus],
  );

  const getRouteConsignments = useCallback(
    (id: string) => {
      dispatch(
        fetchRouteConsignments({
          id: id
        })
      );
    },
    [dispatch],
  );

  const handleRouteSelection = useCallback(
    (route: any) => {
      setSelectedRoute(route);
      if (route.data.assignee) {
        setRouteDriverValue(route.data.assignee.id);
      } else {
        setRouteDriverValue(AppConstants.SELECT_NONE.value);
      }
      if (route.data.helper) {
        setRouteHelperValue(route.data.helper.id);
      } else {
        setRouteHelperValue(AppConstants.SELECT_NONE.value);
      }
      if (route.data.assignedVehicle) {
        setRouteVehicleValue(route.data.assignedVehicle.id);
      } else {
        setRouteVehicleValue(AppConstants.SELECT_NONE.value);
      }
      getRouteConsignments(route.code);
    },
    [getRouteConsignments],
  )

  const handleDriverChange = useCallback(
    (value: any) => {
      setDriverValue(value);
    },
    [],
  );

  const handleRouteDriverChange = useCallback(
    (value: any) => {
      setRouteDriverValue(value);
    },
    [],
  );

  const handleHelperChange = useCallback(
    (value: any) => {
      setHelperValue(value);
    },
    [],
  );

  const handleRouteHelperChange = useCallback(
    (value: any) => {
      setRouteHelperValue(value);
    },
    [],
  );

  const handleVehicleChange = useCallback(
    (value: any) => {
      setVehicleValue(value);
    },
    [],
  );

  const handleRouteVehicleChange = useCallback(
    (value: any) => {
      setRouteVehicleValue(value);
    },
    [],
  );

  const getAllSlots = useCallback(
    (params: any) => {
      const payload = createDraftRoutePayload(params);
      dispatch(
        loadAllSlots({
          params: payload
        })
      );
    },
    [dispatch],
  );

  const handleCreateDraftRoute = useCallback(
    (params: any) => {
      const payload = createDraftRoutePayload({
        date: currentDate,
        slotValue: params.slots,
        editMode: editMode,
        hubCode: location.state.hubCode,
        hubName: hubNameRef.current,
        statusValue: params.statusValue
      });
      dispatch(
        createDraftRoute({
          params: payload
        })
      );
    },
    [editMode, currentDate, location.state.hubCode, dispatch],
  );

  const initializeRoute = useCallback(
    (params: any) => {
      setConsignmentsSequence({});
      setLastSequenceNumber(0);
      if (!viewMode) {
        if (filterChangeRef.current) {
          handleCreateDraftRoute({
            slots: params.slotValue,
            statusValue: params.statusValue
          });
        } else {
          getAllSlots({
            date: params.date,
            slotValue: params.slotValue,
            editMode: editMode,
            hubCode: location.state.hubCode,
            hubName: hubNameRef.current,
            statusValue: params.statusValue,
          });
        }
        if (!editMode) {
          setDriverValue(AppConstants.SELECT_NONE.value);
          setHelperValue(AppConstants.SELECT_NONE.value);
          setVehicleValue(AppConstants.SELECT_NONE.value);
        }
      } else {
        //call route api and set selected cons
        getRouteConsignments(match.params.id);
      }
    },
    [editMode, viewMode, match, location.state.hubCode, getRouteConsignments, handleCreateDraftRoute, getAllSlots],
  );

  const handleChangeRoute = useCallback(
    (params: any) => {
      if (!filterChangeRef.current) {
        onLoadRef.current = true;
        setSlotValue([AppConstants.SELECT_NONE.value]);
      }
      initializeRoute({
        date: params.date,
        slotValue: params.slotValue,
        statusValue: params.statusValue
      });
      setSelectedConsignments({});
      setSelectedRoute({});
      setUnplannedConsignmentsData({
        selectedConsignments: {},
        consignmentsSequence: {}
      });
      setPlannedConsignmentsData({
        selectedConsignments: {},
        consignmentsSequence: {}
      });
      setConsignmentSearchValue('');
      setExpandedConsignments([], true);
      setExpandedConsignments([]);
      setToggleAllDropdownExpanded(false);
      autoRefreshMapRef.current = true;
      setOptimizeMapRoute(false);
      history.replace({ ...location, state: { ...location.state, plannedDate: moment(params.date.startDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_BACKEND) } })
    },
    [history, location, initializeRoute, setExpandedConsignments],
  );

  const handleStatusChange = useCallback(
    (value: any) => {
      filterChangeRef.current = true;
      setStatusValue(value);
      if (shouldBlockNavigation) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setOpenDateChangeConfirmDialog(true);
      } else {
        handleChangeRoute({
          date: currentDate,
          slotValue: slotValue,
          statusValue: value
        });
      }
    },
    [currentDate, slotValue, shouldBlockNavigation, handleChangeRoute],
  );

  const handleSlotChange = useCallback(
    (value: any) => {
      onLoadRef.current = false;
      filterChangeRef.current = true;
      setSlotValue(value);
      if (shouldBlockNavigation) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setOpenDateChangeConfirmDialog(true);
      } else {
        handleChangeRoute({
          date: currentDate,
          statusValue: statusValue,
          slotValue: value
        });
      }
    },
    [currentDate, statusValue, shouldBlockNavigation, handleChangeRoute],
  )

  const getSelectedValue = useCallback(
    (item: any, isSlot: boolean) => {
      const slot = isSlot ? item.code : item.slot;
      const area = item.area;
      let selectedValues = [];
      if (selectedConsignments[area] && selectedConsignments[area][slot] && selectedConsignments[area][slot].length) {
        selectedValues = selectedConsignments[area][slot].map((con: any) => con.value);
      }
      return selectedValues.indexOf(isSlot ? AppConstants.SELECT_ALL.value : item.value) > -1;
    },
    [selectedConsignments],
  );

  const getChangeDialogTitleContent = () => {
    return (
      <></>
    )
  };

  const getDiscardDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>All Changes will be lost. Are you sure you want to discard changes?</Typography>
        </Grid>
      </Grid>
    )
  };

  const getChangeDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>All Changes will be lost. Are you sure you want to continue?</Typography>
        </Grid>
      </Grid>
    )
  };

  const handleDiscardDialogClose = useCallback(
    () => {
      setOpenDiscardConfirmDialog(false);
    },
    [],
  );

  const handleChangeDialogClose = useCallback(
    () => {
      setOpenDateChangeConfirmDialog(false);
    },
    [],
  );

  const handleDiscardRoute = useCallback(
    (redirect?: boolean) => {
      const payload = getDiscardRoutePayload({
        previousCons: draftRoute.route ? draftRoute.route.consignmentsIds : []
      });
      dispatch(
        discardRouteChanges({
          params: payload,
          redirect: redirect
        })
      )
    },
    [dispatch, draftRoute],
  )

  const discardDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'discard') {
        setNoRedirect(false);
        handleDiscardRoute();
      }
      handleDiscardDialogClose();
    },
    [handleDiscardDialogClose, handleDiscardRoute],
  )

  const getDiscardDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { discardDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { discardDialogActionHandler('discard') }}>{AppConstants.BUTTONS.PROCEED}</Button>
      </Grid>
    )
  }

  const getChangeDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { changeDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { changeDialogActionHandler('proceed') }}>{AppConstants.BUTTONS.PROCEED}</Button>
      </Grid>
    )
  }

  const getUpdateDialogTitleContent = () => {
    return (
      <></>
    )
  };

  const getCapacityBreachDialogTitleContent = () => {
    return (
      <Typography className={classes.capacityBreachDialogTitle}>Capacity Breach</Typography>
    )
  };

  const getCapacityBreachDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem desc" item>
          <Typography className="alignLeft">{capacityBreachPopupMsg}</Typography>
        </Grid>
      </Grid>
    )
  };

  const handleCapacityBreachDialogClose = useCallback(
    () => {
      setOpenCapacityBreachConfirmDialog(false);
    },
    [],
  )

  const handleConsignmentDetailsClick = useCallback(
    (data: any) => {
      setConsignmentData(data);
      setOpenConsignmentDetailsPopup(true);
    },
    [],
  )

  const closeConsignmentDetailsPopup = useCallback(
    () => {
      setOpenConsignmentDetailsPopup(false);
    },
    [],
  );

  const getSeqSortedSelectedConsignments = useCallback(
    () => {
      let seqSelectedConsignments = getAllSelectedConsignments().map((consignment: any) => {
        return { ...consignment, seq: consignmentsSequence[consignment.code] ? consignmentsSequence[consignment.code].seq : lastSequenceNumber };
      });
      return _.sortBy(seqSelectedConsignments, 'seq');
    },
    [consignmentsSequence, lastSequenceNumber, getAllSelectedConsignments],
  );

  const createRoute = useCallback(
    () => {
      const payload = createRouteCreationPayload({
        consignments: getSeqSortedSelectedConsignments(),
        route: isPlannedRoute ? selectedRoute.data : draftRoute.route,
        selectedDriver: isPlannedRoute ? routeDriverValue : driverValue,
        selectedHelper: isPlannedRoute ? routeHelperValue : helperValue,
        selectedVehicle: isPlannedRoute ? routeVehicleValue : vehicleValue,
        date: currentDate.startDate,
        transitTime: calculateTransitTime(mapRouteDirections),
        mapRouteDirections: mapRouteDirections,
      });
      dispatch(
        createAndEditRoute({
          payload: payload,
          editMode: isPlannedRoute || editMode || false
        })
      );
    },
    [isPlannedRoute, routeDriverValue, routeHelperValue, routeVehicleValue, selectedRoute, currentDate, draftRoute, driverValue, editMode, helperValue, vehicleValue, mapRouteDirections, dispatch, getSeqSortedSelectedConsignments],
  );

  const capacityBreachDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'continue') {
        createRoute();
      }
      handleCapacityBreachDialogClose();
    },
    [createRoute, handleCapacityBreachDialogClose],
  )

  const getCapacityBreachDialogActions = () => {
    return (
      <Grid className={classes.capacityBreachDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { capacityBreachDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { capacityBreachDialogActionHandler('continue') }}>{AppConstants.BUTTONS.CONTINUE}</Button>
      </Grid>
    )
  }

  const getUpdateDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>{`Are you sure you want to update ${draftRoute.route && draftRoute.route.routeId}?`}</Typography>
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography>The changes made here will affect existing routes and cannot be undone.</Typography>
        </Grid>
      </Grid>
    )
  };

  const handleUpdateDialogClose = useCallback(
    () => {
      setOpenUpdateConfirmDialog(false);
    },
    [],
  );

  const getCapacityBreachPopupMsg = useCallback(
    (capacityBreach: any) => {
      let msg = ''
      if (capacityBreach.consignments) {
        msg = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS;
        if (capacityBreach.crates) {
          msg = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS_AND_CRATES;
          if (capacityBreach.transitTime) {
            msg = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.ALL_VALUES;
          }
        } else {
          if (capacityBreach.transitTime) {
            msg = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS_AND_TRANSIT_TIME;
          }
        }
        /* } else if (capacityBreach.crates) {
          msg = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CRATES;
          if (capacityBreach.transitTime) {
            msg = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CRATES_AND_TRANSIT_TIME;
          } */
      } else {
        if (capacityBreach.transitTime) {
          msg = AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.TRANSIT_TIME;
        }
      }
      return msg;
    },
    [],
  )

  const handleCreateRoute = useCallback(
    (isCreateClicked?: boolean) => {
      if (isCreateClicked) {
        setIsPlannedRoute(false);
      }
      const capacityBreach = {
        consignments: calculateCapacityBreach('consignments'),
        crates: calculateCapacityBreach('crates'),
        transitTime: calculateCapacityBreach('transitTime')
      }
      if (capacityBreach.consignments || capacityBreach.crates || capacityBreach.transitTime) {
        setCapacityBreachPopupMsg(getCapacityBreachPopupMsg(capacityBreach));
        setOpenCapacityBreachConfirmDialog(true);
      } else {
        createRoute();
      }
    },
    [calculateCapacityBreach, createRoute, getCapacityBreachPopupMsg],
  )

  const updateDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'update') {
        handleCreateRoute();
      }
      handleUpdateDialogClose();
    },
    [handleUpdateDialogClose, handleCreateRoute],
  )

  const getUpdateDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { updateDialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { updateDialogActionHandler('update') }}>{AppConstants.BUTTONS.PROCEED}</Button>
      </Grid>
    )
  }

  const handleDiscardChanges = useCallback(
    () => {
      setOpenDiscardConfirmDialog(true);
    },
    [],
  )

  const handleUpdateRoute = useCallback(
    () => {
      setIsPlannedRoute(false);
      setOpenUpdateConfirmDialog(true);
    },
    [],
  );

  const handleUpdatePlannedRoute = useCallback(
    () => {
      setIsPlannedRoute(true);
      setOpenUpdateConfirmDialog(true);
    },
    [],
  )

  const getAllDrivers = useCallback(
    (params: any) => {
      const payload = createGetAllDropdownsPayload(params);
      dispatch(
        fetchAllDrivers({
          payload: payload
        })
      );
    },
    [dispatch],
  );

  const getAllHelpers = useCallback(
    (params: any) => {
      const payload = createGetAllDropdownsPayload(params);
      dispatch(
        fetchAllHelpers({
          payload: payload
        })
      );
    },
    [dispatch],
  );

  const getAllVehicles = useCallback(
    (params: any) => {
      const payload = createGetAllDropdownsPayload(params);
      dispatch(
        fetchAllVehicles({
          payload: payload
        })
      );
    },
    [dispatch],
  );

  const getAllSelectedConsignmentsMarkers = useCallback(
    () => {
      const selectedSeqConsignments = getSeqSortedSelectedConsignments();
      let selectedHub: any = hubData[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] ? hubData[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode].filter((hub: any) => hub.code === location.state.hubCode) : [];
      selectedHub = selectedHub.length ? selectedHub[0] : {
        code: location.state.hubCode,
        latitude: 0,
        longitude: 0
      };
      const hubDetails = {
        name: selectedHub.code,
        icon: mapHubIcon,
        location: {
          lat: parseFloat(selectedHub.latitude || 0),
          lng: parseFloat(selectedHub.longitude || 0)
        }
      };
      const markers = [hubDetails];
      const wayPoints = selectedSeqConsignments.map((consignmentItem: any) => {
        const location = consignmentItem.data && consignmentItem.data.deliveryInformation ? {
          lat: parseFloat(consignmentItem.data.deliveryInformation.customerContactInfo.address.latitude) || 0,
          lng: parseFloat(consignmentItem.data.deliveryInformation.customerContactInfo.address.longitude) || 0
        } : {
            lat: 0,
            lng: 0
          };
        const label = consignmentsSequence[consignmentItem.code] ? `${consignmentsSequence[consignmentItem.code].seq}`.padStart(2, '0') : "";
        return {
          name: consignmentItem.code,
          location: location,
          icon: mapItemIcon,
          label: {
            text: label,
            fontSize: "10px",
            fontWeight: "600",
            color: COLORS.WHITE
          }
        };
      });
      return [...markers, ...wayPoints];
    },
    [getSeqSortedSelectedConsignments, consignmentsSequence, countryCode, hubData, location.state.hubCode],
  );

  const handleRefreshMap = useCallback(
    () => {
      const markers: any[] = getAllSelectedConsignmentsMarkers();
      setMapMarkers(markers);
    },
    [getAllSelectedConsignmentsMarkers],
  );

  const handleOptimizeMapRoute = useCallback(
    () => {
      setOptimizeMapRoute(true);
      handleRefreshMap();
    },
    [handleRefreshMap],
  );

  const disableOptimizeMapRoute = useCallback(
    () => {
      return getAllSelectedConsignmentsMarkers().length <= 1;
    },
    [getAllSelectedConsignmentsMarkers],
  );

  const changeDialogActionHandler = useCallback(
    (type: string) => {
      if (type === 'proceed') {
        handleChangeRoute({
          date: currentDate,
          statusValue: statusValue,
          slotValue: slotValue
        });
      } else {
        shouldBlockNavigationRef.current = true;
        setShouldBlockNavigation(true);
      }
      handleChangeDialogClose();
    },
    [handleChangeDialogClose, handleChangeRoute, currentDate, statusValue, slotValue],
  );

  const handleDateChange = useCallback(
    (dateRange: any) => {
      filterChangeRef.current = false;
      setCurrentDate(dateRange);
      setSlotValue([AppConstants.SELECT_NONE.value]);
      setStatusValue([AppConstants.SELECT_NONE.value]);
      if (shouldBlockNavigation) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setOpenDateChangeConfirmDialog(true);
      } else {
        handleChangeRoute({
          date: dateRange,
          statusValue: [],
          slotValue: []
        });
      }
    },
    [shouldBlockNavigation, handleChangeRoute],
  );

  const handleSnackbarExited = useCallback(
    () => {
      if (errorCode === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
      if (error) {
        dispatch(
          resetPlanningError()
        )
      }
      if (updatedRoute && updatedRoute.route) {
        dispatch(
          clearUpdatedRoute()
        )
      }
    },
    [dispatch, error, errorCode, updatedRoute]
  );

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
    },
    [],
  );

  const getSelectedAreas = useCallback(
    (forTooltip?: boolean) => {
      const selectedAreas: string[] = getUniqueValues(getAllSelectedConsignments(), 'area');
      let returnValue: any = selectedAreas.slice();
      if (!forTooltip) {
        returnValue = selectedAreas.length ? selectedAreas.length > 1 ? `Multiple(${selectedAreas.length})` : selectedAreas[0] : "";
      }
      return returnValue;
    },
    [getAllSelectedConsignments],
  )

  const getSelectedAreasTooltip = useCallback(
    () => {
      const selectedAreas: any = getSelectedAreas(true);
      return selectedAreas.length && selectedAreas.map((area: string, index: number) => (
        <Typography key={`tooltipArea-${area}-${index}`} className="tooltipItem">{area}</Typography>
      ));
    },
    [getSelectedAreas],
  );

  const getSelectedDeliverySlots = useCallback(
    (forTooltip?: boolean) => {
      const selectedSlots: string[] = getUniqueValues(getAllSelectedConsignments(), 'slot');
      let returnValue: any = selectedSlots.slice();
      if (!forTooltip) {
        if (selectedSlots.length) {
          const firstDelSlot: any = selectedSlots.length && selectedSlots[0].split('-');
          const slotValue = getSlotUIValue(firstDelSlot);
          returnValue = selectedSlots.length > 1 ? `Multiple(${selectedSlots.length})` : `${slotValue.startTime} - ${slotValue.endTime}`;
        } else {
          returnValue = "";
        }
      }
      return returnValue;
    },
    [getAllSelectedConsignments],
  );

  const getSelectedSlotsTooltip = useCallback(
    () => {
      const selectedSlots: any = getSelectedDeliverySlots(true);
      return selectedSlots.length && selectedSlots.map((slot: string, index: number) => {
        const delSlot = slot.split('-');
        const slotValue = getSlotUIValue(delSlot);
        return (
          <Typography key={`tooltipArea-${slot}-${index}`} className="tooltipItem">{`${slotValue.startTime} - ${slotValue.endTime}`}</Typography>
        )
      });
    },
    [getSelectedDeliverySlots],
  );

  const getCurrentSelectedDate = () => {
    return moment(currentDate.startDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT);
  };

  const checkEditRouteExtraConsignments = () => {
    let isEqual = false;
    if (draftRoute.route) {
      isEqual = isEqualArrays(draftRoute.route.consignmentsIds, getAllSelectedConsignments().map((item: any) => item.code));
    }
    return isEqual;
  }

  const disableCreateRt = () => {
    return !getAllSelectedConsignments().length;
  }

  const disableEditRt = () => {
    let disable = true;
    if (draftRoute.route) {
      const currentHelper = draftRoute.route.helper ? draftRoute.route.helper.id : AppConstants.SELECT_NONE.value;
      const currentDriver = draftRoute.route.assignee ? draftRoute.route.assignee.id : AppConstants.SELECT_NONE.value;
      const currentVehicle = draftRoute.route.assignedVehicle ? draftRoute.route.assignedVehicle.id : AppConstants.SELECT_NONE.value;
      if (!checkEditRouteExtraConsignments() || helperValue !== currentHelper || driverValue !== currentDriver || vehicleValue !== currentVehicle) {
        disable = false;
      }
      return disable;
    }

    return true;
  }

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    (e || window.event).returnValue = '';
    if (editMode) {
      handleDiscardRoute(true);
    }
    return '';
  }

  const handleOrderStatusOnUnload = (status: string, reset?: boolean) => {
    let consignments: any = sessionStorage.getItem('extraConsignments');
    consignments = consignments ? JSON.parse(consignments) : [];
    if (consignments.length) {
      updateOrderStatus({
        consignments: consignments,
        status: status,
        routeId: sessionStorage.getItem('routeId')
      });
    }
    if (reset) {
      sessionStorage.setItem('routeId', '');
      sessionStorage.setItem('extraConsignments', '[]');
    }
  };

  const onConsignmentsDragStart = useCallback(
    () => {
      setDraggingItem(true);
    },
    [],
  )

  const onConsignmentsDragEnd = useCallback(
    (result: any) => {
      setDraggingItem(false);
      const { destination, source } = result;
      if (!destination) {
        return;
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }
      let updatedConsignments = getSeqSortedSelectedConsignments();
      const [deletedItem] = updatedConsignments.splice(source.index, 1);
      updatedConsignments.splice(destination.index, 0, deletedItem);
      updatedConsignments = updatedConsignments.map((item: any) => item.code);
      const seqParams = addSequence(updatedConsignments, 'PLANNED', {
        allSequencedCons: {},
        lastSeqNo: 0
      });
      setConsignmentsSequence(seqParams.allSequencedCons);
      setLastSequenceNumber(seqParams.lastSeqNo);
      setUpdateMap(false);
      setOptimizeMapRoute(false);
    },
    [addSequence, getSeqSortedSelectedConsignments],
  );

  const getMapCenter = useCallback(
    () => {
      return getAllSelectedConsignmentsMarkers().length ? getAllSelectedConsignmentsMarkers()[0].location : {
        lat: 0,
        lng: 0
      };
    },
    [getAllSelectedConsignmentsMarkers],
  );

  const toggleAllDropdowns = useCallback(
    () => {
      const isExpanded = !toggleAllDropdownExpanded;
      const allAreasAndSlots: string[] = getAllAreasAndSlots(areawiseConsignments);
      if (isExpanded) {
        setExpandedConsignments(allAreasAndSlots, consignmentSearchValue ? true : false);
      } else {
        setExpandedConsignments([], consignmentSearchValue ? true : false);
      }
      setToggleAllDropdownExpanded(isExpanded);
    },
    [toggleAllDropdownExpanded, areawiseConsignments, consignmentSearchValue, setExpandedConsignments],
  );

  const updateAllConsignments = useCallback(
    (consignments: any) => {
      dispatch(
        updateAllConsignmentsState({
          consignments: consignments
        })
      );
    },
    [dispatch],
  )

  const updateConsignments = useCallback(
    async () => {
      const consignments = await getConsignmentData(draftRoute.statusOrders);
      updateAllConsignments({ ...consignments, loading: false });
      getAllDrivers({
        date: currentDate,
        hubCode: draftRoute.route.hubCode
      });
      getAllHelpers({
        date: currentDate,
        hubCode: draftRoute.route.hubCode
      });
      getAllVehicles({
        date: currentDate,
        hubCode: draftRoute.route.hubCode
      });
    },
    [getAllDrivers, getAllHelpers, getAllVehicles, updateAllConsignments, draftRoute, currentDate],
  )

  const getCurrentAreaConsignments = useCallback(
    (areaCode: string) => {
      return allConsignments.filter((item: any) => item.area === areaCode);
    },
    [allConsignments],
  );

  /* const getAreaTotalCrates = useCallback(
    (area: any): string => {
      const allAreaConsignments = getCurrentAreaConsignments(area.code);
      return `${getTotalCrates(allAreaConsignments)}`;
    },
    [getCurrentAreaConsignments],
  ); */

  const getAreaTotalConsignments = useCallback(
    (area: any): string => {
      const allAreaConsignments = getCurrentAreaConsignments(area.code);
      return `${allAreaConsignments.length}`;
    },
    [getCurrentAreaConsignments],
  );

  const getTransitTime = useCallback(
    () => {
      let transitTimeSecs = calculateTransitTime(mapRouteDirections);
      return convertTime(transitTimeSecs);
    },
    [mapRouteDirections],
  )

  const getUpdatedDdnList = useCallback(
    (type: string, route: any) => {
      let updatedList: SelectItemProps[] = [];
      switch (type) {
        case 'driver':
          updatedList = [...driversList];
          if (route && route.assignee) {
            updatedList = [...updatedList, {
              name: route.assignee.name,
              value: route.assignee.id
            }];
          }
          break;
        case 'helper':
          updatedList = [...helpersList];
          if (route && route.helper) {
            updatedList = [...updatedList, {
              name: route.helper.name,
              value: route.helper.id
            }];
          }
          break;
        case 'vehicle':
          updatedList = [...vehiclesList];
          if (route && route.assignedVehicle && updatedList.findIndex((vehicle: SelectItemProps) => vehicle.value === route.assignedVehicle.id) === -1) {
            updatedList = [...updatedList, {
              name: route.assignedVehicle.name,
              value: route.assignedVehicle.id
            }];
          }
          break;
        default:
          break;
      }
      return updatedList;
    },
    [driversList, helpersList, vehiclesList],
  );

  const handleClearCurrentSlot = useCallback(
    () => {
      dispatch(
        clearCurrentSlot()
      );
    },
    [dispatch],
  );

  const handleEditRoute = useCallback(
    () => {
      handleClearCurrentSlot();
      setEditRoute(location.state.route || null);
    },
    [location.state.route, handleClearCurrentSlot],
  );

  const checkUnselectVisibility = useCallback(
    () => {
      return editMode ? draftRoute.route ? draftRoute.route.displayRouteStatus !== 'PLANNED' ? false : true : true : true;
    },
    [editMode, draftRoute],
  )

  const initialSteps = useCallback(
    () => {
      if (viewMode || editMode) {
        sessionStorage.setItem('routeId', match.params.id);
      }
      if (editMode) {
        setNoRedirect(true);
        setSlotValue(location.state.slots);
      }
      onLoadRef.current = true;
      filterChangeRef.current = false;
      initializeRoute({
        date: currentDate,
        slotValue: editMode ? location.state.slots : slotValue,
        statusValue: statusValue
      });
    },
    [viewMode, editMode, currentDate, statusValue, location.state, match.params.id, slotValue, initializeRoute],
  );

  useEffect(() => {
    if (editRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(
          clearUpdatedRoute()
        );
      } else {
        onLoadRef.current = true;
        filterChangeRef.current = false;
        history.push(`${AppConstants.ROUTES.CARREFOUR_DELIVERY_EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          route: editRoute,
          slots: editRoute.deliverySlot || [],
          redirect: {
            route: `${AppConstants.ROUTES.CARREFOUR_DELIVERY_VIEW_ROUTE}/${editRoute.id}`,
            state: {
              plannedDate: editRoute.plannedDate,
              hubCode: editRoute.hubCode,
              hubName: editRoute.hubName,
              slots: editRoute.deliverySlot || [],
              route: editRoute
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRoute]);

  useEffect(() => {
    if (mapMarkers.length) {
      setUpdateMap(true);
    } else {
      setUpdateMap(false);
    }
  }, [mapMarkers]);

  useEffect(() => {
    if (optimizeMapRoute && mapRouteDirections) {
      const wayPointsOrder = mapRouteDirections.waypoint_order;
      const selectedWaypoints = getSeqSortedSelectedConsignments();
      if (wayPointsOrder && wayPointsOrder.length && selectedWaypoints.length) {
        let newSequence = _.cloneDeep(consignmentsSequence);
        selectedWaypoints.forEach((wp: any, index: number) => {
          newSequence[wp.code].seq = wayPointsOrder[index] + 1;
        });
        setConsignmentsSequence(newSequence);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRouteDirections]);

  useEffect(() => {
    if (autoRefreshMap) {
      handleRefreshMap();
    }
  }, [autoRefreshMap, handleRefreshMap]);

  useEffect(() => {
    if (autoRefreshMapRef.current) {
      setAutoRefreshMap(true);
    }
  }, [selectedConsignments]);

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
      if (errorCode === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.CONCURRENT_CONSIGNMENT) {
        shouldBlockNavigationRef.current = false;
        sessionStorage.setItem('extraConsignments', '[]');
        setShouldBlockNavigation(false);
      }
    }
  }, [error, errorCode]);

  useEffect(() => {
    if (allConsignments.length) {
      let newChecked: any = {};
      let newSelected: any = {};
      let extraConsignments: any = [];
      let checkedConsForSeq: string[] = [];
      let expandedConsignments = [...consignmentExpanded];
      allConsignments.forEach((item: any) => {
        if (newChecked[item.area]) {
          if (!newChecked[item.area][item.slot]) {
            newChecked[item.area][item.slot] = [];
          }
        } else {
          newChecked[item.area] = {
            [item.slot]: []
          };
        }
        if (newSelected[item.area]) {
          if (!newSelected[item.area][item.slot]) {
            newSelected[item.area][item.slot] = [];
          }
        } else {
          newSelected[item.area] = {
            [item.slot]: []
          };
        }
        if (item.status === 'PLANNED') {
          newChecked[item.area][item.slot].push(item.code);
          checkedConsForSeq.push(item.code);
          newSelected[item.area][item.slot].push(item);
          if (!(editMode || viewMode)) {
            extraConsignments.push(item.code);
          }
          expandedConsignments.push(item.area);
          expandedConsignments.push(`${item.area}-${item.slot}`);
        }
      });
      const seqParams: any = addSequence(checkedConsForSeq, 'PLANNED', {
        allSequencedCons: _.cloneDeep(consignmentsSequence),
        lastSeqNo: lastSequenceNumber
      });
      setConsignmentsSequence(seqParams.allSequencedCons);
      setLastSequenceNumber(seqParams.lastSeqNo);
      setCheckedConsignments(newChecked);
      setSelectedConsignments(newSelected);
      setUnplannedConsignmentsData({ ...unplannedConsignmentsData, selectedConsignments: newSelected });
      setExpandedConsignments(expandedConsignments);
      if (editMode && draftRoute.route) {
        if (draftRoute.route.assignee) {
          setDriverValue(draftRoute.route.assignee.id)
        } else {
          setDriverValue(AppConstants.SELECT_NONE.value);
        }
        if (draftRoute.route.helper) {
          setHelperValue(draftRoute.route.helper.id)
        } else {
          setHelperValue(AppConstants.SELECT_NONE.value);
        }
        if (draftRoute.route.assignedVehicle) {
          setVehicleValue(draftRoute.route.assignedVehicle.id);
        } else {
          setVehicleValue(AppConstants.SELECT_NONE.value);
        }
      }
      sessionStorage.setItem('extraConsignments', JSON.stringify(extraConsignments));
      autoRefreshMapRef.current = true;
      setOptimizeMapRoute(false);
    } else {
      setConsignmentsSequence({});
      setLastSequenceNumber(0);
      setCheckedConsignments({});
      setSelectedConsignments({});
      setExpandedConsignments([]);
      setExpandedConsignments([], true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allConsignments])

  useEffect(() => {
    if (editMode) {
      if (checkEditRouteExtraConsignments()) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
      } else {
        shouldBlockNavigationRef.current = true;
        setShouldBlockNavigation(true);
      }
    } else {
      if (getAllSelectedConsignments().length) {
        shouldBlockNavigationRef.current = true;
        setShouldBlockNavigation(true);
      } else {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedConsignments])

  useEffect(() => {
    let newConsignments = _.cloneDeep(slotwiseConsignments);
    Object.keys(newConsignments).forEach((areaKey: string) => {
      Object.keys(newConsignments[areaKey]).forEach((slotKey: string) => {
        if (newConsignments[areaKey]) {
          newConsignments[areaKey][slotKey] = [AppConstants.SELECT_ALL, ...newConsignments[areaKey][slotKey]]
        }
      })
    })
    setSelectableConsignments(newConsignments);
  }, [slotwiseConsignments])

  useEffect(() => {
    if (currentSlot) {
      let currentSlotValue: any = slotValue;
      if (editMode) {
        if (currentSlot && currentSlot.code) {
          if (onLoadRef.current) {
            currentSlotValue = [currentSlot.code];
          }
        }
        handleCreateDraftRoute({
          slots: currentSlotValue,
          statusValue: statusValue
        });
      } else {
        if (currentSlot && currentSlot.code) {
          if (onLoadRef.current) {
            currentSlotValue = [currentSlot.code];
          }
          handleCreateDraftRoute({
            slots: currentSlotValue,
            statusValue: statusValue
          });
        } else {
          currentSlotValue = [AppConstants.SELECT_NONE.value];
          handleOrderStatusOnUnload('UNPLANNED', true);
        }
      }
      setSlotValue(currentSlotValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlot])

  useEffect(() => {
    if (draftRoute.route) {
      updateConsignments();
    } else {
      updateAllConsignments({
        areaWiseConsignments: [],
        slotwiseConsignments: {},
        allConsignments: [],
        loading: loading
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftRoute]);

  useEffect(() => {
    if (routeCreateSuccess) {
      openSnackbarPopup(`${draftRoute.route && draftRoute.route.routeId} ${AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CREATE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      shouldBlockNavigationRef.current = false;
      setShouldBlockNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeCreateSuccess])

  useEffect(() => {
    if (updatedRoute && updatedRoute.route) {
      openSnackbarPopup(`Route "${updatedRoute.route.routeId}" ${AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.ROUTES_DATA.UPDATE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      if (!isPlannedRoute) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setConsignmentsSequence({});
        setLastSequenceNumber(0);
      }
    }
    if (routeDiscardSuccess) {
      shouldBlockNavigationRef.current = false;
      setShouldBlockNavigation(false);
    }
    if (!updatedRoute && !routeDiscardSuccess) {
      if (editRoute) {
        onLoadRef.current = true;
        filterChangeRef.current = false;
        history.push(`${AppConstants.ROUTES.CARREFOUR_DELIVERY_EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          slots: editRoute.deliverySlot || [],
          route: editRoute,
          redirect: {
            route: `${AppConstants.ROUTES.CARREFOUR_DELIVERY_VIEW_ROUTE}/${editRoute.id}`,
            state: {
              plannedDate: editRoute.plannedDate,
              hubCode: editRoute.hubCode,
              hubName: editRoute.hubName,
              slots: editRoute.deliverySlot || [],
              route: editRoute
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRoute, routeDiscardSuccess]);

  useEffect(() => {
    if (routeConsignments) {
      const transformedConsignments: any = transformRouteConsignments(routeConsignments);
      const seqParams: any = addSequence(transformedConsignments.seqConsignments, 'PLANNED', {
        allSequencedCons: {},
        lastSeqNo: 0
      });
      setConsignmentsSequence(seqParams.allSequencedCons);
      setSelectedConsignments(transformedConsignments.routeConsignments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeConsignments]);

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (routeCreateSuccess) {
        initializeRoute({
          date: currentDate,
          slotValue: slotValue,
          statusValue: statusValue
        });
      }
      if ((updatedRoute && !isPlannedRoute) || (routeDiscardSuccess && !noRedirect)) {
        let route: any = AppConstants.ROUTES.PLANNING;
        let state: any = {
          tabValue: AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES
        }
        if (location.state && location.state.redirect) {
          route = location.state.redirect.route;
          state = updatedRoute ? { ...location.state.redirect.state, route: updatedRoute.route } : location.state.redirect.state;
          onLoadRef.current = true;
          filterChangeRef.current = false;
          setEditRoute(null);
        }
        handleClearCurrentSlot();
        history.push(route, state);
      }
      if (errorCode && errorCode === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.CONCURRENT_CONSIGNMENT) {
        initializeRoute({
          date: currentDate,
          slotValue: slotValue,
          statusValue: statusValue
        });
      }
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldBlockNavigation, routeDiscardSuccess, updatedRoute, noRedirect])

  useEffect(() => {
    if (onLoadRef.current) {
      initialSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, editMode])

  useEffect(() => {
    initialSteps();
    return () => {
      if (editMode && sessionStorage.getItem('routeId')) {
        shouldBlockNavigationRef.current && handleDiscardRoute();
      } else {
        if (!viewMode) {
          handleOrderStatusOnUnload('UNPLANNED', true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStepperTimelineItems = () => {
    return getSeqSortedSelectedConsignments().map((consignmentItem: any, index: number) => (
      <Step key={`timeline-step-${consignmentItem.code}`} className='timelineStep'>
        <Draggable
          draggableId={consignmentItem.code}
          index={index}
        >
          {(provided) => (
            <StepLabel {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className='stepLabel' StepIconComponent={activeTimelineDot}>{`${index + 1}`.padStart(2, '0')}</StepLabel>
          )
          }
        </Draggable >
      </Step >
    ))
  }

  const getListViewTimelineItems = () => {
    return getSeqSortedSelectedConsignments().map((consignmentItem: any, index: number) => (
      <TimelineItem key={`listview-timeline-item-${consignmentItem.code}`} className="timelineItem">
        <TimelineSeparator>
          <TimelineDot className="timelineDot listItem">
            <Grid className="countIcon">
              <Typography className="count">{`${index + 1}`.padStart(2, '0')}</Typography>
            </Grid>
          </TimelineDot>
          <TimelineConnector className="primaryConnector" />
        </TimelineSeparator>
        <TimelineContent className="timelineContent">
          <Grid className="contentContainer" container>
            <Grid className="listViewContentItem itemHeading" onClick={() => handleConsignmentDetailsClick(consignmentItem.data)} item>
              <Typography className="id">{consignmentItem.code}</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid className="listViewContentItem itemDetails" item>
              <Grid className="itemDetailsContainer" container>
                {/* <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                    <Typography className="count">{`${consignmentItem.data && consignmentItem.data.deliveryInformation && consignmentItem.data.deliveryInformation.totalCratesCount}`.padStart(2, '0')}</Typography>
                  </Grid>
                </Grid> */}
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={walletIcon} />
                    <Typography className="count">{consignmentItem.data && consignmentItem.data.metadata && consignmentItem.data.metadata.paymentType}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={mobileIcon} />
                    <Typography className="count">{consignmentItem.data && consignmentItem.data.deliveryInformation && consignmentItem.data.deliveryInformation.customerContactInfo ? consignmentItem.data.deliveryInformation.customerContactInfo.phone : ""}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={timeSlotIcon} />
                    <Typography className="count">{consignmentItem.slotValue}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                    <Typography className="count address">{getAddressInfo(consignmentItem.data && consignmentItem.data.deliveryInformation ? consignmentItem.data.deliveryInformation.customerContactInfo.address : "")}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    ))
  }

  const getSlotwisePlannedRoutes = () => {
    return slotwisePlannedRoutes.length ? slotwisePlannedRoutes.map((slotItem: any) => (
      <Accordion key={`plannedRoute-${slotItem.code}`} className="accordionRoot delSlot">
        <AccordionSummary
          expandIcon={<SvgIcon component={downArrowIcon} />}
          aria-controls="delivery-slot-content"
          className="accordianSummary delSlot"
        >
          <Grid className="slotHeaderContainer" container>
            <Grid className="slotHeaderItem slot" item>
              <Grid className="itemContainer" container>
                <Typography className="heading">{slotItem.value}</Typography>
              </Grid>
            </Grid>
            <Grid className="slotHeaderItem consignment" item>
              <Grid className="itemContainer" container>
                <SvgIcon className="icon" viewBox="0 0 16 16" component={consignmentsIcon} />
                <Typography className="count">{`${slotItem.totalConsignments}`.padStart(2, '0')}</Typography>
              </Grid>
            </Grid>
            {/* <Grid className="slotHeaderItem crates" item>
              <Grid className="itemContainer" container>
                <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                <Typography className="count">{`${slotItem.totalCrates}`.padStart(2, '0')}</Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails delSlot routes">
          <Grid className="slotContentContainer" container>
            {
              slotItem.data && slotItem.data.length && slotItem.data.map((routeItem: any) => (
                <Grid key={routeItem.code} container className={`slotItemContainer ${selectedRoute && selectedRoute.code === routeItem.code ? 'selected' : ''}`} onClick={() => handleRouteSelection(routeItem)}>
                  <Grid className="slotContentItem consignmentDetails" item>
                    <Grid className="consignmentDetailsContainer" container direction="column">
                      <Grid className="detailsItem" item>
                        <Typography className="heading">{routeItem.value}</Typography>
                      </Grid>
                      <Grid className="detailsItem desc" item>
                        <Grid className="descContainer" container>
                          <Grid className="descItem consignments" item>
                            <Grid className="itemContainer" container>
                              <SvgIcon className="icon" viewBox="0 0 16 16" component={consignmentsIcon} />
                              <Typography className="count">{routeItem.data && routeItem.data.totalConsignment}</Typography>
                            </Grid>
                          </Grid>
                          {/* <Grid className="descItem crates" item>
                            <Grid className="itemContainer" container>
                              <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                              <Typography className="count">{routeItem.data && routeItem.data.totalCrates}</Typography>
                            </Grid>
                          </Grid> */}
                          <Grid className="descItem timeSlot" item>
                            <Grid className="itemContainer" container>
                              <SvgIcon className="icon" viewBox="0 0 16 16" component={timeSlotIcon} />
                              <Typography className="count">{routeItem.data && routeItem.data.transitTime}</Typography>
                            </Grid>
                          </Grid>
                          <Grid className="descItem assignee" item>
                            <Grid className="itemContainer" container>
                              <SvgIcon className="icon" viewBox="0 0 16 16" component={userIcon} />
                              <Typography className="count">{routeItem.data && routeItem.data.assignee ? routeItem.data.assignee.name : 'Not Assigned'}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    )) : (<Typography className="padding-2">No Items to display</Typography>)
  }

  const getViewModeConsignments = useCallback(
    () => {
      return routeConsignments.length ? routeConsignments.map((consignmentItem: any) => (
        <Grid key={consignmentItem.consignmentCode} container className='slotItemContainer'>
          <Grid className="slotContentItem consignmentDetails" item>
            <Grid className="consignmentDetailsContainer" container>
              <Grid className="detailsItem consignment" item>
                <Grid container className="consignmentHeader">
                  <Grid item className="consignmentHeaderItem heading">
                    <Grid container className="headingContainer">
                      <Grid className="headingItem" onClick={() => handleConsignmentDetailsClick(consignmentItem)} item>
                        <Typography className="heading">{consignmentItem.consignmentCode}</Typography>
                      </Grid>
                      <Grid className="headingItem status" item>
                        <Grid className={classes.statusHeaderContainer} container alignItems="center">
                          <Typography className={['tag', getConsignmentStatusField('color', consignmentItem.metadata.deliveryStatus)].join(" ")}>{consignmentItem ? consignmentItem.metadata.deliveryStatus : ""}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="consignmentHeaderItem details">
                    <Grid container className="headerDetailsContainer">
                      {/* <Grid className="detailsContainerItem crates" item>
                        <Grid className="itemContainer" container>
                          <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                          <Typography className="count">{consignmentItem && consignmentItem.deliveryInformation.totalCratesCount}</Typography>
                        </Grid>
                      </Grid> */}
                      <Grid className="detailsContainerItem location" item>
                        <Grid className="itemContainer" container>
                          <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                          <Typography className="count locDesc">{getAddressInfo(consignmentItem && consignmentItem.deliveryInformation ? consignmentItem.deliveryInformation.customerContactInfo.address : "")}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))
        : (<Typography className="padding-2">No Items to display</Typography>)
    },
    [classes.statusHeaderContainer, routeConsignments, handleConsignmentDetailsClick],
  )

  const getAreawiseConsignments = useCallback(
    () => {
      return areawiseConsignments.length ? areawiseConsignments.map((areaItem: any) => (
        <Accordion expanded={checkExpanded(areaItem)} onChange={handleConsignmentAccordionChange(areaItem)} key={areaItem.code} className="accordionRoot delArea">
          <AccordionSummary
            expandIcon={<SvgIcon component={downArrowIcon} />}
            aria-controls="delivery-area-content"
            className="accordianSummary delArea"
          >
            <Grid className="areaHeaderContainer" container>
              <Grid className="areaHeaderItem area" item>
                <Grid className="itemContainer" container>
                  <Typography className="heading" dangerouslySetInnerHTML={{ __html: updateHighlightSearch({ code: areaItem.value }) }} />
                </Grid>
              </Grid>
              <Grid className="areaHeaderItem consignment" item>
                <Grid className="itemContainer" container>
                  <SvgIcon className="icon" viewBox="0 0 16 16" component={consignmentsIcon} />
                  <Typography className="count">{getAreaTotalConsignments(areaItem).padStart(2, '0')}</Typography>
                </Grid>
              </Grid>
              {/* <Grid className="areaHeaderItem crates" item>
                <Grid className="itemContainer" container>
                  <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                  <Typography className="count">{getAreaTotalCrates(areaItem).padStart(2, '0')}</Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordianDetails delArea">
            <Grid className="delSlotContainer" container>
              {
                areaItem.data && areaItem.data.length && areaItem.data.map((slotItem: any) => (
                  <Accordion expanded={checkExpanded(slotItem, true)} onChange={handleConsignmentAccordionChange(slotItem, true)} key={slotItem.code} className="accordionRoot delSlot">
                    <AccordionSummary
                      expandIcon={<SvgIcon component={downArrowIcon} />}
                      aria-controls="delivery-slot-content"
                      className="accordianSummary delSlot"
                    >
                      <Grid className="slotHeaderContainer" container>
                        <Grid className="slotHeaderItem slot" item>
                          <Grid className="itemContainer" container>
                            <Checkbox color="primary" checked={getSelectedValue(slotItem, true)} onClick={(e: any) => handleCheckboxToggle(e, slotItem, true)} />
                            <Typography className="heading">{slotItem.value}</Typography>
                          </Grid>
                        </Grid>
                        <Grid className="slotHeaderItem consignment" item>
                          <Grid className="itemContainer" container>
                            <SvgIcon className="icon" viewBox="0 0 16 16" component={consignmentsIcon} />
                            <Typography className="count">{`${slotItem.data.length}`.padStart(2, '0')}</Typography>
                          </Grid>
                        </Grid>
                        {/* <Grid className="slotHeaderItem crates" item>
                          <Grid className="itemContainer" container>
                            <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                            <Typography className="count">{`${getTotalCrates(slotItem.data)}`.padStart(2, '0')}</Typography>
                          </Grid>
                        </Grid> */}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails delSlot">
                      <Grid className="slotContentContainer" container>
                        {
                          slotItem.data && slotItem.data.length && slotItem.data.map((consignmentItem: any) => (
                            <Grid key={consignmentItem.code} container className={`slotItemContainer ${getSelectedValue(consignmentItem, false) ? 'selected' : ''} ${consignmentItem.status === 'DRAFTED' ? 'drafted' : checkTerminalStatus(consignmentItem.data.metadata.deliveryStatus) ? 'terminal' : ''}`}>
                              <Grid className="slotContentItem checkbox" item>
                                <Grid container className="checkboxContainer">
                                  {
                                    consignmentItem.status === 'DRAFTED' &&
                                    <CustomTooltip placement="bottom-start" title={AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.DRAFTED_CONSIGNMENT_TOOLTIP_MSG}>
                                      <SvgIcon className="lockIcon" viewBox="0 0 12 12" component={lockIcon} />
                                    </CustomTooltip>
                                  }
                                  <Checkbox disabled={consignmentItem.status === 'DRAFTED' || checkTerminalStatus(consignmentItem.data.metadata.deliveryStatus)} checked={getSelectedValue(consignmentItem, false)} onClick={(e: any) => handleCheckboxToggle(e, consignmentItem, false)} color="primary" />
                                </Grid>
                              </Grid>
                              <Grid className="slotContentItem consignmentDetails" item>
                                <Grid className="consignmentDetailsContainer" container>
                                  <Grid className="detailsItem consignment" item>
                                    <Grid container className="consignmentHeader">
                                      <Grid item className="consignmentHeaderItem heading">
                                        <Grid container className="headingContainer">
                                          <Grid className="headingItem" onClick={() => handleConsignmentDetailsClick(consignmentItem.data)} item>
                                            <Typography className="heading" dangerouslySetInnerHTML={{ __html: updateHighlightSearch(consignmentItem) }} />
                                          </Grid>
                                          <Grid className="headingItem status" item>
                                            <Grid className={classes.statusHeaderContainer} container alignItems="center">
                                              <Typography className={['tag', getConsignmentStatusField('color', consignmentItem.data.metadata.deliveryStatus)].join(" ")}>{consignmentItem.data ? consignmentItem.data.metadata.deliveryStatus : ""}</Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item className="consignmentHeaderItem details">
                                        <Grid container className="headerDetailsContainer">
                                          {/* <Grid className="detailsContainerItem crates" item>
                                            <Grid className="itemContainer" container>
                                              <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                                              <Typography className="count">{consignmentItem.data && consignmentItem.data.deliveryInformation.totalCratesCount}</Typography>
                                            </Grid>
                                          </Grid> */}
                                          <Grid className="detailsContainerItem location" item>
                                            <Grid className="itemContainer" container>
                                              <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                                              <Typography className="count locDesc" dangerouslySetInnerHTML={{ __html: updateHighlightSearch({ code: getAddressInfo(consignmentItem.data && consignmentItem.data.deliveryInformation ? consignmentItem.data.deliveryInformation.customerContactInfo.address : "") }) }} />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid className="detailsItem sequence" item>
                                    {
                                      getSelectedValue(consignmentItem, false) &&
                                      <Grid className="seqNoContainer">
                                        <Typography className="seqNo">{consignmentsSequence[consignmentItem.code] && `${consignmentsSequence[consignmentItem.code].seq}`.padStart(2, '0')}</Typography>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      )) : (<Typography className="padding-2">No Items to display</Typography>)
    },
    [areawiseConsignments, consignmentsSequence, classes.statusHeaderContainer, checkExpanded, getAreaTotalConsignments, getSelectedValue, handleCheckboxToggle, handleConsignmentAccordionChange, handleConsignmentDetailsClick, updateHighlightSearch],
  );

  return (
    <div className={classes.root}>
      {loading && <Loader></Loader>}
      {!viewMode &&
        <Prompt
          when={shouldBlockNavigation}
          message={() => 'All changes will be discarded. Are you sure you want to leave this page?'}
        />
      }
      <Grid className={classes.container} container>
        <Grid className="createRtItem heading" item>
          <Grid container className="headingContainer">
            <Typography className="headingTitle">{editMode ? `Edit ${draftRoute.route ? draftRoute.route.routeId : ""}` : viewMode ? location.state.route.routeId : 'Create Route'}</Typography>
            {
              viewMode && location.state.route.displayRouteStatus !== AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.ROUTES_DATA.UNEDITABLE_CHIP &&
              <>
                <Grid item>
                  <Grid className={classes.statusHeaderContainer} container>
                    <Typography className={['tag', getRouteStatusField('color', location.state.route.status),].join(" ")}>{location.state ? location.state.route.status : ""}</Typography>
                    {!(isLastMileViewer || isOnlyStdLastMileViewer) &&
                      <SvgIcon
                        component={editIcon}
                        className='editIcon'
                        onClick={handleEditRoute}
                        viewBox='0 0 24 24'
                      />
                    }
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
        </Grid>
        <Grid className="createRtItem content" item>
          <Grid className={classes.createRtContentContainer} container>
            <Grid className="contentItem left" item>
              <Grid className="consignmentsContainer" container>
                <Grid className="consignmentItem header" item>
                  <Grid container className={`headerSectionContainer ${viewMode ? 'viewMode' : ""}`}>
                    <Grid className="headerSectionItem" item>
                      <Grid className="headerContainer" container>
                        <Grid className="headerItem heading" item>
                          <Grid container alignItems="center">
                            <Typography>{viewMode ? 'Consignments' : tabValue === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? `Consignments(${allConsignments.length})` : 'Routes'}</Typography>
                            {
                              !viewMode && tabValue !== AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ?
                                <SvgIcon className="refreshIcon" component={refreshIcon} onClick={handleRefreshPlannedRoutes} viewBox="0 0 20 17" />
                                : null
                            }
                          </Grid>
                        </Grid>
                        {
                          !viewMode &&
                          <Grid className="headerItem icons" item>
                            {
                              tabValue === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED &&
                              <TextBox
                                className="searchTextBox"
                                variant="outlined"
                                onChange={handleSearch}
                                value={consignmentSearchValue}
                                textBoxId="consignmentSearchTextbox"
                                name="consignmentSearch"
                                type="text"
                                placeholderText="Search Consignments/Area/Address"
                                endadornment={(
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      size="small"
                                    >
                                      <SvgIcon component={searchIcon} viewBox="0 0 16 16" />
                                    </IconButton>
                                  </InputAdornment>
                                )}
                              ></TextBox>
                            }
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                    {
                      !viewMode &&
                      <Grid className="headerSectionItem" item>
                        <Grid container className="filtersContainer">
                          <Grid className="filterItem" item>
                            <SelectBox
                              multiple
                              handleChange={handleStatusChange}
                              menuPropsList={classes.selectMenuPropsList}
                              menuPaperProps={classes.selectMenuPaperProps}
                              value={statusValue}
                              id="create-route-status-select-box"
                              items={consignmentStatusList || []}
                            ></SelectBox>
                          </Grid>
                          <Grid className="filterItem slot" item>
                            <SelectBox
                              multiple
                              handleChange={handleSlotChange}
                              menuPropsList={classes.selectMenuPropsList}
                              menuPaperProps={classes.selectMenuPaperProps}
                              value={slotValue}
                              id="create-route-slot-select-box"
                              items={slotsList || []}
                            ></SelectBox>
                          </Grid>
                          {
                            !editMode &&
                            <Grid className="filterItem" item>
                              <DateRangePickerComponent
                                keyId="create-route-date-picker"
                                className="icon"
                                value={currentDate}
                                isTextField={false}
                                iconSrc={calendarIcon}
                                autoUpdateInput={false}
                                autoApply={true}
                                maxSpan={{
                                  day: '0'
                                }}
                                showDropdowns={true}
                                linkedCalendars={true}
                                locale={{
                                  format: AppConstants.DATE_FORMAT
                                }}
                                updateDateRange={handleDateChange}
                              />
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid className="consignmentItem content" item>
                  {
                    viewMode ?
                      <Grid className="contentContainer slotContentContainer viewMode">
                        {getViewModeConsignments()}
                      </Grid>
                      :
                      <Grid className="contentContainer">
                        <TabContext value={tabValue}>
                          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Consignments for Route Creation">
                            <Tab label="Unplanned" value={AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED} />
                            <Tab label="Planned" value={AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED} />
                          </Tabs>
                          <TabPanel className="tabPanel" value={AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED}>
                            <Grid className="tabPanelContainer unplanned" container>
                              {getAreawiseConsignments()}
                            </Grid>
                          </TabPanel>
                          <TabPanel className="tabPanel" value={AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED}>
                            <Grid className="tabPanelContainer planned" container>
                              {getSlotwisePlannedRoutes()}
                            </Grid>
                          </TabPanel>
                        </TabContext>
                        <Grid className={`hubLabel ${tabValue !== AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? 'isRoute' : ''}`}>
                          <Grid className="hubLabelContainer" container>
                            <Typography className="hubLabelText"><span className="label">Hub:</span>{location.state.hubName || ""}</Typography>
                          </Grid>
                        </Grid>
                        {
                          tabValue === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED &&
                          <Grid className="toggleAll" onClick={toggleAllDropdowns}>
                            <Grid className="toggleAllContainer" container>
                              <Grid className="toggleItem" item>
                                <Typography className="toggleText">All</Typography>
                              </Grid>
                              <Grid className={`toggleItem iconItem ${toggleAllDropdownExpanded ? 'expanded' : ''}`} item>
                                <SvgIcon className="toggleIcon" viewBox="0 0 21 21" component={downArrowIcon} />
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentItem right" item>
              <Grid className="detailsContainer" container>
                {
                  viewMode ?
                    <Grid className="detailItem routeDetails viewMode" item>
                      <Grid className="routeDetailsContainer" container>
                        {
                          location.state.route ?
                            <Grid className="routeDetailsItem header" item>
                              <Typography className="heading">{location.state.route && location.state.route.routeId}</Typography>
                            </Grid>
                            : null
                        }
                        {
                          location.state.route ?
                            <Grid className="routeDetailsItem basicDesc" item>
                              <Grid className="basicDescContainer" container>
                                <Grid className="basicItem descriptions" item>
                                  <Grid className="descCommonContainer" container>
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Driver</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{location.state.route && location.state.route.assignee ? location.state.route.assignee.name : 'Not Assigned'}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Helper</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{location.state.route && location.state.route.helper ? location.state.route.helper.name : 'Not Assigned'}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Vehicle</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{location.state.route && location.state.route.assignedVehicle ? location.state.route.assignedVehicle.name : 'Not Assigned'}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Hub</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{location.state.route && location.state.route.hubName}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Delivery Area</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <CustomTooltip title={getSelectedAreasTooltip()}>
                                            <Typography className="columnValue">{getSelectedAreas()}</Typography>
                                          </CustomTooltip>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="basicItem descriptions" item>
                                  <Grid className="descCommonContainer" container>

                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Consignments</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{location.state.route && `${location.state.route.totalConsignment}`.padStart(2, '0')}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {/*  <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Crates</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{location.state.route && `${location.state.route.totalCrates}`.padStart(2, '0')}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid> */}
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Delivery Slot</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <CustomTooltip title={getSelectedSlotsTooltip()}>
                                            <Typography className="columnValue">{getSelectedDeliverySlots()}</Typography>
                                          </CustomTooltip>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Delivery Date</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{getCurrentSelectedDate()}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="commonItem" item>
                                      <Grid className="descContainer" container>
                                        <Grid className="descItem title" item>
                                          <Typography>Transit Time</Typography>
                                        </Grid>
                                        <Grid className="descItem value" item>
                                          <Typography>{getTransitTime()}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            :
                            <Grid className="routeDetailsItem noSelectedArea" item>
                              <Typography>Route Details will appear here</Typography>
                            </Grid>
                        }
                      </Grid>
                    </Grid>
                    :
                    tabValue === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ?
                      <Grid className="detailItem routeDetails" item>
                        <Grid className="routeDetailsContainer" container>
                          <Grid className="routeDetailsItem header" item>
                            <Grid container className="routeIdHeaderContainer">
                              <Grid item className="headingItem">
                                <Typography className="heading">{draftRoute.route && draftRoute.route.routeId}</Typography>
                              </Grid>
                              {
                                getAllSelectedConsignments().length ?
                                  <Grid item className="ddnsItem">
                                    <SelectBox
                                      handleChange={handleDriverChange}
                                      value={driverValue}
                                      id="driver-select-box"
                                      label="Driver"
                                      items={getUpdatedDdnList('driver', draftRoute.route)}
                                      showSelectNone={checkUnselectVisibility()}
                                    ></SelectBox>
                                    <SelectBox
                                      handleChange={handleHelperChange}
                                      value={helperValue}
                                      id="helper-select-box"
                                      label="Helper"
                                      items={getUpdatedDdnList('helper', draftRoute.route)}
                                      showSelectNone
                                    ></SelectBox>
                                    <SelectBox
                                      handleChange={handleVehicleChange}
                                      value={vehicleValue}
                                      id="vehicle-select-box"
                                      label="Vehicle"
                                      items={getUpdatedDdnList('vehicle', draftRoute.route)}
                                      showSelectNone={checkUnselectVisibility()}
                                    ></SelectBox>
                                  </Grid>
                                  : null
                              }
                            </Grid>
                          </Grid>
                          {
                            getAllSelectedConsignments().length ?
                              <Grid className="routeDetailsItem basicDesc" item>
                                <Grid className="basicDescContainer" container>
                                  <Grid className="basicItem descriptions" item>
                                    <Grid className="descCommonContainer" container>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Delivery Area</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <CustomTooltip title={getSelectedAreasTooltip()}>
                                              <Typography className="columnValue">{getSelectedAreas()}</Typography>
                                            </CustomTooltip>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Consignments</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Grid container className="capacityBreachContainer">
                                              <Typography>{`${getAllSelectedConsignments().length}`.padStart(2, '0')}</Typography>
                                              {
                                                calculateCapacityBreach('consignments') &&
                                                <CustomTooltip placement="bottom-start" title={AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS}>
                                                  <SvgIcon className="infoErrorIcon" viewBox="0 0 16 16" component={infoErrorIcon} />
                                                </CustomTooltip>
                                              }
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {/* <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Crates</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Grid container className="capacityBreachContainer">
                                              <Typography>{`${getTotalCrates(getAllSelectedConsignments())}`.padStart(2, '0')}</Typography>
                                              {
                                                calculateCapacityBreach('crates') &&
                                                <CustomTooltip placement="bottom-start" title={AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CRATES}>
                                                  <SvgIcon className="infoErrorIcon" viewBox="0 0 16 16" component={infoErrorIcon} />
                                                </CustomTooltip>
                                              }
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid> */}
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Delivery Slot</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <CustomTooltip title={getSelectedSlotsTooltip()}>
                                              <Typography className="columnValue">{getSelectedDeliverySlots()}</Typography>
                                            </CustomTooltip>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Delivery Date</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Typography>{getCurrentSelectedDate()}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Transit Time</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Typography>{getTransitTime()}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              :
                              <Grid className="routeDetailsItem noSelectedArea" item>
                                <Typography>Selected Consignments will appear here</Typography>
                              </Grid>
                          }
                          {
                            getAllSelectedConsignments().length ?
                              <Grid className="routeDetailsItem timeline" item>
                                <Typography component="div" className="content">
                                  <DragDropContext onDragStart={onConsignmentsDragStart} onDragEnd={onConsignmentsDragEnd}>
                                    <Droppable droppableId='droppableArea' direction="horizontal" ignoreContainerClipping>
                                      {(provided) => (
                                        <Stepper className={classes.timelineStepper} {...provided.droppableProps} ref={provided.innerRef} alternativeLabel connector={<StepperTimelineConnector hidden={draggingItem} />}>
                                          <Step className="timelineStep first">
                                            <StepLabel
                                              className="stepLabel"
                                              StepIconComponent={areaTimelineIcon}
                                            >
                                              {draftRoute.route && draftRoute.route.hubName}
                                            </StepLabel>
                                          </Step>
                                          {getStepperTimelineItems()}
                                          {provided.placeholder}
                                        </Stepper>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </Typography>
                              </Grid>
                              : null
                          }
                          {
                            getAllSelectedConsignments().length ?
                              <Grid className="routeDetailsItem timelineWarning" item>
                                <Grid container className="warningContainer">
                                  <Grid item className="warningItem">
                                    <SvgIcon className="warningIcon" component={infoWarningIcon} viewBox="0 0 20 20" />
                                  </Grid>
                                  <Grid item className="warningItem">
                                    <Typography>You can also drag and reorder the consigments to change their sequence.</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              : null
                          }
                        </Grid>
                      </Grid>
                      :
                      <Grid className="detailItem routeDetails planned" item>
                        <Grid className="routeDetailsContainer" container>
                          {
                            selectedRoute.code ?
                              <Grid className="routeDetailsItem header" item>
                                <Typography className="heading">{selectedRoute && selectedRoute.value}</Typography>
                              </Grid>
                              : null
                          }
                          {
                            selectedRoute.code ?
                              <Grid className="routeDetailsItem basicDesc" item>
                                <Grid className="basicDescContainer" container>
                                  <Grid className="basicItem descriptions" item>
                                    <Grid className="descCommonContainer" container>
                                      <Grid className="commonItem" item>
                                        <SelectBox
                                          handleChange={handleRouteDriverChange}
                                          value={routeDriverValue}
                                          id="route-driver-select-box"
                                          label="Driver"
                                          items={getUpdatedDdnList('driver', selectedRoute.data)}
                                          showSelectNone={true}
                                        ></SelectBox>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <SelectBox
                                          handleChange={handleRouteHelperChange}
                                          value={routeHelperValue}
                                          id="route-helper-select-box"
                                          label="Helper"
                                          items={getUpdatedDdnList('helper', selectedRoute.data)}
                                          showSelectNone={true}
                                        ></SelectBox>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <SelectBox
                                          handleChange={handleRouteVehicleChange}
                                          value={routeVehicleValue}
                                          id="route-vehicle-select-box"
                                          label="Vehicle"
                                          items={getUpdatedDdnList('vehicle', selectedRoute.data)}
                                          showSelectNone={true}
                                        ></SelectBox>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Hub</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Typography>{selectedRoute.data && selectedRoute.data.hubName}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Delivery Area</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <CustomTooltip title={getSelectedAreasTooltip()}>
                                              <Typography className="columnValue">{getSelectedAreas()}</Typography>
                                            </CustomTooltip>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid className="basicItem descriptions" item>
                                    <Grid className="descCommonContainer" container>

                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Consignments</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Typography>{selectedRoute.data && `${selectedRoute.data.totalConsignment}`.padStart(2, '0')}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {/* <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Crates</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Typography>{selectedRoute.data && `${selectedRoute.data.totalCrates}`.padStart(2, '0')}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid> */}
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Delivery Slot</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <CustomTooltip title={getSelectedSlotsTooltip()}>
                                              <Typography className="columnValue">{getSelectedDeliverySlots()}</Typography>
                                            </CustomTooltip>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Delivery Date</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Typography>{getCurrentSelectedDate()}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="commonItem" item>
                                        <Grid className="descContainer" container>
                                          <Grid className="descItem title" item>
                                            <Typography>Transit Time</Typography>
                                          </Grid>
                                          <Grid className="descItem value" item>
                                            <Typography>{getTransitTime()}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              :
                              <Grid className="routeDetailsItem noSelectedArea" item>
                                <Typography>Selected Route Details will appear here</Typography>
                              </Grid>
                          }
                        </Grid>
                      </Grid>
                }
                <Grid className="detailItem mapZone" item>
                  {
                    tabValue === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED && !selectedRoute.code ? null : isListView ? getAllSelectedConsignments().length ?
                      <Typography component="div" className="listViewTimelineContent">
                        <Timeline className={classes.listViewTimeline}>
                          <TimelineItem className="timelineItem first">
                            <TimelineSeparator>
                              <TimelineDot className="timelineDot">
                                <SvgIcon className="timelineIcon areaIcon" component={areaTimelineIcon} viewBox="0 0 32 32" />
                              </TimelineDot>
                              <TimelineConnector className="primaryConnector" />
                            </TimelineSeparator>
                            <TimelineContent className="timelineContent first">
                              <Typography className="titleHeading">{viewMode ? location.state.route && location.state.route.hubName : draftRoute.route && draftRoute.route.hubName}</Typography>
                            </TimelineContent>
                          </TimelineItem>
                          {getListViewTimelineItems()}
                        </Timeline>
                      </Typography>
                      : null :
                      <MapContainer center={getMapCenter()} zoom={12} markers={mapMarkers} showDirections={true} roundTrip={true} optimizeRoute={optimizeMapRoute} updateMap={updateMap}></MapContainer>
                  }
                  <Grid className="mapSwitchBtnContainer" container>
                    <Grid className={`switchBtnItem mapView ${isListView ? '' : 'selected'}`} onClick={() => toggleListView('map')} item>
                      <Grid container className="switchBtnItemContainer">
                        <SvgIcon className="switchBtnIcon" component={mapViewIcon} htmlColor="transparent" viewBox="0 0 21 20" />
                        <Typography className="title">Map</Typography>
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid className={`switchBtnItem listView ${isListView ? 'selected' : ''}`} onClick={() => toggleListView('list')} item>
                      <Grid container className="switchBtnItemContainer">
                        <SvgIcon className="switchBtnIcon" component={listViewIcon} viewBox="0 0 20 20" />
                        <Typography className="title">List</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {
                  !viewMode ?
                    <Grid className="detailItem buttons" item>
                      <Grid className="buttonsContainer" container>
                        {
                          !isListView &&
                          <>
                            <Button className="refreshMapBtn secondary btn" variant="contained" onClick={handleRefreshMap}>{AppConstants.BUTTONS.REFRESH_MAP}</Button>
                            <Button className="optimizeBtn secondary btn" variant="contained" disabled={disableOptimizeMapRoute()} onClick={handleOptimizeMapRoute}>{AppConstants.BUTTONS.OPTIMIZE}</Button>
                          </>
                        }
                        {
                          tabValue === AppConstants.CARREFOUR_HOME_DLVRY_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ?
                            editMode ?
                              <>
                                <Button className="discardBtn secondary btn" variant="contained" onClick={handleDiscardChanges}>{AppConstants.BUTTONS.DISCARD}</Button>
                                <Button className="saveBtn primary" disabled={disableCreateRt() || disableEditRt()} variant="contained" onClick={handleUpdateRoute}>{AppConstants.BUTTONS.SAVE}</Button>
                              </>
                              :
                              <Button className="createRtBtn primary btn" disabled={disableCreateRt()} variant="contained" onClick={() => handleCreateRoute(true)}>{AppConstants.BUTTONS.CREATE_ROUTE}</Button>
                            :
                            <Button className="saveBtn primary" disabled={!selectedRoute.data} variant="contained" onClick={handleUpdatePlannedRoute}>{AppConstants.BUTTONS.SAVE}</Button>
                        }
                      </Grid>
                    </Grid>
                    : null
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CarrefourDeliveryOrderDetails open={openConsignmentDetailsPopup} isFromViewRoute={true} isEditIcon={isEditIcon} consignment={consignmentData} closePopup={closeConsignmentDetailsPopup}></CarrefourDeliveryOrderDetails>
      <CustomDialog
        open={openDiscardConfirmDialog}
        PaperProps={confirmDialogPaperProps}
        title={getChangeDialogTitleContent()}
        content={getDiscardDialogDetailsContent()}
        actions={getDiscardDialogActions()}
        handleClose={handleDiscardDialogClose}
      ></CustomDialog>
      <CustomDialog
        open={openDateChangeConfirmDialog}
        PaperProps={confirmDialogPaperProps}
        title={getChangeDialogTitleContent()}
        content={getChangeDialogDetailsContent()}
        actions={getChangeDialogActions()}
        handleClose={handleChangeDialogClose}
      ></CustomDialog>
      <CustomDialog
        open={openUpdateConfirmDialog}
        PaperProps={confirmDialogPaperProps}
        title={getUpdateDialogTitleContent()}
        content={getUpdateDialogDetailsContent()}
        actions={getUpdateDialogActions()}
        handleClose={handleUpdateDialogClose}
      ></CustomDialog>
      <CustomDialog
        open={openCapacityBreachConfirmDialog}
        PaperProps={confirmWithTitleDialogProps}
        title={getCapacityBreachDialogTitleContent()}
        content={getCapacityBreachDialogDetailsContent()}
        actions={getCapacityBreachDialogActions()}
        handleClose={handleCapacityBreachDialogClose}
      ></CustomDialog>
      <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
    </div >
  );
}

export default CreateRoute;