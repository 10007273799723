import { FormControl, InputLabel } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge } from '@material-ui/core';
import AppConstants from '../../constants';
import DatePickerComponent from '../DatePickerComponent';
import filterIcon from './../../assets/icons/filter-icon.svg';
import { useStyles } from './DateRangeComponent.styles';
import { areDatesEqual, isNonEmptyObject } from 'utils/helpers.utils';

interface DateRangePickerComponentProps {
  className?: string;
  keyId: string;
  label?: string;
  placeholder?: string;
  value: any;
  isTextField: boolean;
  iconSrc?: string;
  autoUpdateInput?: boolean;
  locale?: any;
  maxSpan?: any,
  showDropdowns?: boolean,
  linkedCalendars?: boolean,
  autoApply?: boolean;
  fullWidth?: boolean;
  minDate?: any;
  updateDateRange: (dateRange: any) => void;
  defaultValue?: any
}

const DateRangePickerComponent = (props: DateRangePickerComponentProps) => {

  const classes = useStyles();

  const {
    keyId,
    label,
    placeholder,
    className,
    value,
    isTextField,
    iconSrc,
    autoUpdateInput,
    locale,
    maxSpan,
    showDropdowns,
    linkedCalendars,
    autoApply,
    fullWidth,
    minDate,
    updateDateRange,
    defaultValue,
  } = props;
  const [dateUpdated, setdateUpdated] = useState(false)
  
  const [initialSettings, setInitialSettings] = useState({
    autoUpdateInput: autoUpdateInput,
    startDate: value.startDate,
    endDate: value.endDate,
    locale: locale,
    maxSpan: maxSpan,
    showDropdowns: showDropdowns,
    linkedCalendars: linkedCalendars,
    autoApply: autoApply,
    minDate: minDate
  });

  const getDatePickerValue = useCallback(
    (picker: any) => {
      return `${picker.startDate.format(AppConstants.DATE_FORMAT_UI)} - ${picker.endDate.format(AppConstants.DATE_FORMAT_UI)}`;
    },
    [],
  );
  const [datePickerValue, setDatePickerValue] = useState(getDatePickerValue({
    startDate: moment(value.startDate, locale.format ? locale.format : AppConstants.DATE_FORMAT),
    endDate: moment(value.endDate, locale.format ? locale.format : AppConstants.DATE_FORMAT)
  }));

  const handleDateRangeChange = useCallback(
    (event: any, picker: any) => {
      updateDateRange({
        startDate: picker.startDate.format(locale.format ? locale.format : AppConstants.DATE_FORMAT),
        endDate: picker.endDate.format(locale.format ? locale.format : AppConstants.DATE_FORMAT)
      });
    },
    [locale, updateDateRange]
  )

  useEffect(() => {
    setInitialSettings(i => ({ ...i, startDate: value.startDate, endDate: value.endDate }));
    setDatePickerValue(getDatePickerValue({
      startDate: moment(value.startDate, locale.format ? locale.format : AppConstants.DATE_FORMAT),
      endDate: moment(value.endDate, locale.format ? locale.format : AppConstants.DATE_FORMAT)
    }));
  }, [value, locale, getDatePickerValue]);

  useEffect(() => {
    if(isNonEmptyObject(defaultValue) && isNonEmptyObject(value)){
    const  isStartDateChanged = !areDatesEqual(defaultValue?.startDate, value?.startDate)
    const  isEndDateChanged = !areDatesEqual(defaultValue?.endDate, value?.endDate);
    if(isStartDateChanged || isEndDateChanged) {
      setdateUpdated(true)
    }
    else{
      setdateUpdated(false)
    }
  }
  }, [value, defaultValue]);

  return (
    <>
      {
        isTextField ?
          <FormControl className={`${classes.formControl} ${label ? 'hasLabel' : ''}`} variant="outlined" fullWidth={fullWidth}>
            {label && <InputLabel htmlFor={keyId} shrink>{label}</InputLabel>}
            <DatePickerComponent
              id={keyId}
              initialSettings={initialSettings}
              renderInput={(params: any) => (
                <input
                  className="MuiOutlinedInput-root MuiOutlinedInput-input MuiOutlinedInput-notchedOutline"
                  type="text"
                  id={params.id}
                  readOnly
                  value={datePickerValue}
                  placeholder={placeholder || ''}
                />
              )}
              onChange={handleDateRangeChange}
            />
          </FormControl>
          :
          <DatePickerComponent
            id={keyId}
            initialSettings={initialSettings}
            renderInput={(params: any) => (
              
              <Badge classes={{ badge: classes.customBadge }} variant="dot" invisible={!dateUpdated}>
              <button
                className={`${classes.datePickerIcon} ${className}`}
                id={params.id}
              >
                <img src={iconSrc || filterIcon} alt="React Datepicker Logo" />
              </button>
              </Badge>
            )}
            onChange={handleDateRangeChange}
          />
      }
    </>
  )
}

export default DateRangePickerComponent;