import React, { forwardRef, ReactElement, Ref, useCallback, useEffect } from 'react';
import * as yup from 'yup';
import CustomDialog from '../common/Dialog';
import { useStyles } from './ResetPwd.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography } from '@material-ui/core';
import AppConstants from '../constants/index';
import TextBox from '../common/TextBox';
import { useFormik } from 'formik';

interface ResetPwdProps {
  open: boolean;
  user: any;
  closePopup: () => void;
  handleDialogAction: (params: any) => void;
}

const inputValidationSchema = yup.object({
  password: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg),
  confirmPassword: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg)
    .oneOf([yup.ref('password')], AppConstants.VALIDATIONS.PASSWORD.ONE_OF.msg)
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ResetPwd = (props: ResetPwdProps) => {

  const classes = useStyles();
  const { open, user, closePopup, handleDialogAction } = props;
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const getInitialValues = () => {
    return {
      password: '',
      confirmPassword: ''
    }
  }
  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: inputValidationSchema,
    onSubmit: (values) => {
      dialogActionHandler('submit', values);
    }
  });

  const handleDialogClose = () => {
    closePopup();
  }

  const dialogActionHandler = useCallback(
    (type: string, values?: any) => {
      const params: any = {
        type: type,
        values: { ...values }
      }
      if (user) {
        params.values = { ...params.values, username: user.username };
      }
      handleDialogAction && handleDialogAction(params);
    },
    [handleDialogAction, user],
  );

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className="titleHeading">
                    RESET PASSWORD
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }

  const getDetailsContent = () => {
    return (
      <form onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.contentContainer} container>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Password</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.password}
                  error={inputFormik.touched.password && Boolean(inputFormik.errors.password)}
                  helperText={inputFormik.touched.password && inputFormik.errors.password}
                  textBoxId="ResetPwdPasswordTextbox"
                  name="password"
                  type="password"
                  placeholderText="Password"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Confirm Password</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  onChange={inputFormik.handleChange}
                  value={inputFormik.values.confirmPassword}
                  error={inputFormik.touched.confirmPassword && Boolean(inputFormik.errors.confirmPassword)}
                  helperText={inputFormik.touched.confirmPassword && inputFormik.errors.confirmPassword}
                  textBoxId="ResetPwdConfirmPasswordTextbox"
                  name="confirmPassword"
                  type="password"
                  placeholderText="Confirm Password"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  const getDialogActions = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className="buttonsContainer" container>
          <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
          <Button className="userBtn primary" variant="contained" type="submit">{AppConstants.BUTTONS.CONFIRM}</Button>
        </Grid>
      </form>
    );
  };

  useEffect(() => {
    if (open) {
      inputFormik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      content={getDetailsContent()}
      actions={getDialogActions()}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default ResetPwd;