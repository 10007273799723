import moment from "moment";

export function newDate() {
    return new Date();
}

/**
 *@param {String} format - format string
 */
export function currentDate(format: string) {
    return moment(newDate()).format(format);
}

/**
 * @param {String} date - date string
 *@param {String} format - format string
 */
export function dateWithReqFormat(date: string, format: string) {
    return moment(date).hours(0).minutes(0).seconds(0).format(format);
}

/**
 *@param {String} date - date string
 *@param {String} currFormat - currFormat string
 *@param {String} reqFormat - reqFormat string
 */
export function startOfDay(date: string, currFormat: string, reqFormat: string) {
    return moment(date, currFormat).hours(0).minutes(0).seconds(0).format(reqFormat);
}

/**
 *@param {String} date - date string
 *@param {String} currFormat - currFormat string
 *@param {String} reqFormat - reqFormat string
 */
export function endOfDay(date: string, currFormat: string, reqFormat: string) {
    return moment(date, currFormat).hours(23).minutes(59).seconds(59).format(reqFormat);
}

/**
 *@param {String} hour - hour value string
 *@param {String} minutes - minutes value string
 *@param {String} seconds - seconds value string
 *@param {String} reqFormat - reqFormat string
 */
export function getTimeByHourMinutesAndSecond(hour: any, minutes: any, seconds: any, reqFormat: string) {
    return moment().hours(hour).minutes(minutes).seconds(seconds).format(reqFormat)
}

export function getTimeByHourAndMinutes(hour: any, minutes: any, reqFormat: string) {
  return moment().hours(hour).minutes(minutes).format(reqFormat);
}

export function isDateValid(date: any) {
    return moment(date).isValid();
}

export function isEndDateBeforeStartDate(start: any, end : any){
    return moment(end).isBefore(moment(start))
}

export function getDateOrTimeInReqFormat(date:any, reqFormat:string){
    return moment(date).format(reqFormat);
}

export function getDateFromFormatInReqFormat(date:any, currFormat: string, reqFormat:string){
    return moment(date, currFormat).format(reqFormat);
}