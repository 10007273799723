import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            height: "100%",
            '& .panelItem': {
                width: "100%",
                '&.gridWrapper': {
                    height: "calc(100% - 230px)",
                    paddingTop: "8px",
                    '& .idLink': {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        fontSize: 12
                    },
                    '& .actionsLink': {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        fontSize: 14
                    }
                }
            }
        },
        container: {
            display: "flex",
            justifyContent: "flex-start"
        },
        dialogPaperPropsRoot: {
            minWidth: "95vw",
            height: "95vh",
            margin: "auto",
            borderRadius: theme.spacing(1),
        },
        hubSelectionDialogPaperPropsRoot: {
            width: 500,
            height: 300,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        collectionConfirmationDialogPaperPropsRoot: {
            width: 500,
            height: 350,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        hubSelectionDialogTitle: {
            fontSize: 20,
            fontWeight: 600
        },
        hubSelectionDialogContentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .contentItem': {
                '&.heading': {
                    '& p': {
                        fontSize: 14,
                        fontWeight: 400
                    }
                },
                '&.hub': {
                    paddingTop: theme.spacing(3.75)
                }
            }
        },
        hubSelectionDialogButtonsContainer: {
            justifyContent: "flex-end",
            paddingBottom: theme.spacing(7),
            '& .dialogBtn': {
                paddingLeft: theme.spacing(4.5),
                paddingRight: theme.spacing(4.5),
                '&.secondary': {
                    marginRight: theme.spacing(1)
                },
                '&.primary': {
                    marginLeft: theme.spacing(1)
                }
            }
        },
        tilesView: {
            flexGrow: 1,
        },
        title: {
            fontSize: "24px",
            fontWeight: 600
        },
        subHeadingBold:{
            fontWeight: "600!important" as any,
        },
        bucketHeader:{
            flexWrap: "nowrap",
            paddingTop: "30px"
        },
        icon: {
            "& .MuiOutlinedInput-input": {
                height: "40px",
            },
        },
        select: {
            borderRadius: 8,
            background: COLORS.WHITE,
            minWidth: 150
        },
        button: {
            margin: theme.spacing(0.5),
            '&:last-child': {
                marginRight: theme.spacing(0)
            }
        },
        buttonContainer: {
            marginLeft: "auto",
        },
        gridWrapper: {
            width: "100%",
            paddingTop: "20px",
            '& .idLink': {
                cursor: "pointer",
                color: theme.palette.primary.main,
                fontWeight: 600,
                fontSize: 12
            },
            '& .actionsLink': {
                cursor: "pointer",
                color: theme.palette.primary.main,
                fontWeight: 600,
                fontSize: 14,
                '& .statusIcon': {
                fill: "none",
                height: 'unset',
                width: 'unset'
                }, 
            }
        },
        statusHeaderContainer: {
            '& .tag': {
                color: COLORS.WHITE,
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(0.5, 1),
                lineHeight: "14px",
                backgroundColor: COLORS.BLACK,
                fontWeight: 600,
                textTransform: "uppercase",
                fontSize: 12,
                '&.INFO_BLUE': {
                    backgroundColor: COLORS.INFO_BLUE
                },
                '&.READY_BLUE': {
                    backgroundColor: COLORS.READY_BLUE
                },
                '&.MINSK': {
                    backgroundColor: COLORS.MINSK
                },
                '&.PRIMARY_BLUE': {
                    backgroundColor: COLORS.PRIMARY_MAIN
                },
                '&.JUNGLE_GREEN': {
                    backgroundColor: COLORS.JUNGLE_GREEN
                },
                '&.PARTIAL_SUCCESS_GREEN': {
                    backgroundColor: COLORS.PARTIAL_SUCCESS_GREEN
                },
                '&.ERROR_RED': {
                    backgroundColor: COLORS.PALE_RED
                },
                '&.PALE_GRAY': {
                    backgroundColor: COLORS.PALE_GRAY
                },
                '&.ALERT_ORANGE': {
                    backgroundColor: COLORS.ALERT_ORANGE
                },
            },
        },
        confirmationContent: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .itemContainer': {
                flexDirection: "column",
                '&.area': {
                    paddingTop: "24px"
                },
                '& .itemLabel': {
                    paddingBottom: theme.spacing(1),
                    alignSelf: "flex-start",
                    '& p': {
                        fontSize: 14,
                        fontWeight: 400
                    }
                },
                '& .itemInput': {
                    '& .MuiOutlinedInput-input': {
                        fontSize: 14,
                        fontWeight: 400
                    }
                }
            },
        },
        confimationDialogButtonsContainer: {
            justifyContent: "flex-end",
            '& .dialogBtn': {
                paddingLeft: theme.spacing(4.5),
                paddingRight: theme.spacing(4.5),
                '&.secondary': {
                    marginRight: theme.spacing(1)
                },
                '&.primary': {
                    marginLeft: theme.spacing(1)
                }
            }
        },
        checkedIconStyle: {
            width: 33,
            height: 33,
            padding: "6px 0px 0px 16px"
        },
        close: {
            color: COLORS.WHITE
        },
        closeIcon: {
            width: 16,
            height: 16
        },
        excelDownloadError: {
            background: COLORS.LIGHT_RED,
            borderRadius: "8px 8px 0px 0px",
            position: "fixed",
            bottom: "23px",
            height: 48,
            width: 336,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        excelDownloadStatus: {
            background: COLORS.INFO_BLACK,
            borderRadius: "8px 8px 0px 0px",
            position: "fixed",
            bottom: "12px",
            height: 48,
            width: 336,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        excelDownloadStatusText: {
            color: COLORS.WHITE,
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 500,
            fontFamily: "Montserrat"
        },
        contentItem: {
            paddingTop: theme.spacing(3),
            '& .itemContainer': {
                flexDirection: "column",
                '& .itemLabel': {
                    paddingBottom: theme.spacing(1),
                    alignSelf: "flex-start",
                    '& p': {
                        fontSize: 14,
                        fontWeight: 400
                    }
                },
                '& .itemInput': {
                    '& .MuiOutlinedInput-input': {
                        fontSize: 14,
                        fontWeight: 400
                    },
                    '& .userIdContainer .input': {
                        flexGrow: 1,
                        paddingRight: theme.spacing(2)
                    }
                }
            },
        },
        uploadContainer: {
            '& .buttonsContainer': {
                '& .userBtn': {
                    '&.secondary': {
                        marginRight: theme.spacing(2)
                    },
                    flexGrow: 1
                }
            }
        },
    }),
);