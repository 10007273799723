import React, { useMemo } from "react";
import { Grid, Typography, SvgIcon, Box } from "@material-ui/core";
import { fixDecimals } from "utils/helpers.utils";
import { ReactComponent as stack } from "../assets/icons/Stack.svg";
import { useStyles } from './Bundleproduct.styles';
import SelectBox from "../common/SelectBox";

interface BundleProductsProps {
  bundle: {
    campaignId: string;
    bundleName: string;
    bundleShippedQty: number;
    bundleProductQuantity: number;
    bundlePrice: number;
    bundleCurrency: string;
    bundleRejectedQty: number;
    rejectedQtyList?: any;
    selectedRejectedQty?: any;
    rejectedQty?: any;
    showReason?: any;
    itemDeliveryReason?: any;
    error?: any;
  };
  bundleCurrency: string;
  editMode?: boolean;
  rejectionReasonList?: any;
  rejectBundle: (
    params: any,
    param1: any,
    param3?: boolean
  ) => void;
}
const BundleProductsHeaderEdit = (props: BundleProductsProps) => {
  const classes = useStyles();
  const {
    bundle,
    bundle: {
      campaignId,
      bundleName,
      bundleShippedQty,
      bundlePrice,
      bundleRejectedQty,
      bundleProductQuantity,
      rejectedQtyList,
      selectedRejectedQty,
    },
    bundleCurrency,
    rejectBundle,
  } = props;


  const displayedOptions = useMemo(() => {
    return [
      { key: "name", value: bundleName, width: 4, class: "item1" },
      {
        key: "rejectedQty",
        value: bundleRejectedQty || '-',
        width: 2,
        class: "item2",
      },
      { key: "shippedQty", value: bundleShippedQty || 0, width: 2, class: "item2" },
      {
        key: "quantity",
        value: bundleProductQuantity,
        width: 2,
        class: "item2",
      },
      { key: "price", value: bundlePrice, width: 2, class: "item3" },
    ];
  }, [
    bundleName,
    bundleRejectedQty,
    bundleShippedQty,
    bundleProductQuantity,
    bundlePrice,
  ]);

  const getItem = (item: any) => {
    switch (item.key) {
      case "price":
        return (
          <Typography className="itemsContent">{`${fixDecimals(2, item.value.toString())} ${bundleCurrency}`}</Typography>
        );
      case "name":
        return (
          <Typography><Box className={classes.itemHeader}>
            <SvgIcon className="icon" component={stack} />
            <Typography className="itemsContent">{item.value}</Typography>
          </Box> </Typography>
        );
      case "rejectedQty":
        return (<Typography className="itemsContent">
          <SelectBox
            className="select"
            value={selectedRejectedQty[0].value}
            id="rejectedQty"
            inputProps={{
              name: "rejectedQty",
              id: "rejectedQty",
            }}
            items={rejectedQtyList || []}
            handleChange={(e) =>rejectBundle(e, campaignId, true)
            }
          />
          </Typography>
        );
      case "shippedQty" : 
         return  <Typography className="itemsContent"><span className="green">{item.value}</span></Typography>;
      default:
        return  <Typography className="itemsContent"><span>{item.value}</span></Typography>
    }
  };

  const getItemsDetail = () => {
    return (
      <Grid className={classes.bundleItemsContainer} container>
        {displayedOptions?.length &&
          displayedOptions.map((item: any, index: any) => (
            <>
              <Grid
                className={`itemColumn ${item.class} ${item.key}`}
                item
                key={index}
                xs={item.width}
              >
                  {getItem(item)}
              </Grid>
            </>
          ))}
        
      </Grid>
    );
  };

  return <>{getItemsDetail()}</>;
};

export default BundleProductsHeaderEdit;
