import AppConstants from "../../constants";

export interface Elements {
    id: string;
    name: string;
    hubStatus: string;
    displayName: string;
    deliveryType: any;
    category: any;
    contactInfo: Contact;
    serviceTime: any;
}

export interface Contact {
    name: string;
    phone: string;
    email: string;
    address: Address;
}

export interface Address {
    area: string;
    region: string;
    countryName: string;
    countryIsoCode: string;
    longitude: string;
    latitude: string;
}

export interface HubDataResponse {
    pageNumber: number;
    totalElements: number;
    totalPages: number;
    elements: Elements[];
}

const lastMileHubDataTransform = (response: HubDataResponse, countryCode: string): any[] => {
    const transformedArray: any[] = [];
    response.elements.forEach((elements: Elements) => {
        const {
            id,
            name,
            hubStatus,
            displayName,
            deliveryType,
            category,
            contactInfo,
        } = elements;
        // TODO: map/add actual objects too for drilling down content or store in state
        transformedArray.push({
            hubId: id,
            hubName: name,
            latLong: `${contactInfo.address.latitude}, ${contactInfo.address.longitude}`,
            serviceType: deliveryType,
            area: contactInfo.address.area,
            hubStatus: hubStatus,
            category: category,
            displayName: displayName,
            latitude: contactInfo.address.latitude,
            longitude: contactInfo.address.longitude,
            status: hubStatus,
            country: contactInfo.address.countryName
        });

    });
    return [...transformedArray];
};

export { lastMileHubDataTransform };

export const createHubDetailsPayload = (payload: any, data?: any, isLoadMore?: boolean) => {
    payload.attributes = [];
    if (payload) {
        payload.attributes.push({
            key: payload.fieldName,
            value: payload.value
        })
    }
    if (isLoadMore) {
        payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
    }
    if (data.continuationToken && payload.pageNumber) {
        payload.continuationToken = data.continuationToken
    }
    return {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        attributes: payload.attributes,
        pageNumber: payload.pageNumber,
        requestContinuationToken: payload.continuationToken
    };
};

export const createAddHubPayload = (payload: any) => {
    if (payload.hubId) {
        payload.id = payload.hubId
    }
    payload.contactInfo = {};
    payload.contactInfo.address = {};
    if (payload.latitude) {
        payload.contactInfo.address.latitude = payload.latitude;
    }
    if (payload.longitude) {
        payload.contactInfo.address.longitude = payload.longitude;
    }
    if (payload.country) {
        payload.contactInfo.address.countryIsoCode = payload.country;
    }
    if (payload.address) {
        payload.contactInfo.address.area = payload.address;
    }
    return {
        id: payload.hubId,
        name: payload.hubName,
        hubStatus: payload.status,
        deliveryType: [payload.serviceType],
        category: [payload.category],
        contactInfo: payload.contactInfo,
        pageSize: AppConstants.HUB_CONSTANTS.TABLE_WRAPPER_DATA.loadMore.pageSize,
        pageNumber: payload.pageNumber
    };
}

export const createEditHubPayload = (payload: any) => {
    if (payload.hubId) {
        payload.id = payload.hubId
    }
    payload.contactInfo = {};
    payload.contactInfo.address = {};
    if (payload.latitude) {
        payload.contactInfo.address.latitude = payload.latitude;
    }
    if (payload.longitude) {
        payload.contactInfo.address.longitude = payload.longitude;
    }
    if (payload.country) {
        payload.contactInfo.address.countryIsoCode = payload.country;
    }
    if (payload.address) {
        payload.contactInfo.address.area = payload.address;
    }
    return {
        id: payload.hubId,
        name: payload.hubName,
        hubStatus: payload.status,
        deliveryType: [payload.serviceType],
        category: [payload.category],
        contactInfo: payload.contactInfo,
        pageSize: AppConstants.HUB_CONSTANTS.TABLE_WRAPPER_DATA.loadMore.pageSize,
        pageNumber: payload.pageNumber
    };
}