import React, { useCallback, useState } from "react";

//common components
import AppConstants from "../constants";

//Material UI Components
import { Grid, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";

//component
import Consignments from "./Consignments/index";
import History from "./History/index";

interface ExpressProps {
  match?: any;
  location?: any;
}

const CustomerCommunication = (props: ExpressProps) => {

  const [tabValue, setTabValue] = useState(
    AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES
      .CONSIGNMENTS
  );

  const [orderType, setOrderType] = useState("");

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      setTabValue(value);
      setOrderType("")
    },
    []
  );

  const changeTabAfterSuccess = useCallback((orderType) => {
    setTabValue(
      AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES
        .COMM_HISTORY
    );
  }, []);

  const handleTypeChange = useCallback(
    (value: string) => {
      setOrderType(value)
    },
    []
  );

  return (
    <>
      <TabContext value={tabValue}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#1F1F1F" } }}
          value={tabValue}
          aria-label="Consignments/Routes Navigation Tabs"
          style={{
            paddingLeft: "20px",
            paddingTop: "8px",
            lineHeight: "24px",
            minHeight: 0,
            backgroundColor: "rgba(244, 244, 244, 0.6)",
            paddingBottom: "16px",
          }}
          onChange={handleTabChange}
        >
          <Tab
            label="Consignments"
            style={{ fontSize: "16px", fontWeight: 600 }}
            value={
              AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES
                .CONSIGNMENTS
            }
          />
          <Tab
            label="Communication History"
            style={{ fontSize: "16px", fontWeight: 600 }}
            value={
              AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES
                .COMM_HISTORY
            }
          />
        </Tabs>
        <TabPanel
          className="tabPanel"
          value={
            AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES
              .CONSIGNMENTS
          }
        >
          <Consignments changeTabAfterSuccess={changeTabAfterSuccess} handleTypeChange={handleTypeChange} />
        </TabPanel>
        <TabPanel
          className="tabPanel"
          value={
            AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES
              .COMM_HISTORY
          }
        >
          <Grid item >
            <History orderType={orderType} />
          </Grid>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default CustomerCommunication;
