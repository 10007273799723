//  REF: https://github.com/nhn/tui.code-snippet/tree/v2.0.0
import { saveAs } from 'file-saver'
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import AppConstants from '../constants';
import cloneDeep from "lodash/cloneDeep";
import { UnitOfMeasure } from "../constants/CommonConstants";

/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Check whether the given variable is undefined or not.
 * If the given variable is undefined, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is undefined?
 */
export const isUndefined = (target: any): boolean => target === undefined;

/**
 * Check whether the given variable is null or not.
 * If the given variable(arguments[0]) is null, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is null?
 */
export const isNull = (target: any): boolean =>
  target === null || target === "";

/**
 * Check whether the given variable is existing or not.
 * If the given variable is not null and not undefined, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is existy?
 */
export const isExisty = (target: any): boolean =>
  !isUndefined(target) && !isNull(target);

/**
 * Check whether the given variable is a function or not.
 * If the given variable is a function, return true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is function?
 * @memberof module:type
 */
export const isFunction = (target: any): boolean => target instanceof Function;

/**
 * Check whether the given variable is a number or not.
 * If the given variable is a number, return true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is number?
 */
export const isNumber = (target: any): boolean =>
  typeof target === "number" || target instanceof Number;

/**
 * Check whether the given variable is an object or not.
 * If the given variable is an object, return true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is object?
 */
export const isObject = (target: any) => target === Object(target);

/**
 * Check whether the given variable is a valid non-empty Array
 * @param {*} target - Target for checking
 * @returns {boolean} Is non-empty Array?
 */
export const isNonEmptyArray = (target: any[]): boolean =>
  !!target && target.length > 0;

/**
 * Convert the given variable into decimal point
 * @param {*} target - Target for checking
 * @param {number} decimalPoint - Decimal point
 * @returns {string | number}
 */
export const setDecimalPoint = (
  target: any,
  decimalPoint = 2
): string | number =>
  isNumber(target) ? target.toFixed(decimalPoint) : target;

/**
 * Round up/down to at most 2 decimal places
 * @param {*} target - Target for checking
 * @returns {string | number}
 * REF: https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
 * REF: https://stackoverflow.com/questions/41259253/how-to-round-down-number-2-decimal-places
 */
export const roundDecimalNumber = (
  target: any,
  isRoundUp = true
): string | number =>
  isNumber(target)
    ? Math[isRoundUp ? "round" : "floor"]((target + 0.00001) * 100) / 100
    : target;

/**
 * Return the correct Noun based on the given "target" variable
 * @param {number} target - Target for checking
 * @param {string} singularNoun - Will return if "target" is only one
 * @param {string} pluralNoun - Will return if "target" is more than one
 * @returns {string}
 */
export const getProperNoun = (
  target: number,
  singularNoun: string,
  pluralNoun: string
): string => (target < 2 ? singularNoun : pluralNoun);

/**
 * Return the length of enumerable properties of the given object
 * @param {object} target - Target for checkout
 */
export const countObjectProperties = (target: object) =>
  Object.keys(target).length;

/**
 * Return the key of the given object
 * @param {*} target - Target for checking
 */
export const getObjectKeys = (target: object) =>
  Object.keys(target);

/**
 * Return the non empty target
 * @param {object} target - Target for checking
 */
export const isArrayNonEmpty = (target: any) =>
  target.length !== 0;

/**
 * Taken from packages/checkout/src/pages/Checkout/components/Delivery/NewAddress/redux/NewAddress.action.ts
 * @param {object} params
 */
export const qs = (params: object) => {
  return Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join("&");
};

/**
 * Check whether the given variable is a valid non-empty Object
 * @param {*} target - Target for checking
 * @returns {boolean} Is non-empty Object?
 */
export const isNonEmptyObject = (target: any): boolean =>
  target && countObjectProperties(target) > 0;


export const deepCopy = (value: any): any => cloneDeep(value);

export const parseQuery = (queryString: string) => {
  if (!queryString) {
    return {};
  }
  const query: any = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (const item of pairs) {
    const pair = item.split("=");
    query[
      (window as any).decodeURIComponent(pair[0])
    ] = (window as any).decodeURIComponent(pair[1] || "");
  }
  return query;
};

/**
 * Check whether the given variable is a String
 * @param {*} candidate - Target for checking
 * @returns {boolean} Is a string
 */
export const isAString = (candidate: any) =>
  typeof candidate === "string" || candidate instanceof String;

export const saveFile = (fileBlob: Blob, fileName: string) => {
  if (fileBlob && fileName) {
    saveAs(fileBlob, fileName);
  }
}

export const transformSelectValues = (values: any, nameKey?: string) => {
  let newValues = [];
  if (values && values.length) {
    newValues = values.map((item: any) => {
      if (item && Object.keys(item).length) {
        return {
          name: nameKey ? item[nameKey] : item.value,
          value: item.code
        }
      }
      return false;
    });
  }
  return newValues.filter((item: any) => item);
}

export const transformSelectValuesBasedOnKey = (values: any, firstKey?: string,secondKey?: string) => {
  let newValues = [];
  if (values && values.length) {
    newValues = values.map((item: any) => {
      if (item && Object.keys(item).length) {
        return {
          name: firstKey ? item[firstKey] : item.value,
          value: secondKey ? item[secondKey] : item.value
        }
      }
      return false;
    });
  }
  return newValues.filter((item: any) => item);
}

export const transformIntoObjArrays = (value: any, qty: any) => {
  let obj = [];
  for (let value = 0; value <= qty; value++) {
    obj.push({
      name: value.toString(),
      value: value.toString()
    })
  }
  return obj;
}

export const transformListOrObjIntoKeyValue = (value: any, nameKey?: string) => {
  let result;
  if (value.constructor.name == "Array") {
    let obj = [];
    for (let item = 0; item < value.length; item++) {
      obj.push({
        name: value[item].toString(),
        value: value[item].toString()
      })
    }
    result = obj
}
else{
  if(nameKey){
    result = transformSelectValues(value,nameKey)
  }
  else{
    result = transformSelectValues(value)
  }
}
 
  return result;
}

export const transformForScalable = (val: any, qty: any) => {
  let obj = [];
  for (let value = 0; value <= qty; value++) {
    if(value==qty && val && Number(val)){
      obj.push({
        name:`${val?.toString()} ${'g'}`,
        value: value?.toString()
      })
    }
    else{
      obj.push({
        name: value?.toString(),
        value: value?.toString()
      })
    }
  }
  return obj;
}


export const transformIntoObj = (value: any) => {
  let obj = [];
  obj.push({
    name: value.toString(),
    value: value.toString()
  })
  return obj;
}

export const displayUnitGrams = (value: any) => {
  return value?.toLowerCase() === "grams" && 'g';
}


export const transformProductsResponse = (values: any) => {
  let qty;
  let prodRes: any = [];
  let finalResp = values.forEach((item: any) => {
    qty = isNumeric(item.shippedQty) ? item.shippedQty : item.quantity;
    let isSubstitution = item.hasOwnProperty('substitutedProducts');
    let isScalable = item.uom?.toLowerCase() === 'grams';
    let totalQty = isScalable ? 1 : isSubstitution ? item.substitutedProducts[0].shippedQty : +qty;
    const rejectedItems = isSubstitution
      ? +item.substitutedProducts[0].rejectedQty || 0
      : +item.rejectedQty || 0;
    const initialQty = isScalable ? rejectedItems > 0 ? 1 : 0 : rejectedItems;
    let newObj = [];
    let selectRejected = [];
    if (initialQty === 0) {
      selectRejected.push({
        name: initialQty.toString(),
        value: initialQty.toString()
      })
    } else {
      selectRejected.push({
        name: initialQty.toString(),
        value: initialQty.toString()
      })
    }
    for (let i = 0; i <= totalQty; i++) {
      newObj.push({
        name: i.toString(),
        value: i.toString()
      })
    };
    let deliveryReason = [];
    if (item.reason) {
      deliveryReason.push({
        name: item.reason.reasonDescription,
        value: item.reason.reasonCode
      });
    } else {
      if (item.substitutedProducts && item.substitutedProducts.length) {
        item.substitutedProducts.forEach((subProd: any) => {
          if (subProd.reason) {
            deliveryReason.push({
              name: subProd.reason.reasonDescription,
              value: subProd.reason.reasonCode
            });
          }
        })
      }
    }
    let newResp = {
      ...item, totalQty: totalQty, rejectedQtyList: newObj, selectedRejectedQty: selectRejected, itemDeliveryReason: deliveryReason
    }
    prodRes.push(newResp);
  });
  return prodRes;
}

export const transformDateWithEmptyValues = (keys: any, state: any, allDates: any = []) => {
  let result: any = [];
  let emptyRes = keys.reduce((acc: any, item: any) => ({ ...acc, [item.value]: 0 }), {});

  if (isNonEmptyObject(state)) {
    const newObj = allDates.reduce((acc: any, item: any) => {
      let responseObj = {}
      if (state[item]) {
        responseObj = { ...state[item] };
      } else {
        responseObj = { ...emptyRes };
      }
      return { ...acc, [item]: { ...responseObj } }
    }, {})
    keys.forEach((key: any) => {
      let obj: any = {};

      if (newObj) {
        Object.keys(newObj).forEach((day) => {
          if (obj[key.value]) {
            obj[key.value] = {
              ...obj[key.value],
              data: [...obj[key.value].data, (newObj[day][key.value] || 0)],
            };
          } else {
            obj[key.value] = { name: key.label, data: [newObj[day][key.value] || 0], type: key.type };
          }
        });
      }
      result.push(obj[key.value]);
    });
  }
  return result;
};

export const transformDateValues = (keys: any, state: any) => {
  let result: any = [];

  if (isNonEmptyObject(state)) {

    keys.forEach((key: any) => {
      let obj: any = {};

      if (state) {
        Object.keys(state).forEach((day) => {
          if (obj[key.value]) {
            obj[key.value] = {
              ...obj[key.value],
              data: [...obj[key.value].data, (state[day][key.value] || 0)],
            };
          } else {
            obj[key.value] = { name: key.label, data: [state[day][key.value] || 0], type: key.type };
          }
        });
      }
      result.push(obj[key.value]);
    });
  }
  return result;
};

export const transformDates = (state: any) => {
  let filteredValues: any = [];
  if (isNonEmptyObject(state)) {
    state.forEach((date: any) => {
      filteredValues.push(moment(date).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_DAY_MONTH));
    })
    return filteredValues;
  }
}
export const transformSlotValues = (keys: any, state: any) => {
  let result: any = [];
  if (isNonEmptyObject(state)) {
    keys.forEach((key: any) => {
      let obj: any = {};
      if (state) {
        Object.keys(state).forEach((day) => {
          if (obj[key.value]) {
            obj[key.value] = {
              ...obj[key.value],
              data: [...obj[key.value].data, state[day][key.value]],
            };
          } else {
            obj[key.value] = { name: key.label, data: [state[day][key.value]] };
          }
        });
      }
      result.push(obj[key.value]);
    });
  }
  return result;
};

export const transformPerformanceResponse = (state: any) => {
  if (isNonEmptyObject(state)) {
    const newResponse = Object.keys(state).reduce((acc, item) => {
      let obj = state[item];
      let newSlotWis = { ...obj, ...obj.elementsStatesCount }
      return { ...acc, [item]: { ...newSlotWis } }

    }, {})
    return newResponse;
  }
};

export const transformVolumeResponse = (state: any) => {
  if (isNonEmptyObject(state)) {
    const newResponse = Object.keys(state).reduce((acc, item) => {
      let obj = state[item];
      let newSlotWis = { ...obj, ...obj.statusWise }
      return { ...acc, [item]: { ...newSlotWis } }

    }, {})
    return newResponse;
  }
};

export const transformSlots = (state: any) => {
  let filteredSlots: any = [];
  if (isNonEmptyObject(state)) {
    Object.keys(state).forEach((slot) => {
      const splitSlot = slot.split('-');
      const startSlot: any = splitSlot[0] && splitSlot[0].split(':');
      const endSlot: any = splitSlot[1] && splitSlot[1].split(':');
      const startTime: any = moment(slot, AppConstants.SLOT_FORMAT_BACKEND).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.SLOT_FORMAT_UI);
      const endTime: any = moment(slot, AppConstants.SLOT_FORMAT_BACKEND).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.SLOT_FORMAT_UI);
      const formattedSlots = startTime + "-" + endTime;
      filteredSlots.push(formattedSlots);
    })
  }
  return filteredSlots;
}

export const transformSlotValue = (slot: any) => {
  const splitSlot = slot.split('-');
  const startSlot: any = splitSlot[0] && splitSlot[0].split(':');
  const endSlot: any = splitSlot[1] && splitSlot[1].split(':');
  const startTime: any = moment(slot, AppConstants.SLOT_FORMAT_BACKEND).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.SLOT_FORMAT_UI);
  const endTime: any = moment(slot, AppConstants.SLOT_FORMAT_BACKEND).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.SLOT_FORMAT_UI);
  const formattedSlots = startTime + "-" + endTime;
  return formattedSlots;
}

export const totalValues = (state: any) => {
  let sum: any = "";
  if (isNonEmptyObject(state)) {
    sum = Object.values(state).reduce((a: any, b: any) => a + b, 0);
  };
  return sum;
}

export const transformIntoArray = (state: any) => {
  if (isNonEmptyObject(state)) {
    let result: any = [];
    state.map((val: any) => {
      return result.push(val.value);
    })
  }
  return [];
}

export const transformDataIntoArray = (keys: any, state: any) => {
  if (isNonEmptyObject(state)) {
    let result: any = [];
    keys.forEach((key: any) => {
      result.push(state[key] || 0);
    });
    return result;
  }
  return [];
}

export const localTimeString = (date: Date) => {
  return date.toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const localTimeStringNEw = (date: Date) => {
  return date.toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
};

export const calculateTimeBetweenSlots = (startSlot: any, endSlot: any) => {
  const startTime = startSlot.split(':');
  const endTime = endSlot.split(':');
  let startTimeInMins = Number(Number(startTime[0]) * 60) + Number(startTime[1]);
  let endTimeInMins = Number(Number(endTime[0]) * 60) + Number(endTime[1]);
  let differenceInMins = Number(endTimeInMins) - Number(startTimeInMins);
  return differenceInMins;
}

export const isEqualArrays = (a: any[], b: any[], key?: string) => {
  return _.isEqual(_.sortBy(a, key || []), _.sortBy(b, key || []));
}

export const getDifferenceValues = (a: any[], b: any[], isObject: boolean = true, key: string = 'value') => {
  let difference = [];
  if (isObject) {
    difference = a.filter((aItem: any) => b.filter((bItem: any) => bItem[key] === aItem[key]).length > 0 ? false : true);
  } else {
    difference = a.filter((aItem: any) => b.filter((bItem: any) => bItem === aItem).length > 0 ? false : true);
  }
  return difference;
}

export const getMatchingValues = (a: any[], b: any[], isObject: boolean = true, key: string = 'value') => {
  let matches = [];
  if (isObject) {
    matches = a.filter((aItem: any) => b.filter((bItem: any) => bItem[key] === aItem[key]).length > 0);
  } else {
    matches = a.filter((aItem: any) => b.filter((bItem: any) => bItem === aItem).length > 0);
  }
  return matches;
}

export const enumerateDaysBetweenDates = (startDate: string, endDate: string, dateFormat: string = AppConstants.DATE_FORMAT) => {
  let dates = [];
  const currDate = moment(startDate, dateFormat);
  const lastDate = moment(endDate, dateFormat);
  while (currDate.isSameOrBefore(lastDate)) {
    dates.push(currDate.format(dateFormat));
    currDate.add(1, 'days');
  }
  return dates;
};

export const getUniqueValues = (array: any[], key?: string) => {
  return Array.from(new Set(key ? array.map((item: any) => item[key]).filter((item: any) => item !== undefined) : array));
}

export const getUniqueObjValues = (array: any[], key: string) => {
  return array.filter((item: any, index: number, self: any[]) => {
    return self.findIndex((selfItem: any) => selfItem[key] === item[key]) === index;
  });
}

export const highlightTextWithClass = (queryStr: string, resultStr: string) => {
  const regex = new RegExp(queryStr, 'gi');
  return resultStr.replace(regex, (res: string) => {
    return `<span class='highlightText'>${res}</span>`;
  });
}

export const getSlotUIValue = (slot: any[]) => {
  const startTime = slot.length && moment(slot[0], AppConstants.SLOT_FORMAT_BACKEND).format(AppConstants.SLOT_FORMAT_UI);
  const endTime = slot.length && moment(slot[1], AppConstants.SLOT_FORMAT_BACKEND).format(AppConstants.SLOT_FORMAT_UI);
  return {
    startTime: startTime,
    endTime: endTime
  }
}

export const useOutsideClickHandler = (ref: any, handler: any, handlerParams?: any) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event, handlerParams);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler, handlerParams]);
}

export const useClickHandler = (ref: any, handler: any, handlerParams?: any) => {
  useEffect(() => {
    function handleClick(event: any) {
      if (ref.current && ref.current.contains(event.target)) {
        handler(event, handlerParams);
      }
    }

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, handler, handlerParams]);
}

export const getValueFromArray = (array: any[], key: string, match: any, matchKey?: string) => {
  return array.filter((item: any) => (matchKey ? item[key] === match[matchKey] : item[key] === match));
}

export const convertTime = (seconds: number, showSecs?: boolean) => {
  const hrs = ~~(seconds / 3600);
  let mins = ~~((seconds % 3600) / 60);
  if (~~((seconds % 3600) % 60) > 0) {
    mins = mins + 1;
  }
  const secs = ~~seconds % 60;
  let ret = "";
  if (hrs > 0) {
    ret += "" + hrs + " Hrs " + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + " Mins ";
  if (showSecs) {
    ret += "" + (secs < 10 ? "0" : "") + secs + " Secs";
  }
  return ret;
}

export const fixDecimals = (n: number, value: string) => {
  return value && value.indexOf('.') > -1 && !isNaN(parseInt(value)) ? parseFloat(value).toFixed(n) : value;
}

export function isNumeric(num: any) {
  return !isNaN(num);
}

export const isDisplayStatusEditable = (data: any, country?: any) => {
  if (country === "EG") {
    if (AppConstants.DEFAULT_STATUS === (data && data.displayRouteStatus) || AppConstants.PLANNED === (data && data.displayRouteStatus) || (AppConstants.COMPLETED === (data && data.displayRouteStatus))) {
      return true;
    }
  } else {
    if (AppConstants.DEFAULT_STATUS === (data && data.displayRouteStatus) || (AppConstants.COMPLETED === (data && data.displayRouteStatus))) {
      return true;
    }
  }
}

export const generateRandomNumber = (startNumber: number, endNumber: number) => {
  return Math.floor(Math.random() * endNumber) + startNumber;
}

export function matchAllElements(array1: any, array2: any) {
  return array1.every((ele: any) => {
    return array2.includes(ele);
  })
}

export function isModifiedBySystem(lastModifiedBy: string) {
  return lastModifiedBy === AppConstants.EXPRESS_CONSTANTS.ORDER_LOG_MODIFIED_BY.SYSTEM
}

export const isInputAnArray = (value: any) => {
  return Array.isArray(value);
};

export const checkIfInputIsObject = (value: any) => {
  return typeof value === "object";
};

export const checkIfInputIsValidObjectWithKeys = (value: any) => {
  return !isUndefined(value) && checkIfInputIsObject(value) && countObjectProperties(value) > 0;
};

export const isNumberGreaterThanZero = (num: any) => {
  return isNumeric(num) && parseInt(num) > 0
}

export const getPercentageValue = (sumValue: any, currentValue: any) => {
  if(!isUndefined(sumValue) && isNumeric(sumValue) && isNumberGreaterThanZero(sumValue) && !isUndefined(currentValue) && isNumeric(currentValue) && isNumberGreaterThanZero(currentValue)){
    return Math.round((currentValue / sumValue) * 100);
  }
  return 0;
}

export const transformHubWithNameAndValueFromSelectValues = (values: any, nameKey?: string) => {
  let newValues = [];
  if (values && values.length) {
    newValues = values.map((item: any) => {
      if (item && Object.keys(item).length) {
        return {
          name: nameKey ? item[nameKey] : item.code + ' - ' + item.value,
          value: item.code
        }
      }
      return false;
    });
  }
  return newValues.filter((item: any) => item);
}

export const filterSlots =(value:any, array:any) =>{
  const startDate = moment(new Date(value.startDate)).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND)
  const endDate  = moment(new Date(value.endDate)).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
  const arr:any = []
  array.filter((d:any) => {let time = moment(new Date(d.deliveryDate)).format(AppConstants.DATE_FORMAT_BACKEND);
    let obj:any = {}
    if(startDate <= time && time <= endDate){
      obj['name'] = `${d.startTime}-${d.endTime}`
      obj['value'] = `${d.startTime}-${d.endTime}`
      arr.push(obj)
    }
   });
   return arr;
}

/**
* Formats a number with commas as thousand separators and rounds it to a specified precision.
* @param {(number|string)} num - The number to be formatted.
* @param {number} [precision=2] - The number of decimal places to round the number to.
* @return {string} The formatted number, or a dash if the input is null, NaN, or non-numeric.
*/
export function formatNumberWithCommas(num : any, precision: number = 2) {
  // handle null, NaN, and non-numeric input
  if (num === null || isNaN(num)) {
    return '-';
  }
  num = +num; // convert input to a number
  // round the number to the specified precision
  num = +num.toFixed(precision);
  // split the number into the integer and decimal parts
  const parts = num.toString().split('.');
  const integerPart = parts[0];
  let decimalPart = parts[1];
  // add trailing zeros to the decimal part if the decimal part is shorter than the desired precision
  if (decimalPart && decimalPart.length < precision) {
    decimalPart += '0'.repeat(precision - decimalPart.length);
  }
  // add commas to the integer part
  let formattedInt = '';
  for (let i = integerPart.length - 1, j = 1; i >= 0; i--, j++) {
    formattedInt = integerPart[i] + formattedInt;
    if (j % 3 === 0 && i > 0) {
      formattedInt = ',' + formattedInt;
    }
  }
  // join the integer and decimal parts
  return decimalPart ? `${formattedInt}.${decimalPart}` : formattedInt;
}


  export function transformCountryAndZones(data:any){
    let newValues : {hubs: any[], zoneList: {[key: string]: {}}}= {hubs:[],zoneList:{}}
    if (data && data.length) {
      data.forEach((item: any) => {
        if (item && Object.keys(item).length) {
          newValues.hubs.push({
            name: `${item.posNo} - ${item.name}`,
            value: item.posNo
          }) 
          newValues.zoneList[item.posNo] = item.serviceTypes[0].zones
        }
      });
     
    
    }
    return newValues
  }
export function isUomGrams(unit: UnitOfMeasure): boolean {
  return unit === UnitOfMeasure.GRAMS;
}

export function isCardOnDelivery(paymentType: 'string'): boolean {
  const type = paymentType?.toLowerCase() === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.CARD_ON_DELIVERY.toLowerCase()
  return type;
}

export function isFeatureEnabledForCountry(FeatureEnabledForCountries: string, country: string): boolean {
  return FeatureEnabledForCountries?.split(',').includes(country?.toUpperCase());
}
export const isFeatureEnabled = (featureEnabledFor:string, regions: string) => {
  if (regions && featureEnabledFor?.split(",").includes(regions?.toUpperCase())) {
    return true;
  }
  return false;
}

export const isFeatureDisabled = (featureDisabledFor:string, regions: string) => {
  if (regions && featureDisabledFor?.split(",").includes(regions?.toUpperCase())) {
    return false;
  }
  return true;
}

export function areDatesEqual(date1: Date, date2: Date): boolean {
  const firstDate = moment(date1,"DD-MM-YYYY")
  const secDate = moment(date2,"DD-MM-YYYY");
  return moment(firstDate).isSame(secDate)
}

export function loadDashIsEmpty(value: any): boolean {
  return _.isEmpty(value);
}

export function loadDashIsArray(value: any) : boolean {
  return _.isArray(value);
}

export function loadDashIsArrayAndNotEmpty(value: any) : boolean {
  return loadDashIsArray(value) && _.size(value) > 0
}

export const transformSelectValuesForPhonePrefixes = (values: any) => {
  let newValues = [];
  if (values && values.length) {
    newValues = values.map((item: any) => {
      if (item && Object.keys(item).length) {
        return {
          name: `${item.phonePrefix} - ${item.countryName}`,
          value: item.phonePrefix,
          countryCode: item.countryCode,
        };
      }
      return false;
    });
  }
  return newValues.filter((item: any) => item);
};

export const transformUerTypeListSelectValues = (values: any, nameKey?: string) => {
  let newValues = [];
  if (values && values.length) {
    newValues = values.map((item: any) => {
      if (item && Object.keys(item).length) {
        return {
          name: nameKey 
                ? item[nameKey]
                : item.value
                  ? item.value === 'Internal' 
                    ? 'Portal User' 
                    : item.value === 'External' 
                      ? 'App User'
                      : item.value
                  : null,
          value: item.code
        }
      }
      return false;
    });
  }
  return newValues.filter((item: any) => item);
}

export function combineTwoArrayAndRemoveDuplicate(updatingArr: any[], origArr: any[], keyName: string) {
  let result = _.unionBy(updatingArr, origArr, keyName);
  return result
}

export const transformSelectMenuValue = (values: any) => {
  let newValues = [];
  if (values && values.length) {
    newValues = values.map((item: any) => {
      if (item !== undefined) {
        return { name: item ? item : "(Blanks)", value: item }
      }
      return false;
    });
  }
  return newValues.filter((item: any) => item);
}