import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            height: "100%",
        },
        container: {
            flexDirection: "column",
            '& .planningItem': {
                width: '100%',
                '& .MuiTab-root': {
                    marginRight: 44
                },
                '&.heading': {
                    '& p': {
                        fontSize: 20,
                        lineHeight: "30px",
                        color: COLORS.PRIMARY_MAIN,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                    }
                },
                '&.content': {
                    backgroundColor: COLORS.WHITE,
                    borderRadius: theme.spacing(1),
                    height: "calc(100vh - 172px)",
                    '& .tabPanel': {
                        height: "calc(100% - 56px)",
                        padding: 0,
                        '&.routes': {
                            position: "relative"
                        }
                    }
                }
            }
        },
        tabPanelContainer: {
            flexDirection: "column",
            height: "100%",
            '& .panelItem': {
                width: "100%",
                '&.chipFilter': {
                    padding: "16px 16px 16px 0px",
                    '& .chipsContainer': {
                        display: "flex",
                        justifyContent: "space-between",
                        '& .chipFilterItem': {
                            height: 36,
                            minWidth: "16%",
                            borderRadius: "30px",
                            boxShadow: "0px 4px 48px rgb(0 0 0 / 8%)",
                            backgroundColor: "#FFFFFF",
                            cursor: "pointer",
                            '&:last-child': {
                                marginRight: 0
                            },
                            '&.firstItem': {
                                boxShadow: `0px 0px 10px 4px ${COLORS.BOX_SHADOW_PRIMARY}`,
                                border: `2px solid ${theme.palette.primary.main}`
                            },
                            '&.active': {
                                background: COLORS.PRIMARY_MAIN,
                                border: "none",
                                boxShadow: "none",
                                '& .chipItem p': {
                                    color: COLORS.WHITE
                                }
                            },
                            '& .chipContainer': {
                                alignItems: "center",
                                height: "100%",
                                '& .chipItem': {
                                    padding: theme.spacing(0, 1.5),
                                    '& p': {
                                        fontWeight: 500,
                                        color: COLORS.MINE_SHAFT,
                                    },
                                    '&.name': {
                                        '& p': {
                                            fontSize: 14
                                        }
                                    },
                                    '&.count': {
                                        marginLeft: "auto",
                                        '& p': {
                                            fontSize: 24,
                                            fontWeight: 600
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                '&.gridWrapper': {
                    height: "calc(100% - 98px)",
                    '& .idLink': {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        fontSize: 12
                    },
                    '& .actionsLink': {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        fontSize: 14
                    }
                }
            }
        },
        infoSection: {
            display: "flex",
            alignItems: "center"
        },
        infoIconStyle: {
            height: "12px",
            width: "12px",
            marginLeft: "6px"
        },
        publishDialogContentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .contentItem': {
                '& p': {
                    textAlign: "center",
                    lineHeight: 1.2
                },
                '&.heading': {
                    padding: theme.spacing(0, 15.75, 5),
                    '& p': {
                        fontSize: 24,
                        fontWeight: 600
                    }
                },
                '&.desc': {
                    padding: theme.spacing(0, 2.5),
                    '& p': {
                        fontSize: 18,
                        fontWeight: 400,
                        '& .count': {
                            fontWeight: 600
                        }
                    }
                }
            }
        },
        deleteDialogContentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .contentItem': {
                '& p': {
                    textAlign: "center",
                    lineHeight: 1.2
                },
                '&.heading': {
                    padding: theme.spacing(0, 15.75, 5),
                    '& p': {
                        fontSize: 24,
                        fontWeight: 600
                    }
                },
                '&.desc': {
                    padding: theme.spacing(0, 2.5),
                    '& p': {
                        fontSize: 18,
                        fontWeight: 400,
                        '& .count': {
                            fontWeight: 600
                        }
                    }
                }
            }
        },
        hubSelectionDialogTitle: {
            fontSize: 20,
            fontWeight: 600
        },
        hubSelectionDialogContentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .contentItem': {
                '&.heading': {
                    '& p': {
                        fontSize: 14,
                        fontWeight: 400
                    }
                },
                '&.hub': {
                    paddingTop: theme.spacing(3.75)
                }
            }
        },
        publishDialogButtonsContainer: {
            justifyContent: "center",
            paddingBottom: theme.spacing(8.25),
            '& .dialogBtn': {
                '&.secondary': {
                    paddingLeft: theme.spacing(8),
                    paddingRight: theme.spacing(8),
                    marginRight: theme.spacing(1)
                },
                '&.primary': {
                    paddingLeft: theme.spacing(7.25),
                    paddingRight: theme.spacing(7.25),
                    marginLeft: theme.spacing(1)
                }
            }
        },
        deleteDialogButtonsContainer: {
            justifyContent: "center",
            paddingBottom: theme.spacing(8.25),
            '& .dialogBtn': {
                '&.secondary': {
                    paddingLeft: theme.spacing(8),
                    paddingRight: theme.spacing(8),
                    marginRight: theme.spacing(1)
                },
                '&.primary': {
                    paddingLeft: theme.spacing(7.25),
                    paddingRight: theme.spacing(7.25),
                    marginLeft: theme.spacing(1)
                }
            }
        },
        hubSelectionDialogButtonsContainer: {
            justifyContent: "flex-end",
            paddingBottom: theme.spacing(7),
            '& .dialogBtn': {
                paddingLeft: theme.spacing(4.5),
                paddingRight: theme.spacing(4.5),
                '&.secondary': {
                    marginRight: theme.spacing(1)
                },
                '&.primary': {
                    marginLeft: theme.spacing(1)
                }
            }
        },
        publishDialogPaperPropsRoot: {
            width: 600,
            height: 350,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        deleteDialogPaperPropsRoot: {
            width: 600,
            height: 350,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        hubSelectionDialogPaperPropsRoot: {
            width: 500,
            height: 300,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        publishErrorPopupContainer: {
            position: "absolute",
            bottom: 0,
            height: 100,
            backgroundColor: "#FEECEC",
            flexDirection: "column",
            flexWrap: "nowrap",
            padding: theme.spacing(2),
            '& .titleBlock': {
                '& .titleContainer': {
                    alignItems: "center",
                    justifyContent: "space-between",
                    '& .titleItem': {
                        '& .title': {
                            fontSize: 14,
                            fontWeight: 600
                        },
                        '& .closeBtn': {
                            padding: theme.spacing(0)
                        }
                    }
                },
            },
            '& .contentBlock': {
                flexGrow: 1,
                overflow: "auto",
                '& .contentContainer': {
                    flexDirection: "column",
                    '& .contentItem': {
                        paddingBottom: theme.spacing(1.25),
                        '&:last-child': {
                            paddingBottom: theme.spacing(0)
                        },
                        '& .content': {
                            alignItems: "center",
                            '& .dotIcon': {
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor: "#F34041",
                                marginRight: theme.spacing(2)
                            },
                            '& .text': {
                                fontSize: 14,
                                fontWeight: 500
                            }
                        }
                    }
                }
            }
        },
        actionsHeader: {
            alignItems: "center",
            '& .actionItem': {
                cursor: "pointer",
                '& .icon': {
                    padding: theme.spacing(0.25),
                    '&.editIcon': {
                        marginRight: theme.spacing(0.75)
                    }
                }
            }
        },
        deliveryAreaHeader: {
            alignItems: "center",
            '& .columnValue': {
                fontSize: 12
            }
        },
        statusHeaderContainer: {
            '& .tag': {
                color: COLORS.WHITE,
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(0.5, 1),
                lineHeight: "14px",
                backgroundColor: COLORS.BLACK,
                fontWeight: 600,
                textTransform: "uppercase",
                fontSize: 12,
                '&.INFO_BLUE': {
                    backgroundColor: COLORS.INFO_BLUE
                },
                '&.READY_BLUE': {
                    backgroundColor: COLORS.READY_BLUE
                },
                '&.MINSK': {
                    backgroundColor: COLORS.MINSK
                },
                '&.PRIMARY_BLUE': {
                    backgroundColor: COLORS.PRIMARY_MAIN
                },
                '&.JUNGLE_GREEN': {
                    backgroundColor: COLORS.JUNGLE_GREEN
                },
                '&.PARTIAL_SUCCESS_GREEN': {
                    backgroundColor: COLORS.PARTIAL_SUCCESS_GREEN
                },
                '&.ERROR_RED': {
                    backgroundColor: COLORS.PALE_RED
                },
                '&.PALE_GRAY': {
                    backgroundColor: COLORS.PALE_GRAY
                },
                '&.ALERT_ORANGE': {
                    backgroundColor: COLORS.ALERT_ORANGE
                },
            },
            '& .orderTag': {
                background: "#FFFFFF",
                border: "1px solid #319E60",
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(0.5, 1),
                fontWeight: 600,
                fontSize: 12,
                color: "#319E60",
                lineHeight: "14px",
                height: "24px",
                textTransform: "uppercase",
                '&.GOLDEN_BELL': {
                    color: COLORS.GOLDEN_BELL,
                    borderColor: COLORS.GOLDEN_BELL
                },
                '&.MINSK': {
                    color: COLORS.SCAMPI,
                    borderColor: COLORS.SCAMPI
                },
                '&.READY_BLUE': {
                    color: COLORS.READY_BLUE,
                    borderColor: COLORS.READY_BLUE
                },
                '&.SCAMPI': {
                    color: COLORS.SCAMPI,
                    borderColor: COLORS.SCAMPI
                },
                '&.INFO_BLUE': {
                    color: COLORS.INFO_BLUE,
                    borderColor: COLORS.INFO_BLUE
                },
                '&.MINSK_DARK': {
                    color: COLORS.MINSK_DARK,
                    borderColor: COLORS.MINSK_DARK
                },
                '&.DRIFT_WOOD': {
                    color: COLORS.DRIFT_WOOD,
                    borderColor: COLORS.DRIFT_WOOD
                },
                '&.TAG_YEllOW': {
                    color: COLORS.TAG_YEllOW,
                    borderColor: COLORS.TAG_YEllOW
                },
                '&.PERSIAN_GREEN_DARK': {
                    color: COLORS.PERSIAN_GREEN_DARK,
                    borderColor: COLORS.PERSIAN_GREEN_DARK
                },
                '&.PARTIAL_SUCCESS_GREEN': {
                    color: COLORS.PARTIAL_SUCCESS_GREEN,
                    borderColor: COLORS.PARTIAL_SUCCESS_GREEN
                },
                '&.SILVER_CHALICE2': {
                    color: COLORS.SILVER_CHALICE2,
                    borderColor: COLORS.SILVER_CHALICE2
                },
                '&.PALE_RED': {
                    color: COLORS.PALE_RED,
                    borderColor: COLORS.PALE_RED
                },
                '&.GREEN_BLUE': {
                    color: COLORS.GREEN_BLUE,
                    borderColor: COLORS.GREEN_BLUE
                },
                '&.MAROON': {
                    color: COLORS.MAROON,
                    borderColor: COLORS.MAROON
                }
            }
        },
        excelDownloadStatus: {
            background: COLORS.INFO_BLACK,
            borderRadius: "8px 8px 0px 0px",
            position: "fixed",
            bottom: "12px",
            height: 48,
            width: 336,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        excelDownloadStatusText: {
            color: COLORS.WHITE,
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 500,
            fontFamily: "Montserrat"
        },
        checkedIconStyle: {
            width: 33,
            height: 33,
            padding: "6px 0px 0px 16px"
        },
        close: {
            color: COLORS.WHITE
        },
        closeIcon: {
            width: 16,
            height: 16
        },
        excelDownloadError: {
            background: COLORS.LIGHT_RED,
            borderRadius: "8px 8px 0px 0px",
            position: "fixed",
            bottom: "23px",
            height: 48,
            width: 336,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        button: {
            margin: theme.spacing(0.5),
            '&:last-child': {
                marginRight: theme.spacing(0)
            }
        },
        colSettings: {
            float: "right",
            marginTop: -3
        },
        buttonContainer: {
            marginLeft: "auto",
            '& .clearAllFilterBtn': {
                fontSize: 14,
                fontWeight: 600,
                marginRight: theme.spacing(2)
            }
        },
    }),
);
