import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { getConsignmentStatusField, getStatusWithoutUnderScore } from "../../../mocks/delve/response.transforms";

interface interfaceDelveOrderDetailsTitleContent {
  classes: any;
  consignment: any;
  metadata: any;
}

const DelveOrderDetailsTitleContent = (props: interfaceDelveOrderDetailsTitleContent) => {
  const { classes, consignment, metadata } = props;
  return (
    <Grid className={classes.titleContainer} container>
      <Grid item>
        <Grid className="headingContainer" container>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Typography className="titleHeading">
                  <span className="label"> CONSIGNMENT ID : </span>
                  <span className="content"> {consignment.consignmentCode} </span>
                </Typography>
              </Grid>
              {metadata && metadata.deliveryStatus && (
                <Grid className="tagContainer" item>
                  <Typography className={["tag", getConsignmentStatusField("color", metadata && metadata.deliveryStatus)].join(" ")}>
                    {metadata && metadata.deliveryStatus ? getStatusWithoutUnderScore(metadata.deliveryStatus) : ""}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(DelveOrderDetailsTitleContent);
