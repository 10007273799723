import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export interface StyleProps {
  width: string;
}
export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    popoverFilter: {
      maxHeight: 500,
      '& .MuiAutocomplete-popper': {
        maxHeight: 100,
        overflowY: "auto"
      }
    },
    buttonsContainer: {
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center"
    },
    rootIcon: {
      width: 24,
      height: 24,
      verticalAlign: "middle",
      cursor: "pointer",
      marginLeft: theme.spacing(1),
      '&.addMargin': {
        marginLeft: theme.spacing(0.5)
      }
    },
    button: {
      width: "50%",
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
      '&:last-child': {
        //marginRight: 0
        marginLeft: "auto"
      }
    },
    paperProps: {
      minWidth: 200,
      maxHeight: 300,
      padding: theme.spacing(2),
      marginTop: theme.spacing(1.5),
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: 4,
      boxShadow: "none",
      overflow: "hidden"
    },
    root: {
      '& .MuiOutlinedInput-root': {
        width: (props:any) => props.width ? props.width : 200,
        minWidth: 180,
      },
     
    }

  })
);