import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterFormWrapper:{
        display:'flex',
        gap: 24
    },
    buttonsFilterForm:{
        marginLeft: 'auto',
        marginRight: 12,
  '& .disableButton':{
    background:'gray !important',
  },
        '& .MuiButton-contained': {
           color: 'white',
           borderRadius: 18,
           background:'#0E5AA7',
           width: 150,
           fontSize:12
        }
        
    }

  })
);