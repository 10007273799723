import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from '../config/redux/reducers';
import { userLogout } from "../Login/redux/loginSlice";
import _ from "lodash";
import { fetchSlotForTracking, fetchStatusForTracking, fetchRouteDetails } from "./redux/routeTrackingSlice";

//Material UI Components
import { Grid, IconButton, Typography, InputAdornment, Stepper, Step, StepLabel, debounce } from "@material-ui/core";
import moment from "moment";
import Lens from "@material-ui/icons/Lens";

//Common Components
import SelectBox from "common/SelectBox";
import TextBox from '../common/TextBox';
import CustomTooltip from "common/Tooltip";
import DateRangePickerComponent from "../common/DateRangeComponent/index";
import CustomSnackbar from '../common/CustomSnackbar';
import Loader from "../common/Loader";

//Styles
import { useStyles, StepperTimelineConnector } from "./RouteTracking.styles";

//Assets
import calendarIcon from "../assets/icons/calendar-icon.svg";
import searchIcon from './../assets/icons/search-icon-small.svg';
import consignmentsIcon from './../assets/icons/consignments-icon.svg';
import cratesIcon from './../assets/icons/crates-icon.svg';
import timeSlotIcon from './../assets/icons/time-slot-icon.svg';
import userIcon from './../assets/icons/user-icon.svg';
import mobileIcon from './../assets/icons/mobile-icon.svg';
import walletIcon from "./../assets/icons/wallet-icon.svg";
import { ReactComponent as newConsignmentStatusIcon } from "./../assets/icons/new-consignment-status.svg";
import { ReactComponent as readyConsignmentStatusIcon } from "./../assets/icons/ready-consignment-status.svg";
import { ReactComponent as preparingConsignmentStatusIcon } from "./../assets/icons/preparing-consignment-status.svg";
import { ReactComponent as preparedConsignmentStatusIcon } from "./../assets/icons/prepared-consignment-status.svg";
import { ReactComponent as outForDeliveryStatusIcon } from "./../assets/icons/outForDelivery-consignment-status.svg";
import { ReactComponent as onHoldConsignmentStatusIcon } from "./../assets/icons/onHold-consignment-status.svg";
import { ReactComponent as pendingConsignmentStatusIcon } from "./../assets/icons/pending-consignment-status.svg";
import { ReactComponent as deliveredConsignmentStatusIcon } from "./../assets/icons/delivered-consignment-status.svg";
import { ReactComponent as partiallyDeliveredConsignmentStatusIcon } from "./../assets/icons/partiallyDelivered-consignment-status.svg";
import { ReactComponent as cancelledConsignmentStatusIcon } from "./../assets/icons/cancelled-consignment-status.svg";
import { ReactComponent as failedConsignmentStatusIcon } from "./../assets/icons/failed-consignment-status.svg";

import { createPayloadForSlots, createRouteDetailsPayload, getAddressInfo } from "../mocks/routeTracking/response.transforms";

//Constants
import AppConstants from "../constants";
import ConsignmentDetails from "RoutePlanning/ConsignmentDetails";
import { getDelveDashboardRecord } from "../Delve/Dashboard/Redux/DelveDashboardSlice";

interface RouteTrackingProps {
}

const RouteTracking = (props: RouteTrackingProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { routeStatus, error, allSlots, routesData, slotsList, allRouteStatus, errorCode, loading, formattedSlots } = useSelector(
    (state: AppState) => state.routeTracking
  );
  const { countryCode, hubCode } = useSelector(
    (state: AppState) => state.common
  );
  const [datePickerValue, setDatePickerValue] = useState({
    startDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
    endDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
  });
  const [routesStatus, setRoutesStatus] = useState(() => [AppConstants.SELECT_ALL.value]);
  const [slots, setSlots] = useState(() => [AppConstants.SELECT_ALL.value]);
  const [searchValue, setSearchValue] = useState("");
  const activeStep = 0;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const [openConsignmentDetailsPopup, setOpenConsignmentDetailsPopup] = useState(false);
  const [consignmentDataObj, setConsignmentDataObj] = useState({});
  const [routesActiveFilters, setRoutesActiveFilters] = useState({
    ...AppConstants.TRACKING_CONSTANTS.defaultFiltersObj, dateRange: {
      ...AppConstants.TRACKING_CONSTANTS.defaultFiltersObj.dateRange, value: {
        startDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
        endDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
      }
    }
  });
  const [loadMore, setLoadMore] = useState(AppConstants.TRACKING_CONSTANTS.loadMore);

  const handleConsignmentDetailsClick = useCallback(
    (data: any) => {
      setConsignmentDataObj(data);
      setOpenConsignmentDetailsPopup(true);
    },
    [],
  );

  const closeConsignmentDetailsPopup = useCallback(
    () => {
      setOpenConsignmentDetailsPopup(false);
    },
    [],
  );

  const handleRouteDetailsWithConsignments = useCallback(
    (filters: any, isLoadMore?: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        payloadFilters.pageSize = loadMore.pageSize;
        const payload = createRouteDetailsPayload(payloadFilters, routesData, isLoadMore);
        dispatch(
          fetchRouteDetails({
            params: payload
          })
        );
        setRoutesActiveFilters(filters);
      }
    },
    [countryCode, routesData, dispatch, loadMore.pageSize]
  );

  const handleDateChange = ((date: any) => {
    setDatePickerValue(date);
    const hubsToAdd = hubCode.filter((hub: string) => hub !== AppConstants.SELECT_ALL.value).map((hub: string) => ({
      name: hub,
      value: hub
    }));
    let updatedSlotFilters: any = {};
    updatedSlotFilters = { ...routesActiveFilters, hubCode: { ...routesActiveFilters.hubCode, value: hubsToAdd }, dateRange: { ...routesActiveFilters.dateRange, value: date } };
    slotFilterCallback(updatedSlotFilters);
    let updatedFilters: any = {};
    updatedFilters = { ...routesActiveFilters, slots: slots, hubCode: { ...routesActiveFilters.hubCode, value: hubsToAdd }, status: { ...routesActiveFilters.status, value: routesStatus }, dateRange: { ...routesActiveFilters.dateRange, value: date } };
    handleRouteDetailsWithConsignments(updatedFilters);
  });

  /**
   * Method to search based on RouteId
   */
  const handleSearchByRouteId = useCallback(() => {
    let updatedFilters: any = {};
    updatedFilters = { ...routesActiveFilters, searchAttribute: { ...AppConstants.TRACKING_CONSTANTS.searchAttribute, value: searchValue } };
    handleRouteDetailsWithConsignments(updatedFilters);
  }, [searchValue, routesActiveFilters, handleRouteDetailsWithConsignments]
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const searchValueText = event.target.value;
      setSearchValue(searchValueText);
    }, []
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if ((searchValue === "" || searchValue) && event.keyCode === 13) {
        handleSearchByRouteId();
      }
    },
    [searchValue, handleSearchByRouteId]
  )

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleDeliverySlotChange = ((selectedSlots: any) => {
    setSlots(selectedSlots);
    let updatedFilters: any = {};
    updatedFilters = { ...routesActiveFilters, slots: selectedSlots };
    handleRouteDetailsWithConsignments(updatedFilters);
  });

  const handleStatusChange = ((selectedStatus: any) => {
    setRoutesStatus(selectedStatus);
    const hubsToAdd = hubCode.filter((hub: string) => hub !== AppConstants.SELECT_ALL.value).map((hub: string) => ({
      name: hub,
      value: hub
    }));
    let updatedFilters: any = {};
    updatedFilters = { ...routesActiveFilters, status: { ...routesActiveFilters.status, value: selectedStatus }, hubCode: { ...routesActiveFilters.hubCode, value: hubsToAdd } };
    handleRouteDetailsWithConsignments(updatedFilters);
  });

  const handleSnackbarExited = useCallback(
    () => {
      if (errorCode === AppConstants.TRACKING_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
    },
    [dispatch, errorCode]
  );

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
    },
    [],
  );

  const fetchStatus = useCallback(
    () => {
      dispatch(
        fetchStatusForTracking()
      )
    },
    [dispatch]
  );

  const loadMoreCallback = debounce((event: any) => {
    const target = event.target;
    if (target) {
      if (loading || loadMore.disabled) return;
      if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
        handleRouteDetailsWithConsignments(routesActiveFilters, true);
      }
    } else {
      return;
    }
  }, 100);

  const enableLoadMore = useCallback(
    (disable?: boolean) => {
      const element = document.querySelector('.route-data-scrollable-area');
      if (element) {
        if (!disable) {
          element.addEventListener('scroll', loadMoreCallback);
        } else {
          element.removeEventListener('scroll', loadMoreCallback);
        }
      }
    },
    [loadMoreCallback]
  );

  const handleLoadMoreDisableState = useCallback(
    () => {
      let loadMoreState = loadMore;
      loadMoreState.disabled = routesData.totalPages ? routesData.pageNumber + 1 >= routesData.totalPages : true;
      setLoadMore(loadMoreState);
    }, [routesData, loadMore]
  );

  const consignmentData = (consignmentCode: any, deliveryStatus: any, address: any, totalCratesCount: any, paymentType: any, phone: any) => {
    return (
      <Grid container className={classes.tooltipContainer}>
        <Grid container className={classes.tooltipSection}>
          <Grid item>
            <Typography className={classes.routeIdText}>
              {consignmentCode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={deliveryStatus === "NEW" ? classes.newConsignmentStatus : deliveryStatus === "READY_TO_DISPATCH" ? classes.readyConsignmentStatus : deliveryStatus === "PREPARING" ? classes.preparingConsignmentStatus : deliveryStatus === "PREPARED" ? classes.preparedConsignmentStatus : deliveryStatus === "OUT_FOR_DELIVERY" ? classes.outForDeliveryConsignmentStatus : deliveryStatus === "ON_HOLD" ? classes.onHoldConsignmentStatus : deliveryStatus === "PENDING_DELIVERY" ? classes.pendingConsignmentStatus : deliveryStatus === "DELIVERED" ? classes.deliveredConsignmentStatus : deliveryStatus === "PARTIALLY_DELIVERED" ? classes.partialConsignmentStatus : deliveryStatus === "CANCELLED" ? classes.cancelledConsignmentStatus : classes.failedConsignmentStatus}>
              {deliveryStatus}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: "12px" }}>
          <Typography className={classes.addressInfoTooltip}>
            {getAddressInfo(address)}
          </Typography>
        </Grid>
        <Grid container className={classes.subSectionContainer}>
          <Grid item className={classes.subSections}>
            <img className={classes.icons} src={cratesIcon} alt="consignment-crates" />
            <Typography className={classes.iconsData}>
              {totalCratesCount || 0}
            </Typography>
          </Grid>
          <Grid item className={classes.subSections}>
            <img className={classes.icons} src={walletIcon} alt="consignment-wallet" />
            <Typography className={classes.iconsData}>
              {paymentType}
            </Typography>
          </Grid>
          <Grid item className={classes.subSections}>
            <img className={classes.icons} src={mobileIcon} alt="consignmennt-mobile" />
            <Typography className={classes.iconsData}>
              {phone || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getRouteDetails = () => {
    return routesData.elements.map((key: any, index: number) => (
      <>
        <Grid container key={index} className={classes.mainContainer}>
          <Grid item className={classes.routeDetailsSection}>
            <Grid container key={index}>
              <Grid className={classes.routesSection}>
                <Grid container>
                  <Typography className={classes.routeIdText}>{key.routeId}</Typography>
                  <Typography className={key.status === "CREATED" ? classes.createdRouteStatus : key.status === "ASSIGNED" ? classes.assignedRouteStatus : key.status === "PREPARING" ? classes.preparingRouteStatus : key.status === "OUT_FOR_DELIVERY" ? classes.outForDeliveryRouteStatus : key.status === "COMPLETED" ? classes.completedRouteStatus : key.status === "PUBLISHED" ? classes.publishedRouteStatus : key.status === "PREPARED" ? classes.preparedRouteStatus : classes.cancelledRouteStatus}>{key.status}</Typography>
                </Grid>
                <Grid container style={{ paddingTop: "12px" }}>
                  <Grid item className={classes.subSections}>
                    <img className={classes.icons} src={consignmentsIcon} alt="consignments" />
                    <Typography className={classes.iconsData}>{key.totalConsignment}</Typography>
                  </Grid>
                  <Grid item className={classes.subSections}>
                    <img className={classes.icons} src={cratesIcon} alt="crates" />
                    <Typography className={classes.iconsData}>{key.totalCrates}</Typography>
                  </Grid>
                  <Grid item className={classes.subSections}>
                    <img className={classes.icons} src={timeSlotIcon} alt="timeSlot" />
                    <Typography className={classes.iconsData}>{key.transitTime || "0 Minutes"}</Typography>
                  </Grid>
                  {key.assignee ? (
                    < Grid item className={classes.subSections}>
                      <img className={classes.icons} src={userIcon} alt="user" />
                      <Typography className={classes.iconsData}>{key.assignee.name}</Typography>
                    </Grid>
                  ) : (
                      < Grid item className={classes.subSections}>
                        <img className={classes.icons} src={userIcon} alt="user" />
                        <Typography className={classes.iconsData}>No Assignee</Typography>
                      </Grid>
                    )}

                </Grid>

              </Grid>
              <div className={classes.horizontalLine} />
            </Grid >
          </Grid>
          <Grid item className={classes.consignmentDetailsSection}>
            {slotsList.map((slotItem: any) => (
              <>
                <Grid container className={classes.consignmentsSection}>
                  <Grid item className={classes.slotWiseText}>
                    <Grid item>
                      <Stepper className={classes.timelineStepper} alternativeLabel activeStep={activeStep} connector={<StepperTimelineConnector />}>
                        {
                          key.slotsWiseData && key.slotsWiseData[slotItem] ? (
                            key.slotsWiseData[slotItem].map((slots: any, index: number) => (
                              <CustomTooltip classes={{ tooltip: classes.tooltip, popper: classes.popper }} arrow title={consignmentData(slots.consignmentCode, slots.metadata.deliveryStatus, slots.deliveryInformation.customerContactInfo.address, slots.deliveryInformation.totalCratesCount, slots.metadata.paymentType, slots.deliveryInformation.customerContactInfo.phone)}>
                                <Step onClick={() => handleConsignmentDetailsClick(slots)} key={`consignment-step-${slots.consignmentCode}`} className='timelineStep'>
                                  {getConsignmentStatus(slots.metadata.deliveryStatus, index)}
                                </Step >
                              </CustomTooltip>
                            ))) : (null)}
                      </Stepper>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid >
      </>
    ));
  };

  const getConsignmentStatus = (status: any, index: any) => {
    switch (status) {
      case "NEW":
        return <StepLabel StepIconComponent={newConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "READY_TO_DISPATCH":
        return <StepLabel StepIconComponent={readyConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "PREPARING":
        return <StepLabel StepIconComponent={preparingConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "PREPARED":
        return <StepLabel StepIconComponent={preparedConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "OUT_FOR_DELIVERY":
        return <StepLabel StepIconComponent={outForDeliveryStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "ON_HOLD":
        return <StepLabel StepIconComponent={onHoldConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "PENDING_DELIVERY":
        return <StepLabel StepIconComponent={pendingConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "DELIVERED":
        return <StepLabel StepIconComponent={deliveredConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "PARTIALLY_DELIVERED":
        return <StepLabel StepIconComponent={partiallyDeliveredConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      case "CANCELLED":
        return <StepLabel StepIconComponent={cancelledConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
      default:
        return <StepLabel StepIconComponent={failedConsignmentStatusIcon} className='stepLabel'>{`${index + 1}`.padStart(2, '0')}</StepLabel>;
    }
  };

  const slotFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean) => {
      if (filters) {
        let payloadFilters = _.cloneDeep(filters);
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        payloadFilters.pageSize = loadMore.pageSize;
        const payload = createPayloadForSlots(payloadFilters, routesData, isLoadMore);
        dispatch(
          fetchSlotForTracking({
            params: payload
          })
        );
        setRoutesActiveFilters(filters);
      }
    },
    [countryCode, routesData, dispatch, loadMore.pageSize]
  );

  useEffect(() => {
    enableLoadMore();
    return () => {
      enableLoadMore(true);
    }
  }, [loadMore, enableLoadMore])

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    handleLoadMoreDisableState();
  }, [routesData, handleLoadMoreDisableState]);


  useEffect(() => {
    fetchStatus();
    const hubsToAdd = hubCode.filter((hub: string) => hub !== AppConstants.SELECT_ALL.value).map((hub: string) => ({
      name: hub,
      value: hub
    }));
    let updatedSlotFilters: any = { ...routesActiveFilters, hubCode: { ...routesActiveFilters.hubCode, value: hubsToAdd } };
    let updatedFilters: any = { ...routesActiveFilters, slots: slots, hubCode: { ...routesActiveFilters.hubCode, value: hubsToAdd }, status: { ...routesActiveFilters.status, value: routesStatus.length ? routesStatus : allRouteStatus } };
    slotFilterCallback(updatedSlotFilters);
    handleRouteDetailsWithConsignments(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubCode]);

  return (
    <div className={classes.root}>
      <Grid container justify="space-between">
        <Grid item xs={4}>
          <Typography className={classes.title}></Typography>
        </Grid>
        <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Grid container spacing={2} style={{ flexWrap: "nowrap" }}>

            <Grid item className={classes.tilesView}>
              <TextBox
                fullWidth
                variant="outlined"
                onChange={handleSearch}
                onKeyDown={handleKeyPress}
                value={searchValue}
                className={classes.searchBox}
                textBoxId="searchTextbox"
                name="consignmentAndRouteSearch"
                type="text"
                placeholderText="Search by Route Id"
                endadornment={(
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={handleSearchByRouteId}
                    >
                      <img src={searchIcon} alt="search" className={classes.searchImage} />
                    </IconButton>
                  </InputAdornment>
                )}
              ></TextBox>
            </Grid>
            <Grid item className={classes.tilesView}>
              <SelectBox
                fullWidth
                multiple
                className={classes.select}
                id="route-status"
                label="Route Status"
                value={routesStatus}
                items={routeStatus || []}
                handleChange={handleStatusChange}
              ></SelectBox>
            </Grid>
            <Grid item className={classes.tilesView}>
              <DateRangePickerComponent
                fullWidth
                keyId="create-route-date-picker"
                label="Date"
                className={classes.icon}
                value={datePickerValue}
                isTextField={true}
                iconSrc={calendarIcon}
                autoUpdateInput={false}
                autoApply={true}
                maxSpan={{
                  day: "2",
                }}
                showDropdowns={true}
                linkedCalendars={true}
                locale={{
                  format: AppConstants.DATE_FORMAT,
                }}
                updateDateRange={handleDateChange}
              />
            </Grid>
            <Grid item className={classes.tilesView}>
              <SelectBox
                fullWidth
                className={classes.select}
                value={slots}
                id="slot"
                label="Slots"
                multiple
                items={allSlots || []}
                handleChange={handleDeliverySlotChange}
              ></SelectBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.statusContainer}>
          <Grid container className={classes.legendsContainer} style={{}}>
            <Grid item className={classes.main}>
              <Lens
                className={classes.newStatusIcon}
              />
              <Typography className={classes.legend}>New</Typography></Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.readyToDispatchStatusIcon}
              />
              <Typography className={classes.legend}>Ready To Dispatch</Typography></Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.preparingStatusIcon}
              />
              <Typography className={classes.legend}>Preparing</Typography></Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.preparedStatusIcon}
              />
              <Typography className={classes.legend}>Prepared</Typography></Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.outForDeliveryStatusIcon}
              />
              <Typography className={classes.legend}>Out for Delivery</Typography>
            </Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.onHoldStatusIcon}
              />
              <Typography className={classes.legend}>On Hold</Typography>
            </Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.pendingDeliveryStatusIcon}
              />
              <Typography className={classes.legend}>Pending Delivery</Typography>
            </Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.deliveredStatusIcon}
              />
              <Typography className={classes.legend}>Delivered</Typography>
            </Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.partiallyDeliveredStatusIcon}
              />
              <Typography className={classes.legend}>Partially Delivered</Typography>
            </Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.cancelledStatusIcon}
              />
              <Typography className={classes.legend}>Cancelled</Typography>
            </Grid>
            <Grid item className={classes.main}>
              <Lens
                className={classes.failedStatusIcon}
              />
              <Typography className={classes.legend}>Delivery Failed</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.statusTitle}>
            <Typography className={classes.statusText}>Routes</Typography>
          </Grid>
          <Grid container className={`route-data-scrollable-area ${classes.scrollableContainer}`}>
            {!error ? (
              <>
                {loading && <Loader></Loader>}
                <Grid container className={classes.routeDetailsContainer} direction="column">
                  {
                    getRouteDetails()
                  }
                </Grid>

                <Grid container className={classes.slotsContainer}>
                  <Grid item className={classes.slotSection}>
                    <Typography className={classes.slotText} >Slots</Typography>
                  </Grid>
                  <Grid item className={classes.generatedSlotsContainer}>
                    {formattedSlots.map((item: any) => (
                      <Grid container className={classes.slotWiseItem}>
                        <Grid item className={classes.slotWiseText} style={{ justifyContent: "center" }}>{item}</Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            ) : (
                <Grid container className={classes.errorContainer}>
                  <Typography className={classes.error}>{error}</Typography>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      {!error ? (
        <Grid className={classes.routesCount} container>
          <Grid className={classes.countSection} item>
            <Grid className="countContainer" container>
              <Grid className="countItem" item>
                <Typography>
                  <span className="current">{routesData.elements.length || 0}</span>
                  <span className="separator">/</span>
                  <span className="count">{routesData.totalElements || 0}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>) : null}
      <ConsignmentDetails open={openConsignmentDetailsPopup} consignment={consignmentDataObj} closePopup={closeConsignmentDetailsPopup}></ConsignmentDetails>
      <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
    </div >
  );
};

export default RouteTracking;