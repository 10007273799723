import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 18,
          fontWeight: 500,
          '& .content': {
            fontWeight: 600
          }
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        }
      },
      '& .tagContainer': {
        marginLeft: theme.spacing(1),
        '& .tag': {
          color: COLORS.WHITE,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5, 1),
          lineHeight: "14px",
          backgroundColor: COLORS.BLACK,
          fontWeight: 600,
          textTransform: "uppercase",
          fontSize: 12,
          '&.PICTON_BLUE': {
            backgroundColor: COLORS.PICTON_BLUE
          },
          '&.CREAM_CAN': {
            backgroundColor: COLORS.CREAM_CAN
          },
          '&.VISTA_BLUE': {
            backgroundColor: COLORS.VISTA_BLUE
          },
          '&.SEA_GREEN': {
            backgroundColor: COLORS.SEA_GREEN
          },
          '&.GOLDEN_BELL': {
            backgroundColor: COLORS.GOLDEN_BELL
          },
          '&.SCAMPI': {
            backgroundColor: COLORS.SCAMPI
          },
          '&.BLUE': {
            backgroundColor: COLORS.PRIMARY_MAIN
          },
          '&.SEA_GREEN2': {
            backgroundColor: COLORS.SEA_GREEN2
          },
          '&.LUCKY': {
            backgroundColor: COLORS.LUCKY
          },
          '&.DUSTY_GRAY': {
            backgroundColor: COLORS.DUSTY_GRAY
          },
          '&.JUNGLE_GREEN': {
            backgroundColor: COLORS.JUNGLE_GREEN
          },
          '&.BLACK': {
            backgroundColor: COLORS.BLACK
          },
        }
      }
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .selectionArea': {
        paddingBottom: theme.spacing(3),
        '& .selectionFormControlContainer': {
          '& .formHeading': {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.text.primary,
            paddingBottom: theme.spacing(2)
          },
          '& .radioGroup': {
            flexDirection: 'row',
            '& .radioItem': {
              marginRight: theme.spacing(3)
            },
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.text.primary,
            }
          }
        }
      },
      '& .selectedContentArea': {
        '& .contentAreaContainer': {
          flexDirection: "column",
          flexWrap: "nowrap",
          '&.reschedule .MuiOutlinedInput-root': {
            height: 40
          },
          '& .contentAreaItem': {
            paddingBottom: theme.spacing(3),
            flexGrow: 1,
            '& .itemContainer': {
              flexDirection: "column",
              '& .itemLabel': {
                paddingBottom: theme.spacing(1),
                alignSelf: "flex-start",
                '& p': {
                  fontSize: 14,
                  fontWeight: 400
                }
              },
              '& .itemInput': {
                '& .MuiOutlinedInput-input': {
                  fontSize: 14,
                  fontWeight: 400
                }
              }
            },
          }
        }
      }
    },
    buttons: {
      width: "100%",
      '& .userBtn': {
        '&.secondary': {
          marginRight: theme.spacing(2)
        },
        flexGrow: 1
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 600
    },
    '& .react-pdf__Page': {
      marginTop: "10px",
    },
    '& .react - pdf__Page__textContent': {
      border: "1px solid darkgrey",
      boxShadow: "5px 5px 5px 1px #ccc",
      borderRadius: "5px",
    },
    '& .react - pdf__Page__annotations.annotationLayer': {
      padding: "20px",
    },
    '& .react - pdf__Page__canvas': {
      margin: "0 auto",
    },
  })
);