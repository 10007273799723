import React from "react";

import { Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import SelectBox from "../../../common/SelectBox";
import CustomTooltip from "../../../common/Tooltip";
import AppConstants from "../../../constants/AppConstants";

import { isFeatureEnabledForCountry, isNonEmptyArray } from "../../../utils/helpers.utils";
import { getAllAreaTitle, getSlotName } from "../utils/AllRouteUtils";
import { ALL_ROUTES_TEXT_CONTANT, ROUTE_TYPE } from "../utils/AllRouteConstant";
import { subRouteCreateFeatureEnabledForCountries } from "../../../config/Api.config";
import { getDateFromFormatInReqFormat } from "../../../utils/dateUtils";

interface AssignDriverDialogContentInterface {
  classes: any;
  countryCode: string;
  getUpdatedAssigneList: any;
  stateObj: any;
  driverValue: any;
  helperValue: any;
  vehicleValue: any;
  handleInputChange: (value: any, name: string) => void;
  showPopUp: boolean;
  handleHideShowPopUp: () => void;
  toggleCreateSubroute: (routeId: string) => void;
}

const AssignDriverDialogContent = (props: AssignDriverDialogContentInterface) => {
  const { classes, countryCode, getUpdatedAssigneList, stateObj, driverValue, helperValue, vehicleValue, handleInputChange, showPopUp, handleHideShowPopUp, toggleCreateSubroute } = props;

  const getPopUpTitle = () => {
    let returnString: string = ALL_ROUTES_TEXT_CONTANT.ROUTE_DETAILS;
    if (stateObj?.isSubRoutePresent) {
      returnString = ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE_DETAILS;
      if (stateObj?.routeType === ROUTE_TYPE.PARENT_ROUTE) {
        returnString = ALL_ROUTES_TEXT_CONTANT.PARENT_ROUTE_DETAILS;
      }
    }
    return returnString;
  };

  const getClassName = () => {
    if (stateObj?.isSubRoutePresent) {
      if (stateObj?.routeType === ROUTE_TYPE.PARENT_ROUTE) {
        return `${classes.routeTitleSpanStyle} ${classes.parentRouteBoxStyle}`;
      }
      return `${classes.routeTitleSpanStyle} ${classes.subRouteBoxStyle}`;
    }
    return classes.dialogHeaderValueSpanStyle;
  };

  const renderSubRouteSwitchTypography = () => {
    return (
      <Typography
        className={`${classes.marginAutoZero} ${classes.fontSizeFourteen} ${classes.fontWeightSix} ${classes.lineHeightTwenty}`}
      >{`${ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE_ENABLING}`}</Typography>
    );
  };
  const renderSubRouteSwitch = () => {
    return (
      <>
        <Grid item className={`${classes.displayFlex} ${classes.marginAutoZero}`}>
          {stateObj?.isSubRoutePresent ? (
            <CustomTooltip title={`${ALL_ROUTES_TEXT_CONTANT.DISABLE_SUB_ROUTE_SWITCH}`}>{renderSubRouteSwitchTypography()}</CustomTooltip>
          ) : (
            renderSubRouteSwitchTypography()
          )}
          <SwitchButton
            size="large"
            checked={stateObj?.subRouteCreated}
            handleChange={() => toggleCreateSubroute(stateObj?.routeId)}
            onClick={(e: any) => e.stopPropagation()}
            disabled={stateObj?.isSubRoutePresent}
          />
        </Grid>
      </>
    );
  };
  return (
    <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={12} className={classes.dialogHeaderButtonGridItemStyle}>
        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
          <Grid item>{showPopUp && <Typography className={getClassName()}>{`${getPopUpTitle()}`}</Typography>}</Grid>
          <Grid item className={classes.dialogHeaderButtonGridStyle}>
            <Grid container spacing={0} direction="row" justify="flex-end" alignItems="center">
              {showPopUp && isFeatureEnabledForCountry(subRouteCreateFeatureEnabledForCountries, sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode) && renderSubRouteSwitch()}
              <Grid item>
                <IconButton aria-label="close" className="closeBtn" onClick={handleHideShowPopUp}>
                  {showPopUp ? (
                    <CloseIcon />
                  ) : (
                    <CustomTooltip title={`${ALL_ROUTES_TEXT_CONTANT.ROUTE_DETAILS_POP_UP_TOOL_TIP}`}>
                      <KeyboardArrowUpIcon />
                    </CustomTooltip>
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showPopUp && (
        <>
          <Grid item>
            <Typography className={classes.dialogHeaderSpanStyle}>{"Route ID"}</Typography>
            <Typography className={classes.dialogValueSpanStyle}>{`${stateObj?.routeId}`}</Typography>
          </Grid>
          <Grid item>
            <SelectBox
              handleChange={(value: any) => handleInputChange(value, "Driver")}
              value={driverValue}
              id="driver-select-box"
              label="Driver"
              enableSearch={true}
              customWidthSearchBar={true}
              items={getUpdatedAssigneList("driver", stateObj)}
              showSelectNone={true}
            ></SelectBox>
          </Grid>
          <Grid item>
            <SelectBox
              handleChange={(value: any) => handleInputChange(value, "Helper")}
              value={helperValue}
              enableSearch={true}
              customWidthSearchBar={true}
              id="helper-select-box"
              label="Helper"
              items={getUpdatedAssigneList("helper", stateObj)}
              showSelectNone={true}
            ></SelectBox>
          </Grid>
          <Grid item>
            <SelectBox
              handleChange={(value: any) => handleInputChange(value, "Vehicle")}
              value={vehicleValue}
              id="vehicle-select-box"
              label="Vehicle"
              items={getUpdatedAssigneList("vehicle", stateObj)}
              showSelectNone={true}
            ></SelectBox>
          </Grid>
          <Grid item>
            <Typography className={classes.dialogHeaderSpanStyle}>{"Hub"}</Typography>
            <Typography className={classes.dialogValueSpanStyle}>{`${stateObj?.hubName}`}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dialogHeaderSpanStyle}>{"Delivery Area"}</Typography>
            <Typography className={classes.dialogValueSpanStyle}>{`${getAllAreaTitle(stateObj?.deliveryArea)}`}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dialogHeaderSpanStyle}>{"Consignments"}</Typography>
            <Typography className={classes.dialogValueSpanStyle}>{`${stateObj?.consignmentsIds?.length}`}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dialogHeaderSpanStyle}>{"Crates"}</Typography>
            <Typography className={classes.dialogValueSpanStyle}>{`${stateObj?.totalCrates}`}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dialogHeaderSpanStyle}>{"Delivery Slot"}</Typography>
            <Typography className={classes.dialogValueSpanStyle}>{`${stateObj?.deliverySlot && isNonEmptyArray(stateObj?.deliverySlot) && getSlotName(stateObj?.deliverySlot[0])}`}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dialogHeaderSpanStyle}>{"Delivery Date"}</Typography>
            <Typography className={classes.dialogValueSpanStyle}>{`${
              stateObj?.plannedDate && getDateFromFormatInReqFormat(stateObj.plannedDate, AppConstants.DATE_FORMAT_BACKEND, AppConstants.DATE_FORMAT)
            }`}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(AssignDriverDialogContent);
