import * as React from "react";
import TextBox from "../../common/TextBox";
import { ReactComponent as searchIcon } from "../../assets/icons/search-icon-blue.svg";
import {
  List,
  ListItem,
  FormControlLabel,
  SvgIcon,
  Checkbox,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import { useStyles } from "./listItem.styles";

interface CheckboxListProps {
  showCheckBox?: boolean;
  item: Array<string>;
  checkboxHandler: (value: string) => void;
  searchValue:string
  handleSearch:() => void;
  listClass?: string
}

const SearchableList = (props: CheckboxListProps) => {
  const classes = useStyles();
  const {
    showCheckBox,
    checkboxHandler,
    item,
    handleSearch,
    searchValue,
    listClass,
  } = props;
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value: any) => () => {
    // const currentIndex = checked.indexOf(value);
    // const newChecked = [...checked];

    // if (currentIndex === -1) {
    //   newChecked.push(value);
    // } else {
    //   newChecked.splice(currentIndex, 1);
    // }

    checkboxHandler(value);
  };

  return (
    <>
      <Box className={classes.search}>
        <TextBox
          variant="outlined"
          onChange={handleSearch}
          value={searchValue}
          textBoxId="searchTextbox"
          className="inputBox"
          name="stagingAreaSearch"
          type="text"
          placeholderText="Search by Area"
        ></TextBox>
        <SvgIcon
          component={searchIcon}
          className={classes.icon}
          viewBox="0 0 48 48"
        />
      </Box>
      <List className={listClass && listClass}>
        {item
          .filter((value: any) => value.match(new RegExp(searchValue, "i")))
          .map((value: any) => {
            return (
              <ListItem key={value}>
                <FormControlLabel
                  label={
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 400,
                      }}
                    >
                      {value}
                    </Typography>
                  }
                  control={
                    showCheckBox ? (
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        color="primary"
                        onChange={handleToggle(value)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </ListItem>
            );
          })}
      </List>
    </>
  );
};

export default SearchableList;
