import networkHandler from "../../../network/networkHandler";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AllRoutesSliceInitialState, commonFetchInitialState } from "../utils/AllRouteConstant";
import { transformSelectValues } from "../../../utils/helpers.utils";
import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { API_RESPONSE_STATUS } from "../../../constants/CommonConstants";

export const fetchAllPlannedRoutes = createAsyncThunk("AllRoutesPlanning/FetchAllPlannedRoutes", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "route/planned-route",
      method: EApiMiddlewareMethods.POST,
      data: payload,
    } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchAllUnPlannedRoutes = createAsyncThunk("AllRoutesPlanning/fetchAllUnPlannedRoutes", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "route/unplanned-route",
      method: EApiMiddlewareMethods.POST,
      data: payload,
    } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const createRemoveOrderFromRoute = createAsyncThunk("AllRoutesPlanning/createRemoveOrderFromRoute", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "order/update-order-route-status",
      method: EApiMiddlewareMethods.PUT,
      data: payload,
    } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchExistingRouteToAssignToOrder = createAsyncThunk("AllRoutesPlanning/fetchExistingRouteToAssignToOrder", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "route/add-order/list-routes",
      method: EApiMiddlewareMethods.POST,
      data: payload,
    } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const createNewSubRoute = createAsyncThunk("AllRoutesPlanning/createNewSubRoute", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "route/sub-route",
      method: EApiMiddlewareMethods.POST,
      data: payload,
    } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const initialState: AllRoutesSliceInitialState = {
  loading: false,
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  plannedRoutes: {},
  unPlannedRoutes: {},
  removedRouteData: {},
  existingRoutesList: {},
  orderStatuses: [],
};

const allRoutesSlice = createSlice({
  name: "AllRoutesPlanning",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchAllPlannedRoutes.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.plannedRoutes = {};
      })
      .addCase(fetchAllPlannedRoutes.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.plannedRoutes = payload;
      })
      .addCase(fetchAllPlannedRoutes.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /** fetchAllUnPlannedRoutes */
      .addCase(fetchAllUnPlannedRoutes.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.unPlannedRoutes = {};
      })
      .addCase(fetchAllUnPlannedRoutes.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.orderStatuses = transformSelectValues(payload.deliveryStatuses || []);
        state.unPlannedRoutes = payload;
      })
      .addCase(fetchAllUnPlannedRoutes.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /** createRemoveOrderFromRoute */
      .addCase(createRemoveOrderFromRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.removedRouteData = {};
      })
      .addCase(createRemoveOrderFromRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.removedRouteData = payload;
      })
      .addCase(createRemoveOrderFromRoute.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /* fetchExistingRouteToAssignToOrder */
      .addCase(fetchExistingRouteToAssignToOrder.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.existingRoutesList = {};
      })
      .addCase(fetchExistingRouteToAssignToOrder.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.existingRoutesList = payload;
      })
      .addCase(fetchExistingRouteToAssignToOrder.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /* createNewSubRoute */
      .addCase(createNewSubRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(createNewSubRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(createNewSubRoute.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      });
  },
});

export default allRoutesSlice.reducer;
