import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { ExpressDashboardState } from "./expressSlice.types";
import AppConstants from "../../constants";
import moment from "moment";
import { transformSelectValues, saveFile } from "../../utils/helpers.utils";
import { transformBundleData } from "../../mocks/routePlanning/response.transforms";
import {getCrateDetails} from '../../utils/business/crate'

export const fetchDynamicFiltersOrdersDropdownValues = createAsyncThunk(
  "Express/FetchDynamicFiltersOrdersDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningOrderListByFilter = createAsyncThunk(
  "Express/FetchOrdersByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrdersFilterChipsDisplayCount = createAsyncThunk(
  "Express/FetchOrdersFilterChipsDisplayCount",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/displayFilterCount',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConsignmentHistory = createAsyncThunk(
  "ExpressOrderDetails/FetchConsignmentHistory",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `status/getOrderStatuses?type=ORDER&sort=DESC&orderBy=updatedAt&consignmentCode=${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStatusOnClick = createAsyncThunk(
  "ConsignmentDetails/FetchStatusOnClick",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `delayedUpdates/fetch/snapshot/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, false, false, false, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddressStatus = createAsyncThunk(
  "ExpressOrderDetails/GetAddressStatus",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/address-status`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrderLogs = createAsyncThunk(
  "ExpressOrderDetails/FetchOrderLogs",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `log/getOrderLogs?type=ORDER&consignmentCode=${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchDriversList = createAsyncThunk(
  "DriversAssignment/FetchDriversList",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `driver/getAllDrivers`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignDriverManually = createAsyncThunk(
  "DriverAssignment/AssignDriverManually",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/assign-driver`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "Express/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/export',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      const errorResponse = await error?.response?.data?.text();
      const errorObj = JSON.parse(errorResponse);
      return rejectWithValue(errorObj?.error);
    }
  }
);

export const downloadOrderLogs = createAsyncThunk(
  "Express/ExportOrderLogs",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/export-logs',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportInvoicePdf = createAsyncThunk(
  "Express/ExportInvoicePdf",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'v2/order/view-invoice',
        method: EApiMiddlewareMethods.POST,
        data: params,
        responseType: "bytes"
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchExpressOrderDeliveryStatuses = createAsyncThunk(
  "Express/FetchExpressOrderDeliveryStatuses",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/statuses/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeliveryReasons = createAsyncThunk(
  "Express/FetchDeliveryReasons",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'reasons/get',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBundleProducts = createAsyncThunk(
  "Express/FetchBundleProducts",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `product/${params}`,
        method: EApiMiddlewareMethods.GET,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const expressOrderReplanning = createAsyncThunk(
  "Express/ExpressOrderReplanning",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/replanning',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const replanDuringPartialRejection = createAsyncThunk(
  "Express/ReplanAndPartialRejection",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'v2/order/replan-order-line-items',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const submitOrderComments = createAsyncThunk(
  "Express/SubmitOrderComments",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/add-comment',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeDialog = createAsyncThunk("Express/CloseDialog", () => {
  return false;
});
export const resetStatusRefreshButton = createAsyncThunk("Planning/resetStatusRefreshButton", () => {
  return false;
});
export const resetBundleList = createAsyncThunk("Planning/resetBundleList", () => {
  return false;
});

export const driverAssignmentSuccess = createAsyncThunk("Express/DriverAssignmentSuccess", () => {
  return false;
});

export const userLogout = createAsyncThunk("UserLogin/Logout", () => {
  return false;
});


const initialState: ExpressDashboardState = {
  loading: false,
  tableLoading: false,
  exportError: "",
  ordersFilterChipsDisplayCount: {
    consignmentCount: {
      UNASSIGNED: 0,
      ON_HOLD: 0,
      ASSIGNED: 0,
      OUT_FOR_DELIVERY: 0,
      COMPLETED: 0,
    }
  },
  ordersFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    deliverySlotList: [],
    deliveryZonesList: [],
    addressStatusList: [],
    statusList: [],
    driverList: [],
    vehicleList: [],
    paymentTypeList: [],
    deliveryArea: [],
    orderStatusList: [],
    executionStatusList: []
  },
  ordersData: {
    elements: [],
    pageNumber: 0
  },
  error: "",
  errorCode: "",
  isPolled: false,
  consignmentStatuses: [],
  orderLogs: [],
  driversList: [],
  excelLoading: false,
  invoiceData: "",
  invoiceUrl:"",
  displayInvoice: false,
  invoiceMessage: "",
  invoiceConsignment: "",
  driverAssigned: false,
  orderDeliveryStatuses: [],
  reasonsDropdownList: [],
  orderCommentsList: [],
  deliveryReasons: {},
  rejectionReasons: {},
  orderCommentsReasons: {},
  consignmentDataAfterReplanning: {},
  replanningConsignmentSuccess: false,
  rejectionReasonList: [],
  productsAfterUpdateLineItem: [],
  bundleProductAfterUpdateLineItem: [],
  revisedAmountAfterCalculation: "",
  consignmentDataAfterAddressUpdate: {},
  isAddressUpdated: false,
  orderCommentsAfterSubmit: {},
  areOrderCommentsAdded: false,
  bundleProductList:[],
  productList:[],
  crateInfo:[],
};
const ADDRESS_STATUS_TEMP =[{code: "PENDING", value: "PENDING"},{code: "UNVERIFIED", value: "UNVERIFIED"},{code: "VERIFIED", value: "VERIFIED"}]
const expressSlice = createSlice({
  name: "Express",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchOrdersFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.isAddressUpdated = false;
        state.ordersFilterChipsDisplayCount = payload && payload.results;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningOrderListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningOrderListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = false;
        state.isAddressUpdated = false;
        state.ordersData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.results.elements]
              : [...state.ordersData.elements, ...payload.results.elements],
          pageNumber: payload.results.pageNumber,
          totalElements: payload.results.totalElements,
          totalPages: payload.results.totalPages
        };
        if (payload && payload.results && payload.results.filters) {
          state.ordersFiltersDropdowns = {
            hubCodeList: transformSelectValues(payload.results.filters.hubCodes || []),
            hubNameList: transformSelectValues(payload.results.filters.hubNames || []),
            deliverySlotList: transformSelectValues(payload.results.filters.deliverySlots || []),
            deliveryZonesList: transformSelectValues(payload.results.filters.deliveryZones || []),
            statusList: transformSelectValues(payload.results.filters.statuses || []),
            orderStatusList: transformSelectValues(payload.results.filters.orderStatuses || []),
            driverList: transformSelectValues(payload.results.filters.drivers || []),
            vehicleList: transformSelectValues(payload.results.filters.vehicles || []),
            paymentTypeList: transformSelectValues(payload.results.filters.paymentTypes || []),
            deliveryArea: transformSelectValues(payload.results.filters.areas || []),
            addressStatusList: transformSelectValues(ADDRESS_STATUS_TEMP),
            executionStatusList: transformSelectValues(payload.results.filters.deliveryExecutionStatus || [])
          };
        }
      })
      .addCase(fetchPlanningOrderListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchConsignmentHistory.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchConsignmentHistory.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.consignmentStatuses = payload.results.statuses || [];
        state.orderLogs = [];
      })
      .addCase(fetchConsignmentHistory.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
        .addCase(fetchStatusOnClick.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchStatusOnClick.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.isPolled = payload.polled;
      })
      .addCase(fetchStatusOnClick.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrderLogs.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderLogs.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.orderLogs = payload.results.logs || [];
      })
      .addCase(fetchOrderLogs.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchBundleProducts.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchBundleProducts.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.productList= payload?.results?.products
        state.bundleProductList = payload?.results?.bundleProducts?.length ? transformBundleData(payload?.results?.bundleProducts) : [];
        state.crateInfo = getCrateDetails(payload?.results)
      
      })
      .addCase(fetchBundleProducts.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Express_Orders_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload?.errorCode : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload?.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;      
      })
      .addCase(downloadOrderLogs.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(downloadOrderLogs.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Order_Logs_${currentDate}_${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(downloadOrderLogs.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportInvoicePdf.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(exportInvoicePdf.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        const data = payload.results.Invoices && payload.results.Invoices.length ? payload.results.Invoices[0] : [];
        state.invoiceConsignment = payload.results.Invoices && payload.results.Invoices.length ? payload.results.Invoices[0].ConsignmentNo : "";
        state.displayInvoice = payload.results.Invoices && payload.results.Invoices.length;
        if(data?.FileUrl){
          state.invoiceUrl = data?.FileUrl
        }
        else if(data?.FileDataBytes){
         const pdfData = `data:application/pdf;base64,${data.FileDataBytes}`;
          state.invoiceData = pdfData ;
        }
        state.invoiceMessage = payload.results.Message;
      })
      .addCase(exportInvoicePdf.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDriversList.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDriversList.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driversList = payload.results || [];
        state.driverAssigned = false;
      })
      .addCase(fetchDriversList.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(assignDriverManually.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(assignDriverManually.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driverAssigned = payload && payload.status === "SUCCESS" ? true : false;
      })
      .addCase(assignDriverManually.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.error.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchExpressOrderDeliveryStatuses.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchExpressOrderDeliveryStatuses.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.orderDeliveryStatuses = transformSelectValues(payload.results && payload.results.length ? payload.results : []);
      })
      .addCase(fetchExpressOrderDeliveryStatuses.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDeliveryReasons.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDeliveryReasons.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let deliveryReasonsList: any = [];
        let rejectionReasonsList: any = [];
        let orderCommentsList : any = [];
        if (payload.results && payload.results.type === AppConstants.EXPRESS_CONSTANTS.DELIVERY_TYPE.LINE_ITEM_REJECTION) {
          if (payload.results && payload.results.reasons) {
            rejectionReasonsList = payload.results.reasons.map((reason: any) => {
              return {
                code: reason.reasonCode,
                value: reason.reasonDescription
              };
            });
          }
          state.loading = false;
          state.errorCode = "";
          state.error = "";
          state.rejectionReasonList = transformSelectValues(rejectionReasonsList);
          state.rejectionReasons = payload.results.reasons;
        } else {
          if (payload.results && payload.results.reasons) {
            deliveryReasonsList = payload.results.reasons.map((reason: any) => {
              return {
                code: reason.reasonCode,
                value: reason.reasonDescription
              };
            });
          }
          state.loading = false;
          state.errorCode = "";
          state.error = "";
          state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
          state.deliveryReasons = payload.results.reasons;
        }
        if(payload.results && payload.results.type === AppConstants.EXPRESS_CONSTANTS.DELIVERY_TYPE.ORDER_COMMENTS){
          if (payload.results && payload.results.reasons) {
            orderCommentsList = payload.results.reasons.map((reason: any) => {
              return {
                code: reason.reasonCode,
                value: reason.reasonDescription
              };
            });
          }
          state.loading = false;
          state.errorCode = "";
          state.error = "";
          state.orderCommentsList = transformSelectValues(orderCommentsList);
          state.orderCommentsReasons = payload.results.reasons;
        }
      })
      .addCase(fetchDeliveryReasons.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(expressOrderReplanning.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(expressOrderReplanning.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.replanningConsignmentSuccess = true;
        state.consignmentDataAfterReplanning = payload && payload.results;
      })
      .addCase(expressOrderReplanning.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.error.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.replanningConsignmentSuccess = false;
      })
      .addCase(closeDialog.fulfilled, (state, action) => {
        state.consignmentDataAfterReplanning = {};
        state.productsAfterUpdateLineItem = [];
        state.bundleProductAfterUpdateLineItem = [];
        state.consignmentDataAfterAddressUpdate = {};
        state.isPolled = false;
        state.orderCommentsAfterSubmit = {};
        state.areOrderCommentsAdded = false;
      })
      .addCase(resetBundleList.fulfilled, (state, action) => {
        state.bundleProductList = []
        state.crateInfo = []
        state.productList =[]
      })
      .addCase(resetStatusRefreshButton.fulfilled, (state, action) => {
        state.isPolled = false
      })
      .addCase(replanDuringPartialRejection.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(replanDuringPartialRejection.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.revisedAmountAfterCalculation = payload && payload.results.metadata && payload.results.metadata.collectedAmount ? payload.results.metadata.collectedAmount : "";
        state.productsAfterUpdateLineItem = payload && payload.results.products;
        state.bundleProductAfterUpdateLineItem = payload?.results?.bundleProducts?.length && transformBundleData(payload?.results?.bundleProducts)
        state.consignmentDataAfterReplanning = payload.results;
        state.replanningConsignmentSuccess = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(replanDuringPartialRejection.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.error.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.replanningConsignmentSuccess = false;
      })
      .addCase(getAddressStatus.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(getAddressStatus.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.consignmentDataAfterAddressUpdate = payload && payload.results;
        state.isAddressUpdated = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(getAddressStatus.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(submitOrderComments.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(submitOrderComments.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.orderCommentsAfterSubmit = payload?.results;
        state.areOrderCommentsAdded = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(submitOrderComments.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
  },
});

export default expressSlice.reducer;