const LoginConstants = {
  LOGIN_CONSTANTS: {
    VALIDATIONS: {
      EMAIL: {
        REQUIRED: {
          msg: "Email is required"
        },
        VALID_EMAIL: {
          msg: "Please enter a valid email"
        }
      },
      USERNAME: {
        REQUIRED: {
          msg: "Username is required"
        },
      },
      OLDPASSWORD: {
        REQUIRED: {
          msg: "Old Password is required"
        },
      },
      PASSWORD: {
        REQUIRED: {
          msg: "Password is required"
        },
        STRONG_PWD: {
          msg: "Password should be of minimum 8 characters length and should contain at least one Lowercase character, one Uppercase character, one Number and one Special character",
          value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
        },
        ONE_OF: {
          msg: "Passwords should be same"
        }
      },
      FIRSTNAME: {
        REQUIRED: {
          msg: "Firstname is required"
        }
      },
      LASTNAME: {
        REQUIRED: {
          msg: "Lastname is required"
        }
      },
      PHONE: {
        REQUIRED: {
          msg: "Phone Number is required"
        },
        VALIDITY: {
          msg: "Please enter a valid Phone number",
          value: "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$"
        }
      },
      ROLE: {
        REQUIRED: {
          msg: "Role is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      TYPE: {
        REQUIRED: {
          msg: "Type is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      COUNTRY: {
        REQUIRED: {
          msg: "Country is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      HUB: {
        REQUIRED: {
          msg: "Hub is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      OTP: {
        REQUIRED: {
          msg: "OTP is required"
        },
        VALIDITY: {
          msg: "Please enter a valid OTP",
          value: "^[0-9]{6}$",
        }
      }
    },
    ROLES: [
      {
        name: "Last Mile Dispatcher",
        value: "ROLE_LMD"
      },
      {
        name: "Last Mile Operations Manager",
        value: "ROLE_LMOM"
      },
      {
        name: "Customer Service",
        value: "ROLE_CS"
      }
    ],
    TYPES: [
      {
        name: "Staff",
        value: "STAFF"
      },
      {
        name: "Outsourced",
        value: "OUTSOURCED"
      }
    ],
    INCORRECT_UNAME_PWD_ERROR_MSG: "Incorrect Username or Password. Please try again",
    PWD_CHANGE_SUCCESS_MSG: "We have sent a reset password link to your email. Please check.",
    USER_CREATION_SUCCESS_MSG: "Account is created successfully and sent to admin for approval.",
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        CONFIRM_PWD: "UM102",
        ACCOUNT_DISABLED: "UM103",
        INVALID_UNAME: "UM104",
        PWD_EXP: "UM105",
        INVALID_PWD: "UM106",
        PWD_MISMATCH: "UM107"
      }
    },
    OTP_SENT_SUCCESSFULLY: "OTP Sent Successfully"
  }
}

export default LoginConstants;