import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import AppConstants from "../../../constants/AppConstants";
import networkHandlerDelve from "../../../network/networkHandlerDelve";

import { EApiMiddlewareMethods } from "../../../network//networkHandler.types";
import { commonFetchInitialState, DelveDashboardSliceState } from "../../../constants/DelveDashboardConstant";
import { API_RESPONSE_STATUS } from "../../../constants/CommonConstants";
import { isNull, isUndefined } from "../../../utils//helpers.utils";

export const getDelveDashboardRecord = createAsyncThunk("DelveDashboard/getDelveDashboardRecord", async (filters: any, { rejectWithValue }) => {
  try {
    const request = {
      url: "filter/orders-aggregate",
      method: EApiMiddlewareMethods.POST,
      data: filters,
    } as any;
    const { data } = await networkHandlerDelve(request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getConsignmentsDataBasedOnIssueName = createAsyncThunk("DelveDashboard/getConsignmentsDataBasedOnIssueName", async (filters: any, { rejectWithValue }) => {
  try {
    const request = {
      url: "filter/issues-consignment-list",
      method: EApiMiddlewareMethods.POST,
      data: filters,
    } as any;
    const { data } = await networkHandlerDelve(request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

const initialState: DelveDashboardSliceState = {
  delveDashboardRecod: { ...commonFetchInitialState },
  delveConsignmentsIssueName: { ...commonFetchInitialState },
};

const delveDashboardSlice = createSlice({
  name: "delveDashboard",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(getDelveDashboardRecord.pending, (state) => {
        state.delveDashboardRecod.loading = true;
        state.delveDashboardRecod.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.delveDashboardRecod.error = "";
        state.delveDashboardRecod.data = [];
      })
      .addCase(getDelveDashboardRecord.fulfilled, (state, action) => {
        state.delveDashboardRecod.loading = false;
        state.delveDashboardRecod.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.delveDashboardRecod.data = action.payload;
      })
      .addCase(getDelveDashboardRecord.rejected, (state, action) => {
        state.delveDashboardRecod.loading = false;
        state.delveDashboardRecod.fetchStatus = API_RESPONSE_STATUS.FAILED;
        const errorPayload: any = action.payload;
        if (!isUndefined(errorPayload) && !isUndefined(errorPayload.message) && !isNull(errorPayload.message) && errorPayload.message === "Request failed with status code 404") {
          state.delveDashboardRecod.error = "No Orders are present for this request";
        } else {
          state.delveDashboardRecod.error = errorPayload && errorPayload.message ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        }
      })
      /** getConsignmentsDataBasedOnIssueName */
      /** */
      .addCase(getConsignmentsDataBasedOnIssueName.pending, (state) => {
        state.delveConsignmentsIssueName.loading = true;
        state.delveConsignmentsIssueName.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.delveConsignmentsIssueName.error = "";
        state.delveConsignmentsIssueName.data = [];
      })
      .addCase(getConsignmentsDataBasedOnIssueName.fulfilled, (state, action) => {
        state.delveConsignmentsIssueName.loading = false;
        state.delveConsignmentsIssueName.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.delveConsignmentsIssueName.data = action.payload;
      })
      .addCase(getConsignmentsDataBasedOnIssueName.rejected, (state, action) => {
        state.delveConsignmentsIssueName.loading = false;
        state.delveConsignmentsIssueName.fetchStatus = API_RESPONSE_STATUS.FAILED;
        const errorPayload: any = action.payload;
        state.delveConsignmentsIssueName.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      });
  },
});

export default delveDashboardSlice.reducer;
export const selectAllDelveDashboardRecordListServices = (state: any) => state.delveDashboard.delveDashboardRecod;
