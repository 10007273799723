import React, {useMemo} from 'react';
import { Grid, Typography, SvgIcon, Box} from '@material-ui/core';
import { fixDecimals } from 'utils/helpers.utils';
import { ReactComponent as stack } from '../assets/icons/Stack.svg';
import { useStyles } from './Bundleproduct.styles';

interface BundleProductsProps {
  bundle: {
    bundleName: string;
    bundleShippedQty: number;
    bundleProductQuantity: number;
    bundlePrice: number;
    bundleRejectedQty: number;
  };
  bundleCurrency: string
}
const BundleProductsHeader = (props: BundleProductsProps) => {
  const classes = useStyles();
    const { bundle: { bundleName, bundleShippedQty, bundlePrice, bundleRejectedQty, bundleProductQuantity }, bundleCurrency } = props;
   

    const displayedOptions = useMemo(
      () => {
      return  [
        { key: 'name', value: bundleName , width: 4, class:'item1'},
        { key: 'rejectedQty', value: bundleRejectedQty || '-', width: 2, class:'item2' },
        { key: 'shippedQty', value: bundleShippedQty || 0, width: 2, class:'item2' },
        { key: 'quantity', value: bundleProductQuantity, width: 2 , class:'item2'},
        { key: 'price', value: bundlePrice, width: 2, class:'item3' }
      ];
      },
      [bundleName,bundleRejectedQty,bundleShippedQty, bundleProductQuantity, bundlePrice ],
    )

    const getItem =(key: string, value: string) =>{
      switch (key) {
        case 'price':
          return `${fixDecimals(2, value.toString())} ${bundleCurrency}`;
        case 'name':
           return <Box className={classes.itemHeader}><SvgIcon
           className="icon"
           component={stack}
         />
         <span>{value}</span></Box>
        case 'shippedQty':
          return <span className='green'>{value}</span>;
        default:
          return <span>{value}</span>;
      }
    }
    
    const getItemsDetail = () => {
      return (
      
          <Grid className="itemContainer" container>
            {displayedOptions?.length &&
              displayedOptions.map((item: any, index: any) => (
                <Grid
                  className={`itemColumn ${item.class} ${item.key}`}
                  item
                  key={index}
                  xs={item.width}
                >
                  <Typography>
                    {
                       getItem(item.key, item.value)
                    }
                  </Typography>
                </Grid>
              ))}
          </Grid>
       
      );
    };


    return (
        <>
        {getItemsDetail()}
        </>
    )

  }

  export default BundleProductsHeader;