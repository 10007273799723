
import {isEmpty} from 'lodash'; 

export interface CrateQtyAndLocationInfo {
    crateId: string;
    locationCode: string;
    quantitiesPerCrate?: number | null;
    scanned: boolean;
    productsCountPerCrate: number | null;
  }

  export interface CrateIdToQuantityMapValue extends Partial<AssociatedCrates> {
    quantitiesPerCrate: number;
  }

  export interface AssociatedCrates {
    crateId: string;
    scanned: boolean;
    quantity: number;
    locationCode: string;
    qcStatus: any;
    qcReports: any;
  }

  export function isProductHasAssociatedCrates(
    product: any,
  ): boolean {
    return (
      product.hasOwnProperty('associatedCrates') &&
      product.associatedCrates.length > 0
    );
  }

  export function checkIfSubstitutedProduct(product: any): boolean {
    return (
      product.hasOwnProperty('substitutedProducts') &&
      !isEmpty(product.substitutedProducts)
    );
  }

  export function getSubstitutedProductsField(
    product: any,
  ): any {
    return product.substitutedProducts?.[0];
  }

  export function isOrderHasAssociatedCrates(
    order: any
  ): boolean {
    return (
      order.hasOwnProperty('associatedCrates') &&
      order.associatedCrates.length > 0
    );
  }
  export function getShippedProduct(
    product: any,
  ): any{
    const isSubstituteProduct = checkIfSubstitutedProduct(product);
    if (isSubstituteProduct) {
      return getSubstitutedProductsField(product);
    } else {
      return product;
    }
  }
  export function processProductLevelCrateInfo(
    products: any,
  ): any {
    const crateIdToQuantityMap = new Map<string, CrateIdToQuantityMapValue>();
    const crateToSKUsMap = new Map<string, Set<string>>();
  
    products.forEach((product:any) => {
      const shippedProduct = getShippedProduct(product);
      const hasAssociatedCrates = isProductHasAssociatedCrates(shippedProduct);
  
      if (hasAssociatedCrates) {
        shippedProduct.associatedCrates.forEach((crate:any) => {
          const currentQuantity =
            crateIdToQuantityMap.get(crate.crateId)?.quantitiesPerCrate || 0;
  
          crateIdToQuantityMap.set(crate.crateId, {
            quantitiesPerCrate: currentQuantity + crate.quantity,
            locationCode: crate.locationCode,
            scanned: crate.scanned,
          });
  
          if (!crateToSKUsMap.has(crate.crateId)) {
            crateToSKUsMap.set(crate.crateId, new Set());
          }
  
          const SKUs = crateToSKUsMap.get(crate.crateId);
          SKUs?.add(product.sku);
        });
      }
    });
  
    return Array.from(crateIdToQuantityMap.entries()).map(
      ([crateId, {quantitiesPerCrate, locationCode, scanned}]) => ({
        crateId,
        quantitiesPerCrate,
        locationCode,
        productsCountPerCrate: crateToSKUsMap.get(crateId)?.size, // Getting the count of unique SKUs
        scanned,
      }),
    );
  }

  function processOrderLevelCrateInfo(
    order: any,
  ): CrateQtyAndLocationInfo[] {
    if (!isOrderHasAssociatedCrates(order)) {
      return [];
    }
  
    return order.associatedCrates.map(({crateId, scanned, locationCode}:CrateQtyAndLocationInfo) => {
      return {
        crateId,
        locationCode,
        scanned,
        quantitiesPerCrate: null,
      };
    });
  }

  export function getCrateDetails(
    order: any,
  ): CrateQtyAndLocationInfo[] {
    const orderLevelInfo = processOrderLevelCrateInfo(order);
    const products = order?.products || []
    const bundledProductsCombined = order?.bundleProducts?.flatMap(
      ({items}:any) => items || [],
    ) || [];
    const productLevelAndBundleItemLevelProducts = processProductLevelCrateInfo([
      ...products,
      ...bundledProductsCombined,
    ]);
    return [...orderLevelInfo, ...productLevelAndBundleItemLevelProducts];
  }