import moment from "moment";
import AppConstants from "../../constants";

export const createPerformancePayload = (payload: any) => {

    if (payload) {
        let dateRange = [];
        const selectAllIdx = payload.slots.indexOf(AppConstants.SELECT_ALL.value);
        if (payload.dateRange) {
            if (selectAllIdx > -1 || !payload.slots.length) {
                payload.isDateRange = true;
                dateRange.push({
                    startTime: moment(payload.dateRange.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
                    endTime: moment(payload.dateRange.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
                });
            }
            else {
                if (payload.slots.length && !payload.slots.includes(AppConstants.SELECT_NONE.value)) {
                    payload.isDateRange = false;
                    payload.slots.forEach((slot: any) => {
                        const splitSlot = slot.split('-');
                        const startSlot = splitSlot[0] && splitSlot[0].split(':');
                        const endSlot = splitSlot[1] && splitSlot[1].split(':');
                        dateRange.push({
                            startTime: moment(payload.dateRange.startDate, AppConstants.DATE_FORMAT).hours(startSlot[0]).minutes(startSlot[1]).seconds(startSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                            endTime: moment(payload.dateRange.endDate, AppConstants.DATE_FORMAT).hours(endSlot[0]).minutes(endSlot[1]).seconds(endSlot[2]).format(AppConstants.DATE_FORMAT_BACKEND),
                        })
                    })
                }
                else {
                    payload.isDateRange = false;
                    dateRange.push({
                        startTime: moment(payload.dateRange.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
                        endTime: moment(payload.dateRange.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
                    });
                }
            }
        }
        payload.dateRange = dateRange;
    }
    return {
        countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
        hubCode: payload.hubCode,
        orderType: payload.orderType,
        dateRange: payload.dateRange,
        isDateRange: payload.isDateRange
    };
};