import { createStyles, makeStyles, PopoverOrigin, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      "& .open": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderBottom: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    searchBox: {
      background: "white",
      overflowY: "auto",
      fontSize:'12px',
      "& .MuiOutlinedInput-notchedOutline": {
        borderBottomLeftRadius: "inherit !important",
        borderBottomRightRadius: "inherit !important",
        borderBottom: "ridge !important"
      }
    },
    customWidthSearch:{
      "& fieldset": {
        maxWidth:"170px",
      },
      "& .MuiIconButton-sizeSmall.MuiIconButton-edgeEnd":{
         marginRight:20
      }
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: 19,
      overflow: "auto",
    },
    chip: {
      margin: 2,
      height: 17,
      fontSize: 12,
    },
    menuPropsList: {
      "& .MuiListItem-root": {
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
      },
      "& .MuiCheckbox-root": {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75),
      },
    },
    menuPropsPaper: {
      maxHeight: 200,
      maxWidth: 200,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      overflow: "auto",
      boxShadow: "none",
    },
  })
);

export const anchorOriginDefault: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
}