import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rescheduleOrdersIconStyle: {
      fill: "transparent",
      width: "24px",
      height: "24px",
      cursor: "pointer",
      margin: "4px 0px auto 8px",
    },
    popperStyle: {
      fontSize: "10px",
      fontWeight: 500,
      color: COLORS.WHITE,
      width: "229px",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    arrowStyle: {
      color: COLORS.WHITE,
    },
    tooltipStyle: {
      background: COLORS.WHITE,
      color: COLORS.BLACK,
    },
    tooltipTitleSection: {
      display: "flex",
      flexDirection: "column",
      padding: "8px",
    },
    tooltipHeading: {
      fontSize: "12px",
      fontWeight: 600,
    },
    tooltipData: {
      fontSize: "10px",
      fontWeight: 400,
    },
  })
);
