import AppConstants from "./AppConstants";

export enum ACTION_BUTTON {
  BULK_DELETE ="bulkDelete"
}

const UsersConstants = {
  USERS_CONSTANTS: {
    TABLE_WRAPPER_DATA: {
      title: "Users",
      HeaderData: [
        {
          field: 'id',
          headerName: 'Id',
          hide: true
        },
        {
          field: 'type',
          headerName: 'Type',
          width: 150,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "type",
            fieldName: "type",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-type-${Math.floor(Math.random() * 1000)}`,
            label: "Type",
            items: [],
          }
        },
        {
          field: 'firstname',
          headerName: 'First Name',
          width: 150
        },
        {
          field: 'lastname',
          headerName: 'Last Name',
          width: 150
        },
        {
          field: 'userId',
          headerName: 'User Id/ Email',
          width: 180
        },
        {
          field: 'country',
          headerName: 'Country',
          width: 150
        },
        {
          field: 'hubName',
          headerName: 'Hub',
          width: 150,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "hubName",
            fieldName: "hub",
            fieldFullName: "c.posInformation.store",
            multiple: true,
            key: `autoComplete-hub-${Math.floor(Math.random() * 1000)}`,
            label: "Hub",
            items: []
          }
        },
        {
          field: 'phone',
          headerName: 'Phone No.',
          width: 150
        },
        {
          field: 'buildVersion',
          headerName: 'App Version',
          width: 150,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "buildVersion",
            fieldName: "buildVersion",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-buildVersion-${Math.floor(Math.random() * 1000)}`,
            label: "buildVersion",
            items: [],
          }
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 170,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "status",
            fieldName: "status",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-status-${Math.floor(Math.random() * 1000)}`,
            label: "Status",
            items: [],
          }
        },
        {
          field: 'role',
          headerName: 'Role',
          width: 170,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "role",
            fieldName: "role",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-role-${Math.floor(Math.random() * 1000)}`,
            label: "Role",
            items: [],
          }
        },
        {
          field: 'vehicleType',
          headerName: 'Vehicle Type',
          width: 150,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "vehicleType",
            fieldName: "vehicleType",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-vehicleType-${Math.floor(Math.random() * 1000)}`,
            label: "Vehicle Type",
            items: [],
          }
        },
        {
          field: 'vendor',
          headerName: 'Vendor',
          width: 150,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "vendor",
            fieldName: "vendor",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-vendor-${Math.floor(Math.random() * 1000)}`,
            label: "Vendor",
            items: [],
          }
        },
        {
          field: 'deliveryType',
          headerName: 'Delivery Type',
          width: 150,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "deliveryType",
            fieldName: "deliveryType",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-deliveryType-${Math.floor(Math.random() * 1000)}`,
            label: "Delivery Type",
            items: [],
          }
        },
        {
          field: 'serviceType',
          headerName: 'Service Type',
          width: 170,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "serviceType",
            fieldName: "serviceType",
            fieldFullName: "",
            multiple: true,
            key: `autoComplete-serviceType-${Math.floor(Math.random() * 1000)}`,
            label: "Service Type",
            items: [],
          }
        },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 100,
          hide: false
        }
      ],
      headerButtons: [
        {
          name: ACTION_BUTTON.BULK_DELETE,
          displayName: "",
          disabled: false,
          classes: 'primary',
          hide: false
        },
        {
          name: "addUser",
          displayName: "Add New User",
          disabled: false,
          classes: 'primary',
          hide: false
        },
        {
          name: AppConstants.BUTTONS.DOWNLOAD_ICON,
          displayName: "",
          disabled: false,
          classes: 'primary',
          hide: false
        }
      ],
      loadMore: {
        name: 'loadMore',
        disabled: false,
        pageSize: 20
      },
      defaultFiltersObj: {
        hub: {
          key: "hub",
          value: [],
          fieldName: 'c.posInformation.store'
        },
        role: {
          key: "role",
          value: [],
          fieldName: ''
        },
        buildVersion:{
          key: "buildVersion",
          value: [],
          fieldName: ''
        },
        status: {
          key: "status",
          value: [],
          fieldName: ''
        },
        type: {
          key: "type",
          value: [],
          fieldName: ''
        },
        vendor: { key: "type", value: [], fieldName: 'vendor' },
        deliveryType: { key: "type", value: [], fieldName: 'deliveryType' },
        vehicleType: { key: "type", value: [], fieldName: 'vehicleType' },
        serviceType: { key: "type", value: [], fieldName: 'serviceType' },
        page: 0
      },
      SEARCH_TYPES: [
        {
          name: "First Name",
          value: "firstname"
        },
        {
          name: "Last Name",
          value: "lastname"
        },
        {
          name: "Email Id",
          value: "email"
        }
      ],
      sortModel: {
        field: "email",
        direction: "asc"
      }
    },
    VALIDATIONS: {
      FIRSTNAME: {
        REQUIRED: {
          msg: "Firstname is required"
        }
      },
      LASTNAME: {
        REQUIRED: {
          msg: "Lastname is required"
        }
      },
      PHONE: {
        REQUIRED: {
          msg: "Phone Number is required"
        },
        VALIDITY: {
          msg: "Please enter a valid Phone number",
          value: "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$",
          lengthIssue: "Please enter a valid phone number between 6 to 10 digits"
        }
      },
      STATUS: {
        REQUIRED: {
          msg: "Status is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      ROLE: {
        REQUIRED: {
          msg: "Role is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      TYPE: {
        REQUIRED: {
          msg: "Type is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      DELIVERY_TYPE: {
        REQUIRED: {
          msg: "Delivery Type is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      VEHICLE: {
        REQUIRED: {
          msg: "Vehicle Type is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      VENDOR: {
        REQUIRED: {
          msg: "Vendor is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      COUNTRY: {
        REQUIRED: {
          msg: "Country is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      HUB: {
        REQUIRED: {
          msg: "Hub is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
       PREFIX: {
        REQUIRED: {
          msg: "Phone prefix is required",
          value: "\\b(?![n\\/a]\\b)\\w+",
        },
      },
    },
    STATUSES: {
      "CREATED": {
        color: "black",
        name: "Created",
        tooltip: "User created by admin"
      },
      "ACTIVE": {
        color: "green",
        name: "Active",
        tooltip: "User approved by admin"
      },
      "INACTIVE": {
        color: "red",
        name: "Inactive",
        tooltip: "User is deactivated by admin"
      },
      "REJECTED": {
        color: "gray",
        name: "Rejected",
        tooltip: "User request is rejected by admin"
      },
      "AWAITING_APPROVAL": {
        color: "yellow",
        name: "Pending Approval",
        tooltip: "User approval is pending at admin end"
      },
      "AWAITING_EMAIL_VERIFICATION": {
        color: "yellow",
        name: "Pending Email Verification",
        tooltip: "User email verification is pending at user end"
      }
    },
    USER_ROLES: [
      {
        code: "ROLE_SUPER_ADMIN",
        value: "Super Admin"
      },
      {
        code: "ROLE_APP_ADMIN",
        value: "Application Admin"
      },
      {
        code: "ROLE_COUNTRY_ADMIN",
        value: "Country Admin"
      },
      {
        code: "ROLE_LAST_MILE_DRIVER",
        value: "Driver"
      },
      {
        code: "ROLE_LAST_MILE_HELPER",
        value: "Helper"
      },
      {
        code: "ROLE_LAST_MILE_DISPATCHER",
        value: "Last Mile Dispatcher"
      },
      {
        code: "ROLE_LAST_MILE_OPERATIONS_MANAGER",
        value: "Last Mile Operations Manager"
      },
      {
        code: "ROLE_CUSTOMER_SERVICE",
        value: "Customer Service"
      },
      {
        code: "ROLE_COLLECTION_POINT_MANAGER",
        value: "Collection Point Manager"
      },
      {
        code: "ROLE_STANDARD_VALET_TROLLEY_MANAGER",
        value: "Standard Valet Trolley Manager"
      },
      {
        code: "ROLE_CARREFOUR_HOME_DELIVERY_MANAGER",
        value: "Carrefour Home Delivery Manager"
      },
      {
        code: "ROLE_LAST_MILE_QC_MANAGER",
        value: "QC Manager"
      },
      {
        code: "ROLE_LAST_MILE_QC_EXECUTIVE",
        value: "QC Executive"
      },
      {
        code: "ROLE_LAST_MILE_VIEWER",
        value: "Last Mile Viewer"
      }
    ],
    READ_ONLY_ROLE: "LAST_MILE_VIEWER",
    READ_ONLY_ROLE_STANDARD: "LAST_MILE_VIEWER_STANDARD",
    READ_ONLY_ROLE_EXPRESS: "LAST_MILE_VIEWER_EXPRESS",
    DISPATCHER_ROLE: "LAST_MILE_DISPATCHER",
    DISPATCHER_ROLE_EXP: "LAST_MILE_DISPATCHER_EXPRESS",
    DISPATCHER_ROLE_STD: "LAST_MILE_DISPATCHER_STANDARD",
    COLLECTION_POINT_MANAGER: [
      {
        code: "ROLE_COLLECTION_POINT_MANAGER",
        value: "Collection Point Manager"
      }
    ],
    VALET_TROLLEY_MANAGER: [
      {
        code: "ROLE_STANDARD_VALET_TROLLEY_MANAGER",
        value: "Standard Valet Trolley Manager"
      }
    ],
    CARREFOUR_DELIVERY_MANAGER: [
      {
        code: "ROLE_CARREFOUR_HOME_DELIVERY_MANAGER",
        value: "Carrefour Home Delivery Manager"
      }
    ],
    QC_MANAGER: [
      {
        code: "ROLE_LAST_MILE_QC_MANAGER",
        value: "QC Manager"
      }
    ],
    QC_EXECUTIVE: [
      {
        code: "ROLE_LAST_MILE_QC_EXECUTIVE",
        value: "QC Executive"
      }
    ],
    DRIVER: [
      {
        code: "ROLE_LAST_MILE_DRIVER",
        value: "Driver"
      }
    ],
    ADMIN_ROLES: [
      {
        code: "ROLE_SUPER_ADMIN",
        value: "Super Admin"
      },
      {
        code: "ROLE_APP_ADMIN",
        value: "Application Admin"
      },
      {
        code: "ROLE_COUNTRY_ADMIN",
        value: "Country Admin"
      }
    ],
    EXPRESS: "EXPRESS",
    DRIVER_ROLE: "ROLE_LAST_MILE_DRIVER",
    LAST_MILE_DISPATCHER_ROLE: "ROLE_LAST_MILE_DISPATCHER",
    LAST_MILE_VIEWER_ROLE: "ROLE_LAST_MILE_VIEWER",
    DRIVER_OR_VEHICLE_ROLES: [
      {
        code: "ROLE_LAST_MILE_DRIVER",
        value: "Driver"
      },
      {
        code: "ROLE_LAST_MILE_HELPER",
        value: "Helper"
      },
    ],
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
      },
      USER_UPDATE_SUCCESS_MSG: "has been approved successfully!",
      USER_UPDATE_REJECT_MSG: "has been rejected."
    },
    USERNAME_USERTYPE: "External",
    RELEASE_ASSIGNEE_CONTENT: "This will result in clearing the assigned slots to the driver / helper.Please confirm to proceed.",
    END_ACTIVE_SESSION_CONTENT: "Ending a session will result in losing all unsaved data from the device. Please confirm to proceed.",
    END_SESSION: "END SESSION",
    LAST_MILE_QC_EXECUTIVE_ROLE: "ROLE_LAST_MILE_QC_EXECUTIVE",
    USER_ROLE_TYPE_ERROR : "Only Drivers or QC Executives roles can be External User.",
    POP_UP_MESSAGE_CONSTANT: {
      DELETE_USER_RECORD: 'Delete User Data',
      DOWNLOAD_USER_INFORMATION_CONFIRMATION : 'Are you sure you want to delete the user data ? This action cannot be undone.'
    },
    ROLE_LAST_MILE_HELPER: 'ROLE_LAST_MILE_HELPER',
    USER_TYPE: {
      INTERNAL: 'Internal',
      PORTAL_USER: 'Portal User',
      EXTERNAL: 'External',
      APP_USER: 'App User'
    },
    SERVICE_TYPE:{
      STANDARD: "STANDARD",
      EXPRESS: "EXPRESS",
    },
  },
}

export default UsersConstants;