import moment from "moment";
import AppConstants from "../../constants";

export const createCodReconciliationPayload = (payload: any) => {
  return {
    countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
    startDate: moment(payload.date.startDate, AppConstants.DATE_FORMAT)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format(AppConstants.DATE_FORMAT_BACKEND),
    endDate: moment(payload.date.endDate, AppConstants.DATE_FORMAT)
      .hours(23)
      .minutes(59)
      .seconds(59)
      .format(AppConstants.DATE_FORMAT_BACKEND),
  };
};
