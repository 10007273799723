import { Snackbar, SnackbarCloseReason, SnackbarProps } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';
import AppConstants from '../../constants';

interface CustomSnackbarProps extends SnackbarProps {
  message: string;
  type?: any;
  handleClose: (event: React.SyntheticEvent<Element, Event>, reason?: SnackbarCloseReason) => void;
  action?: () => void;
  actionText?: string;
}

const CustomSnackbar = (props: CustomSnackbarProps) => {

  const { actionText, type, message, handleClose, action, ...rest } = props;
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Snackbar
      {...rest}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type || AppConstants.SNACKBAR.TYPES.SUCCESS}>
        {message}
        {action && <span style={{marginLeft:'10px', fontWeight:'bold', cursor:'pointer'}} onClick={action}>{actionText}</span>}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar;