import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%"
    },
    headerSection: {},
    footerSection: {
      position: "fixed",
      bottom: 35,
      left: "calc(50% - 88px)"
    },
    noRowsOverlaySection: {
      '&:hover': {
        backgroundColor: "unset"
      },
      '& .MuiTableCell-root': {
        border: "none"
      },
      '& .overlayContainer': {
        alignItems: "center",
        justifyContent: "center",
        '& p': {
          fontSize: 16,
          fontWeight: 600
        }
      }
    },
    tableContainer: {
      height: "calc(100% + 29px)"
    },
    newTableDashboard: {
      height: "calc(100% + 70px)"
    },
    tableDashboardWithTab: {
      height: "calc(100% - 35px)"
    }
  })
);
