export enum TextConstants {
  EDIT_CONSIGNMENT_TITLE = "EDIT CONSIGNMENT : ",
  UPDATE_CONSIGNMENT_STATUS = "Update Status",
  REASON = "Reason",
  COMMENTS = "Comments",
  CONSIGNMENT_WARNING = "This consignment will no longer be delivered",
  REJECT_ITEMS_TITLE = "Edit Rejected Items",
  REJECT_ITEMS_CONTENT = "You will not be able to edit the order details after this status change.",
  REJECT_ITEMS_CONTENT_DESC = "Please go ahead and make changes to the item summary if you have any",
  STORES_LABEL = "Stores",
  DATE_LABEL = "Date",
  VALID_END_TIME = "Please enter valid end time",
  ADD_COMMENT = "Add a new comment",
  ADDITIONAL_INFO = "Additional information",
  ORDERS_VERIFIED = "Orders Verified (% of Total)",
  ORDERS_WITH_ISSUES = "Orders with Issues (% of Verified)",
  COLLECTED = "Collected",
  COLLECT = "Collect",
  TOTAL_COD_ORDERS = "Total COD orders",
  TOTAL_ORDERS_AMOUNT = "Total Orders amount",
  TOTAL_COD_COLLECTED = "Total COD collected",
  COD_AMOUNT_RECEIVED = "COD amount received",
  TOTAL_COD_AMOUNT = "Total COD amount",
  REASON_ATTEMPTED_DELIVERY = "Reason for Attempted delivery",
  CONSIGNMENT_VERIFIED = "Consignment Verified (% of Total)",
  CONSIGNMENT_WITH_ISSUES = "Consignment with Issues (% of Verified)",
}
