// import { SortDirection } from "@material-ui/data-grid";

const ClickAndCollectConstants = {
    CNC_CONSTANTS: {
        CONSIGNMENTS_DATA: {
            TABLE_WRAPPER_DATA: {
                title: "Orders",
                HeaderData: [
                    {
                        field: 'collectionHubCode',
                        headerName: 'Collection Hub Code',
                        width: 220,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "collectionHubCode",
                            fieldName: "collectionHubCode",
                            fieldFullName: "c.pickUpInformation.pickUpContactInfo.companyName",
                            multiple: true,
                            key: `autoComplete-hub-${Math.floor(Math.random() * 1000)}`,
                            label: "Hub Code",
                            items: []
                        }
                    },
                    {
                        field: 'collectionHubName',
                        headerName: 'Collection Hub Name',
                        width: 220,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "collectionHubName",
                            fieldName: "hubName",
                            fieldFullName: "c.pickUpInformation.pickUpContactInfo.name",
                            multiple: true,
                            key: `autoComplete-hubName-${Math.floor(Math.random() * 1000)}`,
                            label: "Hub Name",
                            items: []
                        }
                    },
                    {
                        field: 'consignmentId',
                        headerName: 'Consignment ID',
                        width: 150,
                    },
                    {
                        field: 'deliveryStatus',
                        headerName: 'Status',
                        width: 170,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "deliveryStatus",
                            fieldName: "deliveryStatus",
                            fieldFullName: "c.metadata.deliveryStatus",
                            multiple: true,
                            key: `autoComplete-delStatus-${Math.floor(Math.random() * 1000)}`,
                            label: "Delivery Status",
                            items: []
                        }
                    },
                    {
                        field: 'orderId',
                        headerName: 'Order ID',
                        width: 150,
                    },
                    {
                        type: "date",
                        field: 'transactionDate',
                        headerName: 'Order Date',
                        width: 120,
                    },
                    {
                        field: 'type',
                        headerName: 'Order Type',
                        width: 160,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "type",
                            fieldName: "orderType",
                            fieldFullName: "c.metadata.category",
                            multiple: true,
                            key: `autoComplete-orderType-${Math.floor(Math.random() * 1000)}`,
                            label: "Order Type",
                            items: [],
                        }
                    },
                    {
                        type: "date",
                        field: 'collectionDate',
                        headerName: 'Collection Date',
                        width: 170,
                        filter: true,
                        filterObj: {
                            type: "dateRange",
                            fieldKey: "collectionDate",
                            fieldName: "dateRange",
                            key: `dateRange-filter-${Math.floor(Math.random() * 1000)}`,
                            placeholderText: 'Collection Date',
                            label: 'Collection Date',
                            autoUpdateInput: false,
                            autoApply: true,
                            showDropdowns: true,
                            linkedCalendars: true,
                            maxSpan: {
                                month: "1"
                            },
                            locale: {},
                        }
                    },
                    {
                        field: 'deliverySlot',
                        headerName: 'Collection Slot',
                        width: 170,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldName: "deliverySlot",
                            multiple: true,
                            key: `autoComplete-deliverySlot-${Math.floor(Math.random() * 1000)}`,
                            label: "Collection Slot",
                            items: [],
                            fieldKey: "startEndTime",
                        }
                    },
                    {
                        field: 'customerName',
                        headerName: 'Customer Name',
                        width: 160
                    },
                    {
                        field: 'customerEmail',
                        headerName: 'Customer Email',
                        width: 160
                    },
                    {
                        field: 'customerMobileNumber',
                        headerName: 'Customer Mobile Number',
                        width: 220
                    },
                    {
                        field: 'hubCode',
                        headerName: 'Fulfilment Hub Code',
                        width: 220,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "hub",
                            fieldName: "hub",
                            fieldFullName: "c.posInformation.store",
                            multiple: true,
                            key: `autoComplete-hub-${Math.floor(Math.random() * 1000)}`,
                            label: "Hub Code",
                            items: []
                        }
                    },
                    {
                        field: 'hubName',
                        headerName: 'Fulfilment Hub Name',
                        width: 220,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "hubName",
                            fieldName: "hubName",
                            fieldFullName: "c.posInformation.storeName",
                            multiple: true,
                            key: `autoComplete-hub-${Math.floor(Math.random() * 1000)}`,
                            label: "Hub Name",
                            items: []
                        }
                    },
                    {
                        type: 'date',
                        field: 'statusDate',
                        headerName: 'Last Updated',
                        width: 150
                    },
                    {
                        field: 'numberOfItems',
                        headerName: 'No of Items',
                        width: 150
                    },
                    {
                        field: 'paymentType',
                        headerName: 'Payment Type',
                        width: 160,
                    },
                    {
                        field: 'cancellationReason',
                        headerName: 'Cancellation Reason',
                        width: 200,
                        filter: true,
                        filterObj: {
                            type: "autocomplete",
                            fieldKey: "cancellationReason",
                            fieldName: "cancellationReason",
                            fieldFullName: "c.reason.reasonCode",
                            multiple: true,
                            key: `autoComplete-cancellationReason-${Math.floor(Math.random() * 1000)}`,
                            label: "Cancellation Reason",
                            items: []
                        }
                    },
                ],
                headerButtons: [
                    {
                        name: "colsettings",
                        displayName: "Column Settings",
                        disabled: false,
                        childElements: [
                            {
                                name: "excel",
                                displayName: "EXPORT DATA",
                                disabled: false
                            }
                        ]
                    }
                ],
                loadMore: {
                    name: 'loadMore',
                    disabled: false,
                    pageSize: 20
                },
                defaultFiltersObj: {
                    pageNumber: 0,
                    hub: {
                        key: "hub",
                        value: [],
                        fieldName: 'c.posInformation.store'
                    },
                    hubName: {
                        key: "hubName",
                        value: [],
                        fieldName: 'c.posInformation.storeName'
                    },
                    deliveryStatus: {
                        key: "deliveryStatus",
                        value: [],
                        fieldName: 'c.metadata.deliveryStatus'
                    },
                    collectionHubCode: {
                        key: "collectionHubCode",
                        value: [],
                        fieldName: 'c.pickUpInformation.pickUpContactInfo.companyName'
                    },
                    collectionHubName: {
                        key: "collectionHubName",
                        value: [],
                        fieldName: 'c.pickUpInformation.pickUpContactInfo.name'
                    },
                    modeOfCollection: {
                        key: "modeOfCollection",
                        value: ['CNC'],
                        fieldName: 'c.metadata.modeOfCollection',
                        ignoreCount:true
                    },
                    deliveryType: {
                        key: "deliveryType",
                        value: ['SCC'],
                        fieldName: 'c.metadata.deliveryType',
                        ignoreCount:true
                    },
                    displayRouteStatus: {
                        key: "displayRouteStatus",
                        value: ['NEW'],
                        fieldName: 'c.metadata.displayRouteStatus',
                        ignoreCount:true
                    },
                    orderType: {
                        key: "orderType",
                        value: [],
                        fieldName: 'c.metadata.category'
                    },
                    dateRange: {
                        key: "dateRange",
                        value: {
                            startDate: new Date(),
                            endDate: new Date()
                        }
                    },
                    deliverySlot: {
                        key: "deliverySlot",
                        value: []
                    },
                    cancellationReason: {
                        key: "cancellationReason",
                        value: [],
                        fieldName: 'c.reason.reasonCode'
                    },
                },
                SEARCH_TYPES: [
                    {
                        name: "Consignment Id",
                        value: "c.consignmentCode"
                    },
                    {
                        name: "Order Id",
                        value: "c.orderCode"
                    },
                    {
                        name: "Name",
                        value: "c.deliveryInformation.customerContactInfo.name"
                    },
                    {
                        name: "Email",
                        value: "c.deliveryInformation.customerContactInfo.email"
                    },
                    {
                        name: "Phone",
                        value: "c.deliveryInformation.customerContactInfo.phone"
                    },
                ],
                sortModel: {
                    field: "deliveryDate",
                    direction: "asc"
                }
            },
            FILTER_CHIPS: [
                {
                    code: 'NEW',
                    value: 'New'
                },
                {
                    code: 'PACKED',
                    value: 'Packed'
                },
                {
                    code: 'READY_TO_COLLECT',
                    value: 'Ready'
                },
                {
                    code: 'COLLECTED',
                    value: 'Collected'
                },
                {
                    code: 'UNCOLLECTED',
                    value: 'Uncollected'
                },
                {
                    code: 'CANCELLED',
                    value: 'Cancelled'
                },
                {
                    code: "ALL",
                    value: "All"
                }
            ],
            STATUSES: {
                "NEW": {
                    color: "PICTON_BLUE",
                    hintText: ""
                },
                "PACKING_COMPLETE": {
                    color: "MINSK",
                    hintText: ""
                },
                "READY_TO_COLLECT": {
                    color: "AMBER",
                    hintText: ""
                },
                "COLLECTED": {
                    color: "SEA_GREEN",
                    hintText: ""
                },
                "UNCOLLECTED": {
                    color: "ERROR_RED",
                    hintText: ""
                },
                "COLLECTION_FAILED": {
                    color: "ERROR_RED",
                    hintText: ""
                },
                "PREPARED": {
                    color: "MADISON_BLUE",
                    hintText: ""
                },
                "CANCELLED": {
                    color: "DUSTY_GRAY",
                    hintText: ""
                }
            },
            TERMINAL_STATUSES: ['COLLECTION_FAILED', 'CANCELLED', 'COLLECTED'],
            NON_EDITABLE_STATUSES: ['COLLECTION_FAILED', 'CANCELLED', 'COLLECTED'],
            CANCELLED: 'CANCELLED',
            DEFAULT_FILTER_CHIP: 'NEW',
            REPLANNING_CONSIGNMENT_SUCCESS_MSG: 'Consignment updated successfully.',
            DEFAULT_REPLANNING_SELECTION: 'reschedule',
            COD_TEXT: "COD",
            DISPLAY_ROUTE_STATUS_FILTER_OBJ: {
                key: "displayRouteStatus",
                value: ['NEW'],
                fieldName: 'c.metadata.displayRouteStatus'
            },
        },
        RESPONSE_CONSTANTS: {
            ERROR_CODES: {
                SESSION_TIMEOUT: "UM100",
                UNAUTHORISED: "UM101",
                CONCURRENT_CONSIGNMENT: "CWO15"
            }
        },
        ALL_FILTER_CHIP: "ALL"
    },
}

export default ClickAndCollectConstants;