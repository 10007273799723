import React from "react";

import { FormControl, InputLabel } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import AppConstants from "../../constants/AppConstants";

import { useStyles } from "./CustomTimePickerStyles";
import { isNull, isUndefined } from "../../utils/helpers.utils";

import Clock from "../../assets/icons/clock.svg";

interface CustomTimePickerInterface {
  keyId: any;
  label: any;
  name: any;
  value: any;
  handleChange: any;
  ampmBoolean: any;
  placeholder?: any;
  fullWidth?: boolean;
}

const CustomTimePicker = (props: CustomTimePickerInterface) => {
  const { keyId, label, name, placeholder, handleChange, fullWidth, ampmBoolean, value } = props;

  const classes = useStyles();

  const handleTimeChange = (time: any) => {
    let removedSecTime = !isUndefined(time) && !isNull(time) ? new Date(time.setSeconds(0, 0)) : time;
    handleChange(removedSecTime);
  };

  return (
    <FormControl className={`${classes.formControl} ${label ? "hasLabel" : ""}`} variant="outlined" fullWidth={fullWidth}>
      {label && (
        <InputLabel htmlFor={keyId} shrink>
          {label}
        </InputLabel>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          {...props}
          name={name}
          label=""
          margin="none"
          variant="dialog"
          inputVariant="outlined"
          value={value}
          onChange={handleTimeChange}
          ampm={!isUndefined(ampmBoolean) ? ampmBoolean : true}
          placeholder={!isUndefined(placeholder) ? placeholder : AppConstants.SLOT_FORMAT_UI}
          keyboardIcon={<img src={Clock} alt="timer" />}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

export default CustomTimePicker;
