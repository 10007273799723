import React from "react";

//Material UI Components
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Grid, Typography } from "@material-ui/core";
//Style
import { progressStyles } from "./HubsRowDetails.styles";

//Assets
import SlotOpenDropDown from "../../assets/icons/SlotOpenDropDown.svg";
import SlotOpenDropDownOpen from "../../assets/icons/SlotOpenDropDownOpen.svg";

interface HubsRowProps {
  classes: any;
  hubData: any;
  hubName: any;
  hubCode: any;
  hubDataIndex: any;
}


const HubsRow = (props: HubsRowProps) => {
  const { classes, hubData, hubName, hubCode } = props;
  const [open, setOpen] = React.useState(false);
  const progressClasses = progressStyles();
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.gridRow}
      >
        <TableCell
          component="th"
          padding="none"
          scope="row"
          className={hubCode === "All" ? classes.tableDataFirstRow : classes.tableDataRowCell}
          style={{
            minWidth: "320px",
            maxWidth: "320px",
          }}
        >
          {hubName}
        </TableCell>

        <TableCell className={hubCode === "All" ? classes.tableDataFirstRow : classes.tableDataRowCell} padding="none">
          {hubData && hubData.totalConsignments}
        </TableCell>
        <TableCell className={hubCode === "All" ? classes.tableDataFirstRow : classes.tableDataRowCell} padding="none">
          <Grid container>
            <Grid item xs={2}>{(hubData && hubData.globalStateCount && hubData.globalStateCount['OnTime Handover']) || 0}</Grid>
            <Grid item xs={2} className={classes.handoverPercent}>{(hubData && hubData.handOverOnTimePercentage) || 0}</Grid>
          </Grid>
        </TableCell>
        <TableCell className={hubCode === "All" ? classes.tableDataFirstRow : classes.tableDataRowCell} padding="none">
          <Grid container>
            <Grid item xs={2}>{(hubData && hubData.globalStateCount && hubData.globalStateCount['OnTime']) || 0}</Grid>
            <Grid item xs={2} className={classes.ontimePercent}>{(hubData && hubData.onTimePercentage) || 0}</Grid>
          </Grid>
        </TableCell>
        <TableCell className={hubCode === "All" ? classes.tableDataFirstRow : classes.tableDataRowCell} padding="none">
          <Grid container>
            <Grid item>
              {(hubData && hubData.globalStateCount && hubData.globalStateCount['At Risk']) || 0}</Grid>
          </Grid>
        </TableCell>
        <TableCell className={hubCode === "All" ? classes.tableDataFirstRow : classes.tableDataRowCell} padding="none" style={{
          minWidth: "100px",
          maxWidth: "100px",
        }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ?
              <img src={SlotOpenDropDownOpen} className={classes.SlotOpenDropDownStyle} alt="dropdown-open" /> :
              <img src={SlotOpenDropDown} className={classes.SlotOpenDropDownStyle} alt="dropdown-close" />}
          </IconButton>
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell className={classes.collapseTableCell} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapseTableCellDiv}>
            <Box margin={0}>
              <Table padding="none" size="small" stickyHeader={true} style={{ width: "100%", maxWidth: "100%" }}>
                <TableHead>
                  <TableRow
                    className={classes.collapseTable} style={{ background: "#ECECEC", height: "88px" }}
                  >
                    <TableCell
                      component="th"
                      padding="none"
                      scope="row"
                      className={classes.tableDataRowCell}
                      style={{
                        minWidth: "240px",
                        maxWidth: "240px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          < Typography className={classes.statusTitle}>Unplanned</Typography>
                          <Typography className={classes.statusData}>
                            {(hubData && hubData.statusWise && hubData.statusWise.Unplanned) || 0}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.statusTitle}>Published</Typography>
                          <Typography className={classes.statusData}>
                            {(hubData && hubData.statusWise && hubData.statusWise['Planned/Published']) || 0}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.statusTitle}>Preparing</Typography>
                          <Typography className={classes.statusData}>
                            {(hubData && hubData.statusWise && hubData.statusWise['Preparing/Prepared']) || 0}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.statusTitle}>Out for Delivery</Typography>
                          <Typography className={classes.statusData}>
                            {(hubData && hubData.statusWise && hubData.statusWise['Out for Delivery']) || 0}</Typography>
                        </Grid>

                      </Grid>
                    </TableCell>

                    <TableCell className={classes.tableDataRowCell} padding="none" style={{
                      minWidth: "240px",
                      maxWidth: "240px",
                    }}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography className={classes.statusTitle}>Delivered</Typography>
                          <Typography className={classes.statusData}>
                            {(hubData && hubData.statusWise && hubData.statusWise.Delivered) || 0}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          < Typography className={classes.statusTitle}>Un-delivered</Typography>
                          <Typography className={classes.statusData}>
                            {(hubData && hubData.statusWise && hubData.statusWise['Un-Delivered']) || 0}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography className={classes.statusTitle}>Cancelled</Typography>
                          <Typography className={classes.statusData}>
                            {(hubData && hubData.statusWise && hubData.statusWise.Cancelled) || 0}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.tableDataRowCell} padding="none">
                      <Grid item>
                        <Typography>
                          <LinearProgressWithLabelPro
                            classes={progressClasses} value={(hubData && hubData.handOverOnTimePercentage) || 0} />
                        </Typography>
                        <Typography className={classes.progressText}>
                          On time handover v/s Handed over
                          </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.tableDataRowCell} padding="none">
                      <Grid item>
                        <Typography>
                          <LinearProgressWithLabel
                            classes={progressClasses} value={(hubData && hubData.onTimePercentage) || 0} />
                        </Typography>
                        <Typography className={classes.progressText}>
                          On time delivery v/s Delivered consignments
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.tableDataRowCell} padding="none">
                      <Grid item>
                        <Typography>
                          <LinearProgressWithLabelPro
                            classes={progressClasses} value={(hubData && hubData.onTimePercentageWithAllConsignments) || 0} />
                        </Typography>
                        <Typography className={classes.progressText}>
                          On time delivery v/s All consignments
                          </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.tableDataRowCell} padding="none">
                      <Grid item>
                        <Typography>
                          <LinearProgressWithLabel
                            classes={progressClasses} value={(hubData && hubData.onTimePercentageWithAttemptedConsignments) || 0} />
                        </Typography>
                        <Typography className={classes.progressText}>
                          On time delivery v/s All attempted order
                          </Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow >
    </React.Fragment>
  );
};

function LinearProgressWithLabelPro(props: any) {
  const { value, classes } = props;
  return (
    <Box display="flex" alignItems="center" className={classes.linearProgress}>
      <Box width="70%">
        <Grid container>
          <Grid item xs={10} style={{ marginTop: "auto", marginBottom: "auto" }}>
            <LinearProgress
              classes={{
                root: classes.linearProgressRoot,
                bar: classes.linearProgressBarRoot,
                colorPrimary: classes.linearProgressColorDefault,
                barColorPrimary: classes.linearProgressBarColorInProgress,
              }}
              variant="determinate"
              value={parseFloat(value)}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            {value || "0"}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function LinearProgressWithLabel(props: any) {
  const { value, classes } = props;
  return (
    <Box display="flex" alignItems="center" className={classes.linearProgress}>
      <Box width="70%">
        <Grid container>
          <Grid item xs={10} style={{ marginTop: "auto", marginBottom: "auto" }}>
            <LinearProgress
              classes={{
                root: classes.linearProgressRoot,
                bar: classes.linearProgressBarRoot,
                colorPrimary: classes.linearProgressColorDefault,
                barColorPrimary: classes.linearProgressBarColor,
              }}
              variant="determinate"
              value={parseFloat(value)}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            {value || "0"}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HubsRow;
