import React, { forwardRef, ReactElement, Ref, useCallback, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import * as yup from 'yup';
import { useStyles } from './UpdateCustomerAddress.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography } from '@material-ui/core';
import AppConstants from '../constants';
import { useFormik } from 'formik';
import TextBox from '../common/TextBox';

interface UpdateCustomerAddressProps {
  open: boolean;
  custInfo: any;
  consignment: any;
  closePopup: () => void;
  handleDialogAction?: (params: any) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UpdateCustomerAddress = (props: UpdateCustomerAddressProps) => {

  const classes = useStyles();
  const { open, custInfo, consignment, closePopup, handleDialogAction } = props;

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const inputValidationSchema = yup.object({
    area: yup
      .string().min(5, "Please enter valid details")
      .required(AppConstants.VALET_TROLLEY_CONSTANTS.VALIDATIONS.AREA.REQUIRED.msg),
    city: yup
      .string().min(5, "Please enter valid details")
      .required(AppConstants.VALET_TROLLEY_CONSTANTS.VALIDATIONS.CITY.REQUIRED.msg),
    streetNumber: yup
      .string()
      .test(
        'oneOfRequired',
        'One of street number, street name must be entered',
        function (item) {
          return (this.parent.streetNumber || this.parent.streetName)
        }
      ),
    streetName: yup
      .string()
      .test(
        'oneOfRequired',
        'One of street number, street name must be entered',
        function (item) {
          return (this.parent.streetNumber || this.parent.streetName)
        }
      ),
    building: yup
      .string()
      .required(AppConstants.VALET_TROLLEY_CONSTANTS.VALIDATIONS.BUILDING.REQUIRED.msg),
    apartment: yup
      .string()
      .required(AppConstants.VALET_TROLLEY_CONSTANTS.VALIDATIONS.APARTMENT.REQUIRED.msg),
    phone: yup
      .string()
      .required(AppConstants.VALET_TROLLEY_CONSTANTS.VALIDATIONS.PHONE.REQUIRED.msg)
      .matches(new RegExp(AppConstants.VALET_TROLLEY_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.value), AppConstants.VALET_TROLLEY_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.msg),
  });

  const getInitialValues = () => {
    return {
      name: custInfo && custInfo.name ? custInfo.name : '',
      area: custInfo && custInfo.address && custInfo.address.area ? custInfo.address.area : '',
      city: custInfo && custInfo.address && custInfo.address.city ? custInfo.address.city : '',
      streetNumber: custInfo && custInfo.address && custInfo.address.streetNumber ? custInfo.address.streetNumber : '',
      streetName: custInfo && custInfo.address && custInfo.address.streetName ? custInfo.address.streetName : '',
      building: custInfo && custInfo.address && custInfo.address.building ? custInfo.address.building : '',
      apartment: custInfo && custInfo.address && custInfo.address.apartment ? custInfo.address.apartment : '',
      landmark: custInfo && custInfo.address && custInfo.address.landmark ? custInfo.address.landmark : '',
      phone: custInfo && custInfo.phone ? custInfo.phone : '',
    }
  }
  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: inputValidationSchema,
    onSubmit: (values) => {
      dialogActionHandler('submit', values, consignment);
    }
  });


  const handleDialogClose = useCallback(
    () => {
      closePopup();
    },
    [closePopup],
  );

  const dialogActionHandler = useCallback(
    (type: string, values?: any, consignment?: any) => {
      handleDialogAction && handleDialogAction({
        type: type,
        values: values,
        consignmentId: consignment
      });
    },
    [handleDialogAction],
  );

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label">Update Customer Address</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }

  const getDetailsContent = () => {
    return (
      <form onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.contentContainer} container>

          <Grid className="selectedContentArea" item>
            <Grid className="contentAreaContainer cancel" container>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Customer Name</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      disabled={true}
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.name}
                      error={inputFormik.touched.name && Boolean(inputFormik.errors.name)}
                      helperText={inputFormik.touched.name && inputFormik.errors.name}
                      textBoxId="customerName"
                      name="name"
                      type="text"
                      placeholderText="Customer name"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Area</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.area}
                      error={inputFormik.touched.area && Boolean(inputFormik.errors.area)}
                      helperText={inputFormik.touched.area && inputFormik.errors.area}
                      textBoxId="area"
                      name="area"
                      type="text"
                      placeholderText="Area"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>City</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.city}
                      error={inputFormik.touched.city && Boolean(inputFormik.errors.city)}
                      helperText={inputFormik.touched.city && inputFormik.errors.city}
                      textBoxId="city"
                      name="city"
                      type="text"
                      placeholderText="City"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Street Number</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.streetNumber}
                      error={inputFormik.touched.streetNumber && Boolean(inputFormik.errors.streetNumber)}
                      helperText={inputFormik.touched.streetNumber && inputFormik.errors.streetNumber}
                      textBoxId="streetNumber"
                      name="streetNumber"
                      type="text"
                      placeholderText="Street Number"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Street Name</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.streetName}
                      error={inputFormik.touched.streetName && Boolean(inputFormik.errors.streetName)}
                      helperText={inputFormik.touched.streetName && inputFormik.errors.streetName}
                      textBoxId="streetName"
                      name="streetName"
                      type="text"
                      placeholderText="Street Name/No"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Building Name/ Villa No.</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.building}
                      error={inputFormik.touched.building && Boolean(inputFormik.errors.building)}
                      helperText={inputFormik.touched.building && inputFormik.errors.building}
                      textBoxId="building"
                      name="building"
                      type="text"
                      placeholderText="Building Name"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Floor No./ Apartment No.</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.apartment}
                      error={inputFormik.touched.apartment && Boolean(inputFormik.errors.apartment)}
                      helperText={inputFormik.touched.apartment && inputFormik.errors.apartment}
                      textBoxId="apartment"
                      name="apartment"
                      type="text"
                      placeholderText="Apartment No."
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="contentAreaItem" item>
                <Grid className="itemContainer" container>
                  <Grid className="itemLabel" item>
                    <Typography>Landmark</Typography>
                  </Grid>
                  <Grid className="itemInput" item>
                    <TextBox
                      fullWidth
                      variant="outlined"
                      onChange={inputFormik.handleChange}
                      value={inputFormik.values.landmark}
                      error={inputFormik.touched.landmark && Boolean(inputFormik.errors.landmark)}
                      helperText={inputFormik.touched.landmark && inputFormik.errors.landmark}
                      textBoxId="landmark"
                      name="landmark"
                      type="text"
                      placeholderText="Landmark"
                    ></TextBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  const getDialogActions = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className="buttonsContainer" container>
          <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel', null, consignment) }}>{AppConstants.BUTTONS.CANCEL}</Button>
          <Button className="userBtn primary" variant="contained" type="submit" onClick={() => { dialogActionHandler('save') }}>{AppConstants.BUTTONS.SAVE}</Button>
        </Grid>
      </form >
    );
  };

  useEffect(() => {
    if (open) {
      inputFormik.resetForm();
      inputFormik.setValues(getInitialValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      actions={getDialogActions()}
      content={getDetailsContent()}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default UpdateCustomerAddress;