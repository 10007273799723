import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import AppConstants from "../../constants";
import { StagingAreaState } from "./stagingAreaSlice.types";

export const fetchStagingArea = createAsyncThunk(
  "StagingArea/FetchStagingArea",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `/staging/dashboard`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createStagingArea = createAsyncThunk(
  "StagingArea/CreateStagingArea",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `/staging/create`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateStagingArea = createAsyncThunk(
  "StagingArea/UpdateStagingArea",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `/staging/update`,
        method: EApiMiddlewareMethods.PUT,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteStagingArea = createAsyncThunk(
  "StagingArea/DeleteStagingArea",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      
      const request = {
        url: `/staging/delete`,
        method: EApiMiddlewareMethods.DELETE,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const linkStagingArea = createAsyncThunk(
  "StagingArea/LinkStagingArea",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `staging/delivery/link`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const unLinkStagingArea = createAsyncThunk(
  "StagingArea/UnLinkStagingArea",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      
      const request = {
        url: `staging/delivery/unlink`,
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: StagingAreaState = {
  loading: false,
  stagingCreateResponse: {},
  stagingUpdateResponse: {},
  stagingDeleteResponse: '',
  stagingAreaList:[],
  deliveryAreaList:[],
  linkArea: '',
  unLinkArea: '',
  error: ""
}

const stagingAreaSlice = createSlice({
  name: "StagingArea",
  initialState,
  reducers: {},
   extraReducers: (builders) => {
    builders
      .addCase(createStagingArea.pending, (state) => {
        state.loading = true;
        state.stagingCreateResponse={};
        state.stagingUpdateResponse={};
        state.stagingDeleteResponse='';
        state.error = "";
      })
      .addCase(createStagingArea.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.error = "";
        state.stagingCreateResponse= payload
      })
      .addCase(createStagingArea.rejected, (state, action) => {
        state.loading = false;
        const errorPayload: any = action.payload;
         state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        
      })
       .addCase(updateStagingArea.pending, (state) => {
        state.error = "";
        state.loading = true;
        state.stagingCreateResponse={};
        state.stagingUpdateResponse={};
        state.stagingDeleteResponse='';
      })
      .addCase(updateStagingArea.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.error="";
        state.loading = false;
        state.stagingUpdateResponse= payload
      })
      .addCase(updateStagingArea.rejected, (state, action) => {
        state.loading = false;
        const errorPayload: any = action.payload;
         state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        
      })
       .addCase(deleteStagingArea.pending, (state) => {
        state.stagingCreateResponse={};
        state.stagingUpdateResponse={};
        state.stagingDeleteResponse='';
        state.error = "";
        state.loading = true;
      })
      .addCase(deleteStagingArea.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.stagingDeleteResponse= payload
        state.loading = false;
      })
      .addCase(deleteStagingArea.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
         state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        
      })
      .addCase(fetchStagingArea.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchStagingArea.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.stagingAreaList= payload.stagingAreas
        state.deliveryAreaList=payload.deliveryAreas
      })
      .addCase(fetchStagingArea.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
         state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        
      })
      .addCase(linkStagingArea.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(linkStagingArea.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.linkArea= payload
      })
      .addCase(linkStagingArea.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
         state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        
      })
      .addCase(unLinkStagingArea.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(unLinkStagingArea.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.unLinkArea= payload
      })
      .addCase(unLinkStagingArea.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
         state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        
      })
    }
})

export default stagingAreaSlice.reducer;