import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      '& .MuiOutlinedInput-input': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontWeight: 600,
        borderWidth: 1,
        borderRadius: theme.spacing(1),
        cursor: "pointer",
        fontFamily: "inherit",
        '&:focus': {
          outline: "none"
        }
      },
      '&.hasLabel': {
        '& .MuiOutlinedInput-input': {
          paddingTop: theme.spacing(2)
        }
      }
    },
    customBadge:{
      backgroundColor: COLORS.AMBER,
    },
    datePickerIcon: {
      padding: 0,
      width: 24,
      height: 24,
      cursor: "pointer",
      border: "none",
      verticalAlign: "middle",
      backgroundColor: COLORS.TRANSPARENT,
      marginLeft: theme.spacing(1),
      '&:focus': {
        outline: "none"
      },
      '&.addMargin': {
        marginLeft: theme.spacing(0.5)
      }
    }
  })
);