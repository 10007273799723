import { TextConstants } from "constants/TextConstants";
import AppConstants from "../../constants";
import { startOfDay, endOfDay } from "../../utils/dateUtils";

export interface CODPortalDriversDataResponse {
    pageNumber: number;
    totalElements: number;
    totalPages: number;
    continuationToken?: string;
    elements: Drivers[];
}

export interface Drivers {
    driverId: string;
    driverName: string;
    ordersCount: any;
    amountCollected: any;
    amountReceived: any;
    amountPending: any;
    vendorName: string;
}

export interface CODPortalOrdersDataResponse {
    pageNumber: number;
    totalElements: number;
    totalPages: number;
    continuationToken?: string;
    elements: Orders[];
}

export interface Orders {
    consignmentCode: string;
    driverId: any;
    driverName: string;
    deliveryStatus: string;
    deliveryDate: any;
    deliverySlot: any;
    codAmountCollected: string;
    codAmountReceived: string;
    codAmountPending: string;
    collected: boolean;
    remarks: string;
}

export const codPortalDriversDataTransform = (response: CODPortalDriversDataResponse): any[] => {
    const transformedArray: any[] = [];
    response.elements.forEach((driver: Drivers) => {
        const { driverId, driverName, ordersCount, amountCollected, amountReceived, amountPending, vendorName } = driver;
        transformedArray.push({
            id: driverId,
            driverName: driverName,
            totalOrders: ordersCount,
            codCollected: amountCollected,
            codReceived: amountReceived,
            codPending: amountPending,
            vendor: vendorName,
        });
    });
    return [...transformedArray];
};

export const codPortalOrdersDataTransform = (response: CODPortalOrdersDataResponse): any[] => {
    const transformedOrderArray: any[] = [];
    response.elements.forEach((order: Orders) => {
        const {
            consignmentCode,
            deliveryStatus,
            deliveryDate,
            deliverySlot,
            codAmountCollected,
            codAmountPending,
            codAmountReceived,
            collected,
            remarks,
        } = order;
        transformedOrderArray.push({
            orderNo: consignmentCode,
            deliveryStatus: deliveryStatus,
            orderDate: deliveryDate,
            slot: deliverySlot,
            codCollected: codAmountCollected,
            codReceived: codAmountReceived,
            codAmountDue: codAmountPending,
            collectionStatus: collected ? TextConstants.COLLECTED : TextConstants.COLLECT,
            remarks: remarks,
        });
    });
    return [...transformedOrderArray];
};

export const createPayloadToFetchDriversList = (
    payload: any,
    params: any,
    data?: any,
    isLoadMore?: boolean,
    countryCode?: string,
    dateValue?: any,
    dashboardHub?: string
) => {
    payload.attributes = [];
    if (countryCode) {
        payload.countryISOCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    }
    if (params && params.dateRange) {
        payload.dateRange = {
            startTime: startOfDay(params.dateRange.startDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
            endTime: endOfDay(params.dateRange.endDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
        };
    } else {
        payload.dateRange = {
            startTime: startOfDay(dateValue.startDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
            endTime: endOfDay(dateValue.endDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
        };
    }
    if (isLoadMore && payload) {
        payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
    }
    if (params && params.hubCode) {
        payload.attributes.push({
            key: "hub",
            value: [params.hubCode],
        });
    } else {
        payload.attributes.push({
            key: "hub",
            value: [dashboardHub],
        });
    }
    if (payload.driverName.value.length > 0) {
        let attrValue = [];
        if (payload.driverName.value) {
            attrValue = getFilterAttributeValue(payload.driverName.value);
            payload.attributes.push({
                key: payload.driverName.fieldName,
                value: attrValue,
            });
        }
    } else {
        delete payload.driverName;
    }
    if (payload.vendorName.value.length > 0) {
        let attrValue = [];
        if (payload.vendorName.value) {
            attrValue = getFilterAttributeValue(payload.vendorName.value);
            payload.attributes.push({
                key: payload.vendorName.fieldName,
                value: attrValue,
            });
        }
    } else {
        delete payload.vendorName;
    }
    delete payload.reset;
    delete payload.driverName;
    delete payload.vendorName;
    payload.isDateRange = true;
    return payload;
};

export const createPayloadToFetchOrdersList = (
    payload: any,
    data?: any,
    isLoadMore?: boolean,
    countryCode?: any,
    dashboardHub?: any,
    dateValue?: any
) => {
    payload.attributes = [];
    if (countryCode) {
        payload.countryISOCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    }
    payload.isDateRange = true;
    if (dateValue) {
        payload.dateRange = {
            startTime: startOfDay(dateValue.startDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
            endTime: endOfDay(dateValue.endDate, AppConstants.DATE_FORMAT, AppConstants.DATE_FORMAT_BACKEND),
        };
    }
    if (isLoadMore && payload) {
        payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
    }
    if (dashboardHub && dashboardHub !== AppConstants.SELECT_NONE.value) {
        payload.attributes.push({
            key: "hub",
            value: [dashboardHub],
        });
    }
    if (data) {
        payload.attributes.push({
            key: "driverId",
            value: [data.id],
        });
    }
    if (payload.deliveryStatus.value.length > 0) {
        let attrValue = [];
        if (payload.deliveryStatus.value) {
            attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
            payload.attributes.push({
                key: payload.deliveryStatus.fieldName,
                value: attrValue,
            });
        }
    } else {
        delete payload.deliveryStatus;
    }
    delete payload.reset;
    delete payload.deliveryStatus;
    return payload;
};

export const getConsignmentStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
};

export const createPayloadToCollectAmount = (data: any, amt: any, comments: any) => {
    const payload = {
        consignmentCode: data.orderNo,
        collected: Number(amt).toFixed(2) === '0.00' ? false : true,
        amount: amt,
        remarks: comments,
    };
    return payload;
};

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
    let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
    const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
        currentValue = [];
    }
    if (selectNoneIdx > -1) {
        currentValue = [];
    }
    return currentValue;
};

export const createPayloadToDeleteUploadedFile = (item: any, data: any, isTemp: boolean) => {
    let payload = {
        id: data,
        removeImages: [item.name],
        removeTempImage: isTemp
    }
    return payload;
}