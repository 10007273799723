import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../config/redux/reducers";
import moment from "moment";
import { createCodReconciliationPayload } from "../../mocks/codReconciliation/response.transforms";
import { fetchCodReconciliation } from "./redux/reconciliationSlice";
import { userLogout } from "../../Login/redux/loginSlice";

//Material UI Components
import { Grid, Typography, SvgIcon } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import Lens from "@material-ui/icons/Lens";
import clsx from "clsx";
//Assets
import { ReactComponent as infoIcon } from "../../assets/icons/info-icon.svg";
import ExpandMoreIcon from "../../assets/icons/expand-accordion-icon.svg";
import calendarIcon from "../../assets/icons/calendar-icon.svg";

//Common Components
import BarCharts from "../../common/BarCharts/index";
import DateRangePickerComponent from "../../common/DateRangeComponent/index";
import Loader from "../../common/Loader";
import { COLORS } from "../../config/theme/baseTheme";
import CustomSnackbar from '../../common/CustomSnackbar';

//Style
import { useStyles } from "./reconciliationDashboard.styles";

//Constants
import AppConstants from "../../constants";

interface DashboardProps { }

const BARCOLORS = [
  COLORS.LIGHT_GREEN,
  COLORS.ALERT_ORANGE,
  COLORS.PICTON_BLUE,
  COLORS.CORAL_PINK,
];

const ReconciliationDashboard = (props: DashboardProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { codReconciliationCount, loading, filteredDays, formattedDates, errorCode, error } = useSelector(
    (state: AppState) => state.codReconciliation
  );

  const [expanded, setExpanded] = useState(true);
  const [datePickerValue, setDatePickerValue] = useState({
    endDate: moment().hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
    startDate: moment().subtract(6, 'd').format(AppConstants.DATE_FORMAT),
  });
  const [showLastSevenDays, setShowLastSevenDays] = useState(true);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = "No Data present for Requested Date";
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
    },
    [],
  );

  const handleSnackbarExited = useCallback(
    () => {
      if (errorCode === AppConstants.RECONCILIATION_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
    },
    [dispatch, errorCode]
  );


  const handleCreateCodReconciliation = useCallback(
    (date: any) => {
      const params = createCodReconciliationPayload({
        date: date,
      });
      dispatch(
        fetchCodReconciliation({
          params: params,
        })
      );
    },
    [dispatch]
  );

  const handleDateChange = useCallback((dateRange: any) => {
    setDatePickerValue(dateRange);
    handleCreateCodReconciliation(dateRange);
    setShowLastSevenDays(false);
  }, [handleCreateCodReconciliation]);

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    handleCreateCodReconciliation(datePickerValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Grid container>
              <Grid item className={classes.heading}>
                COD Reconciliation
              </Grid>
              <Grid item className={classes.subHeading}>
                (based on Planned delivery dates)
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
            <Grid container>
              <Grid item xs={6} style={{ display: "flex" }}>
                <SvgIcon component={infoIcon} style={{ width: "20px", height: "20px", marginTop: "10px" }} />
                <Typography className={classes.infoText}>All figures are in AED</Typography>
              </Grid>

              <DateRangePickerComponent
                keyId="create-route-date-picker"
                className="icon"
                value={datePickerValue}
                isTextField={true}
                iconSrc={calendarIcon}
                autoUpdateInput={false}
                autoApply={true}
                maxSpan={{
                  day: "6",
                }}
                showDropdowns={true}
                linkedCalendars={true}
                locale={{
                  format: AppConstants.DATE_FORMAT,
                }}
                updateDateRange={handleDateChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {loading && <Loader></Loader>}
        <Accordion defaultExpanded className={classes.accordion} onClick={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />}
            className={classes.accordionSummary}
          >
            <Grid container style={{ alignItems: "center" }}>
              <Grid item xs={4}>
                <Grid item className={classes.expectedAmtText}>
                  Expected Amount : {`${codReconciliationCount.expectedAmount}`}
                </Grid>
                {showLastSevenDays ? (
                  <Grid item className={classes.daysText}>
                    (Last 7 days)
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={8}>
                {!expanded ? (
                  <div>
                    <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className={classes.dataDivs}>
                        <Grid item>
                          <Grid container>
                            <Lens
                              className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconCollected)}
                            />
                            <Typography className={classes.amountStatusText}>Collected</Typography>
                          </Grid>
                          <Typography className={classes.amountStatusSummaryData}>
                            {codReconciliationCount.collectedAmount}
                          </Typography>
                        </Grid>
                      </div>
                      <div className={classes.dataDivs}>
                        <Grid item>
                          <Grid container>
                            <Lens
                              className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconHandedOver)}
                            />
                            <Typography className={classes.amountStatusText}>Handed Over</Typography>
                          </Grid>
                          <Typography className={classes.amountStatusSummaryData}>
                            {codReconciliationCount.handedOver}
                          </Typography>
                        </Grid>
                      </div>
                      <div className={classes.dataDivs}>
                        <Grid item>
                          <Grid container>
                            <Lens
                              className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconPending)}
                            />
                            <Typography className={classes.amountStatusText}>Pending</Typography>
                          </Grid>
                          <Typography className={classes.amountStatusSummaryData}>
                            {codReconciliationCount.pendingAmount}
                          </Typography>
                        </Grid>
                      </div>
                      <div className={classes.dataDivs}>
                        <Grid item>
                          <Grid container>
                            <Lens
                              className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconExcess)}
                            />
                            <Typography className={classes.amountStatusText}>Excess</Typography>
                          </Grid>
                          <Typography className={classes.amountStatusSummaryData}>
                            {codReconciliationCount.excess}
                          </Typography>
                        </Grid>
                      </div>
                      <div className={classes.dataDivs}>
                        <Grid item>
                          <Grid container>
                            <Lens
                              className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconShortage)}
                            />
                            <Typography className={classes.amountStatusText}>Shortage</Typography>
                          </Grid>
                          <Typography className={classes.amountStatusSummaryData}>
                            {codReconciliationCount.shortage}
                          </Typography>
                        </Grid>
                      </div>
                    </Grid>
                  </div>
                ) : (
                    <div></div>
                  )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails onClick={(e) => e.stopPropagation()}>
            <Grid container className={classes.mainContainer}>
              <Grid item>
                <div className={classes.dataDivs} style={{ width: "346px" }}>
                  <Grid container>
                    <Lens className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconCollected)} />
                    <Grid item>
                      <Typography className={classes.amountStatusText}>Collected Amount</Typography>
                      <Typography className={classes.amountStatusData}>
                        {codReconciliationCount.collectedAmount}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Grid container className={classes.amountCounts}>
                  <Grid item xs={6} className={classes.firstAmountDiv}>
                    <div className={classes.dataDivs}>
                      <Grid container>
                        <Lens
                          className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconHandedOver)}
                        />
                        <Grid item>
                          <Typography className={classes.amountStatusText}>Handed Over</Typography>
                          <Typography className={classes.amountStatusData}>
                            {codReconciliationCount.handedOver}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.secondAmountDiv}>
                    <div className={classes.dataDivs}>
                      <Grid container>
                        <Lens className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconPending)} />
                        <Grid item>
                          <Typography className={classes.amountStatusText}>Pending</Typography>
                          <Typography className={classes.amountStatusData}>
                            {codReconciliationCount.pendingAmount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className={classes.amountCounts}>
                  <Grid item xs={6} className={classes.firstAmountDiv}>
                    <div className={classes.dataDivs}>
                      <Grid container>
                        <Lens className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconExcess)} />
                        <Grid item>
                          <Typography className={classes.amountStatusText}>Excess</Typography>
                          <Typography className={classes.amountStatusData}>{codReconciliationCount.excess}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.secondAmountDiv}>
                    <div className={classes.dataDivs}>
                      <Grid container>
                        <Lens className={clsx(classes.orderStatusBottonIcon, classes.orderStatusBottonIconShortage)} />
                        <Grid item>
                          <Typography className={classes.amountStatusText}>Shortage</Typography>
                          <Typography className={classes.amountStatusData}>
                            {codReconciliationCount.shortage}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <BarCharts
                  days={formattedDates}
                  color={BARCOLORS}
                  seriesObj={filteredDays && filteredDays.length ? filteredDays : []}
                  showLegend={false}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} />
    </div>
  );
};

export default ReconciliationDashboard;
