import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './AccessDenied.styles';
import AppConstants from "../../constants/AppConstants";

const AccessDenied = () => {
    const classes = useStyles();
    return (
        <Grid className={classes.root} container>
            <Typography className={classes.content}>{AppConstants.ACCESS_DENIED}</Typography>
        </Grid>
    );
}

export default AccessDenied;