import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { getItemsFromMetaData, getValueFromMetaData } from "../../../mocks/delve/response.transforms";

interface interfaceDelveOrderDetailsConsignmentDetails {
  classes: any;
  consignment: any;
  deliveryDate: any;
  deliverySlot: any;
  metadata: any;
  custInfo: any;
  deliveryInformation: any;
  items: any;
  itemsVerified: any;
  tabValue: any;
}

const DelveOrderDetailsConsignmentDetails = (props: interfaceDelveOrderDetailsConsignmentDetails) => {
  const { classes, consignment, deliveryDate, deliverySlot, metadata, custInfo, deliveryInformation, items, itemsVerified, tabValue } = props;
  return (
    <Grid item>
      <Grid className="detailsContent" container>
        <Grid className="contentRow" item>
          <Grid className="rowContainer" container>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Order No.</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{consignment.orderCode}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Delivery Date</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{deliveryDate}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Delivery Slot</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{deliverySlot}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="contentRow" item>
          <Grid className="rowContainer" container>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Verified By</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{getValueFromMetaData(metadata, tabValue, "verifiedBy")}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Delivery Area</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{custInfo && custInfo.address ? custInfo.address.area : ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Payment Mode</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{metadata ? metadata?.paymentType : ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="contentRow" item>
          <Grid className="rowContainer" container>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Delivery Proposition</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{metadata ? metadata?.deliveryType : ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Route No.</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{metadata ? metadata?.routeName : ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Driver</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{(deliveryInformation && deliveryInformation.driver && deliveryInformation.driver.name) || ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="contentRow" item>
          <Grid className="rowContainer" container>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Vehicle</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{(deliveryInformation && deliveryInformation.vehicle && deliveryInformation.vehicle.name) || ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">Helper</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{(deliveryInformation && deliveryInformation.helper && deliveryInformation.helper.name) || ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentColumn" item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="label">{""}</Typography>
                </Grid>
                <Grid item>
                  <Typography className="content">{""}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.detailsItemContent} container>
        {/* New Items Section */}
        <Grid item xs={4}>
          <Grid container direction="column">
            <Grid item className={classes.detailsItemContentDivStyle}>
              <Typography className={classes.detailsItemContentLabelStyle}>Total Items</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.detailsItemContentValueStyle}>{`${Number(getItemsFromMetaData(metadata, tabValue))}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="column">
            <Grid item className={classes.detailsItemContentDivStyle}>
              <Typography className={classes.detailsItemContentLabelStyle}>Items Verified</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.detailsItemContentValueStyle}>{`${Number(getValueFromMetaData(metadata, tabValue, "itemsVerified"))}`}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container direction="column">
            <Grid item className={classes.detailsItemContentDivStyle}>
              <Typography className={classes.detailsItemContentLabelStyle}>Issues Reported</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.detailsItemContentValueStyle}>{`${Number(getValueFromMetaData(metadata, tabValue, "issuesFound"))} `}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(DelveOrderDetailsConsignmentDetails);
