import AppConstants from "../../constants";

export interface formDetail { }

export interface Permission {
  assignedRole: string;
  formDetails: formDetail[];
}

export interface Hub {
  hubCode: string;
  hubName: string;
  countryName: string;
  permissions: Permission[];
}

export interface Country {
  [key: string]: Hub[];
}
export interface Content {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: number;
  enabled: boolean;
  createdBy: string;
  lastModifiedBy: string;
  userType: string;
  countryDetails: Country;
  status: string;
  deliveryType: string;
  vehicleType: string;
  serviceType: string[];
  vendor: string;
  buildVersion: string;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface UserDataResponse {
  content: Content[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  sort: Sort2;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

const getUserType = (userType : string) => {
  if(userType){
    switch(userType){
      case AppConstants.USERS_CONSTANTS.USER_TYPE.INTERNAL:
        return AppConstants.USERS_CONSTANTS.USER_TYPE.PORTAL_USER;
      case AppConstants.USERS_CONSTANTS.USER_TYPE.EXTERNAL:
        return AppConstants.USERS_CONSTANTS.USER_TYPE.APP_USER;
    }
    return userType;
  }
}

const lastMileUserDataTransform = (listToShow: any, response: UserDataResponse, countryCode: string): any[] => {
  const transformedArray: any[] = [];
  response.content.forEach((content: Content) => {
    const { username, firstname, lastname, phone, userType, countryDetails, status, deliveryType, vehicleType, serviceType, vendor, buildVersion } = content;
    const hubDetails: Hub[] = countryDetails[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [];
    const currentHubDetails: Hub = hubDetails.length ? hubDetails[0] : countryDetails[AppConstants.COUNTRY_ALL] ? countryDetails[AppConstants.COUNTRY_ALL][0] : { hubCode: "N/A", hubName: "N/A", countryName: "N/A", permissions: [] };
    const currentRole = currentHubDetails.permissions.length ? currentHubDetails.permissions[0].assignedRole : "N/A";
    const currentRoleIndex = AppConstants.USERS_CONSTANTS.USER_ROLES.findIndex((role:any) => role.code === currentRole);
    const accessibleRoles = AppConstants.USERS_CONSTANTS.USER_ROLES.slice(currentRoleIndex);
    const role = accessibleRoles.length ? accessibleRoles[0].value : "N/A";
    if(transformedArray.findIndex((obj: any) => obj?.username === username) === -1){
      transformedArray.push({
        id: username,
        userId: username,
        firstname: firstname,
        lastname: lastname,
        hubName: `${currentHubDetails.hubCode} - ${currentHubDetails.hubName}`,
        hub: `${currentHubDetails.hubCode}`,
        phone: phone,
        role: role,
        type: getUserType(userType),
        country: currentHubDetails.countryName,
        deliveryType: deliveryType,
        status: status,
        vendor: vendor,
        vehicleType: vehicleType,
        serviceType: serviceType?.length > 0 ? serviceType.join(", ") : "",
        actions: content,
        buildVersion: buildVersion
      });
    }
  });
  return [...transformedArray];
};

export { lastMileUserDataTransform };

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, role?: any) => {
  if (payload.hub) {
    if (payload.hub.value) {
      let hubValue = payload.hub.value.map((item: any) => item.value);
      const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        hubValue = [];
      }
      if (selectNoneIdx > -1) {
        hubValue = [];
      }
      payload.hub = hubValue;
    } else {
      payload.hub = [];
    }
  }
  //To get the current role of the user from the session storage
  const currentRoles = sessionStorage.getItem('role');
  if (currentRoles) {
    const currentRole = JSON.parse(currentRoles)[0];
    if (currentRole) {
      role = currentRole.code;
    }
  }
  //To check whether the user is either CPM or VT Manager or CHD Manager
  let isCpmVtOrChdUser = role === AppConstants.CPM_ROLE || role === AppConstants.VT_MANAGER_ROLE || role === AppConstants.CHD_ROLE;

  if (isCpmVtOrChdUser) {
    //If the role is collection point manager, the user will be shown only CPM users in the users dashboard
    if (role === AppConstants.CPM_ROLE) {
      payload.role = [role];
    }
    //If the role is vt manager or chd manager, the user will be shown only VT Managers or CHD Managers along with drivers and helpers in the users dashboard
    if (role === AppConstants.VT_MANAGER_ROLE || role === AppConstants.CHD_ROLE) {
      if (payload.role) {
        if (payload.role.value.length) {
          let roleValue = payload.role.value.map((item: any) => item.value);
          const selectAllIdx = roleValue.indexOf(AppConstants.SELECT_ALL.value);
          const selectNoneIdx = roleValue.indexOf(AppConstants.SELECT_NONE.value);
          if (selectAllIdx > -1) {
            roleValue = [role, AppConstants.DRIVER_ROLE, AppConstants.HELPER_ROLE];
          }
          if (selectNoneIdx > -1) {
            roleValue = [role, AppConstants.DRIVER_ROLE, AppConstants.HELPER_ROLE];
          }
          payload.role = roleValue;
        } else {
          payload.role = [role, AppConstants.DRIVER_ROLE, AppConstants.HELPER_ROLE];
        }
      }
    }
  } else {
    if (payload.role) {
      if (payload.role.value) {
        let roleValue = payload.role.value.map((item: any) => item.value);
        const selectAllIdx = roleValue.indexOf(AppConstants.SELECT_ALL.value);
        const selectNoneIdx = roleValue.indexOf(AppConstants.SELECT_NONE.value);
        if (selectAllIdx > -1) {
          roleValue = [];
        }
        if (selectNoneIdx > -1) {
          roleValue = [];
        }
        payload.role = roleValue;
      } else {
        payload.role = [];
      }
    }
  }
  if (payload.status) {
    if (payload.status.value) {
      let statusValue = payload.status.value.map((item: any) => item.value);
      const selectAllIdx = statusValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = statusValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        statusValue = [];
      }
      if (selectNoneIdx > -1) {
        statusValue = [];
      }
      payload.status = statusValue;
    } else {
      payload.status = [];
    }
  }
  if (payload.buildVersion) {
    if (payload.buildVersion.value) {
      let buildVersionValue = payload.buildVersion.value.map((item: any) => item.value);
      const selectAllIdx = buildVersionValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = buildVersionValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        buildVersionValue = [];
      }
      if (selectNoneIdx > -1) {
        buildVersionValue = [];
      }
      payload.buildVersion = buildVersionValue;
    } else {
      payload.buildVersion = [];
    }
  }
  if (payload.type) {
    if (payload.type.value) {
      let typeValue = payload.type.value.map((item: any) => item.value);
      const selectAllIdx = typeValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = typeValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        typeValue = [];
      }
      if (selectNoneIdx > -1) {
        typeValue = [];
      }
      payload.type = typeValue;
    } else {
      payload.type = [];
    }
  }

  /** vehicleType */
  if (payload.vehicleType) {
    if (payload.vehicleType.value) {
      let typeValue = payload.vehicleType.value.map((item: any) => item.value);
      const selectAllIdx = typeValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = typeValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        typeValue = [];
      }
      if (selectNoneIdx > -1) {
        typeValue = [];
      }
      payload.vehicleType = typeValue;
    } else {
      payload.vehicleType = [];
    }
  }

   /** vendor */
   if (payload.vendor) {
    if (payload.vendor.value) {
      let typeValue = payload.vendor.value.map((item: any) => item.value);
      const selectAllIdx = typeValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = typeValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        typeValue = [];
      }
      if (selectNoneIdx > -1) {
        typeValue = [];
      }
      payload.vendor = typeValue;
    } else {
      payload.vendor = [];
    }
  }

   /** deliveryType */
   if (payload.deliveryType) {
    if (payload.deliveryType.value) {
      let typeValue = payload.deliveryType.value.map((item: any) => item.value);
      const selectAllIdx = typeValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = typeValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        typeValue = [];
      }
      if (selectNoneIdx > -1) {
        typeValue = [];
      }
      payload.deliveryType = typeValue;
    } else {
      payload.deliveryType = [];
    }
  }

   /** serviceType */
   if (payload.serviceType) {
    if (payload.serviceType.value) {
      let typeValue = payload.serviceType.value.map((item: any) => item.value);
      const selectAllIdx = typeValue.indexOf(AppConstants.SELECT_ALL.value);
      const selectNoneIdx = typeValue.indexOf(AppConstants.SELECT_NONE.value);
      if (selectAllIdx > -1) {
        typeValue = [AppConstants.USERS_CONSTANTS.SERVICE_TYPE.STANDARD, AppConstants.USERS_CONSTANTS.SERVICE_TYPE.EXPRESS];
      }
      if (selectNoneIdx > -1) {
        typeValue = [];
      }
      payload.serviceType = typeValue;
    } else {
      payload.serviceType = [];
    }
  }

  if (isLoadMore && data) {
    const paginationObj = data.pageable;
    if (paginationObj) {
      payload.page = paginationObj.pageNumber + 1 < data.totalPages ? paginationObj.pageNumber + 1 : payload.page;
    }
  }
  return payload;
};

export const createUserResetPwdPayload = (payload: any) => {
  return payload;
}

export const createGenerateIdPayload = (payload: any) => {
  return {
    queryString: `userName=${payload.firstName}.${payload.lastName}`
  }
}

export const createAddUserPayload = (payload: any, dropdowns: any) => {
  let countryDetails = dropdowns.countriesList[payload.userRole] ? dropdowns.countriesList[payload.userRole].filter((country: any) => country.value === payload.country) : [];
  let hubDetails = dropdowns.hubList[payload.country] ? dropdowns.hubList[payload.country].filter((hub: any) => payload.hub.includes(hub.value)) : [];
  let serviceTypesList = dropdowns.serviceTypeList ? dropdowns.serviceTypeList.filter((service: any) => payload.serviceTypes.includes(service.value)) : [];
  if (countryDetails.length) {
    payload.countryDetails = countryDetails.map((country: any) => {
      return {
        code: country.value,
        value: country.name
      };
    })[0];
  }

  if (hubDetails.length) {
    const selectAllIdx = hubDetails.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = hubDetails.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      hubDetails = [];
    }
    if (selectNoneIdx > -1) {
      hubDetails = [];
    }
    payload.hubDetails = hubDetails.map((hub: any) => {
      return {
        code: hub.value,
        value: hub.name
      };
    });
  }

  if (serviceTypesList.length !== undefined && serviceTypesList.length > 0) {
    const selectAllIdx = payload.serviceTypes.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.serviceTypes.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.serviceTypes = [];
    }
    if (selectNoneIdx > -1) {
      payload.serviceTypes = [];
    }
    payload.serviceTypes = serviceTypesList.map((service: any) => {
      return {
        code: service.value,
        value: service.name
      };
    });
  }
  else{
    delete payload.serviceTypes;
  }

  if (payload.vehicleType === AppConstants.SELECT_NONE.value) {
    delete payload.vehicleType;
  }
  if (payload.vendor === AppConstants.SELECT_NONE.value) {
    delete payload.vendor;
  }
  if(payload.deliveryType === AppConstants.SELECT_NONE.value) {
    delete payload.deliveryType;
  }
  if(payload.serviceType === AppConstants.SELECT_NONE.value) {
    delete payload.serviceType;
  }
  delete payload.confirmPassword;
  delete payload.country;
  delete payload.hub;
  return payload;
}

export const createUserUAEPayload = (payload: any, dropdowns: any) => {
  let countryDetails = dropdowns.countriesList[payload.userRole] ? dropdowns.countriesList[payload.userRole].filter((country: any) => country.value === payload.country) : [];
  let hubDetails = dropdowns.hubList[payload.country] ? dropdowns.hubList[payload.country].filter((hub: any) => payload.hub.includes(hub.value)) : []
  if (countryDetails.length) {
    payload.countryDetails = countryDetails.map((country: any) => {
      return {
        code: country.value,
        value: country.name
      };
    })[0];
  }
  if (hubDetails.length) {
    payload.hubDetails = hubDetails.map((hub: any) => {
      return {
        code: hub.value,
        value: hub.name
      };
    });
  }
  if (payload.vehicleType === AppConstants.SELECT_NONE.value) {
    delete payload.vehicleType;
  }
  if (payload.vendor === AppConstants.SELECT_NONE.value) {
    delete payload.vendor;
  }
  if(payload.deliveryType === AppConstants.SELECT_NONE.value) {
    delete payload.deliveryType;
  }
  if(payload.serviceType === AppConstants.SELECT_NONE.value) {
    delete payload.serviceType;
  }
   if(payload.serviceTypes !== undefined && Array.isArray(payload.serviceTypes) && payload.serviceTypes.length > 0){
    if(payload.serviceTypes.length === 1 && payload.serviceTypes[0] === AppConstants.SELECT_NONE.value){
      delete payload.serviceTypes
    }
  }
  delete payload.password;
  delete payload.confirmPassword;
  delete payload.country;
  delete payload.hub;
  delete payload.userType;
  return payload;
}

export const createEditUserPayload = (values: any, dropdowns: any) => {
  let countryDetails = dropdowns.countriesList[values.userRole] ? dropdowns.countriesList[values.userRole].filter((country: any) => country.value === values.country) : [];
  let hubDetails = dropdowns.hubList[values.country] ? dropdowns.hubList[values.country].filter((hub: any) => values.hub.includes(hub.value)) : []
  if (countryDetails.length) {
    values.countryDetails = countryDetails.map((country: any) => {
      return {
        countryCode: country.value,
        countryName: country.name
      };
    })[0];
  }
  if (hubDetails.length) {
    const selectAllIdx = hubDetails.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = hubDetails.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      hubDetails = [];
    }
    if (selectNoneIdx > -1) {
      hubDetails = [];
    }
    values.hubDetails = hubDetails.map((hub: any) => {
      return {
        hubCode: hub.value,
        hubName: hub.name
      };
    });
  }
  values.firstname = values.firstName;
  values.lastname = values.lastName;
  values.role = values.userRole;
  values.type = values.userType;
  // removing "n/a"
  if(values.vehicleType === AppConstants.SELECT_NONE.value){
    delete values.vehicleType
  }
  if(values.vendor === AppConstants.SELECT_NONE.value){
    delete values.vendor
  }
  if(values.deliveryType === AppConstants.SELECT_NONE.value){
    delete values.deliveryType
  }
  if(values.serviceType === AppConstants.SELECT_NONE.value){
    delete values.serviceType
  }
  
  if(values.serviceTypes !== undefined && Array.isArray(values.serviceTypes) && values.serviceTypes.length > 0){
    if(values.serviceTypes.length === 1 && values.serviceTypes[0] === AppConstants.SELECT_NONE.value){
      delete values.serviceTypes
    }
    else{
      let serviceTypesList = dropdowns.serviceTypeList ? dropdowns.serviceTypeList.filter((service: any) => values.serviceTypes.includes(service.value)) : [];
      if(serviceTypesList !== undefined && serviceTypesList.length > 0){
        values.serviceTypes = serviceTypesList.map((service: any) => {
          return {
            code: service.value,
            value: service.name
          };
        });
      }
      else{
        values.serviceTypes = []
      }
    }
   
  }

  let payload = {
    userName: values.userName,
    data: values
  }
  delete payload.data.firstName;
  delete payload.data.lastName;
  delete payload.data.userRole;
  delete payload.data.userType;
  delete payload.data.confirmPassword;
  delete payload.data.country;
  delete payload.data.hub;
  delete payload.data.email;
  delete payload.data.userName;
  return payload;
}

export const getStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.USERS_CONSTANTS.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

export const checkRequiredUser = (currentRoles: any, role:any) => {
  let isReqUser = false;
  for (let adminRole of role) {
    for (let currentRole of currentRoles) {
      if (currentRole.code === adminRole.code) {
        isReqUser = true;
        break;
      }
    }
    if (isReqUser) {
      break;
    }
  }
  return isReqUser;
}

export const checkUserRoleAccess = (currentRole: any, selectedRole: any) => {
  let canAccess = false;
  const currentRoleIndex = AppConstants.USERS_CONSTANTS.USER_ROLES.findIndex(role => role.code === currentRole.code);
  let accessibleRoles = AppConstants.USERS_CONSTANTS.USER_ROLES.slice(currentRoleIndex);
  if (currentRole.code === 'ROLE_COUNTRY_ADMIN') {
    accessibleRoles = accessibleRoles.filter(role => role.code !== 'ROLE_COUNTRY_ADMIN');
  }
  for (let userRole of accessibleRoles) {
    if (selectedRole.code === userRole.code) {
      canAccess = true;
      break;
    }
  }
  return canAccess;
}
