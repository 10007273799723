import React, {useCallback, useState, useEffect, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {cloneDeep} from "../../utils/loadash.utils";
import {fetchOrdersList, reconcileCollectedAmtForStdOrder, openCollectionConfirmation} from "./redux/stdCodPortalSlice";
//common components
import {AppState} from "../../config/redux/reducers";
import AppConstants from "../../constants";
import CustomDialog from "../../common/Dialog";
import {CellParams} from "../../common/GridWrapper/DataTable";
import GridWrapper from "../../common/GridWrapper";
import TextBox from "../../common/TextBox";
import {ReactComponent as collectIcon} from "../../assets/icons/collect-button.svg";
import {ReactComponent as collectedIcon} from "../../assets/icons/collected-button.svg";
//Material UI Components
import {Grid, Typography, Button, SvgIcon} from "@material-ui/core";
//styles
import {useStyles} from "./codportal.styles";
import BucketComponent from "../../common/BucketComponent";
import {
  codPortalOrdersDataTransform,
  getConsignmentStatusField,
  createPayloadToFetchOrdersList,
  createPayloadToCollectAmount,
} from "../../mocks/stdCodPortal/response.transforms";
import {TextConstants} from "constants/TextConstants";

interface StandardCodDriverDetailsProps {
  open: boolean;
  driverDetails: any;
  dashboardHub: any;
  dateRange: any;
  closePopup: () => void;
}

const StandardCodDriverDetails = (props: StandardCodDriverDetailsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {open, driverDetails, dashboardHub, dateRange, closePopup} = props;
  const {
    ordersData,
    loading,
    isAmtCollected,
    ordersFiltersDropdowns,
    ordersOnDriverDetails,
    codAmtOnDriverDetails,
    codPendingOnDriverDetails,
    codReceivedOnDriverDetails,
    totalOrdersAmtOnDriverDetails,
  } = useSelector((state: AppState) => state.stdCodPortal);
  const {isLastMileViewer, isOnlyExpLastMileViewer, isAdmin, isDispatcher, isOnlyStandardDispatcher} = useSelector(
    (state: AppState) => state.userLogin
  );

  const collectionStatus = (status: any) => {
    if (status.toLowerCase() === "collect") {
      return <SvgIcon className='statusIcon' component={collectIcon} viewBox='0 0 108 32' />;
    } else {
      return <SvgIcon className='statusIcon' component={collectedIcon} viewBox='0 0 108 32' />;
    }
  };

  const {countryCode} = useSelector((state: AppState) => state.common);
  const country = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value;
  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.COD_RECON_CONSTANTS.ORDERS_DATA.TABLE_WRAPPER_DATA,
    HeaderData: AppConstants.COD_RECON_CONSTANTS.ORDERS_DATA.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = header;
      if (newHeader.field === "orderNo") {
        newHeader.renderCell = (params: CellParams) => <Typography className='idLink'>{params.value}</Typography>;
      }
      if (newHeader.field === "deliveryStatus") {
        newHeader.renderCell = (params: any) =>
          params.value ? (
            <Grid className={classes.statusHeaderContainer} container alignItems='center'>
              <Typography className={["tag", getConsignmentStatusField("color", params.value)].join(" ")}>
                {params.value ? params.value : ""}
              </Typography>
            </Grid>
          ) : (
            <></>
          );
      }
      if (newHeader.field === "collectionStatus") {
        newHeader.renderCell = (params: CellParams) => (
          <Typography className='actionsLink'>{collectionStatus(params.value)}</Typography>
        );
      }
      return newHeader;
    }),
  });
  const [ordersListToShow, setOrdersListToShow] = useState([]);
  const [ordersSearchValue, setOrdersSearchValue] = useState("");
  const [isOrderSearched, setIsOrderSearched] = useState(false);
  const [activeFilters, setActiveFilters] = useState(ordersTableWrapperData.defaultFiltersObj);
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot,
    },
  };
  const createCollectionConfirmationDialogPaperProps = {
    classes: {
      root: classes.collectionConfirmationDialogPaperPropsRoot,
    },
  };
  const [consignmentData, setConsignmentData] = useState({
    orderNo: "",
  });
  const [openCollectionConfirmationPopup, setOpenCollectionConfirmationPopup] = useState(false);
  const [collectedAmt, setCollectedAmt] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState("");
  let driverDetailsForOrders = {};
  let hub = "";
  let dateValue = {};
  if (driverDetails) {
    driverDetailsForOrders = driverDetails;
  }
  if (dashboardHub) {
    hub = dashboardHub;
  }
  if (dateRange) {
    dateValue = dateRange;
  }

  const handleDialogClose = () => {
    closePopup();
    setOrdersSearchValue("");
    setActiveFilters(ordersTableWrapperData.defaultFiltersObj);
  };

  const getTitleContent = () => {
    return (
      <Grid container>
        <Typography className={classes.title}>COD Reconciliation - {driverDetails.driverName}</Typography>
      </Grid>
    );
  };

  const validateForm = useCallback(
    (amt: any) => {
      let newErrors = "";
      if (Math.sign(Number(amt)) === -1) {
        newErrors = "Amount should only be positive";
      }
      setErrors(newErrors);
      return newErrors.length;
    },
    [errors]
  );

  const ordersSearchFilterHandler = (data: {[key: string]: string}, updatedSearchValue: string, filters: any) => {
    const activeFilters = cloneDeep(filters);
    setOrdersSearchValue(updatedSearchValue);
    setIsOrderSearched(false);
    if (updatedSearchValue) {
      setIsOrderSearched(true);
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = {
            key: searchKey,
            value: data[searchKey],
          };
          ordersFilterCallback(activeFilters, false);
          return;
        }
      }
    }
  };

  const ordersDataCallback = useCallback(
    (filters: any, isLoadMore?: boolean, driverDetails?: any, countryCode?: any, hub?: any, dateRange?: any) => {
      if (driverDetails) {
        let payloadFilters = cloneDeep(filters);
        payloadFilters.pageSize = ordersTableWrapperData.loadMore.pageSize;
        payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        const orderFilters = createPayloadToFetchOrdersList(payloadFilters, driverDetails, isLoadMore, hub, dateRange);
        dispatch(
          fetchOrdersList({
            params: orderFilters,
          })
        );
        setActiveFilters(filters);
      }
    },
    [dispatch, ordersTableWrapperData]
  );

  const ordersFilterCallback = useCallback(
    (filters: any, isLoadMore?: boolean) => {
      ordersDataCallback(filters, isLoadMore, driverDetailsForOrders, countryCode, hub, dateValue);
    },
    [ordersDataCallback, hub, dateValue, countryCode, driverDetailsForOrders]
  );

  const getHeaderConfig = useMemo(() => {
    return [
      {
        tileHeading: TextConstants.TOTAL_COD_ORDERS,
        tileData: ordersOnDriverDetails,
        subHeading: `(${country})`,
      },
      {
        tileHeading: TextConstants.TOTAL_ORDERS_AMOUNT,
        tileData: totalOrdersAmtOnDriverDetails,
        subHeading: `(${country})`,
      },
      {
        tileHeading: TextConstants.TOTAL_COD_COLLECTED,
        tileData: codAmtOnDriverDetails,
        subHeading: "(By Driver)",
      },
      {
        tileHeading: TextConstants.COD_AMOUNT_RECEIVED,
        tileData: codReceivedOnDriverDetails,
        subHeading: "(By Dispatcher)",
      },
      {
        tileHeading: TextConstants.TOTAL_COD_AMOUNT,
        tileData: codPendingOnDriverDetails,
        subHeading: "pending",
        classes: {subHeading: classes.subHeadingBold},
      },
    ];
  }, [ordersOnDriverDetails, totalOrdersAmtOnDriverDetails, codReceivedOnDriverDetails, codPendingOnDriverDetails, codAmtOnDriverDetails, country, classes.subHeadingBold]);

  const HeaderComponent = () => {
    const config = getHeaderConfig;
    return <BucketComponent config={config} />;
  };

  const getDetailsContent = () => {
    return (
      <Grid container>
        <HeaderComponent />
        <Grid item className={classes.gridWrapper}>
          <GridWrapper
            showHeader={true}
            checkboxSelection={false}
            headerData={ordersTableWrapperData.HeaderData}
            rowData={ordersListToShow}
            loading={loading}
            searchTypes={ordersTableWrapperData.SEARCH_TYPES}
            defaultFilter={ordersTableWrapperData.defaultFiltersObj}
            activeFilter={activeFilters}
            title={ordersTableWrapperData.title}
            disableSelectionOnClick={true}
            searchValue={ordersSearchValue}
            loadMore={{...ordersTableWrapperData.loadMore, rowCount: ordersData.totalElements || 0}}
            filterGrid={ordersFilterCallback}
            searchCallback={ordersSearchFilterHandler}
            onCellClick={handleConsignmentCellClick}
          />
        </Grid>
      </Grid>
    );
  };

  const getCollectionConfirmationDialogTitleContent = () => {
    return (
      <Typography className={classes.hubSelectionDialogTitle}>
        Confirm collection - ({consignmentData && consignmentData.orderNo})
      </Typography>
    );
  };

  const handleCollectedAmtChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCollectedAmt(event.target.value);
      if (event.target.value) {
        setErrors("");
      }
    },
    [errors]
  );

  const handleCommentsChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComments(event.target.value);
  }, []);

  const collectionConfirmationDialogActionHandler = useCallback(
    (type: string, data: any, amt: any, comments: any) => {
      if (validateForm(amt) === 0) {
        const payload = createPayloadToCollectAmount(data, amt, comments);
        dispatch(
          reconcileCollectedAmtForStdOrder({
            params: payload,
          })
        );
        setOpenCollectionConfirmationPopup(false);
      }
    },
    [dispatch, validateForm]
  );

  const getCollectionConfirmationDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmationContent} item>
        <Grid className='itemContainer' container>
          <Grid className='itemLabel' item>
            <Typography>Collect amount</Typography>
          </Grid>
          <Grid className='itemInput' item>
            <TextBox
              type='number'
              fullWidth
              variant='outlined'
              rows={4}
              onChange={handleCollectedAmtChange}
              value={collectedAmt}
              textBoxId='EditConsignmentComments'
              placeholderText=''
              InputProps={{
                inputProps: {min: 0},
              }}
              error={!!errors}
              helperText={errors}
            ></TextBox>
          </Grid>
        </Grid>
        <Grid className='itemContainer area' container>
          <Grid className='itemLabel' item>
            <Typography>Comments</Typography>
          </Grid>
          <Grid className='itemInput' item>
            <TextBox
              fullWidth
              multiline
              variant='outlined'
              rows={4}
              onChange={handleCommentsChange}
              value={comments}
              textBoxId='EditConsignmentComments'
              placeholderText=''
            ></TextBox>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getCollectionConfirmationDialogActions = () => {
    return (
      <Grid className={classes.confimationDialogButtonsContainer} container>
        <Button
          className='dialogBtn secondary'
          variant='contained'
          onClick={() => {
            handleCollectionConfirmationDialogClose();
          }}
        >
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button
          className='dialogBtn primary'
          variant='contained'
          disabled={collectedAmt === ""}
          onClick={() => {
            collectionConfirmationDialogActionHandler("submit", consignmentData, collectedAmt, comments);
          }}
        >
          {AppConstants.BUTTONS.SUBMIT}
        </Button>
      </Grid>
    );
  };

  const handleCollectionConfirmationDialogClose = () => {
    setOpenCollectionConfirmationPopup(false);
  };

  const handleLoadMoreDisableState = useCallback(() => {
    let loadMoreState = ordersTableWrapperData.loadMore;
    loadMoreState.disabled = ordersData.totalPages ? ordersData.pageNumber + 1 >= ordersData.totalPages : true;
    setOrdersTableWrapperData({...ordersTableWrapperData, loadMore: loadMoreState});
  }, [ordersTableWrapperData, ordersData]);

  const handleConsignmentCellClick = useCallback(
    (params: CellParams) => {
      if (isAdmin && params.field === "collectionStatus") {
        const selectedRow: any = params.rowData;
        setConsignmentData(selectedRow);
        setCollectedAmt(selectedRow && selectedRow.codCollected);
        setComments(selectedRow && selectedRow.remarks);
        dispatch(openCollectionConfirmation());
        setOpenCollectionConfirmationPopup(true);
      } else {
        if (
          (isDispatcher || isOnlyStandardDispatcher) &&
          params?.rowData?.collectionStatus === TextConstants.COLLECT &&
          params.field === "collectionStatus"
        ) {
          const selectedRow: any = params.rowData;
          setConsignmentData(selectedRow);
          setCollectedAmt(selectedRow && selectedRow.codCollected);
          setComments(selectedRow && selectedRow.remarks);
          dispatch(openCollectionConfirmation());
          setOpenCollectionConfirmationPopup(true);
        }
      }
    },
    [dispatch, isAdmin, isDispatcher, isOnlyStandardDispatcher]
  );

  useEffect(() => {
    if (isAmtCollected) {
      setIsOrderSearched(false);
      setOrdersSearchValue("");
      ordersFilterCallback(activeFilters, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAmtCollected]);

  useEffect(() => {
    setIsOrderSearched(false);
    setOrdersSearchValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ordersTableWrapperData && ordersTableWrapperData.HeaderData) {
      let headerData = ordersTableWrapperData.HeaderData.map((header: any) => {
        if (header.filterObj) {
          if (header.filterObj.fieldName === "deliveryStatus") {
            header.filterObj.items = ordersFiltersDropdowns.deliveryStatusList || [];
          }
        }
        return header;
      });
      setOrdersTableWrapperData({...ordersTableWrapperData, HeaderData: headerData});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersFiltersDropdowns]);

  useEffect(() => {
    if (isLastMileViewer || isOnlyExpLastMileViewer) {
      let headerDataWithoutActions = ordersTableWrapperData.HeaderData.map((data: any) => {
        if (data.field === "collectionStatus") {
          data.hide = true;
        }
        return data;
      });
      setOrdersTableWrapperData({...ordersTableWrapperData, HeaderData: headerDataWithoutActions});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastMileViewer, isOnlyExpLastMileViewer]);

  useEffect(() => {
    setOrdersListToShow(codPortalOrdersDataTransform(ordersData) as any);
    handleLoadMoreDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersData]);

  return (
    <>
      <CustomDialog
        open={open}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={null}
        handleClose={handleDialogClose}
      ></CustomDialog>
      <CustomDialog
        disableBackdropClick={true}
        open={openCollectionConfirmationPopup}
        PaperProps={createCollectionConfirmationDialogPaperProps}
        title={getCollectionConfirmationDialogTitleContent()}
        content={getCollectionConfirmationDialogDetailsContent()}
        actions={getCollectionConfirmationDialogActions()}
        handleClose={handleCollectionConfirmationDialogClose}
      ></CustomDialog>
    </>
  );
};

export default StandardCodDriverDetails;
