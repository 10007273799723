const ReconciliationConstants = {
  RECONCILIATION_CONSTANTS: {
    AMOUNT_VALUES: {
      Amount: [
        { label: "Handed Over", value: "handedOver" },
        { label: "Pending", value: "pendingAmount" },
        { label: "Excess", value: "excess" },
        { label: "Shortage", value: "shortage" },
      ]
    },
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
      }
    },
  }
}

export default ReconciliationConstants;
