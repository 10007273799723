import React from 'react';
import { useBarcode } from 'react-barcodes';

interface CustomBarcodeProps {
    value: string;
    imgProps?: any;
    options?: any;
}

const CustomBarcode = (props: CustomBarcodeProps) => {
    const { value, imgProps, options } = props;

    const { inputRef } = useBarcode({
        value: value || "N/A",
        options: options || {}
    });

    return (
        <img className={imgProps ? imgProps.className : ""} alt={imgProps ? imgProps.alt : "Custom Barcode"} ref={inputRef} />
    )
}

export default CustomBarcode;